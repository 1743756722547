import React from 'react';
import styles from './QuoteCard.module.css'

const NewQuoteCard = ({ name, price, previous_close, daily_price_change, daily_percentage_change, broker}) => {
  return (
    <div className={styles.stockQuote}>
      {/* <h2>{symbol}</h2> */}
      <div className={styles.quoteInfo}>
        <div className={styles.quoteItem}>
        <strong>{broker}: </strong><strong>{name}</strong>
        </div>
        <div className={styles.quoteItem}>
          <strong>Price: $</strong> {Math.round(price*100)/100}
        </div>
        <div className={styles.quoteItem}>
          <strong>Previous Close: $</strong> {Math.round(previous_close*100)/100}
        </div>
        <div className={styles.quoteItem}>
          <strong>Change: </strong> {Math.round(daily_price_change*100)/100}
        </div>
        <div className={styles.quoteItem}>
          <strong>Change %:</strong> {Math.round(daily_percentage_change*100)/100}
        </div>
      </div>
    </div>
  );
};

export default NewQuoteCard;

