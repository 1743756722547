// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvj3uepIESwew0gL3Fsl6JQs7r3w8-PYU",
  authDomain: "icarus-bt.firebaseapp.com",
  databaseURL: "https://icarus-bt-default-rtdb.firebaseio.com",
  projectId: "icarus-bt",
  storageBucket: "icarus-bt.appspot.com",
  messagingSenderId: "468090686091",
  appId: "1:468090686091:web:720b5a483a2691e7b4228e",
  measurementId: "G-Z44B5JX16K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// auth

export const auth = getAuth(app)
export default app