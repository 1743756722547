import React from "react";
// import { Routes } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
// import Layout from "../layout/Layout";

const Backtest = () => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Typography variant="h1">Backtest</Typography>
            </Grid>
        </Box>
    );
}

export default Backtest;