import React from 'react';
import Layout from '../layout/Layout';


const Portfolio = ({handleThemeChange}) => {
    return (
        <div>
            <Layout handleThemeChange={handleThemeChange} />
            <h1>Trade Journal Coming Soon</h1>

        </div>
    );
}

export default Portfolio;
