const Tickers = [
    {
        "label": "AAPL - Apple Inc.",
        "value": "AAPL"
    },
    {
        "label": "MSFT - MICROSOFT CORP",
        "value": "MSFT"
    },
    {
        "label": "GOOGL - Alphabet Inc.",
        "value": "GOOGL"
    },
    {
        "label": "AMZN - AMAZON COM INC",
        "value": "AMZN"
    },
    {
        "label": "NVDA - NVIDIA CORP",
        "value": "NVDA"
    },
    {
        "label": "BRK-B - BERKSHIRE HATHAWAY INC",
        "value": "BRK-B"
    },
    {
        "label": "META - Meta Platforms, Inc.",
        "value": "META"
    },
    {
        "label": "TSLA - Tesla, Inc.",
        "value": "TSLA"
    },
    {
        "label": "LLY - ELI LILLY & Co",
        "value": "LLY"
    },
    {
        "label": "V - VISA INC.",
        "value": "V"
    },
    {
        "label": "TSM - TAIWAN SEMICONDUCTOR MANUFACTURING CO LTD",
        "value": "TSM"
    },
    {
        "label": "UNH - UNITEDHEALTH GROUP INC",
        "value": "UNH"
    },
    {
        "label": "JNJ - JOHNSON & JOHNSON",
        "value": "JNJ"
    },
    {
        "label": "XOM - EXXON MOBIL CORP",
        "value": "XOM"
    },
    {
        "label": "JPM - JPMORGAN CHASE & CO",
        "value": "JPM"
    },
    {
        "label": "LVMUY - LVMH MOET HENNESSY LOUIS VUITTON",
        "value": "LVMUY"
    },
    {
        "label": "WMT - Walmart Inc.",
        "value": "WMT"
    },
    {
        "label": "NVO - NOVO NORDISK A S",
        "value": "NVO"
    },
    {
        "label": "SPY - SPDR S&P 500 ETF TRUST",
        "value": "SPY"
    },
    {
        "label": "LTMAY - LATAM AIRLINES GROUP S.A.",
        "value": "LTMAY"
    },
    {
        "label": "MA - Mastercard Inc",
        "value": "MA"
    },
    {
        "label": "PG - PROCTER & GAMBLE Co",
        "value": "PG"
    },
    {
        "label": "AVGO - Broadcom Inc.",
        "value": "AVGO"
    },
    {
        "label": "HD - HOME DEPOT, INC.",
        "value": "HD"
    },
    {
        "label": "ORCL - ORACLE CORP",
        "value": "ORCL"
    },
    {
        "label": "CVX - CHEVRON CORP",
        "value": "CVX"
    },
    {
        "label": "MRK - Merck & Co., Inc.",
        "value": "MRK"
    },
    {
        "label": "ABBV - AbbVie Inc.",
        "value": "ABBV"
    },
    {
        "label": "KO - COCA COLA CO",
        "value": "KO"
    },
    {
        "label": "ASML - ASML HOLDING NV",
        "value": "ASML"
    },
    {
        "label": "PEP - PEPSICO INC",
        "value": "PEP"
    },
    {
        "label": "COST - COSTCO WHOLESALE CORP /NEW",
        "value": "COST"
    },
    {
        "label": "ADBE - ADOBE INC.",
        "value": "ADBE"
    },
    {
        "label": "BAC - BANK OF AMERICA CORP /DE/",
        "value": "BAC"
    },
    {
        "label": "BABA - Alibaba Group Holding Ltd",
        "value": "BABA"
    },
    {
        "label": "CSCO - CISCO SYSTEMS, INC.",
        "value": "CSCO"
    },
    {
        "label": "TM - TOYOTA MOTOR CORP/",
        "value": "TM"
    },
    {
        "label": "NVS - NOVARTIS AG",
        "value": "NVS"
    },
    {
        "label": "AZN - ASTRAZENECA PLC",
        "value": "AZN"
    },
    {
        "label": "MCD - MCDONALDS CORP",
        "value": "MCD"
    },
    {
        "label": "TMO - THERMO FISHER SCIENTIFIC INC.",
        "value": "TMO"
    },
    {
        "label": "PFE - PFIZER INC",
        "value": "PFE"
    },
    {
        "label": "SHEL - Shell plc",
        "value": "SHEL"
    },
    {
        "label": "FMX - MEXICAN ECONOMIC DEVELOPMENT INC",
        "value": "FMX"
    },
    {
        "label": "CRM - Salesforce, Inc.",
        "value": "CRM"
    },
    {
        "label": "CMCSA - COMCAST CORP",
        "value": "CMCSA"
    },
    {
        "label": "ACN - Accenture plc",
        "value": "ACN"
    },
    {
        "label": "DHR - DANAHER CORP /DE/",
        "value": "DHR"
    },
    {
        "label": "LIN - LINDE PLC",
        "value": "LIN"
    },
    {
        "label": "ABT - ABBOTT LABORATORIES",
        "value": "ABT"
    },
    {
        "label": "NFLX - NETFLIX INC",
        "value": "NFLX"
    },
    {
        "label": "AMD - ADVANCED MICRO DEVICES INC",
        "value": "AMD"
    },
    {
        "label": "SAP - SAP SE",
        "value": "SAP"
    },
    {
        "label": "TMUS - T-Mobile US, Inc.",
        "value": "TMUS"
    },
    {
        "label": "NKE - NIKE, Inc.",
        "value": "NKE"
    },
    {
        "label": "HDB - HDFC BANK LTD",
        "value": "HDB"
    },
    {
        "label": "DIS - Walt Disney Co",
        "value": "DIS"
    },
    {
        "label": "WFC - WELLS FARGO & COMPANY/MN",
        "value": "WFC"
    },
    {
        "label": "HSBC - HSBC HOLDINGS PLC",
        "value": "HSBC"
    },
    {
        "label": "TXN - TEXAS INSTRUMENTS INC",
        "value": "TXN"
    },
    {
        "label": "TTE - TotalEnergies SE",
        "value": "TTE"
    },
    {
        "label": "UPS - UNITED PARCEL SERVICE INC",
        "value": "UPS"
    },
    {
        "label": "PM - Philip Morris International Inc.",
        "value": "PM"
    },
    {
        "label": "CAT - CATERPILLAR INC",
        "value": "CAT"
    },
    {
        "label": "MS - MORGAN STANLEY",
        "value": "MS"
    },
    {
        "label": "BHP - BHP Group Ltd",
        "value": "BHP"
    },
    {
        "label": "QQQ - INVESCO QQQ TRUST, SERIES 1",
        "value": "QQQ"
    },
    {
        "label": "AMGN - AMGEN INC",
        "value": "AMGN"
    },
    {
        "label": "COP - CONOCOPHILLIPS",
        "value": "COP"
    },
    {
        "label": "VZ - VERIZON COMMUNICATIONS INC",
        "value": "VZ"
    },
    {
        "label": "INTC - INTEL CORP",
        "value": "INTC"
    },
    {
        "label": "UNP - UNION PACIFIC CORP",
        "value": "UNP"
    },
    {
        "label": "INTU - INTUIT INC.",
        "value": "INTU"
    },
    {
        "label": "NEE - NEXTERA ENERGY INC",
        "value": "NEE"
    },
    {
        "label": "BA - BOEING CO",
        "value": "BA"
    },
    {
        "label": "SNY - Sanofi",
        "value": "SNY"
    },
    {
        "label": "BMY - BRISTOL MYERS SQUIBB CO",
        "value": "BMY"
    },
    {
        "label": "IBM - INTERNATIONAL BUSINESS MACHINES CORP",
        "value": "IBM"
    },
    {
        "label": "LOW - LOWES COMPANIES INC",
        "value": "LOW"
    },
    {
        "label": "UL - UNILEVER PLC",
        "value": "UL"
    },
    {
        "label": "RY - ROYAL BANK OF CANADA",
        "value": "RY"
    },
    {
        "label": "RTX - RTX Corp",
        "value": "RTX"
    },
    {
        "label": "HON - HONEYWELL INTERNATIONAL INC",
        "value": "HON"
    },
    {
        "label": "SPGI - S&P Global Inc.",
        "value": "SPGI"
    },
    {
        "label": "DE - DEERE & CO",
        "value": "DE"
    },
    {
        "label": "QCOM - QUALCOMM INC/DE",
        "value": "QCOM"
    },
    {
        "label": "GE - GENERAL ELECTRIC CO",
        "value": "GE"
    },
    {
        "label": "AXP - AMERICAN EXPRESS CO",
        "value": "AXP"
    },
    {
        "label": "BX - Blackstone Inc.",
        "value": "BX"
    },
    {
        "label": "BKNG - Booking Holdings Inc.",
        "value": "BKNG"
    },
    {
        "label": "AMAT - APPLIED MATERIALS INC /DE",
        "value": "AMAT"
    },
    {
        "label": "TD - TORONTO DOMINION BANK",
        "value": "TD"
    },
    {
        "label": "LMT - LOCKHEED MARTIN CORP",
        "value": "LMT"
    },
    {
        "label": "PLD - Prologis, Inc.",
        "value": "PLD"
    },
    {
        "label": "SBUX - STARBUCKS CORP",
        "value": "SBUX"
    },
    {
        "label": "NOW - ServiceNow, Inc.",
        "value": "NOW"
    },
    {
        "label": "RTNTF - RIO TINTO LTD",
        "value": "RTNTF"
    },
    {
        "label": "EADSY - Airbus SE/ADR",
        "value": "EADSY"
    },
    {
        "label": "BUD - Anheuser-Busch InBev SA/NV",
        "value": "BUD"
    },
    {
        "label": "SCHW - SCHWAB CHARLES CORP",
        "value": "SCHW"
    },
    {
        "label": "GS - GOLDMAN SACHS GROUP INC",
        "value": "GS"
    },
    {
        "label": "MDT - Medtronic plc",
        "value": "MDT"
    },
    {
        "label": "ELV - Elevance Health, Inc.",
        "value": "ELV"
    },
    {
        "label": "SYK - STRYKER CORP",
        "value": "SYK"
    },
    {
        "label": "PDD - PDD Holdings Inc.",
        "value": "PDD"
    },
    {
        "label": "BP - BP PLC",
        "value": "BP"
    },
    {
        "label": "ADP - AUTOMATIC DATA PROCESSING INC",
        "value": "ADP"
    },
    {
        "label": "ISRG - INTUITIVE SURGICAL INC",
        "value": "ISRG"
    },
    {
        "label": "SONY - Sony Group Corp",
        "value": "SONY"
    },
    {
        "label": "TJX - TJX COMPANIES INC /DE/",
        "value": "TJX"
    },
    {
        "label": "BLK - BlackRock Inc.",
        "value": "BLK"
    },
    {
        "label": "T - AT&T INC.",
        "value": "T"
    },
    {
        "label": "MDLZ - Mondelez International, Inc.",
        "value": "MDLZ"
    },
    {
        "label": "RIO - RIO TINTO PLC",
        "value": "RIO"
    },
    {
        "label": "GILD - GILEAD SCIENCES, INC.",
        "value": "GILD"
    },
    {
        "label": "EQNR - EQUINOR ASA",
        "value": "EQNR"
    },
    {
        "label": "DEO - DIAGEO PLC",
        "value": "DEO"
    },
    {
        "label": "MMC - MARSH & MCLENNAN COMPANIES, INC.",
        "value": "MMC"
    },
    {
        "label": "PBR - PETROBRAS - PETROLEO BRASILEIRO SA",
        "value": "PBR"
    },
    {
        "label": "UBER - Uber Technologies, Inc",
        "value": "UBER"
    },
    {
        "label": "MUFG - MITSUBISHI UFJ FINANCIAL GROUP INC",
        "value": "MUFG"
    },
    {
        "label": "VRTX - VERTEX PHARMACEUTICALS INC / MA",
        "value": "VRTX"
    },
    {
        "label": "ADI - ANALOG DEVICES INC",
        "value": "ADI"
    },
    {
        "label": "REGN - REGENERON PHARMACEUTICALS, INC.",
        "value": "REGN"
    },
    {
        "label": "LRCX - LAM RESEARCH CORP",
        "value": "LRCX"
    },
    {
        "label": "ETN - Eaton Corp plc",
        "value": "ETN"
    },
    {
        "label": "CVS - CVS HEALTH Corp",
        "value": "CVS"
    },
    {
        "label": "ZTS - Zoetis Inc.",
        "value": "ZTS"
    },
    {
        "label": "CSLLY - CSL LTD",
        "value": "CSLLY"
    },
    {
        "label": "CB - Chubb Ltd",
        "value": "CB"
    },
    {
        "label": "AMT - AMERICAN TOWER CORP /MA/",
        "value": "AMT"
    },
    {
        "label": "SLB - SCHLUMBERGER LIMITED/NV",
        "value": "SLB"
    },
    {
        "label": "C - CITIGROUP INC",
        "value": "C"
    },
    {
        "label": "ABNB - Airbnb, Inc.",
        "value": "ABNB"
    },
    {
        "label": "IBN - ICICI BANK LTD",
        "value": "IBN"
    },
    {
        "label": "CI - Cigna Group",
        "value": "CI"
    },
    {
        "label": "PGR - PROGRESSIVE CORP/OH/",
        "value": "PGR"
    },
    {
        "label": "BDX - BECTON DICKINSON & CO",
        "value": "BDX"
    },
    {
        "label": "MBGYY - DAIMLER AG",
        "value": "MBGYY"
    },
    {
        "label": "UBS - UBS Group AG",
        "value": "UBS"
    },
    {
        "label": "MO - ALTRIA GROUP, INC.",
        "value": "MO"
    },
    {
        "label": "EOG - EOG RESOURCES INC",
        "value": "EOG"
    },
    {
        "label": "BNPQY - BNP PARIBAS",
        "value": "BNPQY"
    },
    {
        "label": "PBCRY - PT Bank Central Asia Tbk / ADR",
        "value": "PBCRY"
    },
    {
        "label": "CNI - CANADIAN NATIONAL RAILWAY CO",
        "value": "CNI"
    },
    {
        "label": "SO - SOUTHERN CO",
        "value": "SO"
    },
    {
        "label": "FI - FISERV INC",
        "value": "FI"
    },
    {
        "label": "CME - CME GROUP INC.",
        "value": "CME"
    },
    {
        "label": "BSX - BOSTON SCIENTIFIC CORP",
        "value": "BSX"
    },
    {
        "label": "INFY - Infosys Ltd",
        "value": "INFY"
    },
    {
        "label": "CP - CANADIAN PACIFIC KANSAS CITY LTD/CN",
        "value": "CP"
    },
    {
        "label": "HCA - HCA Healthcare, Inc.",
        "value": "HCA"
    },
    {
        "label": "ITW - ILLINOIS TOOL WORKS INC",
        "value": "ITW"
    },
    {
        "label": "BTI - British American Tobacco p.l.c.",
        "value": "BTI"
    },
    {
        "label": "GSK - GSK plc",
        "value": "GSK"
    },
    {
        "label": "ATVI - Activision Blizzard, Inc.",
        "value": "ATVI"
    },
    {
        "label": "EQIX - EQUINIX INC",
        "value": "EQIX"
    },
    {
        "label": "ENB - ENBRIDGE INC",
        "value": "ENB"
    },
    {
        "label": "MU - MICRON TECHNOLOGY INC",
        "value": "MU"
    },
    {
        "label": "DUK - Duke Energy CORP",
        "value": "DUK"
    },
    {
        "label": "SHW - SHERWIN WILLIAMS CO",
        "value": "SHW"
    },
    {
        "label": "ABBNY - ABB LTD",
        "value": "ABBNY"
    },
    {
        "label": "SHOP - SHOPIFY INC.",
        "value": "SHOP"
    },
    {
        "label": "VMW - VMWARE, INC.",
        "value": "VMW"
    },
    {
        "label": "KKR - KKR & Co. Inc.",
        "value": "KKR"
    },
    {
        "label": "FDX - FEDEX CORP",
        "value": "FDX"
    },
    {
        "label": "CNQ - CANADIAN NATURAL RESOURCES LTD",
        "value": "CNQ"
    },
    {
        "label": "AMX - AMERICA MOVIL SAB DE CV/",
        "value": "AMX"
    },
    {
        "label": "PYPL - PayPal Holdings, Inc.",
        "value": "PYPL"
    },
    {
        "label": "NTES - NetEase, Inc.",
        "value": "NTES"
    },
    {
        "label": "AON - Aon plc",
        "value": "AON"
    },
    {
        "label": "KLAC - KLA CORP",
        "value": "KLAC"
    },
    {
        "label": "NOC - NORTHROP GRUMMAN CORP /DE/",
        "value": "NOC"
    },
    {
        "label": "SNPS - SYNOPSYS INC",
        "value": "SNPS"
    },
    {
        "label": "TOELY - Tokyo Electron LTD",
        "value": "TOELY"
    },
    {
        "label": "CHTR - CHARTER COMMUNICATIONS, INC. /MO/",
        "value": "CHTR"
    },
    {
        "label": "WM - WASTE MANAGEMENT INC",
        "value": "WM"
    },
    {
        "label": "ENLAY - ENEL SOCIETA PER AZIONI",
        "value": "ENLAY"
    },
    {
        "label": "PANW - Palo Alto Networks Inc",
        "value": "PANW"
    },
    {
        "label": "ICE - Intercontinental Exchange, Inc.",
        "value": "ICE"
    },
    {
        "label": "APD - Air Products & Chemicals, Inc.",
        "value": "APD"
    },
    {
        "label": "SHECY - Shin-Etsu Chemical Co., Ltd.",
        "value": "SHECY"
    },
    {
        "label": "SAN - Banco Santander, S.A.",
        "value": "SAN"
    },
    {
        "label": "ATLKY - ATLAS COPCO AB",
        "value": "ATLKY"
    },
    {
        "label": "SCCO - SOUTHERN COPPER CORP/",
        "value": "SCCO"
    },
    {
        "label": "MAR - MARRIOTT INTERNATIONAL INC /MD/",
        "value": "MAR"
    },
    {
        "label": "CL - COLGATE PALMOLIVE CO",
        "value": "CL"
    },
    {
        "label": "MPC - Marathon Petroleum Corp",
        "value": "MPC"
    },
    {
        "label": "HUM - HUMANA INC",
        "value": "HUM"
    },
    {
        "label": "MELI - MERCADOLIBRE INC",
        "value": "MELI"
    },
    {
        "label": "GD - GENERAL DYNAMICS CORP",
        "value": "GD"
    },
    {
        "label": "CSX - CSX CORP",
        "value": "CSX"
    },
    {
        "label": "MCO - MOODYS CORP /DE/",
        "value": "MCO"
    },
    {
        "label": "TGT - TARGET CORP",
        "value": "TGT"
    },
    {
        "label": "MNST - Monster Beverage Corp",
        "value": "MNST"
    },
    {
        "label": "CDNS - CADENCE DESIGN SYSTEMS INC",
        "value": "CDNS"
    },
    {
        "label": "BMO - BANK OF MONTREAL /CAN/",
        "value": "BMO"
    },
    {
        "label": "RELX - RELX PLC",
        "value": "RELX"
    },
    {
        "label": "WDAY - Workday, Inc.",
        "value": "WDAY"
    },
    {
        "label": "EL - ESTEE LAUDER COMPANIES INC",
        "value": "EL"
    },
    {
        "label": "TRI - THOMSON REUTERS CORP /CAN/",
        "value": "TRI"
    },
    {
        "label": "OLCLY - Oriental Land Co., Ltd./ADR",
        "value": "OLCLY"
    },
    {
        "label": "SMFG - SUMITOMO MITSUI FINANCIAL GROUP, INC.",
        "value": "SMFG"
    },
    {
        "label": "EPD - ENTERPRISE PRODUCTS PARTNERS L.P.",
        "value": "EPD"
    },
    {
        "label": "ORLY - O REILLY AUTOMOTIVE INC",
        "value": "ORLY"
    },
    {
        "label": "FCX - FREEPORT-MCMORAN INC",
        "value": "FCX"
    },
    {
        "label": "USB - US BANCORP \\DE\\",
        "value": "USB"
    },
    {
        "label": "OXY - OCCIDENTAL PETROLEUM CORP /DE/",
        "value": "OXY"
    },
    {
        "label": "ANET - Arista Networks, Inc.",
        "value": "ANET"
    },
    {
        "label": "MCK - MCKESSON CORP",
        "value": "MCK"
    },
    {
        "label": "RACE - Ferrari N.V.",
        "value": "RACE"
    },
    {
        "label": "MMM - 3M CO",
        "value": "MMM"
    },
    {
        "label": "BNS - BANK OF NOVA SCOTIA",
        "value": "BNS"
    },
    {
        "label": "PXD - PIONEER NATURAL RESOURCES CO",
        "value": "PXD"
    },
    {
        "label": "STLA - Stellantis N.V.",
        "value": "STLA"
    },
    {
        "label": "EMR - EMERSON ELECTRIC CO",
        "value": "EMR"
    },
    {
        "label": "JD - JD.com, Inc.",
        "value": "JD"
    },
    {
        "label": "VALE - Vale S.A.",
        "value": "VALE"
    },
    {
        "label": "PRNDY - PERNOD RICARD S A /FI",
        "value": "PRNDY"
    },
    {
        "label": "ITUB - Itau Unibanco Holding S.A.",
        "value": "ITUB"
    },
    {
        "label": "PSX - Phillips 66",
        "value": "PSX"
    },
    {
        "label": "ROP - ROPER TECHNOLOGIES INC",
        "value": "ROP"
    },
    {
        "label": "ECL - ECOLAB INC.",
        "value": "ECL"
    },
    {
        "label": "PH - PARKER HANNIFIN CORP",
        "value": "PH"
    },
    {
        "label": "CMG - CHIPOTLE MEXICAN GRILL INC",
        "value": "CMG"
    },
    {
        "label": "BN - BROOKFIELD Corp /ON/",
        "value": "BN"
    },
    {
        "label": "APH - AMPHENOL CORP /DE/",
        "value": "APH"
    },
    {
        "label": "ING - ING GROEP NV",
        "value": "ING"
    },
    {
        "label": "CTA-PA - EIDP, Inc.",
        "value": "CTA-PA"
    },
    {
        "label": "NXPI - NXP Semiconductors N.V.",
        "value": "NXPI"
    },
    {
        "label": "E - ENI SPA",
        "value": "E"
    },
    {
        "label": "HMC - HONDA MOTOR CO LTD",
        "value": "HMC"
    },
    {
        "label": "MRVL - Marvell Technology, Inc.",
        "value": "MRVL"
    },
    {
        "label": "CTAS - CINTAS CORP",
        "value": "CTAS"
    },
    {
        "label": "PNC - PNC FINANCIAL SERVICES GROUP, INC.",
        "value": "PNC"
    },
    {
        "label": "PSA - Public Storage",
        "value": "PSA"
    },
    {
        "label": "LULU - lululemon athletica inc.",
        "value": "LULU"
    },
    {
        "label": "SNOW - Snowflake Inc.",
        "value": "SNOW"
    },
    {
        "label": "VLO - VALERO ENERGY CORP/TX",
        "value": "VLO"
    },
    {
        "label": "TEAM - Atlassian Corp",
        "value": "TEAM"
    },
    {
        "label": "AJG - Arthur J. Gallagher & Co.",
        "value": "AJG"
    },
    {
        "label": "HES - HESS CORP",
        "value": "HES"
    },
    {
        "label": "NSC - NORFOLK SOUTHERN CORP",
        "value": "NSC"
    },
    {
        "label": "STZ - CONSTELLATION BRANDS, INC.",
        "value": "STZ"
    },
    {
        "label": "EW - Edwards Lifesciences Corp",
        "value": "EW"
    },
    {
        "label": "MET - METLIFE INC",
        "value": "MET"
    },
    {
        "label": "F - FORD MOTOR CO",
        "value": "F"
    },
    {
        "label": "KDP - Keurig Dr Pepper Inc.",
        "value": "KDP"
    },
    {
        "label": "ANZGY - ANZ GROUP HOLDINGS LIMITED/ADR",
        "value": "ANZGY"
    },
    {
        "label": "TAK - TAKEDA PHARMACEUTICAL CO LTD",
        "value": "TAK"
    },
    {
        "label": "WDS - WOODSIDE ENERGY GROUP LTD",
        "value": "WDS"
    },
    {
        "label": "MSI - Motorola Solutions, Inc.",
        "value": "MSI"
    },
    {
        "label": "TDG - TransDigm Group INC",
        "value": "TDG"
    },
    {
        "label": "RSG - REPUBLIC SERVICES, INC.",
        "value": "RSG"
    },
    {
        "label": "BBVA - BANCO BILBAO VIZCAYA ARGENTARIA, S.A.",
        "value": "BBVA"
    },
    {
        "label": "GM - General Motors Co",
        "value": "GM"
    },
    {
        "label": "AFL - AFLAC INC",
        "value": "AFL"
    },
    {
        "label": "BIDU - Baidu, Inc.",
        "value": "BIDU"
    },
    {
        "label": "GLD - SPDR GOLD TRUST",
        "value": "GLD"
    },
    {
        "label": "FTNT - Fortinet, Inc.",
        "value": "FTNT"
    },
    {
        "label": "TT - Trane Technologies plc",
        "value": "TT"
    },
    {
        "label": "ADM - Archer-Daniels-Midland Co",
        "value": "ADM"
    },
    {
        "label": "AZO - AUTOZONE INC",
        "value": "AZO"
    },
    {
        "label": "APO - Apollo Global Management, Inc.",
        "value": "APO"
    },
    {
        "label": "NGG - NATIONAL GRID PLC",
        "value": "NGG"
    },
    {
        "label": "SRE - SEMPRA",
        "value": "SRE"
    },
    {
        "label": "ABEV - AMBEV S.A.",
        "value": "ABEV"
    },
    {
        "label": "HSY - HERSHEY CO",
        "value": "HSY"
    },
    {
        "label": "CARR - CARRIER GLOBAL Corp",
        "value": "CARR"
    },
    {
        "label": "PCAR - PACCAR INC",
        "value": "PCAR"
    },
    {
        "label": "PAYX - PAYCHEX INC",
        "value": "PAYX"
    },
    {
        "label": "ODFL - OLD DOMINION FREIGHT LINE, INC.",
        "value": "ODFL"
    },
    {
        "label": "CCI - CROWN CASTLE INC.",
        "value": "CCI"
    },
    {
        "label": "KVUE - Kenvue Inc.",
        "value": "KVUE"
    },
    {
        "label": "STM - STMicroelectronics N.V.",
        "value": "STM"
    },
    {
        "label": "MCHP - MICROCHIP TECHNOLOGY INC",
        "value": "MCHP"
    },
    {
        "label": "SPG - SIMON PROPERTY GROUP INC /DE/",
        "value": "SPG"
    },
    {
        "label": "KMB - KIMBERLY CLARK CORP",
        "value": "KMB"
    },
    {
        "label": "ADSK - Autodesk, Inc.",
        "value": "ADSK"
    },
    {
        "label": "SU - SUNCOR ENERGY INC",
        "value": "SU"
    },
    {
        "label": "WMB - WILLIAMS COMPANIES, INC.",
        "value": "WMB"
    },
    {
        "label": "NUE - NUCOR CORP",
        "value": "NUE"
    },
    {
        "label": "DXCM - DEXCOM INC",
        "value": "DXCM"
    },
    {
        "label": "AIG - AMERICAN INTERNATIONAL GROUP, INC.",
        "value": "AIG"
    },
    {
        "label": "MSCI - MSCI Inc.",
        "value": "MSCI"
    },
    {
        "label": "GIS - GENERAL MILLS INC",
        "value": "GIS"
    },
    {
        "label": "CPRT - COPART INC",
        "value": "CPRT"
    },
    {
        "label": "WELL - WELLTOWER INC.",
        "value": "WELL"
    },
    {
        "label": "KHC - Kraft Heinz Co",
        "value": "KHC"
    },
    {
        "label": "CRH - CRH PUBLIC LTD CO",
        "value": "CRH"
    },
    {
        "label": "LVS - LAS VEGAS SANDS CORP",
        "value": "LVS"
    },
    {
        "label": "AEP - AMERICAN ELECTRIC POWER CO INC",
        "value": "AEP"
    },
    {
        "label": "IDXX - IDEXX LABORATORIES INC /DE",
        "value": "IDXX"
    },
    {
        "label": "ET - Energy Transfer LP",
        "value": "ET"
    },
    {
        "label": "TEL - TE Connectivity Ltd.",
        "value": "TEL"
    },
    {
        "label": "JCI - Johnson Controls International plc",
        "value": "JCI"
    },
    {
        "label": "LI - Li Auto Inc.",
        "value": "LI"
    },
    {
        "label": "ALC - ALCON INC",
        "value": "ALC"
    },
    {
        "label": "DELL - Dell Technologies Inc.",
        "value": "DELL"
    },
    {
        "label": "D - DOMINION ENERGY, INC",
        "value": "D"
    },
    {
        "label": "MRNA - Moderna, Inc.",
        "value": "MRNA"
    },
    {
        "label": "COF - CAPITAL ONE FINANCIAL CORP",
        "value": "COF"
    },
    {
        "label": "HLT - Hilton Worldwide Holdings Inc.",
        "value": "HLT"
    },
    {
        "label": "LNG - Cheniere Energy, Inc.",
        "value": "LNG"
    },
    {
        "label": "DHI - HORTON D R INC /DE/",
        "value": "DHI"
    },
    {
        "label": "EXC - EXELON CORP",
        "value": "EXC"
    },
    {
        "label": "IQV - IQVIA HOLDINGS INC.",
        "value": "IQV"
    },
    {
        "label": "MFG - MIZUHO FINANCIAL GROUP INC",
        "value": "MFG"
    },
    {
        "label": "PCG - PG&E Corp",
        "value": "PCG"
    },
    {
        "label": "ON - ON SEMICONDUCTOR CORP",
        "value": "ON"
    },
    {
        "label": "BSBR - Banco Santander (Brasil) S.A.",
        "value": "BSBR"
    },
    {
        "label": "KMI - KINDER MORGAN, INC.",
        "value": "KMI"
    },
    {
        "label": "ROST - ROSS STORES, INC.",
        "value": "ROST"
    },
    {
        "label": "O - REALTY INCOME CORP",
        "value": "O"
    },
    {
        "label": "HLN - Haleon plc",
        "value": "HLN"
    },
    {
        "label": "TFC - TRUIST FINANCIAL CORP",
        "value": "TFC"
    },
    {
        "label": "HCMLY - LafargeHolcim Ltd/ADR",
        "value": "HCMLY"
    },
    {
        "label": "DOW - DOW INC.",
        "value": "DOW"
    },
    {
        "label": "IBKR - Interactive Brokers Group, Inc.",
        "value": "IBKR"
    },
    {
        "label": "BIIB - BIOGEN INC.",
        "value": "BIIB"
    },
    {
        "label": "BAESY - BAE SYSTEMS PLC /FI/",
        "value": "BAESY"
    },
    {
        "label": "CRARY - CREDIT AGRICOLE S A",
        "value": "CRARY"
    },
    {
        "label": "TRV - TRAVELERS COMPANIES, INC.",
        "value": "TRV"
    },
    {
        "label": "BCE - BCE INC",
        "value": "BCE"
    },
    {
        "label": "CVE - CENOVUS ENERGY INC.",
        "value": "CVE"
    },
    {
        "label": "CM - CANADIAN IMPERIAL BANK OF COMMERCE /CAN/",
        "value": "CM"
    },
    {
        "label": "YUM - YUM BRANDS INC",
        "value": "YUM"
    },
    {
        "label": "SYY - SYSCO CORP",
        "value": "SYY"
    },
    {
        "label": "ABC - AMERISOURCEBERGEN CORP",
        "value": "ABC"
    },
    {
        "label": "SGEN - Seagen Inc.",
        "value": "SGEN"
    },
    {
        "label": "CTVA - Corteva, Inc.",
        "value": "CTVA"
    },
    {
        "label": "A - AGILENT TECHNOLOGIES, INC.",
        "value": "A"
    },
    {
        "label": "DLR - DIGITAL REALTY TRUST, INC.",
        "value": "DLR"
    },
    {
        "label": "TRP - TC ENERGY CORP",
        "value": "TRP"
    },
    {
        "label": "WCN - Waste Connections, Inc.",
        "value": "WCN"
    },
    {
        "label": "BKR - Baker Hughes Co",
        "value": "BKR"
    },
    {
        "label": "DG - DOLLAR GENERAL CORP",
        "value": "DG"
    },
    {
        "label": "HAL - HALLIBURTON CO",
        "value": "HAL"
    },
    {
        "label": "AME - AMETEK INC/",
        "value": "AME"
    },
    {
        "label": "GWW - W.W. GRAINGER, INC.",
        "value": "GWW"
    },
    {
        "label": "CTSH - COGNIZANT TECHNOLOGY SOLUTIONS CORP",
        "value": "CTSH"
    },
    {
        "label": "PPERY - PT Bank Mandiri (Persero) Tbk / ADR",
        "value": "PPERY"
    },
    {
        "label": "AMP - AMERIPRISE FINANCIAL INC",
        "value": "AMP"
    },
    {
        "label": "OTIS - Otis Worldwide Corp",
        "value": "OTIS"
    },
    {
        "label": "MPLX - MPLX LP",
        "value": "MPLX"
    },
    {
        "label": "TTD - Trade Desk, Inc.",
        "value": "TTD"
    },
    {
        "label": "CNC - CENTENE CORP",
        "value": "CNC"
    },
    {
        "label": "IAU - ISHARES GOLD TRUST",
        "value": "IAU"
    },
    {
        "label": "PUK - PRUDENTIAL PLC",
        "value": "PUK"
    },
    {
        "label": "LHX - L3HARRIS TECHNOLOGIES, INC. /DE/",
        "value": "LHX"
    },
    {
        "label": "CRWD - CrowdStrike Holdings, Inc.",
        "value": "CRWD"
    },
    {
        "label": "DD - DuPont de Nemours, Inc.",
        "value": "DD"
    },
    {
        "label": "BK - Bank of New York Mellon Corp",
        "value": "BK"
    },
    {
        "label": "LYG - Lloyds Banking Group plc",
        "value": "LYG"
    },
    {
        "label": "MFC - MANULIFE FINANCIAL CORP",
        "value": "MFC"
    },
    {
        "label": "SQ - Block, Inc.",
        "value": "SQ"
    },
    {
        "label": "CEG - Constellation Energy Corp",
        "value": "CEG"
    },
    {
        "label": "PRU - PRUDENTIAL FINANCIAL INC",
        "value": "PRU"
    },
    {
        "label": "NU - Nu Holdings Ltd.",
        "value": "NU"
    },
    {
        "label": "ROK - ROCKWELL AUTOMATION, INC",
        "value": "ROK"
    },
    {
        "label": "KR - KROGER CO",
        "value": "KR"
    },
    {
        "label": "VRSK - Verisk Analytics, Inc.",
        "value": "VRSK"
    },
    {
        "label": "LEN - LENNAR CORP /NEW/",
        "value": "LEN"
    },
    {
        "label": "CODYY - COMPAGNIE DE SAINT GOBAIN",
        "value": "CODYY"
    },
    {
        "label": "FIS - Fidelity National Information Services, Inc.",
        "value": "FIS"
    },
    {
        "label": "CMI - CUMMINS INC",
        "value": "CMI"
    },
    {
        "label": "BF-B - BROWN FORMAN CORP",
        "value": "BF-B"
    },
    {
        "label": "GPN - GLOBAL PAYMENTS INC",
        "value": "GPN"
    },
    {
        "label": "FAST - FASTENAL CO",
        "value": "FAST"
    },
    {
        "label": "PPG - PPG INDUSTRIES INC",
        "value": "PPG"
    },
    {
        "label": "EA - ELECTRONIC ARTS INC.",
        "value": "EA"
    },
    {
        "label": "CPNG - Coupang, Inc.",
        "value": "CPNG"
    },
    {
        "label": "IMO - IMPERIAL OIL LTD",
        "value": "IMO"
    },
    {
        "label": "XEL - XCEL ENERGY INC",
        "value": "XEL"
    },
    {
        "label": "LYB - LyondellBasell Industries N.V.",
        "value": "LYB"
    },
    {
        "label": "FERG - Ferguson plc",
        "value": "FERG"
    },
    {
        "label": "CSGP - COSTAR GROUP, INC.",
        "value": "CSGP"
    },
    {
        "label": "BBD - BANK BRADESCO",
        "value": "BBD"
    },
    {
        "label": "EXPGY - Experian Group LTD",
        "value": "EXPGY"
    },
    {
        "label": "DLTR - DOLLAR TREE, INC.",
        "value": "DLTR"
    },
    {
        "label": "WBD - Warner Bros. Discovery, Inc.",
        "value": "WBD"
    },
    {
        "label": "DVN - DEVON ENERGY CORP/DE",
        "value": "DVN"
    },
    {
        "label": "QSR - Restaurant Brands International Inc.",
        "value": "QSR"
    },
    {
        "label": "GEHC - GE HealthCare Technologies Inc.",
        "value": "GEHC"
    },
    {
        "label": "PKX - POSCO HOLDINGS INC.",
        "value": "PKX"
    },
    {
        "label": "URI - UNITED RENTALS, INC.",
        "value": "URI"
    },
    {
        "label": "TLGPY - Telstra Group Ltd",
        "value": "TLGPY"
    },
    {
        "label": "ED - CONSOLIDATED EDISON INC",
        "value": "ED"
    },
    {
        "label": "HPQ - HP INC",
        "value": "HPQ"
    },
    {
        "label": "NTR - Nutrien Ltd.",
        "value": "NTR"
    },
    {
        "label": "NEM - NEWMONT Corp /DE/",
        "value": "NEM"
    },
    {
        "label": "DASH - DoorDash, Inc.",
        "value": "DASH"
    },
    {
        "label": "VEEV - VEEVA SYSTEMS INC",
        "value": "VEEV"
    },
    {
        "label": "GFS - GLOBALFOUNDRIES Inc.",
        "value": "GFS"
    },
    {
        "label": "PLTR - Palantir Technologies Inc.",
        "value": "PLTR"
    },
    {
        "label": "PEG - PUBLIC SERVICE ENTERPRISE GROUP INC",
        "value": "PEG"
    },
    {
        "label": "VICI - VICI PROPERTIES INC.",
        "value": "VICI"
    },
    {
        "label": "VTMX - Vesta Real Estate Corporation, S.A.B. de C.V.",
        "value": "VTMX"
    },
    {
        "label": "ARES - Ares Management Corp",
        "value": "ARES"
    },
    {
        "label": "MBLY - Mobileye Global Inc.",
        "value": "MBLY"
    },
    {
        "label": "ORAN - ORANGE",
        "value": "ORAN"
    },
    {
        "label": "OEZVY - OSTERREICHISCHE ELEKTRIZITATSWIRTSCHAFTS /FI",
        "value": "OEZVY"
    },
    {
        "label": "ARGX - ARGENX SE",
        "value": "ARGX"
    },
    {
        "label": "OKE - ONEOK INC /NEW/",
        "value": "OKE"
    },
    {
        "label": "WTKWY - WOLTERS KLUWER N V /FI",
        "value": "WTKWY"
    },
    {
        "label": "WST - WEST PHARMACEUTICAL SERVICES INC",
        "value": "WST"
    },
    {
        "label": "PWR - QUANTA SERVICES, INC.",
        "value": "PWR"
    },
    {
        "label": "DDOG - Datadog, Inc.",
        "value": "DDOG"
    },
    {
        "label": "BCS - BARCLAYS PLC",
        "value": "BCS"
    },
    {
        "label": "CCEP - COCA-COLA EUROPACIFIC PARTNERS plc",
        "value": "CCEP"
    },
    {
        "label": "CHT - CHUNGHWA TELECOM CO LTD",
        "value": "CHT"
    },
    {
        "label": "VMC - Vulcan Materials CO",
        "value": "VMC"
    },
    {
        "label": "ALL - ALLSTATE CORP",
        "value": "ALL"
    },
    {
        "label": "SLF - SUN LIFE FINANCIAL INC",
        "value": "SLF"
    },
    {
        "label": "DIA - SPDR DOW JONES INDUSTRIAL AVERAGE ETF TRUST",
        "value": "DIA"
    },
    {
        "label": "EXR - Extra Space Storage Inc.",
        "value": "EXR"
    },
    {
        "label": "ACGL - ARCH CAPITAL GROUP LTD.",
        "value": "ACGL"
    },
    {
        "label": "FTV - Fortive Corp",
        "value": "FTV"
    },
    {
        "label": "GOLD - BARRICK GOLD CORP",
        "value": "GOLD"
    },
    {
        "label": "CDW - CDW Corp",
        "value": "CDW"
    },
    {
        "label": "BDORY - BANCO DO BRASIL S.A.",
        "value": "BDORY"
    },
    {
        "label": "MLM - MARTIN MARIETTA MATERIALS INC",
        "value": "MLM"
    },
    {
        "label": "DAL - DELTA AIR LINES, INC.",
        "value": "DAL"
    },
    {
        "label": "BNTX - BioNTech SE",
        "value": "BNTX"
    },
    {
        "label": "GWLIF - GREAT-WEST LIFECO INC.",
        "value": "GWLIF"
    },
    {
        "label": "AWK - American Water Works Company, Inc.",
        "value": "AWK"
    },
    {
        "label": "ALGN - ALIGN TECHNOLOGY INC",
        "value": "ALGN"
    },
    {
        "label": "KEYS - Keysight Technologies, Inc.",
        "value": "KEYS"
    },
    {
        "label": "IR - Ingersoll Rand Inc.",
        "value": "IR"
    },
    {
        "label": "WEC - WEC ENERGY GROUP, INC.",
        "value": "WEC"
    },
    {
        "label": "FANG - Diamondback Energy, Inc.",
        "value": "FANG"
    },
    {
        "label": "ILMN - ILLUMINA, INC.",
        "value": "ILMN"
    },
    {
        "label": "EIX - EDISON INTERNATIONAL",
        "value": "EIX"
    },
    {
        "label": "MTD - METTLER TOLEDO INTERNATIONAL INC/",
        "value": "MTD"
    },
    {
        "label": "NWG - NatWest Group plc",
        "value": "NWG"
    },
    {
        "label": "FANUY - Fanuc Ltd",
        "value": "FANUY"
    },
    {
        "label": "GLW - CORNING INC /NY",
        "value": "GLW"
    },
    {
        "label": "MDY - SPDR S&P MIDCAP 400 ETF TRUST",
        "value": "MDY"
    },
    {
        "label": "FNV - FRANCO NEVADA Corp",
        "value": "FNV"
    },
    {
        "label": "APTV - Aptiv PLC",
        "value": "APTV"
    },
    {
        "label": "WIT - WIPRO LTD",
        "value": "WIT"
    },
    {
        "label": "IT - GARTNER INC",
        "value": "IT"
    },
    {
        "label": "RCL - ROYAL CARIBBEAN CRUISES LTD",
        "value": "RCL"
    },
    {
        "label": "CBRE - CBRE GROUP, INC.",
        "value": "CBRE"
    },
    {
        "label": "AVB - AVALONBAY COMMUNITIES INC",
        "value": "AVB"
    },
    {
        "label": "SPOT - Spotify Technology S.A.",
        "value": "SPOT"
    },
    {
        "label": "NDAQ - NASDAQ, INC.",
        "value": "NDAQ"
    },
    {
        "label": "PCRFY - PANASONIC Corp",
        "value": "PCRFY"
    },
    {
        "label": "TCOM - Trip.com Group Ltd",
        "value": "TCOM"
    },
    {
        "label": "ANSS - ANSYS INC",
        "value": "ANSS"
    },
    {
        "label": "ZBH - ZIMMER BIOMET HOLDINGS, INC.",
        "value": "ZBH"
    },
    {
        "label": "MDB - MongoDB, Inc.",
        "value": "MDB"
    },
    {
        "label": "HUBS - HUBSPOT INC",
        "value": "HUBS"
    },
    {
        "label": "TROW - PRICE T ROWE GROUP INC",
        "value": "TROW"
    },
    {
        "label": "VOD - VODAFONE GROUP PUBLIC LTD CO",
        "value": "VOD"
    },
    {
        "label": "TU - TELUS CORP",
        "value": "TU"
    },
    {
        "label": "EQR - EQUITY RESIDENTIAL",
        "value": "EQR"
    },
    {
        "label": "CAJPY - CANON INC",
        "value": "CAJPY"
    },
    {
        "label": "CQP - Cheniere Energy Partners, L.P.",
        "value": "CQP"
    },
    {
        "label": "TLK - PERUSAHAAN PERSEROAN PERSERO PT TELEKOMUNIKASI INDONESIA TBK",
        "value": "TLK"
    },
    {
        "label": "SAUHY - Straumann Holding AG / ADR",
        "value": "SAUHY"
    },
    {
        "label": "SBAC - SBA COMMUNICATIONS CORP",
        "value": "SBAC"
    },
    {
        "label": "TSCO - TRACTOR SUPPLY CO /DE/",
        "value": "TSCO"
    },
    {
        "label": "RMD - RESMED INC",
        "value": "RMD"
    },
    {
        "label": "EFX - EQUIFAX INC",
        "value": "EFX"
    },
    {
        "label": "XYL - Xylem Inc.",
        "value": "XYL"
    },
    {
        "label": "HZNP - Horizon Therapeutics Public Ltd Co",
        "value": "HZNP"
    },
    {
        "label": "GMAB - GENMAB A/S",
        "value": "GMAB"
    },
    {
        "label": "WY - WEYERHAEUSER CO",
        "value": "WY"
    },
    {
        "label": "WBA - Walgreens Boots Alliance, Inc.",
        "value": "WBA"
    },
    {
        "label": "TTWO - TAKE TWO INTERACTIVE SOFTWARE INC",
        "value": "TTWO"
    },
    {
        "label": "GIB - CGI INC",
        "value": "GIB"
    },
    {
        "label": "ELP - ENERGY CO OF PARANA",
        "value": "ELP"
    },
    {
        "label": "DFS - Discover Financial Services",
        "value": "DFS"
    },
    {
        "label": "MPWR - MONOLITHIC POWER SYSTEMS INC",
        "value": "MPWR"
    },
    {
        "label": "ALNY - ALNYLAM PHARMACEUTICALS, INC.",
        "value": "ALNY"
    },
    {
        "label": "EC - ECOPETROL S.A.",
        "value": "EC"
    },
    {
        "label": "EBAY - EBAY INC",
        "value": "EBAY"
    },
    {
        "label": "MKC - MCCORMICK & CO INC",
        "value": "MKC"
    },
    {
        "label": "AEM - AGNICO EAGLE MINES LTD",
        "value": "AEM"
    },
    {
        "label": "YUMC - Yum China Holdings, Inc.",
        "value": "YUMC"
    },
    {
        "label": "ATEYY - ADVANTEST CORP",
        "value": "ATEYY"
    },
    {
        "label": "CHD - CHURCH & DWIGHT CO INC /DE/",
        "value": "CHD"
    },
    {
        "label": "SE - Sea Ltd",
        "value": "SE"
    },
    {
        "label": "RYAAY - RYANAIR HOLDINGS PLC",
        "value": "RYAAY"
    },
    {
        "label": "ES - EVERSOURCE ENERGY",
        "value": "ES"
    },
    {
        "label": "TEF - TELEFONICA S A",
        "value": "TEF"
    },
    {
        "label": "ULTA - Ulta Beauty, Inc.",
        "value": "ULTA"
    },
    {
        "label": "CABHF - Carlsberg AS",
        "value": "CABHF"
    },
    {
        "label": "HIG - HARTFORD FINANCIAL SERVICES GROUP, INC.",
        "value": "HIG"
    },
    {
        "label": "STE - STERIS plc",
        "value": "STE"
    },
    {
        "label": "RJF - RAYMOND JAMES FINANCIAL INC",
        "value": "RJF"
    },
    {
        "label": "STT - STATE STREET CORP",
        "value": "STT"
    },
    {
        "label": "HPE - Hewlett Packard Enterprise Co",
        "value": "HPE"
    },
    {
        "label": "DB - DEUTSCHE BANK AKTIENGESELLSCHAFT",
        "value": "DB"
    },
    {
        "label": "CAH - CARDINAL HEALTH INC",
        "value": "CAH"
    },
    {
        "label": "SYM - Symbotic Inc.",
        "value": "SYM"
    },
    {
        "label": "GPC - GENUINE PARTS CO",
        "value": "GPC"
    },
    {
        "label": "MT - ArcelorMittal",
        "value": "MT"
    },
    {
        "label": "HRL - HORMEL FOODS CORP /DE/",
        "value": "HRL"
    },
    {
        "label": "DTE - DTE ENERGY CO",
        "value": "DTE"
    },
    {
        "label": "ALB - ALBEMARLE CORP",
        "value": "ALB"
    },
    {
        "label": "RKT - Rocket Companies, Inc.",
        "value": "RKT"
    },
    {
        "label": "UDR - UDR, Inc.",
        "value": "UDR"
    },
    {
        "label": "MTB - M&T BANK CORP",
        "value": "MTB"
    },
    {
        "label": "RCI - ROGERS COMMUNICATIONS INC",
        "value": "RCI"
    },
    {
        "label": "BR - BROADRIDGE FINANCIAL SOLUTIONS, INC.",
        "value": "BR"
    },
    {
        "label": "CTRA - Coterra Energy Inc.",
        "value": "CTRA"
    },
    {
        "label": "FICO - FAIR ISAAC CORP",
        "value": "FICO"
    },
    {
        "label": "K - KELLOGG CO",
        "value": "K"
    },
    {
        "label": "BAX - BAXTER INTERNATIONAL INC",
        "value": "BAX"
    },
    {
        "label": "VRSN - VERISIGN INC/CA",
        "value": "VRSN"
    },
    {
        "label": "NOK - NOKIA CORP",
        "value": "NOK"
    },
    {
        "label": "WTW - WILLIS TOWERS WATSON PLC",
        "value": "WTW"
    },
    {
        "label": "AEE - AMEREN CORP",
        "value": "AEE"
    },
    {
        "label": "INVH - Invitation Homes Inc.",
        "value": "INVH"
    },
    {
        "label": "CCL - CARNIVAL CORP",
        "value": "CCL"
    },
    {
        "label": "FRFHF - FAIRFAX FINANCIAL HOLDINGS LTD/ CAN",
        "value": "FRFHF"
    },
    {
        "label": "ICLR - ICON PLC",
        "value": "ICLR"
    },
    {
        "label": "BGNE - BeiGene, Ltd.",
        "value": "BGNE"
    },
    {
        "label": "FE - FIRSTENERGY CORP",
        "value": "FE"
    },
    {
        "label": "IX - ORIX CORP",
        "value": "IX"
    },
    {
        "label": "PHG - KONINKLIJKE PHILIPS NV",
        "value": "PHG"
    },
    {
        "label": "NIO - NIO Inc.",
        "value": "NIO"
    },
    {
        "label": "ZTO - ZTO Express (Cayman) Inc.",
        "value": "ZTO"
    },
    {
        "label": "BRO - BROWN & BROWN, INC.",
        "value": "BRO"
    },
    {
        "label": "ARE - ALEXANDRIA REAL ESTATE EQUITIES, INC.",
        "value": "ARE"
    },
    {
        "label": "LYV - Live Nation Entertainment, Inc.",
        "value": "LYV"
    },
    {
        "label": "HWM - Howmet Aerospace Inc.",
        "value": "HWM"
    },
    {
        "label": "FCNCA - FIRST CITIZENS BANCSHARES INC /DE/",
        "value": "FCNCA"
    },
    {
        "label": "ETR - ENTERGY CORP /DE/",
        "value": "ETR"
    },
    {
        "label": "WAB - WESTINGHOUSE AIR BRAKE TECHNOLOGIES CORP",
        "value": "WAB"
    },
    {
        "label": "MKL - MARKEL GROUP INC.",
        "value": "MKL"
    },
    {
        "label": "ROL - ROLLINS INC",
        "value": "ROL"
    },
    {
        "label": "HEI - HEICO CORP",
        "value": "HEI"
    },
    {
        "label": "AIU - Meta Data Ltd",
        "value": "AIU"
    },
    {
        "label": "ZS - Zscaler, Inc.",
        "value": "ZS"
    },
    {
        "label": "TECK - TECK RESOURCES LTD",
        "value": "TECK"
    },
    {
        "label": "RIVN - Rivian Automotive, Inc. / DE",
        "value": "RIVN"
    },
    {
        "label": "NVR - NVR INC",
        "value": "NVR"
    },
    {
        "label": "ZM - Zoom Video Communications, Inc.",
        "value": "ZM"
    },
    {
        "label": "FSLR - FIRST SOLAR, INC.",
        "value": "FSLR"
    },
    {
        "label": "DOV - DOVER Corp",
        "value": "DOV"
    },
    {
        "label": "JBHT - HUNT J B TRANSPORT SERVICES INC",
        "value": "JBHT"
    },
    {
        "label": "TS - TENARIS SA",
        "value": "TS"
    },
    {
        "label": "FLT - FLEETCOR TECHNOLOGIES INC",
        "value": "FLT"
    },
    {
        "label": "GRMN - GARMIN LTD",
        "value": "GRMN"
    },
    {
        "label": "TW - Tradeweb Markets Inc.",
        "value": "TW"
    },
    {
        "label": "NET - Cloudflare, Inc.",
        "value": "NET"
    },
    {
        "label": "TSN - TYSON FOODS, INC.",
        "value": "TSN"
    },
    {
        "label": "FTS - Fortis Inc.",
        "value": "FTS"
    },
    {
        "label": "DRI - DARDEN RESTAURANTS INC",
        "value": "DRI"
    },
    {
        "label": "LUV - SOUTHWEST AIRLINES CO",
        "value": "LUV"
    },
    {
        "label": "DSCSY - Disco Corporation/ADR",
        "value": "DSCSY"
    },
    {
        "label": "CLX - CLOROX CO /DE/",
        "value": "CLX"
    },
    {
        "label": "BEKE - KE Holdings Inc.",
        "value": "BEKE"
    },
    {
        "label": "TRGP - Targa Resources Corp.",
        "value": "TRGP"
    },
    {
        "label": "TDY - TELEDYNE TECHNOLOGIES INC",
        "value": "TDY"
    },
    {
        "label": "LH - LABORATORY CORP OF AMERICA HOLDINGS",
        "value": "LH"
    },
    {
        "label": "RTO - RENTOKIL INITIAL PLC /FI",
        "value": "RTO"
    },
    {
        "label": "WPM - Wheaton Precious Metals Corp.",
        "value": "WPM"
    },
    {
        "label": "PFG - PRINCIPAL FINANCIAL GROUP INC",
        "value": "PFG"
    },
    {
        "label": "PPL - PPL Corp",
        "value": "PPL"
    },
    {
        "label": "CUK - CARNIVAL PLC",
        "value": "CUK"
    },
    {
        "label": "RF - REGIONS FINANCIAL CORP",
        "value": "RF"
    },
    {
        "label": "COO - COOPER COMPANIES, INC.",
        "value": "COO"
    },
    {
        "label": "PINS - PINTEREST, INC.",
        "value": "PINS"
    },
    {
        "label": "HOLX - HOLOGIC INC",
        "value": "HOLX"
    },
    {
        "label": "ENPH - Enphase Energy, Inc.",
        "value": "ENPH"
    },
    {
        "label": "SQM - CHEMICAL & MINING CO OF CHILE INC",
        "value": "SQM"
    },
    {
        "label": "MOH - MOLINA HEALTHCARE, INC.",
        "value": "MOH"
    },
    {
        "label": "STLD - STEEL DYNAMICS INC",
        "value": "STLD"
    },
    {
        "label": "KOF - COCA COLA FEMSA SAB DE CV",
        "value": "KOF"
    },
    {
        "label": "COIN - Coinbase Global, Inc.",
        "value": "COIN"
    },
    {
        "label": "UWMC - UWM Holdings Corp",
        "value": "UWMC"
    },
    {
        "label": "CNP - CENTERPOINT ENERGY INC",
        "value": "CNP"
    },
    {
        "label": "SIRI - SIRIUS XM HOLDINGS INC.",
        "value": "SIRI"
    },
    {
        "label": "NTDTY - NTT Data Corp",
        "value": "NTDTY"
    },
    {
        "label": "FITB - FIFTH THIRD BANCORP",
        "value": "FITB"
    },
    {
        "label": "BALL - BALL Corp",
        "value": "BALL"
    },
    {
        "label": "CNHI - CNH Industrial N.V.",
        "value": "CNHI"
    },
    {
        "label": "EXPD - EXPEDITORS INTERNATIONAL OF WASHINGTON INC",
        "value": "EXPD"
    },
    {
        "label": "PHM - PULTEGROUP INC/MI/",
        "value": "PHM"
    },
    {
        "label": "BEP - Brookfield Renewable Partners L.P.",
        "value": "BEP"
    },
    {
        "label": "MAA - MID AMERICA APARTMENT COMMUNITIES INC.",
        "value": "MAA"
    },
    {
        "label": "LPLA - LPL Financial Holdings Inc.",
        "value": "LPLA"
    },
    {
        "label": "BLDR - Builders FirstSource, Inc.",
        "value": "BLDR"
    },
    {
        "label": "IRM - IRON MOUNTAIN INC",
        "value": "IRM"
    },
    {
        "label": "SGSOY - SGS SOCIETE GENERALE DE SURVEILLANCE HOLDING SA /FI",
        "value": "SGSOY"
    },
    {
        "label": "RBLX - Roblox Corp",
        "value": "RBLX"
    },
    {
        "label": "WMG - Warner Music Group Corp.",
        "value": "WMG"
    },
    {
        "label": "BBY - BEST BUY CO INC",
        "value": "BBY"
    },
    {
        "label": "J - JACOBS SOLUTIONS INC.",
        "value": "J"
    },
    {
        "label": "UMC - UNITED MICROELECTRONICS CORP",
        "value": "UMC"
    },
    {
        "label": "ATO - ATMOS ENERGY CORP",
        "value": "ATO"
    },
    {
        "label": "BMRN - BIOMARIN PHARMACEUTICAL INC",
        "value": "BMRN"
    },
    {
        "label": "PBA - PEMBINA PIPELINE CORP",
        "value": "PBA"
    },
    {
        "label": "PTC - PTC INC.",
        "value": "PTC"
    },
    {
        "label": "VTR - Ventas, Inc.",
        "value": "VTR"
    },
    {
        "label": "WLK - WESTLAKE CORP",
        "value": "WLK"
    },
    {
        "label": "BG - BUNGELTD",
        "value": "BG"
    },
    {
        "label": "IEX - IDEX CORP /DE/",
        "value": "IEX"
    },
    {
        "label": "SWKS - SKYWORKS SOLUTIONS, INC.",
        "value": "SWKS"
    },
    {
        "label": "CINF - CINCINNATI FINANCIAL CORP",
        "value": "CINF"
    },
    {
        "label": "ERIC - ERICSSON LM TELEPHONE CO",
        "value": "ERIC"
    },
    {
        "label": "CMS - CMS ENERGY CORP",
        "value": "CMS"
    },
    {
        "label": "FDS - FACTSET RESEARCH SYSTEMS INC",
        "value": "FDS"
    },
    {
        "label": "RS - RELIANCE STEEL & ALUMINUM CO",
        "value": "RS"
    },
    {
        "label": "PAYC - Paycom Software, Inc.",
        "value": "PAYC"
    },
    {
        "label": "UAL - United Airlines Holdings, Inc.",
        "value": "UAL"
    },
    {
        "label": "FOXA - Fox Corp",
        "value": "FOXA"
    },
    {
        "label": "HBAN - HUNTINGTON BANCSHARES INC /MD/",
        "value": "HBAN"
    },
    {
        "label": "HUBB - HUBBELL INC",
        "value": "HUBB"
    },
    {
        "label": "WAT - WATERS CORP /DE/",
        "value": "WAT"
    },
    {
        "label": "JRONY - Jeronimo Martins SGPS SA",
        "value": "JRONY"
    },
    {
        "label": "SPLK - SPLUNK INC",
        "value": "SPLK"
    },
    {
        "label": "IFF - INTERNATIONAL FLAVORS & FRAGRANCES INC",
        "value": "IFF"
    },
    {
        "label": "NTAP - NetApp, Inc.",
        "value": "NTAP"
    },
    {
        "label": "MRO - MARATHON OIL CORP",
        "value": "MRO"
    },
    {
        "label": "WRB - BERKLEY W R CORP",
        "value": "WRB"
    },
    {
        "label": "CHKP - CHECK POINT SOFTWARE TECHNOLOGIES LTD",
        "value": "CHKP"
    },
    {
        "label": "EBR - BRAZILIAN ELECTRIC POWER CO",
        "value": "EBR"
    },
    {
        "label": "ESS - ESSEX PROPERTY TRUST, INC.",
        "value": "ESS"
    },
    {
        "label": "NTRS - NORTHERN TRUST CORP",
        "value": "NTRS"
    },
    {
        "label": "CBOE - Cboe Global Markets, Inc.",
        "value": "CBOE"
    },
    {
        "label": "EXPE - Expedia Group, Inc.",
        "value": "EXPE"
    },
    {
        "label": "MGA - MAGNA INTERNATIONAL INC",
        "value": "MGA"
    },
    {
        "label": "ASX - ASE Technology Holding Co., Ltd.",
        "value": "ASX"
    },
    {
        "label": "SUI - SUN COMMUNITIES INC",
        "value": "SUI"
    },
    {
        "label": "TYL - TYLER TECHNOLOGIES INC",
        "value": "TYL"
    },
    {
        "label": "HDELY - HeidelbergCement AG",
        "value": "HDELY"
    },
    {
        "label": "TER - TERADYNE, INC",
        "value": "TER"
    },
    {
        "label": "OMC - OMNICOM GROUP INC.",
        "value": "OMC"
    },
    {
        "label": "MGM - MGM Resorts International",
        "value": "MGM"
    },
    {
        "label": "EQT - EQT Corp",
        "value": "EQT"
    },
    {
        "label": "OWL - BLUE OWL CAPITAL INC.",
        "value": "OWL"
    },
    {
        "label": "AKAM - AKAMAI TECHNOLOGIES INC",
        "value": "AKAM"
    },
    {
        "label": "CF - CF Industries Holdings, Inc.",
        "value": "CF"
    },
    {
        "label": "TXT - TEXTRON INC",
        "value": "TXT"
    },
    {
        "label": "EXAS - EXACT SCIENCES CORP",
        "value": "EXAS"
    },
    {
        "label": "BAH - Booz Allen Hamilton Holding Corp",
        "value": "BAH"
    },
    {
        "label": "EG - EVEREST GROUP, LTD.",
        "value": "EG"
    },
    {
        "label": "TRU - TransUnion",
        "value": "TRU"
    },
    {
        "label": "ERIE - ERIE INDEMNITY CO",
        "value": "ERIE"
    },
    {
        "label": "DGX - QUEST DIAGNOSTICS INC",
        "value": "DGX"
    },
    {
        "label": "CCJ - CAMECO CORP",
        "value": "CCJ"
    },
    {
        "label": "HTHT - H World Group Ltd",
        "value": "HTHT"
    },
    {
        "label": "KB - KB Financial Group Inc.",
        "value": "KB"
    },
    {
        "label": "TPL - Texas Pacific Land Corp",
        "value": "TPL"
    },
    {
        "label": "XPEV - XPENG INC.",
        "value": "XPEV"
    },
    {
        "label": "AXON - AXON ENTERPRISE, INC.",
        "value": "AXON"
    },
    {
        "label": "SNAP - Snap Inc",
        "value": "SNAP"
    },
    {
        "label": "AER - AerCap Holdings N.V.",
        "value": "AER"
    },
    {
        "label": "AMH - American Homes 4 Rent",
        "value": "AMH"
    },
    {
        "label": "BIP - Brookfield Infrastructure Partners L.P.",
        "value": "BIP"
    },
    {
        "label": "DECK - DECKERS OUTDOOR CORP",
        "value": "DECK"
    },
    {
        "label": "PODD - INSULET CORP",
        "value": "PODD"
    },
    {
        "label": "RVTY - REVVITY, INC.",
        "value": "RVTY"
    },
    {
        "label": "AMCR - Amcor plc",
        "value": "AMCR"
    },
    {
        "label": "INCY - INCYTE CORP",
        "value": "INCY"
    },
    {
        "label": "AVY - Avery Dennison Corp",
        "value": "AVY"
    },
    {
        "label": "SJM - J M SMUCKER Co",
        "value": "SJM"
    },
    {
        "label": "CAG - CONAGRA BRANDS INC.",
        "value": "CAG"
    },
    {
        "label": "FMS - Fresenius Medical Care AG & Co. KGaA",
        "value": "FMS"
    },
    {
        "label": "LCID - Lucid Group, Inc.",
        "value": "LCID"
    },
    {
        "label": "VIV - TELEFONICA BRASIL S.A.",
        "value": "VIV"
    },
    {
        "label": "SNA - Snap-on Inc",
        "value": "SNA"
    },
    {
        "label": "ENTG - ENTEGRIS INC",
        "value": "ENTG"
    },
    {
        "label": "AVTR - Avantor, Inc.",
        "value": "AVTR"
    },
    {
        "label": "L - LOEWS CORP",
        "value": "L"
    },
    {
        "label": "SMPNY - Sompo Japan Nipponkoa Holdings, Inc./ADR",
        "value": "SMPNY"
    },
    {
        "label": "LKQ - LKQ CORP",
        "value": "LKQ"
    },
    {
        "label": "KKPNY - KONINKLIJKE KPN N V",
        "value": "KKPNY"
    },
    {
        "label": "CSL - CARLISLE COMPANIES INC",
        "value": "CSL"
    },
    {
        "label": "SWK - STANLEY BLACK & DECKER, INC.",
        "value": "SWK"
    },
    {
        "label": "POOL - POOL CORP",
        "value": "POOL"
    },
    {
        "label": "SYF - Synchrony Financial",
        "value": "SYF"
    },
    {
        "label": "BAM - Brookfield Asset Management Ltd.",
        "value": "BAM"
    },
    {
        "label": "LW - Lamb Weston Holdings, Inc.",
        "value": "LW"
    },
    {
        "label": "WPC - W. P. Carey Inc.",
        "value": "WPC"
    },
    {
        "label": "MGDDY - MICHELIN COMPAGNIE GENERALE DES ETABLISSEMENTS MICHELIN /FI",
        "value": "MGDDY"
    },
    {
        "label": "JBL - JABIL INC",
        "value": "JBL"
    },
    {
        "label": "ZBRA - ZEBRA TECHNOLOGIES CORP",
        "value": "ZBRA"
    },
    {
        "label": "SSNC - SS&C Technologies Holdings Inc",
        "value": "SSNC"
    },
    {
        "label": "EPAM - EPAM Systems, Inc.",
        "value": "EPAM"
    },
    {
        "label": "APA - APA Corp",
        "value": "APA"
    },
    {
        "label": "DPZ - DOMINOS PIZZA INC",
        "value": "DPZ"
    },
    {
        "label": "STX - Seagate Technology Holdings plc",
        "value": "STX"
    },
    {
        "label": "VTRS - Viatris Inc",
        "value": "VTRS"
    },
    {
        "label": "TAP - MOLSON COORS BEVERAGE CO",
        "value": "TAP"
    },
    {
        "label": "LNNGY - Li Ning Co. Ltd.",
        "value": "LNNGY"
    },
    {
        "label": "BSY - BENTLEY SYSTEMS INC",
        "value": "BSY"
    },
    {
        "label": "LBRDA - Liberty Broadband Corp",
        "value": "LBRDA"
    },
    {
        "label": "SHG - SHINHAN FINANCIAL GROUP CO LTD",
        "value": "SHG"
    },
    {
        "label": "DT - Dynatrace, Inc.",
        "value": "DT"
    },
    {
        "label": "NDSN - NORDSON CORP",
        "value": "NDSN"
    },
    {
        "label": "CFG - CITIZENS FINANCIAL GROUP INC/RI",
        "value": "CFG"
    },
    {
        "label": "HLDCY - HENDERSON LAND DEVELOPMENT COMPANY LTD /FI",
        "value": "HLDCY"
    },
    {
        "label": "AGR - Avangrid, Inc.",
        "value": "AGR"
    },
    {
        "label": "TRMB - TRIMBLE INC.",
        "value": "TRMB"
    },
    {
        "label": "JHX - James Hardie Industries plc",
        "value": "JHX"
    },
    {
        "label": "MMP - Magellan Midstream Partners, L.P.",
        "value": "MMP"
    },
    {
        "label": "LDOS - Leidos Holdings, Inc.",
        "value": "LDOS"
    },
    {
        "label": "OVV - Ovintiv Inc.",
        "value": "OVV"
    },
    {
        "label": "WSO - WATSCO INC",
        "value": "WSO"
    },
    {
        "label": "PKG - PACKAGING CORP OF AMERICA",
        "value": "PKG"
    },
    {
        "label": "TKHVY - Turk Hava Yollari A.O.",
        "value": "TKHVY"
    },
    {
        "label": "CELH - Celsius Holdings, Inc.",
        "value": "CELH"
    },
    {
        "label": "APP - AppLovin Corp",
        "value": "APP"
    },
    {
        "label": "BEN - FRANKLIN RESOURCES INC",
        "value": "BEN"
    },
    {
        "label": "RPRX - Royalty Pharma plc",
        "value": "RPRX"
    },
    {
        "label": "KMX - CARMAX INC",
        "value": "KMX"
    },
    {
        "label": "MOS - MOSAIC CO",
        "value": "MOS"
    },
    {
        "label": "RPM - RPM INTERNATIONAL INC/DE/",
        "value": "RPM"
    },
    {
        "label": "ELS - EQUITY LIFESTYLE PROPERTIES INC",
        "value": "ELS"
    },
    {
        "label": "PTCAY - PT Chandra Asri Petrochemical Tbk/ADR",
        "value": "PTCAY"
    },
    {
        "label": "SUZ - Suzano S.A.",
        "value": "SUZ"
    },
    {
        "label": "XP - XP Inc.",
        "value": "XP"
    },
    {
        "label": "EVRG - Evergy, Inc.",
        "value": "EVRG"
    },
    {
        "label": "GEN - Gen Digital Inc.",
        "value": "GEN"
    },
    {
        "label": "WDC - WESTERN DIGITAL CORP",
        "value": "WDC"
    },
    {
        "label": "SMCI - Super Micro Computer, Inc.",
        "value": "SMCI"
    },
    {
        "label": "U - Unity Software Inc.",
        "value": "U"
    },
    {
        "label": "CE - Celanese Corp",
        "value": "CE"
    },
    {
        "label": "TME - Tencent Music Entertainment Group",
        "value": "TME"
    },
    {
        "label": "MAS - MASCO CORP /DE/",
        "value": "MAS"
    },
    {
        "label": "BOUYY - Bouygues SA",
        "value": "BOUYY"
    },
    {
        "label": "TECH - BIO-TECHNE Corp",
        "value": "TECH"
    },
    {
        "label": "CPB - CAMPBELL SOUP CO",
        "value": "CPB"
    },
    {
        "label": "GGG - GRACO INC",
        "value": "GGG"
    },
    {
        "label": "LII - LENNOX INTERNATIONAL INC",
        "value": "LII"
    },
    {
        "label": "IHG - INTERCONTINENTAL HOTELS GROUP PLC /NEW/",
        "value": "IHG"
    },
    {
        "label": "GRAB - Grab Holdings Ltd",
        "value": "GRAB"
    },
    {
        "label": "VRT - Vertiv Holdings Co",
        "value": "VRT"
    },
    {
        "label": "LNT - ALLIANT ENERGY CORP",
        "value": "LNT"
    },
    {
        "label": "SGIOY - SHIONOGI & CO LTD",
        "value": "SGIOY"
    },
    {
        "label": "ACI - Albertsons Companies, Inc.",
        "value": "ACI"
    },
    {
        "label": "DKS - DICK'S SPORTING GOODS, INC.",
        "value": "DKS"
    },
    {
        "label": "NICE - NICE Ltd.",
        "value": "NICE"
    },
    {
        "label": "MTCH - Match Group, Inc.",
        "value": "MTCH"
    },
    {
        "label": "AZPN - Aspen Technology, Inc.",
        "value": "AZPN"
    },
    {
        "label": "IPG - INTERPUBLIC GROUP OF COMPANIES, INC.",
        "value": "IPG"
    },
    {
        "label": "DKNG - DraftKings Inc.",
        "value": "DKNG"
    },
    {
        "label": "GFL - GFL Environmental Inc.",
        "value": "GFL"
    },
    {
        "label": "OC - Owens Corning",
        "value": "OC"
    },
    {
        "label": "IOT - Samsara Inc.",
        "value": "IOT"
    },
    {
        "label": "LEGN - Legend Biotech Corp",
        "value": "LEGN"
    },
    {
        "label": "TOST - Toast, Inc.",
        "value": "TOST"
    },
    {
        "label": "AES - AES CORP",
        "value": "AES"
    },
    {
        "label": "ACM - AECOM",
        "value": "ACM"
    },
    {
        "label": "GLPI - Gaming & Leisure Properties, Inc.",
        "value": "GLPI"
    },
    {
        "label": "SNN - SMITH & NEPHEW PLC",
        "value": "SNN"
    },
    {
        "label": "H - Hyatt Hotels Corp",
        "value": "H"
    },
    {
        "label": "NWSA - NEWS CORP",
        "value": "NWSA"
    },
    {
        "label": "LSCC - LATTICE SEMICONDUCTOR CORP",
        "value": "LSCC"
    },
    {
        "label": "RDY - DR REDDYS LABORATORIES LTD",
        "value": "RDY"
    },
    {
        "label": "IP - INTERNATIONAL PAPER CO /NEW/",
        "value": "IP"
    },
    {
        "label": "CHWY - Chewy, Inc.",
        "value": "CHWY"
    },
    {
        "label": "KIM - KIMCO REALTY CORP",
        "value": "KIM"
    },
    {
        "label": "ZG - ZILLOW GROUP, INC.",
        "value": "ZG"
    },
    {
        "label": "LNVGY - LENOVO GROUP LTD",
        "value": "LNVGY"
    },
    {
        "label": "YPF - YPF SOCIEDAD ANONIMA",
        "value": "YPF"
    },
    {
        "label": "BKI - Black Knight, Inc.",
        "value": "BKI"
    },
    {
        "label": "PAG - PENSKE AUTOMOTIVE GROUP, INC.",
        "value": "PAG"
    },
    {
        "label": "RYAN - RYAN SPECIALTY HOLDINGS, INC.",
        "value": "RYAN"
    },
    {
        "label": "CHK - CHESAPEAKE ENERGY CORP",
        "value": "CHK"
    },
    {
        "label": "OKTA - Okta, Inc.",
        "value": "OKTA"
    },
    {
        "label": "MANH - MANHATTAN ASSOCIATES INC",
        "value": "MANH"
    },
    {
        "label": "CPT - CAMDEN PROPERTY TRUST",
        "value": "CPT"
    },
    {
        "label": "CRBG - Corebridge Financial, Inc.",
        "value": "CRBG"
    },
    {
        "label": "CX - CEMEX SAB DE CV",
        "value": "CX"
    },
    {
        "label": "FLEX - FLEX LTD.",
        "value": "FLEX"
    },
    {
        "label": "CZR - Caesars Entertainment, Inc.",
        "value": "CZR"
    },
    {
        "label": "HST - HOST HOTELS & RESORTS, INC.",
        "value": "HST"
    },
    {
        "label": "PSTG - Pure Storage, Inc.",
        "value": "PSTG"
    },
    {
        "label": "JKHY - JACK HENRY & ASSOCIATES INC",
        "value": "JKHY"
    },
    {
        "label": "TFII - TFI International Inc.",
        "value": "TFII"
    },
    {
        "label": "SMKUY - Siam Makro Public Co Limited/ADR",
        "value": "SMKUY"
    },
    {
        "label": "PEAK - HEALTHPEAK PROPERTIES, INC.",
        "value": "PEAK"
    },
    {
        "label": "NMR - NOMURA HOLDINGS INC",
        "value": "NMR"
    },
    {
        "label": "PTBRY - PT Bank Negara Indonesia (Persero) Tbk/ADR",
        "value": "PTBRY"
    },
    {
        "label": "BURL - Burlington Stores, Inc.",
        "value": "BURL"
    },
    {
        "label": "FNF - Fidelity National Financial, Inc.",
        "value": "FNF"
    },
    {
        "label": "BAP - CREDICORP LTD",
        "value": "BAP"
    },
    {
        "label": "FMC - FMC CORP",
        "value": "FMC"
    },
    {
        "label": "VST - Vistra Corp.",
        "value": "VST"
    },
    {
        "label": "BIO - BIO-RAD LABORATORIES, INC.",
        "value": "BIO"
    },
    {
        "label": "PNR - PENTAIR plc",
        "value": "PNR"
    },
    {
        "label": "ROKU - ROKU, INC",
        "value": "ROKU"
    },
    {
        "label": "DINO - HF Sinclair Corp",
        "value": "DINO"
    },
    {
        "label": "BCH - BANK OF CHILE",
        "value": "BCH"
    },
    {
        "label": "BILL - BILL Holdings, Inc.",
        "value": "BILL"
    },
    {
        "label": "TEVA - TEVA PHARMACEUTICAL INDUSTRIES LTD",
        "value": "TEVA"
    },
    {
        "label": "CHRW - C. H. ROBINSON WORLDWIDE, INC.",
        "value": "CHRW"
    },
    {
        "label": "SAIA - SAIA INC",
        "value": "SAIA"
    },
    {
        "label": "GDDY - GoDaddy Inc.",
        "value": "GDDY"
    },
    {
        "label": "REXR - Rexford Industrial Realty, Inc.",
        "value": "REXR"
    },
    {
        "label": "GLPEY - Galp Energia, SGPS, S.A.",
        "value": "GLPEY"
    },
    {
        "label": "NI - NISOURCE INC.",
        "value": "NI"
    },
    {
        "label": "UHAL - U-Haul Holding Co /NV/",
        "value": "UHAL"
    },
    {
        "label": "WYNN - WYNN RESORTS LTD",
        "value": "WYNN"
    },
    {
        "label": "GL - GLOBE LIFE INC.",
        "value": "GL"
    },
    {
        "label": "PCTY - Paylocity Holding Corp",
        "value": "PCTY"
    },
    {
        "label": "CNA - CNA FINANCIAL CORP",
        "value": "CNA"
    },
    {
        "label": "CDAY - Ceridian HCM Holding Inc.",
        "value": "CDAY"
    },
    {
        "label": "TWLO - TWILIO INC",
        "value": "TWLO"
    },
    {
        "label": "LOGI - LOGITECH INTERNATIONAL S.A.",
        "value": "LOGI"
    },
    {
        "label": "UTHR - UNITED THERAPEUTICS Corp",
        "value": "UTHR"
    },
    {
        "label": "LECO - LINCOLN ELECTRIC HOLDINGS INC",
        "value": "LECO"
    },
    {
        "label": "FIVE - FIVE BELOW, INC",
        "value": "FIVE"
    },
    {
        "label": "CG - Carlyle Group Inc.",
        "value": "CG"
    },
    {
        "label": "CCK - CROWN HOLDINGS INC",
        "value": "CCK"
    },
    {
        "label": "GFI - GOLD FIELDS LTD",
        "value": "GFI"
    },
    {
        "label": "AOS - SMITH A O CORP",
        "value": "AOS"
    },
    {
        "label": "REG - REGENCY CENTERS CORP",
        "value": "REG"
    },
    {
        "label": "PAA - PLAINS ALL AMERICAN PIPELINE LP",
        "value": "PAA"
    },
    {
        "label": "ARCC - ARES CAPITAL CORP",
        "value": "ARCC"
    },
    {
        "label": "TFX - TELEFLEX INC",
        "value": "TFX"
    },
    {
        "label": "FND - Floor & Decor Holdings, Inc.",
        "value": "FND"
    },
    {
        "label": "SRPT - Sarepta Therapeutics, Inc.",
        "value": "SRPT"
    },
    {
        "label": "WES - Western Midstream Partners, LP",
        "value": "WES"
    },
    {
        "label": "DOX - AMDOCS LTD",
        "value": "DOX"
    },
    {
        "label": "TTC - TORO CO",
        "value": "TTC"
    },
    {
        "label": "EMRAF - EMERA INC",
        "value": "EMRAF"
    },
    {
        "label": "CRL - CHARLES RIVER LABORATORIES INTERNATIONAL, INC.",
        "value": "CRL"
    },
    {
        "label": "NBIX - NEUROCRINE BIOSCIENCES INC",
        "value": "NBIX"
    },
    {
        "label": "RBA - RB GLOBAL INC.",
        "value": "RBA"
    },
    {
        "label": "WPP - WPP plc",
        "value": "WPP"
    },
    {
        "label": "EME - EMCOR Group, Inc.",
        "value": "EME"
    },
    {
        "label": "KEY - KEYCORP /NEW/",
        "value": "KEY"
    },
    {
        "label": "HSIC - HENRY SCHEIN INC",
        "value": "HSIC"
    },
    {
        "label": "DAR - DARLING INGREDIENTS INC.",
        "value": "DAR"
    },
    {
        "label": "OTEX - OPEN TEXT CORP",
        "value": "OTEX"
    },
    {
        "label": "RRX - REGAL REXNORD CORP",
        "value": "RRX"
    },
    {
        "label": "BXP - BOSTON PROPERTIES INC",
        "value": "BXP"
    },
    {
        "label": "WTRG - Essential Utilities, Inc.",
        "value": "WTRG"
    },
    {
        "label": "WMS - ADVANCED DRAINAGE SYSTEMS, INC.",
        "value": "WMS"
    },
    {
        "label": "EMN - EASTMAN CHEMICAL CO",
        "value": "EMN"
    },
    {
        "label": "AAL - American Airlines Group Inc.",
        "value": "AAL"
    },
    {
        "label": "PEN - Penumbra Inc",
        "value": "PEN"
    },
    {
        "label": "PFGC - Performance Food Group Co",
        "value": "PFGC"
    },
    {
        "label": "AEG - AEGON NV",
        "value": "AEG"
    },
    {
        "label": "EQH - Equitable Holdings, Inc.",
        "value": "EQH"
    },
    {
        "label": "QGEN - QIAGEN N.V.",
        "value": "QGEN"
    },
    {
        "label": "DOCU - DOCUSIGN, INC.",
        "value": "DOCU"
    },
    {
        "label": "PARA - Paramount Global",
        "value": "PARA"
    },
    {
        "label": "AFG - AMERICAN FINANCIAL GROUP INC",
        "value": "AFG"
    },
    {
        "label": "ONON - On Holding AG",
        "value": "ONON"
    },
    {
        "label": "UNM - Unum Group",
        "value": "UNM"
    },
    {
        "label": "QRVO - Qorvo, Inc.",
        "value": "QRVO"
    },
    {
        "label": "ACCYY - ACCOR",
        "value": "ACCYY"
    },
    {
        "label": "COTY - COTY INC.",
        "value": "COTY"
    },
    {
        "label": "ARMK - Aramark",
        "value": "ARMK"
    },
    {
        "label": "UI - Ubiquiti Inc.",
        "value": "UI"
    },
    {
        "label": "CFLT - Confluent, Inc.",
        "value": "CFLT"
    },
    {
        "label": "SCI - SERVICE CORP INTERNATIONAL",
        "value": "SCI"
    },
    {
        "label": "FFIV - F5, INC.",
        "value": "FFIV"
    },
    {
        "label": "NLY - ANNALY CAPITAL MANAGEMENT INC",
        "value": "NLY"
    },
    {
        "label": "MORN - Morningstar, Inc.",
        "value": "MORN"
    },
    {
        "label": "DBX - DROPBOX, INC.",
        "value": "DBX"
    },
    {
        "label": "USFD - US Foods Holding Corp.",
        "value": "USFD"
    },
    {
        "label": "MKTX - MARKETAXESS HOLDINGS INC",
        "value": "MKTX"
    },
    {
        "label": "ALLE - Allegion plc",
        "value": "ALLE"
    },
    {
        "label": "CUBE - CubeSmart",
        "value": "CUBE"
    },
    {
        "label": "BWA - BORGWARNER INC",
        "value": "BWA"
    },
    {
        "label": "SEDG - SOLAREDGE TECHNOLOGIES, INC.",
        "value": "SEDG"
    },
    {
        "label": "ETSY - ETSY INC",
        "value": "ETSY"
    },
    {
        "label": "BJ - BJ's Wholesale Club Holdings, Inc.",
        "value": "BJ"
    },
    {
        "label": "BPYPP - Brookfield Property Partners L.P.",
        "value": "BPYPP"
    },
    {
        "label": "BRKR - BRUKER CORP",
        "value": "BRKR"
    },
    {
        "label": "RGA - REINSURANCE GROUP OF AMERICA INC",
        "value": "RGA"
    },
    {
        "label": "CASY - CASEYS GENERAL STORES INC",
        "value": "CASY"
    },
    {
        "label": "DVA - DAVITA INC.",
        "value": "DVA"
    },
    {
        "label": "ESLT - ELBIT SYSTEMS LTD",
        "value": "ESLT"
    },
    {
        "label": "OMRNY - OMRON CORP /FI",
        "value": "OMRNY"
    },
    {
        "label": "SNX - TD SYNNEX CORP",
        "value": "SNX"
    },
    {
        "label": "RNR - RENAISSANCERE HOLDINGS LTD",
        "value": "RNR"
    },
    {
        "label": "CLH - CLEAN HARBORS INC",
        "value": "CLH"
    },
    {
        "label": "VIPS - Vipshop Holdings Ltd",
        "value": "VIPS"
    },
    {
        "label": "HOOD - Robinhood Markets, Inc.",
        "value": "HOOD"
    },
    {
        "label": "CHDN - Churchill Downs Inc",
        "value": "CHDN"
    },
    {
        "label": "KNX - Knight-Swift Transportation Holdings Inc.",
        "value": "KNX"
    },
    {
        "label": "VIVHY - VIVENDI",
        "value": "VIVHY"
    },
    {
        "label": "RGEN - REPLIGEN CORP",
        "value": "RGEN"
    },
    {
        "label": "AGCO - AGCO CORP /DE",
        "value": "AGCO"
    },
    {
        "label": "UHS - UNIVERSAL HEALTH SERVICES INC",
        "value": "UHS"
    },
    {
        "label": "OUKPY - Outotec OYJ",
        "value": "OUKPY"
    },
    {
        "label": "BLD - TopBuild Corp",
        "value": "BLD"
    },
    {
        "label": "JNPR - JUNIPER NETWORKS INC",
        "value": "JNPR"
    },
    {
        "label": "PAC - Pacific Airport Group",
        "value": "PAC"
    },
    {
        "label": "GGB - GERDAU S.A.",
        "value": "GGB"
    },
    {
        "label": "EDU - New Oriental Education & Technology Group Inc.",
        "value": "EDU"
    },
    {
        "label": "HAS - HASBRO, INC.",
        "value": "HAS"
    },
    {
        "label": "JAZZ - Jazz Pharmaceuticals plc",
        "value": "JAZZ"
    },
    {
        "label": "HII - HUNTINGTON INGALLS INDUSTRIES, INC.",
        "value": "HII"
    },
    {
        "label": "CAR - AVIS BUDGET GROUP, INC.",
        "value": "CAR"
    },
    {
        "label": "GRFS - Grifols SA",
        "value": "GRFS"
    },
    {
        "label": "PCOR - PROCORE TECHNOLOGIES, INC.",
        "value": "PCOR"
    },
    {
        "label": "LAMR - LAMAR ADVERTISING CO/NEW",
        "value": "LAMR"
    },
    {
        "label": "PNW - PINNACLE WEST CAPITAL CORP",
        "value": "PNW"
    },
    {
        "label": "KNSL - Kinsale Capital Group, Inc.",
        "value": "KNSL"
    },
    {
        "label": "BSAC - BANCO SANTANDER CHILE",
        "value": "BSAC"
    },
    {
        "label": "JBSAY - JBS S.A.",
        "value": "JBSAY"
    },
    {
        "label": "MTN - VAIL RESORTS INC",
        "value": "MTN"
    },
    {
        "label": "USO - United States Oil Fund, LP",
        "value": "USO"
    },
    {
        "label": "KEP - KOREA ELECTRIC POWER CORP",
        "value": "KEP"
    },
    {
        "label": "NYCB - NEW YORK COMMUNITY BANCORP INC",
        "value": "NYCB"
    },
    {
        "label": "FBIN - Fortune Brands Innovations, Inc.",
        "value": "FBIN"
    },
    {
        "label": "NVT - nVent Electric plc",
        "value": "NVT"
    },
    {
        "label": "COLD - AMERICOLD REALTY TRUST",
        "value": "COLD"
    },
    {
        "label": "BBWI - Bath & Body Works, Inc.",
        "value": "BBWI"
    },
    {
        "label": "NRG - NRG ENERGY, INC.",
        "value": "NRG"
    },
    {
        "label": "MTLHY - Mitsubishi Chemical Holdings Corp",
        "value": "MTLHY"
    },
    {
        "label": "WSM - WILLIAMS SONOMA INC",
        "value": "WSM"
    },
    {
        "label": "TOL - Toll Brothers, Inc.",
        "value": "TOL"
    },
    {
        "label": "TTEK - TETRA TECH INC",
        "value": "TTEK"
    },
    {
        "label": "LKNCY - Luckin Coffee Inc.",
        "value": "LKNCY"
    },
    {
        "label": "LEA - LEAR CORP",
        "value": "LEA"
    },
    {
        "label": "WRK - WestRock Co",
        "value": "WRK"
    },
    {
        "label": "AR - ANTERO RESOURCES Corp",
        "value": "AR"
    },
    {
        "label": "STVN - Stevanato Group S.p.A.",
        "value": "STVN"
    },
    {
        "label": "SSL - SASOL LTD",
        "value": "SSL"
    },
    {
        "label": "WWE - WORLD WRESTLING ENTERTAINMENTINC",
        "value": "WWE"
    },
    {
        "label": "ASR - SOUTHEAST AIRPORT GROUP",
        "value": "ASR"
    },
    {
        "label": "LAD - LITHIA MOTORS INC",
        "value": "LAD"
    },
    {
        "label": "IEP - ICAHN ENTERPRISES L.P.",
        "value": "IEP"
    },
    {
        "label": "WSC - WillScot Mobile Mini Holdings Corp.",
        "value": "WSC"
    },
    {
        "label": "PATH - UiPath, Inc.",
        "value": "PATH"
    },
    {
        "label": "LBTYA - Liberty Global plc",
        "value": "LBTYA"
    },
    {
        "label": "TPG - TPG Inc.",
        "value": "TPG"
    },
    {
        "label": "XPO - XPO, Inc.",
        "value": "XPO"
    },
    {
        "label": "SEIC - SEI INVESTMENTS CO",
        "value": "SEIC"
    },
    {
        "label": "ALLY - Ally Financial Inc.",
        "value": "ALLY"
    },
    {
        "label": "ROHCY - ROHM Co., Ltd.",
        "value": "ROHCY"
    },
    {
        "label": "ROIV - Roivant Sciences Ltd.",
        "value": "ROIV"
    },
    {
        "label": "SWAV - Shockwave Medical, Inc.",
        "value": "SWAV"
    },
    {
        "label": "CGNX - COGNEX CORP",
        "value": "CGNX"
    },
    {
        "label": "KBR - KBR, INC.",
        "value": "KBR"
    },
    {
        "label": "W - Wayfair Inc.",
        "value": "W"
    },
    {
        "label": "HNGKY - HONGKONG LAND HOLDINGS LTD /FI",
        "value": "HNGKY"
    },
    {
        "label": "ATR - APTARGROUP, INC.",
        "value": "ATR"
    },
    {
        "label": "CTLT - Catalent, Inc.",
        "value": "CTLT"
    },
    {
        "label": "NOV - NOV Inc.",
        "value": "NOV"
    },
    {
        "label": "RHI - ROBERT HALF INC.",
        "value": "RHI"
    },
    {
        "label": "FTI - TechnipFMC plc",
        "value": "FTI"
    },
    {
        "label": "TPR - TAPESTRY, INC.",
        "value": "TPR"
    },
    {
        "label": "SKX - SKECHERS USA INC",
        "value": "SKX"
    },
    {
        "label": "EGP - EASTGROUP PROPERTIES INC",
        "value": "EGP"
    },
    {
        "label": "ZI - ZoomInfo Technologies Inc.",
        "value": "ZI"
    },
    {
        "label": "SBS - COMPANHIA DE SANEAMENTO BASICO DO ESTADO DE SAO PAULO-SABESP",
        "value": "SBS"
    },
    {
        "label": "FRT - FEDERAL REALTY INVESTMENT TRUST",
        "value": "FRT"
    },
    {
        "label": "ORI - OLD REPUBLIC INTERNATIONAL CORP",
        "value": "ORI"
    },
    {
        "label": "PSNY - Polestar Automotive Holding UK PLC",
        "value": "PSNY"
    },
    {
        "label": "VFC - V F CORP",
        "value": "VFC"
    },
    {
        "label": "XRAY - DENTSPLY SIRONA Inc.",
        "value": "XRAY"
    },
    {
        "label": "WEX - WEX Inc.",
        "value": "WEX"
    },
    {
        "label": "ALV - AUTOLIV INC",
        "value": "ALV"
    },
    {
        "label": "ICL - ICL Group Ltd.",
        "value": "ICL"
    },
    {
        "label": "ITT - ITT INC.",
        "value": "ITT"
    },
    {
        "label": "MNDY - monday.com Ltd.",
        "value": "MNDY"
    },
    {
        "label": "RRC - RANGE RESOURCES CORP",
        "value": "RRC"
    },
    {
        "label": "NATI - NATIONAL INSTRUMENTS CORP",
        "value": "NATI"
    },
    {
        "label": "JLL - JONES LANG LASALLE INC",
        "value": "JLL"
    },
    {
        "label": "EWBC - EAST WEST BANCORP INC",
        "value": "EWBC"
    },
    {
        "label": "TX - Ternium S.A.",
        "value": "TX"
    },
    {
        "label": "DSEEY - Daiwa Securities Group Inc.",
        "value": "DSEEY"
    },
    {
        "label": "WCC - WESCO INTERNATIONAL INC",
        "value": "WCC"
    },
    {
        "label": "SOFI - SoFi Technologies, Inc.",
        "value": "SOFI"
    },
    {
        "label": "MEDP - Medpace Holdings, Inc.",
        "value": "MEDP"
    },
    {
        "label": "SKM - SK TELECOM CO LTD",
        "value": "SKM"
    },
    {
        "label": "CW - CURTISS WRIGHT CORP",
        "value": "CW"
    },
    {
        "label": "MIDD - MIDDLEBY Corp",
        "value": "MIDD"
    },
    {
        "label": "CLF - CLEVELAND-CLIFFS INC.",
        "value": "CLF"
    },
    {
        "label": "LSXMA - Liberty Media Corp",
        "value": "LSXMA"
    },
    {
        "label": "BERY - BERRY GLOBAL GROUP, INC.",
        "value": "BERY"
    },
    {
        "label": "RL - RALPH LAUREN CORP",
        "value": "RL"
    },
    {
        "label": "CHE - CHEMED CORP",
        "value": "CHE"
    },
    {
        "label": "CAE - CAE INC",
        "value": "CAE"
    },
    {
        "label": "UELMO - UNION ELECTRIC CO",
        "value": "UELMO"
    },
    {
        "label": "AIZ - ASSURANT, INC.",
        "value": "AIZ"
    },
    {
        "label": "TREX - TREX CO INC",
        "value": "TREX"
    },
    {
        "label": "WWD - Woodward, Inc.",
        "value": "WWD"
    },
    {
        "label": "TPX - TEMPUR SEALY INTERNATIONAL, INC.",
        "value": "TPX"
    },
    {
        "label": "GNTX - GENTEX CORP",
        "value": "GNTX"
    },
    {
        "label": "JEF - Jefferies Financial Group Inc.",
        "value": "JEF"
    },
    {
        "label": "ASXFY - ASX Ltd.",
        "value": "ASXFY"
    },
    {
        "label": "CACI - CACI INTERNATIONAL INC /DE/",
        "value": "CACI"
    },
    {
        "label": "PSO - PEARSON PLC",
        "value": "PSO"
    },
    {
        "label": "DCI - DONALDSON Co INC",
        "value": "DCI"
    },
    {
        "label": "MAT - MATTEL INC /DE/",
        "value": "MAT"
    },
    {
        "label": "ARW - ARROW ELECTRONICS, INC.",
        "value": "ARW"
    },
    {
        "label": "CMS-PB - CONSUMERS ENERGY CO",
        "value": "CMS-PB"
    },
    {
        "label": "PRI - Primerica, Inc.",
        "value": "PRI"
    },
    {
        "label": "WHR - WHIRLPOOL CORP /DE/",
        "value": "WHR"
    },
    {
        "label": "OHI - OMEGA HEALTHCARE INVESTORS INC",
        "value": "OHI"
    },
    {
        "label": "GXO - GXO Logistics, Inc.",
        "value": "GXO"
    },
    {
        "label": "DLB - Dolby Laboratories, Inc.",
        "value": "DLB"
    },
    {
        "label": "STN - STANTEC INC",
        "value": "STN"
    },
    {
        "label": "SWN - SOUTHWESTERN ENERGY CO",
        "value": "SWN"
    },
    {
        "label": "MTZ - MASTEC INC",
        "value": "MTZ"
    },
    {
        "label": "AGL - agilon health, inc.",
        "value": "AGL"
    },
    {
        "label": "EDR - Endeavor Group Holdings, Inc.",
        "value": "EDR"
    },
    {
        "label": "OLN - OLIN Corp",
        "value": "OLN"
    },
    {
        "label": "MTDR - Matador Resources Co",
        "value": "MTDR"
    },
    {
        "label": "ALGM - ALLEGRO MICROSYSTEMS, INC.",
        "value": "ALGM"
    },
    {
        "label": "WBS - WEBSTER FINANCIAL CORP",
        "value": "WBS"
    },
    {
        "label": "BDNNY - Boliden AB",
        "value": "BDNNY"
    },
    {
        "label": "THC - TENET HEALTHCARE CORP",
        "value": "THC"
    },
    {
        "label": "NCLH - Norwegian Cruise Line Holdings Ltd.",
        "value": "NCLH"
    },
    {
        "label": "OLED - UNIVERSAL DISPLAY CORP \\PA\\",
        "value": "OLED"
    },
    {
        "label": "TIMB - TIM S.A.",
        "value": "TIMB"
    },
    {
        "label": "NTNX - Nutanix, Inc.",
        "value": "NTNX"
    },
    {
        "label": "NE - Noble Corp plc",
        "value": "NE"
    },
    {
        "label": "HESM - Hess Midstream LP",
        "value": "HESM"
    },
    {
        "label": "CNM - Core & Main, Inc.",
        "value": "CNM"
    },
    {
        "label": "IVZ - Invesco Ltd.",
        "value": "IVZ"
    },
    {
        "label": "FHN - FIRST HORIZON CORP",
        "value": "FHN"
    },
    {
        "label": "SLV - iShares Silver Trust",
        "value": "SLV"
    },
    {
        "label": "MUR - MURPHY OIL CORP",
        "value": "MUR"
    },
    {
        "label": "PR - Permian Resources Corp",
        "value": "PR"
    },
    {
        "label": "NNN - NNN REIT, INC.",
        "value": "NNN"
    },
    {
        "label": "TXRH - Texas Roadhouse, Inc.",
        "value": "TXRH"
    },
    {
        "label": "X - UNITED STATES STEEL CORP",
        "value": "X"
    },
    {
        "label": "DUFRY - Dufry AG/ADR",
        "value": "DUFRY"
    },
    {
        "label": "RGLD - ROYAL GOLD INC",
        "value": "RGLD"
    },
    {
        "label": "EHC - Encompass Health Corp",
        "value": "EHC"
    },
    {
        "label": "GTLS - CHART INDUSTRIES INC",
        "value": "GTLS"
    },
    {
        "label": "ACHC - Acadia Healthcare Company, Inc.",
        "value": "ACHC"
    },
    {
        "label": "NYT - NEW YORK TIMES CO",
        "value": "NYT"
    },
    {
        "label": "GLOB - Globant S.A.",
        "value": "GLOB"
    },
    {
        "label": "SITE - SiteOne Landscape Supply, Inc.",
        "value": "SITE"
    },
    {
        "label": "GNRC - GENERAC HOLDINGS INC.",
        "value": "GNRC"
    },
    {
        "label": "CIB - BANCOLOMBIA SA",
        "value": "CIB"
    },
    {
        "label": "MEJHY - MEIJI Holdings Co Ltd/ADR",
        "value": "MEJHY"
    },
    {
        "label": "ITTOY - ITOCHU Techno-Solutions Corporation/ADR",
        "value": "ITTOY"
    },
    {
        "label": "FR - FIRST INDUSTRIAL REALTY TRUST INC",
        "value": "FR"
    },
    {
        "label": "EXEL - EXELIXIS, INC.",
        "value": "EXEL"
    },
    {
        "label": "VOYA - Voya Financial, Inc.",
        "value": "VOYA"
    },
    {
        "label": "CHX - ChampionX Corp",
        "value": "CHX"
    },
    {
        "label": "AU - ANGLOGOLD ASHANTI LTD",
        "value": "AU"
    },
    {
        "label": "MUSA - Murphy USA Inc.",
        "value": "MUSA"
    },
    {
        "label": "MSA - MSA Safety Inc",
        "value": "MSA"
    },
    {
        "label": "AN - AUTONATION, INC.",
        "value": "AN"
    },
    {
        "label": "GPK - GRAPHIC PACKAGING HOLDING CO",
        "value": "GPK"
    },
    {
        "label": "FUTU - Futu Holdings Ltd",
        "value": "FUTU"
    },
    {
        "label": "SMBMY - SembCorp Marine Ltd.",
        "value": "SMBMY"
    },
    {
        "label": "LSTR - LANDSTAR SYSTEM INC",
        "value": "LSTR"
    },
    {
        "label": "RCM - R1 RCM Inc. /DE",
        "value": "RCM"
    },
    {
        "label": "YNDX - Yandex N.V.",
        "value": "YNDX"
    },
    {
        "label": "HLI - HOULIHAN LOKEY, INC.",
        "value": "HLI"
    },
    {
        "label": "CHH - CHOICE HOTELS INTERNATIONAL INC /DE",
        "value": "CHH"
    },
    {
        "label": "OGE - OGE ENERGY CORP.",
        "value": "OGE"
    },
    {
        "label": "CCCS - CCC Intelligent Solutions Holdings Inc.",
        "value": "CCCS"
    },
    {
        "label": "BLCO - Bausch & Lomb Corp",
        "value": "BLCO"
    },
    {
        "label": "CSAN - Cosan S.A.",
        "value": "CSAN"
    },
    {
        "label": "LNW - Light & Wonder, Inc.",
        "value": "LNW"
    },
    {
        "label": "YMM - Full Truck Alliance Co. Ltd.",
        "value": "YMM"
    },
    {
        "label": "BZ - Kanzhun Ltd",
        "value": "BZ"
    },
    {
        "label": "GTLB - Gitlab Inc.",
        "value": "GTLB"
    },
    {
        "label": "CVNA - CARVANA CO.",
        "value": "CVNA"
    },
    {
        "label": "G - Genpact LTD",
        "value": "G"
    },
    {
        "label": "BILI - Bilibili Inc.",
        "value": "BILI"
    },
    {
        "label": "BYD - BOYD GAMING CORP",
        "value": "BYD"
    },
    {
        "label": "PUTKY - PT United Tractors Tbk / ADR",
        "value": "PUTKY"
    },
    {
        "label": "KRTX - Karuna Therapeutics, Inc.",
        "value": "KRTX"
    },
    {
        "label": "RH - RH",
        "value": "RH"
    },
    {
        "label": "MNSO - MINISO Group Holding Ltd",
        "value": "MNSO"
    },
    {
        "label": "COKE - Coca-Cola Consolidated, Inc.",
        "value": "COKE"
    },
    {
        "label": "ELF - e.l.f. Beauty, Inc.",
        "value": "ELF"
    },
    {
        "label": "BWXT - BWX Technologies, Inc.",
        "value": "BWXT"
    },
    {
        "label": "SF - STIFEL FINANCIAL CORP",
        "value": "SF"
    },
    {
        "label": "FSV - FirstService Corp",
        "value": "FSV"
    },
    {
        "label": "CMC - COMMERCIAL METALS Co",
        "value": "CMC"
    },
    {
        "label": "GWRE - Guidewire Software, Inc.",
        "value": "GWRE"
    },
    {
        "label": "INGR - Ingredion Inc",
        "value": "INGR"
    },
    {
        "label": "APG - APi Group Corp",
        "value": "APG"
    },
    {
        "label": "RBC - RBC Bearings INC",
        "value": "RBC"
    },
    {
        "label": "BRX - Brixmor Property Group Inc.",
        "value": "BRX"
    },
    {
        "label": "PII - Polaris Inc.",
        "value": "PII"
    },
    {
        "label": "OSK - OSHKOSH CORP",
        "value": "OSK"
    },
    {
        "label": "HR - Healthcare Realty Trust Inc",
        "value": "HR"
    },
    {
        "label": "CHRD - Chord Energy Corp",
        "value": "CHRD"
    },
    {
        "label": "STAG - STAG Industrial, Inc.",
        "value": "STAG"
    },
    {
        "label": "SSD - Simpson Manufacturing Co., Inc.",
        "value": "SSD"
    },
    {
        "label": "LFUS - LITTELFUSE INC /DE",
        "value": "LFUS"
    },
    {
        "label": "WH - WYNDHAM HOTELS & RESORTS, INC.",
        "value": "WH"
    },
    {
        "label": "CYBR - CyberArk Software Ltd.",
        "value": "CYBR"
    },
    {
        "label": "CIVI - CIVITAS RESOURCES, INC.",
        "value": "CIVI"
    },
    {
        "label": "WFG - WEST FRASER TIMBER CO., LTD",
        "value": "WFG"
    },
    {
        "label": "EXP - EAGLE MATERIALS INC",
        "value": "EXP"
    },
    {
        "label": "RETA - REATA PHARMACEUTICALS INC",
        "value": "RETA"
    },
    {
        "label": "FCN - FTI CONSULTING, INC",
        "value": "FCN"
    },
    {
        "label": "FAF - First American Financial Corp",
        "value": "FAF"
    },
    {
        "label": "CBSH - COMMERCE BANCSHARES INC /MO/",
        "value": "CBSH"
    },
    {
        "label": "CFR - CULLEN/FROST BANKERS, INC.",
        "value": "CFR"
    },
    {
        "label": "STWD - STARWOOD PROPERTY TRUST, INC.",
        "value": "STWD"
    },
    {
        "label": "FRSH - Freshworks Inc.",
        "value": "FRSH"
    },
    {
        "label": "SAIC - Science Applications International Corp",
        "value": "SAIC"
    },
    {
        "label": "IGT - International Game Technology PLC",
        "value": "IGT"
    },
    {
        "label": "FIX - COMFORT SYSTEMS USA INC",
        "value": "FIX"
    },
    {
        "label": "INSP - Inspire Medical Systems, Inc.",
        "value": "INSP"
    },
    {
        "label": "TNET - TRINET GROUP, INC.",
        "value": "TNET"
    },
    {
        "label": "CMA - COMERICA INC /NEW/",
        "value": "CMA"
    },
    {
        "label": "RIG - Transocean Ltd.",
        "value": "RIG"
    },
    {
        "label": "MHK - MOHAWK INDUSTRIES INC",
        "value": "MHK"
    },
    {
        "label": "CACC - CREDIT ACCEPTANCE CORP",
        "value": "CACC"
    },
    {
        "label": "IRDM - Iridium Communications Inc.",
        "value": "IRDM"
    },
    {
        "label": "OPCH - Option Care Health, Inc.",
        "value": "OPCH"
    },
    {
        "label": "WTS - WATTS WATER TECHNOLOGIES INC",
        "value": "WTS"
    },
    {
        "label": "UFPI - UFP INDUSTRIES INC",
        "value": "UFPI"
    },
    {
        "label": "AXTA - Axalta Coating Systems Ltd.",
        "value": "AXTA"
    },
    {
        "label": "AMKR - AMKOR TECHNOLOGY, INC.",
        "value": "AMKR"
    },
    {
        "label": "DOOO - BRP Inc.",
        "value": "DOOO"
    },
    {
        "label": "CIEN - CIENA CORP",
        "value": "CIEN"
    },
    {
        "label": "EGFEY - EFG Eurobank Ergasias S.A./ADR",
        "value": "EGFEY"
    },
    {
        "label": "DSGX - DESCARTES SYSTEMS GROUP INC",
        "value": "DSGX"
    },
    {
        "label": "SIGI - SELECTIVE INSURANCE GROUP INC",
        "value": "SIGI"
    },
    {
        "label": "MKSI - MKS INSTRUMENTS INC",
        "value": "MKSI"
    },
    {
        "label": "WF - WOORI FINANCIAL GROUP INC.",
        "value": "WF"
    },
    {
        "label": "CPRI - Capri Holdings Ltd",
        "value": "CPRI"
    },
    {
        "label": "GLBE - Global-E Online Ltd.",
        "value": "GLBE"
    },
    {
        "label": "PPC - PILGRIMS PRIDE CORP",
        "value": "PPC"
    },
    {
        "label": "WFRD - Weatherford International plc",
        "value": "WFRD"
    },
    {
        "label": "RLI - RLI CORP",
        "value": "RLI"
    },
    {
        "label": "SPSC - SPS COMMERCE INC",
        "value": "SPSC"
    },
    {
        "label": "LU - Lufax Holding Ltd",
        "value": "LU"
    },
    {
        "label": "KT - KT CORP",
        "value": "KT"
    },
    {
        "label": "APPF - APPFOLIO INC",
        "value": "APPF"
    },
    {
        "label": "NTRA - Natera, Inc.",
        "value": "NTRA"
    },
    {
        "label": "HRB - H&R BLOCK INC",
        "value": "HRB"
    },
    {
        "label": "PBF - PBF Energy Inc.",
        "value": "PBF"
    },
    {
        "label": "HXL - HEXCEL CORP /DE/",
        "value": "HXL"
    },
    {
        "label": "NFE - New Fortress Energy Inc.",
        "value": "NFE"
    },
    {
        "label": "BC - BRUNSWICK CORP",
        "value": "BC"
    },
    {
        "label": "NEWR - NEW RELIC, INC.",
        "value": "NEWR"
    },
    {
        "label": "ESTC - Elastic N.V.",
        "value": "ESTC"
    },
    {
        "label": "NXT - Nextracker Inc.",
        "value": "NXT"
    },
    {
        "label": "MASI - MASIMO CORP",
        "value": "MASI"
    },
    {
        "label": "REYN - Reynolds Consumer Products Inc.",
        "value": "REYN"
    },
    {
        "label": "ST - Sensata Technologies Holding plc",
        "value": "ST"
    },
    {
        "label": "PHYS - Sprott Physical Gold Trust",
        "value": "PHYS"
    },
    {
        "label": "CROX - Crocs, Inc.",
        "value": "CROX"
    },
    {
        "label": "CIG - ENERGY CO OF MINAS GERAIS",
        "value": "CIG"
    },
    {
        "label": "MGY - Magnolia Oil & Gas Corp",
        "value": "MGY"
    },
    {
        "label": "PSN - PARSONS CORP",
        "value": "PSN"
    },
    {
        "label": "ADT - ADT Inc.",
        "value": "ADT"
    },
    {
        "label": "ADC - AGREE REALTY CORP",
        "value": "ADC"
    },
    {
        "label": "BKGFY - Berkeley Group Holdings plc",
        "value": "BKGFY"
    },
    {
        "label": "KGC - KINROSS GOLD CORP",
        "value": "KGC"
    },
    {
        "label": "OGN - Organon & Co.",
        "value": "OGN"
    },
    {
        "label": "HQY - HEALTHEQUITY, INC.",
        "value": "HQY"
    },
    {
        "label": "AGNC - AGNC Investment Corp.",
        "value": "AGNC"
    },
    {
        "label": "AM - Antero Midstream Corp",
        "value": "AM"
    },
    {
        "label": "ATI - ATI INC",
        "value": "ATI"
    },
    {
        "label": "JSCPY - JSR Corp.",
        "value": "JSCPY"
    },
    {
        "label": "IONS - IONIS PHARMACEUTICALS INC",
        "value": "IONS"
    },
    {
        "label": "NXST - NEXSTAR MEDIA GROUP, INC.",
        "value": "NXST"
    },
    {
        "label": "BZZUY - BUZZI UNICEM S.p.A.",
        "value": "BZZUY"
    },
    {
        "label": "GMED - GLOBUS MEDICAL INC",
        "value": "GMED"
    },
    {
        "label": "GME - GameStop Corp.",
        "value": "GME"
    },
    {
        "label": "ELAN - Elanco Animal Health Inc",
        "value": "ELAN"
    },
    {
        "label": "HALO - HALOZYME THERAPEUTICS, INC.",
        "value": "HALO"
    },
    {
        "label": "DLO - dLocal Ltd",
        "value": "DLO"
    },
    {
        "label": "DDS - DILLARD'S, INC.",
        "value": "DDS"
    },
    {
        "label": "MSM - MSC INDUSTRIAL DIRECT CO INC",
        "value": "MSM"
    },
    {
        "label": "RMBS - RAMBUS INC",
        "value": "RMBS"
    },
    {
        "label": "THO - THOR INDUSTRIES INC",
        "value": "THO"
    },
    {
        "label": "LEVI - LEVI STRAUSS & CO",
        "value": "LEVI"
    },
    {
        "label": "AIT - APPLIED INDUSTRIAL TECHNOLOGIES INC",
        "value": "AIT"
    },
    {
        "label": "SIM - GRUPO SIMEC, S.A.B. de C.V.",
        "value": "SIM"
    },
    {
        "label": "POST - Post Holdings, Inc.",
        "value": "POST"
    },
    {
        "label": "ALK - ALASKA AIR GROUP, INC.",
        "value": "ALK"
    },
    {
        "label": "PAAS - PAN AMERICAN SILVER CORP",
        "value": "PAAS"
    },
    {
        "label": "VAL - Valaris Ltd",
        "value": "VAL"
    },
    {
        "label": "FSK - FS KKR Capital Corp",
        "value": "FSK"
    },
    {
        "label": "INFA - Informatica Inc.",
        "value": "INFA"
    },
    {
        "label": "ENLC - EnLink Midstream, LLC",
        "value": "ENLC"
    },
    {
        "label": "WOLF - WOLFSPEED, INC.",
        "value": "WOLF"
    },
    {
        "label": "BOKF - BOK FINANCIAL CORP",
        "value": "BOKF"
    },
    {
        "label": "TKR - TIMKEN CO",
        "value": "TKR"
    },
    {
        "label": "HTZ - HERTZ GLOBAL HOLDINGS, INC",
        "value": "HTZ"
    },
    {
        "label": "SON - SONOCO PRODUCTS CO",
        "value": "SON"
    },
    {
        "label": "SSB - SouthState Corp",
        "value": "SSB"
    },
    {
        "label": "ATKR - Atkore Inc.",
        "value": "ATKR"
    },
    {
        "label": "CRCBY - Chongqing Rural Commercial Bank Co., Ltd./ADR",
        "value": "CRCBY"
    },
    {
        "label": "ONTO - ONTO INNOVATION INC.",
        "value": "ONTO"
    },
    {
        "label": "NOVT - NOVANTA INC",
        "value": "NOVT"
    },
    {
        "label": "GBTG - Global Business Travel Group, Inc.",
        "value": "GBTG"
    },
    {
        "label": "TXG - 10x Genomics, Inc.",
        "value": "TXG"
    },
    {
        "label": "BMBL - Bumble Inc.",
        "value": "BMBL"
    },
    {
        "label": "ESNT - Essent Group Ltd.",
        "value": "ESNT"
    },
    {
        "label": "ENSG - ENSIGN GROUP, INC",
        "value": "ENSG"
    },
    {
        "label": "SRC - SPIRIT REALTY CAPITAL, INC.",
        "value": "SRC"
    },
    {
        "label": "DV - DoubleVerify Holdings, Inc.",
        "value": "DV"
    },
    {
        "label": "QLYS - QUALYS, INC.",
        "value": "QLYS"
    },
    {
        "label": "GIL - Gildan Activewear Inc.",
        "value": "GIL"
    },
    {
        "label": "PB - PROSPERITY BANCSHARES INC",
        "value": "PB"
    },
    {
        "label": "ADOOY - Adaro Energy PT/ADR/",
        "value": "ADOOY"
    },
    {
        "label": "HCP - HashiCorp, Inc.",
        "value": "HCP"
    },
    {
        "label": "ITCI - Intra-Cellular Therapies, Inc.",
        "value": "ITCI"
    },
    {
        "label": "PLNT - Planet Fitness, Inc.",
        "value": "PLNT"
    },
    {
        "label": "IQ - iQIYI, Inc.",
        "value": "IQ"
    },
    {
        "label": "SMAR - SMARTSHEET INC",
        "value": "SMAR"
    },
    {
        "label": "ALSN - Allison Transmission Holdings Inc",
        "value": "ALSN"
    },
    {
        "label": "NVST - Envista Holdings Corp",
        "value": "NVST"
    },
    {
        "label": "BFAM - BRIGHT HORIZONS FAMILY SOLUTIONS INC.",
        "value": "BFAM"
    },
    {
        "label": "ACLS - AXCELIS TECHNOLOGIES INC",
        "value": "ACLS"
    },
    {
        "label": "WAL - WESTERN ALLIANCE BANCORPORATION",
        "value": "WAL"
    },
    {
        "label": "SNDR - Schneider National, Inc.",
        "value": "SNDR"
    },
    {
        "label": "DUOL - Duolingo, Inc.",
        "value": "DUOL"
    },
    {
        "label": "AA - Alcoa Corp",
        "value": "AA"
    },
    {
        "label": "HGTY - Hagerty, Inc.",
        "value": "HGTY"
    },
    {
        "label": "OBDC - Blue Owl Capital Corp",
        "value": "OBDC"
    },
    {
        "label": "PRGO - PERRIGO Co plc",
        "value": "PRGO"
    },
    {
        "label": "WK - WORKIVA INC",
        "value": "WK"
    },
    {
        "label": "ZION - ZIONS BANCORPORATION, NATIONAL ASSOCIATION /UT/",
        "value": "ZION"
    },
    {
        "label": "PLUG - PLUG POWER INC",
        "value": "PLUG"
    },
    {
        "label": "VMI - VALMONT INDUSTRIES INC",
        "value": "VMI"
    },
    {
        "label": "FLO - FLOWERS FOODS INC",
        "value": "FLO"
    },
    {
        "label": "PVH - PVH CORP. /DE/",
        "value": "PVH"
    },
    {
        "label": "PNFP - PINNACLE FINANCIAL PARTNERS INC",
        "value": "PNFP"
    },
    {
        "label": "MTSI - MACOM Technology Solutions Holdings, Inc.",
        "value": "MTSI"
    },
    {
        "label": "TENB - Tenable Holdings, Inc.",
        "value": "TENB"
    },
    {
        "label": "SEE - SEALED AIR CORP/DE",
        "value": "SEE"
    },
    {
        "label": "FIVN - Five9, Inc.",
        "value": "FIVN"
    },
    {
        "label": "FIZZ - NATIONAL BEVERAGE CORP",
        "value": "FIZZ"
    },
    {
        "label": "EVR - Evercore Inc.",
        "value": "EVR"
    },
    {
        "label": "LANC - LANCASTER COLONY CORP",
        "value": "LANC"
    },
    {
        "label": "ABCM - Abcam plc",
        "value": "ABCM"
    },
    {
        "label": "BECN - BEACON ROOFING SUPPLY INC",
        "value": "BECN"
    },
    {
        "label": "GOL - Gol Intelligent Airlines Inc.",
        "value": "GOL"
    },
    {
        "label": "CIGI - Colliers International Group Inc.",
        "value": "CIGI"
    },
    {
        "label": "ZWS - Zurn Elkay Water Solutions Corp",
        "value": "ZWS"
    },
    {
        "label": "RHP - Ryman Hospitality Properties, Inc.",
        "value": "RHP"
    },
    {
        "label": "AIRC - Apartment Income REIT Corp.",
        "value": "AIRC"
    },
    {
        "label": "BRBR - BELLRING BRANDS, INC.",
        "value": "BRBR"
    },
    {
        "label": "NSIT - INSIGHT ENTERPRISES INC",
        "value": "NSIT"
    },
    {
        "label": "WIX - Wix.com Ltd.",
        "value": "WIX"
    },
    {
        "label": "BEPC - Brookfield Renewable Corp",
        "value": "BEPC"
    },
    {
        "label": "TMHC - Taylor Morrison Home Corp",
        "value": "TMHC"
    },
    {
        "label": "MTH - Meritage Homes CORP",
        "value": "MTH"
    },
    {
        "label": "DTM - DT Midstream, Inc.",
        "value": "DTM"
    },
    {
        "label": "MSTR - MICROSTRATEGY Inc",
        "value": "MSTR"
    },
    {
        "label": "FLS - FLOWSERVE CORP",
        "value": "FLS"
    },
    {
        "label": "AYI - ACUITY BRANDS INC",
        "value": "AYI"
    },
    {
        "label": "SLGN - SILGAN HOLDINGS INC",
        "value": "SLGN"
    },
    {
        "label": "ASND - Ascendis Pharma A/S",
        "value": "ASND"
    },
    {
        "label": "MTG - MGIC INVESTMENT CORP",
        "value": "MTG"
    },
    {
        "label": "WING - Wingstop Inc.",
        "value": "WING"
    },
    {
        "label": "HUN - Huntsman CORP",
        "value": "HUN"
    },
    {
        "label": "CWAN - Clearwater Analytics Holdings, Inc.",
        "value": "CWAN"
    },
    {
        "label": "ALTR - Altair Engineering Inc.",
        "value": "ALTR"
    },
    {
        "label": "KRG - KITE REALTY GROUP TRUST",
        "value": "KRG"
    },
    {
        "label": "CR - Crane Co",
        "value": "CR"
    },
    {
        "label": "NFG - NATIONAL FUEL GAS CO",
        "value": "NFG"
    },
    {
        "label": "AQN - ALGONQUIN POWER & UTILITIES CORP.",
        "value": "AQN"
    },
    {
        "label": "HLNE - Hamilton Lane INC",
        "value": "HLNE"
    },
    {
        "label": "NEOG - NEOGEN CORP",
        "value": "NEOG"
    },
    {
        "label": "CWEN - Clearway Energy, Inc.",
        "value": "CWEN"
    },
    {
        "label": "AUR - Aurora Innovation, Inc.",
        "value": "AUR"
    },
    {
        "label": "CAVA - CAVA GROUP, INC.",
        "value": "CAVA"
    },
    {
        "label": "TRNO - Terreno Realty Corp",
        "value": "TRNO"
    },
    {
        "label": "UGI - UGI CORP /PA/",
        "value": "UGI"
    },
    {
        "label": "PAM - Pampa Energy Inc.",
        "value": "PAM"
    },
    {
        "label": "QDEL - QuidelOrtho Corp",
        "value": "QDEL"
    },
    {
        "label": "CLVT - CLARIVATE Plc",
        "value": "CLVT"
    },
    {
        "label": "CC - Chemours Co",
        "value": "CC"
    },
    {
        "label": "HGV - Hilton Grand Vacations Inc.",
        "value": "HGV"
    },
    {
        "label": "BPOP - POPULAR, INC.",
        "value": "BPOP"
    },
    {
        "label": "OMF - OneMain Holdings, Inc.",
        "value": "OMF"
    },
    {
        "label": "SM - SM Energy Co",
        "value": "SM"
    },
    {
        "label": "ALKS - Alkermes plc.",
        "value": "ALKS"
    },
    {
        "label": "MMS - MAXIMUS, INC.",
        "value": "MMS"
    },
    {
        "label": "IDA - IDACORP INC",
        "value": "IDA"
    },
    {
        "label": "KEX - KIRBY CORP",
        "value": "KEX"
    },
    {
        "label": "OMAB - Central North Airport Group",
        "value": "OMAB"
    },
    {
        "label": "WTFC - WINTRUST FINANCIAL CORP",
        "value": "WTFC"
    },
    {
        "label": "AMG - AFFILIATED MANAGERS GROUP, INC.",
        "value": "AMG"
    },
    {
        "label": "FLR - FLUOR CORP",
        "value": "FLR"
    },
    {
        "label": "JOBY - Joby Aviation, Inc.",
        "value": "JOBY"
    },
    {
        "label": "BMI - BADGER METER INC",
        "value": "BMI"
    },
    {
        "label": "ABG - ASBURY AUTOMOTIVE GROUP INC",
        "value": "ABG"
    },
    {
        "label": "ACAD - ACADIA PHARMACEUTICALS INC",
        "value": "ACAD"
    },
    {
        "label": "HOG - HARLEY-DAVIDSON, INC.",
        "value": "HOG"
    },
    {
        "label": "PDI - PIMCO Dynamic Income Fund",
        "value": "PDI"
    },
    {
        "label": "MSSMY - Misumi Group Inc./ADR",
        "value": "MSSMY"
    },
    {
        "label": "CWST - CASELLA WASTE SYSTEMS INC",
        "value": "CWST"
    },
    {
        "label": "COHR - COHERENT CORP.",
        "value": "COHR"
    },
    {
        "label": "AZEK - AZEK Co Inc.",
        "value": "AZEK"
    },
    {
        "label": "VVV - VALVOLINE INC",
        "value": "VVV"
    },
    {
        "label": "NSA - National Storage Affiliates Trust",
        "value": "NSA"
    },
    {
        "label": "IAC - IAC Inc.",
        "value": "IAC"
    },
    {
        "label": "IPGP - IPG PHOTONICS CORP",
        "value": "IPGP"
    },
    {
        "label": "AXS - AXIS CAPITAL HOLDINGS LTD",
        "value": "AXS"
    },
    {
        "label": "ESI - Element Solutions Inc",
        "value": "ESI"
    },
    {
        "label": "DNB - Dun & Bradstreet Holdings, Inc.",
        "value": "DNB"
    },
    {
        "label": "PHI - PLDT Inc.",
        "value": "PHI"
    },
    {
        "label": "NGKSY - NGK Spark Plug Co., Ltd.",
        "value": "NGKSY"
    },
    {
        "label": "COLM - COLUMBIA SPORTSWEAR CO",
        "value": "COLM"
    },
    {
        "label": "RITM - Rithm Capital Corp.",
        "value": "RITM"
    },
    {
        "label": "POWI - POWER INTEGRATIONS INC",
        "value": "POWI"
    },
    {
        "label": "VLY - VALLEY NATIONAL BANCORP",
        "value": "VLY"
    },
    {
        "label": "OLLI - Ollie's Bargain Outlet Holdings, Inc.",
        "value": "OLLI"
    },
    {
        "label": "ENIC - Enel Chile S.A.",
        "value": "ENIC"
    },
    {
        "label": "ACT - Enact Holdings, Inc.",
        "value": "ACT"
    },
    {
        "label": "TRTN - Triton International Ltd",
        "value": "TRTN"
    },
    {
        "label": "VNT - Vontier Corp",
        "value": "VNT"
    },
    {
        "label": "ONB - OLD NATIONAL BANCORP /IN/",
        "value": "ONB"
    },
    {
        "label": "VNOM - Viper Energy Partners LP",
        "value": "VNOM"
    },
    {
        "label": "SAM - BOSTON BEER CO INC",
        "value": "SAM"
    },
    {
        "label": "SHC - Sotera Health Co",
        "value": "SHC"
    },
    {
        "label": "RYN - RAYONIER INC",
        "value": "RYN"
    },
    {
        "label": "SWX - Southwest Gas Holdings, Inc.",
        "value": "SWX"
    },
    {
        "label": "BBIO - BridgeBio Pharma, Inc.",
        "value": "BBIO"
    },
    {
        "label": "OZK - Bank OZK",
        "value": "OZK"
    },
    {
        "label": "FOUR - Shift4 Payments, Inc.",
        "value": "FOUR"
    },
    {
        "label": "HOMB - HOME BANCSHARES INC",
        "value": "HOMB"
    },
    {
        "label": "PECO - Phillips Edison & Company, Inc.",
        "value": "PECO"
    },
    {
        "label": "ORA - ORMAT TECHNOLOGIES, INC.",
        "value": "ORA"
    },
    {
        "label": "TDC - TERADATA CORP /DE/",
        "value": "TDC"
    },
    {
        "label": "R - RYDER SYSTEM INC",
        "value": "R"
    },
    {
        "label": "DEN - DENBURY INC",
        "value": "DEN"
    },
    {
        "label": "ASH - ASHLAND INC.",
        "value": "ASH"
    },
    {
        "label": "SNV - SYNOVUS FINANCIAL CORP",
        "value": "SNV"
    },
    {
        "label": "MPW - MEDICAL PROPERTIES TRUST INC",
        "value": "MPW"
    },
    {
        "label": "BXSL - Blackstone Secured Lending Fund",
        "value": "BXSL"
    },
    {
        "label": "LNTH - Lantheus Holdings, Inc.",
        "value": "LNTH"
    },
    {
        "label": "CPG - Crescent Point Energy Corp.",
        "value": "CPG"
    },
    {
        "label": "EXPO - EXPONENT INC",
        "value": "EXPO"
    },
    {
        "label": "NTCO - Natura &Co Holding S.A.",
        "value": "NTCO"
    },
    {
        "label": "GGAL - GRUPO FINANCIERO GALICIA SA",
        "value": "GGAL"
    },
    {
        "label": "NEU - NEWMARKET CORP",
        "value": "NEU"
    },
    {
        "label": "AL - AIR LEASE CORP",
        "value": "AL"
    },
    {
        "label": "AVT - AVNET INC",
        "value": "AVT"
    },
    {
        "label": "MSGS - Madison Square Garden Sports Corp.",
        "value": "MSGS"
    },
    {
        "label": "PCVX - Vaxcyte, Inc.",
        "value": "PCVX"
    },
    {
        "label": "HAE - HAEMONETICS CORP",
        "value": "HAE"
    },
    {
        "label": "TKC - TURKCELL ILETISIM HIZMETLERI A S",
        "value": "TKC"
    },
    {
        "label": "FOXF - FOX FACTORY HOLDING CORP",
        "value": "FOXF"
    },
    {
        "label": "SUM - Summit Materials, Inc.",
        "value": "SUM"
    },
    {
        "label": "BCPC - BALCHEM CORP",
        "value": "BCPC"
    },
    {
        "label": "SBSW - Sibanye Stillwater Ltd",
        "value": "SBSW"
    },
    {
        "label": "SYNH - Syneos Health, Inc.",
        "value": "SYNH"
    },
    {
        "label": "WEN - Wendy's Co",
        "value": "WEN"
    },
    {
        "label": "AGI - ALAMOS GOLD INC",
        "value": "AGI"
    },
    {
        "label": "VNO - VORNADO REALTY TRUST",
        "value": "VNO"
    },
    {
        "label": "WU - Western Union CO",
        "value": "WU"
    },
    {
        "label": "ASAN - Asana, Inc.",
        "value": "ASAN"
    },
    {
        "label": "SLAB - SILICON LABORATORIES INC.",
        "value": "SLAB"
    },
    {
        "label": "BIPC - Brookfield Infrastructure Corp",
        "value": "BIPC"
    },
    {
        "label": "LNC - LINCOLN NATIONAL CORP",
        "value": "LNC"
    },
    {
        "label": "BOX - BOX INC",
        "value": "BOX"
    },
    {
        "label": "ALIT - Alight, Inc. / Delaware",
        "value": "ALIT"
    },
    {
        "label": "MLCO - Melco Resorts & Entertainment LTD",
        "value": "MLCO"
    },
    {
        "label": "MLI - MUELLER INDUSTRIES INC",
        "value": "MLI"
    },
    {
        "label": "JHG - JANUS HENDERSON GROUP PLC",
        "value": "JHG"
    },
    {
        "label": "EBCOY - EBARA CORP /ADR/",
        "value": "EBCOY"
    },
    {
        "label": "PWSC - POWERSCHOOL HOLDINGS, INC.",
        "value": "PWSC"
    },
    {
        "label": "ASO - Academy Sports & Outdoors, Inc.",
        "value": "ASO"
    },
    {
        "label": "LPX - LOUISIANA-PACIFIC CORP",
        "value": "LPX"
    },
    {
        "label": "FELE - FRANKLIN ELECTRIC CO INC",
        "value": "FELE"
    },
    {
        "label": "TAL - TAL Education Group",
        "value": "TAL"
    },
    {
        "label": "DOCS - Doximity, Inc.",
        "value": "DOCS"
    },
    {
        "label": "DXC - DXC Technology Co",
        "value": "DXC"
    },
    {
        "label": "CADE - Cadence Bank",
        "value": "CADE"
    },
    {
        "label": "CRUS - CIRRUS LOGIC, INC.",
        "value": "CRUS"
    },
    {
        "label": "NWL - NEWELL BRANDS INC.",
        "value": "NWL"
    },
    {
        "label": "VIAAY - VIENNA INTERNATIONAL AIRPORT",
        "value": "VIAAY"
    },
    {
        "label": "LYFT - Lyft, Inc.",
        "value": "LYFT"
    },
    {
        "label": "AFRM - Affirm Holdings, Inc.",
        "value": "AFRM"
    },
    {
        "label": "NEP - NEXTERA ENERGY PARTNERS, LP",
        "value": "NEP"
    },
    {
        "label": "SLFPY - STANDARD LIFE PLC",
        "value": "SLFPY"
    },
    {
        "label": "GATX - GATX CORP",
        "value": "GATX"
    },
    {
        "label": "FN - Fabrinet",
        "value": "FN"
    },
    {
        "label": "HP - Helmerich & Payne, Inc.",
        "value": "HP"
    },
    {
        "label": "POR - PORTLAND GENERAL ELECTRIC CO /OR/",
        "value": "POR"
    },
    {
        "label": "S - SentinelOne, Inc.",
        "value": "S"
    },
    {
        "label": "DRS - Leonardo DRS, Inc.",
        "value": "DRS"
    },
    {
        "label": "NJR - NEW JERSEY RESOURCES CORP",
        "value": "NJR"
    },
    {
        "label": "MXCHY - Orbia Advance Corporation, S.A.B. de C.V./ADR",
        "value": "MXCHY"
    },
    {
        "label": "FFIN - FIRST FINANCIAL BANKSHARES INC",
        "value": "FFIN"
    },
    {
        "label": "FNB - FNB CORP/PA/",
        "value": "FNB"
    },
    {
        "label": "MDU - MDU RESOURCES GROUP INC",
        "value": "MDU"
    },
    {
        "label": "GH - Guardant Health, Inc.",
        "value": "GH"
    },
    {
        "label": "AAP - ADVANCE AUTO PARTS INC",
        "value": "AAP"
    },
    {
        "label": "ETRN - Equitrans Midstream Corp",
        "value": "ETRN"
    },
    {
        "label": "FCFS - FirstCash Holdings, Inc.",
        "value": "FCFS"
    },
    {
        "label": "EEFT - EURONET WORLDWIDE, INC.",
        "value": "EEFT"
    },
    {
        "label": "SEB - SEABOARD CORP /DE/",
        "value": "SEB"
    },
    {
        "label": "RDN - RADIAN GROUP INC",
        "value": "RDN"
    },
    {
        "label": "IBP - Installed Building Products, Inc.",
        "value": "IBP"
    },
    {
        "label": "IPAR - INTER PARFUMS INC",
        "value": "IPAR"
    },
    {
        "label": "OGS - ONE Gas, Inc.",
        "value": "OGS"
    },
    {
        "label": "BMA - Macro Bank Inc.",
        "value": "BMA"
    },
    {
        "label": "UBSI - UNITED BANKSHARES INC/WV",
        "value": "UBSI"
    },
    {
        "label": "M - Macy's, Inc.",
        "value": "M"
    },
    {
        "label": "AEL - AMERICAN EQUITY INVESTMENT LIFE HOLDING CO",
        "value": "AEL"
    },
    {
        "label": "BCC - BOISE CASCADE Co",
        "value": "BCC"
    },
    {
        "label": "AEIS - ADVANCED ENERGY INDUSTRIES INC",
        "value": "AEIS"
    },
    {
        "label": "ESAB - ESAB Corp",
        "value": "ESAB"
    },
    {
        "label": "IHCPF - Inchcape plc/ADR",
        "value": "IHCPF"
    },
    {
        "label": "KRC - KILROY REALTY CORP",
        "value": "KRC"
    },
    {
        "label": "NCR - NCR CORP",
        "value": "NCR"
    },
    {
        "label": "RELY - Remitly Global, Inc.",
        "value": "RELY"
    },
    {
        "label": "FRHC - Freedom Holding Corp.",
        "value": "FRHC"
    },
    {
        "label": "FYBR - Frontier Communications Parent, Inc.",
        "value": "FYBR"
    },
    {
        "label": "VAC - MARRIOTT VACATIONS WORLDWIDE Corp",
        "value": "VAC"
    },
    {
        "label": "UGP - ULTRAPAR HOLDINGS INC",
        "value": "UGP"
    },
    {
        "label": "SQSP - Squarespace, Inc.",
        "value": "SQSP"
    },
    {
        "label": "ASMVY - ASM Pacific Technology Ltd.",
        "value": "ASMVY"
    },
    {
        "label": "SGRY - Surgery Partners, Inc.",
        "value": "SGRY"
    },
    {
        "label": "COLB - COLUMBIA BANKING SYSTEM, INC.",
        "value": "COLB"
    },
    {
        "label": "WTM - WHITE MOUNTAINS INSURANCE GROUP LTD",
        "value": "WTM"
    },
    {
        "label": "FRO - Frontline plc",
        "value": "FRO"
    },
    {
        "label": "SRCL - STERICYCLE INC",
        "value": "SRCL"
    },
    {
        "label": "IMGN - ImmunoGen, Inc.",
        "value": "IMGN"
    },
    {
        "label": "ENS - EnerSys",
        "value": "ENS"
    },
    {
        "label": "SVV - Savers Value Village, Inc.",
        "value": "SVV"
    },
    {
        "label": "ESGR - Enstar Group LTD",
        "value": "ESGR"
    },
    {
        "label": "ATS - ATS Corp /ATS",
        "value": "ATS"
    },
    {
        "label": "GOCO - GoHealth, Inc.",
        "value": "GOCO"
    },
    {
        "label": "EVO - Evotec SE",
        "value": "EVO"
    },
    {
        "label": "TFSL - TFS Financial CORP",
        "value": "TFSL"
    },
    {
        "label": "BRZE - Braze, Inc.",
        "value": "BRZE"
    },
    {
        "label": "KBH - KB HOME",
        "value": "KBH"
    },
    {
        "label": "CBT - CABOT CORP",
        "value": "CBT"
    },
    {
        "label": "MAN - ManpowerGroup Inc.",
        "value": "MAN"
    },
    {
        "label": "BTG - B2GOLD CORP",
        "value": "BTG"
    },
    {
        "label": "MMSI - MERIT MEDICAL SYSTEMS INC",
        "value": "MMSI"
    },
    {
        "label": "NSP - INSPERITY, INC.",
        "value": "NSP"
    },
    {
        "label": "TEX - TEREX CORP",
        "value": "TEX"
    },
    {
        "label": "SDRL - Seadrill Ltd",
        "value": "SDRL"
    },
    {
        "label": "TGS - GAS TRANSPORTER OF THE SOUTH INC",
        "value": "TGS"
    },
    {
        "label": "CRVL - CORVEL CORP",
        "value": "CRVL"
    },
    {
        "label": "BLKB - BLACKBAUD INC",
        "value": "BLKB"
    },
    {
        "label": "CRC - California Resources Corp",
        "value": "CRC"
    },
    {
        "label": "SFM - Sprouts Farmers Market, Inc.",
        "value": "SFM"
    },
    {
        "label": "YETI - YETI Holdings, Inc.",
        "value": "YETI"
    },
    {
        "label": "ZGN - Ermenegildo Zegna N.V.",
        "value": "ZGN"
    },
    {
        "label": "VC - VISTEON CORP",
        "value": "VC"
    },
    {
        "label": "ASGN - ASGN Inc",
        "value": "ASGN"
    },
    {
        "label": "CNXC - Concentrix Corp",
        "value": "CNXC"
    },
    {
        "label": "MMYT - MakeMyTrip Ltd",
        "value": "MMYT"
    },
    {
        "label": "FRPT - Freshpet, Inc.",
        "value": "FRPT"
    },
    {
        "label": "CRSP - CRISPR Therapeutics AG",
        "value": "CRSP"
    },
    {
        "label": "THG - HANOVER INSURANCE GROUP, INC.",
        "value": "THG"
    },
    {
        "label": "PEGA - PEGASYSTEMS INC",
        "value": "PEGA"
    },
    {
        "label": "COOP - Mr. Cooper Group Inc.",
        "value": "COOP"
    },
    {
        "label": "LTHM - Livent Corp.",
        "value": "LTHM"
    },
    {
        "label": "CABO - Cable One, Inc.",
        "value": "CABO"
    },
    {
        "label": "STNE - StoneCo Ltd.",
        "value": "STNE"
    },
    {
        "label": "PYCR - PAYCOR HCM, INC.",
        "value": "PYCR"
    },
    {
        "label": "CEF - Sprott Physical Gold & Silver Trust",
        "value": "CEF"
    },
    {
        "label": "NOG - NORTHERN OIL & GAS, INC.",
        "value": "NOG"
    },
    {
        "label": "MP - MP Materials Corp. / DE",
        "value": "MP"
    },
    {
        "label": "GPS - GAP INC",
        "value": "GPS"
    },
    {
        "label": "TDOC - Teladoc Health, Inc.",
        "value": "TDOC"
    },
    {
        "label": "FLNC - Fluence Energy, Inc.",
        "value": "FLNC"
    },
    {
        "label": "PNM - PNM RESOURCES INC",
        "value": "PNM"
    },
    {
        "label": "PAGP - PLAINS GP HOLDINGS LP",
        "value": "PAGP"
    },
    {
        "label": "MANU - Manchester United plc",
        "value": "MANU"
    },
    {
        "label": "SUN - Sunoco LP",
        "value": "SUN"
    },
    {
        "label": "PCH - POTLATCHDELTIC CORP",
        "value": "PCH"
    },
    {
        "label": "FUL - FULLER H B CO",
        "value": "FUL"
    },
    {
        "label": "STR - Sitio Royalties Corp.",
        "value": "STR"
    },
    {
        "label": "DFIHY - DAIRY FARM INTERNATIONAL HOLDINGS LTD /FI",
        "value": "DFIHY"
    },
    {
        "label": "PSLV - Sprott Physical Silver Trust",
        "value": "PSLV"
    },
    {
        "label": "IRT - INDEPENDENCE REALTY TRUST, INC.",
        "value": "IRT"
    },
    {
        "label": "ATHM - Autohome Inc.",
        "value": "ATHM"
    },
    {
        "label": "CXM - Sprinklr, Inc.",
        "value": "CXM"
    },
    {
        "label": "CPA - Copa Holdings, S.A.",
        "value": "CPA"
    },
    {
        "label": "BAK - BRASKEM SA",
        "value": "BAK"
    },
    {
        "label": "BKH - BLACK HILLS CORP /SD/",
        "value": "BKH"
    },
    {
        "label": "NARI - Inari Medical, Inc.",
        "value": "NARI"
    },
    {
        "label": "FG - F&G Annuities & Life, Inc.",
        "value": "FG"
    },
    {
        "label": "EURN - Euronav NV",
        "value": "EURN"
    },
    {
        "label": "LEG - LEGGETT & PLATT INC",
        "value": "LEG"
    },
    {
        "label": "DISH - DISH Network CORP",
        "value": "DISH"
    },
    {
        "label": "SKY - Skyline Champion Corp",
        "value": "SKY"
    },
    {
        "label": "DIOD - DIODES INC /DEL/",
        "value": "DIOD"
    },
    {
        "label": "GOLF - Acushnet Holdings Corp.",
        "value": "GOLF"
    },
    {
        "label": "CNX - CNX Resources Corp",
        "value": "CNX"
    },
    {
        "label": "INST - INSTRUCTURE HOLDINGS, INC.",
        "value": "INST"
    },
    {
        "label": "GPI - GROUP 1 AUTOMOTIVE INC",
        "value": "GPI"
    },
    {
        "label": "FSS - FEDERAL SIGNAL CORP /DE/",
        "value": "FSS"
    },
    {
        "label": "VSH - VISHAY INTERTECHNOLOGY INC",
        "value": "VSH"
    },
    {
        "label": "KD - Kyndryl Holdings, Inc.",
        "value": "KD"
    },
    {
        "label": "ACA - Arcosa, Inc.",
        "value": "ACA"
    },
    {
        "label": "EXTR - EXTREME NETWORKS INC",
        "value": "EXTR"
    },
    {
        "label": "FOLD - AMICUS THERAPEUTICS, INC.",
        "value": "FOLD"
    },
    {
        "label": "BDC - BELDEN INC.",
        "value": "BDC"
    },
    {
        "label": "SPXC - SPX Technologies, Inc.",
        "value": "SPXC"
    },
    {
        "label": "MOG-A - MOOG INC.",
        "value": "MOG-A"
    },
    {
        "label": "VSAT - VIASAT INC",
        "value": "VSAT"
    },
    {
        "label": "PGNY - Progyny, Inc.",
        "value": "PGNY"
    },
    {
        "label": "HRI - HERC HOLDINGS INC",
        "value": "HRI"
    },
    {
        "label": "BSM - Black Stone Minerals, L.P.",
        "value": "BSM"
    },
    {
        "label": "ERF - ENERPLUS Corp",
        "value": "ERF"
    },
    {
        "label": "BXMT - BLACKSTONE MORTGAGE TRUST, INC.",
        "value": "BXMT"
    },
    {
        "label": "APLS - Apellis Pharmaceuticals, Inc.",
        "value": "APLS"
    },
    {
        "label": "ADNT - Adient plc",
        "value": "ADNT"
    },
    {
        "label": "SGML - Sigma Lithium Corp",
        "value": "SGML"
    },
    {
        "label": "AZTA - Azenta, Inc.",
        "value": "AZTA"
    },
    {
        "label": "LPL - LG Display Co., Ltd.",
        "value": "LPL"
    },
    {
        "label": "SEM - SELECT MEDICAL HOLDINGS CORP",
        "value": "SEM"
    },
    {
        "label": "PENN - PENN Entertainment, Inc.",
        "value": "PENN"
    },
    {
        "label": "DNP - DNP SELECT INCOME FUND INC",
        "value": "DNP"
    },
    {
        "label": "AI - C3.ai, Inc.",
        "value": "AI"
    },
    {
        "label": "HWC - HANCOCK WHITNEY CORP",
        "value": "HWC"
    },
    {
        "label": "WOR - WORTHINGTON INDUSTRIES INC",
        "value": "WOR"
    },
    {
        "label": "IART - INTEGRA LIFESCIENCES HOLDINGS CORP",
        "value": "IART"
    },
    {
        "label": "SRAD - Sportradar Group AG",
        "value": "SRAD"
    },
    {
        "label": "OTTR - Otter Tail Corp",
        "value": "OTTR"
    },
    {
        "label": "JOE - ST JOE Co",
        "value": "JOE"
    },
    {
        "label": "ASAI - Sendas Distributor S.A.",
        "value": "ASAI"
    },
    {
        "label": "SMPL - Simply Good Foods Co",
        "value": "SMPL"
    },
    {
        "label": "SYNA - SYNAPTICS Inc",
        "value": "SYNA"
    },
    {
        "label": "GT - GOODYEAR TIRE & RUBBER CO /OH/",
        "value": "GT"
    },
    {
        "label": "PFSI - PennyMac Financial Services, Inc.",
        "value": "PFSI"
    },
    {
        "label": "CVI - CVR ENERGY INC",
        "value": "CVI"
    },
    {
        "label": "EPRT - ESSENTIAL PROPERTIES REALTY TRUST, INC.",
        "value": "EPRT"
    },
    {
        "label": "PLTK - Playtika Holding Corp.",
        "value": "PLTK"
    },
    {
        "label": "AGO - ASSURED GUARANTY LTD",
        "value": "AGO"
    },
    {
        "label": "FLYW - Flywire Corp",
        "value": "FLYW"
    },
    {
        "label": "GKOS - GLAUKOS Corp",
        "value": "GKOS"
    },
    {
        "label": "NNI - NELNET INC",
        "value": "NNI"
    },
    {
        "label": "GRP-UN - GRANITE REAL ESTATE INVESTMENT TRUST",
        "value": "GRP-UN"
    },
    {
        "label": "TSEM - TOWER SEMICONDUCTOR LTD",
        "value": "TSEM"
    },
    {
        "label": "SLM - SLM Corp",
        "value": "SLM"
    },
    {
        "label": "JBT - John Bean Technologies CORP",
        "value": "JBT"
    },
    {
        "label": "YOU - Clear Secure, Inc.",
        "value": "YOU"
    },
    {
        "label": "AMN - AMN HEALTHCARE SERVICES INC",
        "value": "AMN"
    },
    {
        "label": "FOCS - Focus Financial Partners Inc.",
        "value": "FOCS"
    },
    {
        "label": "RUSHA - RUSH ENTERPRISES INC TX",
        "value": "RUSHA"
    },
    {
        "label": "CCOI - COGENT COMMUNICATIONS HOLDINGS, INC.",
        "value": "CCOI"
    },
    {
        "label": "DOC - Physicians Realty Trust",
        "value": "DOC"
    },
    {
        "label": "FTAI - FTAI Aviation Ltd.",
        "value": "FTAI"
    },
    {
        "label": "GEF - GREIF, INC",
        "value": "GEF"
    },
    {
        "label": "MDC - M.D.C. HOLDINGS, INC.",
        "value": "MDC"
    },
    {
        "label": "BRFS - BRF S.A.",
        "value": "BRFS"
    },
    {
        "label": "AB - ALLIANCEBERNSTEIN HOLDING L.P.",
        "value": "AB"
    },
    {
        "label": "DNA - Ginkgo Bioworks Holdings, Inc.",
        "value": "DNA"
    },
    {
        "label": "CXT - Crane NXT, Co.",
        "value": "CXT"
    },
    {
        "label": "BTE - BAYTEX ENERGY CORP.",
        "value": "BTE"
    },
    {
        "label": "VTSCY - Vitesco Technologies Group AG /ADR",
        "value": "VTSCY"
    },
    {
        "label": "AMC - AMC ENTERTAINMENT HOLDINGS, INC.",
        "value": "AMC"
    },
    {
        "label": "SIG - SIGNET JEWELERS LTD",
        "value": "SIG"
    },
    {
        "label": "AVNT - AVIENT CORP",
        "value": "AVNT"
    },
    {
        "label": "SHLS - Shoals Technologies Group, Inc.",
        "value": "SHLS"
    },
    {
        "label": "AWI - ARMSTRONG WORLD INDUSTRIES INC",
        "value": "AWI"
    },
    {
        "label": "GBCI - GLACIER BANCORP, INC.",
        "value": "GBCI"
    },
    {
        "label": "TGNA - TEGNA INC",
        "value": "TGNA"
    },
    {
        "label": "NVMI - NOVA LTD.",
        "value": "NVMI"
    },
    {
        "label": "CNMD - CONMED Corp",
        "value": "CNMD"
    },
    {
        "label": "CUZ - COUSINS PROPERTIES INC",
        "value": "CUZ"
    },
    {
        "label": "KOS - Kosmos Energy Ltd.",
        "value": "KOS"
    },
    {
        "label": "LOPE - Grand Canyon Education, Inc.",
        "value": "LOPE"
    },
    {
        "label": "BCO - BRINKS CO",
        "value": "BCO"
    },
    {
        "label": "NVCR - NovoCure Ltd",
        "value": "NVCR"
    },
    {
        "label": "APLE - Apple Hospitality REIT, Inc.",
        "value": "APLE"
    },
    {
        "label": "GBTC - Grayscale Bitcoin Trust (BTC)",
        "value": "GBTC"
    },
    {
        "label": "AAON - AAON, INC.",
        "value": "AAON"
    },
    {
        "label": "NCNO - nCino, Inc.",
        "value": "NCNO"
    },
    {
        "label": "USM - UNITED STATES CELLULAR CORP",
        "value": "USM"
    },
    {
        "label": "UAA - Under Armour, Inc.",
        "value": "UAA"
    },
    {
        "label": "APPN - APPIAN CORP",
        "value": "APPN"
    },
    {
        "label": "NTLA - Intellia Therapeutics, Inc.",
        "value": "NTLA"
    },
    {
        "label": "UCBI - UNITED COMMUNITY BANKS INC",
        "value": "UCBI"
    },
    {
        "label": "GTES - Gates Industrial Corp plc",
        "value": "GTES"
    },
    {
        "label": "CERE - Cerevel Therapeutics Holdings, Inc.",
        "value": "CERE"
    },
    {
        "label": "EPR - EPR PROPERTIES",
        "value": "EPR"
    },
    {
        "label": "URBN - URBAN OUTFITTERS INC",
        "value": "URBN"
    },
    {
        "label": "WHD - Cactus, Inc.",
        "value": "WHD"
    },
    {
        "label": "AMTD - AMTD IDEA GROUP",
        "value": "AMTD"
    },
    {
        "label": "GO - Grocery Outlet Holding Corp.",
        "value": "GO"
    },
    {
        "label": "CRK - COMSTOCK RESOURCES INC",
        "value": "CRK"
    },
    {
        "label": "VRNS - VARONIS SYSTEMS INC",
        "value": "VRNS"
    },
    {
        "label": "IRTC - iRhythm Technologies, Inc.",
        "value": "IRTC"
    },
    {
        "label": "ICUI - ICU MEDICAL INC/DE",
        "value": "ICUI"
    },
    {
        "label": "AXSM - Axsome Therapeutics, Inc.",
        "value": "AXSM"
    },
    {
        "label": "GNNDY - GN STORE NORD A/S",
        "value": "GNNDY"
    },
    {
        "label": "TJBH - Tengjun Biotechnology Corp.",
        "value": "TJBH"
    },
    {
        "label": "BTU - PEABODY ENERGY CORP",
        "value": "BTU"
    },
    {
        "label": "SR - SPIRE INC",
        "value": "SR"
    },
    {
        "label": "MAIN - Main Street Capital CORP",
        "value": "MAIN"
    },
    {
        "label": "MATX - Matson, Inc.",
        "value": "MATX"
    },
    {
        "label": "MDGL - MADRIGAL PHARMACEUTICALS, INC.",
        "value": "MDGL"
    },
    {
        "label": "AWR - AMERICAN STATES WATER CO",
        "value": "AWR"
    },
    {
        "label": "BNL - Broadstone Net Lease, Inc.",
        "value": "BNL"
    },
    {
        "label": "LITE - Lumentum Holdings Inc.",
        "value": "LITE"
    },
    {
        "label": "BANF - BANCFIRST CORP /OK/",
        "value": "BANF"
    },
    {
        "label": "PDCO - PATTERSON COMPANIES, INC.",
        "value": "PDCO"
    },
    {
        "label": "SID - NATIONAL STEEL CO",
        "value": "SID"
    },
    {
        "label": "STEP - StepStone Group Inc.",
        "value": "STEP"
    },
    {
        "label": "VIRT - Virtu Financial, Inc.",
        "value": "VIRT"
    },
    {
        "label": "INDV - INDIVIOR PLC",
        "value": "INDV"
    },
    {
        "label": "UNF - UNIFIRST CORP",
        "value": "UNF"
    },
    {
        "label": "SPB - Spectrum Brands Holdings, Inc.",
        "value": "SPB"
    },
    {
        "label": "LTH - Life Time Group Holdings, Inc.",
        "value": "LTH"
    },
    {
        "label": "BHF - Brighthouse Financial, Inc.",
        "value": "BHF"
    },
    {
        "label": "ALE - ALLETE INC",
        "value": "ALE"
    },
    {
        "label": "UMBF - UMB FINANCIAL CORP",
        "value": "UMBF"
    },
    {
        "label": "ZD - ZIFF DAVIS, INC.",
        "value": "ZD"
    },
    {
        "label": "BBMPY - BBMG Corporation/ADR",
        "value": "BBMPY"
    },
    {
        "label": "RUN - Sunrun Inc.",
        "value": "RUN"
    },
    {
        "label": "DNLI - Denali Therapeutics Inc.",
        "value": "DNLI"
    },
    {
        "label": "JJSF - J&J SNACK FOODS CORP",
        "value": "JJSF"
    },
    {
        "label": "SFBS - ServisFirst Bancshares, Inc.",
        "value": "SFBS"
    },
    {
        "label": "SANM - SANMINA CORP",
        "value": "SANM"
    },
    {
        "label": "CNS - COHEN & STEERS, INC.",
        "value": "CNS"
    },
    {
        "label": "NOMD - Nomad Foods Ltd",
        "value": "NOMD"
    },
    {
        "label": "CORT - CORCEPT THERAPEUTICS INC",
        "value": "CORT"
    },
    {
        "label": "HI - Hillenbrand, Inc.",
        "value": "HI"
    },
    {
        "label": "WB - WEIBO Corp",
        "value": "WB"
    },
    {
        "label": "NEA - Nuveen AMT-Free Quality Municipal Income Fund",
        "value": "NEA"
    },
    {
        "label": "JWN - NORDSTROM INC",
        "value": "JWN"
    },
    {
        "label": "INMD - InMode Ltd.",
        "value": "INMD"
    },
    {
        "label": "MQ - Marqeta, Inc.",
        "value": "MQ"
    },
    {
        "label": "EXPI - EXP World Holdings, Inc.",
        "value": "EXPI"
    },
    {
        "label": "ARRY - Array Technologies, Inc.",
        "value": "ARRY"
    },
    {
        "label": "RVMD - Revolution Medicines, Inc.",
        "value": "RVMD"
    },
    {
        "label": "LCII - LCI INDUSTRIES",
        "value": "LCII"
    },
    {
        "label": "AEO - AMERICAN EAGLE OUTFITTERS INC",
        "value": "AEO"
    },
    {
        "label": "SEAS - SeaWorld Entertainment, Inc.",
        "value": "SEAS"
    },
    {
        "label": "TEO - TELECOM ARGENTINA SA",
        "value": "TEO"
    },
    {
        "label": "IIJIY - INTERNET INITIATIVE JAPAN INC",
        "value": "IIJIY"
    },
    {
        "label": "VSBC - VITASPRING BIOMEDICAL CO. LTD.",
        "value": "VSBC"
    },
    {
        "label": "VERX - Vertex, Inc.",
        "value": "VERX"
    },
    {
        "label": "PTEN - PATTERSON UTI ENERGY INC",
        "value": "PTEN"
    },
    {
        "label": "ARWR - ARROWHEAD PHARMACEUTICALS, INC.",
        "value": "ARWR"
    },
    {
        "label": "BL - BLACKLINE, INC.",
        "value": "BL"
    },
    {
        "label": "CVLT - COMMVAULT SYSTEMS INC",
        "value": "CVLT"
    },
    {
        "label": "CYTK - CYTOKINETICS INC",
        "value": "CYTK"
    },
    {
        "label": "ROYMY - Royal Mail plc/ADR",
        "value": "ROYMY"
    },
    {
        "label": "NWE - NORTHWESTERN CORP",
        "value": "NWE"
    },
    {
        "label": "CD - Chindata Group Holdings Ltd",
        "value": "CD"
    },
    {
        "label": "KSS - KOHLS Corp",
        "value": "KSS"
    },
    {
        "label": "MODG - Topgolf Callaway Brands Corp.",
        "value": "MODG"
    },
    {
        "label": "KRYS - Krystal Biotech, Inc.",
        "value": "KRYS"
    },
    {
        "label": "TPH - Tri Pointe Homes, Inc.",
        "value": "TPH"
    },
    {
        "label": "QS - QuantumScape Corp",
        "value": "QS"
    },
    {
        "label": "TNL - Travel & Leisure Co.",
        "value": "TNL"
    },
    {
        "label": "PINC - Premier, Inc.",
        "value": "PINC"
    },
    {
        "label": "VICR - VICOR CORP",
        "value": "VICR"
    },
    {
        "label": "TDW - TIDEWATER INC",
        "value": "TDW"
    },
    {
        "label": "BE - Bloom Energy Corp",
        "value": "BE"
    },
    {
        "label": "ITRI - ITRON, INC.",
        "value": "ITRI"
    },
    {
        "label": "PRTA - PROTHENA CORP PUBLIC LTD CO",
        "value": "PRTA"
    },
    {
        "label": "AMED - AMEDISYS INC",
        "value": "AMED"
    },
    {
        "label": "BHC - Bausch Health Companies Inc.",
        "value": "BHC"
    },
    {
        "label": "AXNX - Axonics, Inc.",
        "value": "AXNX"
    },
    {
        "label": "TWNK - Hostess Brands, Inc.",
        "value": "TWNK"
    },
    {
        "label": "ARNC - Arconic Corp",
        "value": "ARNC"
    },
    {
        "label": "KMPR - KEMPER Corp",
        "value": "KMPR"
    },
    {
        "label": "PBH - Prestige Consumer Healthcare Inc.",
        "value": "PBH"
    },
    {
        "label": "ENOV - Enovis CORP",
        "value": "ENOV"
    },
    {
        "label": "PJT - PJT Partners Inc.",
        "value": "PJT"
    },
    {
        "label": "KWR - QUAKER CHEMICAL CORP",
        "value": "KWR"
    },
    {
        "label": "INSM - INSMED Inc",
        "value": "INSM"
    },
    {
        "label": "SHWDY - Showa Denko K.K./ADR",
        "value": "SHWDY"
    },
    {
        "label": "MC - Moelis & Co",
        "value": "MC"
    },
    {
        "label": "PTCT - PTC THERAPEUTICS, INC.",
        "value": "PTCT"
    },
    {
        "label": "SHAK - Shake Shack Inc.",
        "value": "SHAK"
    },
    {
        "label": "BUR - Burford Capital Ltd",
        "value": "BUR"
    },
    {
        "label": "ABM - ABM INDUSTRIES INC /DE/",
        "value": "ABM"
    },
    {
        "label": "HAYW - Hayward Holdings, Inc.",
        "value": "HAYW"
    },
    {
        "label": "EVH - Evolent Health, Inc.",
        "value": "EVH"
    },
    {
        "label": "BPMC - Blueprint Medicines Corp",
        "value": "BPMC"
    },
    {
        "label": "OI - O-I Glass, Inc. /DE/",
        "value": "OI"
    },
    {
        "label": "WNS - WNS (HOLDINGS) LTD",
        "value": "WNS"
    },
    {
        "label": "CEIX - CONSOL Energy Inc.",
        "value": "CEIX"
    },
    {
        "label": "LIVN - LivaNova PLC",
        "value": "LIVN"
    },
    {
        "label": "HELE - HELEN OF TROY LTD",
        "value": "HELE"
    },
    {
        "label": "ATAT - Atour Lifestyle Holdings Ltd",
        "value": "ATAT"
    },
    {
        "label": "FHI - FEDERATED HERMES, INC.",
        "value": "FHI"
    },
    {
        "label": "WDFC - WD 40 CO",
        "value": "WDFC"
    },
    {
        "label": "PRVA - Privia Health Group, Inc.",
        "value": "PRVA"
    },
    {
        "label": "PAGS - PagSeguro Digital Ltd.",
        "value": "PAGS"
    },
    {
        "label": "SSRM - SSR MINING INC.",
        "value": "SSRM"
    },
    {
        "label": "LAZ - Lazard Ltd",
        "value": "LAZ"
    },
    {
        "label": "MLTX - MoonLake Immunotherapeutics",
        "value": "MLTX"
    },
    {
        "label": "TCBI - TEXAS CAPITAL BANCSHARES INC/TX",
        "value": "TCBI"
    },
    {
        "label": "CEQP - Crestwood Equity Partners LP",
        "value": "CEQP"
    },
    {
        "label": "KOZAY - Koza Altin Isletmeleri A.S./ADR",
        "value": "KOZAY"
    },
    {
        "label": "DOCN - DigitalOcean Holdings, Inc.",
        "value": "DOCN"
    },
    {
        "label": "OFC - CORPORATE OFFICE PROPERTIES TRUST",
        "value": "OFC"
    },
    {
        "label": "CWT - CALIFORNIA WATER SERVICE GROUP",
        "value": "CWT"
    },
    {
        "label": "VRRM - VERRA MOBILITY Corp",
        "value": "VRRM"
    },
    {
        "label": "ITGR - Integer Holdings Corp",
        "value": "ITGR"
    },
    {
        "label": "AMR - Alpha Metallurgical Resources, Inc.",
        "value": "AMR"
    },
    {
        "label": "STNG - Scorpio Tankers Inc.",
        "value": "STNG"
    },
    {
        "label": "CSWI - CSW INDUSTRIALS, INC.",
        "value": "CSWI"
    },
    {
        "label": "AVAL - Grupo Aval Acciones Y Valores S.A.",
        "value": "AVAL"
    },
    {
        "label": "YELP - YELP INC",
        "value": "YELP"
    },
    {
        "label": "ENV - ENVESTNET, INC.",
        "value": "ENV"
    },
    {
        "label": "NWTN - NWTN, Inc.",
        "value": "NWTN"
    },
    {
        "label": "LGIH - LGI Homes, Inc.",
        "value": "LGIH"
    },
    {
        "label": "CRS - CARPENTER TECHNOLOGY CORP",
        "value": "CRS"
    },
    {
        "label": "ABCB - Ameris Bancorp",
        "value": "ABCB"
    },
    {
        "label": "ALRM - Alarm.com Holdings, Inc.",
        "value": "ALRM"
    },
    {
        "label": "PK - Park Hotels & Resorts Inc.",
        "value": "PK"
    },
    {
        "label": "MEOH - METHANEX CORP",
        "value": "MEOH"
    },
    {
        "label": "SHZNY - Shenzhen Expressway Co. / ADR",
        "value": "SHZNY"
    },
    {
        "label": "LFST - LifeStance Health Group, Inc.",
        "value": "LFST"
    },
    {
        "label": "IBOC - INTERNATIONAL BANCSHARES CORP",
        "value": "IBOC"
    },
    {
        "label": "AIN - ALBANY INTERNATIONAL CORP /DE/",
        "value": "AIN"
    },
    {
        "label": "RPD - Rapid7, Inc.",
        "value": "RPD"
    },
    {
        "label": "GMS - GMS Inc.",
        "value": "GMS"
    },
    {
        "label": "SMG - SCOTTS MIRACLE-GRO CO",
        "value": "SMG"
    },
    {
        "label": "ESMT - EngageSmart, Inc.",
        "value": "ESMT"
    },
    {
        "label": "WD - Walker & Dunlop, Inc.",
        "value": "WD"
    },
    {
        "label": "WIRE - ENCORE WIRE CORP",
        "value": "WIRE"
    },
    {
        "label": "CCU - UNITED BREWERIES CO INC",
        "value": "CCU"
    },
    {
        "label": "OMCL - OMNICELL, INC.",
        "value": "OMCL"
    },
    {
        "label": "KNF - Knife River Corp",
        "value": "KNF"
    },
    {
        "label": "FIBK - FIRST INTERSTATE BANCSYSTEM INC",
        "value": "FIBK"
    },
    {
        "label": "KLIC - KULICKE & SOFFA INDUSTRIES INC",
        "value": "KLIC"
    },
    {
        "label": "LXP - LXP Industrial Trust",
        "value": "LXP"
    },
    {
        "label": "FUPBY - Fuchs Petrolub SE/ADR",
        "value": "FUPBY"
    },
    {
        "label": "JXN - Jackson Financial Inc.",
        "value": "JXN"
    },
    {
        "label": "DY - DYCOM INDUSTRIES INC",
        "value": "DY"
    },
    {
        "label": "GDRX - GoodRx Holdings, Inc.",
        "value": "GDRX"
    },
    {
        "label": "GNW - GENWORTH FINANCIAL INC",
        "value": "GNW"
    },
    {
        "label": "HL - HECLA MINING CO/DE/",
        "value": "HL"
    },
    {
        "label": "HEP - HOLLY ENERGY PARTNERS LP",
        "value": "HEP"
    },
    {
        "label": "RNG - RingCentral, Inc.",
        "value": "RNG"
    },
    {
        "label": "KTB - Kontoor Brands, Inc.",
        "value": "KTB"
    },
    {
        "label": "DQ - DAQO NEW ENERGY CORP.",
        "value": "DQ"
    },
    {
        "label": "ABR - ARBOR REALTY TRUST INC",
        "value": "ABR"
    },
    {
        "label": "SBRA - Sabra Health Care REIT, Inc.",
        "value": "SBRA"
    },
    {
        "label": "BOOT - Boot Barn Holdings, Inc.",
        "value": "BOOT"
    },
    {
        "label": "GHC - Graham Holdings Co",
        "value": "GHC"
    },
    {
        "label": "FROG - JFrog Ltd",
        "value": "FROG"
    },
    {
        "label": "SITC - SITE Centers Corp.",
        "value": "SITC"
    },
    {
        "label": "WERN - WERNER ENTERPRISES INC",
        "value": "WERN"
    },
    {
        "label": "AMBA - AMBARELLA INC",
        "value": "AMBA"
    },
    {
        "label": "LBRT - Liberty Energy Inc.",
        "value": "LBRT"
    },
    {
        "label": "SITM - SITIME Corp",
        "value": "SITM"
    },
    {
        "label": "RKLB - Rocket Lab USA, Inc.",
        "value": "RKLB"
    },
    {
        "label": "FTDR - Frontdoor, Inc.",
        "value": "FTDR"
    },
    {
        "label": "NPO - ENPRO INDUSTRIES, INC",
        "value": "NPO"
    },
    {
        "label": "IFS - Intercorp Financial Services Inc.",
        "value": "IFS"
    },
    {
        "label": "CBZ - CBIZ, Inc.",
        "value": "CBZ"
    },
    {
        "label": "TFPM - Triple Flag Precious Metals Corp.",
        "value": "TFPM"
    },
    {
        "label": "IMCR - Immunocore Holdings plc",
        "value": "IMCR"
    },
    {
        "label": "HUBG - Hub Group, Inc.",
        "value": "HUBG"
    },
    {
        "label": "ERJ - EMBRAER S.A.",
        "value": "ERJ"
    },
    {
        "label": "CRI - CARTERS INC",
        "value": "CRI"
    },
    {
        "label": "KFY - KORN FERRY",
        "value": "KFY"
    },
    {
        "label": "CRGY - Crescent Energy Co",
        "value": "CRGY"
    },
    {
        "label": "CALX - CALIX, INC",
        "value": "CALX"
    },
    {
        "label": "CNO - CNO Financial Group, Inc.",
        "value": "CNO"
    },
    {
        "label": "MGEE - MGE ENERGY INC",
        "value": "MGEE"
    },
    {
        "label": "IONQ - IonQ, Inc.",
        "value": "IONQ"
    },
    {
        "label": "DBC - Invesco DB Commodity Index Tracking Fund",
        "value": "DBC"
    },
    {
        "label": "TIGO - MILLICOM INTERNATIONAL CELLULAR SA",
        "value": "TIGO"
    },
    {
        "label": "LAC - LITHIUM AMERICAS CORP.",
        "value": "LAC"
    },
    {
        "label": "TV - GRUPO TELEVISA, S.A.B.",
        "value": "TV"
    },
    {
        "label": "ESE - ESCO TECHNOLOGIES INC",
        "value": "ESE"
    },
    {
        "label": "MHO - M/I HOMES, INC.",
        "value": "MHO"
    },
    {
        "label": "DEI - Douglas Emmett Inc",
        "value": "DEI"
    },
    {
        "label": "UPST - Upstart Holdings, Inc.",
        "value": "UPST"
    },
    {
        "label": "PLXS - PLEXUS CORP",
        "value": "PLXS"
    },
    {
        "label": "QFIN - Qifu Technology, Inc.",
        "value": "QFIN"
    },
    {
        "label": "PACB - PACIFIC BIOSCIENCES OF CALIFORNIA, INC.",
        "value": "PACB"
    },
    {
        "label": "MAC - MACERICH CO",
        "value": "MAC"
    },
    {
        "label": "MRVI - MARAVAI LIFESCIENCES HOLDINGS, INC.",
        "value": "MRVI"
    },
    {
        "label": "THS - TreeHouse Foods, Inc.",
        "value": "THS"
    },
    {
        "label": "DAVA - Endava plc",
        "value": "DAVA"
    },
    {
        "label": "ASB - ASSOCIATED BANC-CORP",
        "value": "ASB"
    },
    {
        "label": "DBRG - DigitalBridge Group, Inc.",
        "value": "DBRG"
    },
    {
        "label": "OSTIY - Osterreichische Post AG ADR",
        "value": "OSTIY"
    },
    {
        "label": "CERT - Certara, Inc.",
        "value": "CERT"
    },
    {
        "label": "MGPI - MGP INGREDIENTS INC",
        "value": "MGPI"
    },
    {
        "label": "SHOO - STEVEN MADDEN, LTD.",
        "value": "SHOO"
    },
    {
        "label": "IOSP - INNOSPEC INC.",
        "value": "IOSP"
    },
    {
        "label": "GLPG - GALAPAGOS NV",
        "value": "GLPG"
    },
    {
        "label": "DORM - Dorman Products, Inc.",
        "value": "DORM"
    },
    {
        "label": "SLRN - ACELYRIN, Inc.",
        "value": "SLRN"
    },
    {
        "label": "TAC - TRANSALTA CORP",
        "value": "TAC"
    },
    {
        "label": "ROG - ROGERS CORP",
        "value": "ROG"
    },
    {
        "label": "CBU - COMMUNITY BANK SYSTEM, INC.",
        "value": "CBU"
    },
    {
        "label": "ODD - Oddity Tech Ltd",
        "value": "ODD"
    },
    {
        "label": "EVTC - EVERTEC, Inc.",
        "value": "EVTC"
    },
    {
        "label": "AMPH - Amphastar Pharmaceuticals, Inc.",
        "value": "AMPH"
    },
    {
        "label": "CHPT - ChargePoint Holdings, Inc.",
        "value": "CHPT"
    },
    {
        "label": "ARLP - ALLIANCE RESOURCE PARTNERS LP",
        "value": "ARLP"
    },
    {
        "label": "PZZA - PAPA JOHNS INTERNATIONAL INC",
        "value": "PZZA"
    },
    {
        "label": "FBP - FIRST BANCORP /PR/",
        "value": "FBP"
    },
    {
        "label": "PRGS - PROGRESS SOFTWARE CORP /MA",
        "value": "PRGS"
    },
    {
        "label": "VIEW - View, Inc.",
        "value": "VIEW"
    },
    {
        "label": "AVA - AVISTA CORP",
        "value": "AVA"
    },
    {
        "label": "IMVT - Immunovant, Inc.",
        "value": "IMVT"
    },
    {
        "label": "WSFS - WSFS FINANCIAL CORP",
        "value": "WSFS"
    },
    {
        "label": "BB - BLACKBERRY Ltd",
        "value": "BB"
    },
    {
        "label": "HIW - HIGHWOODS PROPERTIES, INC.",
        "value": "HIW"
    },
    {
        "label": "HCM - HUTCHMED (China) Ltd",
        "value": "HCM"
    },
    {
        "label": "AY - Atlantica Sustainable Infrastructure plc",
        "value": "AY"
    },
    {
        "label": "AKRO - Akero Therapeutics, Inc.",
        "value": "AKRO"
    },
    {
        "label": "NAD - Nuveen Quality Municipal Income Fund",
        "value": "NAD"
    },
    {
        "label": "CATY - CATHAY GENERAL BANCORP",
        "value": "CATY"
    },
    {
        "label": "SPT - Sprout Social, Inc.",
        "value": "SPT"
    },
    {
        "label": "ARCH - ARCH RESOURCES, INC.",
        "value": "ARCH"
    },
    {
        "label": "APAM - Artisan Partners Asset Management Inc.",
        "value": "APAM"
    },
    {
        "label": "SKT - TANGER FACTORY OUTLET CENTERS, INC",
        "value": "SKT"
    },
    {
        "label": "DFH - Dream Finders Homes, Inc.",
        "value": "DFH"
    },
    {
        "label": "RARE - Ultragenyx Pharmaceutical Inc.",
        "value": "RARE"
    },
    {
        "label": "PTON - PELOTON INTERACTIVE, INC.",
        "value": "PTON"
    },
    {
        "label": "NEX - NEXTIER OILFIELD SOLUTIONS INC.",
        "value": "NEX"
    },
    {
        "label": "GBDC - GOLUB CAPITAL BDC, Inc.",
        "value": "GBDC"
    },
    {
        "label": "CSTM - CONSTELLIUM SE",
        "value": "CSTM"
    },
    {
        "label": "XPRO - EXPRO GROUP HOLDINGS N.V.",
        "value": "XPRO"
    },
    {
        "label": "KAI - KADANT INC",
        "value": "KAI"
    },
    {
        "label": "CLS - CELESTICA INC",
        "value": "CLS"
    },
    {
        "label": "PSEC - PROSPECT CAPITAL CORP",
        "value": "PSEC"
    },
    {
        "label": "ENR - ENERGIZER HOLDINGS, INC.",
        "value": "ENR"
    },
    {
        "label": "CVBF - CVB FINANCIAL CORP",
        "value": "CVBF"
    },
    {
        "label": "RRR - Red Rock Resorts, Inc.",
        "value": "RRR"
    },
    {
        "label": "SDGR - Schrodinger, Inc.",
        "value": "SDGR"
    },
    {
        "label": "FHB - FIRST HAWAIIAN, INC.",
        "value": "FHB"
    },
    {
        "label": "ARCB - ARCBEST CORP /DE/",
        "value": "ARCB"
    },
    {
        "label": "ACIW - ACI WORLDWIDE, INC.",
        "value": "ACIW"
    },
    {
        "label": "SXT - SENSIENT TECHNOLOGIES CORP",
        "value": "SXT"
    },
    {
        "label": "CPE - Callon Petroleum Co",
        "value": "CPE"
    },
    {
        "label": "MGRC - MCGRATH RENTCORP",
        "value": "MGRC"
    },
    {
        "label": "OR - Osisko Gold Royalties LTD",
        "value": "OR"
    },
    {
        "label": "AVAV - AeroVironment Inc",
        "value": "AVAV"
    },
    {
        "label": "AMRC - Ameresco, Inc.",
        "value": "AMRC"
    },
    {
        "label": "BRC - BRADY CORP",
        "value": "BRC"
    },
    {
        "label": "ITCL - BANCO ITAU CHILE",
        "value": "ITCL"
    },
    {
        "label": "NABL - N-able, Inc.",
        "value": "NABL"
    },
    {
        "label": "EBC - Eastern Bankshares, Inc.",
        "value": "EBC"
    },
    {
        "label": "FSLY - Fastly, Inc.",
        "value": "FSLY"
    },
    {
        "label": "INDB - INDEPENDENT BANK CORP",
        "value": "INDB"
    },
    {
        "label": "DEA - Easterly Government Properties, Inc.",
        "value": "DEA"
    },
    {
        "label": "DRVN - Driven Brands Holdings Inc.",
        "value": "DRVN"
    },
    {
        "label": "NVG - Nuveen AMT-Free Municipal Credit Income Fund",
        "value": "NVG"
    },
    {
        "label": "AX - Axos Financial, Inc.",
        "value": "AX"
    },
    {
        "label": "PRMW - Primo Water Corp /CN/",
        "value": "PRMW"
    },
    {
        "label": "VIST - Vista Energy, S.A.B. de C.V.",
        "value": "VIST"
    },
    {
        "label": "CVCO - CAVCO INDUSTRIES INC.",
        "value": "CVCO"
    },
    {
        "label": "ACVA - ACV Auctions Inc.",
        "value": "ACVA"
    },
    {
        "label": "GLNG - GOLAR LNG LTD",
        "value": "GLNG"
    },
    {
        "label": "ALVO - Alvotech",
        "value": "ALVO"
    },
    {
        "label": "INTA - Intapp, Inc.",
        "value": "INTA"
    },
    {
        "label": "NVEI - Nuvei Corp",
        "value": "NVEI"
    },
    {
        "label": "ZLAB - Zai Lab Ltd",
        "value": "ZLAB"
    },
    {
        "label": "PSMT - PRICESMART INC",
        "value": "PSMT"
    },
    {
        "label": "EXG - Eaton Vance Tax-Managed Global Diversified Equity Income Fund",
        "value": "EXG"
    },
    {
        "label": "NUVL - Nuvalent, Inc.",
        "value": "NUVL"
    },
    {
        "label": "BATRA - Atlanta Braves Holdings, Inc.",
        "value": "BATRA"
    },
    {
        "label": "VET - VERMILION ENERGY INC.",
        "value": "VET"
    },
    {
        "label": "ICFI - ICF International, Inc.",
        "value": "ICFI"
    },
    {
        "label": "XRX - Xerox Holdings Corp",
        "value": "XRX"
    },
    {
        "label": "NMIH - NMI Holdings, Inc.",
        "value": "NMIH"
    },
    {
        "label": "REZI - RESIDEO TECHNOLOGIES, INC.",
        "value": "REZI"
    },
    {
        "label": "FL - FOOT LOCKER, INC.",
        "value": "FL"
    },
    {
        "label": "MORF - Morphic Holding, Inc.",
        "value": "MORF"
    },
    {
        "label": "STRL - STERLING INFRASTRUCTURE, INC.",
        "value": "STRL"
    },
    {
        "label": "FTRE - Fortrea Holdings Inc.",
        "value": "FTRE"
    },
    {
        "label": "RNW - ReNew Energy Global plc",
        "value": "RNW"
    },
    {
        "label": "COUR - Coursera, Inc.",
        "value": "COUR"
    },
    {
        "label": "FORM - FORMFACTOR INC",
        "value": "FORM"
    },
    {
        "label": "LZ - LEGALZOOM.COM, INC.",
        "value": "LZ"
    },
    {
        "label": "GFF - GRIFFON CORP",
        "value": "GFF"
    },
    {
        "label": "GSHD - Goosehead Insurance, Inc.",
        "value": "GSHD"
    },
    {
        "label": "HTGC - Hercules Capital, Inc.",
        "value": "HTGC"
    },
    {
        "label": "ESRT - Empire State Realty Trust, Inc.",
        "value": "ESRT"
    },
    {
        "label": "MYRG - MYR GROUP INC.",
        "value": "MYRG"
    },
    {
        "label": "CSQ - CALAMOS STRATEGIC TOTAL RETURN FUND",
        "value": "CSQ"
    },
    {
        "label": "SFNC - SIMMONS FIRST NATIONAL CORP",
        "value": "SFNC"
    },
    {
        "label": "CALM - CAL-MAINE FOODS INC",
        "value": "CALM"
    },
    {
        "label": "TR - TOOTSIE ROLL INDUSTRIES INC",
        "value": "TR"
    },
    {
        "label": "MRTX - Mirati Therapeutics, Inc.",
        "value": "MRTX"
    },
    {
        "label": "NXE - NexGen Energy Ltd.",
        "value": "NXE"
    },
    {
        "label": "LSPD - Lightspeed Commerce Inc.",
        "value": "LSPD"
    },
    {
        "label": "XPEL - XPEL, Inc.",
        "value": "XPEL"
    },
    {
        "label": "PROK - PROKIDNEY CORP.",
        "value": "PROK"
    },
    {
        "label": "INSW - International Seaways, Inc.",
        "value": "INSW"
    },
    {
        "label": "ATRC - AtriCure, Inc.",
        "value": "ATRC"
    },
    {
        "label": "AKO-A - ANDINA BOTTLING CO INC",
        "value": "AKO-A"
    },
    {
        "label": "TIXT - TELUS International (Cda) Inc.",
        "value": "TIXT"
    },
    {
        "label": "SOVO - Sovos Brands, Inc.",
        "value": "SOVO"
    },
    {
        "label": "AUB - Atlantic Union Bankshares Corp",
        "value": "AUB"
    },
    {
        "label": "VRTV - Veritiv Corp",
        "value": "VRTV"
    },
    {
        "label": "CENT - CENTRAL GARDEN & PET CO",
        "value": "CENT"
    },
    {
        "label": "YY - JOYY Inc.",
        "value": "YY"
    },
    {
        "label": "DOOR - MASONITE INTERNATIONAL CORP",
        "value": "DOOR"
    },
    {
        "label": "TRMD - TORM plc",
        "value": "TRMD"
    },
    {
        "label": "BLMN - Bloomin' Brands, Inc.",
        "value": "BLMN"
    },
    {
        "label": "DAN - DANA INC",
        "value": "DAN"
    },
    {
        "label": "TCN - Tricon Residential Inc.",
        "value": "TCN"
    },
    {
        "label": "ENVX - Enovix Corp",
        "value": "ENVX"
    },
    {
        "label": "SPR - Spirit AeroSystems Holdings, Inc.",
        "value": "SPR"
    },
    {
        "label": "XENE - Xenon Pharmaceuticals Inc.",
        "value": "XENE"
    },
    {
        "label": "RLX - RLX Technology Inc.",
        "value": "RLX"
    },
    {
        "label": "LAUR - LAUREATE EDUCATION, INC.",
        "value": "LAUR"
    },
    {
        "label": "VIAV - VIAVI SOLUTIONS INC.",
        "value": "VIAV"
    },
    {
        "label": "HASI - Hannon Armstrong Sustainable Infrastructure Capital, Inc.",
        "value": "HASI"
    },
    {
        "label": "CCS - Century Communities, Inc.",
        "value": "CCS"
    },
    {
        "label": "RXO - RXO, Inc.",
        "value": "RXO"
    },
    {
        "label": "VCTR - Victory Capital Holdings, Inc.",
        "value": "VCTR"
    },
    {
        "label": "PPBI - PACIFIC PREMIER BANCORP INC",
        "value": "PPBI"
    },
    {
        "label": "IHS - IHS Holding Ltd",
        "value": "IHS"
    },
    {
        "label": "IIPR - INNOVATIVE INDUSTRIAL PROPERTIES INC",
        "value": "IIPR"
    },
    {
        "label": "GRBK - Green Brick Partners, Inc.",
        "value": "GRBK"
    },
    {
        "label": "SLG - SL GREEN REALTY CORP",
        "value": "SLG"
    },
    {
        "label": "FA - FIRST ADVANTAGE CORP",
        "value": "FA"
    },
    {
        "label": "ESTE - EARTHSTONE ENERGY INC",
        "value": "ESTE"
    },
    {
        "label": "DNUT - Krispy Kreme, Inc.",
        "value": "DNUT"
    },
    {
        "label": "BGC - BGC Group, Inc.",
        "value": "BGC"
    },
    {
        "label": "ESBA - Empire State Realty OP, L.P.",
        "value": "ESBA"
    },
    {
        "label": "NAVI - NAVIENT CORP",
        "value": "NAVI"
    },
    {
        "label": "HMY - HARMONY GOLD MINING CO LTD",
        "value": "HMY"
    },
    {
        "label": "SSII - SS Innovations International, Inc.",
        "value": "SSII"
    },
    {
        "label": "LAZR - Luminar Technologies, Inc./DE",
        "value": "LAZR"
    },
    {
        "label": "IDCC - InterDigital, Inc.",
        "value": "IDCC"
    },
    {
        "label": "FULT - FULTON FINANCIAL CORP",
        "value": "FULT"
    },
    {
        "label": "CNK - Cinemark Holdings, Inc.",
        "value": "CNK"
    },
    {
        "label": "NHI - NATIONAL HEALTH INVESTORS INC",
        "value": "NHI"
    },
    {
        "label": "NZF - Nuveen Municipal Credit Income Fund",
        "value": "NZF"
    },
    {
        "label": "AESI - Atlas Energy Solutions Inc.",
        "value": "AESI"
    },
    {
        "label": "KW - Kennedy-Wilson Holdings, Inc.",
        "value": "KW"
    },
    {
        "label": "FCPT - Four Corners Property Trust, Inc.",
        "value": "FCPT"
    },
    {
        "label": "TRIP - TripAdvisor, Inc.",
        "value": "TRIP"
    },
    {
        "label": "PD - PagerDuty, Inc.",
        "value": "PD"
    },
    {
        "label": "STGW - Stagwell Inc",
        "value": "STGW"
    },
    {
        "label": "BOH - BANK OF HAWAII CORP",
        "value": "BOH"
    },
    {
        "label": "ROCK - GIBRALTAR INDUSTRIES, INC.",
        "value": "ROCK"
    },
    {
        "label": "CAAP - CORPORACION AMERICA AIRPORTS S.A.",
        "value": "CAAP"
    },
    {
        "label": "MOD - MODINE MANUFACTURING CO",
        "value": "MOD"
    },
    {
        "label": "MRCY - MERCURY SYSTEMS INC",
        "value": "MRCY"
    },
    {
        "label": "IAS - INTEGRAL AD SCIENCE HOLDING CORP.",
        "value": "IAS"
    },
    {
        "label": "GDS - GDS Holdings Ltd",
        "value": "GDS"
    },
    {
        "label": "OPEN - Opendoor Technologies Inc.",
        "value": "OPEN"
    },
    {
        "label": "TALO - TALOS ENERGY INC.",
        "value": "TALO"
    },
    {
        "label": "MTRN - MATERION Corp",
        "value": "MTRN"
    },
    {
        "label": "VRNT - VERINT SYSTEMS INC",
        "value": "VRNT"
    },
    {
        "label": "JAMF - Jamf Holding Corp.",
        "value": "JAMF"
    },
    {
        "label": "JBLU - JETBLUE AIRWAYS CORP",
        "value": "JBLU"
    },
    {
        "label": "RIOT - Riot Platforms, Inc.",
        "value": "RIOT"
    },
    {
        "label": "SJW - SJW GROUP",
        "value": "SJW"
    },
    {
        "label": "MWA - Mueller Water Products, Inc.",
        "value": "MWA"
    },
    {
        "label": "MARA - MARATHON DIGITAL HOLDINGS, INC.",
        "value": "MARA"
    },
    {
        "label": "PAX - Patria Investments Ltd",
        "value": "PAX"
    },
    {
        "label": "KMT - KENNAMETAL INC",
        "value": "KMT"
    },
    {
        "label": "PARR - PAR PACIFIC HOLDINGS, INC.",
        "value": "PARR"
    },
    {
        "label": "OII - OCEANEERING INTERNATIONAL INC",
        "value": "OII"
    },
    {
        "label": "SVMB - SavMobi Technology Inc.",
        "value": "SVMB"
    },
    {
        "label": "EE - Excelerate Energy, Inc.",
        "value": "EE"
    },
    {
        "label": "GTX - Garrett Motion Inc.",
        "value": "GTX"
    },
    {
        "label": "TDS - TELEPHONE & DATA SYSTEMS INC /DE/",
        "value": "TDS"
    },
    {
        "label": "AMBP - Ardagh Metal Packaging S.A.",
        "value": "AMBP"
    },
    {
        "label": "CRDO - Credo Technology Group Holding Ltd",
        "value": "CRDO"
    },
    {
        "label": "ARCO - Arcos Dorados Holdings Inc.",
        "value": "ARCO"
    },
    {
        "label": "EVEX - Eve Holding, Inc.",
        "value": "EVEX"
    },
    {
        "label": "ANF - ABERCROMBIE & FITCH CO /DE/",
        "value": "ANF"
    },
    {
        "label": "CRCT - Cricut, Inc.",
        "value": "CRCT"
    },
    {
        "label": "AMK - AssetMark Financial Holdings, Inc.",
        "value": "AMK"
    },
    {
        "label": "HCC - WARRIOR MET COAL, INC.",
        "value": "HCC"
    },
    {
        "label": "ADX - ADAMS DIVERSIFIED EQUITY FUND, INC.",
        "value": "ADX"
    },
    {
        "label": "UTF - COHEN & STEERS INFRASTRUCTURE FUND INC",
        "value": "UTF"
    },
    {
        "label": "TMDX - TransMedics Group, Inc.",
        "value": "TMDX"
    },
    {
        "label": "TRN - TRINITY INDUSTRIES INC",
        "value": "TRN"
    },
    {
        "label": "EQC - Equity Commonwealth",
        "value": "EQC"
    },
    {
        "label": "PRFT - PERFICIENT INC",
        "value": "PRFT"
    },
    {
        "label": "CURLF - Curaleaf Holdings, Inc.",
        "value": "CURLF"
    },
    {
        "label": "CWK - Cushman & Wakefield plc",
        "value": "CWK"
    },
    {
        "label": "AIR - AAR CORP",
        "value": "AIR"
    },
    {
        "label": "PAYO - Payoneer Global Inc.",
        "value": "PAYO"
    },
    {
        "label": "LVWR - LiveWire Group, Inc.",
        "value": "LVWR"
    },
    {
        "label": "CARG - CarGurus, Inc.",
        "value": "CARG"
    },
    {
        "label": "ALG - ALAMO GROUP INC",
        "value": "ALG"
    },
    {
        "label": "CEPU - CENTRAL PUERTO S.A.",
        "value": "CEPU"
    },
    {
        "label": "FFBC - FIRST FINANCIAL BANCORP /OH/",
        "value": "FFBC"
    },
    {
        "label": "RAMP - LiveRamp Holdings, Inc.",
        "value": "RAMP"
    },
    {
        "label": "AYX - Alteryx, Inc.",
        "value": "AYX"
    },
    {
        "label": "GPOR - GULFPORT ENERGY CORP",
        "value": "GPOR"
    },
    {
        "label": "PTXKY - PT XL Axiata Tbk/ADR",
        "value": "PTXKY"
    },
    {
        "label": "PIPR - PIPER SANDLER COMPANIES",
        "value": "PIPR"
    },
    {
        "label": "RUM - Rumble Inc.",
        "value": "RUM"
    },
    {
        "label": "AVDX - AvidXchange Holdings, Inc.",
        "value": "AVDX"
    },
    {
        "label": "CSIQ - Canadian Solar Inc.",
        "value": "CSIQ"
    },
    {
        "label": "OLK - Olink Holding AB (publ)",
        "value": "OLK"
    },
    {
        "label": "MCW - Mister Car Wash, Inc.",
        "value": "MCW"
    },
    {
        "label": "WLY - JOHN WILEY & SONS, INC.",
        "value": "WLY"
    },
    {
        "label": "NUVA - NUVASIVE INC",
        "value": "NUVA"
    },
    {
        "label": "STAA - STAAR SURGICAL CO",
        "value": "STAA"
    },
    {
        "label": "CAMT - CAMTEK LTD",
        "value": "CAMT"
    },
    {
        "label": "NTCT - NETSCOUT SYSTEMS INC",
        "value": "NTCT"
    },
    {
        "label": "SNEX - StoneX Group Inc.",
        "value": "SNEX"
    },
    {
        "label": "USAC - USA Compression Partners, LP",
        "value": "USAC"
    },
    {
        "label": "GSAT - Globalstar, Inc.",
        "value": "GSAT"
    },
    {
        "label": "RDNT - RadNet, Inc.",
        "value": "RDNT"
    },
    {
        "label": "FUN - CEDAR FAIR L P",
        "value": "FUN"
    },
    {
        "label": "SYSX - Sysorex, Inc.",
        "value": "SYSX"
    },
    {
        "label": "AROC - Archrock, Inc.",
        "value": "AROC"
    },
    {
        "label": "CMRF - CIM REAL ESTATE FINANCE TRUST, INC.",
        "value": "CMRF"
    },
    {
        "label": "UTG - REAVES UTILITY INCOME FUND",
        "value": "UTG"
    },
    {
        "label": "CPK - CHESAPEAKE UTILITIES CORP",
        "value": "CPK"
    },
    {
        "label": "KTOS - KRATOS DEFENSE & SECURITY SOLUTIONS, INC.",
        "value": "KTOS"
    },
    {
        "label": "THRM - GENTHERM Inc",
        "value": "THRM"
    },
    {
        "label": "SCL - STEPAN CO",
        "value": "SCL"
    },
    {
        "label": "CVAC - CureVac N.V.",
        "value": "CVAC"
    },
    {
        "label": "UE - Urban Edge Properties",
        "value": "UE"
    },
    {
        "label": "CTRE - CareTrust REIT, Inc.",
        "value": "CTRE"
    },
    {
        "label": "TROX - Tronox Holdings plc",
        "value": "TROX"
    },
    {
        "label": "BVN - BUENAVENTURA MINING CO INC",
        "value": "BVN"
    },
    {
        "label": "HTH - Hilltop Holdings Inc.",
        "value": "HTH"
    },
    {
        "label": "QTWO - Q2 Holdings, Inc.",
        "value": "QTWO"
    },
    {
        "label": "SBCF - SEACOAST BANKING CORP OF FLORIDA",
        "value": "SBCF"
    },
    {
        "label": "ENLT - Enlight Renewable Energy Ltd.",
        "value": "ENLT"
    },
    {
        "label": "TBBK - Bancorp, Inc.",
        "value": "TBBK"
    },
    {
        "label": "WGO - WINNEBAGO INDUSTRIES INC",
        "value": "WGO"
    },
    {
        "label": "HRMY - Harmony Biosciences Holdings, Inc.",
        "value": "HRMY"
    },
    {
        "label": "EVCM - EverCommerce Inc.",
        "value": "EVCM"
    },
    {
        "label": "OSIS - OSI SYSTEMS INC",
        "value": "OSIS"
    },
    {
        "label": "B - BARNES GROUP INC",
        "value": "B"
    },
    {
        "label": "FSR - Fisker Inc./DE",
        "value": "FSR"
    },
    {
        "label": "HBI - Hanesbrands Inc.",
        "value": "HBI"
    },
    {
        "label": "LILA - Liberty Latin America Ltd.",
        "value": "LILA"
    },
    {
        "label": "BKU - BankUnited, Inc.",
        "value": "BKU"
    },
    {
        "label": "ATMU - Atmus Filtration Technologies Inc.",
        "value": "ATMU"
    },
    {
        "label": "NXRT - NexPoint Residential Trust, Inc.",
        "value": "NXRT"
    },
    {
        "label": "FTCH - Farfetch Ltd",
        "value": "FTCH"
    },
    {
        "label": "SXI - STANDEX INTERNATIONAL CORP/DE/",
        "value": "SXI"
    },
    {
        "label": "SBLK - Star Bulk Carriers Corp.",
        "value": "SBLK"
    },
    {
        "label": "UPWK - UPWORK, INC",
        "value": "UPWK"
    },
    {
        "label": "EPC - EDGEWELL PERSONAL CARE Co",
        "value": "EPC"
    },
    {
        "label": "ETY - Eaton Vance Tax-Managed Diversified Equity Income Fund",
        "value": "ETY"
    },
    {
        "label": "HURN - Huron Consulting Group Inc.",
        "value": "HURN"
    },
    {
        "label": "BOWL - Bowlero Corp.",
        "value": "BOWL"
    },
    {
        "label": "CBRL - CRACKER BARREL OLD COUNTRY STORE, INC",
        "value": "CBRL"
    },
    {
        "label": "GPRE - Green Plains Inc.",
        "value": "GPRE"
    },
    {
        "label": "BFH - BREAD FINANCIAL HOLDINGS, INC.",
        "value": "BFH"
    },
    {
        "label": "GDV - GABELLI DIVIDEND & INCOME TRUST",
        "value": "GDV"
    },
    {
        "label": "MOMO - Hello Group Inc.",
        "value": "MOMO"
    },
    {
        "label": "BORR - Borr Drilling Ltd",
        "value": "BORR"
    },
    {
        "label": "MTX - MINERALS TECHNOLOGIES INC",
        "value": "MTX"
    },
    {
        "label": "WMK - WEIS MARKETS INC",
        "value": "WMK"
    },
    {
        "label": "HLIO - HELIOS TECHNOLOGIES, INC.",
        "value": "HLIO"
    },
    {
        "label": "CLBK - Columbia Financial, Inc.",
        "value": "CLBK"
    },
    {
        "label": "ATGE - Adtalem Global Education Inc.",
        "value": "ATGE"
    },
    {
        "label": "FORG - ForgeRock, Inc.",
        "value": "FORG"
    },
    {
        "label": "GVA - GRANITE CONSTRUCTION INC",
        "value": "GVA"
    },
    {
        "label": "BKE - BUCKLE INC",
        "value": "BKE"
    },
    {
        "label": "SHO - Sunstone Hotel Investors, Inc.",
        "value": "SHO"
    },
    {
        "label": "VRE - Veris Residential, Inc.",
        "value": "VRE"
    },
    {
        "label": "WAFD - WASHINGTON FEDERAL INC",
        "value": "WAFD"
    },
    {
        "label": "TLRY - Tilray Brands, Inc.",
        "value": "TLRY"
    },
    {
        "label": "OUT - OUTFRONT Media Inc.",
        "value": "OUT"
    },
    {
        "label": "AILIH - Ameren Illinois Co",
        "value": "AILIH"
    },
    {
        "label": "NGVT - Ingevity Corp",
        "value": "NGVT"
    },
    {
        "label": "MXL - MAXLINEAR, INC",
        "value": "MXL"
    },
    {
        "label": "ODP - ODP Corp",
        "value": "ODP"
    },
    {
        "label": "SIMO - Silicon Motion Technology CORP",
        "value": "SIMO"
    },
    {
        "label": "ERO - Ero Copper Corp.",
        "value": "ERO"
    },
    {
        "label": "DVAX - DYNAVAX TECHNOLOGIES CORP",
        "value": "DVAX"
    },
    {
        "label": "SAVE - Spirit Airlines, Inc.",
        "value": "SAVE"
    },
    {
        "label": "SAH - SONIC AUTOMOTIVE INC",
        "value": "SAH"
    },
    {
        "label": "PRIM - Primoris Services Corp",
        "value": "PRIM"
    },
    {
        "label": "TNDM - TANDEM DIABETES CARE INC",
        "value": "TNDM"
    },
    {
        "label": "HPK - HighPeak Energy, Inc.",
        "value": "HPK"
    },
    {
        "label": "PTY - PIMCO CORPORATE & INCOME OPPORTUNITY FUND",
        "value": "PTY"
    },
    {
        "label": "ZNTL - Zentalis Pharmaceuticals, Inc.",
        "value": "ZNTL"
    },
    {
        "label": "KNTK - Kinetik Holdings Inc.",
        "value": "KNTK"
    },
    {
        "label": "PEB - Pebblebrook Hotel Trust",
        "value": "PEB"
    },
    {
        "label": "WOOF - Petco Health & Wellness Company, Inc.",
        "value": "WOOF"
    },
    {
        "label": "AGM - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM"
    },
    {
        "label": "FRME - FIRST MERCHANTS CORP",
        "value": "FRME"
    },
    {
        "label": "SWI - SolarWinds Corp",
        "value": "SWI"
    },
    {
        "label": "BROS - Dutch Bros Inc.",
        "value": "BROS"
    },
    {
        "label": "LRN - Stride, Inc.",
        "value": "LRN"
    },
    {
        "label": "BEAM - Beam Therapeutics Inc.",
        "value": "BEAM"
    },
    {
        "label": "DK - Delek US Holdings, Inc.",
        "value": "DK"
    },
    {
        "label": "STRA - Strategic Education, Inc.",
        "value": "STRA"
    },
    {
        "label": "CVII - Churchill Capital Corp VII",
        "value": "CVII"
    },
    {
        "label": "RC - Ready Capital Corp",
        "value": "RC"
    },
    {
        "label": "SONO - Sonos Inc",
        "value": "SONO"
    },
    {
        "label": "HLMN - Hillman Solutions Corp.",
        "value": "HLMN"
    },
    {
        "label": "SKYW - SKYWEST INC",
        "value": "SKYW"
    },
    {
        "label": "EGO - ELDORADO GOLD CORP /FI",
        "value": "EGO"
    },
    {
        "label": "PATK - PATRICK INDUSTRIES INC",
        "value": "PATK"
    },
    {
        "label": "AGYS - AGILYSYS INC",
        "value": "AGYS"
    },
    {
        "label": "CLM - CORNERSTONE STRATEGIC VALUE FUND INC",
        "value": "CLM"
    },
    {
        "label": "AMEH - Apollo Medical Holdings, Inc.",
        "value": "AMEH"
    },
    {
        "label": "RES - RPC INC",
        "value": "RES"
    },
    {
        "label": "SWTX - SpringWorks Therapeutics, Inc.",
        "value": "SWTX"
    },
    {
        "label": "NS - NuStar Energy L.P.",
        "value": "NS"
    },
    {
        "label": "SIX - Six Flags Entertainment Corp",
        "value": "SIX"
    },
    {
        "label": "ALGT - Allegiant Travel CO",
        "value": "ALGT"
    },
    {
        "label": "VCYT - VERACYTE, INC.",
        "value": "VCYT"
    },
    {
        "label": "GOF - GUGGENHEIM STRATEGIC OPPORTUNITIES FUND",
        "value": "GOF"
    },
    {
        "label": "JBI - Janus International Group, Inc.",
        "value": "JBI"
    },
    {
        "label": "OLPX - OLAPLEX HOLDINGS, INC.",
        "value": "OLPX"
    },
    {
        "label": "IBTX - Independent Bank Group, Inc.",
        "value": "IBTX"
    },
    {
        "label": "NUV - NUVEEN MUNICIPAL VALUE FUND INC",
        "value": "NUV"
    },
    {
        "label": "ROAD - Construction Partners, Inc.",
        "value": "ROAD"
    },
    {
        "label": "MRTN - MARTEN TRANSPORT LTD",
        "value": "MRTN"
    },
    {
        "label": "LUMN - Lumen Technologies, Inc.",
        "value": "LUMN"
    },
    {
        "label": "VTYX - Ventyx Biosciences, Inc.",
        "value": "VTYX"
    },
    {
        "label": "SGHC - Super Group (SGHC) Ltd",
        "value": "SGHC"
    },
    {
        "label": "TGH - TEXTAINER GROUP HOLDINGS LTD",
        "value": "TGH"
    },
    {
        "label": "NEO - NEOGENOMICS INC",
        "value": "NEO"
    },
    {
        "label": "ALTB - Alpine Auto Brokers Inc.",
        "value": "ALTB"
    },
    {
        "label": "TSLX - Sixth Street Specialty Lending, Inc.",
        "value": "TSLX"
    },
    {
        "label": "CMPR - CIMPRESS plc",
        "value": "CMPR"
    },
    {
        "label": "BIGZ - BlackRock Innovation & Growth Term Trust",
        "value": "BIGZ"
    },
    {
        "label": "BRDG - Bridge Investment Group Holdings Inc.",
        "value": "BRDG"
    },
    {
        "label": "SPNT - SiriusPoint Ltd",
        "value": "SPNT"
    },
    {
        "label": "CCYC - Clancy Corp",
        "value": "CCYC"
    },
    {
        "label": "CSGS - CSG SYSTEMS INTERNATIONAL INC",
        "value": "CSGS"
    },
    {
        "label": "DKL - Delek Logistics Partners, LP",
        "value": "DKL"
    },
    {
        "label": "PLAY - Dave & Buster's Entertainment, Inc.",
        "value": "PLAY"
    },
    {
        "label": "VSTO - Vista Outdoor Inc.",
        "value": "VSTO"
    },
    {
        "label": "ROIC - RETAIL OPPORTUNITY INVESTMENTS CORP",
        "value": "ROIC"
    },
    {
        "label": "VIR - Vir Biotechnology, Inc.",
        "value": "VIR"
    },
    {
        "label": "BMEZ - BlackRock Health Sciences Term Trust",
        "value": "BMEZ"
    },
    {
        "label": "BNRE - Brookfield Reinsurance Ltd.",
        "value": "BNRE"
    },
    {
        "label": "COHU - COHU INC",
        "value": "COHU"
    },
    {
        "label": "USA - LIBERTY ALL STAR EQUITY FUND",
        "value": "USA"
    },
    {
        "label": "PRK - PARK NATIONAL CORP /OH/",
        "value": "PRK"
    },
    {
        "label": "SATS - EchoStar CORP",
        "value": "SATS"
    },
    {
        "label": "PLUS - EPLUS INC",
        "value": "PLUS"
    },
    {
        "label": "ACLX - Arcellx, Inc.",
        "value": "ACLX"
    },
    {
        "label": "PAY - Paymentus Holdings, Inc.",
        "value": "PAY"
    },
    {
        "label": "EVT - EATON VANCE TAX ADVANTAGED DIVIDEND INCOME FUND",
        "value": "EVT"
    },
    {
        "label": "ACDC - ProFrac Holding Corp.",
        "value": "ACDC"
    },
    {
        "label": "RXRX - RECURSION PHARMACEUTICALS, INC.",
        "value": "RXRX"
    },
    {
        "label": "SABR - Sabre Corp",
        "value": "SABR"
    },
    {
        "label": "PCRX - Pacira BioSciences, Inc.",
        "value": "PCRX"
    },
    {
        "label": "ATEC - Alphatec Holdings, Inc.",
        "value": "ATEC"
    },
    {
        "label": "CAKE - CHEESECAKE FACTORY INC",
        "value": "CAKE"
    },
    {
        "label": "SUPN - SUPERNUS PHARMACEUTICALS, INC.",
        "value": "SUPN"
    },
    {
        "label": "HMNTY - Hemnet Group AB/ADR",
        "value": "HMNTY"
    },
    {
        "label": "ANDE - Andersons, Inc.",
        "value": "ANDE"
    },
    {
        "label": "JKS - JinkoSolar Holding Co., Ltd.",
        "value": "JKS"
    },
    {
        "label": "NMRK - NEWMARK GROUP, INC.",
        "value": "NMRK"
    },
    {
        "label": "FLNG - Flex LNG Ltd.",
        "value": "FLNG"
    },
    {
        "label": "JACK - JACK IN THE BOX INC",
        "value": "JACK"
    },
    {
        "label": "FWRD - FORWARD AIR CORP",
        "value": "FWRD"
    },
    {
        "label": "LGF-A - LIONS GATE ENTERTAINMENT CORP /CN/",
        "value": "LGF-A"
    },
    {
        "label": "MCY - MERCURY GENERAL CORP",
        "value": "MCY"
    },
    {
        "label": "VSCO - Victoria's Secret & Co.",
        "value": "VSCO"
    },
    {
        "label": "DRH - DiamondRock Hospitality Co",
        "value": "DRH"
    },
    {
        "label": "MIR - Mirion Technologies, Inc.",
        "value": "MIR"
    },
    {
        "label": "ZIP - ZIPRECRUITER, INC.",
        "value": "ZIP"
    },
    {
        "label": "HEES - H&E Equipment Services, Inc.",
        "value": "HEES"
    },
    {
        "label": "HBM - Hudbay Minerals Inc.",
        "value": "HBM"
    },
    {
        "label": "RNST - RENASANT CORP",
        "value": "RNST"
    },
    {
        "label": "VGR - VECTOR GROUP LTD",
        "value": "VGR"
    },
    {
        "label": "UPBD - UPBOUND GROUP, INC.",
        "value": "UPBD"
    },
    {
        "label": "SLVM - Sylvamo Corp",
        "value": "SLVM"
    },
    {
        "label": "BRP - BRP Group, Inc.",
        "value": "BRP"
    },
    {
        "label": "NWLI - National Western Life Group, Inc.",
        "value": "NWLI"
    },
    {
        "label": "DHT - DHT Holdings, Inc.",
        "value": "DHT"
    },
    {
        "label": "KAR - OPENLANE, Inc.",
        "value": "KAR"
    },
    {
        "label": "AQPW - Golden Ally Lifetech Group, Inc.",
        "value": "AQPW"
    },
    {
        "label": "CRTO - Criteo S.A.",
        "value": "CRTO"
    },
    {
        "label": "JBGS - JBG SMITH Properties",
        "value": "JBGS"
    },
    {
        "label": "MBC - MasterBrand, Inc.",
        "value": "MBC"
    },
    {
        "label": "AAT - American Assets Trust, Inc.",
        "value": "AAT"
    },
    {
        "label": "GOGO - Gogo Inc.",
        "value": "GOGO"
    },
    {
        "label": "SPNS - SAPIENS INTERNATIONAL CORP N V",
        "value": "SPNS"
    },
    {
        "label": "INTR - Inter & Co, Inc.",
        "value": "INTR"
    },
    {
        "label": "GTBIF - Green Thumb Industries Inc.",
        "value": "GTBIF"
    },
    {
        "label": "SMTC - SEMTECH CORP",
        "value": "SMTC"
    },
    {
        "label": "PGTI - PGT Innovations, Inc.",
        "value": "PGTI"
    },
    {
        "label": "BCAT - BlackRock Capital Allocation Term Trust",
        "value": "BCAT"
    },
    {
        "label": "ZETA - Zeta Global Holdings Corp.",
        "value": "ZETA"
    },
    {
        "label": "OXM - OXFORD INDUSTRIES INC",
        "value": "OXM"
    },
    {
        "label": "GAB - GABELLI EQUITY TRUST INC",
        "value": "GAB"
    },
    {
        "label": "CTOS - Custom Truck One Source, Inc.",
        "value": "CTOS"
    },
    {
        "label": "AG - FIRST MAJESTIC SILVER CORP",
        "value": "AG"
    },
    {
        "label": "PBAJ - PETRO USA, INC.",
        "value": "PBAJ"
    },
    {
        "label": "NOVA - Sunnova Energy International Inc.",
        "value": "NOVA"
    },
    {
        "label": "PRO - PROS Holdings, Inc.",
        "value": "PRO"
    },
    {
        "label": "GETY - Getty Images Holdings, Inc.",
        "value": "GETY"
    },
    {
        "label": "ABCL - AbCellera Biologics Inc.",
        "value": "ABCL"
    },
    {
        "label": "LTGHY - Life Healthcare Group Holdings Limited/ADR",
        "value": "LTGHY"
    },
    {
        "label": "VVX - V2X, Inc.",
        "value": "VVX"
    },
    {
        "label": "TGTX - TG THERAPEUTICS, INC.",
        "value": "TGTX"
    },
    {
        "label": "FIHL - Fidelis Insurance Holdings Ltd",
        "value": "FIHL"
    },
    {
        "label": "PCT - PureCycle Technologies, Inc.",
        "value": "PCT"
    },
    {
        "label": "PRG - PROG Holdings, Inc.",
        "value": "PRG"
    },
    {
        "label": "MSGE - Madison Square Garden Entertainment Corp.",
        "value": "MSGE"
    },
    {
        "label": "ENVA - Enova International, Inc.",
        "value": "ENVA"
    },
    {
        "label": "GOOS - Canada Goose Holdings Inc.",
        "value": "GOOS"
    },
    {
        "label": "OSCR - Oscar Health, Inc.",
        "value": "OSCR"
    },
    {
        "label": "IVT - InvenTrust Properties Corp.",
        "value": "IVT"
    },
    {
        "label": "TGLS - Tecnoglass Inc.",
        "value": "TGLS"
    },
    {
        "label": "IMKTA - INGLES MARKETS INC",
        "value": "IMKTA"
    },
    {
        "label": "GSBD - Goldman Sachs BDC, Inc.",
        "value": "GSBD"
    },
    {
        "label": "WSBC - WESBANCO INC",
        "value": "WSBC"
    },
    {
        "label": "IOVA - IOVANCE BIOTHERAPEUTICS, INC.",
        "value": "IOVA"
    },
    {
        "label": "BTDR - Bitdeer Technologies Group",
        "value": "BTDR"
    },
    {
        "label": "DO - DIAMOND OFFSHORE DRILLING, INC.",
        "value": "DO"
    },
    {
        "label": "ULCC - Frontier Group Holdings, Inc.",
        "value": "ULCC"
    },
    {
        "label": "IE - Ivanhoe Electric Inc.",
        "value": "IE"
    },
    {
        "label": "CPRX - CATALYST PHARMACEUTICALS, INC.",
        "value": "CPRX"
    },
    {
        "label": "AHCO - AdaptHealth Corp.",
        "value": "AHCO"
    },
    {
        "label": "RLJ - RLJ Lodging Trust",
        "value": "RLJ"
    },
    {
        "label": "ECAT - BlackRock ESG Capital Allocation Term Trust",
        "value": "ECAT"
    },
    {
        "label": "PERI - Perion Network Ltd.",
        "value": "PERI"
    },
    {
        "label": "TWKS - Thoughtworks Holding, Inc.",
        "value": "TWKS"
    },
    {
        "label": "ATSG - Air Transport Services Group, Inc.",
        "value": "ATSG"
    },
    {
        "label": "EYE - National Vision Holdings, Inc.",
        "value": "EYE"
    },
    {
        "label": "PI - IMPINJ INC",
        "value": "PI"
    },
    {
        "label": "NVEE - NV5 Global, Inc.",
        "value": "NVEE"
    },
    {
        "label": "GTY - GETTY REALTY CORP /MD/",
        "value": "GTY"
    },
    {
        "label": "HLF - HERBALIFE LTD.",
        "value": "HLF"
    },
    {
        "label": "VCEL - Vericel Corp",
        "value": "VCEL"
    },
    {
        "label": "ACHR - Archer Aviation Inc.",
        "value": "ACHR"
    },
    {
        "label": "AKR - ACADIA REALTY TRUST",
        "value": "AKR"
    },
    {
        "label": "ERII - Energy Recovery, Inc.",
        "value": "ERII"
    },
    {
        "label": "BANR - BANNER CORP",
        "value": "BANR"
    },
    {
        "label": "NBTB - NBT BANCORP INC",
        "value": "NBTB"
    },
    {
        "label": "CRSR - Corsair Gaming, Inc.",
        "value": "CRSR"
    },
    {
        "label": "RQI - COHEN & STEERS QUALITY INCOME REALTY FUND INC",
        "value": "RQI"
    },
    {
        "label": "KN - Knowles Corp",
        "value": "KN"
    },
    {
        "label": "GOGL - Golden Ocean Group Ltd",
        "value": "GOGL"
    },
    {
        "label": "TH - Target Hospitality Corp.",
        "value": "TH"
    },
    {
        "label": "CODI - Compass Diversified Holdings",
        "value": "CODI"
    },
    {
        "label": "SAND - SANDSTORM GOLD LTD",
        "value": "SAND"
    },
    {
        "label": "SSTK - Shutterstock, Inc.",
        "value": "SSTK"
    },
    {
        "label": "TFIN - Triumph Financial, Inc.",
        "value": "TFIN"
    },
    {
        "label": "NAC - Nuveen California Quality Municipal Income Fund",
        "value": "NAC"
    },
    {
        "label": "SG - Sweetgreen, Inc.",
        "value": "SG"
    },
    {
        "label": "UDMY - Udemy, Inc.",
        "value": "UDMY"
    },
    {
        "label": "OCSL - Oaktree Specialty Lending Corp",
        "value": "OCSL"
    },
    {
        "label": "LOB - Live Oak Bancshares, Inc.",
        "value": "LOB"
    },
    {
        "label": "IRWD - IRONWOOD PHARMACEUTICALS INC",
        "value": "IRWD"
    },
    {
        "label": "AHL-PC - ASPEN INSURANCE HOLDINGS LTD",
        "value": "AHL-PC"
    },
    {
        "label": "FBK - FB Financial Corp",
        "value": "FBK"
    },
    {
        "label": "RVNC - Revance Therapeutics, Inc.",
        "value": "RVNC"
    },
    {
        "label": "COCO - Vita Coco Company, Inc.",
        "value": "COCO"
    },
    {
        "label": "ATUS - Altice USA, Inc.",
        "value": "ATUS"
    },
    {
        "label": "ZIM - ZIM Integrated Shipping Services Ltd.",
        "value": "ZIM"
    },
    {
        "label": "COMP - Compass, Inc.",
        "value": "COMP"
    },
    {
        "label": "RVT - ROYCE VALUE TRUST, INC.",
        "value": "RVT"
    },
    {
        "label": "TNK - TEEKAY TANKERS LTD.",
        "value": "TNK"
    },
    {
        "label": "EFSC - ENTERPRISE FINANCIAL SERVICES CORP",
        "value": "EFSC"
    },
    {
        "label": "EAT - BRINKER INTERNATIONAL, INC",
        "value": "EAT"
    },
    {
        "label": "TNC - TENNANT CO",
        "value": "TNC"
    },
    {
        "label": "RADI - Radius Global Infrastructure, Inc.",
        "value": "RADI"
    },
    {
        "label": "TTMI - TTM TECHNOLOGIES INC",
        "value": "TTMI"
    },
    {
        "label": "HNI - HNI CORP",
        "value": "HNI"
    },
    {
        "label": "LOMA - Loma Negra Compania Industrial Argentina Sociedad Anonima",
        "value": "LOMA"
    },
    {
        "label": "BDJ - BlackRock Enhanced Equity Dividend Trust",
        "value": "BDJ"
    },
    {
        "label": "VRTS - VIRTUS INVESTMENT PARTNERS, INC.",
        "value": "VRTS"
    },
    {
        "label": "TY - TRI-CONTINENTAL CORP",
        "value": "TY"
    },
    {
        "label": "PTVE - Pactiv Evergreen Inc.",
        "value": "PTVE"
    },
    {
        "label": "ADUS - Addus HomeCare Corp",
        "value": "ADUS"
    },
    {
        "label": "TRMK - TRUSTMARK CORP",
        "value": "TRMK"
    },
    {
        "label": "OFG - OFG BANCORP",
        "value": "OFG"
    },
    {
        "label": "MLKN - MILLERKNOLL, INC.",
        "value": "MLKN"
    },
    {
        "label": "VECO - VEECO INSTRUMENTS INC",
        "value": "VECO"
    },
    {
        "label": "FINV - FinVolution Group",
        "value": "FINV"
    },
    {
        "label": "USPH - U S PHYSICAL THERAPY INC /NV",
        "value": "USPH"
    },
    {
        "label": "NWN - Northwest Natural Holding Co",
        "value": "NWN"
    },
    {
        "label": "NAPA - Duckhorn Portfolio, Inc.",
        "value": "NAPA"
    },
    {
        "label": "MLNK - MeridianLink, Inc.",
        "value": "MLNK"
    },
    {
        "label": "CLBT - Cellebrite DI Ltd.",
        "value": "CLBT"
    },
    {
        "label": "CMTG - Claros Mortgage Trust, Inc.",
        "value": "CMTG"
    },
    {
        "label": "PGY - Pagaya Technologies Ltd.",
        "value": "PGY"
    },
    {
        "label": "AGIO - AGIOS PHARMACEUTICALS, INC.",
        "value": "AGIO"
    },
    {
        "label": "PX - P10, Inc.",
        "value": "PX"
    },
    {
        "label": "HIMS - Hims & Hers Health, Inc.",
        "value": "HIMS"
    },
    {
        "label": "GCMG - GCM Grosvenor Inc.",
        "value": "GCMG"
    },
    {
        "label": "NWBI - Northwest Bancshares, Inc.",
        "value": "NWBI"
    },
    {
        "label": "BTT - BlackRock Municipal 2030 Target Term Trust",
        "value": "BTT"
    },
    {
        "label": "UCTT - Ultra Clean Holdings, Inc.",
        "value": "UCTT"
    },
    {
        "label": "KGS - Kodiak Gas Services, Inc.",
        "value": "KGS"
    },
    {
        "label": "ARI - Apollo Commercial Real Estate Finance, Inc.",
        "value": "ARI"
    },
    {
        "label": "FOR - Forestar Group Inc.",
        "value": "FOR"
    },
    {
        "label": "EPAC - ENERPAC TOOL GROUP CORP",
        "value": "EPAC"
    },
    {
        "label": "SEAT - Vivid Seats Inc.",
        "value": "SEAT"
    },
    {
        "label": "NAAS - NaaS Technology Inc.",
        "value": "NAAS"
    },
    {
        "label": "ETWO - E2open Parent Holdings, Inc.",
        "value": "ETWO"
    },
    {
        "label": "PHR - Phreesia, Inc.",
        "value": "PHR"
    },
    {
        "label": "NTB - Bank of N.T. Butterfield & Son Ltd",
        "value": "NTB"
    },
    {
        "label": "ARHS - Arhaus, Inc.",
        "value": "ARHS"
    },
    {
        "label": "CNNE - Cannae Holdings, Inc.",
        "value": "CNNE"
    },
    {
        "label": "MDRX - Veradigm Inc.",
        "value": "MDRX"
    },
    {
        "label": "RILY - B. Riley Financial, Inc.",
        "value": "RILY"
    },
    {
        "label": "HSAI - Hesai Group",
        "value": "HSAI"
    },
    {
        "label": "DADA - Dada Nexus Ltd",
        "value": "DADA"
    },
    {
        "label": "VKTX - Viking Therapeutics, Inc.",
        "value": "VKTX"
    },
    {
        "label": "CLDX - Celldex Therapeutics, Inc.",
        "value": "CLDX"
    },
    {
        "label": "ALKT - ALKAMI TECHNOLOGY, INC.",
        "value": "ALKT"
    },
    {
        "label": "IESC - IES Holdings, Inc.",
        "value": "IESC"
    },
    {
        "label": "VRNA - Verona Pharma plc",
        "value": "VRNA"
    },
    {
        "label": "LKFN - LAKELAND FINANCIAL CORP",
        "value": "LKFN"
    },
    {
        "label": "IDYA - IDEAYA Biosciences, Inc.",
        "value": "IDYA"
    },
    {
        "label": "PRCT - PROCEPT BioRobotics Corp",
        "value": "PRCT"
    },
    {
        "label": "MNTK - Montauk Renewables, Inc.",
        "value": "MNTK"
    },
    {
        "label": "HLX - HELIX ENERGY SOLUTIONS GROUP INC",
        "value": "HLX"
    },
    {
        "label": "KRP - Kimbell Royalty Partners, LP",
        "value": "KRP"
    },
    {
        "label": "TTEC - TTEC Holdings, Inc.",
        "value": "TTEC"
    },
    {
        "label": "TARO - TARO PHARMACEUTICAL INDUSTRIES LTD",
        "value": "TARO"
    },
    {
        "label": "NSSC - NAPCO SECURITY TECHNOLOGIES, INC",
        "value": "NSSC"
    },
    {
        "label": "ETV - Eaton Vance Tax-Managed Buy-Write Opportunities Fund",
        "value": "ETV"
    },
    {
        "label": "ORLA - Orla Mining Ltd.",
        "value": "ORLA"
    },
    {
        "label": "PDO - PIMCO Dynamic Income Opportunities Fund",
        "value": "PDO"
    },
    {
        "label": "BBUC - Brookfield Business Corp",
        "value": "BBUC"
    },
    {
        "label": "RYTM - RHYTHM PHARMACEUTICALS, INC.",
        "value": "RYTM"
    },
    {
        "label": "CHCO - CITY HOLDING CO",
        "value": "CHCO"
    },
    {
        "label": "UEC - URANIUM ENERGY CORP",
        "value": "UEC"
    },
    {
        "label": "SYBT - Stock Yards Bancorp, Inc.",
        "value": "SYBT"
    },
    {
        "label": "WRBY - Warby Parker Inc.",
        "value": "WRBY"
    },
    {
        "label": "DAC - Danaos Corp",
        "value": "DAC"
    },
    {
        "label": "NKLA - Nikola Corp",
        "value": "NKLA"
    },
    {
        "label": "AGTI - AGILITI, INC. \\DE",
        "value": "AGTI"
    },
    {
        "label": "PLAB - PHOTRONICS INC",
        "value": "PLAB"
    },
    {
        "label": "DFIN - Donnelley Financial Solutions, Inc.",
        "value": "DFIN"
    },
    {
        "label": "EQX - Equinox Gold Corp.",
        "value": "EQX"
    },
    {
        "label": "MSEX - MIDDLESEX WATER CO",
        "value": "MSEX"
    },
    {
        "label": "NG - NOVAGOLD RESOURCES INC",
        "value": "NG"
    },
    {
        "label": "OPK - OPKO HEALTH, INC.",
        "value": "OPK"
    },
    {
        "label": "SFL - SFL Corp Ltd.",
        "value": "SFL"
    },
    {
        "label": "ECX - ECARX Holdings Inc.",
        "value": "ECX"
    },
    {
        "label": "ARVN - ARVINAS, INC.",
        "value": "ARVN"
    },
    {
        "label": "WKC - WORLD KINECT CORP",
        "value": "WKC"
    },
    {
        "label": "MYGN - MYRIAD GENETICS INC",
        "value": "MYGN"
    },
    {
        "label": "CTS - CTS CORP",
        "value": "CTS"
    },
    {
        "label": "FCF - FIRST COMMONWEALTH FINANCIAL CORP /PA/",
        "value": "FCF"
    },
    {
        "label": "BXMX - Nuveen S&P 500 BuyWrite Income Fund",
        "value": "BXMX"
    },
    {
        "label": "CNXN - PC CONNECTION INC",
        "value": "CNXN"
    },
    {
        "label": "SVC - Service Properties Trust",
        "value": "SVC"
    },
    {
        "label": "XNCR - Xencor Inc",
        "value": "XNCR"
    },
    {
        "label": "AMLX - Amylyx Pharmaceuticals, Inc.",
        "value": "AMLX"
    },
    {
        "label": "AUPH - Aurinia Pharmaceuticals Inc.",
        "value": "AUPH"
    },
    {
        "label": "SCHL - SCHOLASTIC CORP",
        "value": "SCHL"
    },
    {
        "label": "SHCO - Soho House & Co Inc.",
        "value": "SHCO"
    },
    {
        "label": "AFYA - Afya Ltd",
        "value": "AFYA"
    },
    {
        "label": "STER - Sterling Check Corp.",
        "value": "STER"
    },
    {
        "label": "NVTS - Navitas Semiconductor Corp",
        "value": "NVTS"
    },
    {
        "label": "ZUO - ZUORA INC",
        "value": "ZUO"
    },
    {
        "label": "LNN - LINDSAY CORP",
        "value": "LNN"
    },
    {
        "label": "PLMR - Palomar Holdings, Inc.",
        "value": "PLMR"
    },
    {
        "label": "GBX - GREENBRIER COMPANIES INC",
        "value": "GBX"
    },
    {
        "label": "DCBO - Docebo Inc.",
        "value": "DCBO"
    },
    {
        "label": "OMI - OWENS & MINOR INC/VA/",
        "value": "OMI"
    },
    {
        "label": "ELME - Elme Communities",
        "value": "ELME"
    },
    {
        "label": "ESTA - ESTABLISHMENT LABS HOLDINGS INC.",
        "value": "ESTA"
    },
    {
        "label": "LZB - LA-Z-BOY INC",
        "value": "LZB"
    },
    {
        "label": "BSTZ - BlackRock Science & Technology Term Trust",
        "value": "BSTZ"
    },
    {
        "label": "HTLF - HEARTLAND FINANCIAL USA INC",
        "value": "HTLF"
    },
    {
        "label": "NISUY - NIPPON SUISAN KAISHA LTD /ADR/",
        "value": "NISUY"
    },
    {
        "label": "LTC - LTC PROPERTIES INC",
        "value": "LTC"
    },
    {
        "label": "GSG - iShares S&P GSCI Commodity-Indexed Trust",
        "value": "GSG"
    },
    {
        "label": "CMRE - Costamare Inc.",
        "value": "CMRE"
    },
    {
        "label": "OPRA - Opera Ltd",
        "value": "OPRA"
    },
    {
        "label": "BHVN - Biohaven Ltd.",
        "value": "BHVN"
    },
    {
        "label": "MNKD - MANNKIND CORP",
        "value": "MNKD"
    },
    {
        "label": "STEW - SRH Total Return Fund, Inc.",
        "value": "STEW"
    },
    {
        "label": "GERN - GERON CORP",
        "value": "GERN"
    },
    {
        "label": "GENI - Genius Sports Ltd",
        "value": "GENI"
    },
    {
        "label": "MATW - MATTHEWS INTERNATIONAL CORP",
        "value": "MATW"
    },
    {
        "label": "SPWR - SUNPOWER CORP",
        "value": "SPWR"
    },
    {
        "label": "JPS - Nuveen Preferred & Income Securities Fund",
        "value": "JPS"
    },
    {
        "label": "AMPL - Amplitude, Inc.",
        "value": "AMPL"
    },
    {
        "label": "XHR - Xenia Hotels & Resorts, Inc.",
        "value": "XHR"
    },
    {
        "label": "CLMT - Calumet Specialty Products Partners, L.P.",
        "value": "CLMT"
    },
    {
        "label": "LADR - Ladder Capital Corp",
        "value": "LADR"
    },
    {
        "label": "SEMR - SEMrush Holdings, Inc.",
        "value": "SEMR"
    },
    {
        "label": "KEN - Kenon Holdings Ltd.",
        "value": "KEN"
    },
    {
        "label": "BCRX - BIOCRYST PHARMACEUTICALS INC",
        "value": "BCRX"
    },
    {
        "label": "ALEX - Alexander & Baldwin, Inc.",
        "value": "ALEX"
    },
    {
        "label": "MBIN - Merchants Bancorp",
        "value": "MBIN"
    },
    {
        "label": "CIM - CHIMERA INVESTMENT CORP",
        "value": "CIM"
    },
    {
        "label": "LESL - Leslie's, Inc.",
        "value": "LESL"
    },
    {
        "label": "RCUS - Arcus Biosciences, Inc.",
        "value": "RCUS"
    },
    {
        "label": "DOLE - Dole plc",
        "value": "DOLE"
    },
    {
        "label": "CHGG - CHEGG, INC",
        "value": "CHGG"
    },
    {
        "label": "WINA - WINMARK CORP",
        "value": "WINA"
    },
    {
        "label": "SAFE - Safehold Inc.",
        "value": "SAFE"
    },
    {
        "label": "AVPT - AvePoint, Inc.",
        "value": "AVPT"
    },
    {
        "label": "PDFS - PDF SOLUTIONS INC",
        "value": "PDFS"
    },
    {
        "label": "FDP - FRESH DEL MONTE PRODUCE INC",
        "value": "FDP"
    },
    {
        "label": "CASH - PATHWARD FINANCIAL, INC.",
        "value": "CASH"
    },
    {
        "label": "NMFC - New Mountain Finance Corp",
        "value": "NMFC"
    },
    {
        "label": "MCRI - MONARCH CASINO & RESORT INC",
        "value": "MCRI"
    },
    {
        "label": "JELD - JELD-WEN Holding, Inc.",
        "value": "JELD"
    },
    {
        "label": "SPHR - Sphere Entertainment Co.",
        "value": "SPHR"
    },
    {
        "label": "HYT - BLACKROCK CORPORATE HIGH YIELD FUND, INC.",
        "value": "HYT"
    },
    {
        "label": "MMI - Marcus & Millichap, Inc.",
        "value": "MMI"
    },
    {
        "label": "FBNC - FIRST BANCORP /NC/",
        "value": "FBNC"
    },
    {
        "label": "GIC - GLOBAL INDUSTRIAL Co",
        "value": "GIC"
    },
    {
        "label": "PHIN - PHINIA INC.",
        "value": "PHIN"
    },
    {
        "label": "RLAY - Relay Therapeutics, Inc.",
        "value": "RLAY"
    },
    {
        "label": "CMP - COMPASS MINERALS INTERNATIONAL INC",
        "value": "CMP"
    },
    {
        "label": "PFS - PROVIDENT FINANCIAL SERVICES INC",
        "value": "PFS"
    },
    {
        "label": "KC - Kingsoft Cloud Holdings Ltd",
        "value": "KC"
    },
    {
        "label": "OEC - Orion S.A.",
        "value": "OEC"
    },
    {
        "label": "STC - STEWART INFORMATION SERVICES CORP",
        "value": "STC"
    },
    {
        "label": "MSC - STUDIO CITY INTERNATIONAL HOLDINGS Ltd",
        "value": "MSC"
    },
    {
        "label": "BLDP - Ballard Power Systems Inc.",
        "value": "BLDP"
    },
    {
        "label": "LNZA - LanzaTech Global, Inc.",
        "value": "LNZA"
    },
    {
        "label": "CARS - Cars.com Inc.",
        "value": "CARS"
    },
    {
        "label": "SMMT - Summit Therapeutics Inc.",
        "value": "SMMT"
    },
    {
        "label": "UNIT - Uniti Group Inc.",
        "value": "UNIT"
    },
    {
        "label": "EVGO - EVgo Inc.",
        "value": "EVGO"
    },
    {
        "label": "ETG - EATON VANCE TAX ADVANTAGED GLOBAL DIVIDEND INCOME FUND",
        "value": "ETG"
    },
    {
        "label": "KALU - KAISER ALUMINUM CORP",
        "value": "KALU"
    },
    {
        "label": "TWO - TWO HARBORS INVESTMENT CORP.",
        "value": "TWO"
    },
    {
        "label": "SNDX - Syndax Pharmaceuticals Inc",
        "value": "SNDX"
    },
    {
        "label": "RCKT - ROCKET PHARMACEUTICALS, INC.",
        "value": "RCKT"
    },
    {
        "label": "HE - HAWAIIAN ELECTRIC INDUSTRIES INC",
        "value": "HE"
    },
    {
        "label": "USNA - USANA HEALTH SCIENCES INC",
        "value": "USNA"
    },
    {
        "label": "VZIO - Vizio Holding Corp.",
        "value": "VZIO"
    },
    {
        "label": "EDN - EDENOR",
        "value": "EDN"
    },
    {
        "label": "NBHC - National Bank Holdings Corp",
        "value": "NBHC"
    },
    {
        "label": "MD - Pediatrix Medical Group, Inc.",
        "value": "MD"
    },
    {
        "label": "LMAT - LEMAITRE VASCULAR INC",
        "value": "LMAT"
    },
    {
        "label": "OPAL - OPAL Fuels Inc.",
        "value": "OPAL"
    },
    {
        "label": "BFS - SAUL CENTERS, INC.",
        "value": "BFS"
    },
    {
        "label": "WABC - WESTAMERICA BANCORPORATION",
        "value": "WABC"
    },
    {
        "label": "UTZ - Utz Brands, Inc.",
        "value": "UTZ"
    },
    {
        "label": "DSL - DoubleLine Income Solutions Fund",
        "value": "DSL"
    },
    {
        "label": "TCBK - TRICO BANCSHARES /",
        "value": "TCBK"
    },
    {
        "label": "HTLD - HEARTLAND EXPRESS INC",
        "value": "HTLD"
    },
    {
        "label": "KFRC - KFORCE INC",
        "value": "KFRC"
    },
    {
        "label": "CGAU - Centerra Gold Inc.",
        "value": "CGAU"
    },
    {
        "label": "NUS - NU SKIN ENTERPRISES, INC.",
        "value": "NUS"
    },
    {
        "label": "ANIP - ANI PHARMACEUTICALS INC",
        "value": "ANIP"
    },
    {
        "label": "CUBI - Customers Bancorp, Inc.",
        "value": "CUBI"
    },
    {
        "label": "BBU - Brookfield Business Partners L.P.",
        "value": "BBU"
    },
    {
        "label": "FNA - Paragon 28, Inc.",
        "value": "FNA"
    },
    {
        "label": "DNOW - NOW Inc.",
        "value": "DNOW"
    },
    {
        "label": "SAGE - Sage Therapeutics, Inc.",
        "value": "SAGE"
    },
    {
        "label": "UNFI - UNITED NATURAL FOODS INC",
        "value": "UNFI"
    },
    {
        "label": "UFPT - UFP TECHNOLOGIES INC",
        "value": "UFPT"
    },
    {
        "label": "CCF - CHASE CORP",
        "value": "CCF"
    },
    {
        "label": "DAWN - Day One Biopharmaceuticals, Inc.",
        "value": "DAWN"
    },
    {
        "label": "ANGI - Angi Inc.",
        "value": "ANGI"
    },
    {
        "label": "OSTK - OVERSTOCK.COM, INC",
        "value": "OSTK"
    },
    {
        "label": "JBSS - SANFILIPPO JOHN B & SON INC",
        "value": "JBSS"
    },
    {
        "label": "STEL - Stellar Bancorp, Inc.",
        "value": "STEL"
    },
    {
        "label": "MEG - Montrose Environmental Group, Inc.",
        "value": "MEG"
    },
    {
        "label": "IRON - Disc Medicine, Inc.",
        "value": "IRON"
    },
    {
        "label": "GDEV - GDEV Inc.",
        "value": "GDEV"
    },
    {
        "label": "ASTE - ASTEC INDUSTRIES INC",
        "value": "ASTE"
    },
    {
        "label": "AMWD - AMERICAN WOODMARK CORP",
        "value": "AMWD"
    },
    {
        "label": "HWKN - HAWKINS INC",
        "value": "HWKN"
    },
    {
        "label": "TRUP - TRUPANION, INC.",
        "value": "TRUP"
    },
    {
        "label": "SGH - SMART Global Holdings, Inc.",
        "value": "SGH"
    },
    {
        "label": "APGE - Apogee Therapeutics, Inc.",
        "value": "APGE"
    },
    {
        "label": "ALHC - Alignment Healthcare, Inc.",
        "value": "ALHC"
    },
    {
        "label": "MEI - METHODE ELECTRONICS INC",
        "value": "MEI"
    },
    {
        "label": "VEON - VEON Ltd.",
        "value": "VEON"
    },
    {
        "label": "HOPE - HOPE BANCORP INC",
        "value": "HOPE"
    },
    {
        "label": "KYN - Kayne Anderson Energy Infrastructure Fund, Inc.",
        "value": "KYN"
    },
    {
        "label": "AIV - APARTMENT INVESTMENT & MANAGEMENT CO",
        "value": "AIV"
    },
    {
        "label": "EVRI - Everi Holdings Inc.",
        "value": "EVRI"
    },
    {
        "label": "LIFX - Life360, Inc.",
        "value": "LIFX"
    },
    {
        "label": "UVV - UNIVERSAL CORP /VA/",
        "value": "UVV"
    },
    {
        "label": "IRS - IRSA INVESTMENTS & REPRESENTATIONS INC",
        "value": "IRS"
    },
    {
        "label": "HMN - HORACE MANN EDUCATORS CORP /DE/",
        "value": "HMN"
    },
    {
        "label": "USLM - UNITED STATES LIME & MINERALS INC",
        "value": "USLM"
    },
    {
        "label": "AVID - AVID TECHNOLOGY, INC.",
        "value": "AVID"
    },
    {
        "label": "VLRS - Controladora Vuela Compania de Aviacion, S.A.B. de C.V.",
        "value": "VLRS"
    },
    {
        "label": "KRNT - Kornit Digital Ltd.",
        "value": "KRNT"
    },
    {
        "label": "AZZ - AZZ INC",
        "value": "AZZ"
    },
    {
        "label": "CXW - CoreCivic, Inc.",
        "value": "CXW"
    },
    {
        "label": "EH - EHang Holdings Ltd",
        "value": "EH"
    },
    {
        "label": "DSGR - Distribution Solutions Group, Inc.",
        "value": "DSGR"
    },
    {
        "label": "NFJ - Virtus Dividend, Interest & Premium Strategy Fund",
        "value": "NFJ"
    },
    {
        "label": "CTKB - Cytek Biosciences, Inc.",
        "value": "CTKB"
    },
    {
        "label": "PUMP - ProPetro Holding Corp.",
        "value": "PUMP"
    },
    {
        "label": "PRME - Prime Medicine, Inc.",
        "value": "PRME"
    },
    {
        "label": "ROVR - ROVER GROUP, INC.",
        "value": "ROVR"
    },
    {
        "label": "GNL - Global Net Lease, Inc.",
        "value": "GNL"
    },
    {
        "label": "GEL - GENESIS ENERGY LP",
        "value": "GEL"
    },
    {
        "label": "LGND - LIGAND PHARMACEUTICALS INC",
        "value": "LGND"
    },
    {
        "label": "HLIT - HARMONIC INC",
        "value": "HLIT"
    },
    {
        "label": "ETNB - 89bio, Inc.",
        "value": "ETNB"
    },
    {
        "label": "AEHR - AEHR TEST SYSTEMS",
        "value": "AEHR"
    },
    {
        "label": "KNSA - Kiniksa Pharmaceuticals, Ltd.",
        "value": "KNSA"
    },
    {
        "label": "CHEF - Chefs' Warehouse, Inc.",
        "value": "CHEF"
    },
    {
        "label": "CWH - Camping World Holdings, Inc.",
        "value": "CWH"
    },
    {
        "label": "OSW - ONESPAWORLD HOLDINGS Ltd",
        "value": "OSW"
    },
    {
        "label": "FORTY - FORMULA SYSTEMS (1985) LTD",
        "value": "FORTY"
    },
    {
        "label": "BUSE - FIRST BUSEY CORP /NV/",
        "value": "BUSE"
    },
    {
        "label": "ECVT - Ecovyst Inc.",
        "value": "ECVT"
    },
    {
        "label": "CLB - Core Laboratories Inc. /DE/",
        "value": "CLB"
    },
    {
        "label": "CIR - CIRCOR INTERNATIONAL INC",
        "value": "CIR"
    },
    {
        "label": "AMRX - Amneal Pharmaceuticals, Inc.",
        "value": "AMRX"
    },
    {
        "label": "DMLP - DORCHESTER MINERALS, L.P.",
        "value": "DMLP"
    },
    {
        "label": "SHEN - SHENANDOAH TELECOMMUNICATIONS CO/VA/",
        "value": "SHEN"
    },
    {
        "label": "NIC - NICOLET BANKSHARES INC",
        "value": "NIC"
    },
    {
        "label": "CBAY - CymaBay Therapeutics, Inc.",
        "value": "CBAY"
    },
    {
        "label": "XPOF - Xponential Fitness, Inc.",
        "value": "XPOF"
    },
    {
        "label": "JMKJ - Nine Alliance Science & Technology Group",
        "value": "JMKJ"
    },
    {
        "label": "NMZ - NUVEEN MUNICIPAL HIGH INCOME OPPORTUNITY FUND",
        "value": "NMZ"
    },
    {
        "label": "ECPG - ENCORE CAPITAL GROUP INC",
        "value": "ECPG"
    },
    {
        "label": "MAG - MAG SILVER CORP",
        "value": "MAG"
    },
    {
        "label": "QQQX - Nuveen NASDAQ 100 Dynamic Overwrite Fund",
        "value": "QQQX"
    },
    {
        "label": "RVLV - Revolve Group, Inc.",
        "value": "RVLV"
    },
    {
        "label": "MBUU - MALIBU BOATS, INC.",
        "value": "MBUU"
    },
    {
        "label": "STBA - S&T BANCORP INC",
        "value": "STBA"
    },
    {
        "label": "DCPH - Deciphera Pharmaceuticals, Inc.",
        "value": "DCPH"
    },
    {
        "label": "FIGS - FIGS, Inc.",
        "value": "FIGS"
    },
    {
        "label": "SRCE - 1ST SOURCE CORP",
        "value": "SRCE"
    },
    {
        "label": "REPL - Replimune Group, Inc.",
        "value": "REPL"
    },
    {
        "label": "DGII - DIGI INTERNATIONAL INC",
        "value": "DGII"
    },
    {
        "label": "DH - Definitive Healthcare Corp.",
        "value": "DH"
    },
    {
        "label": "NTST - NETSTREIT Corp.",
        "value": "NTST"
    },
    {
        "label": "PMT - PennyMac Mortgage Investment Trust",
        "value": "PMT"
    },
    {
        "label": "MPLN - MultiPlan Corp",
        "value": "MPLN"
    },
    {
        "label": "PLYA - Playa Hotels & Resorts N.V.",
        "value": "PLYA"
    },
    {
        "label": "HCCI - Heritage-Crystal Clean, Inc.",
        "value": "HCCI"
    },
    {
        "label": "NXGN - NEXTGEN HEALTHCARE, INC.",
        "value": "NXGN"
    },
    {
        "label": "PGRE - Paramount Group, Inc.",
        "value": "PGRE"
    },
    {
        "label": "GB - Global Blue Group Holding AG",
        "value": "GB"
    },
    {
        "label": "HIMX - Himax Technologies, Inc.",
        "value": "HIMX"
    },
    {
        "label": "DNN - DENISON MINES CORP.",
        "value": "DNN"
    },
    {
        "label": "EQRX - EQRx, Inc.",
        "value": "EQRX"
    },
    {
        "label": "APOG - APOGEE ENTERPRISES, INC.",
        "value": "APOG"
    },
    {
        "label": "FBRT - Franklin BSP Realty Trust, Inc.",
        "value": "FBRT"
    },
    {
        "label": "ATEN - A10 Networks, Inc.",
        "value": "ATEN"
    },
    {
        "label": "EWCZ - European Wax Center, Inc.",
        "value": "EWCZ"
    },
    {
        "label": "SBH - Sally Beauty Holdings, Inc.",
        "value": "SBH"
    },
    {
        "label": "PTGX - Protagonist Therapeutics, Inc",
        "value": "PTGX"
    },
    {
        "label": "PRDO - PERDOCEO EDUCATION Corp",
        "value": "PRDO"
    },
    {
        "label": "MGNI - MAGNITE, INC.",
        "value": "MGNI"
    },
    {
        "label": "ADEA - Adeia Inc.",
        "value": "ADEA"
    },
    {
        "label": "BST - BlackRock Science & Technology Trust",
        "value": "BST"
    },
    {
        "label": "EVV - EATON VANCE LTD DURATION INCOME FUND",
        "value": "EVV"
    },
    {
        "label": "AZUL - AZUL SA",
        "value": "AZUL"
    },
    {
        "label": "EMBC - Embecta Corp.",
        "value": "EMBC"
    },
    {
        "label": "ARR - Armour Residential REIT, Inc.",
        "value": "ARR"
    },
    {
        "label": "HKD - AMTD Digital Inc.",
        "value": "HKD"
    },
    {
        "label": "FVRR - Fiverr International Ltd.",
        "value": "FVRR"
    },
    {
        "label": "TWST - Twist Bioscience Corp",
        "value": "TWST"
    },
    {
        "label": "PBT - PERMIAN BASIN ROYALTY TRUST",
        "value": "PBT"
    },
    {
        "label": "HAIN - HAIN CELESTIAL GROUP INC",
        "value": "HAIN"
    },
    {
        "label": "GLP - GLOBAL PARTNERS LP",
        "value": "GLP"
    },
    {
        "label": "VBTX - Veritex Holdings, Inc.",
        "value": "VBTX"
    },
    {
        "label": "MOR - MorphoSys AG",
        "value": "MOR"
    },
    {
        "label": "TRS - TRIMAS CORP",
        "value": "TRS"
    },
    {
        "label": "YEXT - Yext, Inc.",
        "value": "YEXT"
    },
    {
        "label": "MODN - MODEL N, INC.",
        "value": "MODN"
    },
    {
        "label": "CMCO - COLUMBUS MCKINNON CORP",
        "value": "CMCO"
    },
    {
        "label": "IMAX - IMAX CORP",
        "value": "IMAX"
    },
    {
        "label": "MNRO - MONRO, INC.",
        "value": "MNRO"
    },
    {
        "label": "GES - GUESS INC",
        "value": "GES"
    },
    {
        "label": "EIG - Employers Holdings, Inc.",
        "value": "EIG"
    },
    {
        "label": "OLO - Olo Inc.",
        "value": "OLO"
    },
    {
        "label": "PAR - PAR TECHNOLOGY CORP",
        "value": "PAR"
    },
    {
        "label": "HOLI - Hollysys Automation Technologies, Ltd.",
        "value": "HOLI"
    },
    {
        "label": "NHC - NATIONAL HEALTHCARE CORP",
        "value": "NHC"
    },
    {
        "label": "FWRG - First Watch Restaurant Group, Inc.",
        "value": "FWRG"
    },
    {
        "label": "WT - WisdomTree, Inc.",
        "value": "WT"
    },
    {
        "label": "WNC - WABASH NATIONAL Corp",
        "value": "WNC"
    },
    {
        "label": "NRC - NATIONAL RESEARCH CORP",
        "value": "NRC"
    },
    {
        "label": "FRG - Franchise Group, Inc.",
        "value": "FRG"
    },
    {
        "label": "TVTX - Travere Therapeutics, Inc.",
        "value": "TVTX"
    },
    {
        "label": "SDA - SunCar Technology Group Inc.",
        "value": "SDA"
    },
    {
        "label": "RYI - Ryerson Holding Corp",
        "value": "RYI"
    },
    {
        "label": "FSCO - FS Credit Opportunities Corp.",
        "value": "FSCO"
    },
    {
        "label": "IAG - IAMGOLD CORP",
        "value": "IAG"
    },
    {
        "label": "ARGO - Argo Group International Holdings, Ltd.",
        "value": "ARGO"
    },
    {
        "label": "SAFT - SAFETY INSURANCE GROUP INC",
        "value": "SAFT"
    },
    {
        "label": "KYMR - Kymera Therapeutics, Inc.",
        "value": "KYMR"
    },
    {
        "label": "GSM - Ferroglobe PLC",
        "value": "GSM"
    },
    {
        "label": "MFA - MFA FINANCIAL, INC.",
        "value": "MFA"
    },
    {
        "label": "SANA - Sana Biotechnology, Inc.",
        "value": "SANA"
    },
    {
        "label": "CBD - BRAZILIAN DISTRIBUTION CO COMPANHIA BRASILEIRA DE DISTR CBD",
        "value": "CBD"
    },
    {
        "label": "TMCI - TREACE MEDICAL CONCEPTS, INC.",
        "value": "TMCI"
    },
    {
        "label": "GPCR - Structure Therapeutics Inc.",
        "value": "GPCR"
    },
    {
        "label": "BIOGY - BioGaia AB/ADR",
        "value": "BIOGY"
    },
    {
        "label": "GRNT - Granite Ridge Resources, Inc.",
        "value": "GRNT"
    },
    {
        "label": "TBLA - Taboola.com Ltd.",
        "value": "TBLA"
    },
    {
        "label": "AVNS - AVANOS MEDICAL, INC.",
        "value": "AVNS"
    },
    {
        "label": "ARIS - Aris Water Solutions, Inc.",
        "value": "ARIS"
    },
    {
        "label": "GDEN - GOLDEN ENTERTAINMENT, INC.",
        "value": "GDEN"
    },
    {
        "label": "EAF - GRAFTECH INTERNATIONAL LTD",
        "value": "EAF"
    },
    {
        "label": "NBR - NABORS INDUSTRIES LTD",
        "value": "NBR"
    },
    {
        "label": "OCFC - OCEANFIRST FINANCIAL CORP",
        "value": "OCFC"
    },
    {
        "label": "EB - Eventbrite, Inc.",
        "value": "EB"
    },
    {
        "label": "FLGT - Fulgent Genetics, Inc.",
        "value": "FLGT"
    },
    {
        "label": "INDI - indie Semiconductor, Inc.",
        "value": "INDI"
    },
    {
        "label": "EAI - ENTERGY ARKANSAS, LLC",
        "value": "EAI"
    },
    {
        "label": "LPRO - Open Lending Corp",
        "value": "LPRO"
    },
    {
        "label": "MUC - BLACKROCK MUNIHOLDINGS CALIFORNIA QUALITY FUND, INC.",
        "value": "MUC"
    },
    {
        "label": "CET - CENTRAL SECURITIES CORP",
        "value": "CET"
    },
    {
        "label": "RXST - RxSight, Inc.",
        "value": "RXST"
    },
    {
        "label": "PLRX - PLIANT THERAPEUTICS, INC.",
        "value": "PLRX"
    },
    {
        "label": "SASR - SANDY SPRING BANCORP INC",
        "value": "SASR"
    },
    {
        "label": "IRBT - IROBOT CORP",
        "value": "IRBT"
    },
    {
        "label": "VTLE - Vital Energy, Inc.",
        "value": "VTLE"
    },
    {
        "label": "NOAH - Noah Holdings Ltd",
        "value": "NOAH"
    },
    {
        "label": "AGRO - Adecoagro S.A.",
        "value": "AGRO"
    },
    {
        "label": "PTLO - Portillo's Inc.",
        "value": "PTLO"
    },
    {
        "label": "BBAR - Banco BBVA Argentina S.A.",
        "value": "BBAR"
    },
    {
        "label": "HHRS - Hammerhead Energy Inc.",
        "value": "HHRS"
    },
    {
        "label": "KRO - KRONOS WORLDWIDE INC",
        "value": "KRO"
    },
    {
        "label": "AMSF - AMERISAFE INC",
        "value": "AMSF"
    },
    {
        "label": "RTL - Necessity Retail REIT, Inc.",
        "value": "RTL"
    },
    {
        "label": "LZM - Lifezone Metals Ltd",
        "value": "LZM"
    },
    {
        "label": "AHH - Armada Hoffler Properties, Inc.",
        "value": "AHH"
    },
    {
        "label": "RDFN - Redfin Corp",
        "value": "RDFN"
    },
    {
        "label": "LPG - DORIAN LPG LTD.",
        "value": "LPG"
    },
    {
        "label": "SSYS - STRATASYS LTD.",
        "value": "SSYS"
    },
    {
        "label": "NVGS - Navigator Holdings Ltd.",
        "value": "NVGS"
    },
    {
        "label": "MRUS - Merus N.V.",
        "value": "MRUS"
    },
    {
        "label": "ACCD - Accolade, Inc.",
        "value": "ACCD"
    },
    {
        "label": "TMST - TimkenSteel Corp",
        "value": "TMST"
    },
    {
        "label": "CRNC - Cerence Inc.",
        "value": "CRNC"
    },
    {
        "label": "AMPS - Altus Power, Inc.",
        "value": "AMPS"
    },
    {
        "label": "KROS - Keros Therapeutics, Inc.",
        "value": "KROS"
    },
    {
        "label": "NPWR - NET Power Inc.",
        "value": "NPWR"
    },
    {
        "label": "SCS - STEELCASE INC",
        "value": "SCS"
    },
    {
        "label": "UUUU - ENERGY FUELS INC",
        "value": "UUUU"
    },
    {
        "label": "ARKO - ARKO Corp.",
        "value": "ARKO"
    },
    {
        "label": "BBDC - Barings BDC, Inc.",
        "value": "BBDC"
    },
    {
        "label": "LMND - Lemonade, Inc.",
        "value": "LMND"
    },
    {
        "label": "DCGO - DocGo Inc.",
        "value": "DCGO"
    },
    {
        "label": "GAM - GENERAL AMERICAN INVESTORS CO INC",
        "value": "GAM"
    },
    {
        "label": "PTA - Cohen & Steers Tax-Advantaged Preferred Securities & Income Fund",
        "value": "PTA"
    },
    {
        "label": "BHLB - BERKSHIRE HILLS BANCORP INC",
        "value": "BHLB"
    },
    {
        "label": "AVDL - AVADEL PHARMACEUTICALS PLC",
        "value": "AVDL"
    },
    {
        "label": "CAL - CALERES INC",
        "value": "CAL"
    },
    {
        "label": "BCSF - Bain Capital Specialty Finance, Inc.",
        "value": "BCSF"
    },
    {
        "label": "SCRM - Screaming Eagle Acquisition Corp.",
        "value": "SCRM"
    },
    {
        "label": "COGT - Cogent Biosciences, Inc.",
        "value": "COGT"
    },
    {
        "label": "CSR - CENTERSPACE",
        "value": "CSR"
    },
    {
        "label": "SBR - SABINE ROYALTY TRUST",
        "value": "SBR"
    },
    {
        "label": "ADMA - ADMA BIOLOGICS, INC.",
        "value": "ADMA"
    },
    {
        "label": "BBN - BlackRock Taxable Municipal Bond Trust",
        "value": "BBN"
    },
    {
        "label": "SPLP - STEEL PARTNERS HOLDINGS L.P.",
        "value": "SPLP"
    },
    {
        "label": "EVLV - Evolv Technologies Holdings, Inc.",
        "value": "EVLV"
    },
    {
        "label": "PRA - PROASSURANCE CORP",
        "value": "PRA"
    },
    {
        "label": "ICHR - ICHOR HOLDINGS, LTD.",
        "value": "ICHR"
    },
    {
        "label": "BZH - BEAZER HOMES USA INC",
        "value": "BZH"
    },
    {
        "label": "SLCA - U.S. SILICA HOLDINGS, INC.",
        "value": "SLCA"
    },
    {
        "label": "NEXT - NextDecade Corp.",
        "value": "NEXT"
    },
    {
        "label": "VTEX - VTEX",
        "value": "VTEX"
    },
    {
        "label": "SBSI - SOUTHSIDE BANCSHARES INC",
        "value": "SBSI"
    },
    {
        "label": "ALX - ALEXANDERS INC",
        "value": "ALX"
    },
    {
        "label": "MIRM - Mirum Pharmaceuticals, Inc.",
        "value": "MIRM"
    },
    {
        "label": "TASK - TaskUs, Inc.",
        "value": "TASK"
    },
    {
        "label": "DRQ - DRIL-QUIP INC",
        "value": "DRQ"
    },
    {
        "label": "OBK - Origin Bancorp, Inc.",
        "value": "OBK"
    },
    {
        "label": "GIII - G III APPAREL GROUP LTD /DE/",
        "value": "GIII"
    },
    {
        "label": "MRRTY - MARFRIG GLOBAL FOODS S.A.",
        "value": "MRRTY"
    },
    {
        "label": "RGR - STURM RUGER & CO INC",
        "value": "RGR"
    },
    {
        "label": "PLYM - Plymouth Industrial REIT, Inc.",
        "value": "PLYM"
    },
    {
        "label": "ASIX - AdvanSix Inc.",
        "value": "ASIX"
    },
    {
        "label": "PEBO - PEOPLES BANCORP INC",
        "value": "PEBO"
    },
    {
        "label": "VALN - Valneva SE",
        "value": "VALN"
    },
    {
        "label": "MATV - Mativ Holdings, Inc.",
        "value": "MATV"
    },
    {
        "label": "BNGO - Bionano Genomics, Inc.",
        "value": "BNGO"
    },
    {
        "label": "ARLO - Arlo Technologies, Inc.",
        "value": "ARLO"
    },
    {
        "label": "FBMS - FIRST BANCSHARES INC /MS/",
        "value": "FBMS"
    },
    {
        "label": "ALTI - AlTi Global, Inc.",
        "value": "ALTI"
    },
    {
        "label": "PACW - PACWEST BANCORP",
        "value": "PACW"
    },
    {
        "label": "SBOW - SILVERBOW RESOURCES, INC.",
        "value": "SBOW"
    },
    {
        "label": "SLP - Simulations Plus, Inc.",
        "value": "SLP"
    },
    {
        "label": "KRUS - KURA SUSHI USA, INC.",
        "value": "KRUS"
    },
    {
        "label": "VRNOF - Verano Holdings Corp.",
        "value": "VRNOF"
    },
    {
        "label": "EVBG - EVERBRIDGE, INC.",
        "value": "EVBG"
    },
    {
        "label": "UMH - UMH PROPERTIES, INC.",
        "value": "UMH"
    },
    {
        "label": "FPF - First Trust Intermediate Duration Preferred & Income Fund",
        "value": "FPF"
    },
    {
        "label": "CDRE - Cadre Holdings, Inc.",
        "value": "CDRE"
    },
    {
        "label": "SA - SEABRIDGE GOLD INC",
        "value": "SA"
    },
    {
        "label": "EOS - Eaton Vance Enhanced Equity Income Fund II",
        "value": "EOS"
    },
    {
        "label": "POWL - POWELL INDUSTRIES INC",
        "value": "POWL"
    },
    {
        "label": "APPS - Digital Turbine, Inc.",
        "value": "APPS"
    },
    {
        "label": "SPH - SUBURBAN PROPANE PARTNERS LP",
        "value": "SPH"
    },
    {
        "label": "SKWD - Skyward Specialty Insurance Group, Inc.",
        "value": "SKWD"
    },
    {
        "label": "WTTR - Select Water Solutions, Inc.",
        "value": "WTTR"
    },
    {
        "label": "EXLS - ExlService Holdings, Inc.",
        "value": "EXLS"
    },
    {
        "label": "IMTX - Immatics N.V.",
        "value": "IMTX"
    },
    {
        "label": "BTZ - BLACKROCK CREDIT ALLOCATION INCOME TRUST",
        "value": "BTZ"
    },
    {
        "label": "TDCX - TDCX Inc.",
        "value": "TDCX"
    },
    {
        "label": "MED - MEDIFAST INC",
        "value": "MED"
    },
    {
        "label": "RA - Brookfield Real Assets Income Fund Inc.",
        "value": "RA"
    },
    {
        "label": "HCSG - HEALTHCARE SERVICES GROUP INC",
        "value": "HCSG"
    },
    {
        "label": "ACEL - Accel Entertainment, Inc.",
        "value": "ACEL"
    },
    {
        "label": "RSI - Rush Street Interactive, Inc.",
        "value": "RSI"
    },
    {
        "label": "VERV - Verve Therapeutics, Inc.",
        "value": "VERV"
    },
    {
        "label": "NGMS - NeoGames S.A.",
        "value": "NGMS"
    },
    {
        "label": "PL - Planet Labs PBC",
        "value": "PL"
    },
    {
        "label": "PWP - Perella Weinberg Partners",
        "value": "PWP"
    },
    {
        "label": "CRESY - CRESUD INC",
        "value": "CRESY"
    },
    {
        "label": "CLNE - Clean Energy Fuels Corp.",
        "value": "CLNE"
    },
    {
        "label": "QTRX - Quanterix Corp",
        "value": "QTRX"
    },
    {
        "label": "PFBC - Preferred Bank",
        "value": "PFBC"
    },
    {
        "label": "FREY - FREYR Battery",
        "value": "FREY"
    },
    {
        "label": "BHE - BENCHMARK ELECTRONICS INC",
        "value": "BHE"
    },
    {
        "label": "ARCE - Arco Platform Ltd.",
        "value": "ARCE"
    },
    {
        "label": "RNP - COHEN & STEERS REIT & PREFERRED & INCOME FUND INC",
        "value": "RNP"
    },
    {
        "label": "BGS - B&G Foods, Inc.",
        "value": "BGS"
    },
    {
        "label": "SCHN - RADIUS RECYCLING",
        "value": "SCHN"
    },
    {
        "label": "PRM - Perimeter Solutions, SA",
        "value": "PRM"
    },
    {
        "label": "INFN - Infinera Corp",
        "value": "INFN"
    },
    {
        "label": "GEO - GEO GROUP INC",
        "value": "GEO"
    },
    {
        "label": "RBCAA - REPUBLIC BANCORP INC /KY/",
        "value": "RBCAA"
    },
    {
        "label": "DLX - DELUXE CORP",
        "value": "DLX"
    },
    {
        "label": "OXLC - Oxford Lane Capital Corp.",
        "value": "OXLC"
    },
    {
        "label": "INVA - Innoviva, Inc.",
        "value": "INVA"
    },
    {
        "label": "SNCY - Sun Country Airlines Holdings, Inc.",
        "value": "SNCY"
    },
    {
        "label": "LBAI - LAKELAND BANCORP INC",
        "value": "LBAI"
    },
    {
        "label": "NAMS - NewAmsterdam Pharma Co N.V.",
        "value": "NAMS"
    },
    {
        "label": "TUYA - Tuya Inc.",
        "value": "TUYA"
    },
    {
        "label": "PDS - PRECISION DRILLING Corp",
        "value": "PDS"
    },
    {
        "label": "KIDS - ORTHOPEDIATRICS CORP",
        "value": "KIDS"
    },
    {
        "label": "GRND - Grindr Inc.",
        "value": "GRND"
    },
    {
        "label": "SBGI - Sinclair, Inc.",
        "value": "SBGI"
    },
    {
        "label": "CRNX - Crinetics Pharmaceuticals, Inc.",
        "value": "CRNX"
    },
    {
        "label": "ADPT - Adaptive Biotechnologies Corp",
        "value": "ADPT"
    },
    {
        "label": "RWT - REDWOOD TRUST INC",
        "value": "RWT"
    },
    {
        "label": "FNMA - FEDERAL NATIONAL MORTGAGE ASSOCIATION FANNIE MAE",
        "value": "FNMA"
    },
    {
        "label": "AXL - AMERICAN AXLE & MANUFACTURING HOLDINGS INC",
        "value": "AXL"
    },
    {
        "label": "PIII - P3 Health Partners Inc.",
        "value": "PIII"
    },
    {
        "label": "BRCC - BRC Inc.",
        "value": "BRCC"
    },
    {
        "label": "ADV - Advantage Solutions Inc.",
        "value": "ADV"
    },
    {
        "label": "NX - Quanex Building Products CORP",
        "value": "NX"
    },
    {
        "label": "QCRH - QCR HOLDINGS INC",
        "value": "QCRH"
    },
    {
        "label": "MFIC - MidCap Financial Investment Corp",
        "value": "MFIC"
    },
    {
        "label": "CCRN - CROSS COUNTRY HEALTHCARE INC",
        "value": "CCRN"
    },
    {
        "label": "GABC - GERMAN AMERICAN BANCORP, INC.",
        "value": "GABC"
    },
    {
        "label": "BRKL - BROOKLINE BANCORP INC",
        "value": "BRKL"
    },
    {
        "label": "EFC - Ellington Financial Inc.",
        "value": "EFC"
    },
    {
        "label": "ETW - Eaton Vance Tax-Managed Global Buy-Write Opportunities Fund",
        "value": "ETW"
    },
    {
        "label": "NRK - NUVEEN NEW YORK AMT-FREE QUALITY MUNICIPAL INCOME FUND",
        "value": "NRK"
    },
    {
        "label": "NAT - NORDIC AMERICAN TANKERS Ltd",
        "value": "NAT"
    },
    {
        "label": "RPT - RPT Realty",
        "value": "RPT"
    },
    {
        "label": "CHCT - Community Healthcare Trust Inc",
        "value": "CHCT"
    },
    {
        "label": "DCOM - Dime Community Bancshares, Inc. /NY/",
        "value": "DCOM"
    },
    {
        "label": "SNMP - Evolve Transition Infrastructure LP",
        "value": "SNMP"
    },
    {
        "label": "HPP - Hudson Pacific Properties, Inc.",
        "value": "HPP"
    },
    {
        "label": "STEM - STEM, INC.",
        "value": "STEM"
    },
    {
        "label": "ASTS - AST SpaceMobile, Inc.",
        "value": "ASTS"
    },
    {
        "label": "KUBR - Kuber Resources Corp",
        "value": "KUBR"
    },
    {
        "label": "PLL - Piedmont Lithium Inc.",
        "value": "PLL"
    },
    {
        "label": "DDD - 3D SYSTEMS CORP",
        "value": "DDD"
    },
    {
        "label": "NRP - NATURAL RESOURCE PARTNERS LP",
        "value": "NRP"
    },
    {
        "label": "BSIG - BrightSphere Investment Group Inc.",
        "value": "BSIG"
    },
    {
        "label": "UAN - CVR PARTNERS, LP",
        "value": "UAN"
    },
    {
        "label": "DIN - Dine Brands Global, Inc.",
        "value": "DIN"
    },
    {
        "label": "AOSL - ALPHA & OMEGA SEMICONDUCTOR Ltd",
        "value": "AOSL"
    },
    {
        "label": "ATRI - ATRION CORP",
        "value": "ATRI"
    },
    {
        "label": "AWF - ALLIANCEBERNSTEIN GLOBAL HIGH INCOME FUND INC",
        "value": "AWF"
    },
    {
        "label": "THR - Thermon Group Holdings, Inc.",
        "value": "THR"
    },
    {
        "label": "CHY - CALAMOS CONVERTIBLE & HIGH INCOME FUND",
        "value": "CHY"
    },
    {
        "label": "MDXG - MIMEDX GROUP, INC.",
        "value": "MDXG"
    },
    {
        "label": "RPAY - Repay Holdings Corp",
        "value": "RPAY"
    },
    {
        "label": "TTGT - TechTarget Inc",
        "value": "TTGT"
    },
    {
        "label": "UBP - URSTADT BIDDLE PROPERTIES INC",
        "value": "UBP"
    },
    {
        "label": "BLX - FOREIGN TRADE BANK OF LATIN AMERICA, INC.",
        "value": "BLX"
    },
    {
        "label": "OFLX - Omega Flex, Inc.",
        "value": "OFLX"
    },
    {
        "label": "AOD - abrdn Total Dynamic Dividend Fund",
        "value": "AOD"
    },
    {
        "label": "BFC - Bank First Corp",
        "value": "BFC"
    },
    {
        "label": "BRSP - BrightSpire Capital, Inc.",
        "value": "BRSP"
    },
    {
        "label": "SSU - SIGNA Sports United N.V.",
        "value": "SSU"
    },
    {
        "label": "FSM - FORTUNA SILVER MINES INC",
        "value": "FSM"
    },
    {
        "label": "PHAT - Phathom Pharmaceuticals, Inc.",
        "value": "PHAT"
    },
    {
        "label": "KIND - Nextdoor Holdings, Inc.",
        "value": "KIND"
    },
    {
        "label": "SII - SPROTT INC.",
        "value": "SII"
    },
    {
        "label": "CRF - CORNERSTONE TOTAL RETURN FUND INC",
        "value": "CRF"
    },
    {
        "label": "WRLD - WORLD ACCEPTANCE CORP",
        "value": "WRLD"
    },
    {
        "label": "CDE - Coeur Mining, Inc.",
        "value": "CDE"
    },
    {
        "label": "MQY - BLACKROCK MUNIYIELD QUALITY FUND, INC.",
        "value": "MQY"
    },
    {
        "label": "GDYN - GRID DYNAMICS HOLDINGS, INC.",
        "value": "GDYN"
    },
    {
        "label": "CFFN - Capitol Federal Financial, Inc.",
        "value": "CFFN"
    },
    {
        "label": "WEST - Westrock Coffee Co",
        "value": "WEST"
    },
    {
        "label": "SLRC - SLR Investment Corp.",
        "value": "SLRC"
    },
    {
        "label": "IBRX - ImmunityBio, Inc.",
        "value": "IBRX"
    },
    {
        "label": "VRDN - Viridian Therapeutics, Inc.DE",
        "value": "VRDN"
    },
    {
        "label": "CSWC - CAPITAL SOUTHWEST CORP",
        "value": "CSWC"
    },
    {
        "label": "COLL - COLLEGIUM PHARMACEUTICAL, INC",
        "value": "COLL"
    },
    {
        "label": "NBXG - Neuberger Berman Next Generation Connectivity Fund Inc.",
        "value": "NBXG"
    },
    {
        "label": "SIBN - SI-BONE, Inc.",
        "value": "SIBN"
    },
    {
        "label": "MUI - BLACKROCK MUNICIPAL INCOME FUND, INC.",
        "value": "MUI"
    },
    {
        "label": "KURA - Kura Oncology, Inc.",
        "value": "KURA"
    },
    {
        "label": "VSEC - VSE CORP",
        "value": "VSEC"
    },
    {
        "label": "GDOT - GREEN DOT CORP",
        "value": "GDOT"
    },
    {
        "label": "PDM - Piedmont Office Realty Trust, Inc.",
        "value": "PDM"
    },
    {
        "label": "CII - BLACKROCK ENHANCED CAPITAL & INCOME FUND, INC.",
        "value": "CII"
    },
    {
        "label": "HQH - TEKLA HEALTHCARE INVESTORS",
        "value": "HQH"
    },
    {
        "label": "SPTN - SpartanNash Co",
        "value": "SPTN"
    },
    {
        "label": "AMRK - A-Mark Precious Metals, Inc.",
        "value": "AMRK"
    },
    {
        "label": "BCX - BlackRock Resources & Commodities Strategy Trust",
        "value": "BCX"
    },
    {
        "label": "IMOS - CHIPMOS TECHNOLOGIES INC",
        "value": "IMOS"
    },
    {
        "label": "BY - BYLINE BANCORP, INC.",
        "value": "BY"
    },
    {
        "label": "NYMT - NEW YORK MORTGAGE TRUST INC",
        "value": "NYMT"
    },
    {
        "label": "CHI - CALAMOS CONVERTIBLE OPPORTUNITIES & INCOME FUND",
        "value": "CHI"
    },
    {
        "label": "GRC - GORMAN RUPP CO",
        "value": "GRC"
    },
    {
        "label": "GOTU - Gaotu Techedu Inc.",
        "value": "GOTU"
    },
    {
        "label": "XMTR - Xometry, Inc.",
        "value": "XMTR"
    },
    {
        "label": "KOP - Koppers Holdings Inc.",
        "value": "KOP"
    },
    {
        "label": "BDN - BRANDYWINE REALTY TRUST",
        "value": "BDN"
    },
    {
        "label": "CIFR - Cipher Mining Inc.",
        "value": "CIFR"
    },
    {
        "label": "YALA - Yalla Group Ltd",
        "value": "YALA"
    },
    {
        "label": "REVG - REV Group, Inc.",
        "value": "REVG"
    },
    {
        "label": "GTN - GRAY TELEVISION INC",
        "value": "GTN"
    },
    {
        "label": "AMAM - Ambrx Biopharma Inc.",
        "value": "AMAM"
    },
    {
        "label": "NYAX - Nayax Ltd.",
        "value": "NYAX"
    },
    {
        "label": "VTOL - Bristow Group Inc.",
        "value": "VTOL"
    },
    {
        "label": "ETD - ETHAN ALLEN INTERIORS INC",
        "value": "ETD"
    },
    {
        "label": "AVTA - AVANTAX, INC.",
        "value": "AVTA"
    },
    {
        "label": "VREX - Varex Imaging Corp",
        "value": "VREX"
    },
    {
        "label": "PHAR - Pharming Group N.V.",
        "value": "PHAR"
    },
    {
        "label": "THRY - Thryv Holdings, Inc.",
        "value": "THRY"
    },
    {
        "label": "PNT - POINT Biopharma Global Inc.",
        "value": "PNT"
    },
    {
        "label": "SILK - Silk Road Medical Inc",
        "value": "SILK"
    },
    {
        "label": "UTL - UNITIL CORP",
        "value": "UTL"
    },
    {
        "label": "AC - Associated Capital Group, Inc.",
        "value": "AC"
    },
    {
        "label": "BIGC - BigCommerce Holdings, Inc.",
        "value": "BIGC"
    },
    {
        "label": "ACMR - ACM Research, Inc.",
        "value": "ACMR"
    },
    {
        "label": "RSKD - Riskified Ltd.",
        "value": "RSKD"
    },
    {
        "label": "SP - SP Plus Corp",
        "value": "SP"
    },
    {
        "label": "OTLY - Oatly Group AB",
        "value": "OTLY"
    },
    {
        "label": "KREF - KKR Real Estate Finance Trust Inc.",
        "value": "KREF"
    },
    {
        "label": "PLPC - PREFORMED LINE PRODUCTS CO",
        "value": "PLPC"
    },
    {
        "label": "WKME - WalkMe Ltd.",
        "value": "WKME"
    },
    {
        "label": "THQ - Tekla Healthcare Opportunities Fund",
        "value": "THQ"
    },
    {
        "label": "DRD - DRDGOLD LTD",
        "value": "DRD"
    },
    {
        "label": "TMP - TOMPKINS FINANCIAL CORP",
        "value": "TMP"
    },
    {
        "label": "SPCE - Virgin Galactic Holdings, Inc",
        "value": "SPCE"
    },
    {
        "label": "BKD - Brookdale Senior Living Inc.",
        "value": "BKD"
    },
    {
        "label": "BYND - BEYOND MEAT, INC.",
        "value": "BYND"
    },
    {
        "label": "CDMO - Avid Bioservices, Inc.",
        "value": "CDMO"
    },
    {
        "label": "SKIN - Beauty Health Co",
        "value": "SKIN"
    },
    {
        "label": "NFGC - New Found Gold Corp.",
        "value": "NFGC"
    },
    {
        "label": "WLKP - Westlake Chemical Partners LP",
        "value": "WLKP"
    },
    {
        "label": "HY - HYSTER-YALE MATERIALS HANDLING, INC.",
        "value": "HY"
    },
    {
        "label": "SMP - STANDARD MOTOR PRODUCTS, INC.",
        "value": "SMP"
    },
    {
        "label": "LICY - Li-Cycle Holdings Corp.",
        "value": "LICY"
    },
    {
        "label": "RGNX - REGENXBIO Inc.",
        "value": "RGNX"
    },
    {
        "label": "BV - BrightView Holdings, Inc.",
        "value": "BV"
    },
    {
        "label": "TSAT - Telesat Corp",
        "value": "TSAT"
    },
    {
        "label": "COMM - CommScope Holding Company, Inc.",
        "value": "COMM"
    },
    {
        "label": "BCYC - BICYCLE THERAPEUTICS plc",
        "value": "BCYC"
    },
    {
        "label": "WW - WW INTERNATIONAL, INC.",
        "value": "WW"
    },
    {
        "label": "PSFE - Paysafe Ltd",
        "value": "PSFE"
    },
    {
        "label": "ASTL - Algoma Steel Group Inc.",
        "value": "ASTL"
    },
    {
        "label": "MRC - MRC GLOBAL INC.",
        "value": "MRC"
    },
    {
        "label": "SXC - SunCoke Energy, Inc.",
        "value": "SXC"
    },
    {
        "label": "PRAA - PRA GROUP INC",
        "value": "PRAA"
    },
    {
        "label": "AAC - Ares Acquisition Corp",
        "value": "AAC"
    },
    {
        "label": "CLCO - Cool Co Ltd.",
        "value": "CLCO"
    },
    {
        "label": "ARDX - ARDELYX, INC.",
        "value": "ARDX"
    },
    {
        "label": "EVA - Enviva Inc.",
        "value": "EVA"
    },
    {
        "label": "TWI - TITAN INTERNATIONAL INC",
        "value": "TWI"
    },
    {
        "label": "LXU - LSB INDUSTRIES, INC.",
        "value": "LXU"
    },
    {
        "label": "VERA - Vera Therapeutics, Inc.",
        "value": "VERA"
    },
    {
        "label": "NRGX - PIMCO Energy & Tactical Credit Opportunities Fund",
        "value": "NRGX"
    },
    {
        "label": "CNOB - ConnectOne Bancorp, Inc.",
        "value": "CNOB"
    },
    {
        "label": "SNPO - Snap One Holdings Corp.",
        "value": "SNPO"
    },
    {
        "label": "BXC - BlueLinx Holdings Inc.",
        "value": "BXC"
    },
    {
        "label": "EGBN - EAGLE BANCORP INC",
        "value": "EGBN"
    },
    {
        "label": "HRT - HireRight Holdings Corp",
        "value": "HRT"
    },
    {
        "label": "EXAI - Exscientia plc",
        "value": "EXAI"
    },
    {
        "label": "SES - SES AI Corp",
        "value": "SES"
    },
    {
        "label": "CENX - CENTURY ALUMINUM CO",
        "value": "CENX"
    },
    {
        "label": "AVO - Mission Produce, Inc.",
        "value": "AVO"
    },
    {
        "label": "PRLB - Proto Labs Inc",
        "value": "PRLB"
    },
    {
        "label": "MLAB - MESA LABORATORIES INC /CO/",
        "value": "MLAB"
    },
    {
        "label": "EFXT - Enerflex Ltd.",
        "value": "EFXT"
    },
    {
        "label": "GGR - Gogoro Inc.",
        "value": "GGR"
    },
    {
        "label": "RDWR - RADWARE LTD",
        "value": "RDWR"
    },
    {
        "label": "CGBD - Carlyle Secured Lending, Inc.",
        "value": "CGBD"
    },
    {
        "label": "ARCT - Arcturus Therapeutics Holdings Inc.",
        "value": "ARCT"
    },
    {
        "label": "GHLD - Guild Holdings Co",
        "value": "GHLD"
    },
    {
        "label": "CRAI - CRA INTERNATIONAL, INC.",
        "value": "CRAI"
    },
    {
        "label": "CAPL - CrossAmerica Partners LP",
        "value": "CAPL"
    },
    {
        "label": "SSP - E.W. SCRIPPS Co",
        "value": "SSP"
    },
    {
        "label": "ZUUS - ZEUUS, INC.",
        "value": "ZUUS"
    },
    {
        "label": "IIIV - i3 Verticals, Inc.",
        "value": "IIIV"
    },
    {
        "label": "ULH - UNIVERSAL LOGISTICS HOLDINGS, INC.",
        "value": "ULH"
    },
    {
        "label": "LWLG - Lightwave Logic, Inc.",
        "value": "LWLG"
    },
    {
        "label": "FMCB - FARMERS & MERCHANTS BANCORP",
        "value": "FMCB"
    },
    {
        "label": "HOUS - Anywhere Real Estate Inc.",
        "value": "HOUS"
    },
    {
        "label": "INNV - InnovAge Holding Corp.",
        "value": "INNV"
    },
    {
        "label": "HLLY - Holley Inc.",
        "value": "HLLY"
    },
    {
        "label": "LC - LendingClub Corp",
        "value": "LC"
    },
    {
        "label": "ZH - Zhihu Inc.",
        "value": "ZH"
    },
    {
        "label": "MDVL - MedAvail Holdings, Inc.",
        "value": "MDVL"
    },
    {
        "label": "HZO - MARINEMAX INC",
        "value": "HZO"
    },
    {
        "label": "SAVA - CASSAVA SCIENCES INC",
        "value": "SAVA"
    },
    {
        "label": "BJRI - BJs RESTAURANTS INC",
        "value": "BJRI"
    },
    {
        "label": "CRON - Cronos Group Inc.",
        "value": "CRON"
    },
    {
        "label": "MTTR - Matterport, Inc./DE",
        "value": "MTTR"
    },
    {
        "label": "PHK - PIMCO HIGH INCOME FUND",
        "value": "PHK"
    },
    {
        "label": "NNDM - Nano Dimension Ltd.",
        "value": "NNDM"
    },
    {
        "label": "CCVI - Churchill Capital Corp VI",
        "value": "CCVI"
    },
    {
        "label": "KE - Kimball Electronics, Inc.",
        "value": "KE"
    },
    {
        "label": "KARO - Karooooo Ltd.",
        "value": "KARO"
    },
    {
        "label": "TRNS - TRANSCAT INC",
        "value": "TRNS"
    },
    {
        "label": "BANC - BANC OF CALIFORNIA, INC.",
        "value": "BANC"
    },
    {
        "label": "PRTC - PureTech Health plc",
        "value": "PRTC"
    },
    {
        "label": "MYI - BLACKROCK MUNIYIELD QUALITY FUND III, INC.",
        "value": "MYI"
    },
    {
        "label": "CRMT - AMERICAS CARMART INC",
        "value": "CRMT"
    },
    {
        "label": "INBX - Inhibrx, Inc.",
        "value": "INBX"
    },
    {
        "label": "WDI - Western Asset Diversified Income Fund",
        "value": "WDI"
    },
    {
        "label": "TELL - TELLURIAN INC. /DE/",
        "value": "TELL"
    },
    {
        "label": "PFC - PREMIER FINANCIAL CORP",
        "value": "PFC"
    },
    {
        "label": "MAXN - Maxeon Solar Technologies, Ltd.",
        "value": "MAXN"
    },
    {
        "label": "GSL - Global Ship Lease, Inc.",
        "value": "GSL"
    },
    {
        "label": "PLOW - DOUGLAS DYNAMICS, INC",
        "value": "PLOW"
    },
    {
        "label": "SCSC - SCANSOURCE, INC.",
        "value": "SCSC"
    },
    {
        "label": "TCPC - BlackRock TCP Capital Corp.",
        "value": "TCPC"
    },
    {
        "label": "CCO - Clear Channel Outdoor Holdings, Inc.",
        "value": "CCO"
    },
    {
        "label": "CNDT - CONDUENT Inc",
        "value": "CNDT"
    },
    {
        "label": "NGD - New Gold Inc. /FI",
        "value": "NGD"
    },
    {
        "label": "TCNNF - Trulieve Cannabis Corp.",
        "value": "TCNNF"
    },
    {
        "label": "NMM - Navios Maritime Partners L.P.",
        "value": "NMM"
    },
    {
        "label": "OM - Outset Medical, Inc.",
        "value": "OM"
    },
    {
        "label": "NRDY - Nerdy Inc.",
        "value": "NRDY"
    },
    {
        "label": "DMRC - Digimarc CORP",
        "value": "DMRC"
    },
    {
        "label": "EDIT - Editas Medicine, Inc.",
        "value": "EDIT"
    },
    {
        "label": "DLY - DoubleLine Yield Opportunities Fund",
        "value": "DLY"
    },
    {
        "label": "NVRO - NEVRO CORP",
        "value": "NVRO"
    },
    {
        "label": "SILV - SilverCrest Metals Inc.",
        "value": "SILV"
    },
    {
        "label": "CBL - CBL & ASSOCIATES PROPERTIES INC",
        "value": "CBL"
    },
    {
        "label": "ELVN - Enliven Therapeutics, Inc.",
        "value": "ELVN"
    },
    {
        "label": "MYE - MYERS INDUSTRIES INC",
        "value": "MYE"
    },
    {
        "label": "BIOX - Bioceres Crop Solutions Corp.",
        "value": "BIOX"
    },
    {
        "label": "EOSS - EOS INC.",
        "value": "EOSS"
    },
    {
        "label": "EIM - EATON VANCE MUNICIPAL BOND FUND",
        "value": "EIM"
    },
    {
        "label": "SMRT - SmartRent, Inc.",
        "value": "SMRT"
    },
    {
        "label": "BASE - Couchbase, Inc.",
        "value": "BASE"
    },
    {
        "label": "MTEM - Molecular Templates, Inc.",
        "value": "MTEM"
    },
    {
        "label": "PGRU - PROPERTYGURU GROUP LTD",
        "value": "PGRU"
    },
    {
        "label": "BLTE - BELITE BIO, INC",
        "value": "BLTE"
    },
    {
        "label": "JQC - Nuveen Credit Strategies Income Fund",
        "value": "JQC"
    },
    {
        "label": "WEAV - Weave Communications, Inc.",
        "value": "WEAV"
    },
    {
        "label": "HTD - JOHN HANCOCK TAX-ADVANTAGED DIVIDEND INCOME FUND",
        "value": "HTD"
    },
    {
        "label": "HCAT - Health Catalyst, Inc.",
        "value": "HCAT"
    },
    {
        "label": "MEGI - MainStay CBRE Global Infrastructure Megatrends Term Fund",
        "value": "MEGI"
    },
    {
        "label": "BALY - Bally's Corp",
        "value": "BALY"
    },
    {
        "label": "GHIX - Gores Holdings IX, Inc.",
        "value": "GHIX"
    },
    {
        "label": "DBI - Designer Brands Inc.",
        "value": "DBI"
    },
    {
        "label": "NEXA - Nexa Resources S.A.",
        "value": "NEXA"
    },
    {
        "label": "LVRO - Lavoro Ltd",
        "value": "LVRO"
    },
    {
        "label": "VTS - Vitesse Energy, Inc.",
        "value": "VTS"
    },
    {
        "label": "NXP - NUVEEN SELECT TAX FREE INCOME PORTFOLIO",
        "value": "NXP"
    },
    {
        "label": "NRDS - NERDWALLET, INC.",
        "value": "NRDS"
    },
    {
        "label": "REPX - Riley Exploration Permian, Inc.",
        "value": "REPX"
    },
    {
        "label": "CINT - CI&T Inc",
        "value": "CINT"
    },
    {
        "label": "JPC - Nuveen Preferred & Income Opportunities Fund",
        "value": "JPC"
    },
    {
        "label": "PKST - Peakstone Realty Trust",
        "value": "PKST"
    },
    {
        "label": "CHUY - CHUY'S HOLDINGS, INC.",
        "value": "CHUY"
    },
    {
        "label": "OFIX - Orthofix Medical Inc.",
        "value": "OFIX"
    },
    {
        "label": "FCEL - FUELCELL ENERGY INC",
        "value": "FCEL"
    },
    {
        "label": "TTI - TETRA TECHNOLOGIES INC",
        "value": "TTI"
    },
    {
        "label": "DX - DYNEX CAPITAL INC",
        "value": "DX"
    },
    {
        "label": "OSBC - OLD SECOND BANCORP INC",
        "value": "OSBC"
    },
    {
        "label": "FDMT - 4D Molecular Therapeutics, Inc.",
        "value": "FDMT"
    },
    {
        "label": "OABI - OmniAb, Inc.",
        "value": "OABI"
    },
    {
        "label": "DHC - DIVERSIFIED HEALTHCARE TRUST",
        "value": "DHC"
    },
    {
        "label": "AVXL - ANAVEX LIFE SCIENCES CORP.",
        "value": "AVXL"
    },
    {
        "label": "TITN - Titan Machinery Inc.",
        "value": "TITN"
    },
    {
        "label": "CTBI - COMMUNITY TRUST BANCORP INC /KY/",
        "value": "CTBI"
    },
    {
        "label": "TYGO - TIGO ENERGY, INC.",
        "value": "TYGO"
    },
    {
        "label": "CLOV - CLOVER HEALTH INVESTMENTS, CORP. /DE",
        "value": "CLOV"
    },
    {
        "label": "ALCC - AltC Acquisition Corp.",
        "value": "ALCC"
    },
    {
        "label": "SCVL - SHOE CARNIVAL INC",
        "value": "SCVL"
    },
    {
        "label": "BLBD - Blue Bird Corp",
        "value": "BLBD"
    },
    {
        "label": "HSTM - HEALTHSTREAM INC",
        "value": "HSTM"
    },
    {
        "label": "VMEO - Vimeo, Inc.",
        "value": "VMEO"
    },
    {
        "label": "WWW - WOLVERINE WORLD WIDE INC /DE/",
        "value": "WWW"
    },
    {
        "label": "AORT - ARTIVION, INC.",
        "value": "AORT"
    },
    {
        "label": "GMRE - Global Medical REIT Inc.",
        "value": "GMRE"
    },
    {
        "label": "ALLG - Allego N.V.",
        "value": "ALLG"
    },
    {
        "label": "EOI - Eaton Vance Enhanced Equity Income Fund",
        "value": "EOI"
    },
    {
        "label": "UHT - UNIVERSAL HEALTH REALTY INCOME TRUST",
        "value": "UHT"
    },
    {
        "label": "RAPT - RAPT Therapeutics, Inc.",
        "value": "RAPT"
    },
    {
        "label": "BRY - Berry Corp (bry)",
        "value": "BRY"
    },
    {
        "label": "WALD - Waldencast plc",
        "value": "WALD"
    },
    {
        "label": "TXO - TXO Partners, L.P.",
        "value": "TXO"
    },
    {
        "label": "AACT - Ares Acquisition Corp II",
        "value": "AACT"
    },
    {
        "label": "SVA - SINOVAC BIOTECH LTD",
        "value": "SVA"
    },
    {
        "label": "PUBM - PubMatic, Inc.",
        "value": "PUBM"
    },
    {
        "label": "FAX - ABRDN ASIA-PACIFIC INCOME FUND, INC.",
        "value": "FAX"
    },
    {
        "label": "KELYA - KELLY SERVICES INC",
        "value": "KELYA"
    },
    {
        "label": "WOW - WideOpenWest, Inc.",
        "value": "WOW"
    },
    {
        "label": "CVLG - COVENANT LOGISTICS GROUP, INC.",
        "value": "CVLG"
    },
    {
        "label": "ATEX - Anterix Inc.",
        "value": "ATEX"
    },
    {
        "label": "FFC - Flaherty & Crumrine PREFERRED & INCOME SECURITIES FUND INC",
        "value": "FFC"
    },
    {
        "label": "DCO - DUCOMMUN INC /DE/",
        "value": "DCO"
    },
    {
        "label": "TK - TEEKAY CORP",
        "value": "TK"
    },
    {
        "label": "BBSI - BARRETT BUSINESS SERVICES INC",
        "value": "BBSI"
    },
    {
        "label": "ADTN - ADTRAN Holdings, Inc.",
        "value": "ADTN"
    },
    {
        "label": "TNP - TSAKOS ENERGY NAVIGATION LTD",
        "value": "TNP"
    },
    {
        "label": "TNGX - Tango Therapeutics, Inc.",
        "value": "TNGX"
    },
    {
        "label": "IMXI - International Money Express, Inc.",
        "value": "IMXI"
    },
    {
        "label": "SPRY - ARS Pharmaceuticals, Inc.",
        "value": "SPRY"
    },
    {
        "label": "CHS - CHICO'S FAS, INC.",
        "value": "CHS"
    },
    {
        "label": "SLI - STANDARD LITHIUM LTD.",
        "value": "SLI"
    },
    {
        "label": "IOCJY - IOCHPE-MAXION SA /FI",
        "value": "IOCJY"
    },
    {
        "label": "REX - REX AMERICAN RESOURCES Corp",
        "value": "REX"
    },
    {
        "label": "HCKT - HACKETT GROUP, INC.",
        "value": "HCKT"
    },
    {
        "label": "EVM - EATON VANCE CALIFORNIA MUNICIPAL BOND FUND",
        "value": "EVM"
    },
    {
        "label": "CYRX - Cryoport, Inc.",
        "value": "CYRX"
    },
    {
        "label": "PAXS - PIMCO Access Income Fund",
        "value": "PAXS"
    },
    {
        "label": "PHVS - Pharvaris N.V.",
        "value": "PHVS"
    },
    {
        "label": "WDH - Waterdrop Inc.",
        "value": "WDH"
    },
    {
        "label": "PCN - PIMCO CORPORATE & INCOME STRATEGY FUND",
        "value": "PCN"
    },
    {
        "label": "CVGW - CALAVO GROWERS INC",
        "value": "CVGW"
    },
    {
        "label": "LEU - CENTRUS ENERGY CORP",
        "value": "LEU"
    },
    {
        "label": "GSBC - GREAT SOUTHERN BANCORP, INC.",
        "value": "GSBC"
    },
    {
        "label": "NVAX - NOVAVAX INC",
        "value": "NVAX"
    },
    {
        "label": "NDMO - Nuveen Dynamic Municipal Opportunities Fund",
        "value": "NDMO"
    },
    {
        "label": "NOA - North American Construction Group Ltd.",
        "value": "NOA"
    },
    {
        "label": "HFWA - HERITAGE FINANCIAL CORP /WA/",
        "value": "HFWA"
    },
    {
        "label": "ALPN - ALPINE IMMUNE SCIENCES, INC.",
        "value": "ALPN"
    },
    {
        "label": "ENFN - Enfusion, Inc.",
        "value": "ENFN"
    },
    {
        "label": "CLSK - CLEANSPARK, INC.",
        "value": "CLSK"
    },
    {
        "label": "HAYN - HAYNES INTERNATIONAL INC",
        "value": "HAYN"
    },
    {
        "label": "SYTA - Siyata Mobile Inc.",
        "value": "SYTA"
    },
    {
        "label": "FUBO - fuboTV Inc. /FL",
        "value": "FUBO"
    },
    {
        "label": "GNLX - GENELUX Corp",
        "value": "GNLX"
    },
    {
        "label": "RICK - RCI HOSPITALITY HOLDINGS, INC.",
        "value": "RICK"
    },
    {
        "label": "NMCO - Nuveen Municipal Credit Opportunities Fund",
        "value": "NMCO"
    },
    {
        "label": "ECC - Eagle Point Credit Co Inc.",
        "value": "ECC"
    },
    {
        "label": "CCSI - Consensus Cloud Solutions, Inc.",
        "value": "CCSI"
    },
    {
        "label": "VMO - Invesco Municipal Opportunity Trust",
        "value": "VMO"
    },
    {
        "label": "IRMD - IRADIMED CORP",
        "value": "IRMD"
    },
    {
        "label": "MOV - MOVADO GROUP INC",
        "value": "MOV"
    },
    {
        "label": "PBI - PITNEY BOWES INC /DE/",
        "value": "PBI"
    },
    {
        "label": "KAMN - KAMAN Corp",
        "value": "KAMN"
    },
    {
        "label": "VSLAX - Invesco Senior Loan Fund",
        "value": "VSLAX"
    },
    {
        "label": "CCAP - Crescent Capital BDC, Inc.",
        "value": "CCAP"
    },
    {
        "label": "VVI - VIAD CORP",
        "value": "VVI"
    },
    {
        "label": "BOE - BlackRock Enhanced Global Dividend Trust",
        "value": "BOE"
    },
    {
        "label": "AMBC - AMBAC FINANCIAL GROUP INC",
        "value": "AMBC"
    },
    {
        "label": "DYN - Dyne Therapeutics, Inc.",
        "value": "DYN"
    },
    {
        "label": "GHG - GreenTree Hospitality Group Ltd.",
        "value": "GHG"
    },
    {
        "label": "LDI - loanDepot, Inc.",
        "value": "LDI"
    },
    {
        "label": "MVST - Microvast Holdings, Inc.",
        "value": "MVST"
    },
    {
        "label": "IDT - IDT CORP",
        "value": "IDT"
    },
    {
        "label": "IGR - CBRE GLOBAL REAL ESTATE INCOME FUND",
        "value": "IGR"
    },
    {
        "label": "CLW - Clearwater Paper Corp",
        "value": "CLW"
    },
    {
        "label": "MGIC - MAGIC SOFTWARE ENTERPRISES LTD",
        "value": "MGIC"
    },
    {
        "label": "WTI - W&T OFFSHORE INC",
        "value": "WTI"
    },
    {
        "label": "ASLE - AerSale Corp",
        "value": "ASLE"
    },
    {
        "label": "HBIA - HILLS BANCORPORATION",
        "value": "HBIA"
    },
    {
        "label": "PAHC - PHIBRO ANIMAL HEALTH CORP",
        "value": "PAHC"
    },
    {
        "label": "FCBC - FIRST COMMUNITY BANKSHARES INC /VA/",
        "value": "FCBC"
    },
    {
        "label": "BMEA - Biomea Fusion, Inc.",
        "value": "BMEA"
    },
    {
        "label": "APLD - Applied Digital Corp.",
        "value": "APLD"
    },
    {
        "label": "GNK - GENCO SHIPPING & TRADING LTD",
        "value": "GNK"
    },
    {
        "label": "TYRA - Tyra Biosciences, Inc.",
        "value": "TYRA"
    },
    {
        "label": "HBT - HBT Financial, Inc.",
        "value": "HBT"
    },
    {
        "label": "FC - FRANKLIN COVEY CO",
        "value": "FC"
    },
    {
        "label": "IIIN - INSTEEL INDUSTRIES INC",
        "value": "IIIN"
    },
    {
        "label": "DOMO - DOMO, INC.",
        "value": "DOMO"
    },
    {
        "label": "MHD - BLACKROCK MUNIHOLDINGS FUND, INC.",
        "value": "MHD"
    },
    {
        "label": "AIO - Virtus Artificial Intelligence & Technology Opportunities Fund",
        "value": "AIO"
    },
    {
        "label": "FORR - FORRESTER RESEARCH, INC.",
        "value": "FORR"
    },
    {
        "label": "INN - Summit Hotel Properties, Inc.",
        "value": "INN"
    },
    {
        "label": "ALEC - Alector, Inc.",
        "value": "ALEC"
    },
    {
        "label": "TGI - TRIUMPH GROUP INC",
        "value": "TGI"
    },
    {
        "label": "HWGC - Fintech Scion Ltd",
        "value": "HWGC"
    },
    {
        "label": "CION - CION Investment Corp",
        "value": "CION"
    },
    {
        "label": "CLFD - Clearfield, Inc.",
        "value": "CLFD"
    },
    {
        "label": "ITRN - Ituran Location & Control Ltd.",
        "value": "ITRN"
    },
    {
        "label": "SWBI - SMITH & WESSON BRANDS, INC.",
        "value": "SWBI"
    },
    {
        "label": "COOK - Traeger, Inc.",
        "value": "COOK"
    },
    {
        "label": "YORW - YORK WATER CO",
        "value": "YORW"
    },
    {
        "label": "JOUT - JOHNSON OUTDOORS INC",
        "value": "JOUT"
    },
    {
        "label": "CNTA - Centessa Pharmaceuticals plc",
        "value": "CNTA"
    },
    {
        "label": "MUJ - BLACKROCK MUNIHOLDINGS NEW JERSEY QUALITY FUND, INC.",
        "value": "MUJ"
    },
    {
        "label": "DXPE - DXP ENTERPRISES INC",
        "value": "DXPE"
    },
    {
        "label": "JRVR - James River Group Holdings, Ltd.",
        "value": "JRVR"
    },
    {
        "label": "CCB - COASTAL FINANCIAL CORP",
        "value": "CCB"
    },
    {
        "label": "BH-A - Biglari Holdings Inc.",
        "value": "BH-A"
    },
    {
        "label": "HROW - HARROW HEALTH, INC.",
        "value": "HROW"
    },
    {
        "label": "SD - SANDRIDGE ENERGY INC",
        "value": "SD"
    },
    {
        "label": "ATNI - ATN International, Inc.",
        "value": "ATNI"
    },
    {
        "label": "BIOL - BIOLASE, INC",
        "value": "BIOL"
    },
    {
        "label": "PFN - PIMCO Income Strategy Fund II",
        "value": "PFN"
    },
    {
        "label": "ALLO - Allogene Therapeutics, Inc.",
        "value": "ALLO"
    },
    {
        "label": "LANV - Lanvin Group Holdings Ltd",
        "value": "LANV"
    },
    {
        "label": "RNA - Avidity Biosciences, Inc.",
        "value": "RNA"
    },
    {
        "label": "BVH - Bluegreen Vacations Holding Corp",
        "value": "BVH"
    },
    {
        "label": "VVR - Invesco Senior Income Trust",
        "value": "VVR"
    },
    {
        "label": "DBA - INVESCO DB AGRICULTURE FUND",
        "value": "DBA"
    },
    {
        "label": "FPI - Farmland Partners Inc.",
        "value": "FPI"
    },
    {
        "label": "FMBH - FIRST MID BANCSHARES, INC.",
        "value": "FMBH"
    },
    {
        "label": "TIPT - TIPTREE INC.",
        "value": "TIPT"
    },
    {
        "label": "GCBC - GREENE COUNTY BANCORP INC",
        "value": "GCBC"
    },
    {
        "label": "ZING - FTAC ZEUS ACQUISITION CORP.",
        "value": "ZING"
    },
    {
        "label": "SRI - STONERIDGE INC",
        "value": "SRI"
    },
    {
        "label": "BELFA - BEL FUSE INC /NJ",
        "value": "BELFA"
    },
    {
        "label": "HUYA - HUYA Inc.",
        "value": "HUYA"
    },
    {
        "label": "PML - PIMCO MUNICIPAL INCOME FUND II",
        "value": "PML"
    },
    {
        "label": "AMTB - Amerant Bancorp Inc.",
        "value": "AMTB"
    },
    {
        "label": "PETQ - PetIQ, Inc.",
        "value": "PETQ"
    },
    {
        "label": "EHAB - Enhabit, Inc.",
        "value": "EHAB"
    },
    {
        "label": "PEO - ADAMS NATURAL RESOURCES FUND, INC.",
        "value": "PEO"
    },
    {
        "label": "DWAC - Digital World Acquisition Corp.",
        "value": "DWAC"
    },
    {
        "label": "TILE - INTERFACE INC",
        "value": "TILE"
    },
    {
        "label": "LAND - GLADSTONE LAND Corp",
        "value": "LAND"
    },
    {
        "label": "GLDD - Great Lakes Dredge & Dock CORP",
        "value": "GLDD"
    },
    {
        "label": "QNST - QUINSTREET, INC",
        "value": "QNST"
    },
    {
        "label": "NIE - Virtus Equity & Convertible Income Fund",
        "value": "NIE"
    },
    {
        "label": "NVRI - ENVIRI Corp",
        "value": "NVRI"
    },
    {
        "label": "YSG - Yatsen Holding Ltd",
        "value": "YSG"
    },
    {
        "label": "RERE - ATRenew Inc.",
        "value": "RERE"
    },
    {
        "label": "AMAL - Amalgamated Financial Corp.",
        "value": "AMAL"
    },
    {
        "label": "GPRK - GeoPark Ltd",
        "value": "GPRK"
    },
    {
        "label": "MULN - MULLEN AUTOMOTIVE INC.",
        "value": "MULN"
    },
    {
        "label": "GPRO - GoPro, Inc.",
        "value": "GPRO"
    },
    {
        "label": "ZEUS - OLYMPIC STEEL INC",
        "value": "ZEUS"
    },
    {
        "label": "LQDT - LIQUIDITY SERVICES INC",
        "value": "LQDT"
    },
    {
        "label": "MPX - MARINE PRODUCTS CORP",
        "value": "MPX"
    },
    {
        "label": "AMBI - Ambipar Emergency Response",
        "value": "AMBI"
    },
    {
        "label": "SCLX - Scilex Holding Co",
        "value": "SCLX"
    },
    {
        "label": "GGN - GAMCO Global Gold, Natural Resources & Income Trust",
        "value": "GGN"
    },
    {
        "label": "ASC - Ardmore Shipping Corp",
        "value": "ASC"
    },
    {
        "label": "SNBR - Sleep Number Corp",
        "value": "SNBR"
    },
    {
        "label": "HFRO - HIGHLAND OPPORTUNITIES & INCOME FUND",
        "value": "HFRO"
    },
    {
        "label": "LYEL - Lyell Immunopharma, Inc.",
        "value": "LYEL"
    },
    {
        "label": "OBE - OBSIDIAN ENERGY LTD.",
        "value": "OBE"
    },
    {
        "label": "EBF - ENNIS, INC.",
        "value": "EBF"
    },
    {
        "label": "TBPH - Theravance Biopharma, Inc.",
        "value": "TBPH"
    },
    {
        "label": "DENN - DENNY'S Corp",
        "value": "DENN"
    },
    {
        "label": "WBX - Wallbox N.V.",
        "value": "WBX"
    },
    {
        "label": "SCWX - SecureWorks Corp",
        "value": "SCWX"
    },
    {
        "label": "MTW - MANITOWOC CO INC",
        "value": "MTW"
    },
    {
        "label": "BHK - BLACKROCK CORE BOND TRUST",
        "value": "BHK"
    },
    {
        "label": "MERC - MERCER INTERNATIONAL INC.",
        "value": "MERC"
    },
    {
        "label": "ATRO - ASTRONICS CORP",
        "value": "ATRO"
    },
    {
        "label": "MTAL - Metals Acquisition Ltd",
        "value": "MTAL"
    },
    {
        "label": "ISPR - Ispire Technology Inc.",
        "value": "ISPR"
    },
    {
        "label": "EOLS - Evolus, Inc.",
        "value": "EOLS"
    },
    {
        "label": "LAW - CS Disco, Inc.",
        "value": "LAW"
    },
    {
        "label": "HTBK - HERITAGE COMMERCE CORP",
        "value": "HTBK"
    },
    {
        "label": "BIT - BlackRock Multi-Sector Income Trust",
        "value": "BIT"
    },
    {
        "label": "TRST - TRUSTCO BANK CORP N Y",
        "value": "TRST"
    },
    {
        "label": "BME - BlackRock Health Sciences Trust",
        "value": "BME"
    },
    {
        "label": "UVSP - UNIVEST FINANCIAL Corp",
        "value": "UVSP"
    },
    {
        "label": "HUT - HUT 8 MINING CORP.",
        "value": "HUT"
    },
    {
        "label": "GAMB - Gambling.com Group Ltd",
        "value": "GAMB"
    },
    {
        "label": "HIBB - HIBBETT INC",
        "value": "HIBB"
    },
    {
        "label": "VTRU - Vitru Ltd",
        "value": "VTRU"
    },
    {
        "label": "HAFC - HANMI FINANCIAL CORP",
        "value": "HAFC"
    },
    {
        "label": "GHRS - GH Research PLC",
        "value": "GHRS"
    },
    {
        "label": "TSNDF - TerrAscend Corp.",
        "value": "TSNDF"
    },
    {
        "label": "NPK - NATIONAL PRESTO INDUSTRIES INC",
        "value": "NPK"
    },
    {
        "label": "BWMX - BETTERWARE DE MEXICO, S.A.P.I. DE C.V",
        "value": "BWMX"
    },
    {
        "label": "ETJ - Eaton Vance Risk-Managed Diversified Equity Income Fund",
        "value": "ETJ"
    },
    {
        "label": "GOOD - GLADSTONE COMMERCIAL CORP",
        "value": "GOOD"
    },
    {
        "label": "MBWM - MERCANTILE BANK CORP",
        "value": "MBWM"
    },
    {
        "label": "RGP - RESOURCES CONNECTION, INC.",
        "value": "RGP"
    },
    {
        "label": "EMD - WESTERN ASSET EMERGING MARKETS DEBT FUND INC.",
        "value": "EMD"
    },
    {
        "label": "EXK - ENDEAVOUR SILVER CORP",
        "value": "EXK"
    },
    {
        "label": "IIM - Invesco Value Municipal Income Trust",
        "value": "IIM"
    },
    {
        "label": "ACRE - Ares Commercial Real Estate Corp",
        "value": "ACRE"
    },
    {
        "label": "FRPH - FRP HOLDINGS, INC.",
        "value": "FRPH"
    },
    {
        "label": "MODV - ModivCare Inc",
        "value": "MODV"
    },
    {
        "label": "CFB - CROSSFIRST BANKSHARES, INC.",
        "value": "CFB"
    },
    {
        "label": "CASS - CASS INFORMATION SYSTEMS INC",
        "value": "CASS"
    },
    {
        "label": "AMOT - ALLIED MOTION TECHNOLOGIES INC",
        "value": "AMOT"
    },
    {
        "label": "SRDX - SURMODICS INC",
        "value": "SRDX"
    },
    {
        "label": "BOC - BOSTON OMAHA Corp",
        "value": "BOC"
    },
    {
        "label": "HSII - HEIDRICK & STRUGGLES INTERNATIONAL INC",
        "value": "HSII"
    },
    {
        "label": "AGX - ARGAN INC",
        "value": "AGX"
    },
    {
        "label": "MAX - MediaAlpha, Inc.",
        "value": "MAX"
    },
    {
        "label": "CRBU - Caribou Biosciences, Inc.",
        "value": "CRBU"
    },
    {
        "label": "BGY - BlackRock Enhanced International Dividend Trust",
        "value": "BGY"
    },
    {
        "label": "HVT - HAVERTY FURNITURE COMPANIES INC",
        "value": "HVT"
    },
    {
        "label": "CTLP - CANTALOUPE, INC.",
        "value": "CTLP"
    },
    {
        "label": "NNOX - Nano-X Imaging Ltd.",
        "value": "NNOX"
    },
    {
        "label": "WIW - WESTERN ASSET INFLATION-LINKED OPPORTUNITIES & INCOME FUND",
        "value": "WIW"
    },
    {
        "label": "RUPRF - RUPERT RESOURCES LTD",
        "value": "RUPRF"
    },
    {
        "label": "CCD - Calamos Dynamic Convertible & Income Fund",
        "value": "CCD"
    },
    {
        "label": "KRNY - Kearny Financial Corp.",
        "value": "KRNY"
    },
    {
        "label": "BTO - JOHN HANCOCK FINANCIAL OPPORTUNITIES FUND",
        "value": "BTO"
    },
    {
        "label": "LIND - LINDBLAD EXPEDITIONS HOLDINGS, INC.",
        "value": "LIND"
    },
    {
        "label": "EGY - VAALCO ENERGY INC /DE/",
        "value": "EGY"
    },
    {
        "label": "AMCX - AMC Networks Inc.",
        "value": "AMCX"
    },
    {
        "label": "TIGR - UP Fintech Holding Ltd",
        "value": "TIGR"
    },
    {
        "label": "RMAX - RE/MAX Holdings, Inc.",
        "value": "RMAX"
    },
    {
        "label": "NKX - NUVEEN CALIFORNIA AMT-FREE QUALITY MUNICIPAL INCOME FUND",
        "value": "NKX"
    },
    {
        "label": "NWBO - NORTHWEST BIOTHERAPEUTICS INC",
        "value": "NWBO"
    },
    {
        "label": "VINP - Vinci Partners Investments Ltd.",
        "value": "VINP"
    },
    {
        "label": "CCBG - CAPITAL CITY BANK GROUP INC",
        "value": "CCBG"
    },
    {
        "label": "PFLT - PennantPark Floating Rate Capital Ltd.",
        "value": "PFLT"
    },
    {
        "label": "ML - MONEYLION INC.",
        "value": "ML"
    },
    {
        "label": "RWAY - Runway Growth Finance Corp.",
        "value": "RWAY"
    },
    {
        "label": "PGC - PEAPACK GLADSTONE FINANCIAL CORP",
        "value": "PGC"
    },
    {
        "label": "AUTL - Autolus Therapeutics plc",
        "value": "AUTL"
    },
    {
        "label": "DIAX - Nuveen Dow 30sm Dynamic Overwrite Fund",
        "value": "DIAX"
    },
    {
        "label": "ACRS - Aclaris Therapeutics, Inc.",
        "value": "ACRS"
    },
    {
        "label": "LDP - Cohen & Steers Ltd Duration Preferred & Income Fund, Inc.",
        "value": "LDP"
    },
    {
        "label": "NFBK - Northfield Bancorp, Inc.",
        "value": "NFBK"
    },
    {
        "label": "DESP - Despegar.com, Corp.",
        "value": "DESP"
    },
    {
        "label": "THW - Tekla World Healthcare Fund",
        "value": "THW"
    },
    {
        "label": "GENK - GEN Restaurant Group, Inc.",
        "value": "GENK"
    },
    {
        "label": "TRIN - Trinity Capital Inc.",
        "value": "TRIN"
    },
    {
        "label": "ME - 23andMe Holding Co.",
        "value": "ME"
    },
    {
        "label": "JANX - Janux Therapeutics, Inc.",
        "value": "JANX"
    },
    {
        "label": "ACCO - ACCO BRANDS Corp",
        "value": "ACCO"
    },
    {
        "label": "XPER - Xperi Inc.",
        "value": "XPER"
    },
    {
        "label": "CMPO - CompoSecure, Inc.",
        "value": "CMPO"
    },
    {
        "label": "BFST - Business First Bancshares, Inc.",
        "value": "BFST"
    },
    {
        "label": "DHIL - DIAMOND HILL INVESTMENT GROUP INC",
        "value": "DHIL"
    },
    {
        "label": "SHYF - SHYFT GROUP, INC.",
        "value": "SHYF"
    },
    {
        "label": "LEGH - Legacy Housing Corp",
        "value": "LEGH"
    },
    {
        "label": "TRTX - TPG RE Finance Trust, Inc.",
        "value": "TRTX"
    },
    {
        "label": "EUBG - ENTREPRENEUR UNIVERSE BRIGHT GROUP",
        "value": "EUBG"
    },
    {
        "label": "ANAB - ANAPTYSBIO, INC",
        "value": "ANAB"
    },
    {
        "label": "VGM - Invesco Trust for Investment Grade Municipals",
        "value": "VGM"
    },
    {
        "label": "MCS - MARCUS CORP",
        "value": "MCS"
    },
    {
        "label": "GUT - GABELLI UTILITY TRUST",
        "value": "GUT"
    },
    {
        "label": "STKL - SunOpta Inc.",
        "value": "STKL"
    },
    {
        "label": "OTMO - Otonomo Technologies Ltd.",
        "value": "OTMO"
    },
    {
        "label": "NLST - NETLIST INC",
        "value": "NLST"
    },
    {
        "label": "BENF - Beneficient",
        "value": "BENF"
    },
    {
        "label": "PVNC - PREVENTION INSURANCE COM",
        "value": "PVNC"
    },
    {
        "label": "VKQ - Invesco Municipal Trust",
        "value": "VKQ"
    },
    {
        "label": "MCBS - MetroCity Bankshares, Inc.",
        "value": "MCBS"
    },
    {
        "label": "NGL - NGL Energy Partners LP",
        "value": "NGL"
    },
    {
        "label": "HBNC - HORIZON BANCORP INC /IN/",
        "value": "HBNC"
    },
    {
        "label": "DM - Desktop Metal, Inc.",
        "value": "DM"
    },
    {
        "label": "BROG - Brooge Energy Ltd",
        "value": "BROG"
    },
    {
        "label": "BLFS - BIOLIFE SOLUTIONS INC",
        "value": "BLFS"
    },
    {
        "label": "MOND - Mondee Holdings, Inc.",
        "value": "MOND"
    },
    {
        "label": "CAC - CAMDEN NATIONAL CORP",
        "value": "CAC"
    },
    {
        "label": "FTHY - FIRST TRUST HIGH YIELD OPPORTUNITIES 2027 TERM FUND",
        "value": "FTHY"
    },
    {
        "label": "RXT - Rackspace Technology, Inc.",
        "value": "RXT"
    },
    {
        "label": "LASR - NLIGHT, INC.",
        "value": "LASR"
    },
    {
        "label": "OIS - OIL STATES INTERNATIONAL, INC",
        "value": "OIS"
    },
    {
        "label": "SMBC - SOUTHERN MISSOURI BANCORP, INC.",
        "value": "SMBC"
    },
    {
        "label": "SUPV - Grupo Supervielle S.A.",
        "value": "SUPV"
    },
    {
        "label": "WSR - Whitestone REIT",
        "value": "WSR"
    },
    {
        "label": "PDT - JOHN HANCOCK PREMIUM DIVIDEND FUND",
        "value": "PDT"
    },
    {
        "label": "IAUX - i-80 Gold Corp.",
        "value": "IAUX"
    },
    {
        "label": "SMR - NUSCALE POWER Corp",
        "value": "SMR"
    },
    {
        "label": "ARQT - Arcutis Biotherapeutics, Inc.",
        "value": "ARQT"
    },
    {
        "label": "FLWS - 1 800 FLOWERS COM INC",
        "value": "FLWS"
    },
    {
        "label": "BGB - Blackstone Strategic Credit 2027 Term Fund",
        "value": "BGB"
    },
    {
        "label": "AERG - APPLIED ENERGETICS, INC.",
        "value": "AERG"
    },
    {
        "label": "LILM - Lilium N.V.",
        "value": "LILM"
    },
    {
        "label": "VITL - Vital Farms, Inc.",
        "value": "VITL"
    },
    {
        "label": "QD - Qudian Inc.",
        "value": "QD"
    },
    {
        "label": "SOUN - SOUNDHOUND AI, INC.",
        "value": "SOUN"
    },
    {
        "label": "SOI - Solaris Oilfield Infrastructure, Inc.",
        "value": "SOI"
    },
    {
        "label": "DDL - Dingdong (Cayman) Ltd",
        "value": "DDL"
    },
    {
        "label": "MLYS - Mineralys Therapeutics, Inc.",
        "value": "MLYS"
    },
    {
        "label": "IFN - INDIA FUND, INC.",
        "value": "IFN"
    },
    {
        "label": "CABA - Cabaletta Bio, Inc.",
        "value": "CABA"
    },
    {
        "label": "PTSI - PAM TRANSPORTATION SERVICES INC",
        "value": "PTSI"
    },
    {
        "label": "HOV - HOVNANIAN ENTERPRISES INC",
        "value": "HOV"
    },
    {
        "label": "MITK - MITEK SYSTEMS INC",
        "value": "MITK"
    },
    {
        "label": "TRDA - Entrada Therapeutics, Inc.",
        "value": "TRDA"
    },
    {
        "label": "CEM - ClearBridge MLP & Midstream Fund Inc.",
        "value": "CEM"
    },
    {
        "label": "WASH - WASHINGTON TRUST BANCORP INC",
        "value": "WASH"
    },
    {
        "label": "UFCS - UNITED FIRE GROUP INC",
        "value": "UFCS"
    },
    {
        "label": "MYPS - PLAYSTUDIOS, Inc.",
        "value": "MYPS"
    },
    {
        "label": "HCI - HCI Group, Inc.",
        "value": "HCI"
    },
    {
        "label": "BUI - BlackRock Utilities, Infrastructure & Power Opportunities Trust",
        "value": "BUI"
    },
    {
        "label": "MSBI - Midland States Bancorp, Inc.",
        "value": "MSBI"
    },
    {
        "label": "METC - Ramaco Resources, Inc.",
        "value": "METC"
    },
    {
        "label": "BLE - BLACKROCK MUNICIPAL INCOME TRUST II",
        "value": "BLE"
    },
    {
        "label": "IGIC - International General Insurance Holdings Ltd.",
        "value": "IGIC"
    },
    {
        "label": "SMWB - SIMILARWEB LTD.",
        "value": "SMWB"
    },
    {
        "label": "FMNB - FARMERS NATIONAL BANC CORP /OH/",
        "value": "FMNB"
    },
    {
        "label": "IQI - Invesco Quality Municipal Income Trust",
        "value": "IQI"
    },
    {
        "label": "HEPS - D-MARKET Electronic Services & Trading",
        "value": "HEPS"
    },
    {
        "label": "AMPX - Amprius Technologies, Inc.",
        "value": "AMPX"
    },
    {
        "label": "ARTNA - ARTESIAN RESOURCES CORP",
        "value": "ARTNA"
    },
    {
        "label": "RBBN - Ribbon Communications Inc.",
        "value": "RBBN"
    },
    {
        "label": "FDUS - FIDUS INVESTMENT Corp",
        "value": "FDUS"
    },
    {
        "label": "TBLD - Thornburg Income Builder Opportunities Trust",
        "value": "TBLD"
    },
    {
        "label": "LBC - Luther Burbank Corp",
        "value": "LBC"
    },
    {
        "label": "ATLC - Atlanticus Holdings Corp",
        "value": "ATLC"
    },
    {
        "label": "SCPL - SciPlay Corp",
        "value": "SCPL"
    },
    {
        "label": "HA - HAWAIIAN HOLDINGS INC",
        "value": "HA"
    },
    {
        "label": "GCT - GigaCloud Technology Inc",
        "value": "GCT"
    },
    {
        "label": "VPG - Vishay Precision Group, Inc.",
        "value": "VPG"
    },
    {
        "label": "EZPW - EZCORP INC",
        "value": "EZPW"
    },
    {
        "label": "DGICA - DONEGAL GROUP INC",
        "value": "DGICA"
    },
    {
        "label": "ABL - Abacus Life, Inc.",
        "value": "ABL"
    },
    {
        "label": "MCB - Metropolitan Bank Holding Corp.",
        "value": "MCB"
    },
    {
        "label": "OSUR - ORASURE TECHNOLOGIES INC",
        "value": "OSUR"
    },
    {
        "label": "VALU - VALUE LINE INC",
        "value": "VALU"
    },
    {
        "label": "ODC - Oil-Dri Corp of America",
        "value": "ODC"
    },
    {
        "label": "FMCC - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCC"
    },
    {
        "label": "CEVA - CEVA INC",
        "value": "CEVA"
    },
    {
        "label": "PERF - Perfect Corp.",
        "value": "PERF"
    },
    {
        "label": "GBLI - Global Indemnity Group, LLC",
        "value": "GBLI"
    },
    {
        "label": "MYD - BLACKROCK MUNIYIELD FUND, INC.",
        "value": "MYD"
    },
    {
        "label": "UNTC - UNIT CORP",
        "value": "UNTC"
    },
    {
        "label": "SWIM - Latham Group, Inc.",
        "value": "SWIM"
    },
    {
        "label": "GCI - Gannett Co., Inc.",
        "value": "GCI"
    },
    {
        "label": "SFWL - SHENGFENG DEVELOPMENT Ltd",
        "value": "SFWL"
    },
    {
        "label": "HCVI - Hennessy Capital Investment Corp. VI",
        "value": "HCVI"
    },
    {
        "label": "CSTL - CASTLE BIOSCIENCES INC",
        "value": "CSTL"
    },
    {
        "label": "NXJ - NUVEEN NEW JERSEY QUALITY MUNICIPAL INCOME FUND",
        "value": "NXJ"
    },
    {
        "label": "MVIS - MICROVISION, INC.",
        "value": "MVIS"
    },
    {
        "label": "BLW - BLACKROCK Ltd DURATION INCOME TRUST",
        "value": "BLW"
    },
    {
        "label": "ZYME - Zymeworks Inc.",
        "value": "ZYME"
    },
    {
        "label": "MMD - MainStay MacKay DefinedTerm Municipal Opportunities Fund",
        "value": "MMD"
    },
    {
        "label": "STK - Columbia Seligman Premium Technology Growth Fund, Inc.",
        "value": "STK"
    },
    {
        "label": "TBI - TrueBlue, Inc.",
        "value": "TBI"
    },
    {
        "label": "PXPC - Phoenix Plus Corp.",
        "value": "PXPC"
    },
    {
        "label": "OCS - Oculis Holding AG",
        "value": "OCS"
    },
    {
        "label": "BFAC - Battery Future Acquisition Corp.",
        "value": "BFAC"
    },
    {
        "label": "CPF - CENTRAL PACIFIC FINANCIAL CORP",
        "value": "CPF"
    },
    {
        "label": "ALTG - ALTA EQUIPMENT GROUP INC.",
        "value": "ALTG"
    },
    {
        "label": "LND - BrasilAgro - Brazilian Agricultural Real Estate Co",
        "value": "LND"
    },
    {
        "label": "DSU - BLACKROCK DEBT STRATEGIES FUND, INC.",
        "value": "DSU"
    },
    {
        "label": "SVRA - Savara Inc",
        "value": "SVRA"
    },
    {
        "label": "AGEN - AGENUS INC",
        "value": "AGEN"
    },
    {
        "label": "EYPT - EyePoint Pharmaceuticals, Inc.",
        "value": "EYPT"
    },
    {
        "label": "LEV - Lion Electric Co",
        "value": "LEV"
    },
    {
        "label": "VNET - VNET Group, Inc.",
        "value": "VNET"
    },
    {
        "label": "EBIX - EBIX INC",
        "value": "EBIX"
    },
    {
        "label": "HONE - HarborOne Bancorp, Inc.",
        "value": "HONE"
    },
    {
        "label": "MBI - MBIA INC",
        "value": "MBI"
    },
    {
        "label": "CDNA - CareDx, Inc.",
        "value": "CDNA"
    },
    {
        "label": "SPFI - SOUTH PLAINS FINANCIAL, INC.",
        "value": "SPFI"
    },
    {
        "label": "MCFT - MasterCraft Boat Holdings, Inc.",
        "value": "MCFT"
    },
    {
        "label": "KRT - Karat Packaging Inc.",
        "value": "KRT"
    },
    {
        "label": "CNSL - Consolidated Communications Holdings, Inc.",
        "value": "CNSL"
    },
    {
        "label": "NN - NEXTNAV INC.",
        "value": "NN"
    },
    {
        "label": "CPAC - CEMENTOS PACASMAYO SAA",
        "value": "CPAC"
    },
    {
        "label": "YUMM - YUMMIES INC",
        "value": "YUMM"
    },
    {
        "label": "BANT - Bantec, Inc.",
        "value": "BANT"
    },
    {
        "label": "CLDT - Chatham Lodging Trust",
        "value": "CLDT"
    },
    {
        "label": "CSV - CARRIAGE SERVICES INC",
        "value": "CSV"
    },
    {
        "label": "DBO - Invesco DB Oil Fund",
        "value": "DBO"
    },
    {
        "label": "GUG - Guggenheim Active Allocation Fund",
        "value": "GUG"
    },
    {
        "label": "GRVY - GRAVITY Co., Ltd.",
        "value": "GRVY"
    },
    {
        "label": "AVD - AMERICAN VANGUARD CORP",
        "value": "AVD"
    },
    {
        "label": "DAO - Youdao, Inc.",
        "value": "DAO"
    },
    {
        "label": "SNDL - SNDL Inc.",
        "value": "SNDL"
    },
    {
        "label": "CYD - CHINA YUCHAI INTERNATIONAL LTD",
        "value": "CYD"
    },
    {
        "label": "CECO - CECO ENVIRONMENTAL CORP",
        "value": "CECO"
    },
    {
        "label": "JFR - NUVEEN FLOATING RATE INCOME FUND",
        "value": "JFR"
    },
    {
        "label": "AATP - Agape ATP Corp",
        "value": "AATP"
    },
    {
        "label": "GHY - PGIM Global High Yield Fund, Inc.",
        "value": "GHY"
    },
    {
        "label": "NR - NEWPARK RESOURCES INC",
        "value": "NR"
    },
    {
        "label": "VCV - Invesco California Value Municipal Income Trust",
        "value": "VCV"
    },
    {
        "label": "EWTX - Edgewise Therapeutics, Inc.",
        "value": "EWTX"
    },
    {
        "label": "OSPN - OneSpan Inc.",
        "value": "OSPN"
    },
    {
        "label": "NFYS - Enphys Acquisition Corp.",
        "value": "NFYS"
    },
    {
        "label": "TRTL - TortoiseEcofin Acquisition Corp. III",
        "value": "TRTL"
    },
    {
        "label": "BOOM - DMC Global Inc.",
        "value": "BOOM"
    },
    {
        "label": "CYH - COMMUNITY HEALTH SYSTEMS INC",
        "value": "CYH"
    },
    {
        "label": "HLVX - HilleVax, Inc.",
        "value": "HLVX"
    },
    {
        "label": "LQDA - Liquidia Corp",
        "value": "LQDA"
    },
    {
        "label": "CAN - Canaan Inc.",
        "value": "CAN"
    },
    {
        "label": "SRG - Seritage Growth Properties",
        "value": "SRG"
    },
    {
        "label": "THFF - FIRST FINANCIAL CORP /IN/",
        "value": "THFF"
    },
    {
        "label": "SVM - SILVERCORP METALS INC",
        "value": "SVM"
    },
    {
        "label": "PROC - Procaps Group, S.A.",
        "value": "PROC"
    },
    {
        "label": "EGLE - Eagle Bulk Shipping Inc.",
        "value": "EGLE"
    },
    {
        "label": "HDSN - HUDSON TECHNOLOGIES INC /NY",
        "value": "HDSN"
    },
    {
        "label": "BWMN - Bowman Consulting Group Ltd.",
        "value": "BWMN"
    },
    {
        "label": "PGEN - PRECIGEN, INC.",
        "value": "PGEN"
    },
    {
        "label": "TRC - TEJON RANCH CO",
        "value": "TRC"
    },
    {
        "label": "TPB - Turning Point Brands, Inc.",
        "value": "TPB"
    },
    {
        "label": "CRD-A - CRAWFORD & CO",
        "value": "CRD-A"
    },
    {
        "label": "INRE - Inland Real Estate Income Trust, Inc.",
        "value": "INRE"
    },
    {
        "label": "NBB - Nuveen Taxable Municipal Income Fund",
        "value": "NBB"
    },
    {
        "label": "PACK - Ranpak Holdings Corp.",
        "value": "PACK"
    },
    {
        "label": "APLM - Apollomics Inc.",
        "value": "APLM"
    },
    {
        "label": "CATC - CAMBRIDGE BANCORP",
        "value": "CATC"
    },
    {
        "label": "TARS - Tarsus Pharmaceuticals, Inc.",
        "value": "TARS"
    },
    {
        "label": "SUAC - ShoulderUP Technology Acquisition Corp.",
        "value": "SUAC"
    },
    {
        "label": "ACRO - Acropolis Infrastructure Acquisition Corp.",
        "value": "ACRO"
    },
    {
        "label": "RTC - Baijiayun Group Ltd",
        "value": "RTC"
    },
    {
        "label": "NETI - ENETI INC.",
        "value": "NETI"
    },
    {
        "label": "IVR - Invesco Mortgage Capital Inc.",
        "value": "IVR"
    },
    {
        "label": "MCRB - Seres Therapeutics, Inc.",
        "value": "MCRB"
    },
    {
        "label": "RPTX - Repare Therapeutics Inc.",
        "value": "RPTX"
    },
    {
        "label": "MLR - MILLER INDUSTRIES INC /TN/",
        "value": "MLR"
    },
    {
        "label": "BFK - BLACKROCK MUNICIPAL INCOME TRUST",
        "value": "BFK"
    },
    {
        "label": "WULF - TERAWULF INC.",
        "value": "WULF"
    },
    {
        "label": "VEL - Velocity Financial, Inc.",
        "value": "VEL"
    },
    {
        "label": "ASGI - abrdn Global Infrastructure Income Fund",
        "value": "ASGI"
    },
    {
        "label": "FRA - BLACKROCK FLOATING RATE INCOME STRATEGIES FUND, INC.",
        "value": "FRA"
    },
    {
        "label": "FPH - Five Point Holdings, LLC",
        "value": "FPH"
    },
    {
        "label": "NEWT - NewtekOne, Inc.",
        "value": "NEWT"
    },
    {
        "label": "BBCP - Concrete Pumping Holdings, Inc.",
        "value": "BBCP"
    },
    {
        "label": "HPS - JOHN HANCOCK PREFERRED INCOME FUND III",
        "value": "HPS"
    },
    {
        "label": "SFIX - Stitch Fix, Inc.",
        "value": "SFIX"
    },
    {
        "label": "CALT - Calliditas Therapeutics AB",
        "value": "CALT"
    },
    {
        "label": "PNNT - PENNANTPARK INVESTMENT CORP",
        "value": "PNNT"
    },
    {
        "label": "CDLX - Cardlytics, Inc.",
        "value": "CDLX"
    },
    {
        "label": "AMRN - AMARIN CORP PLC\\UK",
        "value": "AMRN"
    },
    {
        "label": "SGU - STAR GROUP, L.P.",
        "value": "SGU"
    },
    {
        "label": "URGN - UroGen Pharma Ltd.",
        "value": "URGN"
    },
    {
        "label": "ALDX - Aldeyra Therapeutics, Inc.",
        "value": "ALDX"
    },
    {
        "label": "TPC - TUTOR PERINI CORP",
        "value": "TPC"
    },
    {
        "label": "GAIN - GLADSTONE INVESTMENT CORPORATIONDE",
        "value": "GAIN"
    },
    {
        "label": "FFIC - FLUSHING FINANCIAL CORP",
        "value": "FFIC"
    },
    {
        "label": "KVSA - Khosla Ventures Acquisition Co.",
        "value": "KVSA"
    },
    {
        "label": "AMNB - AMERICAN NATIONAL BANKSHARES INC.",
        "value": "AMNB"
    },
    {
        "label": "SLAM - Slam Corp.",
        "value": "SLAM"
    },
    {
        "label": "AIRS - Airsculpt Technologies, Inc.",
        "value": "AIRS"
    },
    {
        "label": "MVF - BLACKROCK MUNIVEST FUND, INC.",
        "value": "MVF"
    },
    {
        "label": "ONEW - OneWater Marine Inc.",
        "value": "ONEW"
    },
    {
        "label": "CHRS - Coherus BioSciences, Inc.",
        "value": "CHRS"
    },
    {
        "label": "OLP - ONE LIBERTY PROPERTIES INC",
        "value": "OLP"
    },
    {
        "label": "LYTS - LSI INDUSTRIES INC",
        "value": "LYTS"
    },
    {
        "label": "BW - Babcock & Wilcox Enterprises, Inc.",
        "value": "BW"
    },
    {
        "label": "TCMD - TACTILE SYSTEMS TECHNOLOGY INC",
        "value": "TCMD"
    },
    {
        "label": "WVE - Wave Life Sciences Ltd.",
        "value": "WVE"
    },
    {
        "label": "LSEA - Landsea Homes Corp",
        "value": "LSEA"
    },
    {
        "label": "QSG - QuantaSing Group Ltd",
        "value": "QSG"
    },
    {
        "label": "IHRT - iHeartMedia, Inc.",
        "value": "IHRT"
    },
    {
        "label": "NRGV - Energy Vault Holdings, Inc.",
        "value": "NRGV"
    },
    {
        "label": "MMU - WESTERN ASSET MANAGED MUNICIPALS FUND INC.",
        "value": "MMU"
    },
    {
        "label": "VTNR - Vertex Energy Inc.",
        "value": "VTNR"
    },
    {
        "label": "RMT - ROYCE MICRO-CAP TRUST, INC.",
        "value": "RMT"
    },
    {
        "label": "ICPT - INTERCEPT PHARMACEUTICALS, INC.",
        "value": "ICPT"
    },
    {
        "label": "UVE - UNIVERSAL INSURANCE HOLDINGS, INC.",
        "value": "UVE"
    },
    {
        "label": "FFWM - First Foundation Inc.",
        "value": "FFWM"
    },
    {
        "label": "RCEL - AVITA Medical, Inc.",
        "value": "RCEL"
    },
    {
        "label": "ORC - Orchid Island Capital, Inc.",
        "value": "ORC"
    },
    {
        "label": "GIM - TEMPLETON GLOBAL INCOME FUND",
        "value": "GIM"
    },
    {
        "label": "NEGG - Newegg Commerce, Inc.",
        "value": "NEGG"
    },
    {
        "label": "ITOS - iTeos Therapeutics, Inc.",
        "value": "ITOS"
    },
    {
        "label": "AVTE - Aerovate Therapeutics, Inc.",
        "value": "AVTE"
    },
    {
        "label": "TROO - Troops, Inc. /Cayman Islands/",
        "value": "TROO"
    },
    {
        "label": "ASPN - ASPEN AEROGELS INC",
        "value": "ASPN"
    },
    {
        "label": "NRIX - Nurix Therapeutics, Inc.",
        "value": "NRIX"
    },
    {
        "label": "GNE - Genie Energy Ltd.",
        "value": "GNE"
    },
    {
        "label": "NQP - NUVEEN PENNSYLVANIA QUALITY MUNICIPAL INCOME FUND",
        "value": "NQP"
    },
    {
        "label": "TSE - Trinseo PLC",
        "value": "TSE"
    },
    {
        "label": "OPY - OPPENHEIMER HOLDINGS INC",
        "value": "OPY"
    },
    {
        "label": "FRGE - Forge Global Holdings, Inc.",
        "value": "FRGE"
    },
    {
        "label": "MTLS - MATERIALISE NV",
        "value": "MTLS"
    },
    {
        "label": "ICG - Intchains Group Ltd",
        "value": "ICG"
    },
    {
        "label": "EVC - ENTRAVISION COMMUNICATIONS CORP",
        "value": "EVC"
    },
    {
        "label": "PLM - POLYMET MINING CORP",
        "value": "PLM"
    },
    {
        "label": "TAST - CARROLS RESTAURANT GROUP, INC.",
        "value": "TAST"
    },
    {
        "label": "HTBI - HomeTrust Bancshares, Inc.",
        "value": "HTBI"
    },
    {
        "label": "RRAC - Rigel Resource Acquisition Corp.",
        "value": "RRAC"
    },
    {
        "label": "ABML - AMERICAN BATTERY TECHNOLOGY Co",
        "value": "ABML"
    },
    {
        "label": "IBCP - INDEPENDENT BANK CORP /MI/",
        "value": "IBCP"
    },
    {
        "label": "THRN - Thorne Healthtech, Inc.",
        "value": "THRN"
    },
    {
        "label": "SKE - Skeena Resources Ltd",
        "value": "SKE"
    },
    {
        "label": "DJCO - DAILY JOURNAL CORP",
        "value": "DJCO"
    },
    {
        "label": "IGD - Voya GLOBAL EQUITY DIVIDEND & PREMIUM OPPORTUNITY FUND",
        "value": "IGD"
    },
    {
        "label": "VYGR - Voyager Therapeutics, Inc.",
        "value": "VYGR"
    },
    {
        "label": "ISD - PGIM High Yield Bond Fund, Inc.",
        "value": "ISD"
    },
    {
        "label": "EMO - ClearBridge Energy Midstream Opportunity Fund Inc.",
        "value": "EMO"
    },
    {
        "label": "CGEM - Cullinan Oncology, Inc.",
        "value": "CGEM"
    },
    {
        "label": "LIFW - MSP Recovery, Inc.",
        "value": "LIFW"
    },
    {
        "label": "BCHPY - Brainchip Holdings Limited/ADR",
        "value": "BCHPY"
    },
    {
        "label": "EGHT - 8X8 INC /DE/",
        "value": "EGHT"
    },
    {
        "label": "AAOI - APPLIED OPTOELECTRONICS, INC.",
        "value": "AAOI"
    },
    {
        "label": "QURE - uniQure N.V.",
        "value": "QURE"
    },
    {
        "label": "HPI - JOHN HANCOCK PREFERRED INCOME FUND",
        "value": "HPI"
    },
    {
        "label": "DSX - DIANA SHIPPING INC.",
        "value": "DSX"
    },
    {
        "label": "SMBK - SMARTFINANCIAL INC.",
        "value": "SMBK"
    },
    {
        "label": "LXRX - LEXICON PHARMACEUTICALS, INC.",
        "value": "LXRX"
    },
    {
        "label": "UHG - United Homes Group, Inc.",
        "value": "UHG"
    },
    {
        "label": "CCNE - CNB FINANCIAL CORP/PA",
        "value": "CCNE"
    },
    {
        "label": "CWCO - Consolidated Water Co. Ltd.",
        "value": "CWCO"
    },
    {
        "label": "HYAC - Haymaker Acquisition Corp. 4",
        "value": "HYAC"
    },
    {
        "label": "GLRE - GREENLIGHT CAPITAL RE, LTD.",
        "value": "GLRE"
    },
    {
        "label": "ESQ - Esquire Financial Holdings, Inc.",
        "value": "ESQ"
    },
    {
        "label": "NXDT - NEXPOINT DIVERSIFIED REAL ESTATE TRUST",
        "value": "NXDT"
    },
    {
        "label": "NML - Neuberger Berman Energy Infrastructure & Income Fund Inc.",
        "value": "NML"
    },
    {
        "label": "AVK - ADVENT CONVERTIBLE & INCOME FUND",
        "value": "AVK"
    },
    {
        "label": "ETB - Eaton Vance Tax-Managed Buy-Write Income Fund",
        "value": "ETB"
    },
    {
        "label": "QUOT - Quotient Technology Inc.",
        "value": "QUOT"
    },
    {
        "label": "EQBK - EQUITY BANCSHARES INC",
        "value": "EQBK"
    },
    {
        "label": "SHCR - Sharecare, Inc.",
        "value": "SHCR"
    },
    {
        "label": "BHRB - Burke & Herbert Financial Services Corp.",
        "value": "BHRB"
    },
    {
        "label": "CXAC - C5 Acquisition Corp",
        "value": "CXAC"
    },
    {
        "label": "NMAI - Nuveen Multi-Asset Income Fund",
        "value": "NMAI"
    },
    {
        "label": "MBSC - M3-Brigade Acquisition III Corp.",
        "value": "MBSC"
    },
    {
        "label": "CMPS - COMPASS Pathways plc",
        "value": "CMPS"
    },
    {
        "label": "CBUS - Cibus, Inc.",
        "value": "CBUS"
    },
    {
        "label": "GILT - GILAT SATELLITE NETWORKS LTD",
        "value": "GILT"
    },
    {
        "label": "TDUP - ThredUp Inc.",
        "value": "TDUP"
    },
    {
        "label": "JPI - Nuveen Preferred & Income Term Fund",
        "value": "JPI"
    },
    {
        "label": "ORIC - Oric Pharmaceuticals, Inc.",
        "value": "ORIC"
    },
    {
        "label": "DDI - DoubleDown Interactive Co., Ltd.",
        "value": "DDI"
    },
    {
        "label": "BHB - BAR HARBOR BANKSHARES",
        "value": "BHB"
    },
    {
        "label": "AAN - Aaron's Company, Inc.",
        "value": "AAN"
    },
    {
        "label": "KODK - EASTMAN KODAK CO",
        "value": "KODK"
    },
    {
        "label": "FSBC - FIVE STAR BANCORP",
        "value": "FSBC"
    },
    {
        "label": "TPVG - TriplePoint Venture Growth BDC Corp.",
        "value": "TPVG"
    },
    {
        "label": "QRTEA - Qurate Retail, Inc.",
        "value": "QRTEA"
    },
    {
        "label": "GLAD - GLADSTONE CAPITAL CORP",
        "value": "GLAD"
    },
    {
        "label": "EVN - EATON VANCE MUNICIPAL INCOME TRUST",
        "value": "EVN"
    },
    {
        "label": "LUNG - Pulmonx Corp",
        "value": "LUNG"
    },
    {
        "label": "HRZN - Horizon Technology Finance Corp",
        "value": "HRZN"
    },
    {
        "label": "NOTE - FiscalNote Holdings, Inc.",
        "value": "NOTE"
    },
    {
        "label": "NPFD - Nuveen Variable Rate Preferred & Income Fund",
        "value": "NPFD"
    },
    {
        "label": "AURA - Aura Biosciences, Inc.",
        "value": "AURA"
    },
    {
        "label": "SLDP - Solid Power, Inc.",
        "value": "SLDP"
    },
    {
        "label": "PLSE - Pulse Biosciences, Inc.",
        "value": "PLSE"
    },
    {
        "label": "THCH - TH International Ltd",
        "value": "THCH"
    },
    {
        "label": "APTM - Alpha Partners Technology Merger Corp.",
        "value": "APTM"
    },
    {
        "label": "SLGC - SomaLogic, Inc.",
        "value": "SLGC"
    },
    {
        "label": "AMSWA - AMERICAN SOFTWARE INC",
        "value": "AMSWA"
    },
    {
        "label": "BLUE - bluebird bio, Inc.",
        "value": "BLUE"
    },
    {
        "label": "HUMA - Humacyte, Inc.",
        "value": "HUMA"
    },
    {
        "label": "GCO - GENESCO INC",
        "value": "GCO"
    },
    {
        "label": "MYN - BLACKROCK MUNIYIELD NEW YORK QUALITY FUND, INC.",
        "value": "MYN"
    },
    {
        "label": "AMWL - American Well Corp",
        "value": "AMWL"
    },
    {
        "label": "RMR - RMR GROUP INC.",
        "value": "RMR"
    },
    {
        "label": "REI - RING ENERGY, INC.",
        "value": "REI"
    },
    {
        "label": "CTO - CTO Realty Growth, Inc.",
        "value": "CTO"
    },
    {
        "label": "XPDB - Power & Digital Infrastructure Acquisition II Corp.",
        "value": "XPDB"
    },
    {
        "label": "ALRS - ALERUS FINANCIAL CORP",
        "value": "ALRS"
    },
    {
        "label": "TRVG - trivago N.V.",
        "value": "TRVG"
    },
    {
        "label": "EAD - ALLSPRING INCOME OPPORTUNITIES FUND",
        "value": "EAD"
    },
    {
        "label": "ACTG - ACACIA RESEARCH CORP",
        "value": "ACTG"
    },
    {
        "label": "TGB - TASEKO MINES LTD",
        "value": "TGB"
    },
    {
        "label": "SENEA - Seneca Foods Corp",
        "value": "SENEA"
    },
    {
        "label": "SIFY - SIFY TECHNOLOGIES LTD",
        "value": "SIFY"
    },
    {
        "label": "FHTX - Foghorn Therapeutics Inc.",
        "value": "FHTX"
    },
    {
        "label": "MXCT - MAXCYTE, INC.",
        "value": "MXCT"
    },
    {
        "label": "ERAS - Erasca, Inc.",
        "value": "ERAS"
    },
    {
        "label": "EXFY - Expensify, Inc.",
        "value": "EXFY"
    },
    {
        "label": "DTC - Solo Brands, Inc.",
        "value": "DTC"
    },
    {
        "label": "NREF - NexPoint Real Estate Finance, Inc.",
        "value": "NREF"
    },
    {
        "label": "BBW - BUILD-A-BEAR WORKSHOP INC",
        "value": "BBW"
    },
    {
        "label": "CHW - Calamos Global Dynamic Income Fund",
        "value": "CHW"
    },
    {
        "label": "SOHU - Sohu.com Ltd",
        "value": "SOHU"
    },
    {
        "label": "NETC - Nabors Energy Transition Corp.",
        "value": "NETC"
    },
    {
        "label": "ETO - Eaton Vance Tax-Advantaged Global Dividend Opportunities Fund",
        "value": "ETO"
    },
    {
        "label": "NVEC - NVE CORP /NEW/",
        "value": "NVEC"
    },
    {
        "label": "NTGR - NETGEAR, INC.",
        "value": "NTGR"
    },
    {
        "label": "SDHY - PGIM Short Duration High Yield Opportunities Fund",
        "value": "SDHY"
    },
    {
        "label": "KALV - KalVista Pharmaceuticals, Inc.",
        "value": "KALV"
    },
    {
        "label": "NUVB - Nuvation Bio Inc.",
        "value": "NUVB"
    },
    {
        "label": "GRPN - Groupon, Inc.",
        "value": "GRPN"
    },
    {
        "label": "FSD - FIRST TRUST HIGH INCOME LONG/SHORT FUND",
        "value": "FSD"
    },
    {
        "label": "GRAM - Gold Flora Corp.",
        "value": "GRAM"
    },
    {
        "label": "OOMA - OOMA INC",
        "value": "OOMA"
    },
    {
        "label": "NAUT - Nautilus Biotechnology, Inc.",
        "value": "NAUT"
    },
    {
        "label": "DSP - Viant Technology Inc.",
        "value": "DSP"
    },
    {
        "label": "SB - SAFE BULKERS, INC.",
        "value": "SB"
    },
    {
        "label": "OTLK - Outlook Therapeutics, Inc.",
        "value": "OTLK"
    },
    {
        "label": "PACI - PROOF Acquisition Corp I",
        "value": "PACI"
    },
    {
        "label": "NFNT - Infinite Acquisition Corp.",
        "value": "NFNT"
    },
    {
        "label": "OLMA - Olema Pharmaceuticals, Inc.",
        "value": "OLMA"
    },
    {
        "label": "ABOS - Acumen Pharmaceuticals, Inc.",
        "value": "ABOS"
    },
    {
        "label": "SHBI - SHORE BANCSHARES INC",
        "value": "SHBI"
    },
    {
        "label": "ADSE - Ads-Tec Energy Public Ltd Co",
        "value": "ADSE"
    },
    {
        "label": "APGB - Apollo Strategic Growth Capital II",
        "value": "APGB"
    },
    {
        "label": "EBTC - ENTERPRISE BANCORP INC /MA/",
        "value": "EBTC"
    },
    {
        "label": "STRW - Strawberry Fields REIT, Inc.",
        "value": "STRW"
    },
    {
        "label": "DPG - Duff & Phelps Utility & Infrastructure Fund Inc.",
        "value": "DPG"
    },
    {
        "label": "ZUMZ - Zumiez Inc",
        "value": "ZUMZ"
    },
    {
        "label": "EVE - EVe Mobility Acquisition Corp",
        "value": "EVE"
    },
    {
        "label": "BFLY - Butterfly Network, Inc.",
        "value": "BFLY"
    },
    {
        "label": "FEI - FIRST TRUST MLP & ENERGY INCOME FUND",
        "value": "FEI"
    },
    {
        "label": "SGHT - Sight Sciences, Inc.",
        "value": "SGHT"
    },
    {
        "label": "INOD - INNODATA INC",
        "value": "INOD"
    },
    {
        "label": "LOCO - El Pollo Loco Holdings, Inc.",
        "value": "LOCO"
    },
    {
        "label": "VKI - Invesco Advantage Municipal Income Trust II",
        "value": "VKI"
    },
    {
        "label": "KCGI - Kensington Capital Acquisition Corp. V",
        "value": "KCGI"
    },
    {
        "label": "PSTL - Postal Realty Trust, Inc.",
        "value": "PSTL"
    },
    {
        "label": "FFIE - FARADAY FUTURE INTELLIGENT ELECTRIC INC.",
        "value": "FFIE"
    },
    {
        "label": "DFP - Flaherty & Crumrine Dynamic Preferred & Income Fund Inc",
        "value": "DFP"
    },
    {
        "label": "CNDA - Concord Acquisition Corp II",
        "value": "CNDA"
    },
    {
        "label": "VHI - VALHI INC /DE/",
        "value": "VHI"
    },
    {
        "label": "BLEU - bleuacacia ltd",
        "value": "BLEU"
    },
    {
        "label": "LEO - BNY MELLON STRATEGIC MUNICIPALS, INC.",
        "value": "LEO"
    },
    {
        "label": "NEWP - NEW PACIFIC METALS CORP",
        "value": "NEWP"
    },
    {
        "label": "EMLD - FTAC Emerald Acquisition Corp.",
        "value": "EMLD"
    },
    {
        "label": "DAKT - DAKTRONICS INC /SD/",
        "value": "DAKT"
    },
    {
        "label": "ACP - abrdn Income Credit Strategies Fund",
        "value": "ACP"
    },
    {
        "label": "PNTG - Pennant Group, Inc.",
        "value": "PNTG"
    },
    {
        "label": "NOTR - Nowtransit Inc",
        "value": "NOTR"
    },
    {
        "label": "HIO - WESTERN ASSET HIGH INCOME OPPORTUNITY FUND INC.",
        "value": "HIO"
    },
    {
        "label": "MPB - MID PENN BANCORP INC",
        "value": "MPB"
    },
    {
        "label": "GNTY - GUARANTY BANCSHARES INC /TX/",
        "value": "GNTY"
    },
    {
        "label": "IONR - ioneer Ltd",
        "value": "IONR"
    },
    {
        "label": "CVRX - CVRx, Inc.",
        "value": "CVRX"
    },
    {
        "label": "QIWI - QIWI",
        "value": "QIWI"
    },
    {
        "label": "LX - LexinFintech Holdings Ltd.",
        "value": "LX"
    },
    {
        "label": "BGR - BlackRock Energy & Resources Trust",
        "value": "BGR"
    },
    {
        "label": "RRBI - RED RIVER BANCSHARES INC",
        "value": "RRBI"
    },
    {
        "label": "BRT - BRT Apartments Corp.",
        "value": "BRT"
    },
    {
        "label": "PLCE - Childrens Place, Inc.",
        "value": "PLCE"
    },
    {
        "label": "GHI - Greystone Housing Impact Investors LP",
        "value": "GHI"
    },
    {
        "label": "EFT - Eaton Vance Floating-Rate Income Trust",
        "value": "EFT"
    },
    {
        "label": "EFR - EATON VANCE SENIOR FLOATING RATE TRUST",
        "value": "EFR"
    },
    {
        "label": "MX - MAGNACHIP SEMICONDUCTOR Corp",
        "value": "MX"
    },
    {
        "label": "MOFG - MidWestOne Financial Group, Inc.",
        "value": "MOFG"
    },
    {
        "label": "ONL - Orion Office REIT Inc.",
        "value": "ONL"
    },
    {
        "label": "FFA - FIRST TRUST ENHANCED EQUITY INCOME FUND",
        "value": "FFA"
    },
    {
        "label": "VLGEA - VILLAGE SUPER MARKET INC",
        "value": "VLGEA"
    },
    {
        "label": "BTMD - biote Corp.",
        "value": "BTMD"
    },
    {
        "label": "LOVE - Lovesac Co",
        "value": "LOVE"
    },
    {
        "label": "SRRK - Scholar Rock Holding Corp",
        "value": "SRRK"
    },
    {
        "label": "PPT - PUTNAM PREMIER INCOME TRUST",
        "value": "PPT"
    },
    {
        "label": "SMMF - SUMMIT FINANCIAL GROUP, INC.",
        "value": "SMMF"
    },
    {
        "label": "FANH - FANHUA INC.",
        "value": "FANH"
    },
    {
        "label": "MGTX - MeiraGTx Holdings plc",
        "value": "MGTX"
    },
    {
        "label": "EPWCF - Empower Clinics Inc.",
        "value": "EPWCF"
    },
    {
        "label": "CGNT - Cognyte Software Ltd.",
        "value": "CGNT"
    },
    {
        "label": "TSP - TuSimple Holdings Inc.",
        "value": "TSP"
    },
    {
        "label": "ABUS - Arbutus Biopharma Corp",
        "value": "ABUS"
    },
    {
        "label": "HNRG - HALLADOR ENERGY CO",
        "value": "HNRG"
    },
    {
        "label": "FIP - FTAI Infrastructure Inc.",
        "value": "FIP"
    },
    {
        "label": "HQL - TEKLA LIFE SCIENCES INVESTORS",
        "value": "HQL"
    },
    {
        "label": "NESR - National Energy Services Reunited Corp.",
        "value": "NESR"
    },
    {
        "label": "IVCA - Investcorp India Acquisition Corp",
        "value": "IVCA"
    },
    {
        "label": "IBEX - IBEX Ltd",
        "value": "IBEX"
    },
    {
        "label": "TYG - TORTOISE ENERGY INFRASTRUCTURE CORP",
        "value": "TYG"
    },
    {
        "label": "AUDC - AUDIOCODES LTD",
        "value": "AUDC"
    },
    {
        "label": "RSVR - Reservoir Media, Inc.",
        "value": "RSVR"
    },
    {
        "label": "KMF - Kayne Anderson NextGen Energy & Infrastructure, Inc.",
        "value": "KMF"
    },
    {
        "label": "BFZ - BLACKROCK CALIFORNIA MUNICIPAL INCOME TRUST",
        "value": "BFZ"
    },
    {
        "label": "GBAB - Guggenheim Taxable Municipal Bond & Investment Grade Debt Trust",
        "value": "GBAB"
    },
    {
        "label": "CRLBF - Cresco Labs Inc.",
        "value": "CRLBF"
    },
    {
        "label": "OPI - OFFICE PROPERTIES INCOME TRUST",
        "value": "OPI"
    },
    {
        "label": "PEGR - Project Energy Reimagined Acquisition Corp.",
        "value": "PEGR"
    },
    {
        "label": "BAND - Bandwidth Inc.",
        "value": "BAND"
    },
    {
        "label": "PUYI - PUYI, INC.",
        "value": "PUYI"
    },
    {
        "label": "ORGO - Organogenesis Holdings Inc.",
        "value": "ORGO"
    },
    {
        "label": "SMTI - Sanara MedTech Inc.",
        "value": "SMTI"
    },
    {
        "label": "FWAC - Fifth Wall Acquisition Corp. III",
        "value": "FWAC"
    },
    {
        "label": "GFGD - Growth for Good Acquisition Corp",
        "value": "GFGD"
    },
    {
        "label": "UTMD - UTAH MEDICAL PRODUCTS INC",
        "value": "UTMD"
    },
    {
        "label": "INSE - Inspired Entertainment, Inc.",
        "value": "INSE"
    },
    {
        "label": "NCMI - National CineMedia, Inc.",
        "value": "NCMI"
    },
    {
        "label": "OCUL - OCULAR THERAPEUTIX, INC",
        "value": "OCUL"
    },
    {
        "label": "RFMZ - RiverNorth Flexible Municipal Income Fund II, Inc.",
        "value": "RFMZ"
    },
    {
        "label": "IPI - Intrepid Potash, Inc.",
        "value": "IPI"
    },
    {
        "label": "PFTA - PORTAGE FINTECH ACQUISITION CORP.",
        "value": "PFTA"
    },
    {
        "label": "CARE - Carter Bankshares, Inc.",
        "value": "CARE"
    },
    {
        "label": "PMO - PUTNAM MUNICIPAL OPPORTUNITIES TRUST",
        "value": "PMO"
    },
    {
        "label": "GEVO - Gevo, Inc.",
        "value": "GEVO"
    },
    {
        "label": "ARL - AMERICAN REALTY INVESTORS INC",
        "value": "ARL"
    },
    {
        "label": "BITF - Bitfarms Ltd",
        "value": "BITF"
    },
    {
        "label": "AWP - abrdn Global Premier Properties Fund",
        "value": "AWP"
    },
    {
        "label": "ANGO - ANGIODYNAMICS INC",
        "value": "ANGO"
    },
    {
        "label": "EOSE - Eos Energy Enterprises, Inc.",
        "value": "EOSE"
    },
    {
        "label": "VNDA - Vanda Pharmaceuticals Inc.",
        "value": "VNDA"
    },
    {
        "label": "CLIN - Clean Earth Acquisitions Corp.",
        "value": "CLIN"
    },
    {
        "label": "AVNW - AVIAT NETWORKS, INC.",
        "value": "AVNW"
    },
    {
        "label": "TMC - TMC the metals Co Inc.",
        "value": "TMC"
    },
    {
        "label": "SCU - Sculptor Capital Management, Inc.",
        "value": "SCU"
    },
    {
        "label": "KTF - DWS MUNICIPAL INCOME TRUST",
        "value": "KTF"
    },
    {
        "label": "JFIN - Jiayin Group Inc.",
        "value": "JFIN"
    },
    {
        "label": "SIGA - SIGA TECHNOLOGIES INC",
        "value": "SIGA"
    },
    {
        "label": "MRNS - MARINUS PHARMACEUTICALS, INC.",
        "value": "MRNS"
    },
    {
        "label": "DOYU - DouYu International Holdings Ltd",
        "value": "DOYU"
    },
    {
        "label": "BRW - Saba Capital Income & Opportunities Fund",
        "value": "BRW"
    },
    {
        "label": "BRLT - Brilliant Earth Group, Inc.",
        "value": "BRLT"
    },
    {
        "label": "LMB - Limbach Holdings, Inc.",
        "value": "LMB"
    },
    {
        "label": "ENTA - ENANTA PHARMACEUTICALS INC",
        "value": "ENTA"
    },
    {
        "label": "HIVE - HIVE Digital Technologies Ltd.",
        "value": "HIVE"
    },
    {
        "label": "CJET - Chijet Motor Company, Inc.",
        "value": "CJET"
    },
    {
        "label": "JGGC - Jaguar Global Growth Corp I",
        "value": "JGGC"
    },
    {
        "label": "SLVR - SILVERspac Inc.",
        "value": "SLVR"
    },
    {
        "label": "SVII - Spring Valley Acquisition Corp. II",
        "value": "SVII"
    },
    {
        "label": "BMRC - Bank of Marin Bancorp",
        "value": "BMRC"
    },
    {
        "label": "PRTH - Priority Technology Holdings, Inc.",
        "value": "PRTH"
    },
    {
        "label": "PFIS - PEOPLES FINANCIAL SERVICES CORP.",
        "value": "PFIS"
    },
    {
        "label": "EVI - EVI INDUSTRIES, INC.",
        "value": "EVI"
    },
    {
        "label": "LPSN - LIVEPERSON INC",
        "value": "LPSN"
    },
    {
        "label": "JRI - Nuveen Real Asset Income & Growth Fund",
        "value": "JRI"
    },
    {
        "label": "FF - FutureFuel Corp.",
        "value": "FF"
    },
    {
        "label": "HWEL - Healthwell Acquisition Corp. I",
        "value": "HWEL"
    },
    {
        "label": "MIESY - Mitsui E&S Holdings Co., Ltd./ADR",
        "value": "MIESY"
    },
    {
        "label": "NAN - NUVEEN NEW YORK QUALITY MUNICIPAL INCOME FUND",
        "value": "NAN"
    },
    {
        "label": "RLGT - RADIANT LOGISTICS, INC",
        "value": "RLGT"
    },
    {
        "label": "AIXI - Xiao-I Corp",
        "value": "AIXI"
    },
    {
        "label": "KIO - KKR Income Opportunities Fund",
        "value": "KIO"
    },
    {
        "label": "MVBF - MVB FINANCIAL CORP",
        "value": "MVBF"
    },
    {
        "label": "QUAD - Quad/Graphics, Inc.",
        "value": "QUAD"
    },
    {
        "label": "PMVP - PMV Pharmaceuticals, Inc.",
        "value": "PMVP"
    },
    {
        "label": "AVAH - Aveanna Healthcare Holdings, Inc.",
        "value": "AVAH"
    },
    {
        "label": "HPF - JOHN HANCOCK PREFERRED INCOME FUND II",
        "value": "HPF"
    },
    {
        "label": "OZ - Belpointe PREP, LLC",
        "value": "OZ"
    },
    {
        "label": "CRNCY - CAIRN ENERGY PLC",
        "value": "CRNCY"
    },
    {
        "label": "SOR - SOURCE CAPITAL INC /DE/",
        "value": "SOR"
    },
    {
        "label": "GLUE - Monte Rosa Therapeutics, Inc.",
        "value": "GLUE"
    },
    {
        "label": "IGMS - IGM Biosciences, Inc.",
        "value": "IGMS"
    },
    {
        "label": "TERN - Terns Pharmaceuticals, Inc.",
        "value": "TERN"
    },
    {
        "label": "SJT - SAN JUAN BASIN ROYALTY TRUST",
        "value": "SJT"
    },
    {
        "label": "NWPX - NORTHWEST PIPE CO",
        "value": "NWPX"
    },
    {
        "label": "HIX - WESTERN ASSET HIGH INCOME FUND II INC.",
        "value": "HIX"
    },
    {
        "label": "CURV - Torrid Holdings Inc.",
        "value": "CURV"
    },
    {
        "label": "MIY - BLACKROCK MUNIYIELD MICHIGAN QUALITY FUND, INC.",
        "value": "MIY"
    },
    {
        "label": "IPXX - Inflection Point Acquisition Corp. II",
        "value": "IPXX"
    },
    {
        "label": "EPM - EVOLUTION PETROLEUM CORP",
        "value": "EPM"
    },
    {
        "label": "CIX - COMPX INTERNATIONAL INC",
        "value": "CIX"
    },
    {
        "label": "MUX - McEwen Mining Inc.",
        "value": "MUX"
    },
    {
        "label": "SAR - SARATOGA INVESTMENT CORP.",
        "value": "SAR"
    },
    {
        "label": "EU - enCore Energy Corp.",
        "value": "EU"
    },
    {
        "label": "TRMR - Tremor International Ltd.",
        "value": "TRMR"
    },
    {
        "label": "CERS - CERUS CORP",
        "value": "CERS"
    },
    {
        "label": "SMHI - SEACOR Marine Holdings Inc.",
        "value": "SMHI"
    },
    {
        "label": "ZIMV - ZimVie Inc.",
        "value": "ZIMV"
    },
    {
        "label": "PLAO - Patria Latin American Opportunity Acquisition Corp.",
        "value": "PLAO"
    },
    {
        "label": "OSG - OVERSEAS SHIPHOLDING GROUP INC",
        "value": "OSG"
    },
    {
        "label": "ONTF - ON24 INC.",
        "value": "ONTF"
    },
    {
        "label": "GAQ - Generation Asia I Acquisition Ltd",
        "value": "GAQ"
    },
    {
        "label": "LE - LANDS' END, INC.",
        "value": "LE"
    },
    {
        "label": "MCBC - MACATAWA BANK CORP",
        "value": "MCBC"
    },
    {
        "label": "RNGR - Ranger Energy Services, Inc.",
        "value": "RNGR"
    },
    {
        "label": "LXFR - LUXFER HOLDINGS PLC",
        "value": "LXFR"
    },
    {
        "label": "FARO - FARO TECHNOLOGIES INC",
        "value": "FARO"
    },
    {
        "label": "CVGI - Commercial Vehicle Group, Inc.",
        "value": "CVGI"
    },
    {
        "label": "MIN - MFS INTERMEDIATE INCOME TRUST",
        "value": "MIN"
    },
    {
        "label": "GBIO - Generation Bio Co.",
        "value": "GBIO"
    },
    {
        "label": "WTBA - WEST BANCORPORATION INC",
        "value": "WTBA"
    },
    {
        "label": "VADP - Vado Corp.",
        "value": "VADP"
    },
    {
        "label": "MYTE - MYT Netherlands Parent B.V.",
        "value": "MYTE"
    },
    {
        "label": "CLAR - Clarus Corp",
        "value": "CLAR"
    },
    {
        "label": "BTWN - Bridgetown Holdings Ltd",
        "value": "BTWN"
    },
    {
        "label": "JMIA - Jumia Technologies AG",
        "value": "JMIA"
    },
    {
        "label": "NVX - NOVONIX Ltd",
        "value": "NVX"
    },
    {
        "label": "SLND - Southland Holdings, Inc.",
        "value": "SLND"
    },
    {
        "label": "RENE - Cartesian Growth Corp II",
        "value": "RENE"
    },
    {
        "label": "SBT - Sterling Bancorp, Inc.",
        "value": "SBT"
    },
    {
        "label": "MHN - BLACKROCK MUNIHOLDINGS NEW YORK QUALITY FUND, INC.",
        "value": "MHN"
    },
    {
        "label": "VTDRF - VANTAGE DRILLING INTERNATIONAL",
        "value": "VTDRF"
    },
    {
        "label": "CITE - Cartica Acquisition Corp",
        "value": "CITE"
    },
    {
        "label": "CPLP - Capital Product Partners L.P.",
        "value": "CPLP"
    },
    {
        "label": "LCAA - L Catterton Asia Acquisition Corp",
        "value": "LCAA"
    },
    {
        "label": "WNNR - Andretti Acquisition Corp.",
        "value": "WNNR"
    },
    {
        "label": "UTAA - UTA Acquisition Corp",
        "value": "UTAA"
    },
    {
        "label": "TDF - TEMPLETON DRAGON FUND INC",
        "value": "TDF"
    },
    {
        "label": "MGNX - MACROGENICS INC",
        "value": "MGNX"
    },
    {
        "label": "GDRZF - GOLD RESERVE INC",
        "value": "GDRZF"
    },
    {
        "label": "EDD - Morgan Stanley Emerging Markets Domestic Debt Fund, Inc.",
        "value": "EDD"
    },
    {
        "label": "GPP - Green Plains Partners LP",
        "value": "GPP"
    },
    {
        "label": "CMPX - Compass Therapeutics, Inc.",
        "value": "CMPX"
    },
    {
        "label": "DPCS - DP Cap Acquisition Corp I",
        "value": "DPCS"
    },
    {
        "label": "WLDN - Willdan Group, Inc.",
        "value": "WLDN"
    },
    {
        "label": "WLFC - WILLIS LEASE FINANCE CORP",
        "value": "WLFC"
    },
    {
        "label": "TRIS - Tristar Acquisition I Corp.",
        "value": "TRIS"
    },
    {
        "label": "AKTX - Akari Therapeutics Plc",
        "value": "AKTX"
    },
    {
        "label": "BLNK - Blink Charging Co.",
        "value": "BLNK"
    },
    {
        "label": "LCW - Learn CW Investment Corp",
        "value": "LCW"
    },
    {
        "label": "CGC - Canopy Growth Corp",
        "value": "CGC"
    },
    {
        "label": "CPZ - Calamos Long/Short Equity & Dynamic Income Trust",
        "value": "CPZ"
    },
    {
        "label": "BRD - Beard Energy Transition Acquisition Corp.",
        "value": "BRD"
    },
    {
        "label": "REAX - Real Brokerage Inc",
        "value": "REAX"
    },
    {
        "label": "ICVX - Icosavax, Inc.",
        "value": "ICVX"
    },
    {
        "label": "FLIC - FIRST OF LONG ISLAND CORP",
        "value": "FLIC"
    },
    {
        "label": "NTPIF - NAM TAI PROPERTY INC.",
        "value": "NTPIF"
    },
    {
        "label": "NATH - NATHANS FAMOUS, INC.",
        "value": "NATH"
    },
    {
        "label": "PGSS - Pegasus Digital Mobility Acquisition Corp.",
        "value": "PGSS"
    },
    {
        "label": "DBVT - DBV Technologies S.A.",
        "value": "DBVT"
    },
    {
        "label": "ODV - Osisko Development Corp.",
        "value": "ODV"
    },
    {
        "label": "API - Agora, Inc.",
        "value": "API"
    },
    {
        "label": "PORT - Southport Acquisition Corp",
        "value": "PORT"
    },
    {
        "label": "AROW - ARROW FINANCIAL CORP",
        "value": "AROW"
    },
    {
        "label": "BSRR - SIERRA BANCORP",
        "value": "BSRR"
    },
    {
        "label": "ASG - LIBERTY ALL STAR GROWTH FUND INC.",
        "value": "ASG"
    },
    {
        "label": "VMD - VIEMED HEALTHCARE, INC.",
        "value": "VMD"
    },
    {
        "label": "AEAE - AltEnergy Acquisition Corp",
        "value": "AEAE"
    },
    {
        "label": "JWSM - Jaws Mustang Acquisition Corp",
        "value": "JWSM"
    },
    {
        "label": "KNSW - KnightSwan Acquisition Corp",
        "value": "KNSW"
    },
    {
        "label": "CZNC - CITIZENS & NORTHERN CORP",
        "value": "CZNC"
    },
    {
        "label": "MCI - BARINGS CORPORATE INVESTORS",
        "value": "MCI"
    },
    {
        "label": "INVZ - Innoviz Technologies Ltd.",
        "value": "INVZ"
    },
    {
        "label": "IVCB - Investcorp Europe Acquisition Corp I",
        "value": "IVCB"
    },
    {
        "label": "CBRG - Chain Bridge I",
        "value": "CBRG"
    },
    {
        "label": "RDVT - Red Violet, Inc.",
        "value": "RDVT"
    },
    {
        "label": "RMM - RiverNorth Managed Duration Municipal Income Fund, Inc.",
        "value": "RMM"
    },
    {
        "label": "SKYT - SkyWater Technology, Inc",
        "value": "SKYT"
    },
    {
        "label": "TCI - TRANSCONTINENTAL REALTY INVESTORS INC",
        "value": "TCI"
    },
    {
        "label": "LVOX - LiveVox Holdings, Inc.",
        "value": "LVOX"
    },
    {
        "label": "BKUH - Bakhu Holdings, Corp.",
        "value": "BKUH"
    },
    {
        "label": "NATR - NATURES SUNSHINE PRODUCTS INC",
        "value": "NATR"
    },
    {
        "label": "NCV - Virtus Convertible & Income Fund",
        "value": "NCV"
    },
    {
        "label": "VMCA - Valuence Merger Corp. I",
        "value": "VMCA"
    },
    {
        "label": "NGVC - Natural Grocers by Vitamin Cottage, Inc.",
        "value": "NGVC"
    },
    {
        "label": "RFI - COHEN & STEERS TOTAL RETURN REALTY FUND INC",
        "value": "RFI"
    },
    {
        "label": "UCAR - U Power Ltd",
        "value": "UCAR"
    },
    {
        "label": "FINS - Angel Oak Financial Strategies Income Term Trust",
        "value": "FINS"
    },
    {
        "label": "FOF - Cohen & Steers Closed-End Opportunity Fund, Inc.",
        "value": "FOF"
    },
    {
        "label": "PSQH - PSQ Holdings, Inc.",
        "value": "PSQH"
    },
    {
        "label": "AFB - ALLIANCEBERNSTEIN NATIONAL MUNICIPAL INCOME FUND",
        "value": "AFB"
    },
    {
        "label": "AFRI - Forafric Global PLC",
        "value": "AFRI"
    },
    {
        "label": "WHF - WhiteHorse Finance, Inc.",
        "value": "WHF"
    },
    {
        "label": "URG - UR-ENERGY INC",
        "value": "URG"
    },
    {
        "label": "BAER - Bridger Aerospace Group Holdings, Inc.",
        "value": "BAER"
    },
    {
        "label": "ZURA - Zura Bio Ltd",
        "value": "ZURA"
    },
    {
        "label": "XERS - Xeris Biopharma Holdings, Inc.",
        "value": "XERS"
    },
    {
        "label": "PFL - PIMCO INCOME STRATEGY FUND",
        "value": "PFL"
    },
    {
        "label": "ARDC - Ares Dynamic Credit Allocation Fund, Inc.",
        "value": "ARDC"
    },
    {
        "label": "SDSYA - SOUTH DAKOTA SOYBEAN PROCESSORS LLC",
        "value": "SDSYA"
    },
    {
        "label": "HYZN - Hyzon Motors Inc.",
        "value": "HYZN"
    },
    {
        "label": "MLP - MAUI LAND & PINEAPPLE CO INC",
        "value": "MLP"
    },
    {
        "label": "SCM - Stellus Capital Investment Corp",
        "value": "SCM"
    },
    {
        "label": "CREEF - Crescera Capital Acquisition Corp.",
        "value": "CREEF"
    },
    {
        "label": "EDAP - EDAP TMS SA",
        "value": "EDAP"
    },
    {
        "label": "TGAA - Target Global Acquisition I Corp.",
        "value": "TGAA"
    },
    {
        "label": "BWB - Bridgewater Bancshares Inc",
        "value": "BWB"
    },
    {
        "label": "ACNB - ACNB CORP",
        "value": "ACNB"
    },
    {
        "label": "OPAD - Offerpad Solutions Inc.",
        "value": "OPAD"
    },
    {
        "label": "BLND - Blend Labs, Inc.",
        "value": "BLND"
    },
    {
        "label": "OIA - Invesco Municipal Income Opportunities Trust",
        "value": "OIA"
    },
    {
        "label": "VSTA - Vasta Platform Ltd",
        "value": "VSTA"
    },
    {
        "label": "ABIT - Athena Bitcoin Global",
        "value": "ABIT"
    },
    {
        "label": "PCYO - PURE CYCLE CORP",
        "value": "PCYO"
    },
    {
        "label": "ALTO - Alto Ingredients, Inc.",
        "value": "ALTO"
    },
    {
        "label": "PKE - PARK AEROSPACE CORP",
        "value": "PKE"
    },
    {
        "label": "FNLC - First Bancorp, Inc /ME/",
        "value": "FNLC"
    },
    {
        "label": "ITIC - INVESTORS TITLE CO",
        "value": "ITIC"
    },
    {
        "label": "GATO - Gatos Silver, Inc.",
        "value": "GATO"
    },
    {
        "label": "PANL - Pangaea Logistics Solutions Ltd.",
        "value": "PANL"
    },
    {
        "label": "BYM - BLACKROCK MUNICIPAL INCOME QUALITY TRUST",
        "value": "BYM"
    },
    {
        "label": "TSVT - 2seventy bio, Inc.",
        "value": "TSVT"
    },
    {
        "label": "EVTL - Vertical Aerospace Ltd.",
        "value": "EVTL"
    },
    {
        "label": "UTI - UNIVERSAL TECHNICAL INSTITUTE INC",
        "value": "UTI"
    },
    {
        "label": "CONX - CONX Corp.",
        "value": "CONX"
    },
    {
        "label": "ALLK - Allakos Inc.",
        "value": "ALLK"
    },
    {
        "label": "CSTR - CapStar Financial Holdings, Inc.",
        "value": "CSTR"
    },
    {
        "label": "CCV - Churchill Capital Corp V",
        "value": "CCV"
    },
    {
        "label": "QSI - Quantum-Si Inc",
        "value": "QSI"
    },
    {
        "label": "AVIR - Atea Pharmaceuticals, Inc.",
        "value": "AVIR"
    },
    {
        "label": "PMM - PUTNAM MANAGED MUNICIPAL INCOME TRUST",
        "value": "PMM"
    },
    {
        "label": "NBTX - Nanobiotix S.A.",
        "value": "NBTX"
    },
    {
        "label": "SKGR - SK Growth Opportunities Corp",
        "value": "SKGR"
    },
    {
        "label": "CIVB - CIVISTA BANCSHARES, INC.",
        "value": "CIVB"
    },
    {
        "label": "SGMT - Sagimet Biosciences Inc.",
        "value": "SGMT"
    },
    {
        "label": "WAVC - Waverley Capital Acquisition Corp. 1",
        "value": "WAVC"
    },
    {
        "label": "FNKO - Funko, Inc.",
        "value": "FNKO"
    },
    {
        "label": "NCA - NUVEEN CALIFORNIA MUNICIPAL VALUE FUND",
        "value": "NCA"
    },
    {
        "label": "CVEO - Civeo Corp",
        "value": "CVEO"
    },
    {
        "label": "NLCP - NewLake Capital Partners, Inc.",
        "value": "NLCP"
    },
    {
        "label": "FISI - FINANCIAL INSTITUTIONS INC",
        "value": "FISI"
    },
    {
        "label": "OB - Outbrain Inc.",
        "value": "OB"
    },
    {
        "label": "CAF - Morgan Stanley China A Share Fund, Inc.",
        "value": "CAF"
    },
    {
        "label": "DXLG - DESTINATION XL GROUP, INC.",
        "value": "DXLG"
    },
    {
        "label": "CBNK - Capital Bancorp Inc",
        "value": "CBNK"
    },
    {
        "label": "VLD - Velo3D, Inc.",
        "value": "VLD"
    },
    {
        "label": "ALVR - Allovir, Inc.",
        "value": "ALVR"
    },
    {
        "label": "GHL - GREENHILL & CO INC",
        "value": "GHL"
    },
    {
        "label": "GLASF - Glass House Brands Inc.",
        "value": "GLASF"
    },
    {
        "label": "OBT - Orange County Bancorp, Inc. /DE/",
        "value": "OBT"
    },
    {
        "label": "CDAQ - Compass Digital Acquisition Corp.",
        "value": "CDAQ"
    },
    {
        "label": "CPS - Cooper-Standard Holdings Inc.",
        "value": "CPS"
    },
    {
        "label": "VCSA - Vacasa, Inc.",
        "value": "VCSA"
    },
    {
        "label": "ZYXI - ZYNEX INC",
        "value": "ZYXI"
    },
    {
        "label": "ASA - ASA Gold & Precious Metals Ltd",
        "value": "ASA"
    },
    {
        "label": "TRHC - Tabula Rasa HealthCare, Inc.",
        "value": "TRHC"
    },
    {
        "label": "SPXX - Nuveen S&P 500 Dynamic Overwrite Fund",
        "value": "SPXX"
    },
    {
        "label": "ARAY - ACCURAY INC",
        "value": "ARAY"
    },
    {
        "label": "FMAO - FARMERS & MERCHANTS BANCORP INC",
        "value": "FMAO"
    },
    {
        "label": "LFCR - LIFECORE BIOMEDICAL, INC. \\DE\\",
        "value": "LFCR"
    },
    {
        "label": "FATE - FATE THERAPEUTICS INC",
        "value": "FATE"
    },
    {
        "label": "CORZQ - Core Scientific, Inc./tx",
        "value": "CORZQ"
    },
    {
        "label": "ANIK - Anika Therapeutics, Inc.",
        "value": "ANIK"
    },
    {
        "label": "LINC - LINCOLN EDUCATIONAL SERVICES CORP",
        "value": "LINC"
    },
    {
        "label": "LMNR - Limoneira CO",
        "value": "LMNR"
    },
    {
        "label": "ASCB - ASPAC II Acquisition Corp.",
        "value": "ASCB"
    },
    {
        "label": "NODK - NI Holdings, Inc.",
        "value": "NODK"
    },
    {
        "label": "REFI - Chicago Atlantic Real Estate Finance, Inc.",
        "value": "REFI"
    },
    {
        "label": "FBIZ - FIRST BUSINESS FINANCIAL SERVICES, INC.",
        "value": "FBIZ"
    },
    {
        "label": "WSBF - Waterstone Financial, Inc.",
        "value": "WSBF"
    },
    {
        "label": "DSM - BNY MELLON STRATEGIC MUNICIPAL BOND FUND, INC.",
        "value": "DSM"
    },
    {
        "label": "FEN - FIRST TRUST ENERGY INCOME & GROWTH FUND",
        "value": "FEN"
    },
    {
        "label": "FDBC - FIDELITY D & D BANCORP INC",
        "value": "FDBC"
    },
    {
        "label": "HT - HERSHA HOSPITALITY TRUST",
        "value": "HT"
    },
    {
        "label": "MULG - MULIANG VIAGOO TECHNOLOGY, INC.",
        "value": "MULG"
    },
    {
        "label": "HBCP - HOME BANCORP, INC.",
        "value": "HBCP"
    },
    {
        "label": "TCBX - Third Coast Bancshares, Inc.",
        "value": "TCBX"
    },
    {
        "label": "CPSI - COMPUTER PROGRAMS & SYSTEMS INC",
        "value": "CPSI"
    },
    {
        "label": "ZTR - Virtus Total Return Fund Inc.",
        "value": "ZTR"
    },
    {
        "label": "NVTA - Invitae Corp",
        "value": "NVTA"
    },
    {
        "label": "SCPH - scPharmaceuticals Inc.",
        "value": "SCPH"
    },
    {
        "label": "VAXX - Vaxxinity, Inc.",
        "value": "VAXX"
    },
    {
        "label": "EEX - Emerald Holding, Inc.",
        "value": "EEX"
    },
    {
        "label": "VHNA - Vahanna Tech Edge Acquisition I Corp.",
        "value": "VHNA"
    },
    {
        "label": "ATAI - ATAI Life Sciences N.V.",
        "value": "ATAI"
    },
    {
        "label": "OVID - Ovid Therapeutics Inc.",
        "value": "OVID"
    },
    {
        "label": "OPFI - OppFi Inc.",
        "value": "OPFI"
    },
    {
        "label": "UIS - UNISYS CORP",
        "value": "UIS"
    },
    {
        "label": "JGH - Nuveen Global High Income Fund",
        "value": "JGH"
    },
    {
        "label": "BGH - BARINGS GLOBAL SHORT DURATION HIGH YIELD FUND",
        "value": "BGH"
    },
    {
        "label": "PRLH - Pearl Holdings Acquisition Corp",
        "value": "PRLH"
    },
    {
        "label": "TLYS - TILLY'S, INC.",
        "value": "TLYS"
    },
    {
        "label": "GWRS - Global Water Resources, Inc.",
        "value": "GWRS"
    },
    {
        "label": "RM - Regional Management Corp.",
        "value": "RM"
    },
    {
        "label": "KLTR - KALTURA INC",
        "value": "KLTR"
    },
    {
        "label": "HFFG - HF Foods Group Inc.",
        "value": "HFFG"
    },
    {
        "label": "ERC - ALLSPRING MULTI-SECTOR INCOME FUND",
        "value": "ERC"
    },
    {
        "label": "HYI - Western Asset High Yield Defined Opportunity Fund Inc.",
        "value": "HYI"
    },
    {
        "label": "BGT - BLACKROCK FLOATING RATE INCOME TRUST",
        "value": "BGT"
    },
    {
        "label": "MCR - MFS CHARTER INCOME TRUST",
        "value": "MCR"
    },
    {
        "label": "SEDA - SDCL EDGE Acquisition Corp",
        "value": "SEDA"
    },
    {
        "label": "RGTI - Rigetti Computing, Inc.",
        "value": "RGTI"
    },
    {
        "label": "TTSH - TILE SHOP HOLDINGS, INC.",
        "value": "TTSH"
    },
    {
        "label": "MKFG - Markforged Holding Corp",
        "value": "MKFG"
    },
    {
        "label": "BCAL - Southern California Bancorp CA",
        "value": "BCAL"
    },
    {
        "label": "EOT - Eaton Vance National Municipal Opportunities Trust",
        "value": "EOT"
    },
    {
        "label": "NL - NL INDUSTRIES INC",
        "value": "NL"
    },
    {
        "label": "IMRX - Immuneering Corp",
        "value": "IMRX"
    },
    {
        "label": "RBB - RBB Bancorp",
        "value": "RBB"
    },
    {
        "label": "SPOK - Spok Holdings, Inc",
        "value": "SPOK"
    },
    {
        "label": "KMDA - KAMADA LTD",
        "value": "KMDA"
    },
    {
        "label": "TALK - Talkspace, Inc.",
        "value": "TALK"
    },
    {
        "label": "AGS - PlayAGS, Inc.",
        "value": "AGS"
    },
    {
        "label": "VUZI - Vuzix Corp",
        "value": "VUZI"
    },
    {
        "label": "TNYA - Tenaya Therapeutics, Inc.",
        "value": "TNYA"
    },
    {
        "label": "AMLI - American Lithium Corp.",
        "value": "AMLI"
    },
    {
        "label": "AMSC - AMERICAN SUPERCONDUCTOR CORP /DE/",
        "value": "AMSC"
    },
    {
        "label": "FCT - FIRST TRUST SENIOR FLOATING RATE INCOME FUND II",
        "value": "FCT"
    },
    {
        "label": "MMT - MFS MULTIMARKET INCOME TRUST",
        "value": "MMT"
    },
    {
        "label": "ILPT - Industrial Logistics Properties Trust",
        "value": "ILPT"
    },
    {
        "label": "MXF - MEXICO FUND INC",
        "value": "MXF"
    },
    {
        "label": "GPMT - Granite Point Mortgage Trust Inc.",
        "value": "GPMT"
    },
    {
        "label": "HQI - HireQuest, Inc.",
        "value": "HQI"
    },
    {
        "label": "LOCC - Live Oak Crestview Climate Acquisition Corp.",
        "value": "LOCC"
    },
    {
        "label": "PINE - Alpine Income Property Trust, Inc.",
        "value": "PINE"
    },
    {
        "label": "EBS - Emergent BioSolutions Inc.",
        "value": "EBS"
    },
    {
        "label": "CMTL - COMTECH TELECOMMUNICATIONS CORP /DE/",
        "value": "CMTL"
    },
    {
        "label": "IREN - Iris Energy Ltd",
        "value": "IREN"
    },
    {
        "label": "DLTH - DULUTH HOLDINGS INC.",
        "value": "DLTH"
    },
    {
        "label": "PETS - PETMED EXPRESS INC",
        "value": "PETS"
    },
    {
        "label": "AURC - Aurora Acquisition Corp.",
        "value": "AURC"
    },
    {
        "label": "AEF - ABRDN EMERGING MARKETS EQUITY INCOME FUND, INC.",
        "value": "AEF"
    },
    {
        "label": "SKYH - Sky Harbour Group Corp",
        "value": "SKYH"
    },
    {
        "label": "UONE - URBAN ONE, INC.",
        "value": "UONE"
    },
    {
        "label": "XFLT - XAI Octagon Floating Rate & Alternative Income Term Trust",
        "value": "XFLT"
    },
    {
        "label": "CARM - Carisma Therapeutics Inc.",
        "value": "CARM"
    },
    {
        "label": "CHMX - NEXT-ChemX Corporation.",
        "value": "CHMX"
    },
    {
        "label": "BLDE - Blade Air Mobility, Inc.",
        "value": "BLDE"
    },
    {
        "label": "MSB - MESABI TRUST",
        "value": "MSB"
    },
    {
        "label": "KNDI - Kandi Technologies Group, Inc.",
        "value": "KNDI"
    },
    {
        "label": "BYNO - byNordic Acquisition Corp",
        "value": "BYNO"
    },
    {
        "label": "AXGN - Axogen, Inc.",
        "value": "AXGN"
    },
    {
        "label": "AFCG - AFC Gamma, Inc.",
        "value": "AFCG"
    },
    {
        "label": "WEYS - WEYCO GROUP INC",
        "value": "WEYS"
    },
    {
        "label": "HIPO - Hippo Holdings Inc.",
        "value": "HIPO"
    },
    {
        "label": "CLPR - Clipper Realty Inc.",
        "value": "CLPR"
    },
    {
        "label": "BKCC - BlackRock Capital Investment Corp",
        "value": "BKCC"
    },
    {
        "label": "THRD - Third Harmonic Bio, Inc.",
        "value": "THRD"
    },
    {
        "label": "NEN - NEW ENGLAND REALTY ASSOCIATES LIMITED PARTNERSHIP",
        "value": "NEN"
    },
    {
        "label": "PRPL - Purple Innovation, Inc.",
        "value": "PRPL"
    },
    {
        "label": "CDZI - CADIZ INC",
        "value": "CDZI"
    },
    {
        "label": "TREE - LendingTree, Inc.",
        "value": "TREE"
    },
    {
        "label": "JMSB - John Marshall Bancorp, Inc.",
        "value": "JMSB"
    },
    {
        "label": "VLN - Valens Semiconductor Ltd.",
        "value": "VLN"
    },
    {
        "label": "ACIU - AC Immune SA",
        "value": "ACIU"
    },
    {
        "label": "BOCN - Blue Ocean Acquisition Corp",
        "value": "BOCN"
    },
    {
        "label": "FTF - FRANKLIN LTD DURATION INCOME TRUST",
        "value": "FTF"
    },
    {
        "label": "JILL - J.Jill, Inc.",
        "value": "JILL"
    },
    {
        "label": "STRO - SUTRO BIOPHARMA, INC.",
        "value": "STRO"
    },
    {
        "label": "SSTI - SOUNDTHINKING, INC.",
        "value": "SSTI"
    },
    {
        "label": "AMPY - Amplify Energy Corp.",
        "value": "AMPY"
    },
    {
        "label": "CMBM - Cambium Networks Corp",
        "value": "CMBM"
    },
    {
        "label": "NRIM - NORTHRIM BANCORP INC",
        "value": "NRIM"
    },
    {
        "label": "CFIV - CF ACQUISITION CORP. IV",
        "value": "CFIV"
    },
    {
        "label": "PMX - PIMCO MUNICIPAL INCOME FUND III",
        "value": "PMX"
    },
    {
        "label": "NC - NACCO INDUSTRIES INC",
        "value": "NC"
    },
    {
        "label": "DRTS - Alpha Tau Medical Ltd.",
        "value": "DRTS"
    },
    {
        "label": "CFFS - CF Acquisition Corp. VII",
        "value": "CFFS"
    },
    {
        "label": "SENS - Senseonics Holdings, Inc.",
        "value": "SENS"
    },
    {
        "label": "UNTY - UNITY BANCORP INC /NJ/",
        "value": "UNTY"
    },
    {
        "label": "GOEV - Canoo Inc.",
        "value": "GOEV"
    },
    {
        "label": "HKIT - HiTek Global Inc.",
        "value": "HKIT"
    },
    {
        "label": "ORRF - ORRSTOWN FINANCIAL SERVICES INC",
        "value": "ORRF"
    },
    {
        "label": "NIU - Niu Technologies",
        "value": "NIU"
    },
    {
        "label": "PMF - PIMCO MUNICIPAL INCOME FUND",
        "value": "PMF"
    },
    {
        "label": "BKT - BLACKROCK INCOME TRUST, INC.",
        "value": "BKT"
    },
    {
        "label": "YI - 111, Inc.",
        "value": "YI"
    },
    {
        "label": "CNF - CNFinance Holdings Ltd.",
        "value": "CNF"
    },
    {
        "label": "IPHA - Innate Pharma SA",
        "value": "IPHA"
    },
    {
        "label": "THCP - Thunder Bridge Capital Partners IV, Inc.",
        "value": "THCP"
    },
    {
        "label": "NECB - NorthEast Community Bancorp, Inc./MD/",
        "value": "NECB"
    },
    {
        "label": "BLFY - Blue Foundry Bancorp",
        "value": "BLFY"
    },
    {
        "label": "VSTM - Verastem, Inc.",
        "value": "VSTM"
    },
    {
        "label": "STOK - Stoke Therapeutics, Inc.",
        "value": "STOK"
    },
    {
        "label": "TWOU - 2U, Inc.",
        "value": "TWOU"
    },
    {
        "label": "PMTS - CPI Card Group Inc.",
        "value": "PMTS"
    },
    {
        "label": "PBFS - Pioneer Bancorp, Inc./MD",
        "value": "PBFS"
    },
    {
        "label": "NHHS - NorthStar Healthcare Income, Inc.",
        "value": "NHHS"
    },
    {
        "label": "CZFS - CITIZENS FINANCIAL SERVICES INC",
        "value": "CZFS"
    },
    {
        "label": "FNGR - FingerMotion, Inc.",
        "value": "FNGR"
    },
    {
        "label": "AKYA - Akoya Biosciences, Inc.",
        "value": "AKYA"
    },
    {
        "label": "ASUR - ASURE SOFTWARE INC",
        "value": "ASUR"
    },
    {
        "label": "ATLX - Atlas Lithium Corp",
        "value": "ATLX"
    },
    {
        "label": "REAL - TheRealReal, Inc.",
        "value": "REAL"
    },
    {
        "label": "NUW - Nuveen AMT-Free Municipal Value Fund",
        "value": "NUW"
    },
    {
        "label": "FULC - Fulcrum Therapeutics, Inc.",
        "value": "FULC"
    },
    {
        "label": "OUST - Ouster, Inc.",
        "value": "OUST"
    },
    {
        "label": "AOMR - Angel Oak Mortgage REIT, Inc.",
        "value": "AOMR"
    },
    {
        "label": "ACRV - Acrivon Therapeutics, Inc.",
        "value": "ACRV"
    },
    {
        "label": "DSKE - Daseke, Inc.",
        "value": "DSKE"
    },
    {
        "label": "BNY - BLACKROCK NEW YORK MUNICIPAL INCOME TRUST",
        "value": "BNY"
    },
    {
        "label": "GTII - GLOBAL TECH INDUSTRIES GROUP, INC.",
        "value": "GTII"
    },
    {
        "label": "CORBF - Global Cord Blood Corp",
        "value": "CORBF"
    },
    {
        "label": "BIG - BIG LOTS INC",
        "value": "BIG"
    },
    {
        "label": "PKOH - PARK OHIO HOLDINGS CORP",
        "value": "PKOH"
    },
    {
        "label": "III - Information Services Group Inc.",
        "value": "III"
    },
    {
        "label": "BZUN - Baozun Inc.",
        "value": "BZUN"
    },
    {
        "label": "HAWEL - HAWAIIAN ELECTRIC CO INC",
        "value": "HAWEL"
    },
    {
        "label": "SST - System1, Inc.",
        "value": "SST"
    },
    {
        "label": "RCS - PIMCO STRATEGIC INCOME FUND, INC",
        "value": "RCS"
    },
    {
        "label": "FIF - FIRST TRUST ENERGY INFRASTRUCTURE FUND",
        "value": "FIF"
    },
    {
        "label": "RIV - RIVERNORTH OPPORTUNITIES FUND, INC.",
        "value": "RIV"
    },
    {
        "label": "GTE - GRAN TIERRA ENERGY INC.",
        "value": "GTE"
    },
    {
        "label": "FUND - SPROTT FOCUS TRUST INC.",
        "value": "FUND"
    },
    {
        "label": "PBPB - POTBELLY CORP",
        "value": "PBPB"
    },
    {
        "label": "FSBW - FS Bancorp, Inc.",
        "value": "FSBW"
    },
    {
        "label": "TIHE - Taihe Group, Inc.",
        "value": "TIHE"
    },
    {
        "label": "FET - FORUM ENERGY TECHNOLOGIES, INC.",
        "value": "FET"
    },
    {
        "label": "MEC - Mayville Engineering Company, Inc.",
        "value": "MEC"
    },
    {
        "label": "SLN - Silence Therapeutics plc",
        "value": "SLN"
    },
    {
        "label": "LUNA - LUNA INNOVATIONS INC",
        "value": "LUNA"
    },
    {
        "label": "BSVN - Bank7 Corp.",
        "value": "BSVN"
    },
    {
        "label": "ICNC - Iconic Sports Acquisition Corp.",
        "value": "ICNC"
    },
    {
        "label": "SPWH - SPORTSMAN'S WAREHOUSE HOLDINGS, INC.",
        "value": "SPWH"
    },
    {
        "label": "HSHP - Himalaya Shipping Ltd.",
        "value": "HSHP"
    },
    {
        "label": "EM - Smart Share Global Ltd",
        "value": "EM"
    },
    {
        "label": "BVS - Bioventus Inc.",
        "value": "BVS"
    },
    {
        "label": "TSBK - TIMBERLAND BANCORP INC",
        "value": "TSBK"
    },
    {
        "label": "NYXH - Nyxoah SA",
        "value": "NYXH"
    },
    {
        "label": "DBL - DoubleLine Opportunistic Credit Fund",
        "value": "DBL"
    },
    {
        "label": "LTRY - Lottery.com Inc.",
        "value": "LTRY"
    },
    {
        "label": "APCA - AP Acquisition Corp",
        "value": "APCA"
    },
    {
        "label": "BCML - BayCom Corp",
        "value": "BCML"
    },
    {
        "label": "GRCL - Gracell Biotechnologies Inc.",
        "value": "GRCL"
    },
    {
        "label": "AKBA - Akebia Therapeutics, Inc.",
        "value": "AKBA"
    },
    {
        "label": "LCTX - Lineage Cell Therapeutics, Inc.",
        "value": "LCTX"
    },
    {
        "label": "HOFT - HOOKER FURNISHINGS Corp",
        "value": "HOFT"
    },
    {
        "label": "POWW - AMMO, INC.",
        "value": "POWW"
    },
    {
        "label": "FRBN - Forbion European Acquisition Corp.",
        "value": "FRBN"
    },
    {
        "label": "MNTN - Everest Consolidator Acquisition Corp",
        "value": "MNTN"
    },
    {
        "label": "YMAB - Y-mAbs Therapeutics, Inc.",
        "value": "YMAB"
    },
    {
        "label": "PRTS - CarParts.com, Inc.",
        "value": "PRTS"
    },
    {
        "label": "PSTX - Poseida Therapeutics, Inc.",
        "value": "PSTX"
    },
    {
        "label": "IMMR - IMMERSION CORP",
        "value": "IMMR"
    },
    {
        "label": "LMDX - LumiraDx Ltd",
        "value": "LMDX"
    },
    {
        "label": "CMAX - CareMax, Inc.",
        "value": "CMAX"
    },
    {
        "label": "PTHR - Pono Capital Three, Inc.",
        "value": "PTHR"
    },
    {
        "label": "MASS - 908 Devices Inc.",
        "value": "MASS"
    },
    {
        "label": "NVCT - Nuvectis Pharma, Inc.",
        "value": "NVCT"
    },
    {
        "label": "LGO - Largo Inc.",
        "value": "LGO"
    },
    {
        "label": "IIF - MORGAN STANLEY INDIA INVESTMENT FUND, INC.",
        "value": "IIF"
    },
    {
        "label": "AGD - abrdn Global Dynamic Dividend Fund",
        "value": "AGD"
    },
    {
        "label": "EGRX - EAGLE PHARMACEUTICALS, INC.",
        "value": "EGRX"
    },
    {
        "label": "CUTR - CUTERA INC",
        "value": "CUTR"
    },
    {
        "label": "OCN - OCWEN FINANCIAL CORP",
        "value": "OCN"
    },
    {
        "label": "OPRT - Oportun Financial Corp",
        "value": "OPRT"
    },
    {
        "label": "GBBK - Global Blockchain Acquisition Corp.",
        "value": "GBBK"
    },
    {
        "label": "TEI - TEMPLETON EMERGING MARKETS INCOME FUND",
        "value": "TEI"
    },
    {
        "label": "WRN - Western Copper & Gold Corp",
        "value": "WRN"
    },
    {
        "label": "RBTK - ZHEN DING RESOURCES INC.",
        "value": "RBTK"
    },
    {
        "label": "TWN - TAIWAN FUND INC",
        "value": "TWN"
    },
    {
        "label": "AEVA - Aeva Technologies, Inc.",
        "value": "AEVA"
    },
    {
        "label": "MBCN - MIDDLEFIELD BANC CORP",
        "value": "MBCN"
    },
    {
        "label": "VPV - Invesco Pennsylvania Value Municipal Income Trust",
        "value": "VPV"
    },
    {
        "label": "OMER - OMEROS CORP",
        "value": "OMER"
    },
    {
        "label": "CTR - ClearBridge MLP & Midstream Total Return Fund Inc.",
        "value": "CTR"
    },
    {
        "label": "RLTY - Cohen & Steers Real Estate Opportunities & Income Fund",
        "value": "RLTY"
    },
    {
        "label": "NWFL - NORWOOD FINANCIAL CORP",
        "value": "NWFL"
    },
    {
        "label": "EVLO - Evelo Biosciences, Inc.",
        "value": "EVLO"
    },
    {
        "label": "SCWO - 374Water Inc.",
        "value": "SCWO"
    },
    {
        "label": "BOAC - Bluescape Opportunities Acquisition Corp.",
        "value": "BOAC"
    },
    {
        "label": "RMNI - Rimini Street, Inc.",
        "value": "RMNI"
    },
    {
        "label": "TELA - TELA Bio, Inc.",
        "value": "TELA"
    },
    {
        "label": "BTBT - Bit Digital, Inc",
        "value": "BTBT"
    },
    {
        "label": "QIPT - Quipt Home Medical Corp.",
        "value": "QIPT"
    },
    {
        "label": "STRS - STRATUS PROPERTIES INC",
        "value": "STRS"
    },
    {
        "label": "TCOA - Zalatoris Acquisition Corp.",
        "value": "TCOA"
    },
    {
        "label": "AENT - ALLIANCE ENTERTAINMENT HOLDING CORP",
        "value": "AENT"
    },
    {
        "label": "HSDT - HELIUS MEDICAL TECHNOLOGIES, INC.",
        "value": "HSDT"
    },
    {
        "label": "SFST - SOUTHERN FIRST BANCSHARES INC",
        "value": "SFST"
    },
    {
        "label": "LSAK - LESAKA TECHNOLOGIES INC",
        "value": "LSAK"
    },
    {
        "label": "NCZ - Virtus Convertible & Income Fund II",
        "value": "NCZ"
    },
    {
        "label": "KFS - KINGSWAY FINANCIAL SERVICES INC",
        "value": "KFS"
    },
    {
        "label": "FRST - Primis Financial Corp.",
        "value": "FRST"
    },
    {
        "label": "INFU - InfuSystem Holdings, Inc",
        "value": "INFU"
    },
    {
        "label": "FUST - FUSE GROUP HOLDING INC.",
        "value": "FUST"
    },
    {
        "label": "TSI - TCW STRATEGIC INCOME FUND INC",
        "value": "TSI"
    },
    {
        "label": "AIP - Arteris, Inc.",
        "value": "AIP"
    },
    {
        "label": "SCD - LMP CAPITAL & INCOME FUND INC.",
        "value": "SCD"
    },
    {
        "label": "YRD - Yiren Digital Ltd.",
        "value": "YRD"
    },
    {
        "label": "PCB - PCB BANCORP",
        "value": "PCB"
    },
    {
        "label": "FDEU - FIRST TRUST DYNAMIC EUROPE EQUITY INCOME FUND",
        "value": "FDEU"
    },
    {
        "label": "FENC - FENNEC PHARMACEUTICALS INC.",
        "value": "FENC"
    },
    {
        "label": "IFRX - InflaRx N.V.",
        "value": "IFRX"
    },
    {
        "label": "RCAC - Revelstone Capital Acquisition Corp.",
        "value": "RCAC"
    },
    {
        "label": "MQT - BLACKROCK MUNIYIELD QUALITY FUND II, INC.",
        "value": "MQT"
    },
    {
        "label": "EVER - EverQuote, Inc.",
        "value": "EVER"
    },
    {
        "label": "SLQT - SelectQuote, Inc.",
        "value": "SLQT"
    },
    {
        "label": "MVT - BLACKROCK MUNIVEST FUND II, INC.",
        "value": "MVT"
    },
    {
        "label": "ATXS - Astria Therapeutics, Inc.",
        "value": "ATXS"
    },
    {
        "label": "FTCI - FTC Solar, Inc.",
        "value": "FTCI"
    },
    {
        "label": "SDC - SmileDirectClub, Inc.",
        "value": "SDC"
    },
    {
        "label": "EHTH - eHealth, Inc.",
        "value": "EHTH"
    },
    {
        "label": "FRGI - Fiesta Restaurant Group, Inc.",
        "value": "FRGI"
    },
    {
        "label": "GPJA - GEORGIA POWER CO",
        "value": "GPJA"
    },
    {
        "label": "SOL - Emeren Group Ltd",
        "value": "SOL"
    },
    {
        "label": "CMT - CORE MOLDING TECHNOLOGIES INC",
        "value": "CMT"
    },
    {
        "label": "FVCB - FVCBankcorp, Inc.",
        "value": "FVCB"
    },
    {
        "label": "MOLN - MOLECULAR PARTNERS AG",
        "value": "MOLN"
    },
    {
        "label": "WEWA - WEWARDS, INC.",
        "value": "WEWA"
    },
    {
        "label": "BHR - Braemar Hotels & Resorts Inc.",
        "value": "BHR"
    },
    {
        "label": "CRMD - CorMedix Inc.",
        "value": "CRMD"
    },
    {
        "label": "GROY - Gold Royalty Corp.",
        "value": "GROY"
    },
    {
        "label": "CANO - Cano Health, Inc.",
        "value": "CANO"
    },
    {
        "label": "PRPC - CC Neuberger Principal Holdings III",
        "value": "PRPC"
    },
    {
        "label": "PSF - Cohen & Steers Select Preferred & Income Fund, Inc.",
        "value": "PSF"
    },
    {
        "label": "OVLY - Oak Valley Bancorp",
        "value": "OVLY"
    },
    {
        "label": "IRRX - INTEGRATED RAIL & RESOURCES ACQUISITION CORP",
        "value": "IRRX"
    },
    {
        "label": "FLJ - FLJ Group Ltd",
        "value": "FLJ"
    },
    {
        "label": "USCB - USCB FINANCIAL HOLDINGS, INC.",
        "value": "USCB"
    },
    {
        "label": "STKS - ONE Group Hospitality, Inc.",
        "value": "STKS"
    },
    {
        "label": "MTA - Metalla Royalty & Streaming Ltd.",
        "value": "MTA"
    },
    {
        "label": "ARBE - Arbe Robotics Ltd.",
        "value": "ARBE"
    },
    {
        "label": "DC - Dakota Gold Corp.",
        "value": "DC"
    },
    {
        "label": "MUE - BLACKROCK MUNIHOLDINGS QUALITY FUND II, INC.",
        "value": "MUE"
    },
    {
        "label": "ARTE - Artemis Strategic Investment Corp",
        "value": "ARTE"
    },
    {
        "label": "LAB - STANDARD BIOTOOLS INC.",
        "value": "LAB"
    },
    {
        "label": "TUSK - MAMMOTH ENERGY SERVICES, INC.",
        "value": "TUSK"
    },
    {
        "label": "AMTX - AEMETIS, INC",
        "value": "AMTX"
    },
    {
        "label": "SKIL - Skillsoft Corp.",
        "value": "SKIL"
    },
    {
        "label": "EGAN - EGAIN Corp",
        "value": "EGAN"
    },
    {
        "label": "HYLN - Hyliion Holdings Corp.",
        "value": "HYLN"
    },
    {
        "label": "ESCA - ESCALADE INC",
        "value": "ESCA"
    },
    {
        "label": "BARK - Bark, Inc.",
        "value": "BARK"
    },
    {
        "label": "CNLHN - CONNECTICUT LIGHT & POWER CO",
        "value": "CNLHN"
    },
    {
        "label": "AUGX - Augmedix, Inc.",
        "value": "AUGX"
    },
    {
        "label": "VBNK - VersaBank",
        "value": "VBNK"
    },
    {
        "label": "TCX - TUCOWS INC /PA/",
        "value": "TCX"
    },
    {
        "label": "SBXC - SilverBox Corp III",
        "value": "SBXC"
    },
    {
        "label": "BBAI - BigBear.ai Holdings, Inc.",
        "value": "BBAI"
    },
    {
        "label": "CELC - Celcuity Inc.",
        "value": "CELC"
    },
    {
        "label": "EAXR - Ealixir, Inc.",
        "value": "EAXR"
    },
    {
        "label": "PDLB - Ponce Financial Group, Inc.",
        "value": "PDLB"
    },
    {
        "label": "ISRL - Israel Acquisitions Corp",
        "value": "ISRL"
    },
    {
        "label": "INZY - Inozyme Pharma, Inc.",
        "value": "INZY"
    },
    {
        "label": "MFM - MFS MUNICIPAL INCOME TRUST",
        "value": "MFM"
    },
    {
        "label": "SSBK - Southern States Bancshares, Inc.",
        "value": "SSBK"
    },
    {
        "label": "EMCG - Embrace Change Acquisition Corp.",
        "value": "EMCG"
    },
    {
        "label": "UROY - Uranium Royalty Corp.",
        "value": "UROY"
    },
    {
        "label": "GLO - Clough Global Opportunities Fund",
        "value": "GLO"
    },
    {
        "label": "FSTR - FOSTER L B CO",
        "value": "FSTR"
    },
    {
        "label": "JRS - NUVEEN REAL ESTATE INCOME FUND",
        "value": "JRS"
    },
    {
        "label": "BIOS - BioPlus Acquisition Corp.",
        "value": "BIOS"
    },
    {
        "label": "BWFG - Bankwell Financial Group, Inc.",
        "value": "BWFG"
    },
    {
        "label": "PKBK - PARKE BANCORP, INC.",
        "value": "PKBK"
    },
    {
        "label": "RANI - Rani Therapeutics Holdings, Inc.",
        "value": "RANI"
    },
    {
        "label": "ANTX - AN2 Therapeutics, Inc.",
        "value": "ANTX"
    },
    {
        "label": "HYBT - Heyu Biological Technology Corp",
        "value": "HYBT"
    },
    {
        "label": "RLYB - Rallybio Corp",
        "value": "RLYB"
    },
    {
        "label": "FXE - Invesco CurrencyShares Euro Trust",
        "value": "FXE"
    },
    {
        "label": "PHYT - Pyrophyte Acquisition Corp.",
        "value": "PHYT"
    },
    {
        "label": "CNTY - CENTURY CASINOS INC /CO/",
        "value": "CNTY"
    },
    {
        "label": "FGPR - FERRELLGAS PARTNERS L P",
        "value": "FGPR"
    },
    {
        "label": "VIGL - Vigil Neuroscience, Inc.",
        "value": "VIGL"
    },
    {
        "label": "AFT - Apollo Senior Floating Rate Fund Inc.",
        "value": "AFT"
    },
    {
        "label": "ICTSF - ICTS INTERNATIONAL N V",
        "value": "ICTSF"
    },
    {
        "label": "RCFA - RCF Acquisition Corp.",
        "value": "RCFA"
    },
    {
        "label": "LLAP - Terran Orbital Corp",
        "value": "LLAP"
    },
    {
        "label": "SWKH - SWK Holdings Corp",
        "value": "SWKH"
    },
    {
        "label": "VRA - Vera Bradley, Inc.",
        "value": "VRA"
    },
    {
        "label": "JOF - JAPAN SMALLER CAPITALIZATION FUND INC",
        "value": "JOF"
    },
    {
        "label": "JCE - Nuveen Core Equity Alpha Fund",
        "value": "JCE"
    },
    {
        "label": "MESO - MESOBLAST LTD",
        "value": "MESO"
    },
    {
        "label": "CVLY - CODORUS VALLEY BANCORP INC",
        "value": "CVLY"
    },
    {
        "label": "VZLA - Vizsla Silver Corp.",
        "value": "VZLA"
    },
    {
        "label": "ELLO - Ellomay Capital Ltd.",
        "value": "ELLO"
    },
    {
        "label": "TPIC - TPI COMPOSITES, INC",
        "value": "TPIC"
    },
    {
        "label": "NUTX - Nutex Health, Inc.",
        "value": "NUTX"
    },
    {
        "label": "HYW - Hywin Holdings Ltd.",
        "value": "HYW"
    },
    {
        "label": "DNMR - Danimer Scientific, Inc.",
        "value": "DNMR"
    },
    {
        "label": "MFIN - MEDALLION FINANCIAL CORP",
        "value": "MFIN"
    },
    {
        "label": "GENC - GENCOR INDUSTRIES INC",
        "value": "GENC"
    },
    {
        "label": "EDTX - EdtechX Holdings Acquisition Corp. II",
        "value": "EDTX"
    },
    {
        "label": "PPYA - Papaya Growth Opportunity Corp. I",
        "value": "PPYA"
    },
    {
        "label": "ACV - Virtus Diversified Income & Convertible Fund",
        "value": "ACV"
    },
    {
        "label": "KGNR - AMJ Global Technology",
        "value": "KGNR"
    },
    {
        "label": "MCAA - Mountain & Co. I Acquisition Corp.",
        "value": "MCAA"
    },
    {
        "label": "RDW - Redwire Corp",
        "value": "RDW"
    },
    {
        "label": "FUSN - Fusion Pharmaceuticals Inc.",
        "value": "FUSN"
    },
    {
        "label": "MAPS - WM TECHNOLOGY, INC.",
        "value": "MAPS"
    },
    {
        "label": "ERLFF - Entree Resources Ltd.",
        "value": "ERLFF"
    },
    {
        "label": "OBIO - Orchestra BioMed Holdings, Inc.",
        "value": "OBIO"
    },
    {
        "label": "BIRD - Allbirds, Inc.",
        "value": "BIRD"
    },
    {
        "label": "BKSY - BlackSky Technology Inc.",
        "value": "BKSY"
    },
    {
        "label": "DMB - BNY Mellon Municipal Bond Infrastructure Fund, Inc.",
        "value": "DMB"
    },
    {
        "label": "CMCL - Caledonia Mining Corp Plc",
        "value": "CMCL"
    },
    {
        "label": "CPSS - CONSUMER PORTFOLIO SERVICES, INC.",
        "value": "CPSS"
    },
    {
        "label": "PTMN - Portman Ridge Finance Corp",
        "value": "PTMN"
    },
    {
        "label": "VABK - Virginia National Bankshares Corp",
        "value": "VABK"
    },
    {
        "label": "ORGN - Origin Materials, Inc.",
        "value": "ORGN"
    },
    {
        "label": "VOR - Vor Biopharma Inc.",
        "value": "VOR"
    },
    {
        "label": "PHT - PIONEER HIGH INCOME FUND, INC.",
        "value": "PHT"
    },
    {
        "label": "EP - EMPIRE PETROLEUM CORP",
        "value": "EP"
    },
    {
        "label": "BCBP - BCB BANCORP INC",
        "value": "BCBP"
    },
    {
        "label": "BKN - BLACKROCK INVESTMENT QUALITY MUNICIPAL TRUST, INC.",
        "value": "BKN"
    },
    {
        "label": "MHLD - Maiden Holdings, Ltd.",
        "value": "MHLD"
    },
    {
        "label": "CIO - City Office REIT, Inc.",
        "value": "CIO"
    },
    {
        "label": "BGFV - BIG 5 SPORTING GOODS Corp",
        "value": "BGFV"
    },
    {
        "label": "VRCA - Verrica Pharmaceuticals Inc.",
        "value": "VRCA"
    },
    {
        "label": "JAKK - JAKKS PACIFIC INC",
        "value": "JAKK"
    },
    {
        "label": "PLBC - PLUMAS BANCORP",
        "value": "PLBC"
    },
    {
        "label": "RIGL - RIGEL PHARMACEUTICALS INC",
        "value": "RIGL"
    },
    {
        "label": "GASS - StealthGas Inc.",
        "value": "GASS"
    },
    {
        "label": "NTG - Tortoise Midstream Energy Fund, Inc.",
        "value": "NTG"
    },
    {
        "label": "APMI - AxonPrime Infrastructure Acquisition Corp",
        "value": "APMI"
    },
    {
        "label": "DHY - CREDIT SUISSE HIGH YIELD BOND FUND",
        "value": "DHY"
    },
    {
        "label": "MRAM - EVERSPIN TECHNOLOGIES INC",
        "value": "MRAM"
    },
    {
        "label": "INVE - Identiv, Inc.",
        "value": "INVE"
    },
    {
        "label": "CHMG - CHEMUNG FINANCIAL CORP",
        "value": "CHMG"
    },
    {
        "label": "IMAB - I-Mab",
        "value": "IMAB"
    },
    {
        "label": "KPTI - Karyopharm Therapeutics Inc.",
        "value": "KPTI"
    },
    {
        "label": "FSP - FRANKLIN STREET PROPERTIES CORP /MA/",
        "value": "FSP"
    },
    {
        "label": "CLMB - Climb Global Solutions, Inc.",
        "value": "CLMB"
    },
    {
        "label": "LFAC - LF Capital Acquisition Corp. II",
        "value": "LFAC"
    },
    {
        "label": "GNFT - Genfit S.A.",
        "value": "GNFT"
    },
    {
        "label": "XYF - X Financial",
        "value": "XYF"
    },
    {
        "label": "LIAN - LianBio",
        "value": "LIAN"
    },
    {
        "label": "LGI - LAZARD GLOBAL TOTAL RETURN & INCOME FUND INC",
        "value": "LGI"
    },
    {
        "label": "OPP - RiverNorth/DoubleLine Strategic Opportunity Fund, Inc.",
        "value": "OPP"
    },
    {
        "label": "VTN - Invesco Trust for Investment Grade New York Municipals",
        "value": "VTN"
    },
    {
        "label": "HRTX - HERON THERAPEUTICS, INC. /DE/",
        "value": "HRTX"
    },
    {
        "label": "TENK - TenX Keane Acquisition",
        "value": "TENK"
    },
    {
        "label": "NXMH - Next Meats Holdings, Inc.",
        "value": "NXMH"
    },
    {
        "label": "TOP - TOP Financial Group Ltd",
        "value": "TOP"
    },
    {
        "label": "ALXO - ALX ONCOLOGY HOLDINGS INC",
        "value": "ALXO"
    },
    {
        "label": "CFFI - C & F FINANCIAL CORP",
        "value": "CFFI"
    },
    {
        "label": "ZOM - Zomedica Corp.",
        "value": "ZOM"
    },
    {
        "label": "EMX - EMX Royalty Corp",
        "value": "EMX"
    },
    {
        "label": "XFOR - X4 Pharmaceuticals, Inc",
        "value": "XFOR"
    },
    {
        "label": "WIA - WESTERN ASSET INFLATION-LINKED INCOME FUND",
        "value": "WIA"
    },
    {
        "label": "TIO - Tingo Group, Inc.",
        "value": "TIO"
    },
    {
        "label": "CCHWF - Columbia Care Inc.",
        "value": "CCHWF"
    },
    {
        "label": "HEAR - Turtle Beach Corp",
        "value": "HEAR"
    },
    {
        "label": "NPV - NUVEEN VIRGINIA QUALITY MUNICIPAL INCOME FUND",
        "value": "NPV"
    },
    {
        "label": "MYFW - First Western Financial Inc",
        "value": "MYFW"
    },
    {
        "label": "NBH - NEUBERGER BERMAN MUNICIPAL FUND INC.",
        "value": "NBH"
    },
    {
        "label": "DBB - INVESCO DB BASE METALS FUND",
        "value": "DBB"
    },
    {
        "label": "MHI - PIONEER MUNICIPAL HIGH INCOME FUND, INC.",
        "value": "MHI"
    },
    {
        "label": "EMP - ENTERGY MISSISSIPPI, LLC",
        "value": "EMP"
    },
    {
        "label": "OMGA - Omega Therapeutics, Inc.",
        "value": "OMGA"
    },
    {
        "label": "REKR - Rekor Systems, Inc.",
        "value": "REKR"
    },
    {
        "label": "ETX - Eaton Vance Municipal Income 2028 Term Trust",
        "value": "ETX"
    },
    {
        "label": "TWIN - TWIN DISC INC",
        "value": "TWIN"
    },
    {
        "label": "RYAM - RAYONIER ADVANCED MATERIALS INC.",
        "value": "RYAM"
    },
    {
        "label": "RGCO - RGC RESOURCES INC",
        "value": "RGCO"
    },
    {
        "label": "HGLB - HIGHLAND GLOBAL ALLOCATION FUND",
        "value": "HGLB"
    },
    {
        "label": "AIF - Apollo Tactical Income Fund Inc.",
        "value": "AIF"
    },
    {
        "label": "TSBX - Turnstone Biologics Corp.",
        "value": "TSBX"
    },
    {
        "label": "HIE - Miller/Howard High Income Equity Fund",
        "value": "HIE"
    },
    {
        "label": "DHX - DHI GROUP, INC.",
        "value": "DHX"
    },
    {
        "label": "EOD - ALLSPRING GLOBAL DIVIDEND OPPORTUNITY FUND",
        "value": "EOD"
    },
    {
        "label": "ENJ - ENTERGY NEW ORLEANS, LLC",
        "value": "ENJ"
    },
    {
        "label": "TRUE - TrueCar, Inc.",
        "value": "TRUE"
    },
    {
        "label": "RGC - Regencell Bioscience Holdings Ltd",
        "value": "RGC"
    },
    {
        "label": "PCK - PIMCO CALIFORNIA MUNICIPAL INCOME FUND II",
        "value": "PCK"
    },
    {
        "label": "CCRD - CoreCard Corp",
        "value": "CCRD"
    },
    {
        "label": "DOUG - Douglas Elliman Inc.",
        "value": "DOUG"
    },
    {
        "label": "PROF - Profound Medical Corp.",
        "value": "PROF"
    },
    {
        "label": "VOXX - VOXX International Corp",
        "value": "VOXX"
    },
    {
        "label": "ADAP - Adaptimmune Therapeutics PLC",
        "value": "ADAP"
    },
    {
        "label": "TOUR - Tuniu Corp",
        "value": "TOUR"
    },
    {
        "label": "HBIO - HARVARD BIOSCIENCE INC",
        "value": "HBIO"
    },
    {
        "label": "BPRN - Princeton Bancorp, Inc.",
        "value": "BPRN"
    },
    {
        "label": "RPHM - Reneo Pharmaceuticals, Inc.",
        "value": "RPHM"
    },
    {
        "label": "PWOD - PENNS WOODS BANCORP INC",
        "value": "PWOD"
    },
    {
        "label": "WATT - Energous Corp",
        "value": "WATT"
    },
    {
        "label": "EMF - TEMPLETON EMERGING MARKETS FUND",
        "value": "EMF"
    },
    {
        "label": "GDO - WESTERN ASSET GLOBAL CORPORATE DEFINED OPPORTUNITY FUND INC.",
        "value": "GDO"
    },
    {
        "label": "HMST - HomeStreet, Inc.",
        "value": "HMST"
    },
    {
        "label": "PCQ - PIMCO CALIFORNIA MUNICIPAL INCOME FUND",
        "value": "PCQ"
    },
    {
        "label": "GWH - ESS Tech, Inc.",
        "value": "GWH"
    },
    {
        "label": "BRKH - BurTech Acquisition Corp.",
        "value": "BRKH"
    },
    {
        "label": "MAV - PIONEER MUNICIPAL HIGH INCOME ADVANTAGE FUND, INC.",
        "value": "MAV"
    },
    {
        "label": "STBX - Starbox Group Holdings Ltd.",
        "value": "STBX"
    },
    {
        "label": "CYDY - CytoDyn Inc.",
        "value": "CYDY"
    },
    {
        "label": "INTT - INTEST CORP",
        "value": "INTT"
    },
    {
        "label": "RPMT - REGO PAYMENT ARCHITECTURES, INC.",
        "value": "RPMT"
    },
    {
        "label": "PFMT - Performant Financial Corp",
        "value": "PFMT"
    },
    {
        "label": "IVVD - Invivyd, Inc.",
        "value": "IVVD"
    },
    {
        "label": "CBAN - COLONY BANKCORP INC",
        "value": "CBAN"
    },
    {
        "label": "ACB - AURORA CANNABIS INC",
        "value": "ACB"
    },
    {
        "label": "INBK - First Internet Bancorp",
        "value": "INBK"
    },
    {
        "label": "ALCO - ALICO, INC.",
        "value": "ALCO"
    },
    {
        "label": "PRLD - Prelude Therapeutics Inc",
        "value": "PRLD"
    },
    {
        "label": "SAMG - Silvercrest Asset Management Group Inc.",
        "value": "SAMG"
    },
    {
        "label": "KNOP - KNOT Offshore Partners LP",
        "value": "KNOP"
    },
    {
        "label": "ASRT - Assertio Holdings, Inc.",
        "value": "ASRT"
    },
    {
        "label": "CAPR - CAPRICOR THERAPEUTICS, INC.",
        "value": "CAPR"
    },
    {
        "label": "OXSQ - Oxford Square Capital Corp.",
        "value": "OXSQ"
    },
    {
        "label": "PRST - Presto Automation Inc.",
        "value": "PRST"
    },
    {
        "label": "ITI - ITERIS, INC.",
        "value": "ITI"
    },
    {
        "label": "PDSB - PDS Biotechnology Corp",
        "value": "PDSB"
    },
    {
        "label": "NGM - NGM BIOPHARMACEUTICALS INC",
        "value": "NGM"
    },
    {
        "label": "COFS - CHOICEONE FINANCIAL SERVICES INC",
        "value": "COFS"
    },
    {
        "label": "IKNA - Ikena Oncology, Inc.",
        "value": "IKNA"
    },
    {
        "label": "ALYA - Alithya Group inc",
        "value": "ALYA"
    },
    {
        "label": "FSNB - Fusion Acquisition Corp. II",
        "value": "FSNB"
    },
    {
        "label": "SMLR - Semler Scientific, Inc.",
        "value": "SMLR"
    },
    {
        "label": "XOMA - XOMA Corp",
        "value": "XOMA"
    },
    {
        "label": "IHIT - INVESCO HIGH INCOME 2023 TARGET TERM FUND",
        "value": "IHIT"
    },
    {
        "label": "STHO - Star Holdings",
        "value": "STHO"
    },
    {
        "label": "MIO - Pioneer Municipal High Income Opportunities Fund, Inc.",
        "value": "MIO"
    },
    {
        "label": "LCNB - LCNB CORP",
        "value": "LCNB"
    },
    {
        "label": "RRGB - RED ROBIN GOURMET BURGERS INC",
        "value": "RRGB"
    },
    {
        "label": "VRDR - VERDE RESOURCES, INC.",
        "value": "VRDR"
    },
    {
        "label": "PUCK - Goal Acquisitions Corp.",
        "value": "PUCK"
    },
    {
        "label": "IGI - Western Asset Investment Grade Defined Opportunity Trust Inc.",
        "value": "IGI"
    },
    {
        "label": "PNRG - PRIMEENERGY RESOURCES CORP",
        "value": "PNRG"
    },
    {
        "label": "SILC - SILICOM LTD.",
        "value": "SILC"
    },
    {
        "label": "OPT - Opthea Ltd",
        "value": "OPT"
    },
    {
        "label": "KLXE - KLX Energy Services Holdings, Inc.",
        "value": "KLXE"
    },
    {
        "label": "MNSB - MainStreet Bancshares, Inc.",
        "value": "MNSB"
    },
    {
        "label": "TG - TREDEGAR CORP",
        "value": "TG"
    },
    {
        "label": "FLL - FULL HOUSE RESORTS INC",
        "value": "FLL"
    },
    {
        "label": "HHLA - HH&L Acquisition Co.",
        "value": "HHLA"
    },
    {
        "label": "PCYG - PARK CITY GROUP INC",
        "value": "PCYG"
    },
    {
        "label": "GHM - GRAHAM CORP",
        "value": "GHM"
    },
    {
        "label": "CTRN - Citi Trends Inc",
        "value": "CTRN"
    },
    {
        "label": "CANG - Cango Inc.",
        "value": "CANG"
    },
    {
        "label": "CVCY - CENTRAL VALLEY COMMUNITY BANCORP",
        "value": "CVCY"
    },
    {
        "label": "PPTA - PERPETUA RESOURCES CORP.",
        "value": "PPTA"
    },
    {
        "label": "ESSA - ESSA Bancorp, Inc.",
        "value": "ESSA"
    },
    {
        "label": "MG - Mistras Group, Inc.",
        "value": "MG"
    },
    {
        "label": "EGIO - Edgio, Inc.",
        "value": "EGIO"
    },
    {
        "label": "PZC - PIMCO CALIFORNIA MUNICIPAL INCOME FUND III",
        "value": "PZC"
    },
    {
        "label": "ESEA - EUROSEAS LTD.",
        "value": "ESEA"
    },
    {
        "label": "QBTS - D-Wave Quantum Inc.",
        "value": "QBTS"
    },
    {
        "label": "SNFCA - SECURITY NATIONAL FINANCIAL CORP",
        "value": "SNFCA"
    },
    {
        "label": "BSL - Blackstone Senior Floating Rate 2027 Term Fund",
        "value": "BSL"
    },
    {
        "label": "FT - FRANKLIN UNIVERSAL TRUST",
        "value": "FT"
    },
    {
        "label": "MACK - MERRIMACK PHARMACEUTICALS INC",
        "value": "MACK"
    },
    {
        "label": "LYBC - LYONS BANCORP INC",
        "value": "LYBC"
    },
    {
        "label": "CCLP - CSI Compressco LP",
        "value": "CCLP"
    },
    {
        "label": "SELB - SELECTA BIOSCIENCES INC",
        "value": "SELB"
    },
    {
        "label": "PIIVX - Private Shares Fund",
        "value": "PIIVX"
    },
    {
        "label": "OAKC - Oakworth Capital, Inc.",
        "value": "OAKC"
    },
    {
        "label": "LGST - Semper Paratus Acquisition Corp",
        "value": "LGST"
    },
    {
        "label": "NMG - Nouveau Monde Graphite Inc.",
        "value": "NMG"
    },
    {
        "label": "IFIN - InFinT Acquisition Corp",
        "value": "IFIN"
    },
    {
        "label": "IMMP - IMMUTEP Ltd",
        "value": "IMMP"
    },
    {
        "label": "TEAF - ECOFIN SUSTAINABLE & SOCIAL IMPACT TERM FUND",
        "value": "TEAF"
    },
    {
        "label": "ZVIA - Zevia PBC",
        "value": "ZVIA"
    },
    {
        "label": "VRM - Vroom, Inc.",
        "value": "VRM"
    },
    {
        "label": "IH - iHuman Inc.",
        "value": "IH"
    },
    {
        "label": "LRMR - Larimar Therapeutics, Inc.",
        "value": "LRMR"
    },
    {
        "label": "TDAC - Translational Development Acquisition Corp.",
        "value": "TDAC"
    },
    {
        "label": "WILC - G WILLI FOOD INTERNATIONAL LTD",
        "value": "WILC"
    },
    {
        "label": "ZVRA - ZEVRA THERAPEUTICS, INC.",
        "value": "ZVRA"
    },
    {
        "label": "LYRA - Lyra Therapeutics, Inc.",
        "value": "LYRA"
    },
    {
        "label": "GGT - GABELLI MULTIMEDIA TRUST INC.",
        "value": "GGT"
    },
    {
        "label": "PESI - PERMA FIX ENVIRONMENTAL SERVICES INC",
        "value": "PESI"
    },
    {
        "label": "VBF - Invesco Bond Fund",
        "value": "VBF"
    },
    {
        "label": "GRWG - GrowGeneration Corp.",
        "value": "GRWG"
    },
    {
        "label": "AGAC - African Gold Acquisition Corp",
        "value": "AGAC"
    },
    {
        "label": "NUBI - Nubia Brand International Corp.",
        "value": "NUBI"
    },
    {
        "label": "LTRPA - Liberty TripAdvisor Holdings, Inc.",
        "value": "LTRPA"
    },
    {
        "label": "SKLZ - Skillz Inc.",
        "value": "SKLZ"
    },
    {
        "label": "PBYI - PUMA BIOTECHNOLOGY, INC.",
        "value": "PBYI"
    },
    {
        "label": "FREE - Whole Earth Brands, Inc.",
        "value": "FREE"
    },
    {
        "label": "ENX - EATON VANCE NEW YORK MUNICIPAL BOND FUND",
        "value": "ENX"
    },
    {
        "label": "VAQC - Vector Acquisition Corp II",
        "value": "VAQC"
    },
    {
        "label": "SKYX - SKYX Platforms Corp.",
        "value": "SKYX"
    },
    {
        "label": "EMAN - EMAGIN CORP",
        "value": "EMAN"
    },
    {
        "label": "EACO - EACO CORP",
        "value": "EACO"
    },
    {
        "label": "WKHS - Workhorse Group Inc.",
        "value": "WKHS"
    },
    {
        "label": "RELL - RICHARDSON ELECTRONICS, LTD.",
        "value": "RELL"
    },
    {
        "label": "PRAX - Praxis Precision Medicines, Inc.",
        "value": "PRAX"
    },
    {
        "label": "ADRT - Ault Disruptive Technologies Corp",
        "value": "ADRT"
    },
    {
        "label": "HOLO - MicroCloud Hologram Inc.",
        "value": "HOLO"
    },
    {
        "label": "INSI - Insight Select Income Fund",
        "value": "INSI"
    },
    {
        "label": "ATLO - AMES NATIONAL CORP",
        "value": "ATLO"
    },
    {
        "label": "DIBS - 1stdibs.com, Inc.",
        "value": "DIBS"
    },
    {
        "label": "ADVM - Adverum Biotechnologies, Inc.",
        "value": "ADVM"
    },
    {
        "label": "EVGR - Evergreen Corp",
        "value": "EVGR"
    },
    {
        "label": "MTRX - MATRIX SERVICE CO",
        "value": "MTRX"
    },
    {
        "label": "ATNM - Actinium Pharmaceuticals, Inc.",
        "value": "ATNM"
    },
    {
        "label": "GRIN - Grindrod Shipping Holdings Ltd.",
        "value": "GRIN"
    },
    {
        "label": "PVBC - Provident Bancorp, Inc. /MD/",
        "value": "PVBC"
    },
    {
        "label": "SEER - Seer, Inc.",
        "value": "SEER"
    },
    {
        "label": "BCOV - BRIGHTCOVE INC",
        "value": "BCOV"
    },
    {
        "label": "CTG - COMPUTER TASK GROUP INC",
        "value": "CTG"
    },
    {
        "label": "DCFC - Tritium DCFC Ltd",
        "value": "DCFC"
    },
    {
        "label": "CARA - Cara Therapeutics, Inc.",
        "value": "CARA"
    },
    {
        "label": "FTII - FutureTech II Acquisition Corp.",
        "value": "FTII"
    },
    {
        "label": "RTGN - RetinalGenix Technologies Inc.",
        "value": "RTGN"
    },
    {
        "label": "IPSC - Century Therapeutics, Inc.",
        "value": "IPSC"
    },
    {
        "label": "FLME - Flame Acquisition Corp.",
        "value": "FLME"
    },
    {
        "label": "GDHG - GOLDEN HEAVEN GROUP HOLDINGS LTD.",
        "value": "GDHG"
    },
    {
        "label": "GRX - Gabelli Healthcare & WellnessRx Trust",
        "value": "GRX"
    },
    {
        "label": "SQNS - SEQUANS COMMUNICATIONS",
        "value": "SQNS"
    },
    {
        "label": "NKSH - NATIONAL BANKSHARES INC",
        "value": "NKSH"
    },
    {
        "label": "HYPR - Hyperfine, Inc.",
        "value": "HYPR"
    },
    {
        "label": "BDTX - Black Diamond Therapeutics, Inc.",
        "value": "BDTX"
    },
    {
        "label": "RCMT - RCM TECHNOLOGIES, INC.",
        "value": "RCMT"
    },
    {
        "label": "CCCC - C4 Therapeutics, Inc.",
        "value": "CCCC"
    },
    {
        "label": "TSQ - Townsquare Media, Inc.",
        "value": "TSQ"
    },
    {
        "label": "VCXB - 10X Capital Venture Acquisition Corp. III",
        "value": "VCXB"
    },
    {
        "label": "EHI - WESTERN ASSET GLOBAL HIGH INCOME FUND INC.",
        "value": "EHI"
    },
    {
        "label": "KRNL - Kernel Group Holdings, Inc.",
        "value": "KRNL"
    },
    {
        "label": "CTV - Innovid Corp.",
        "value": "CTV"
    },
    {
        "label": "CFNB - CALIFORNIA FIRST LEASING CORP",
        "value": "CFNB"
    },
    {
        "label": "ALSA - Alpha Star Acquisition Corp",
        "value": "ALSA"
    },
    {
        "label": "CRNT - CERAGON NETWORKS LTD",
        "value": "CRNT"
    },
    {
        "label": "BCSA - Blockchain Coinvestors Acquisition Corp. I",
        "value": "BCSA"
    },
    {
        "label": "DHF - BNY MELLON HIGH YIELD STRATEGIES FUND",
        "value": "DHF"
    },
    {
        "label": "MREO - Mereo Biopharma Group plc",
        "value": "MREO"
    },
    {
        "label": "ROSS - Ross Acquisition Corp II",
        "value": "ROSS"
    },
    {
        "label": "CSTE - Caesarstone Ltd.",
        "value": "CSTE"
    },
    {
        "label": "KYCH - Keyarch Acquisition Corp",
        "value": "KYCH"
    },
    {
        "label": "SYT - SYLA Technologies Co., Ltd.",
        "value": "SYT"
    },
    {
        "label": "EVBN - EVANS BANCORP INC",
        "value": "EVBN"
    },
    {
        "label": "ATOM - Atomera Inc",
        "value": "ATOM"
    },
    {
        "label": "PIM - PUTNAM MASTER INTERMEDIATE INCOME TRUST",
        "value": "PIM"
    },
    {
        "label": "PEPG - PepGen Inc.",
        "value": "PEPG"
    },
    {
        "label": "MPAA - MOTORCAR PARTS OF AMERICA INC",
        "value": "MPAA"
    },
    {
        "label": "LAZY - Lazydays Holdings, Inc.",
        "value": "LAZY"
    },
    {
        "label": "MRCC - MONROE CAPITAL Corp",
        "value": "MRCC"
    },
    {
        "label": "ISBA - ISABELLA BANK Corp",
        "value": "ISBA"
    },
    {
        "label": "NNY - NUVEEN NEW YORK MUNICIPAL VALUE FUND",
        "value": "NNY"
    },
    {
        "label": "SGMO - SANGAMO THERAPEUTICS, INC",
        "value": "SGMO"
    },
    {
        "label": "CBH - Virtus Convertible & Income 2024 Target Term Fund",
        "value": "CBH"
    },
    {
        "label": "CATO - CATO CORP",
        "value": "CATO"
    },
    {
        "label": "CALB - California BanCorp",
        "value": "CALB"
    },
    {
        "label": "IVA - Inventiva S.A.",
        "value": "IVA"
    },
    {
        "label": "LVO - LiveOne, Inc.",
        "value": "LVO"
    },
    {
        "label": "WEL - Integrated Wellness Acquisition Corp",
        "value": "WEL"
    },
    {
        "label": "AFAR - Aura Fat Projects Acquisition Corp",
        "value": "AFAR"
    },
    {
        "label": "APYX - Apyx Medical Corp",
        "value": "APYX"
    },
    {
        "label": "MNMD - Mind Medicine (MindMed) Inc.",
        "value": "MNMD"
    },
    {
        "label": "ANNX - Annexon, Inc.",
        "value": "ANNX"
    },
    {
        "label": "HYB - NEW AMERICA HIGH INCOME FUND INC",
        "value": "HYB"
    },
    {
        "label": "SEVN - Seven Hills Realty Trust",
        "value": "SEVN"
    },
    {
        "label": "HLCO - Healing Co Inc.",
        "value": "HLCO"
    },
    {
        "label": "DBP - Invesco DB Precious Metals Fund",
        "value": "DBP"
    },
    {
        "label": "HBB - Hamilton Beach Brands Holding Co",
        "value": "HBB"
    },
    {
        "label": "AVBH - Avidbank Holdings, Inc.",
        "value": "AVBH"
    },
    {
        "label": "CHEA - Chenghe Acquisition Co.",
        "value": "CHEA"
    },
    {
        "label": "ABSI - Absci Corp",
        "value": "ABSI"
    },
    {
        "label": "ANZU - Anzu Special Acquisition Corp I",
        "value": "ANZU"
    },
    {
        "label": "FEXD - Fintech Ecosystem Development Corp.",
        "value": "FEXD"
    },
    {
        "label": "OPRX - OptimizeRx Corp",
        "value": "OPRX"
    },
    {
        "label": "TGAN - Transphorm, Inc.",
        "value": "TGAN"
    },
    {
        "label": "WMPN - William Penn Bancorporation",
        "value": "WMPN"
    },
    {
        "label": "XBIT - XBiotech Inc.",
        "value": "XBIT"
    },
    {
        "label": "AJX - Great Ajax Corp.",
        "value": "AJX"
    },
    {
        "label": "SHLT - SHL TELEMEDICINE LTD",
        "value": "SHLT"
    },
    {
        "label": "ROCL - Roth CH Acquisition V Co.",
        "value": "ROCL"
    },
    {
        "label": "HCMA - HCM Acquisition Corp",
        "value": "HCMA"
    },
    {
        "label": "BNR - Burning Rock Biotech Ltd",
        "value": "BNR"
    },
    {
        "label": "GRTS - Gritstone bio, Inc.",
        "value": "GRTS"
    },
    {
        "label": "MCN - Madison Covered Call & Equity Strategy Fund",
        "value": "MCN"
    },
    {
        "label": "KOPN - KOPIN CORP",
        "value": "KOPN"
    },
    {
        "label": "EFHT - EF Hutton Acquisition Corp I",
        "value": "EFHT"
    },
    {
        "label": "CIA - CITIZENS, INC.",
        "value": "CIA"
    },
    {
        "label": "ATRA - Atara Biotherapeutics, Inc.",
        "value": "ATRA"
    },
    {
        "label": "TGVC - TG Venture Acquisition Corp.",
        "value": "TGVC"
    },
    {
        "label": "GF - NEW GERMANY FUND INC",
        "value": "GF"
    },
    {
        "label": "IDE - Voya Infrastructure, Industrials & Materials Fund",
        "value": "IDE"
    },
    {
        "label": "JYNT - JOINT Corp",
        "value": "JYNT"
    },
    {
        "label": "YTRA - Yatra Online, Inc.",
        "value": "YTRA"
    },
    {
        "label": "NSTD - Northern Star Investment Corp. IV",
        "value": "NSTD"
    },
    {
        "label": "MBAC - M3-Brigade Acquisition II Corp.",
        "value": "MBAC"
    },
    {
        "label": "ELA - Envela Corp",
        "value": "ELA"
    },
    {
        "label": "XTNT - Xtant Medical Holdings, Inc.",
        "value": "XTNT"
    },
    {
        "label": "JSPR - Jasper Therapeutics, Inc.",
        "value": "JSPR"
    },
    {
        "label": "LHC - Leo Holdings Corp. II",
        "value": "LHC"
    },
    {
        "label": "BLZE - Backblaze, Inc.",
        "value": "BLZE"
    },
    {
        "label": "CIK - CREDIT SUISSE ASSET MANAGEMENT INCOME FUND INC",
        "value": "CIK"
    },
    {
        "label": "BMN - BlackRock 2037 Municipal Target Term Trust",
        "value": "BMN"
    },
    {
        "label": "POET - POET TECHNOLOGIES INC.",
        "value": "POET"
    },
    {
        "label": "GLDG - GoldMining Inc.",
        "value": "GLDG"
    },
    {
        "label": "OPBK - OP Bancorp",
        "value": "OPBK"
    },
    {
        "label": "SACH - Sachem Capital Corp.",
        "value": "SACH"
    },
    {
        "label": "FPL - FIRST TRUST NEW OPPORTUNITIES MLP & ENERGY FUND",
        "value": "FPL"
    },
    {
        "label": "FTCO - Fortitude Gold Corp",
        "value": "FTCO"
    },
    {
        "label": "ARC - ARC DOCUMENT SOLUTIONS, INC.",
        "value": "ARC"
    },
    {
        "label": "CDRO - Codere Online Luxembourg, S.A.",
        "value": "CDRO"
    },
    {
        "label": "GRPH - Graphite Bio, Inc.",
        "value": "GRPH"
    },
    {
        "label": "ALCY - Alchemy Investments Acquisition Corp 1",
        "value": "ALCY"
    },
    {
        "label": "FLC - FLAHERTY & CRUMRINE TOTAL RETURN FUND INC",
        "value": "FLC"
    },
    {
        "label": "SOPH - SOPHiA GENETICS SA",
        "value": "SOPH"
    },
    {
        "label": "LCUT - LIFETIME BRANDS, INC",
        "value": "LCUT"
    },
    {
        "label": "NSTC - Northern Star Investment Corp. III",
        "value": "NSTC"
    },
    {
        "label": "KGEIF - Kolibri Global Energy Inc.",
        "value": "KGEIF"
    },
    {
        "label": "DBE - Invesco DB Energy Fund",
        "value": "DBE"
    },
    {
        "label": "NHS - Neuberger Berman High Yield Strategies Fund Inc.",
        "value": "NHS"
    },
    {
        "label": "BRBS - BLUE RIDGE BANKSHARES, INC.",
        "value": "BRBS"
    },
    {
        "label": "DLHC - DLH Holdings Corp.",
        "value": "DLHC"
    },
    {
        "label": "DNIF - DIVIDEND & INCOME FUND",
        "value": "DNIF"
    },
    {
        "label": "MPA - BLACKROCK MUNIYIELD PENNSYLVANIA QUALITY FUND",
        "value": "MPA"
    },
    {
        "label": "LOOP - Loop Industries, Inc.",
        "value": "LOOP"
    },
    {
        "label": "QRHC - Quest Resource Holding Corp",
        "value": "QRHC"
    },
    {
        "label": "MPV - BARINGS PARTICIPATION INVESTORS",
        "value": "MPV"
    },
    {
        "label": "BGX - Blackstone Long-Short Credit Income Fund",
        "value": "BGX"
    },
    {
        "label": "MVO - MV Oil Trust",
        "value": "MVO"
    },
    {
        "label": "FOA - Finance of America Companies Inc.",
        "value": "FOA"
    },
    {
        "label": "INGN - Inogen Inc",
        "value": "INGN"
    },
    {
        "label": "BHIL - Benson Hill, Inc.",
        "value": "BHIL"
    },
    {
        "label": "SATL - Satellogic Inc.",
        "value": "SATL"
    },
    {
        "label": "SEEL - SEELOS THERAPEUTICS, INC.",
        "value": "SEEL"
    },
    {
        "label": "OCEA - Ocean Biomedical, Inc.",
        "value": "OCEA"
    },
    {
        "label": "MIXT - MiX Telematics Ltd",
        "value": "MIXT"
    },
    {
        "label": "VOC - VOC Energy Trust",
        "value": "VOC"
    },
    {
        "label": "MOGO - Mogo Inc.",
        "value": "MOGO"
    },
    {
        "label": "BPT - BP PRUDHOE BAY ROYALTY TRUST",
        "value": "BPT"
    },
    {
        "label": "LBPH - Longboard Pharmaceuticals, Inc.",
        "value": "LBPH"
    },
    {
        "label": "SMLP - Summit Midstream Partners, LP",
        "value": "SMLP"
    },
    {
        "label": "LTRX - LANTRONIX INC",
        "value": "LTRX"
    },
    {
        "label": "ARMP - Armata Pharmaceuticals, Inc.",
        "value": "ARMP"
    },
    {
        "label": "LCA - Landcadia Holdings IV, Inc.",
        "value": "LCA"
    },
    {
        "label": "AADI - Aadi Bioscience, Inc.",
        "value": "AADI"
    },
    {
        "label": "CTXR - Citius Pharmaceuticals, Inc.",
        "value": "CTXR"
    },
    {
        "label": "TLS - TELOS CORP",
        "value": "TLS"
    },
    {
        "label": "NINE - Nine Energy Service, Inc.",
        "value": "NINE"
    },
    {
        "label": "ISSC - INNOVATIVE SOLUTIONS & SUPPORT INC",
        "value": "ISSC"
    },
    {
        "label": "NRO - NEUBERGER BERMAN REAL ESTATE SECURITIES INCOME FUND INC",
        "value": "NRO"
    },
    {
        "label": "TLGY - TLGY ACQUISITION CORP",
        "value": "TLGY"
    },
    {
        "label": "OPTN - OptiNose, Inc.",
        "value": "OPTN"
    },
    {
        "label": "NDLS - NOODLES & Co",
        "value": "NDLS"
    },
    {
        "label": "PLMI - Plum Acquisition Corp. I",
        "value": "PLMI"
    },
    {
        "label": "ZTEK - Zentek Ltd.",
        "value": "ZTEK"
    },
    {
        "label": "MGRM - MONOGRAM ORTHOPAEDICS INC",
        "value": "MGRM"
    },
    {
        "label": "INMB - Inmune Bio, Inc.",
        "value": "INMB"
    },
    {
        "label": "WNEB - Western New England Bancorp, Inc.",
        "value": "WNEB"
    },
    {
        "label": "MOBV - Mobiv Acquisition Corp",
        "value": "MOBV"
    },
    {
        "label": "RDCM - RADCOM LTD",
        "value": "RDCM"
    },
    {
        "label": "OFS - OFS Capital Corp",
        "value": "OFS"
    },
    {
        "label": "HRTG - Heritage Insurance Holdings, Inc.",
        "value": "HRTG"
    },
    {
        "label": "CTGO - Contango ORE, Inc.",
        "value": "CTGO"
    },
    {
        "label": "BSET - BASSETT FURNITURE INDUSTRIES INC",
        "value": "BSET"
    },
    {
        "label": "CWGL - Crimson Wine Group, Ltd",
        "value": "CWGL"
    },
    {
        "label": "GAU - Galiano Gold Inc.",
        "value": "GAU"
    },
    {
        "label": "SGC - SUPERIOR GROUP OF COMPANIES, INC.",
        "value": "SGC"
    },
    {
        "label": "SAMA - Schultze Special Purpose Acquisition Corp. II",
        "value": "SAMA"
    },
    {
        "label": "HURC - HURCO COMPANIES INC",
        "value": "HURC"
    },
    {
        "label": "PLX - Protalix BioTherapeutics, Inc.",
        "value": "PLX"
    },
    {
        "label": "NKTR - NEKTAR THERAPEUTICS",
        "value": "NKTR"
    },
    {
        "label": "FNRN - FIRST NORTHERN COMMUNITY BANCORP",
        "value": "FNRN"
    },
    {
        "label": "CRGE - Charge Enterprises, Inc.",
        "value": "CRGE"
    },
    {
        "label": "AENZ - AENZA S.A.A.",
        "value": "AENZ"
    },
    {
        "label": "PTRS - PARTNERS BANCORP",
        "value": "PTRS"
    },
    {
        "label": "MRSN - Mersana Therapeutics, Inc.",
        "value": "MRSN"
    },
    {
        "label": "STIX - Semantix, Inc.",
        "value": "STIX"
    },
    {
        "label": "IPX - IPERIONX Ltd",
        "value": "IPX"
    },
    {
        "label": "PIAI - Prime Impact Acquisition I",
        "value": "PIAI"
    },
    {
        "label": "RMGC - RMG Acquisition Corp. III",
        "value": "RMGC"
    },
    {
        "label": "MHF - WESTERN ASSET MUNICIPAL HIGH INCOME FUND INC.",
        "value": "MHF"
    },
    {
        "label": "FSRX - Finserv Acquisition Corp. II",
        "value": "FSRX"
    },
    {
        "label": "CAAS - CHINA AUTOMOTIVE SYSTEMS INC",
        "value": "CAAS"
    },
    {
        "label": "FLFV - Feutune Light Acquisition Corp",
        "value": "FLFV"
    },
    {
        "label": "FRXB - Forest Road Acquisition Corp. II",
        "value": "FRXB"
    },
    {
        "label": "BBBYQ - BED BATH & BEYOND INC",
        "value": "BBBYQ"
    },
    {
        "label": "IOAC - Innovative International Acquisition Corp.",
        "value": "IOAC"
    },
    {
        "label": "TWLV - Twelve Seas Investment Co. II",
        "value": "TWLV"
    },
    {
        "label": "NAK - NORTHERN DYNASTY MINERALS LTD",
        "value": "NAK"
    },
    {
        "label": "UFI - UNIFI INC",
        "value": "UFI"
    },
    {
        "label": "LITB - LightInTheBox Holding Co., Ltd.",
        "value": "LITB"
    },
    {
        "label": "BWG - BrandywineGLOBAL-Global Income Opportunities Fund Inc",
        "value": "BWG"
    },
    {
        "label": "FEAM - 5E Advanced Materials, Inc.",
        "value": "FEAM"
    },
    {
        "label": "FZT - FAST Acquisition Corp. II",
        "value": "FZT"
    },
    {
        "label": "ESPR - Esperion Therapeutics, Inc.",
        "value": "ESPR"
    },
    {
        "label": "ARQQ - Arqit Quantum Inc.",
        "value": "ARQQ"
    },
    {
        "label": "EVG - Eaton Vance Short Duration Diversified Income Fund",
        "value": "EVG"
    },
    {
        "label": "MRBK - Meridian Corp",
        "value": "MRBK"
    },
    {
        "label": "FREVS - FIRST REAL ESTATE INVESTMENT TRUST OF NEW JERSEY",
        "value": "FREVS"
    },
    {
        "label": "GDLC - Grayscale Digital Large Cap Fund LLC",
        "value": "GDLC"
    },
    {
        "label": "ALT - Altimmune, Inc.",
        "value": "ALT"
    },
    {
        "label": "ABXXF - Abaxx Technologies Inc.",
        "value": "ABXXF"
    },
    {
        "label": "FTHM - Fathom Holdings Inc.",
        "value": "FTHM"
    },
    {
        "label": "WPRT - WESTPORT FUEL SYSTEMS INC.",
        "value": "WPRT"
    },
    {
        "label": "HNVR - Hanover Bancorp, Inc. /NY",
        "value": "HNVR"
    },
    {
        "label": "KOD - Kodiak Sciences Inc.",
        "value": "KOD"
    },
    {
        "label": "CRGO - Freightos Ltd",
        "value": "CRGO"
    },
    {
        "label": "HNST - Honest Company, Inc.",
        "value": "HNST"
    },
    {
        "label": "CNDB - Concord Acquisition Corp III",
        "value": "CNDB"
    },
    {
        "label": "RCKY - ROCKY BRANDS, INC.",
        "value": "RCKY"
    },
    {
        "label": "OCFT - ONECONNECT FINANCIAL TECHNOLOGY CO., LTD.",
        "value": "OCFT"
    },
    {
        "label": "ZFOX - ZeroFox Holdings, Inc.",
        "value": "ZFOX"
    },
    {
        "label": "NSTG - NanoString Technologies Inc",
        "value": "NSTG"
    },
    {
        "label": "ULBI - ULTRALIFE CORP",
        "value": "ULBI"
    },
    {
        "label": "FCCO - FIRST COMMUNITY CORP /SC/",
        "value": "FCCO"
    },
    {
        "label": "LGVC - LAMF Global Ventures Corp. I",
        "value": "LGVC"
    },
    {
        "label": "SGA - SAGA COMMUNICATIONS INC",
        "value": "SGA"
    },
    {
        "label": "CSTA - Constellation Acquisition Corp I",
        "value": "CSTA"
    },
    {
        "label": "FINW - Finwise Bancorp",
        "value": "FINW"
    },
    {
        "label": "EZGO - EZGO Technologies Ltd.",
        "value": "EZGO"
    },
    {
        "label": "MUA - BLACKROCK MUNIASSETS FUND, INC.",
        "value": "MUA"
    },
    {
        "label": "FGBI - First Guaranty Bancshares, Inc.",
        "value": "FGBI"
    },
    {
        "label": "EPIX - ESSA Pharma Inc.",
        "value": "EPIX"
    },
    {
        "label": "GMDA - Gamida Cell Ltd.",
        "value": "GMDA"
    },
    {
        "label": "ISTR - Investar Holding Corp",
        "value": "ISTR"
    },
    {
        "label": "HEQ - John Hancock Hedged Equity & Income Fund",
        "value": "HEQ"
    },
    {
        "label": "IGA - Voya Global Advantage & Premium Opportunity Fund",
        "value": "IGA"
    },
    {
        "label": "SPE - SPECIAL OPPORTUNITIES FUND, INC.",
        "value": "SPE"
    },
    {
        "label": "RMBI - Richmond Mutual Bancorporation, Inc.",
        "value": "RMBI"
    },
    {
        "label": "GFX - Golden Falcon Acquisition Corp.",
        "value": "GFX"
    },
    {
        "label": "FRAF - FRANKLIN FINANCIAL SERVICES CORP /PA/",
        "value": "FRAF"
    },
    {
        "label": "MSD - MORGAN STANLEY EMERGING MARKETS DEBT FUND INC",
        "value": "MSD"
    },
    {
        "label": "LGYV - LEGACY VENTURES INTERNATIONAL INC.",
        "value": "LGYV"
    },
    {
        "label": "LOMLF - Lion One Metals Ltd.",
        "value": "LOMLF"
    },
    {
        "label": "TCS - Container Store Group, Inc.",
        "value": "TCS"
    },
    {
        "label": "RMMZ - RiverNorth Managed Duration Municipal Income Fund II, Inc.",
        "value": "RMMZ"
    },
    {
        "label": "BIVI - BIOVIE INC.",
        "value": "BIVI"
    },
    {
        "label": "CLPT - ClearPoint Neuro, Inc.",
        "value": "CLPT"
    },
    {
        "label": "FXY - Invesco CurrencyShares Japanese Yen Trust",
        "value": "FXY"
    },
    {
        "label": "PRDS - PARDES BIOSCIENCES, INC.",
        "value": "PRDS"
    },
    {
        "label": "WISH - ContextLogic Inc.",
        "value": "WISH"
    },
    {
        "label": "VATE - INNOVATE Corp.",
        "value": "VATE"
    },
    {
        "label": "PNRLF - Premium Nickel Resources Ltd.",
        "value": "PNRLF"
    },
    {
        "label": "AKTS - Akoustis Technologies, Inc.",
        "value": "AKTS"
    },
    {
        "label": "LFMD - LifeMD, Inc.",
        "value": "LFMD"
    },
    {
        "label": "NGS - NATURAL GAS SERVICES GROUP INC",
        "value": "NGS"
    },
    {
        "label": "ELVA - Electrovaya Inc.",
        "value": "ELVA"
    },
    {
        "label": "DIT - AMCON DISTRIBUTING CO",
        "value": "DIT"
    },
    {
        "label": "BTAI - BioXcel Therapeutics, Inc.",
        "value": "BTAI"
    },
    {
        "label": "ACAQ - Athena Consumer Acquisition Corp.",
        "value": "ACAQ"
    },
    {
        "label": "TALS - Talaris Therapeutics, Inc.",
        "value": "TALS"
    },
    {
        "label": "BTA - BlackRock Long-Term Municipal Advantage Trust",
        "value": "BTA"
    },
    {
        "label": "PHX - PHX MINERALS INC.",
        "value": "PHX"
    },
    {
        "label": "DHCA - DHC Acquisition Corp.",
        "value": "DHCA"
    },
    {
        "label": "DMF - BNY MELLON MUNICIPAL INCOME, INC.",
        "value": "DMF"
    },
    {
        "label": "FDOC - FUEL DOCTOR HOLDINGS, INC.",
        "value": "FDOC"
    },
    {
        "label": "GLSI - Greenwich LifeSciences, Inc.",
        "value": "GLSI"
    },
    {
        "label": "ESHA - ESH Acquisition Corp.",
        "value": "ESHA"
    },
    {
        "label": "WE - WeWork Inc.",
        "value": "WE"
    },
    {
        "label": "ATEK - Athena Technology Acquisition Corp. II",
        "value": "ATEK"
    },
    {
        "label": "THTX - Theratechnologies Inc.",
        "value": "THTX"
    },
    {
        "label": "GLST - Global Star Acquisition Inc.",
        "value": "GLST"
    },
    {
        "label": "YERBF - YERBAE BRANDS CORP.",
        "value": "YERBF"
    },
    {
        "label": "JUN - Juniper II Corp.",
        "value": "JUN"
    },
    {
        "label": "CUE - Cue Biopharma, Inc.",
        "value": "CUE"
    },
    {
        "label": "TRVI - Trevi Therapeutics, Inc.",
        "value": "TRVI"
    },
    {
        "label": "TBCP - Thunder Bridge Capital Partners III Inc.",
        "value": "TBCP"
    },
    {
        "label": "ONCY - ONCOLYTICS BIOTECH INC",
        "value": "ONCY"
    },
    {
        "label": "LL - LL Flooring Holdings, Inc.",
        "value": "LL"
    },
    {
        "label": "CRT - CROSS TIMBERS ROYALTY TRUST",
        "value": "CRT"
    },
    {
        "label": "BMAC - Black Mountain Acquisition Corp.",
        "value": "BMAC"
    },
    {
        "label": "ROOT - Root, Inc.",
        "value": "ROOT"
    },
    {
        "label": "GMFI - Aetherium Acquisition Corp",
        "value": "GMFI"
    },
    {
        "label": "VCIG - VCI Global Ltd",
        "value": "VCIG"
    },
    {
        "label": "GROV - Grove Collaborative Holdings, Inc.",
        "value": "GROV"
    },
    {
        "label": "TSHA - Taysha Gene Therapies, Inc.",
        "value": "TSHA"
    },
    {
        "label": "SANG - Sangoma Technologies Corp",
        "value": "SANG"
    },
    {
        "label": "WTMA - Welsbach Technology Metals Acquisition Corp.",
        "value": "WTMA"
    },
    {
        "label": "LWAY - Lifeway Foods, Inc.",
        "value": "LWAY"
    },
    {
        "label": "MITT - AG Mortgage Investment Trust, Inc.",
        "value": "MITT"
    },
    {
        "label": "ACAH - Atlantic Coastal Acquisition Corp.",
        "value": "ACAH"
    },
    {
        "label": "FNWB - First Northwest Bancorp",
        "value": "FNWB"
    },
    {
        "label": "OGI - ORGANIGRAM HOLDINGS INC.",
        "value": "OGI"
    },
    {
        "label": "GATE - Marblegate Acquisition Corp.",
        "value": "GATE"
    },
    {
        "label": "PFD - FLAHERTY & CRUMRINE PREFERRED & INCOME FUND INC",
        "value": "PFD"
    },
    {
        "label": "DBTX - Decibel Therapeutics, Inc.",
        "value": "DBTX"
    },
    {
        "label": "MHGU - MERITAGE HOSPITALITY GROUP INC",
        "value": "MHGU"
    },
    {
        "label": "AHI - Advanced Health Intelligence Ltd",
        "value": "AHI"
    },
    {
        "label": "PRTK - Paratek Pharmaceuticals, Inc.",
        "value": "PRTK"
    },
    {
        "label": "REEMF - RARE ELEMENT RESOURCES LTD",
        "value": "REEMF"
    },
    {
        "label": "FGEN - FIBROGEN INC",
        "value": "FGEN"
    },
    {
        "label": "WGS - GeneDx Holdings Corp.",
        "value": "WGS"
    },
    {
        "label": "KRMD - KORU Medical Systems, Inc.",
        "value": "KRMD"
    },
    {
        "label": "AAIC - Arlington Asset Investment Corp.",
        "value": "AAIC"
    },
    {
        "label": "BGXX - Bright Green Corp",
        "value": "BGXX"
    },
    {
        "label": "USAP - UNIVERSAL STAINLESS & ALLOY PRODUCTS INC",
        "value": "USAP"
    },
    {
        "label": "DTI - Drilling Tools International Corp",
        "value": "DTI"
    },
    {
        "label": "WEA - WESTERN ASSET PREMIER BOND FUND",
        "value": "WEA"
    },
    {
        "label": "AOUT - American Outdoor Brands, Inc.",
        "value": "AOUT"
    },
    {
        "label": "GPAC - Global Partner Acquisition Corp II",
        "value": "GPAC"
    },
    {
        "label": "THRX - Theseus Pharmaceuticals, Inc.",
        "value": "THRX"
    },
    {
        "label": "RVSB - RIVERVIEW BANCORP INC",
        "value": "RVSB"
    },
    {
        "label": "MCAC - Monterey Capital Acquisition Corp",
        "value": "MCAC"
    },
    {
        "label": "JHS - JOHN HANCOCK INCOME SECURITIES TRUST",
        "value": "JHS"
    },
    {
        "label": "EZOO - Ezagoo Ltd",
        "value": "EZOO"
    },
    {
        "label": "STXS - Stereotaxis, Inc.",
        "value": "STXS"
    },
    {
        "label": "DMO - Western Asset Mortgage Opportunity Fund Inc.",
        "value": "DMO"
    },
    {
        "label": "ORMP - ORAMED PHARMACEUTICALS INC.",
        "value": "ORMP"
    },
    {
        "label": "ACBM - ACRO BIOMEDICAL CO., LTD.",
        "value": "ACBM"
    },
    {
        "label": "NSTB - Northern Star Investment Corp. II",
        "value": "NSTB"
    },
    {
        "label": "BYTS - BYTE Acquisition Corp.",
        "value": "BYTS"
    },
    {
        "label": "FIAC - Focus Impact Acquisition Corp.",
        "value": "FIAC"
    },
    {
        "label": "MHH - Mastech Digital, Inc.",
        "value": "MHH"
    },
    {
        "label": "OPA - Magnum Opus Acquisition Ltd",
        "value": "OPA"
    },
    {
        "label": "NPAB - New Providence Acquisition Corp. II",
        "value": "NPAB"
    },
    {
        "label": "TMNA - AGRI-FINTECH HOLDINGS, INC.",
        "value": "TMNA"
    },
    {
        "label": "SY - So-Young International Inc.",
        "value": "SY"
    },
    {
        "label": "NB - NIOCORP DEVELOPMENTS LTD",
        "value": "NB"
    },
    {
        "label": "ARRW - Arrowroot Acquisition Corp.",
        "value": "ARRW"
    },
    {
        "label": "GNSS - Genasys Inc.",
        "value": "GNSS"
    },
    {
        "label": "ASYS - AMTECH SYSTEMS INC",
        "value": "ASYS"
    },
    {
        "label": "FNCB - FNCB Bancorp, Inc.",
        "value": "FNCB"
    },
    {
        "label": "SJ - Scienjoy Holding Corp",
        "value": "SJ"
    },
    {
        "label": "PRQR - ProQR Therapeutics N.V.",
        "value": "PRQR"
    },
    {
        "label": "SRT - Startek, Inc.",
        "value": "SRT"
    },
    {
        "label": "TRCA - Twin Ridge Capital Acquisition Corp.",
        "value": "TRCA"
    },
    {
        "label": "DSAQ - Direct Selling Acquisition Corp.",
        "value": "DSAQ"
    },
    {
        "label": "FAT - Fat Brands, Inc",
        "value": "FAT"
    },
    {
        "label": "USGO - U.S. GoldMining Inc.",
        "value": "USGO"
    },
    {
        "label": "GRRR - Gorilla Technology Group Inc.",
        "value": "GRRR"
    },
    {
        "label": "SPRU - SPRUCE POWER HOLDING CORP",
        "value": "SPRU"
    },
    {
        "label": "UBFO - UNITED SECURITY BANCSHARES",
        "value": "UBFO"
    },
    {
        "label": "COOL - Corner Growth Acquisition Corp.",
        "value": "COOL"
    },
    {
        "label": "EPSN - Epsilon Energy Ltd.",
        "value": "EPSN"
    },
    {
        "label": "PLG - PLATINUM GROUP METALS LTD",
        "value": "PLG"
    },
    {
        "label": "ADTH - AdTheorent Holding Company, Inc.",
        "value": "ADTH"
    },
    {
        "label": "NAZ - NUVEEN ARIZONA QUALITY MUNICIPAL INCOME FUND",
        "value": "NAZ"
    },
    {
        "label": "HWBK - HAWTHORN BANCSHARES, INC.",
        "value": "HWBK"
    },
    {
        "label": "CTSO - Cytosorbents Corp",
        "value": "CTSO"
    },
    {
        "label": "BYN - Banyan Acquisition Corp",
        "value": "BYN"
    },
    {
        "label": "ECF - ELLSWORTH GROWTH & INCOME FUND LTD",
        "value": "ECF"
    },
    {
        "label": "BCAB - BioAtla, Inc.",
        "value": "BCAB"
    },
    {
        "label": "DGWR - Deep Green Waste & Recycling, Inc.",
        "value": "DGWR"
    },
    {
        "label": "MYNA - Mynaric AG",
        "value": "MYNA"
    },
    {
        "label": "FCAP - FIRST CAPITAL INC",
        "value": "FCAP"
    },
    {
        "label": "DSGN - Design Therapeutics, Inc.",
        "value": "DSGN"
    },
    {
        "label": "BRAG - Bragg Gaming Group Inc.",
        "value": "BRAG"
    },
    {
        "label": "UEIC - UNIVERSAL ELECTRONICS INC",
        "value": "UEIC"
    },
    {
        "label": "USCT - TKB Critical Technologies 1",
        "value": "USCT"
    },
    {
        "label": "FMN - Federated Hermes Premier Municipal Income Fund",
        "value": "FMN"
    },
    {
        "label": "BANX - ArrowMark Financial Corp.",
        "value": "BANX"
    },
    {
        "label": "INO - INOVIO PHARMACEUTICALS, INC.",
        "value": "INO"
    },
    {
        "label": "CFBK - CF BANKSHARES INC.",
        "value": "CFBK"
    },
    {
        "label": "ONYX - Onyx Acquisition Co. I",
        "value": "ONYX"
    },
    {
        "label": "BDSX - BIODESIX INC",
        "value": "BDSX"
    },
    {
        "label": "APXI - APx Acquisition Corp. I",
        "value": "APXI"
    },
    {
        "label": "VFL - abrdn National Municipal Income Fund",
        "value": "VFL"
    },
    {
        "label": "OVBC - OHIO VALLEY BANC CORP",
        "value": "OVBC"
    },
    {
        "label": "TRX - TRX GOLD Corp",
        "value": "TRX"
    },
    {
        "label": "DECA - Denali Capital Acquisition Corp.",
        "value": "DECA"
    },
    {
        "label": "LTCH - Latch, Inc.",
        "value": "LTCH"
    },
    {
        "label": "BCTX - BriaCell Therapeutics Corp.",
        "value": "BCTX"
    },
    {
        "label": "PCF - HIGH INCOME SECURITIES FUND",
        "value": "PCF"
    },
    {
        "label": "BEEM - Beam Global",
        "value": "BEEM"
    },
    {
        "label": "CRKN - Crown Electrokinetics Corp.",
        "value": "CRKN"
    },
    {
        "label": "NRT - NORTH EUROPEAN OIL ROYALTY TRUST",
        "value": "NRT"
    },
    {
        "label": "HGBL - Heritage Global Inc.",
        "value": "HGBL"
    },
    {
        "label": "BKKT - Bakkt Holdings, Inc.",
        "value": "BKKT"
    },
    {
        "label": "CWBC - COMMUNITY WEST BANCSHARES /",
        "value": "CWBC"
    },
    {
        "label": "ADCT - ADC Therapeutics SA",
        "value": "ADCT"
    },
    {
        "label": "DCF - BNY Mellon Alcentra Global Credit Income 2024 Target Term Fund, Inc.",
        "value": "DCF"
    },
    {
        "label": "PYR - PyroGenesis Canada Inc.",
        "value": "PYR"
    },
    {
        "label": "ADER - 26 Capital Acquisition Corp.",
        "value": "ADER"
    },
    {
        "label": "CPTK - Crown PropTech Acquisitions",
        "value": "CPTK"
    },
    {
        "label": "SZZL - Sizzle Acquisition Corp.",
        "value": "SZZL"
    },
    {
        "label": "ATAK - Aurora Technology Acquisition Corp.",
        "value": "ATAK"
    },
    {
        "label": "NTIC - NORTHERN TECHNOLOGIES INTERNATIONAL CORP",
        "value": "NTIC"
    },
    {
        "label": "ACAB - Atlantic Coastal Acquisition Corp. II",
        "value": "ACAB"
    },
    {
        "label": "CHN - CHINA FUND INC",
        "value": "CHN"
    },
    {
        "label": "EMYB - Embassy Bancorp, Inc.",
        "value": "EMYB"
    },
    {
        "label": "SOS - SOS Ltd",
        "value": "SOS"
    },
    {
        "label": "FCUV - FOCUS UNIVERSAL INC.",
        "value": "FCUV"
    },
    {
        "label": "RNLX - Renalytix plc",
        "value": "RNLX"
    },
    {
        "label": "GEOS - GEOSPACE TECHNOLOGIES CORP",
        "value": "GEOS"
    },
    {
        "label": "VII - 7GC & Co. Holdings Inc.",
        "value": "VII"
    },
    {
        "label": "CDXS - CODEXIS, INC.",
        "value": "CDXS"
    },
    {
        "label": "KLDI - KLDiscovery Inc.",
        "value": "KLDI"
    },
    {
        "label": "GLQ - Clough Global Equity Fund",
        "value": "GLQ"
    },
    {
        "label": "EML - EASTERN CO",
        "value": "EML"
    },
    {
        "label": "ACU - ACME UNITED CORP",
        "value": "ACU"
    },
    {
        "label": "PEBK - PEOPLES BANCORP OF NORTH CAROLINA INC",
        "value": "PEBK"
    },
    {
        "label": "MNOV - MEDICINOVA INC",
        "value": "MNOV"
    },
    {
        "label": "ANVS - Annovis Bio, Inc.",
        "value": "ANVS"
    },
    {
        "label": "LFT - Lument Finance Trust, Inc.",
        "value": "LFT"
    },
    {
        "label": "LNKB - LINKBANCORP, Inc.",
        "value": "LNKB"
    },
    {
        "label": "FSFG - First Savings Financial Group, Inc.",
        "value": "FSFG"
    },
    {
        "label": "OCGN - Ocugen, Inc.",
        "value": "OCGN"
    },
    {
        "label": "BHAC - Crixus BH3 Acquisition Co",
        "value": "BHAC"
    },
    {
        "label": "MLEC - Moolec Science SA",
        "value": "MLEC"
    },
    {
        "label": "BODY - Beachbody Company, Inc.",
        "value": "BODY"
    },
    {
        "label": "BSBK - Bogota Financial Corp.",
        "value": "BSBK"
    },
    {
        "label": "YGF - YanGuFang International Group Co., Ltd",
        "value": "YGF"
    },
    {
        "label": "FKYS - FIRST KEYSTONE CORP",
        "value": "FKYS"
    },
    {
        "label": "ATOS - ATOSSA THERAPEUTICS, INC.",
        "value": "ATOS"
    },
    {
        "label": "CDXC - ChromaDex Corp.",
        "value": "CDXC"
    },
    {
        "label": "BGSF - BGSF, INC.",
        "value": "BGSF"
    },
    {
        "label": "IXAQ - IX Acquisition Corp.",
        "value": "IXAQ"
    },
    {
        "label": "JHI - JOHN HANCOCK INVESTORS TRUST",
        "value": "JHI"
    },
    {
        "label": "ENER - ACCRETION ACQUISITION CORP.",
        "value": "ENER"
    },
    {
        "label": "CBFV - CB Financial Services, Inc.",
        "value": "CBFV"
    },
    {
        "label": "AHT - ASHFORD HOSPITALITY TRUST INC",
        "value": "AHT"
    },
    {
        "label": "CXE - MFS HIGH INCOME MUNICIPAL TRUST",
        "value": "CXE"
    },
    {
        "label": "FFNW - First Financial Northwest, Inc.",
        "value": "FFNW"
    },
    {
        "label": "MNP - WESTERN ASSET MUNICIPAL PARTNERS FUND INC.",
        "value": "MNP"
    },
    {
        "label": "QFTA - Quantum FinTech Acquisition Corp",
        "value": "QFTA"
    },
    {
        "label": "RBOT - Vicarious Surgical Inc.",
        "value": "RBOT"
    },
    {
        "label": "KACL - Kairous Acquisition Corp. Ltd",
        "value": "KACL"
    },
    {
        "label": "XFIN - ExcelFin Acquisition Corp.",
        "value": "XFIN"
    },
    {
        "label": "NIM - NUVEEN SELECT MATURITIES MUNICIPAL FUND",
        "value": "NIM"
    },
    {
        "label": "PHD - Pioneer Floating Rate Fund, Inc.",
        "value": "PHD"
    },
    {
        "label": "XNET - Xunlei Ltd",
        "value": "XNET"
    },
    {
        "label": "CPHC - Canterbury Park Holding Corp",
        "value": "CPHC"
    },
    {
        "label": "SMID - SMITH MIDLAND CORP",
        "value": "SMID"
    },
    {
        "label": "BATL - BATTALION OIL CORP",
        "value": "BATL"
    },
    {
        "label": "RBT - Rubicon Technologies, Inc.",
        "value": "RBT"
    },
    {
        "label": "FRD - FRIEDMAN INDUSTRIES INC",
        "value": "FRD"
    },
    {
        "label": "TORO - TORO CORP.",
        "value": "TORO"
    },
    {
        "label": "FONR - FONAR CORP",
        "value": "FONR"
    },
    {
        "label": "WWAC - Worldwide Webb Acquisition Corp.",
        "value": "WWAC"
    },
    {
        "label": "BFIN - BankFinancial CORP",
        "value": "BFIN"
    },
    {
        "label": "PRSR - Prospector Capital Corp.",
        "value": "PRSR"
    },
    {
        "label": "EFSI - EAGLE FINANCIAL SERVICES INC",
        "value": "EFSI"
    },
    {
        "label": "ANIX - Anixa Biosciences Inc",
        "value": "ANIX"
    },
    {
        "label": "NPCE - NeuroPace Inc",
        "value": "NPCE"
    },
    {
        "label": "SHAP - Spree Acquisition Corp. 1 Ltd",
        "value": "SHAP"
    },
    {
        "label": "PAI - Western Asset Investment Grade Income Fund Inc.",
        "value": "PAI"
    },
    {
        "label": "SHIP - Seanergy Maritime Holdings Corp.",
        "value": "SHIP"
    },
    {
        "label": "AAWH - Ascend Wellness Holdings, Inc.",
        "value": "AAWH"
    },
    {
        "label": "SOND - Sonder Holdings Inc.",
        "value": "SOND"
    },
    {
        "label": "CATX - Perspective Therapeutics, Inc.",
        "value": "CATX"
    },
    {
        "label": "TUP - TUPPERWARE BRANDS CORP",
        "value": "TUP"
    },
    {
        "label": "PCM - PCM FUND, INC.",
        "value": "PCM"
    },
    {
        "label": "AREC - American Resources Corp",
        "value": "AREC"
    },
    {
        "label": "ALOT - AstroNova, Inc.",
        "value": "ALOT"
    },
    {
        "label": "KF - KOREA FUND INC",
        "value": "KF"
    },
    {
        "label": "PLNHF - Planet 13 Holdings Inc.",
        "value": "PLNHF"
    },
    {
        "label": "FXNC - FIRST NATIONAL CORP /VA/",
        "value": "FXNC"
    },
    {
        "label": "ESAC - ESGEN Acquisition Corp",
        "value": "ESAC"
    },
    {
        "label": "ECBK - ECB Bancorp, Inc. /MD/",
        "value": "ECBK"
    },
    {
        "label": "FUNC - FIRST UNITED CORP/MD/",
        "value": "FUNC"
    },
    {
        "label": "RGF - Real Good Food Company, Inc.",
        "value": "RGF"
    },
    {
        "label": "GFOR - Graf Acquisition Corp. IV",
        "value": "GFOR"
    },
    {
        "label": "FGMC - FG Merger Corp.",
        "value": "FGMC"
    },
    {
        "label": "AQMS - Aqua Metals, Inc.",
        "value": "AQMS"
    },
    {
        "label": "TWOA - two",
        "value": "TWOA"
    },
    {
        "label": "SRNEQ - Sorrento Therapeutics, Inc.",
        "value": "SRNEQ"
    },
    {
        "label": "SWZ - SWISS HELVETIA FUND, INC.",
        "value": "SWZ"
    },
    {
        "label": "DFLI - Dragonfly Energy Holdings Corp.",
        "value": "DFLI"
    },
    {
        "label": "CZWI - Citizens Community Bancorp Inc.",
        "value": "CZWI"
    },
    {
        "label": "BBXIA - BBX Capital, Inc.",
        "value": "BBXIA"
    },
    {
        "label": "QUIK - QUICKLOGIC Corp",
        "value": "QUIK"
    },
    {
        "label": "CRAWA - CRAWFORD UNITED Corp",
        "value": "CRAWA"
    },
    {
        "label": "MPRA - Mercato Partners Acquisition Corp",
        "value": "MPRA"
    },
    {
        "label": "VENG - VISION ENERGY Corp",
        "value": "VENG"
    },
    {
        "label": "DMAC - DiaMedica Therapeutics Inc.",
        "value": "DMAC"
    },
    {
        "label": "SBI - WESTERN ASSET INTERMEDIATE MUNI FUND INC.",
        "value": "SBI"
    },
    {
        "label": "DTOC - Digital Transformation Opportunities Corp.",
        "value": "DTOC"
    },
    {
        "label": "ORN - Orion Group Holdings Inc",
        "value": "ORN"
    },
    {
        "label": "LARK - LANDMARK BANCORP INC",
        "value": "LARK"
    },
    {
        "label": "IPVF - InterPrivate III Financial Partners Inc.",
        "value": "IPVF"
    },
    {
        "label": "CMCA - Capitalworks Emerging Markets Acquisition Corp",
        "value": "CMCA"
    },
    {
        "label": "BMBN - BENCHMARK BANKSHARES INC",
        "value": "BMBN"
    },
    {
        "label": "BRID - BRIDGFORD FOODS CORP",
        "value": "BRID"
    },
    {
        "label": "ORRCF - OROCO RESOURCE CORP",
        "value": "ORRCF"
    },
    {
        "label": "KVHI - KVH INDUSTRIES INC \\DE\\",
        "value": "KVHI"
    },
    {
        "label": "FOSL - Fossil Group, Inc.",
        "value": "FOSL"
    },
    {
        "label": "TMKR - Priveterra Acquisition Corp. II",
        "value": "TMKR"
    },
    {
        "label": "LAKE - LAKELAND INDUSTRIES INC",
        "value": "LAKE"
    },
    {
        "label": "PFSW - PFSWEB INC",
        "value": "PFSW"
    },
    {
        "label": "CLLS - Cellectis S.A.",
        "value": "CLLS"
    },
    {
        "label": "SLAC - Social Leverage Acquisition Corp I",
        "value": "SLAC"
    },
    {
        "label": "KNIT - KINETIC GROUP INC.",
        "value": "KNIT"
    },
    {
        "label": "BLUA - BlueRiver Acquisition Corp.",
        "value": "BLUA"
    },
    {
        "label": "RAD - RITE AID CORP",
        "value": "RAD"
    },
    {
        "label": "VNJA - VANJIA CORP",
        "value": "VNJA"
    },
    {
        "label": "MMLP - MARTIN MIDSTREAM PARTNERS L.P.",
        "value": "MMLP"
    },
    {
        "label": "FLXS - FLEXSTEEL INDUSTRIES INC",
        "value": "FLXS"
    },
    {
        "label": "AXTI - AXT INC",
        "value": "AXTI"
    },
    {
        "label": "ACHV - ACHIEVE LIFE SCIENCES, INC.",
        "value": "ACHV"
    },
    {
        "label": "VXRT - Vaxart, Inc.",
        "value": "VXRT"
    },
    {
        "label": "IAF - ABRDN AUSTRALIA EQUITY FUND, INC.",
        "value": "IAF"
    },
    {
        "label": "IZM - ICZOOM Group Inc.",
        "value": "IZM"
    },
    {
        "label": "EGGF - EG Acquisition Corp.",
        "value": "EGGF"
    },
    {
        "label": "CHAA - Catcha Investment Corp",
        "value": "CHAA"
    },
    {
        "label": "ETON - Eton Pharmaceuticals, Inc.",
        "value": "ETON"
    },
    {
        "label": "TZOO - TRAVELZOO",
        "value": "TZOO"
    },
    {
        "label": "CEN - CENTER COAST BROOKFIELD MLP & ENERGY INFRASTRUCTURE FUND",
        "value": "CEN"
    },
    {
        "label": "CSBB - CSB Bancorp, Inc.",
        "value": "CSBB"
    },
    {
        "label": "LIFE - aTYR PHARMA INC",
        "value": "LIFE"
    },
    {
        "label": "EBMT - Eagle Bancorp Montana, Inc.",
        "value": "EBMT"
    },
    {
        "label": "PLBY - PLBY Group, Inc.",
        "value": "PLBY"
    },
    {
        "label": "SPPP - SPROTT PHYSICAL PLATINUM & PALLADIUM TRUST",
        "value": "SPPP"
    },
    {
        "label": "MMAT - META MATERIALS INC.",
        "value": "MMAT"
    },
    {
        "label": "JAQC - Jupiter Acquisition Corp",
        "value": "JAQC"
    },
    {
        "label": "LUXH - LUXURBAN HOTELS INC.",
        "value": "LUXH"
    },
    {
        "label": "MGF - MFS GOVERNMENT MARKETS INCOME TRUST",
        "value": "MGF"
    },
    {
        "label": "GIA - GigCapital5, Inc.",
        "value": "GIA"
    },
    {
        "label": "GGZ - Gabelli Global Small & Mid Cap Value Trust",
        "value": "GGZ"
    },
    {
        "label": "MITA - Coliseum Acquisition Corp.",
        "value": "MITA"
    },
    {
        "label": "NCAC - Newcourt Acquisition Corp",
        "value": "NCAC"
    },
    {
        "label": "MRMD - MARIMED INC.",
        "value": "MRMD"
    },
    {
        "label": "RMBL - RumbleOn, Inc.",
        "value": "RMBL"
    },
    {
        "label": "MIST - Milestone Pharmaceuticals Inc.",
        "value": "MIST"
    },
    {
        "label": "DLNG - Dynagas LNG Partners LP",
        "value": "DLNG"
    },
    {
        "label": "SCYX - SCYNEXIS INC",
        "value": "SCYX"
    },
    {
        "label": "PAYS - Paysign, Inc.",
        "value": "PAYS"
    },
    {
        "label": "YCQH - YCQH Agricultural Technology Co. Ltd",
        "value": "YCQH"
    },
    {
        "label": "GALT - GALECTIN THERAPEUTICS INC",
        "value": "GALT"
    },
    {
        "label": "ILLM - illumin Holdings Inc.",
        "value": "ILLM"
    },
    {
        "label": "KZR - Kezar Life Sciences, Inc.",
        "value": "KZR"
    },
    {
        "label": "LVPA - LVPAI GROUP Ltd",
        "value": "LVPA"
    },
    {
        "label": "CONN - CONNS INC",
        "value": "CONN"
    },
    {
        "label": "CSLM - CSLM ACQUISITION CORP.",
        "value": "CSLM"
    },
    {
        "label": "MNTX - Manitex International, Inc.",
        "value": "MNTX"
    },
    {
        "label": "GNTA - Genenta Science S.p.A.",
        "value": "GNTA"
    },
    {
        "label": "GOSS - Gossamer Bio, Inc.",
        "value": "GOSS"
    },
    {
        "label": "BWAQ - Blue World Acquisition Corp",
        "value": "BWAQ"
    },
    {
        "label": "VFF - Village Farms International, Inc.",
        "value": "VFF"
    },
    {
        "label": "RMI - RiverNorth Opportunistic Municipal Income Fund, Inc.",
        "value": "RMI"
    },
    {
        "label": "VCXA - 10X Capital Venture Acquisition Corp. II",
        "value": "VCXA"
    },
    {
        "label": "KNTE - Kinnate Biopharma Inc.",
        "value": "KNTE"
    },
    {
        "label": "BCV - BANCROFT FUND LTD",
        "value": "BCV"
    },
    {
        "label": "UNB - UNION BANKSHARES INC",
        "value": "UNB"
    },
    {
        "label": "ATHA - Athira Pharma, Inc.",
        "value": "ATHA"
    },
    {
        "label": "CCFN - CCFNB BANCORP INC",
        "value": "CCFN"
    },
    {
        "label": "KPEA - Kun Peng International Ltd.",
        "value": "KPEA"
    },
    {
        "label": "ITHUF - iANTHUS CAPITAL HOLDINGS, INC.",
        "value": "ITHUF"
    },
    {
        "label": "PFO - Flaherty & Crumrine PREFERRED & INCOME OPPORTUNITY FUND INC",
        "value": "PFO"
    },
    {
        "label": "ZJYL - Jin Medical International Ltd.",
        "value": "ZJYL"
    },
    {
        "label": "AACI - Armada Acquisition Corp. I",
        "value": "AACI"
    },
    {
        "label": "CHMI - Cherry Hill Mortgage Investment Corp",
        "value": "CHMI"
    },
    {
        "label": "ADEX - Adit EdTech Acquisition Corp.",
        "value": "ADEX"
    },
    {
        "label": "GDL - GDL FUND",
        "value": "GDL"
    },
    {
        "label": "AQST - Aquestive Therapeutics, Inc.",
        "value": "AQST"
    },
    {
        "label": "PWUP - PowerUp Acquisition Corp.",
        "value": "PWUP"
    },
    {
        "label": "VERY - Vericity, Inc.",
        "value": "VERY"
    },
    {
        "label": "DIST - Distoken Acquisition Corp",
        "value": "DIST"
    },
    {
        "label": "NNBR - NN INC",
        "value": "NNBR"
    },
    {
        "label": "SUP - SUPERIOR INDUSTRIES INTERNATIONAL INC",
        "value": "SUP"
    },
    {
        "label": "MDWT - MIDWEST HOLDING INC.",
        "value": "MDWT"
    },
    {
        "label": "EVF - EATON VANCE SENIOR INCOME TRUST",
        "value": "EVF"
    },
    {
        "label": "RAASY - Cloopen Group Holding Ltd",
        "value": "RAASY"
    },
    {
        "label": "ADIL - ADIAL PHARMACEUTICALS, INC.",
        "value": "ADIL"
    },
    {
        "label": "KULR - KULR Technology Group, Inc.",
        "value": "KULR"
    },
    {
        "label": "CTMX - CytomX Therapeutics, Inc.",
        "value": "CTMX"
    },
    {
        "label": "PROV - PROVIDENT FINANCIAL HOLDINGS INC",
        "value": "PROV"
    },
    {
        "label": "CMTV - COMMUNITY BANCORP /VT",
        "value": "CMTV"
    },
    {
        "label": "MDXH - MDxHealth SA",
        "value": "MDXH"
    },
    {
        "label": "UCL - uCloudlink Group Inc.",
        "value": "UCL"
    },
    {
        "label": "FNVT - Finnovate Acquisition Corp.",
        "value": "FNVT"
    },
    {
        "label": "ASFH - ASIAFIN HOLDINGS CORP.",
        "value": "ASFH"
    },
    {
        "label": "SPIR - Spire Global, Inc.",
        "value": "SPIR"
    },
    {
        "label": "TRLM - TRULEUM, INC.",
        "value": "TRLM"
    },
    {
        "label": "GAMC - Golden Arrow Merger Corp.",
        "value": "GAMC"
    },
    {
        "label": "SBFG - SB FINANCIAL GROUP, INC.",
        "value": "SBFG"
    },
    {
        "label": "BACA - Berenson Acquisition Corp. I",
        "value": "BACA"
    },
    {
        "label": "TCRX - TScan Therapeutics, Inc.",
        "value": "TCRX"
    },
    {
        "label": "GEVI - General Enterprise Ventures, Inc.",
        "value": "GEVI"
    },
    {
        "label": "VOXR - Vox Royalty Corp.",
        "value": "VOXR"
    },
    {
        "label": "PVL - Permianville Royalty Trust",
        "value": "PVL"
    },
    {
        "label": "CYT - Cyteir Therapeutics, Inc.",
        "value": "CYT"
    },
    {
        "label": "GMGI - Golden Matrix Group, Inc.",
        "value": "GMGI"
    },
    {
        "label": "MARX - Mars Acquisition Corp.",
        "value": "MARX"
    },
    {
        "label": "HSPO - Horizon Space Acquisition I Corp.",
        "value": "HSPO"
    },
    {
        "label": "WHG - WESTWOOD HOLDINGS GROUP INC",
        "value": "WHG"
    },
    {
        "label": "JDVB - JD BANCSHARES INC",
        "value": "JDVB"
    },
    {
        "label": "SFBC - Sound Financial Bancorp, Inc.",
        "value": "SFBC"
    },
    {
        "label": "CNGL - Canna-Global Acquisition Corp",
        "value": "CNGL"
    },
    {
        "label": "MLGO - MicroAlgo Inc.",
        "value": "MLGO"
    },
    {
        "label": "ATMV - AlphaVest Acquisition Corp.",
        "value": "ATMV"
    },
    {
        "label": "RFM - RiverNorth Flexible Municipal Income Fund, Inc.",
        "value": "RFM"
    },
    {
        "label": "AUDA - AUDACY, INC.",
        "value": "AUDA"
    },
    {
        "label": "APEI - AMERICAN PUBLIC EDUCATION INC",
        "value": "APEI"
    },
    {
        "label": "BRAC - Broad Capital Acquisition Corp",
        "value": "BRAC"
    },
    {
        "label": "VNRX - VOLITIONRX LTD",
        "value": "VNRX"
    },
    {
        "label": "UTGN - UTG INC",
        "value": "UTGN"
    },
    {
        "label": "SNCR - SYNCHRONOSS TECHNOLOGIES INC",
        "value": "SNCR"
    },
    {
        "label": "NPFC - Newpoint Financial Corp",
        "value": "NPFC"
    },
    {
        "label": "CPTN - Cepton, Inc.",
        "value": "CPTN"
    },
    {
        "label": "MAYS - MAYS J W INC",
        "value": "MAYS"
    },
    {
        "label": "HRBR - HARBOR DIVERSIFIED, INC.",
        "value": "HRBR"
    },
    {
        "label": "LATG - LatAmGrowth SPAC",
        "value": "LATG"
    },
    {
        "label": "SMSI - SMITH MICRO SOFTWARE, INC.",
        "value": "SMSI"
    },
    {
        "label": "CRVS - Corvus Pharmaceuticals, Inc.",
        "value": "CRVS"
    },
    {
        "label": "AEON - AEON Biopharma, Inc.",
        "value": "AEON"
    },
    {
        "label": "BFGX - BANGFU TECHNOLOGY GROUP CO., LTD.",
        "value": "BFGX"
    },
    {
        "label": "WRAC - Williams Rowland Acquisition Corp.",
        "value": "WRAC"
    },
    {
        "label": "CGEN - COMPUGEN LTD",
        "value": "CGEN"
    },
    {
        "label": "VERI - Veritone, Inc.",
        "value": "VERI"
    },
    {
        "label": "YS - YS Biopharma Co., Ltd.",
        "value": "YS"
    },
    {
        "label": "MSSA - Metal Sky Star Acquisition Corp",
        "value": "MSSA"
    },
    {
        "label": "FNWD - Finward Bancorp",
        "value": "FNWD"
    },
    {
        "label": "ARIZ - Arisz Acquisition Corp.",
        "value": "ARIZ"
    },
    {
        "label": "AXDX - Accelerate Diagnostics, Inc",
        "value": "AXDX"
    },
    {
        "label": "NMT - NUVEEN MASSACHUSETTS QUALITY MUNICIPAL INCOME FUND",
        "value": "NMT"
    },
    {
        "label": "NBST - Newbury Street Acquisition Corp",
        "value": "NBST"
    },
    {
        "label": "FLAG - First Light Acquisition Group, Inc.",
        "value": "FLAG"
    },
    {
        "label": "GLYC - GLYCOMIMETICS INC",
        "value": "GLYC"
    },
    {
        "label": "ATMC - ALPHATIME ACQUISITION CORP",
        "value": "ATMC"
    },
    {
        "label": "PRPH - ProPhase Labs, Inc.",
        "value": "PRPH"
    },
    {
        "label": "GTHX - G1 Therapeutics, Inc.",
        "value": "GTHX"
    },
    {
        "label": "IXHL - Incannex Healthcare Ltd",
        "value": "IXHL"
    },
    {
        "label": "AREN - Arena Group Holdings, Inc.",
        "value": "AREN"
    },
    {
        "label": "BLAC - Bellevue Life Sciences Acquisition Corp.",
        "value": "BLAC"
    },
    {
        "label": "PDRO - Pedro's List, Inc.",
        "value": "PDRO"
    },
    {
        "label": "HITI - High Tide Inc.",
        "value": "HITI"
    },
    {
        "label": "IMAQ - International Media Acquisition Corp.",
        "value": "IMAQ"
    },
    {
        "label": "NKTX - Nkarta, Inc.",
        "value": "NKTX"
    },
    {
        "label": "NMI - NUVEEN MUNICIPAL INCOME FUND INC",
        "value": "NMI"
    },
    {
        "label": "TBNK - Territorial Bancorp Inc.",
        "value": "TBNK"
    },
    {
        "label": "PRE - Prenetics Global Ltd",
        "value": "PRE"
    },
    {
        "label": "CELL - PhenomeX Inc.",
        "value": "CELL"
    },
    {
        "label": "CMCT - Creative Media & Community Trust Corp",
        "value": "CMCT"
    },
    {
        "label": "RWOD - Redwoods Acquisition Corp.",
        "value": "RWOD"
    },
    {
        "label": "ACNT - ASCENT INDUSTRIES CO.",
        "value": "ACNT"
    },
    {
        "label": "GLT - Glatfelter Corp",
        "value": "GLT"
    },
    {
        "label": "CMU - MFS HIGH YIELD MUNICIPAL TRUST",
        "value": "CMU"
    },
    {
        "label": "INAQ - Insight Acquisition Corp. /DE",
        "value": "INAQ"
    },
    {
        "label": "QDRO - Quadro Acquisition One Corp.",
        "value": "QDRO"
    },
    {
        "label": "LIVE - LIVE VENTURES Inc",
        "value": "LIVE"
    },
    {
        "label": "EAC - Edify Acquisition Corp.",
        "value": "EAC"
    },
    {
        "label": "MDV - Modiv Industrial, Inc.",
        "value": "MDV"
    },
    {
        "label": "RENT - Rent the Runway, Inc.",
        "value": "RENT"
    },
    {
        "label": "CGO - CALAMOS GLOBAL TOTAL RETURN FUND",
        "value": "CGO"
    },
    {
        "label": "NXG - NXG NextGen Infrastructure Income Fund",
        "value": "NXG"
    },
    {
        "label": "OCCI - OFS Credit Company, Inc.",
        "value": "OCCI"
    },
    {
        "label": "TBMC - Trailblazer Merger Corp I",
        "value": "TBMC"
    },
    {
        "label": "VERU - VERU INC.",
        "value": "VERU"
    },
    {
        "label": "TRLEF - Trillion Energy International Inc.",
        "value": "TRLEF"
    },
    {
        "label": "IHD - Voya Emerging Markets High Dividend Equity Fund",
        "value": "IHD"
    },
    {
        "label": "TGLO - THEGLOBE COM INC",
        "value": "TGLO"
    },
    {
        "label": "ERH - ALLSPRING UTILITIES & HIGH INCOME FUND",
        "value": "ERH"
    },
    {
        "label": "ORTX - Orchard Therapeutics plc",
        "value": "ORTX"
    },
    {
        "label": "DISA - Disruptive Acquisition Corp I",
        "value": "DISA"
    },
    {
        "label": "EARN - Ellington Residential Mortgage REIT",
        "value": "EARN"
    },
    {
        "label": "CENN - CENNTRO ELECTRIC GROUP Ltd",
        "value": "CENN"
    },
    {
        "label": "SRL - Scully Royalty Ltd.",
        "value": "SRL"
    },
    {
        "label": "NOBH - NOBILITY HOMES INC",
        "value": "NOBH"
    },
    {
        "label": "SOLO - ELECTRAMECCANICA VEHICLES CORP.",
        "value": "SOLO"
    },
    {
        "label": "KSM - DWS STRATEGIC MUNICIPAL INCOME TRUST",
        "value": "KSM"
    },
    {
        "label": "GODN - Golden Star Acquisition Corp",
        "value": "GODN"
    },
    {
        "label": "AFBI - Affinity Bancshares, Inc.",
        "value": "AFBI"
    },
    {
        "label": "NRAC - NORTHERN REVIVAL ACQUISITION Corp",
        "value": "NRAC"
    },
    {
        "label": "CLNN - Clene Inc.",
        "value": "CLNN"
    },
    {
        "label": "KITT - Nauticus Robotics, Inc.",
        "value": "KITT"
    },
    {
        "label": "FRLA - Fortune Rise Acquisition Corp",
        "value": "FRLA"
    },
    {
        "label": "PSBQ - PSB HOLDINGS INC /WI/",
        "value": "PSBQ"
    },
    {
        "label": "OHAA - Opy Acquisition Corp. I",
        "value": "OHAA"
    },
    {
        "label": "RVPH - REVIVA PHARMACEUTICALS HOLDINGS, INC.",
        "value": "RVPH"
    },
    {
        "label": "STRT - STRATTEC SECURITY CORP",
        "value": "STRT"
    },
    {
        "label": "SPRB - SPRUCE BIOSCIENCES, INC.",
        "value": "SPRB"
    },
    {
        "label": "GP - GREENPOWER MOTOR Co INC.",
        "value": "GP"
    },
    {
        "label": "KRON - Kronos Bio, Inc.",
        "value": "KRON"
    },
    {
        "label": "NEOV - NeoVolta Inc.",
        "value": "NEOV"
    },
    {
        "label": "FFMGF - First Mining Gold Corp.",
        "value": "FFMGF"
    },
    {
        "label": "CFFE - CF Acquisition Corp. VIII",
        "value": "CFFE"
    },
    {
        "label": "AWCA - Awaysis Capital, Inc.",
        "value": "AWCA"
    },
    {
        "label": "STG - Sunlands Technology Group",
        "value": "STG"
    },
    {
        "label": "OPOF - OLD POINT FINANCIAL CORP",
        "value": "OPOF"
    },
    {
        "label": "HUDA - Hudson Acquisition I Corp.",
        "value": "HUDA"
    },
    {
        "label": "OSI - Osiris Acquisition Corp.",
        "value": "OSI"
    },
    {
        "label": "PYXS - Pyxis Oncology, Inc.",
        "value": "PYXS"
    },
    {
        "label": "SSSS - SURO CAPITAL CORP.",
        "value": "SSSS"
    },
    {
        "label": "BITE - Bite Acquisition Corp.",
        "value": "BITE"
    },
    {
        "label": "NOTV - Inotiv, Inc.",
        "value": "NOTV"
    },
    {
        "label": "KLR - Kaleyra, Inc.",
        "value": "KLR"
    },
    {
        "label": "ASPS - ALTISOURCE PORTFOLIO SOLUTIONS S.A.",
        "value": "ASPS"
    },
    {
        "label": "JLS - Nuveen Mortgage & Income Fund/MA/",
        "value": "JLS"
    },
    {
        "label": "IVAC - INTEVAC INC",
        "value": "IVAC"
    },
    {
        "label": "ALTU - Altitude Acquisition Corp.",
        "value": "ALTU"
    },
    {
        "label": "GNT - GAMCO Natural Resources, Gold & Income Trust",
        "value": "GNT"
    },
    {
        "label": "PICC - Pivotal Investment Corp III",
        "value": "PICC"
    },
    {
        "label": "BLRX - BioLineRx Ltd.",
        "value": "BLRX"
    },
    {
        "label": "HOWL - Werewolf Therapeutics, Inc.",
        "value": "HOWL"
    },
    {
        "label": "QNBC - QNB CORP",
        "value": "QNBC"
    },
    {
        "label": "AE - ADAMS RESOURCES & ENERGY, INC.",
        "value": "AE"
    },
    {
        "label": "HMNF - HMN FINANCIAL INC",
        "value": "HMNF"
    },
    {
        "label": "SRV - NXG Cushing Midstream Energy Fund",
        "value": "SRV"
    },
    {
        "label": "LPTV - Loop Media, Inc.",
        "value": "LPTV"
    },
    {
        "label": "APAC - StoneBridge Acquisition Corp.",
        "value": "APAC"
    },
    {
        "label": "HFUS - Hartford Great Health Corp.",
        "value": "HFUS"
    },
    {
        "label": "GSIT - GSI TECHNOLOGY INC",
        "value": "GSIT"
    },
    {
        "label": "WMLLF - Wealth Minerals Ltd.",
        "value": "WMLLF"
    },
    {
        "label": "IMNM - Immunome Inc.",
        "value": "IMNM"
    },
    {
        "label": "CRDF - Cardiff Oncology, Inc.",
        "value": "CRDF"
    },
    {
        "label": "NYMXF - NYMOX PHARMACEUTICAL CORP",
        "value": "NYMXF"
    },
    {
        "label": "PET - Wag! Group Co.",
        "value": "PET"
    },
    {
        "label": "ELTX - Elicio Therapeutics, Inc.",
        "value": "ELTX"
    },
    {
        "label": "PRCH - Porch Group, Inc.",
        "value": "PRCH"
    },
    {
        "label": "RLMD - RELMADA THERAPEUTICS, INC.",
        "value": "RLMD"
    },
    {
        "label": "ALLT - Allot Ltd.",
        "value": "ALLT"
    },
    {
        "label": "GLLI - GLOBALINK INVESTMENT INC.",
        "value": "GLLI"
    },
    {
        "label": "LVLU - Lulu's Fashion Lounge Holdings, Inc.",
        "value": "LVLU"
    },
    {
        "label": "DKDCA - Data Knights Acquisition Corp.",
        "value": "DKDCA"
    },
    {
        "label": "OCUP - Ocuphire Pharma, Inc.",
        "value": "OCUP"
    },
    {
        "label": "PCCT - Perception Capital Corp. II",
        "value": "PCCT"
    },
    {
        "label": "IRAA - Iris Acquisition Corp",
        "value": "IRAA"
    },
    {
        "label": "CODA - Coda Octopus Group, Inc.",
        "value": "CODA"
    },
    {
        "label": "ACET - Adicet Bio, Inc.",
        "value": "ACET"
    },
    {
        "label": "SRFM - SURF AIR MOBILITY INC.",
        "value": "SRFM"
    },
    {
        "label": "SCX - STARRETT L S CO",
        "value": "SCX"
    },
    {
        "label": "CSBR - CHAMPIONS ONCOLOGY, INC.",
        "value": "CSBR"
    },
    {
        "label": "UNG - United States Natural Gas Fund, LP",
        "value": "UNG"
    },
    {
        "label": "BHG - Bright Health Group Inc.",
        "value": "BHG"
    },
    {
        "label": "ARYD - ARYA Sciences Acquisition Corp IV",
        "value": "ARYD"
    },
    {
        "label": "HNW - Pioneer Diversified High Income Fund, Inc.",
        "value": "HNW"
    },
    {
        "label": "CMRX - CHIMERIX INC",
        "value": "CMRX"
    },
    {
        "label": "TPZ - TORTOISE POWER & ENERGY INFRASTRUCTURE FUND INC",
        "value": "TPZ"
    },
    {
        "label": "VGI - Virtus Global Multi-Sector Income Fund",
        "value": "VGI"
    },
    {
        "label": "STTK - Shattuck Labs, Inc.",
        "value": "STTK"
    },
    {
        "label": "UPLD - Upland Software, Inc.",
        "value": "UPLD"
    },
    {
        "label": "ELMD - Electromed, Inc.",
        "value": "ELMD"
    },
    {
        "label": "TMTC - TMT Acquisition Corp.",
        "value": "TMTC"
    },
    {
        "label": "JUSHF - Jushi Holdings Inc.",
        "value": "JUSHF"
    },
    {
        "label": "DRRX - DURECT CORP",
        "value": "DRRX"
    },
    {
        "label": "AXR - AMREP CORP.",
        "value": "AXR"
    },
    {
        "label": "XAIR - Beyond Air, Inc.",
        "value": "XAIR"
    },
    {
        "label": "IVCP - Swiftmerge Acquisition Corp.",
        "value": "IVCP"
    },
    {
        "label": "CBAT - CBAK Energy Technology, Inc.",
        "value": "CBAT"
    },
    {
        "label": "DAVE - Dave Inc./DE",
        "value": "DAVE"
    },
    {
        "label": "SLNA - SELINA HOSPITALITY PLC",
        "value": "SLNA"
    },
    {
        "label": "MDWD - MediWound Ltd.",
        "value": "MDWD"
    },
    {
        "label": "LUNR - Intuitive Machines, Inc.",
        "value": "LUNR"
    },
    {
        "label": "SGII - Seaport Global Acquisition II Corp.",
        "value": "SGII"
    },
    {
        "label": "SYRS - Syros Pharmaceuticals, Inc.",
        "value": "SYRS"
    },
    {
        "label": "PPHP - PHP Ventures Acquisition Corp.",
        "value": "PPHP"
    },
    {
        "label": "PHCI - Panamera Holdings Corp",
        "value": "PHCI"
    },
    {
        "label": "PSNL - Personalis, Inc.",
        "value": "PSNL"
    },
    {
        "label": "SLNG - Stabilis Solutions, Inc.",
        "value": "SLNG"
    },
    {
        "label": "GTH - Genetron Holdings Ltd",
        "value": "GTH"
    },
    {
        "label": "SIEB - SIEBERT FINANCIAL CORP",
        "value": "SIEB"
    },
    {
        "label": "DUO - Fangdd Network Group Ltd.",
        "value": "DUO"
    },
    {
        "label": "ISDR - ISSUER DIRECT CORP",
        "value": "ISDR"
    },
    {
        "label": "CMLS - CUMULUS MEDIA INC",
        "value": "CMLS"
    },
    {
        "label": "ATAQ - Altimar Acquisition Corp. III",
        "value": "ATAQ"
    },
    {
        "label": "GLU - GABELLI GLOBAL UTILITY & INCOME TRUST",
        "value": "GLU"
    },
    {
        "label": "SND - Smart Sand, Inc.",
        "value": "SND"
    },
    {
        "label": "CSLR - Complete Solaria, Inc.",
        "value": "CSLR"
    },
    {
        "label": "FORA - Forian Inc.",
        "value": "FORA"
    },
    {
        "label": "AOGO - Arogo Capital Acquisition Corp.",
        "value": "AOGO"
    },
    {
        "label": "NXC - NUVEEN CALIFORNIA SELECT TAX FREE INCOME PORTFOLIO",
        "value": "NXC"
    },
    {
        "label": "OCAX - OCA Acquisition Corp.",
        "value": "OCAX"
    },
    {
        "label": "DUET - DUET Acquisition Corp.",
        "value": "DUET"
    },
    {
        "label": "PNI - PIMCO NEW YORK MUNICIPAL INCOME FUND II",
        "value": "PNI"
    },
    {
        "label": "SFDL - SECURITY FEDERAL CORP",
        "value": "SFDL"
    },
    {
        "label": "GDST - Goldenstone Acquisition Ltd.",
        "value": "GDST"
    },
    {
        "label": "FICV - Frontier Investment Corp",
        "value": "FICV"
    },
    {
        "label": "INTS - INTENSITY THERAPEUTICS, INC.",
        "value": "INTS"
    },
    {
        "label": "EGLX - Enthusiast Gaming Holdings Inc. / Canada",
        "value": "EGLX"
    },
    {
        "label": "APLT - Applied Therapeutics Inc.",
        "value": "APLT"
    },
    {
        "label": "KANP - KAANAPALI LAND LLC",
        "value": "KANP"
    },
    {
        "label": "ERDCF - Erdene Resource Development Corp",
        "value": "ERDCF"
    },
    {
        "label": "HNRA - HNR Acquisition Corp.",
        "value": "HNRA"
    },
    {
        "label": "LEAT - Leatt Corp",
        "value": "LEAT"
    },
    {
        "label": "STCB - Starco Brands, Inc.",
        "value": "STCB"
    },
    {
        "label": "SLGL - Sol-Gel Technologies Ltd.",
        "value": "SLGL"
    },
    {
        "label": "SOHO - Sotherly Hotels Inc.",
        "value": "SOHO"
    },
    {
        "label": "WFCF - Where Food Comes From, Inc.",
        "value": "WFCF"
    },
    {
        "label": "ENZ - ENZO BIOCHEM INC",
        "value": "ENZ"
    },
    {
        "label": "RDI - READING INTERNATIONAL INC",
        "value": "RDI"
    },
    {
        "label": "PFIE - PROFIRE ENERGY INC",
        "value": "PFIE"
    },
    {
        "label": "CASA - Casa Systems Inc",
        "value": "CASA"
    },
    {
        "label": "PGP - PIMCO Global StocksPLUS & Income Fund",
        "value": "PGP"
    },
    {
        "label": "GCV - GABELLI CONVERTIBLE & INCOME SECURITIES FUND INC",
        "value": "GCV"
    },
    {
        "label": "ZHYBF - Zhong Yuan Bio-Technology Holdings Ltd",
        "value": "ZHYBF"
    },
    {
        "label": "CPTP - CAPITAL PROPERTIES INC /RI/",
        "value": "CPTP"
    },
    {
        "label": "PBHC - Pathfinder Bancorp, Inc.",
        "value": "PBHC"
    },
    {
        "label": "CULL - Cullman Bancorp, Inc. /MD/",
        "value": "CULL"
    },
    {
        "label": "TKNO - Alpha Teknova, Inc.",
        "value": "TKNO"
    },
    {
        "label": "PLTN - Plutonian Acquisition Corp.",
        "value": "PLTN"
    },
    {
        "label": "EDF - Virtus Stone Harbor Emerging Markets Income Fund",
        "value": "EDF"
    },
    {
        "label": "HAIA - Healthcare AI Acquisition Corp.",
        "value": "HAIA"
    },
    {
        "label": "LIBY - Liberty Resources Acquisition Corp.",
        "value": "LIBY"
    },
    {
        "label": "SCTH - Securetech Innovations, Inc.",
        "value": "SCTH"
    },
    {
        "label": "TLSA - Tiziana Life Sciences Ltd",
        "value": "TLSA"
    },
    {
        "label": "USAS - Americas Gold & Silver Corp",
        "value": "USAS"
    },
    {
        "label": "CETU - Cetus Capital Acquisition Corp.",
        "value": "CETU"
    },
    {
        "label": "KSCP - Knightscope, Inc.",
        "value": "KSCP"
    },
    {
        "label": "GOLQ - GoLogiq, Inc.",
        "value": "GOLQ"
    },
    {
        "label": "OAKU - Oak Woods Acquisition Corp",
        "value": "OAKU"
    },
    {
        "label": "ABEO - ABEONA THERAPEUTICS INC.",
        "value": "ABEO"
    },
    {
        "label": "AUBN - AUBURN NATIONAL BANCORPORATION, INC",
        "value": "AUBN"
    },
    {
        "label": "AZ - A2Z Smart Technologies Corp",
        "value": "AZ"
    },
    {
        "label": "INTG - INTERGROUP CORP",
        "value": "INTG"
    },
    {
        "label": "PED - PEDEVCO CORP",
        "value": "PED"
    },
    {
        "label": "GSVRF - GUANAJUATO SILVER CO LTD",
        "value": "GSVRF"
    },
    {
        "label": "AKLI - Akili, Inc.",
        "value": "AKLI"
    },
    {
        "label": "BNED - Barnes & Noble Education, Inc.",
        "value": "BNED"
    },
    {
        "label": "ALOR - ALSP Orchid Acquisition Corp I",
        "value": "ALOR"
    },
    {
        "label": "SURG - SurgePays, Inc.",
        "value": "SURG"
    },
    {
        "label": "SWSS - Clean Energy Special Situations Corp.",
        "value": "SWSS"
    },
    {
        "label": "BKSC - BANK OF SOUTH CAROLINA CORP",
        "value": "BKSC"
    },
    {
        "label": "AFMD - Affimed N.V.",
        "value": "AFMD"
    },
    {
        "label": "RBKB - Rhinebeck Bancorp, Inc.",
        "value": "RBKB"
    },
    {
        "label": "ANEB - Anebulo Pharmaceuticals, Inc.",
        "value": "ANEB"
    },
    {
        "label": "YOTA - Yotta Acquisition Corp",
        "value": "YOTA"
    },
    {
        "label": "PFX - PhenixFIN Corp",
        "value": "PFX"
    },
    {
        "label": "RCLF - Rosecliff Acquisition Corp I",
        "value": "RCLF"
    },
    {
        "label": "BYRN - Byrna Technologies Inc.",
        "value": "BYRN"
    },
    {
        "label": "VTSI - VirTra, Inc",
        "value": "VTSI"
    },
    {
        "label": "VACC - Vaccitech plc",
        "value": "VACC"
    },
    {
        "label": "PCTI - PC TEL INC",
        "value": "PCTI"
    },
    {
        "label": "MCHX - MARCHEX INC",
        "value": "MCHX"
    },
    {
        "label": "VHAQ - Viveon Health Acquisition Corp.",
        "value": "VHAQ"
    },
    {
        "label": "HPLT - Home Plate Acquisition Corp",
        "value": "HPLT"
    },
    {
        "label": "JEQ - ABRDN JAPAN EQUITY FUND, INC.",
        "value": "JEQ"
    },
    {
        "label": "DTF - DTF TAX-FREE INCOME 2028 TERM FUND INC",
        "value": "DTF"
    },
    {
        "label": "UXIN - Uxin Ltd",
        "value": "UXIN"
    },
    {
        "label": "RFAC - RF Acquisition Corp.",
        "value": "RFAC"
    },
    {
        "label": "ZEPP - Zepp Health Corp",
        "value": "ZEPP"
    },
    {
        "label": "MHUA - Meihua International Medical Technologies Co., Ltd.",
        "value": "MHUA"
    },
    {
        "label": "TAYD - TAYLOR DEVICES INC",
        "value": "TAYD"
    },
    {
        "label": "ASM - AVINO SILVER & GOLD MINES LTD",
        "value": "ASM"
    },
    {
        "label": "GECC - Great Elm Capital Corp.",
        "value": "GECC"
    },
    {
        "label": "MLSS - MILESTONE SCIENTIFIC INC.",
        "value": "MLSS"
    },
    {
        "label": "PRT - PermRock Royalty Trust",
        "value": "PRT"
    },
    {
        "label": "FCO - ABRDN GLOBAL INCOME FUND, INC.",
        "value": "FCO"
    },
    {
        "label": "GLXZ - Galaxy Gaming, Inc.",
        "value": "GLXZ"
    },
    {
        "label": "NTZ - NATUZZI S P A",
        "value": "NTZ"
    },
    {
        "label": "ENBP - ENB Financial Corp",
        "value": "ENBP"
    },
    {
        "label": "BFNH - BIOFORCE NANOSCIENCES HOLDINGS, INC.",
        "value": "BFNH"
    },
    {
        "label": "QOMO - Qomolangma Acquisition Corp.",
        "value": "QOMO"
    },
    {
        "label": "IGTA - Inception Growth Acquisition Ltd",
        "value": "IGTA"
    },
    {
        "label": "BWEN - BROADWIND, INC.",
        "value": "BWEN"
    },
    {
        "label": "XLO - Xilio Therapeutics, Inc.",
        "value": "XLO"
    },
    {
        "label": "ACR - ACRES Commercial Realty Corp.",
        "value": "ACR"
    },
    {
        "label": "GGAAF - Genesis Growth Tech Acquisition Corp.",
        "value": "GGAAF"
    },
    {
        "label": "FMBM - F&M BANK CORP",
        "value": "FMBM"
    },
    {
        "label": "AQU - Aquaron Acquisition Corp.",
        "value": "AQU"
    },
    {
        "label": "OXBC - Oxford Bank Corp",
        "value": "OXBC"
    },
    {
        "label": "CLGN - CollPlant Biotechnologies Ltd",
        "value": "CLGN"
    },
    {
        "label": "GTAC - Global Technology Acquisition Corp. I",
        "value": "GTAC"
    },
    {
        "label": "FLUX - Flux Power Holdings, Inc.",
        "value": "FLUX"
    },
    {
        "label": "MGYR - Magyar Bancorp, Inc.",
        "value": "MGYR"
    },
    {
        "label": "AAGH - America Great Health",
        "value": "AAGH"
    },
    {
        "label": "PPIH - Perma-Pipe International Holdings, Inc.",
        "value": "PPIH"
    },
    {
        "label": "LZGI - LZG INTERNATIONAL, INC.",
        "value": "LZGI"
    },
    {
        "label": "TMQ - Trilogy Metals Inc.",
        "value": "TMQ"
    },
    {
        "label": "OMEX - ODYSSEY MARINE EXPLORATION INC",
        "value": "OMEX"
    },
    {
        "label": "CDTX - Cidara Therapeutics, Inc.",
        "value": "CDTX"
    },
    {
        "label": "TETE - Technology & Telecommunication Acquisition Corp",
        "value": "TETE"
    },
    {
        "label": "GAN - GAN Ltd",
        "value": "GAN"
    },
    {
        "label": "WIMI - WiMi Hologram Cloud Inc.",
        "value": "WIMI"
    },
    {
        "label": "VIRC - VIRCO MFG CORPORATION",
        "value": "VIRC"
    },
    {
        "label": "BNTC - Benitec Biopharma Inc.",
        "value": "BNTC"
    },
    {
        "label": "JHAA - Nuveen Corporate Income 2023 Target Term Fund",
        "value": "JHAA"
    },
    {
        "label": "HCWB - HCW Biologics Inc.",
        "value": "HCWB"
    },
    {
        "label": "PNAC - Prime Number Acquisition I Corp.",
        "value": "PNAC"
    },
    {
        "label": "JUVF - JUNIATA VALLEY FINANCIAL CORP",
        "value": "JUVF"
    },
    {
        "label": "BVFL - BV Financial, Inc.",
        "value": "BVFL"
    },
    {
        "label": "DOMA - Doma Holdings, Inc.",
        "value": "DOMA"
    },
    {
        "label": "PWFL - PowerFleet, Inc.",
        "value": "PWFL"
    },
    {
        "label": "CTCX - Carmell Corp",
        "value": "CTCX"
    },
    {
        "label": "SLDB - Solid Biosciences Inc.",
        "value": "SLDB"
    },
    {
        "label": "PXLW - PIXELWORKS, INC",
        "value": "PXLW"
    },
    {
        "label": "LIVB - LIV Capital Acquisition Corp. II",
        "value": "LIVB"
    },
    {
        "label": "FXCO - Financial Strategies Acquisition Corp.",
        "value": "FXCO"
    },
    {
        "label": "DZSI - DZS INC.",
        "value": "DZSI"
    },
    {
        "label": "OXUS - Oxus Acquisition Corp.",
        "value": "OXUS"
    },
    {
        "label": "DRIO - DarioHealth Corp.",
        "value": "DRIO"
    },
    {
        "label": "PMHG - Prime Meridian Holding Co",
        "value": "PMHG"
    },
    {
        "label": "DMTK - DermTech, Inc.",
        "value": "DMTK"
    },
    {
        "label": "FFNTF - 4Front Ventures Corp.",
        "value": "FFNTF"
    },
    {
        "label": "LFVN - Lifevantage Corp",
        "value": "LFVN"
    },
    {
        "label": "HYMC - HYCROFT MINING HOLDING CORP",
        "value": "HYMC"
    },
    {
        "label": "TRON - CORNER GROWTH ACQUISITION CORP. 2",
        "value": "TRON"
    },
    {
        "label": "AUID - authID Inc.",
        "value": "AUID"
    },
    {
        "label": "IMUX - IMMUNIC, INC.",
        "value": "IMUX"
    },
    {
        "label": "VBFC - Village Bank & Trust Financial Corp.",
        "value": "VBFC"
    },
    {
        "label": "BYFC - BROADWAY FINANCIAL CORP DE",
        "value": "BYFC"
    },
    {
        "label": "APCX - AppTech Payments Corp.",
        "value": "APCX"
    },
    {
        "label": "SPRO - Spero Therapeutics, Inc.",
        "value": "SPRO"
    },
    {
        "label": "THM - INTERNATIONAL TOWER HILL MINES LTD",
        "value": "THM"
    },
    {
        "label": "CULP - CULP INC",
        "value": "CULP"
    },
    {
        "label": "SOTK - SONO TEK CORP",
        "value": "SOTK"
    },
    {
        "label": "ELYM - Eliem Therapeutics, Inc.",
        "value": "ELYM"
    },
    {
        "label": "WNLV - Winvest Group Ltd",
        "value": "WNLV"
    },
    {
        "label": "JPT - Nuveen Preferred & Income Fund",
        "value": "JPT"
    },
    {
        "label": "CSPI - CSP INC /MA/",
        "value": "CSPI"
    },
    {
        "label": "SNAX - STRYVE FOODS, INC.",
        "value": "SNAX"
    },
    {
        "label": "TACT - TRANSACT TECHNOLOGIES INC",
        "value": "TACT"
    },
    {
        "label": "GGROU - Golden Growers Cooperative",
        "value": "GGROU"
    },
    {
        "label": "HYSR - SUNHYDROGEN, INC.",
        "value": "HYSR"
    },
    {
        "label": "MAQC - Maquia Capital Acquisition Corp",
        "value": "MAQC"
    },
    {
        "label": "INCR - Intercure Ltd.",
        "value": "INCR"
    },
    {
        "label": "ASXC - ASENSUS SURGICAL, INC.",
        "value": "ASXC"
    },
    {
        "label": "PSIX - POWER SOLUTIONS INTERNATIONAL, INC.",
        "value": "PSIX"
    },
    {
        "label": "IRME - IR-Med, Inc.",
        "value": "IRME"
    },
    {
        "label": "STCN - Steel Connect, Inc.",
        "value": "STCN"
    },
    {
        "label": "TCBC - TC Bancshares, Inc.",
        "value": "TCBC"
    },
    {
        "label": "UNIB - UNIVERSITY BANCORP INC /DE/",
        "value": "UNIB"
    },
    {
        "label": "ZYNE - Zynerba Pharmaceuticals, Inc.",
        "value": "ZYNE"
    },
    {
        "label": "STRM - STREAMLINE HEALTH SOLUTIONS INC.",
        "value": "STRM"
    },
    {
        "label": "ARAT - Arax Holdings Corp",
        "value": "ARAT"
    },
    {
        "label": "CRKR - Prairie Operating Co.",
        "value": "CRKR"
    },
    {
        "label": "RFLFY - Raffles Education Corp Ltd",
        "value": "RFLFY"
    },
    {
        "label": "CEV - EATON VANCE CALIFORNIA MUNICIPAL INCOME TRUST",
        "value": "CEV"
    },
    {
        "label": "SCOR - COMSCORE, INC.",
        "value": "SCOR"
    },
    {
        "label": "EYEN - EYENOVIA, INC.",
        "value": "EYEN"
    },
    {
        "label": "PBAX - PHOENIX BIOTECH ACQUISITION CORP.",
        "value": "PBAX"
    },
    {
        "label": "LBBB - Lakeshore Acquisition II Corp.",
        "value": "LBBB"
    },
    {
        "label": "GLV - Clough Global Dividend & Income Fund",
        "value": "GLV"
    },
    {
        "label": "FHLT - Future Health ESG Corp.",
        "value": "FHLT"
    },
    {
        "label": "VLT - Invesco High Income Trust II",
        "value": "VLT"
    },
    {
        "label": "MESA - MESA AIR GROUP INC",
        "value": "MESA"
    },
    {
        "label": "FATH - Fathom Digital Manufacturing Corp",
        "value": "FATH"
    },
    {
        "label": "FORL - Four Leaf Acquisition Corp",
        "value": "FORL"
    },
    {
        "label": "SNDA - SONIDA SENIOR LIVING, INC.",
        "value": "SNDA"
    },
    {
        "label": "SLBK - Skyline Bankshares, Inc.",
        "value": "SLBK"
    },
    {
        "label": "PPSI - PIONEER POWER SOLUTIONS, INC.",
        "value": "PPSI"
    },
    {
        "label": "LUDG - LUDWIG ENTERPRISES, INC.",
        "value": "LUDG"
    },
    {
        "label": "AYRWF - Ayr Wellness Inc.",
        "value": "AYRWF"
    },
    {
        "label": "MURF - Murphy Canyon Acquisition Corp.",
        "value": "MURF"
    },
    {
        "label": "WRAP - WRAP TECHNOLOGIES, INC.",
        "value": "WRAP"
    },
    {
        "label": "JOB - GEE Group Inc.",
        "value": "JOB"
    },
    {
        "label": "LAES - SEALSQ Corp",
        "value": "LAES"
    },
    {
        "label": "IDR - Idaho Strategic Resources, Inc.",
        "value": "IDR"
    },
    {
        "label": "QUBT - Quantum Computing Inc.",
        "value": "QUBT"
    },
    {
        "label": "NSPR - InspireMD, Inc.",
        "value": "NSPR"
    },
    {
        "label": "IAE - Voya Asia Pacific High Dividend Equity Income Fund",
        "value": "IAE"
    },
    {
        "label": "CLOE - Clover Leaf Capital Corp.",
        "value": "CLOE"
    },
    {
        "label": "ASTR - Astra Space, Inc.",
        "value": "ASTR"
    },
    {
        "label": "BZFD - BuzzFeed, Inc.",
        "value": "BZFD"
    },
    {
        "label": "CXH - MFS INVESTMENT GRADE MUNICIPAL TRUST",
        "value": "CXH"
    },
    {
        "label": "UBCP - UNITED BANCORP INC /OH/",
        "value": "UBCP"
    },
    {
        "label": "KORE - KORE Group Holdings, Inc.",
        "value": "KORE"
    },
    {
        "label": "TATT - TAT TECHNOLOGIES LTD",
        "value": "TATT"
    },
    {
        "label": "VPLM - Voip-pal.com Inc",
        "value": "VPLM"
    },
    {
        "label": "NVAC - NorthView Acquisition Corp",
        "value": "NVAC"
    },
    {
        "label": "MTC - MMTec, Inc.",
        "value": "MTC"
    },
    {
        "label": "AVRO - AVROBIO, Inc.",
        "value": "AVRO"
    },
    {
        "label": "CXAI - CXApp Inc.",
        "value": "CXAI"
    },
    {
        "label": "INSG - INSEEGO CORP.",
        "value": "INSG"
    },
    {
        "label": "DMYY - dMY Squared Technology Group, Inc.",
        "value": "DMYY"
    },
    {
        "label": "MFD - MACQUARIE/FIRST TRUST GLOBAL INFRASTR/UTIL DIV & INC FUND",
        "value": "MFD"
    },
    {
        "label": "YBGJ - Yubo International Biotech Ltd",
        "value": "YBGJ"
    },
    {
        "label": "CYBN - CYBIN INC.",
        "value": "CYBN"
    },
    {
        "label": "NUVR - Nuvera Communications, Inc.",
        "value": "NUVR"
    },
    {
        "label": "GLG - TD Holdings, Inc.",
        "value": "GLG"
    },
    {
        "label": "IPWR - Ideal Power Inc.",
        "value": "IPWR"
    },
    {
        "label": "SERA - SERA PROGNOSTICS, INC.",
        "value": "SERA"
    },
    {
        "label": "FTK - FLOTEK INDUSTRIES INC/CN/",
        "value": "FTK"
    },
    {
        "label": "TIL - Instil Bio, Inc.",
        "value": "TIL"
    },
    {
        "label": "APGT - Appgate, Inc.",
        "value": "APGT"
    },
    {
        "label": "HUIZ - Huize Holding Ltd",
        "value": "HUIZ"
    },
    {
        "label": "LINK - INTERLINK ELECTRONICS INC",
        "value": "LINK"
    },
    {
        "label": "MACA - Moringa Acquisition Corp",
        "value": "MACA"
    },
    {
        "label": "EAR - Eargo, Inc.",
        "value": "EAR"
    },
    {
        "label": "TMRC - Texas Mineral Resources Corp.",
        "value": "TMRC"
    },
    {
        "label": "SEPA - SEP Acquisition Corp.",
        "value": "SEPA"
    },
    {
        "label": "LEE - LEE ENTERPRISES, Inc",
        "value": "LEE"
    },
    {
        "label": "VIA - Via Renewables, Inc.",
        "value": "VIA"
    },
    {
        "label": "FOTB - FIRST OTTAWA BANCSHARES, INC",
        "value": "FOTB"
    },
    {
        "label": "JYD - Jayud Global Logistics Ltd",
        "value": "JYD"
    },
    {
        "label": "RMTI - ROCKWELL MEDICAL, INC.",
        "value": "RMTI"
    },
    {
        "label": "TPCS - TECHPRECISION CORP",
        "value": "TPCS"
    },
    {
        "label": "BGI - BIRKS GROUP INC.",
        "value": "BGI"
    },
    {
        "label": "GEG - Great Elm Group, Inc.",
        "value": "GEG"
    },
    {
        "label": "PGZ - Principal Real Estate Income Fund",
        "value": "PGZ"
    },
    {
        "label": "LTCN - Grayscale Litecoin Trust (LTC)",
        "value": "LTCN"
    },
    {
        "label": "PNF - PIMCO NEW YORK MUNICIPAL INCOME FUND",
        "value": "PNF"
    },
    {
        "label": "CVM - CEL SCI CORP",
        "value": "CVM"
    },
    {
        "label": "VIOT - Viomi Technology Co., Ltd",
        "value": "VIOT"
    },
    {
        "label": "BCRD - BlueOne Card, Inc.",
        "value": "BCRD"
    },
    {
        "label": "RSSS - Research Solutions, Inc.",
        "value": "RSSS"
    },
    {
        "label": "ACAC - Acri Capital Acquisition Corp",
        "value": "ACAC"
    },
    {
        "label": "FIXX - Homology Medicines, Inc.",
        "value": "FIXX"
    },
    {
        "label": "TOI - Oncology Institute, Inc.",
        "value": "TOI"
    },
    {
        "label": "CMCM - Cheetah Mobile Inc.",
        "value": "CMCM"
    },
    {
        "label": "BTQQF - Sonora Gold & Silver Corp.",
        "value": "BTQQF"
    },
    {
        "label": "FEIM - FREQUENCY ELECTRONICS INC",
        "value": "FEIM"
    },
    {
        "label": "INTE - Integral Acquisition Corp 1",
        "value": "INTE"
    },
    {
        "label": "MUGH - MU GLOBAL HOLDING Ltd",
        "value": "MUGH"
    },
    {
        "label": "ROSE - Rose Hill Acquisition Corp",
        "value": "ROSE"
    },
    {
        "label": "MFH - Mercurity Fintech Holding Inc.",
        "value": "MFH"
    },
    {
        "label": "PMVC - PMV Consumer Acquisition Corp.",
        "value": "PMVC"
    },
    {
        "label": "BCLI - BRAINSTORM CELL THERAPEUTICS INC.",
        "value": "BCLI"
    },
    {
        "label": "IHTA - Invesco High Income 2024 Target Term Fund",
        "value": "IHTA"
    },
    {
        "label": "UEEC - United Health Products, Inc.",
        "value": "UEEC"
    },
    {
        "label": "CRIS - CURIS INC",
        "value": "CRIS"
    },
    {
        "label": "AP - AMPCO PITTSBURGH CORP",
        "value": "AP"
    },
    {
        "label": "HSON - Hudson Global, Inc.",
        "value": "HSON"
    },
    {
        "label": "PNYG - Pony Group Inc.",
        "value": "PNYG"
    },
    {
        "label": "NMS - Nuveen Minnesota Quality Municipal Income Fund",
        "value": "NMS"
    },
    {
        "label": "OLIT - OmniLit Acquisition Corp.",
        "value": "OLIT"
    },
    {
        "label": "LPTH - LIGHTPATH TECHNOLOGIES INC",
        "value": "LPTH"
    },
    {
        "label": "TTP - TORTOISE PIPELINE & ENERGY FUND, INC.",
        "value": "TTP"
    },
    {
        "label": "AGSS - AMERIGUARD SECURITY SERVICES, INC.",
        "value": "AGSS"
    },
    {
        "label": "GVXXF - GoviEx Uranium Inc.",
        "value": "GVXXF"
    },
    {
        "label": "NHTC - NATURAL HEALTH TRENDS CORP",
        "value": "NHTC"
    },
    {
        "label": "WMC - Western Asset Mortgage Capital Corp",
        "value": "WMC"
    },
    {
        "label": "ARBK - Argo Blockchain Plc",
        "value": "ARBK"
    },
    {
        "label": "FAM - FIRST TRUST/ABRDN GLOBAL OPPORTUNITY INCOME FUND",
        "value": "FAM"
    },
    {
        "label": "KPLT - Katapult Holdings, Inc.",
        "value": "KPLT"
    },
    {
        "label": "CCTS - Cactus Acquisition Corp. 1 Ltd",
        "value": "CCTS"
    },
    {
        "label": "NERV - Minerva Neurosciences, Inc.",
        "value": "NERV"
    },
    {
        "label": "REDW - REDWOOD FINANCIAL INC /MN/",
        "value": "REDW"
    },
    {
        "label": "MOVE - Movano Inc.",
        "value": "MOVE"
    },
    {
        "label": "AIXN - AiXin Life International, Inc.",
        "value": "AIXN"
    },
    {
        "label": "NA - Nano Labs Ltd",
        "value": "NA"
    },
    {
        "label": "LGVN - Longeveron Inc.",
        "value": "LGVN"
    },
    {
        "label": "AIRT - AIR T INC",
        "value": "AIRT"
    },
    {
        "label": "BUKS - BUTLER NATIONAL CORP",
        "value": "BUKS"
    },
    {
        "label": "LSBK - LAKE SHORE BANCORP, INC.",
        "value": "LSBK"
    },
    {
        "label": "DARE - Dare Bioscience, Inc.",
        "value": "DARE"
    },
    {
        "label": "RIDEQ - Lordstown Motors Corp.",
        "value": "RIDEQ"
    },
    {
        "label": "ITAQ - Industrial Tech Acquisitions II, Inc.",
        "value": "ITAQ"
    },
    {
        "label": "ESOA - Energy Services of America CORP",
        "value": "ESOA"
    },
    {
        "label": "ITRG - Integra Resources Corp.",
        "value": "ITRG"
    },
    {
        "label": "QMCO - QUANTUM CORP /DE/",
        "value": "QMCO"
    },
    {
        "label": "ELTP - ELITE PHARMACEUTICALS INC /NV/",
        "value": "ELTP"
    },
    {
        "label": "BDCO - BLUE DOLPHIN ENERGY CO",
        "value": "BDCO"
    },
    {
        "label": "SSIC - Silver Spike Investment Corp.",
        "value": "SSIC"
    },
    {
        "label": "FLNT - Fluent, Inc.",
        "value": "FLNT"
    },
    {
        "label": "NBW - NEUBERGER BERMAN CALIFORNIA MUNICIPAL FUND INC.",
        "value": "NBW"
    },
    {
        "label": "CIZN - CITIZENS HOLDING CO /MS/",
        "value": "CIZN"
    },
    {
        "label": "PEPL - PepperLime Health Acquisition Corp",
        "value": "PEPL"
    },
    {
        "label": "ENCP - Energem Corp",
        "value": "ENCP"
    },
    {
        "label": "PWM - Prestige Wealth Inc.",
        "value": "PWM"
    },
    {
        "label": "KSBI - KS BANCORP INC",
        "value": "KSBI"
    },
    {
        "label": "DYNR - DYNARESOURCE INC",
        "value": "DYNR"
    },
    {
        "label": "PDEX - PRO DEX INC",
        "value": "PDEX"
    },
    {
        "label": "ARKR - ARK RESTAURANTS CORP",
        "value": "ARKR"
    },
    {
        "label": "BAFN - BayFirst Financial Corp.",
        "value": "BAFN"
    },
    {
        "label": "CLSD - Clearside Biomedical, Inc.",
        "value": "CLSD"
    },
    {
        "label": "KEYR - KeyStar Corp.",
        "value": "KEYR"
    },
    {
        "label": "LOGQ - Coyni, Inc.",
        "value": "LOGQ"
    },
    {
        "label": "MBTC - Nocturne Acquisition Corp",
        "value": "MBTC"
    },
    {
        "label": "PNPL - PINEAPPLE, INC.",
        "value": "PNPL"
    },
    {
        "label": "BNIX - Bannix Acquisition Corp.",
        "value": "BNIX"
    },
    {
        "label": "GNS - Genius Group Ltd",
        "value": "GNS"
    },
    {
        "label": "GGE - Green Giant Inc.",
        "value": "GGE"
    },
    {
        "label": "BEAT - HeartBeam, Inc.",
        "value": "BEAT"
    },
    {
        "label": "CLST - Catalyst Bancorp, Inc.",
        "value": "CLST"
    },
    {
        "label": "ASCA - ASPAC I Acquisition Corp.",
        "value": "ASCA"
    },
    {
        "label": "VTVT - vTv Therapeutics Inc.",
        "value": "VTVT"
    },
    {
        "label": "BDL - FLANIGANS ENTERPRISES INC",
        "value": "BDL"
    },
    {
        "label": "PFBX - PEOPLES FINANCIAL CORP /MS/",
        "value": "PFBX"
    },
    {
        "label": "CEE - CENTRAL & EASTERN EUROPE FUND, INC.",
        "value": "CEE"
    },
    {
        "label": "ADAG - Adagene Inc.",
        "value": "ADAG"
    },
    {
        "label": "ICMB - Investcorp Credit Management BDC, Inc.",
        "value": "ICMB"
    },
    {
        "label": "LUCD - Lucid Diagnostics Inc.",
        "value": "LUCD"
    },
    {
        "label": "MRKR - Marker Therapeutics, Inc.",
        "value": "MRKR"
    },
    {
        "label": "LRFC - Logan Ridge Finance Corp.",
        "value": "LRFC"
    },
    {
        "label": "CRDL - Cardiol Therapeutics Inc.",
        "value": "CRDL"
    },
    {
        "label": "EEA - EUROPEAN EQUITY FUND, INC / MD",
        "value": "EEA"
    },
    {
        "label": "VGZ - VISTA GOLD CORP",
        "value": "VGZ"
    },
    {
        "label": "PTWO - Pono Capital Two, Inc.",
        "value": "PTWO"
    },
    {
        "label": "CETY - Clean Energy Technologies, Inc.",
        "value": "CETY"
    },
    {
        "label": "INKT - MiNK Therapeutics, Inc.",
        "value": "INKT"
    },
    {
        "label": "ICAD - ICAD INC",
        "value": "ICAD"
    },
    {
        "label": "SHUA - SHUAA Partners Acquisition Corp I",
        "value": "SHUA"
    },
    {
        "label": "RGT - ROYCE GLOBAL VALUE TRUST, INC.",
        "value": "RGT"
    },
    {
        "label": "BNET - BION ENVIRONMENTAL TECHNOLOGIES INC",
        "value": "BNET"
    },
    {
        "label": "BHM - Bluerock Homes Trust, Inc.",
        "value": "BHM"
    },
    {
        "label": "ZYRX - GLOBAL EARNINGS CAPITAL LTD.",
        "value": "ZYRX"
    },
    {
        "label": "MTMV - Motomova Inc",
        "value": "MTMV"
    },
    {
        "label": "CCM - Concord Medical Services Holdings Ltd",
        "value": "CCM"
    },
    {
        "label": "SELF - Global Self Storage, Inc.",
        "value": "SELF"
    },
    {
        "label": "IOBT - IO Biotech, Inc.",
        "value": "IOBT"
    },
    {
        "label": "TRIQ - TRAQIQ, INC.",
        "value": "TRIQ"
    },
    {
        "label": "HHGC - HHG Capital Corp",
        "value": "HHGC"
    },
    {
        "label": "SONX - Sonendo, Inc.",
        "value": "SONX"
    },
    {
        "label": "HUDI - Huadi International Group Co., Ltd.",
        "value": "HUDI"
    },
    {
        "label": "NDP - TORTOISE ENERGY INDEPENDENCE FUND, INC.",
        "value": "NDP"
    },
    {
        "label": "SURF - Surface Oncology, Inc.",
        "value": "SURF"
    },
    {
        "label": "HOUR - Hour Loop, Inc",
        "value": "HOUR"
    },
    {
        "label": "HOOK - HOOKIPA Pharma Inc.",
        "value": "HOOK"
    },
    {
        "label": "VANI - Vivani Medical, Inc.",
        "value": "VANI"
    },
    {
        "label": "HRGN - Harvard Apparatus Regenerative Technology, Inc.",
        "value": "HRGN"
    },
    {
        "label": "IBTN - InsCorp Inc",
        "value": "IBTN"
    },
    {
        "label": "RGS - REGIS CORP",
        "value": "RGS"
    },
    {
        "label": "RZLT - Rezolute, Inc.",
        "value": "RZLT"
    },
    {
        "label": "ORPB - OREGON PACIFIC BANCORP",
        "value": "ORPB"
    },
    {
        "label": "DUNE - Dune Acquisition Corp",
        "value": "DUNE"
    },
    {
        "label": "EXPR - EXPRESS, INC.",
        "value": "EXPR"
    },
    {
        "label": "RSKIA - GEORGE RISK INDUSTRIES, INC.",
        "value": "RSKIA"
    },
    {
        "label": "MKTW - MARKETWISE, INC.",
        "value": "MKTW"
    },
    {
        "label": "ASRV - AMERISERV FINANCIAL INC /PA/",
        "value": "ASRV"
    },
    {
        "label": "SCTL - Societal CDMO, Inc.",
        "value": "SCTL"
    },
    {
        "label": "NUKK - Nukkleus Inc.",
        "value": "NUKK"
    },
    {
        "label": "JMM - Nuveen Multi-Market Income Fund",
        "value": "JMM"
    },
    {
        "label": "TAOP - Taoping Inc.",
        "value": "TAOP"
    },
    {
        "label": "SMAP - Sportsmap Tech Acquisition Corp.",
        "value": "SMAP"
    },
    {
        "label": "NANX - NANOPHASE TECHNOLOGIES Corp",
        "value": "NANX"
    },
    {
        "label": "RCAT - Red Cat Holdings, Inc.",
        "value": "RCAT"
    },
    {
        "label": "FBIO - Fortress Biotech, Inc.",
        "value": "FBIO"
    },
    {
        "label": "VASO - VASO Corp",
        "value": "VASO"
    },
    {
        "label": "AEYE - AUDIOEYE INC",
        "value": "AEYE"
    },
    {
        "label": "ADES - Advanced Emissions Solutions, Inc.",
        "value": "ADES"
    },
    {
        "label": "DYAI - DYADIC INTERNATIONAL INC",
        "value": "DYAI"
    },
    {
        "label": "CELU - Celularity Inc",
        "value": "CELU"
    },
    {
        "label": "TOON - Kartoon Studios, Inc.",
        "value": "TOON"
    },
    {
        "label": "RACY - Relativity Acquisition Corp",
        "value": "RACY"
    },
    {
        "label": "DCTH - DELCATH SYSTEMS, INC.",
        "value": "DCTH"
    },
    {
        "label": "MCAF - Mountain Crest Acquisition Corp. IV",
        "value": "MCAF"
    },
    {
        "label": "EDI - Virtus Stone Harbor Emerging Markets Total Income Fund",
        "value": "EDI"
    },
    {
        "label": "MKUL - Molekule Group, Inc.",
        "value": "MKUL"
    },
    {
        "label": "RFL - Rafael Holdings, Inc.",
        "value": "RFL"
    },
    {
        "label": "ARBB - ARB IOT Group Ltd",
        "value": "ARBB"
    },
    {
        "label": "NWPP - NEW PEOPLES BANKSHARES INC",
        "value": "NWPP"
    },
    {
        "label": "IROQ - IF Bancorp, Inc.",
        "value": "IROQ"
    },
    {
        "label": "ISPO - Inspirato Inc",
        "value": "ISPO"
    },
    {
        "label": "MRDB - MariaDB plc",
        "value": "MRDB"
    },
    {
        "label": "SNAL - Snail, Inc.",
        "value": "SNAL"
    },
    {
        "label": "LODE - Comstock Inc.",
        "value": "LODE"
    },
    {
        "label": "CBKM - CONSUMERS BANCORP INC /OH/",
        "value": "CBKM"
    },
    {
        "label": "HNNA - HENNESSY ADVISORS INC",
        "value": "HNNA"
    },
    {
        "label": "DMAQ - Deep Medicine Acquisition Corp.",
        "value": "DMAQ"
    },
    {
        "label": "CHKR - CHESAPEAKE GRANITE WASH TRUST",
        "value": "CHKR"
    },
    {
        "label": "GRFX - Graphex Group Ltd",
        "value": "GRFX"
    },
    {
        "label": "REFR - RESEARCH FRONTIERS INC",
        "value": "REFR"
    },
    {
        "label": "AGBA - AGBA Group Holding Ltd.",
        "value": "AGBA"
    },
    {
        "label": "LOAN - MANHATTAN BRIDGE CAPITAL, INC",
        "value": "LOAN"
    },
    {
        "label": "AIRRF - Aurion Resources Ltd.",
        "value": "AIRRF"
    },
    {
        "label": "SRTS - Sensus Healthcare, Inc.",
        "value": "SRTS"
    },
    {
        "label": "LTBR - LIGHTBRIDGE Corp",
        "value": "LTBR"
    },
    {
        "label": "KTCC - KEY TRONIC CORP",
        "value": "KTCC"
    },
    {
        "label": "HUGE - FSD Pharma Inc.",
        "value": "HUGE"
    },
    {
        "label": "LQMT - LIQUIDMETAL TECHNOLOGIES INC",
        "value": "LQMT"
    },
    {
        "label": "KWIK - KwikClick, Inc.",
        "value": "KWIK"
    },
    {
        "label": "NTIP - NETWORK-1 TECHNOLOGIES, INC.",
        "value": "NTIP"
    },
    {
        "label": "GIFI - GULF ISLAND FABRICATION INC",
        "value": "GIFI"
    },
    {
        "label": "JOAN - JOANN Inc.",
        "value": "JOAN"
    },
    {
        "label": "AURX - Nuo Therapeutics, Inc.",
        "value": "AURX"
    },
    {
        "label": "WHEN - WORLD HEALTH ENERGY HOLDINGS, INC.",
        "value": "WHEN"
    },
    {
        "label": "BWAY - Brainsway Ltd.",
        "value": "BWAY"
    },
    {
        "label": "IPA - ImmunoPrecise Antibodies Ltd.",
        "value": "IPA"
    },
    {
        "label": "MVLA - Movella Holdings Inc.",
        "value": "MVLA"
    },
    {
        "label": "ICCH - ICC Holdings, Inc.",
        "value": "ICCH"
    },
    {
        "label": "RAIL - FreightCar America, Inc.",
        "value": "RAIL"
    },
    {
        "label": "INVU - Investview, Inc.",
        "value": "INVU"
    },
    {
        "label": "AUGG - AUGUSTA GOLD CORP.",
        "value": "AUGG"
    },
    {
        "label": "DLA - DELTA APPAREL, INC",
        "value": "DLA"
    },
    {
        "label": "MPIR - Empire Diversified Energy Inc",
        "value": "MPIR"
    },
    {
        "label": "SLNO - SOLENO THERAPEUTICS INC",
        "value": "SLNO"
    },
    {
        "label": "FUSB - FIRST US BANCSHARES, INC.",
        "value": "FUSB"
    },
    {
        "label": "DTIL - PRECISION BIOSCIENCES INC",
        "value": "DTIL"
    },
    {
        "label": "BREZ - Breeze Holdings Acquisition Corp.",
        "value": "BREZ"
    },
    {
        "label": "FURY - FURY GOLD MINES LTD",
        "value": "FURY"
    },
    {
        "label": "ONDS - Ondas Holdings Inc.",
        "value": "ONDS"
    },
    {
        "label": "OESX - ORION ENERGY SYSTEMS, INC.",
        "value": "OESX"
    },
    {
        "label": "PNXP - PINEAPPLE EXPRESS CANNABIS Co",
        "value": "PNXP"
    },
    {
        "label": "CURO - CURO Group Holdings Corp.",
        "value": "CURO"
    },
    {
        "label": "HLTH - Cue Health Inc.",
        "value": "HLTH"
    },
    {
        "label": "RSF - RiverNorth Capital & Income Fund, Inc.",
        "value": "RSF"
    },
    {
        "label": "CRWS - CROWN CRAFTS INC",
        "value": "CRWS"
    },
    {
        "label": "FARM - FARMER BROTHERS CO",
        "value": "FARM"
    },
    {
        "label": "CFOO - China Foods Holdings Ltd.",
        "value": "CFOO"
    },
    {
        "label": "OTEC - OceanTech Acquisitions I Corp.",
        "value": "OTEC"
    },
    {
        "label": "SMDRF - SIERRA MADRE GOLD & SILVER LTD.",
        "value": "SMDRF"
    },
    {
        "label": "EPOW - Sunrise New Energy Co., Ltd.",
        "value": "EPOW"
    },
    {
        "label": "BLGO - BIOLARGO, INC.",
        "value": "BLGO"
    },
    {
        "label": "VWE - Vintage Wine Estates, Inc.",
        "value": "VWE"
    },
    {
        "label": "HTY - John Hancock Tax-Advantaged Global Shareholder Yield Fund",
        "value": "HTY"
    },
    {
        "label": "JZ - Jianzhi Education Technology Group Co Ltd",
        "value": "JZ"
    },
    {
        "label": "UWHR - UWHARRIE CAPITAL CORP",
        "value": "UWHR"
    },
    {
        "label": "DHAC - DIGITAL HEALTH ACQUISITION CORP.",
        "value": "DHAC"
    },
    {
        "label": "HYFM - HYDROFARM HOLDINGS GROUP, INC.",
        "value": "HYFM"
    },
    {
        "label": "IOR - INCOME OPPORTUNITY REALTY INVESTORS INC /TX/",
        "value": "IOR"
    },
    {
        "label": "CLRC - ClimateRock",
        "value": "CLRC"
    },
    {
        "label": "SAGA - Sagaliam Acquisition Corp",
        "value": "SAGA"
    },
    {
        "label": "VSAC - VISION SENSING ACQUISITION CORP.",
        "value": "VSAC"
    },
    {
        "label": "APT - ALPHA PRO TECH LTD",
        "value": "APT"
    },
    {
        "label": "HMAC - Hainan Manaslu Acquisition Corp.",
        "value": "HMAC"
    },
    {
        "label": "RVYL - RYVYL Inc.",
        "value": "RVYL"
    },
    {
        "label": "MTRY - Monterey Innovation Acquisition Corp",
        "value": "MTRY"
    },
    {
        "label": "BOTJ - BANK OF THE JAMES FINANCIAL GROUP INC",
        "value": "BOTJ"
    },
    {
        "label": "SZSMF - Santacruz Silver Mining Ltd.",
        "value": "SZSMF"
    },
    {
        "label": "JRSS - JRSIS HEALTH CARE Corp",
        "value": "JRSS"
    },
    {
        "label": "ZAPP - Zapp Electric Vehicles Group Ltd",
        "value": "ZAPP"
    },
    {
        "label": "OTRK - Ontrak, Inc.",
        "value": "OTRK"
    },
    {
        "label": "CNTB - Connect Biopharma Holdings Ltd",
        "value": "CNTB"
    },
    {
        "label": "VEV - VICINITY MOTOR CORP",
        "value": "VEV"
    },
    {
        "label": "CCEL - CRYO CELL INTERNATIONAL INC",
        "value": "CCEL"
    },
    {
        "label": "BEST - BEST Inc.",
        "value": "BEST"
    },
    {
        "label": "APRN - Blue Apron Holdings, Inc.",
        "value": "APRN"
    },
    {
        "label": "GROW - U S GLOBAL INVESTORS INC",
        "value": "GROW"
    },
    {
        "label": "NASO - Naples Soap Company, Inc.",
        "value": "NASO"
    },
    {
        "label": "FGB - FIRST TRUST SPECIALTY FINANCE & FINANCIAL OPPORTUNITIES FUND",
        "value": "FGB"
    },
    {
        "label": "HUBC - Hub Cyber Security Ltd.",
        "value": "HUBC"
    },
    {
        "label": "SPAZF - SPANISH MOUNTAIN GOLD LTD.",
        "value": "SPAZF"
    },
    {
        "label": "MFON - MOBIVITY HOLDINGS CORP.",
        "value": "MFON"
    },
    {
        "label": "GORO - GOLD RESOURCE CORP",
        "value": "GORO"
    },
    {
        "label": "EBON - Ebang International Holdings Inc.",
        "value": "EBON"
    },
    {
        "label": "ASMB - ASSEMBLY BIOSCIENCES, INC.",
        "value": "ASMB"
    },
    {
        "label": "NSTS - NSTS Bancorp, Inc.",
        "value": "NSTS"
    },
    {
        "label": "STIM - Neuronetics, Inc.",
        "value": "STIM"
    },
    {
        "label": "FRCB - FIRST REPUBLIC BANK",
        "value": "FRCB"
    },
    {
        "label": "BCAN - BYND CANNASOFT ENTERPRISES INC.",
        "value": "BCAN"
    },
    {
        "label": "NXPL - NextPlat Corp",
        "value": "NXPL"
    },
    {
        "label": "MGTA - Magenta Therapeutics, Inc.",
        "value": "MGTA"
    },
    {
        "label": "CVU - CPI AEROSTRUCTURES INC",
        "value": "CVU"
    },
    {
        "label": "IDN - Intellicheck, Inc.",
        "value": "IDN"
    },
    {
        "label": "VIRX - Viracta Therapeutics, Inc.",
        "value": "VIRX"
    },
    {
        "label": "AKA - A.K.A. BRANDS HOLDING CORP.",
        "value": "AKA"
    },
    {
        "label": "FMY - FIRST TRUST MORTGAGE INCOME FUND",
        "value": "FMY"
    },
    {
        "label": "NBO - NEUBERGER BERMAN NEW YORK MUNICIPAL FUND INC.",
        "value": "NBO"
    },
    {
        "label": "XOS - Xos, Inc.",
        "value": "XOS"
    },
    {
        "label": "LIDR - AEye, Inc.",
        "value": "LIDR"
    },
    {
        "label": "LTRN - Lantern Pharma Inc.",
        "value": "LTRN"
    },
    {
        "label": "FRBK - REPUBLIC FIRST BANCORP INC",
        "value": "FRBK"
    },
    {
        "label": "CCAI - Cascadia Acquisition Corp.",
        "value": "CCAI"
    },
    {
        "label": "CFSB - CFSB Bancorp, Inc. /MA/",
        "value": "CFSB"
    },
    {
        "label": "DWSN - DAWSON GEOPHYSICAL CO",
        "value": "DWSN"
    },
    {
        "label": "UAMY - UNITED STATES ANTIMONY CORP",
        "value": "UAMY"
    },
    {
        "label": "HFBL - Home Federal Bancorp, Inc. of Louisiana",
        "value": "HFBL"
    },
    {
        "label": "ICCM - IceCure Medical Ltd.",
        "value": "ICCM"
    },
    {
        "label": "REE - REE Automotive Ltd.",
        "value": "REE"
    },
    {
        "label": "QNCX - Quince Therapeutics, Inc.",
        "value": "QNCX"
    },
    {
        "label": "ENLV - Enlivex Therapeutics Ltd.",
        "value": "ENLV"
    },
    {
        "label": "DMA - Destra Multi-Alternative Fund",
        "value": "DMA"
    },
    {
        "label": "USIO - Usio, Inc.",
        "value": "USIO"
    },
    {
        "label": "BKRRF - BLACKROCK SILVER CORP.",
        "value": "BKRRF"
    },
    {
        "label": "WKSP - Worksport Ltd",
        "value": "WKSP"
    },
    {
        "label": "TURN - 180 DEGREE CAPITAL CORP. /NY/",
        "value": "TURN"
    },
    {
        "label": "NXN - NUVEEN NEW YORK SELECT TAX -FREE INCOME PORTFOLIO",
        "value": "NXN"
    },
    {
        "label": "LVTX - LAVA Therapeutics NV",
        "value": "LVTX"
    },
    {
        "label": "BCOW - 1895 Bancorp of Wisconsin, Inc. /MD/",
        "value": "BCOW"
    },
    {
        "label": "GSD - Global System Dynamics, Inc.",
        "value": "GSD"
    },
    {
        "label": "MOXC - Moxian (BVI) Inc",
        "value": "MOXC"
    },
    {
        "label": "ELTK - ELTEK LTD",
        "value": "ELTK"
    },
    {
        "label": "GANX - Gain Therapeutics, Inc.",
        "value": "GANX"
    },
    {
        "label": "KXIN - Kaixin Auto Holdings",
        "value": "KXIN"
    },
    {
        "label": "BSGM - BioSig Technologies, Inc.",
        "value": "BSGM"
    },
    {
        "label": "NVNO - enVVeno Medical Corp",
        "value": "NVNO"
    },
    {
        "label": "JROOF - Jericho Energy Ventures Inc.",
        "value": "JROOF"
    },
    {
        "label": "DXR - DAXOR CORP",
        "value": "DXR"
    },
    {
        "label": "MYMD - MyMD Pharmaceuticals, Inc.",
        "value": "MYMD"
    },
    {
        "label": "COE - 51Talk Online Education Group",
        "value": "COE"
    },
    {
        "label": "PPBN - PINNACLE BANKSHARES CORP",
        "value": "PPBN"
    },
    {
        "label": "SANW - S&W Seed Co",
        "value": "SANW"
    },
    {
        "label": "BIOF - BLUE BIOFUELS, INC.",
        "value": "BIOF"
    },
    {
        "label": "GETR - Getaround, Inc",
        "value": "GETR"
    },
    {
        "label": "VGAS - Verde Clean Fuels, Inc.",
        "value": "VGAS"
    },
    {
        "label": "WINV - WinVest Acquisition Corp.",
        "value": "WINV"
    },
    {
        "label": "MXE - MEXICO EQUITY & INCOME FUND INC",
        "value": "MXE"
    },
    {
        "label": "VTGN - Vistagen Therapeutics, Inc.",
        "value": "VTGN"
    },
    {
        "label": "GAIA - GAIA, INC",
        "value": "GAIA"
    },
    {
        "label": "BRSH - Bruush Oral Care Inc.",
        "value": "BRSH"
    },
    {
        "label": "SYPR - SYPRIS SOLUTIONS INC",
        "value": "SYPR"
    },
    {
        "label": "NXTC - NextCure, Inc.",
        "value": "NXTC"
    },
    {
        "label": "CURI - CuriosityStream Inc.",
        "value": "CURI"
    },
    {
        "label": "RVIV - Reviv3 Procare Co",
        "value": "RVIV"
    },
    {
        "label": "KFFB - Kentucky First Federal Bancorp",
        "value": "KFFB"
    },
    {
        "label": "GDTC - CytoMed Therapeutics Ltd",
        "value": "GDTC"
    },
    {
        "label": "YQ - 17 Education & Technology Group Inc.",
        "value": "YQ"
    },
    {
        "label": "ETAO - ETAO International Co., Ltd.",
        "value": "ETAO"
    },
    {
        "label": "PXCLY - Phoenix Copper Limited/ADR",
        "value": "PXCLY"
    },
    {
        "label": "GDNR - Gardiner Healthcare Acquisitions Corp.",
        "value": "GDNR"
    },
    {
        "label": "MYNZ - MAINZ BIOMED N.V.",
        "value": "MYNZ"
    },
    {
        "label": "KEQU - KEWAUNEE SCIENTIFIC CORP /DE/",
        "value": "KEQU"
    },
    {
        "label": "CGTX - COGNITION THERAPEUTICS INC",
        "value": "CGTX"
    },
    {
        "label": "BOLT - Bolt Biotherapeutics, Inc.",
        "value": "BOLT"
    },
    {
        "label": "ALMU - Aeluma, Inc.",
        "value": "ALMU"
    },
    {
        "label": "MGLD - Marygold Companies, Inc.",
        "value": "MGLD"
    },
    {
        "label": "MRT - Marti Technologies, Inc.",
        "value": "MRT"
    },
    {
        "label": "AIRG - AIRGAIN INC",
        "value": "AIRG"
    },
    {
        "label": "PRTG - PORTAGE BIOTECH INC.",
        "value": "PRTG"
    },
    {
        "label": "VRAR - Glimpse Group, Inc.",
        "value": "VRAR"
    },
    {
        "label": "CXDO - Crexendo, Inc.",
        "value": "CXDO"
    },
    {
        "label": "NCSM - NCS Multistage Holdings, Inc.",
        "value": "NCSM"
    },
    {
        "label": "ASPI - ASP Isotopes Inc.",
        "value": "ASPI"
    },
    {
        "label": "ACBA - Ace Global Business Acquisition Ltd",
        "value": "ACBA"
    },
    {
        "label": "CTRM - Castor Maritime Inc.",
        "value": "CTRM"
    },
    {
        "label": "MEIP - MEI Pharma, Inc.",
        "value": "MEIP"
    },
    {
        "label": "HOFV - Hall of Fame Resort & Entertainment Co",
        "value": "HOFV"
    },
    {
        "label": "CZOO - Cazoo Group Ltd",
        "value": "CZOO"
    },
    {
        "label": "BTM - Bitcoin Depot Inc.",
        "value": "BTM"
    },
    {
        "label": "CHCI - Comstock Holding Companies, Inc.",
        "value": "CHCI"
    },
    {
        "label": "CVV - CVD EQUIPMENT CORP",
        "value": "CVV"
    },
    {
        "label": "HLGN - Heliogen, Inc.",
        "value": "HLGN"
    },
    {
        "label": "XGN - EXAGEN INC.",
        "value": "XGN"
    },
    {
        "label": "KWAC - Kingswood Acquisition Corp.",
        "value": "KWAC"
    },
    {
        "label": "MPU - Mega Matrix Corp.",
        "value": "MPU"
    },
    {
        "label": "ESP - ESPEY MFG & ELECTRONICS CORP",
        "value": "ESP"
    },
    {
        "label": "ICD - Independence Contract Drilling, Inc.",
        "value": "ICD"
    },
    {
        "label": "EDRY - EuroDry Ltd.",
        "value": "EDRY"
    },
    {
        "label": "RPID - RAPID MICRO BIOSYSTEMS, INC.",
        "value": "RPID"
    },
    {
        "label": "JRSH - Jerash Holdings (US), Inc.",
        "value": "JRSH"
    },
    {
        "label": "PASG - Passage BIO, Inc.",
        "value": "PASG"
    },
    {
        "label": "SABS - SAB Biotherapeutics, Inc.",
        "value": "SABS"
    },
    {
        "label": "HHS - HARTE HANKS INC",
        "value": "HHS"
    },
    {
        "label": "SHWZ - Medicine Man Technologies, Inc.",
        "value": "SHWZ"
    },
    {
        "label": "BIOR - BIORA THERAPEUTICS, INC.",
        "value": "BIOR"
    },
    {
        "label": "ADOC - Edoc Acquisition Corp.",
        "value": "ADOC"
    },
    {
        "label": "MTR - MESA ROYALTY TRUST/TX",
        "value": "MTR"
    },
    {
        "label": "TCBS - Texas Community Bancshares, Inc.",
        "value": "TCBS"
    },
    {
        "label": "DPSI - DecisionPoint Systems, Inc.",
        "value": "DPSI"
    },
    {
        "label": "BCHG - Grayscale Bitcoin Cash Trust (BCH)",
        "value": "BCHG"
    },
    {
        "label": "TECTP - Tectonic Financial, Inc.",
        "value": "TECTP"
    },
    {
        "label": "RSCI - Redwood Scientific Technologies, Inc.",
        "value": "RSCI"
    },
    {
        "label": "OWLT - Owlet, Inc.",
        "value": "OWLT"
    },
    {
        "label": "SLS - SELLAS Life Sciences Group, Inc.",
        "value": "SLS"
    },
    {
        "label": "RMRI - Rocky Mountain Industrials, Inc.",
        "value": "RMRI"
    },
    {
        "label": "DGHI - Digihost Technology Inc.",
        "value": "DGHI"
    },
    {
        "label": "RSTN - RDE, Inc.",
        "value": "RSTN"
    },
    {
        "label": "IDEX - IDEANOMICS, INC.",
        "value": "IDEX"
    },
    {
        "label": "UMEWF - UMeWorld Ltd",
        "value": "UMEWF"
    },
    {
        "label": "AGMH - AGM GROUP HOLDINGS, INC.",
        "value": "AGMH"
    },
    {
        "label": "HLP - Hongli Group Inc.",
        "value": "HLP"
    },
    {
        "label": "BFI - BurgerFi International, Inc.",
        "value": "BFI"
    },
    {
        "label": "OKYO - OKYO Pharma Ltd",
        "value": "OKYO"
    },
    {
        "label": "BLTH - AMERICAN BATTERY MATERIALS, INC.",
        "value": "BLTH"
    },
    {
        "label": "MATH - Metalpha Technology Holding Ltd",
        "value": "MATH"
    },
    {
        "label": "MMTIF - MICROMEM TECHNOLOGIES INC",
        "value": "MMTIF"
    },
    {
        "label": "PTZH - Photozou Holdings, Inc.",
        "value": "PTZH"
    },
    {
        "label": "DSWL - DESWELL INDUSTRIES INC",
        "value": "DSWL"
    },
    {
        "label": "PVCT - PROVECTUS BIOPHARMACEUTICALS, INC.",
        "value": "PVCT"
    },
    {
        "label": "XPL - SOLITARIO RESOURCES CORP.",
        "value": "XPL"
    },
    {
        "label": "AIB - AIB Acquisition Corp",
        "value": "AIB"
    },
    {
        "label": "MIRO - Miromatrix Medical Inc.",
        "value": "MIRO"
    },
    {
        "label": "FKWL - FRANKLIN WIRELESS CORP",
        "value": "FKWL"
    },
    {
        "label": "AAME - ATLANTIC AMERICAN CORP",
        "value": "AAME"
    },
    {
        "label": "NOVV - Nova Vision Acquisition Corp",
        "value": "NOVV"
    },
    {
        "label": "NCNA - NuCana plc",
        "value": "NCNA"
    },
    {
        "label": "GCEH - Global Clean Energy Holdings, Inc.",
        "value": "GCEH"
    },
    {
        "label": "LNSR - LENSAR, Inc.",
        "value": "LNSR"
    },
    {
        "label": "USEG - US ENERGY CORP",
        "value": "USEG"
    },
    {
        "label": "UTRS - MINERVA SURGICAL INC",
        "value": "UTRS"
    },
    {
        "label": "CPSH - CPS TECHNOLOGIES CORP/DE/",
        "value": "CPSH"
    },
    {
        "label": "TKLF - Yoshitsu Co., Ltd",
        "value": "TKLF"
    },
    {
        "label": "FVTI - Fortune Valley Treasures, Inc.",
        "value": "FVTI"
    },
    {
        "label": "PXS - Pyxis Tankers Inc.",
        "value": "PXS"
    },
    {
        "label": "DERM - Journey Medical Corp",
        "value": "DERM"
    },
    {
        "label": "TISI - TEAM INC",
        "value": "TISI"
    },
    {
        "label": "AACG - ATA Creativity Global",
        "value": "AACG"
    },
    {
        "label": "CODX - Co-Diagnostics, Inc.",
        "value": "CODX"
    },
    {
        "label": "PYYX - PYXUS INTERNATIONAL, INC.",
        "value": "PYYX"
    },
    {
        "label": "UP - Wheels Up Experience Inc.",
        "value": "UP"
    },
    {
        "label": "ICCC - IMMUCELL CORP /DE/",
        "value": "ICCC"
    },
    {
        "label": "ZENV - Zenvia Inc.",
        "value": "ZENV"
    },
    {
        "label": "PLAG - Planet Green Holdings Corp.",
        "value": "PLAG"
    },
    {
        "label": "RFIL - R F INDUSTRIES LTD",
        "value": "RFIL"
    },
    {
        "label": "ALGS - Aligos Therapeutics, Inc.",
        "value": "ALGS"
    },
    {
        "label": "MPTI - M-tron Industries, Inc.",
        "value": "MPTI"
    },
    {
        "label": "BWVI - PSYCHECEUTICAL BIOSCIENCE, INC.",
        "value": "BWVI"
    },
    {
        "label": "BYSI - BeyondSpring Inc.",
        "value": "BYSI"
    },
    {
        "label": "WAVS - Western Acquisition Ventures Corp.",
        "value": "WAVS"
    },
    {
        "label": "TRBMF - TORQ RESOURCES INC.",
        "value": "TRBMF"
    },
    {
        "label": "MNDO - MIND CTI LTD",
        "value": "MNDO"
    },
    {
        "label": "NM - Navios Maritime Holdings Inc.",
        "value": "NM"
    },
    {
        "label": "EFTR - eFFECTOR Therapeutics, Inc.",
        "value": "EFTR"
    },
    {
        "label": "EGF - BlackRock Enhanced Government Fund, Inc.",
        "value": "EGF"
    },
    {
        "label": "FSEA - First Seacoast Bancorp, Inc.",
        "value": "FSEA"
    },
    {
        "label": "JFU - 9F Inc.",
        "value": "JFU"
    },
    {
        "label": "TXMD - TherapeuticsMD, Inc.",
        "value": "TXMD"
    },
    {
        "label": "MLYF - Western Magnesium Corp.",
        "value": "MLYF"
    },
    {
        "label": "MTNB - Matinas BioPharma Holdings, Inc.",
        "value": "MTNB"
    },
    {
        "label": "SUNW - Sunworks, Inc.",
        "value": "SUNW"
    },
    {
        "label": "CWBHF - Charlotte's Web Holdings, Inc.",
        "value": "CWBHF"
    },
    {
        "label": "CLIR - ClearSign Technologies Corp",
        "value": "CLIR"
    },
    {
        "label": "CTIB - Yunhong CTI Ltd.",
        "value": "CTIB"
    },
    {
        "label": "ZNOG - ZION OIL & GAS INC",
        "value": "ZNOG"
    },
    {
        "label": "SGMA - SIGMATRON INTERNATIONAL INC",
        "value": "SGMA"
    },
    {
        "label": "BANL - CBL International Ltd",
        "value": "BANL"
    },
    {
        "label": "FATP - FAT PROJECTS ACQUISITION CORP",
        "value": "FATP"
    },
    {
        "label": "RWLK - ReWalk Robotics Ltd.",
        "value": "RWLK"
    },
    {
        "label": "KNW - KNOW LABS, INC.",
        "value": "KNW"
    },
    {
        "label": "OMIC - Singular Genomics Systems, Inc.",
        "value": "OMIC"
    },
    {
        "label": "AITX - Artificial Intelligence Technology Solutions Inc.",
        "value": "AITX"
    },
    {
        "label": "INDO - Indonesia Energy Corp Ltd",
        "value": "INDO"
    },
    {
        "label": "LIZI - LIZHI INC.",
        "value": "LIZI"
    },
    {
        "label": "PBBK - PB Bankshares, Inc.",
        "value": "PBBK"
    },
    {
        "label": "XELB - XCel Brands, Inc.",
        "value": "XELB"
    },
    {
        "label": "WWR - WESTWATER RESOURCES, INC.",
        "value": "WWR"
    },
    {
        "label": "POCI - PRECISION OPTICS CORPORATION, INC.",
        "value": "POCI"
    },
    {
        "label": "RAIN - Rain Oncology Inc.",
        "value": "RAIN"
    },
    {
        "label": "BIMT - CAMBELL INTERNATIONAL HOLDING CORP.",
        "value": "BIMT"
    },
    {
        "label": "SFRX - SEAFARER EXPLORATION CORP",
        "value": "SFRX"
    },
    {
        "label": "FGNV - FORGE INNOVATION DEVELOPMENT CORP.",
        "value": "FGNV"
    },
    {
        "label": "AIH - Aesthetic Medical International Holdings Group Ltd",
        "value": "AIH"
    },
    {
        "label": "WBQNL - Woodbridge Liquidation Trust",
        "value": "WBQNL"
    },
    {
        "label": "KIRK - KIRKLAND'S, INC",
        "value": "KIRK"
    },
    {
        "label": "QNTO - QUAINT OAK BANCORP INC",
        "value": "QNTO"
    },
    {
        "label": "ACAX - Alset Capital Acquisition Corp.",
        "value": "ACAX"
    },
    {
        "label": "BBGI - BEASLEY BROADCAST GROUP INC",
        "value": "BBGI"
    },
    {
        "label": "SNCE - Science 37 Holdings, Inc.",
        "value": "SNCE"
    },
    {
        "label": "UTSI - UTSTARCOM HOLDINGS CORP.",
        "value": "UTSI"
    },
    {
        "label": "GRF - EAGLE CAPITAL GROWTH FUND, INC.",
        "value": "GRF"
    },
    {
        "label": "JANL - JANEL CORP",
        "value": "JANL"
    },
    {
        "label": "DUOT - DUOS TECHNOLOGIES GROUP, INC.",
        "value": "DUOT"
    },
    {
        "label": "HSTI - High Sierra Technologies, Inc.",
        "value": "HSTI"
    },
    {
        "label": "ICCT - iCoreConnect Inc.",
        "value": "ICCT"
    },
    {
        "label": "HCNWF - Hypercharge Networks Corp.",
        "value": "HCNWF"
    },
    {
        "label": "VMAR - Vision Marine Technologies Inc.",
        "value": "VMAR"
    },
    {
        "label": "ACHL - Achilles Therapeutics plc",
        "value": "ACHL"
    },
    {
        "label": "EVTV - Envirotech Vehicles, Inc.",
        "value": "EVTV"
    },
    {
        "label": "AGLE - Aeglea BioTherapeutics, Inc.",
        "value": "AGLE"
    },
    {
        "label": "OSS - ONE STOP SYSTEMS, INC.",
        "value": "OSS"
    },
    {
        "label": "UBX - Unity Biotechnology, Inc.",
        "value": "UBX"
    },
    {
        "label": "KRKR - 36Kr Holdings Inc.",
        "value": "KRKR"
    },
    {
        "label": "APWC - ASIA PACIFIC WIRE & CABLE CORP LTD",
        "value": "APWC"
    },
    {
        "label": "LONCF - Loncor Gold Inc.",
        "value": "LONCF"
    },
    {
        "label": "XPON - Expion360 Inc.",
        "value": "XPON"
    },
    {
        "label": "RAND - RAND CAPITAL CORP",
        "value": "RAND"
    },
    {
        "label": "PHUN - Phunware, Inc.",
        "value": "PHUN"
    },
    {
        "label": "TLF - TANDY LEATHER FACTORY INC",
        "value": "TLF"
    },
    {
        "label": "USAU - U.S. GOLD CORP.",
        "value": "USAU"
    },
    {
        "label": "KTEL - KonaTel, Inc.",
        "value": "KTEL"
    },
    {
        "label": "UURAF - Ucore Rare Metals Inc.",
        "value": "UURAF"
    },
    {
        "label": "AMAO - American Acquisition Opportunity Inc.",
        "value": "AMAO"
    },
    {
        "label": "DRCT - Direct Digital Holdings, Inc.",
        "value": "DRCT"
    },
    {
        "label": "SYBX - SYNLOGIC, INC.",
        "value": "SYBX"
    },
    {
        "label": "UG - UNITED GUARDIAN INC",
        "value": "UG"
    },
    {
        "label": "PYN - PIMCO NEW YORK MUNICIPAL INCOME FUND III",
        "value": "PYN"
    },
    {
        "label": "CKPT - Checkpoint Therapeutics, Inc.",
        "value": "CKPT"
    },
    {
        "label": "NICK - NICHOLAS FINANCIAL INC",
        "value": "NICK"
    },
    {
        "label": "EVGN - Evogene Ltd.",
        "value": "EVGN"
    },
    {
        "label": "ASLN - ASLAN Pharmaceuticals Ltd",
        "value": "ASLN"
    },
    {
        "label": "KBLB - Kraig Biocraft Laboratories, Inc",
        "value": "KBLB"
    },
    {
        "label": "UPC - Universe Pharmaceuticals INC",
        "value": "UPC"
    },
    {
        "label": "VNCE - VINCE HOLDING CORP.",
        "value": "VNCE"
    },
    {
        "label": "INAB - IN8BIO, INC.",
        "value": "INAB"
    },
    {
        "label": "LITM - Snow Lake Resources Ltd.",
        "value": "LITM"
    },
    {
        "label": "CGA - China Green Agriculture, Inc.",
        "value": "CGA"
    },
    {
        "label": "DRTT - DIRTT ENVIRONMENTAL SOLUTIONS LTD",
        "value": "DRTT"
    },
    {
        "label": "BKTI - BK Technologies Corp",
        "value": "BKTI"
    },
    {
        "label": "NAII - NATURAL ALTERNATIVES INTERNATIONAL INC",
        "value": "NAII"
    },
    {
        "label": "KOSS - KOSS CORP",
        "value": "KOSS"
    },
    {
        "label": "FTEK - FUEL TECH, INC.",
        "value": "FTEK"
    },
    {
        "label": "TBIO - Telesis Bio Inc.",
        "value": "TBIO"
    },
    {
        "label": "INIS - INTERNATIONAL ISOTOPES INC",
        "value": "INIS"
    },
    {
        "label": "MSCLF - Satellos Bioscience Inc.",
        "value": "MSCLF"
    },
    {
        "label": "PNBK - PATRIOT NATIONAL BANCORP INC",
        "value": "PNBK"
    },
    {
        "label": "AGAE - Allied Gaming & Entertainment Inc.",
        "value": "AGAE"
    },
    {
        "label": "AVHI - Achari Ventures Holdings Corp. I",
        "value": "AVHI"
    },
    {
        "label": "COYA - Coya Therapeutics, Inc.",
        "value": "COYA"
    },
    {
        "label": "JETMF - Global Crossing Airlines Group Inc.",
        "value": "JETMF"
    },
    {
        "label": "RMCF - Rocky Mountain Chocolate Factory, Inc.",
        "value": "RMCF"
    },
    {
        "label": "SSVFF - SOUTHERN SILVER EXPLORATION CORP",
        "value": "SSVFF"
    },
    {
        "label": "RVP - RETRACTABLE TECHNOLOGIES INC",
        "value": "RVP"
    },
    {
        "label": "PHXM - PHAXIAM Therapeutics S.A.",
        "value": "PHXM"
    },
    {
        "label": "GSMG - GLORY STAR NEW MEDIA GROUP HOLDINGS Ltd",
        "value": "GSMG"
    },
    {
        "label": "GAME - GameSquare Holdings, Inc.",
        "value": "GAME"
    },
    {
        "label": "NGLD - Nevada Canyon Gold Corp.",
        "value": "NGLD"
    },
    {
        "label": "HGTXU - HUGOTON ROYALTY TRUST",
        "value": "HGTXU"
    },
    {
        "label": "UGEIF - UGE International Ltd.",
        "value": "UGEIF"
    },
    {
        "label": "IZEA - IZEA Worldwide, Inc.",
        "value": "IZEA"
    },
    {
        "label": "FSI - FLEXIBLE SOLUTIONS INTERNATIONAL INC",
        "value": "FSI"
    },
    {
        "label": "ATIP - ATI Physical Therapy, Inc.",
        "value": "ATIP"
    },
    {
        "label": "DSS - DSS, INC.",
        "value": "DSS"
    },
    {
        "label": "OMH - Ohmyhome Ltd",
        "value": "OMH"
    },
    {
        "label": "DAIO - DATA I/O CORP",
        "value": "DAIO"
    },
    {
        "label": "GUER - Guerrilla RF, Inc.",
        "value": "GUER"
    },
    {
        "label": "NOWG - Nowigence Inc.",
        "value": "NOWG"
    },
    {
        "label": "ASPA - Abri SPAC I, Inc.",
        "value": "ASPA"
    },
    {
        "label": "PATI - PATRIOT TRANSPORTATION HOLDING, INC.",
        "value": "PATI"
    },
    {
        "label": "BMTX - BM Technologies, Inc.",
        "value": "BMTX"
    },
    {
        "label": "ALPP - ALPINE 4 HOLDINGS, INC.",
        "value": "ALPP"
    },
    {
        "label": "UWHGF - United World Holding Group Ltd.",
        "value": "UWHGF"
    },
    {
        "label": "SPI - SPI Energy Co., Ltd.",
        "value": "SPI"
    },
    {
        "label": "MMV - MultiMetaVerse Holdings Ltd",
        "value": "MMV"
    },
    {
        "label": "RLFTY - Relief Therapeutics Holding SA",
        "value": "RLFTY"
    },
    {
        "label": "SIEN - Sientra, Inc.",
        "value": "SIEN"
    },
    {
        "label": "AVNI - ARVANA INC",
        "value": "AVNI"
    },
    {
        "label": "VICP - Vicapsys Life Sciences, Inc.",
        "value": "VICP"
    },
    {
        "label": "CVVUF - CANALASKA URANIUM LTD",
        "value": "CVVUF"
    },
    {
        "label": "KALA - KALA BIO, Inc.",
        "value": "KALA"
    },
    {
        "label": "SAI - SAI.TECH Global Corp",
        "value": "SAI"
    },
    {
        "label": "GFAI - Guardforce AI Co., Ltd.",
        "value": "GFAI"
    },
    {
        "label": "WKEY - Wisekey International Holding S.A.",
        "value": "WKEY"
    },
    {
        "label": "HTOO - Fusion Fuel Green PLC",
        "value": "HTOO"
    },
    {
        "label": "NLS - NAUTILUS, INC.",
        "value": "NLS"
    },
    {
        "label": "FGH - FG Group Holdings Inc.",
        "value": "FGH"
    },
    {
        "label": "GNPX - Genprex, Inc.",
        "value": "GNPX"
    },
    {
        "label": "CADL - Candel Therapeutics, Inc.",
        "value": "CADL"
    },
    {
        "label": "XXII - 22nd Century Group, Inc.",
        "value": "XXII"
    },
    {
        "label": "IQST - iQSTEL Inc",
        "value": "IQST"
    },
    {
        "label": "INUV - Inuvo, Inc.",
        "value": "INUV"
    },
    {
        "label": "IPDN - Professional Diversity Network, Inc.",
        "value": "IPDN"
    },
    {
        "label": "CTGL - CITRINE GLOBAL, CORP.",
        "value": "CTGL"
    },
    {
        "label": "TCRT - Alaunos Therapeutics, Inc.",
        "value": "TCRT"
    },
    {
        "label": "AWRE - AWARE INC /MA/",
        "value": "AWRE"
    },
    {
        "label": "PIAC - PRINCETON CAPITAL CORP",
        "value": "PIAC"
    },
    {
        "label": "CLAY - Chavant Capital Acquisition Corp.",
        "value": "CLAY"
    },
    {
        "label": "SCTC - Odysight.ai Inc.",
        "value": "SCTC"
    },
    {
        "label": "INFT - Infinity Bancorp",
        "value": "INFT"
    },
    {
        "label": "ARMV - Arma Services Inc",
        "value": "ARMV"
    },
    {
        "label": "GRUSF - Grown Rogue International Inc.",
        "value": "GRUSF"
    },
    {
        "label": "STRC - Sarcos Technology & Robotics Corp",
        "value": "STRC"
    },
    {
        "label": "MAPPF - PROSTAR HOLDINGS INC. /BC",
        "value": "MAPPF"
    },
    {
        "label": "APTO - Aptose Biosciences Inc.",
        "value": "APTO"
    },
    {
        "label": "DWNX - DELHI BANK CORP",
        "value": "DWNX"
    },
    {
        "label": "CXXIF - C21 Investments Inc.",
        "value": "CXXIF"
    },
    {
        "label": "ATER - Aterian, Inc.",
        "value": "ATER"
    },
    {
        "label": "ARVL - Arrival",
        "value": "ARVL"
    },
    {
        "label": "MPAD - MICROPAC INDUSTRIES INC",
        "value": "MPAD"
    },
    {
        "label": "CIF - MFS INTERMEDIATE HIGH INCOME FUND",
        "value": "CIF"
    },
    {
        "label": "SDIG - Stronghold Digital Mining, Inc.",
        "value": "SDIG"
    },
    {
        "label": "WSTRF - Western Uranium & Vanadium Corp.",
        "value": "WSTRF"
    },
    {
        "label": "EXEO - Exeo Entertainment, Inc.",
        "value": "EXEO"
    },
    {
        "label": "QDMI - QDM International Inc.",
        "value": "QDMI"
    },
    {
        "label": "GTIM - Good Times Restaurants Inc.",
        "value": "GTIM"
    },
    {
        "label": "ADN - ADVENT TECHNOLOGIES HOLDINGS, INC.",
        "value": "ADN"
    },
    {
        "label": "NNUP - NOCOPI TECHNOLOGIES INC/MD/",
        "value": "NNUP"
    },
    {
        "label": "ARRKF - ARRAS MINERALS CORP.",
        "value": "ARRKF"
    },
    {
        "label": "PLUR - Pluri Inc.",
        "value": "PLUR"
    },
    {
        "label": "XITO - Xenous Holdings, Inc.",
        "value": "XITO"
    },
    {
        "label": "NLTX - Neoleukin Therapeutics, Inc.",
        "value": "NLTX"
    },
    {
        "label": "ACHN - Antiaging Quantum Living Inc.",
        "value": "ACHN"
    },
    {
        "label": "AZTR - Azitra Inc",
        "value": "AZTR"
    },
    {
        "label": "FRSX - Foresight Autonomous Holdings Ltd.",
        "value": "FRSX"
    },
    {
        "label": "SBEV - SPLASH BEVERAGE GROUP, INC.",
        "value": "SBEV"
    },
    {
        "label": "EIGR - Eiger BioPharmaceuticals, Inc.",
        "value": "EIGR"
    },
    {
        "label": "SNTI - Senti Biosciences, Inc.",
        "value": "SNTI"
    },
    {
        "label": "QLI - Qilian International Holding Group Ltd",
        "value": "QLI"
    },
    {
        "label": "NEON - Neonode Inc.",
        "value": "NEON"
    },
    {
        "label": "RAVE - RAVE RESTAURANT GROUP, INC.",
        "value": "RAVE"
    },
    {
        "label": "CASI - CASI Pharmaceuticals, Inc.",
        "value": "CASI"
    },
    {
        "label": "BCTF - Bancorp 34, Inc.",
        "value": "BCTF"
    },
    {
        "label": "AIM - AIM ImmunoTech Inc.",
        "value": "AIM"
    },
    {
        "label": "SVT - SERVOTRONICS INC /DE/",
        "value": "SVT"
    },
    {
        "label": "BTCM - BIT Mining Ltd",
        "value": "BTCM"
    },
    {
        "label": "MFV - MFS SPECIAL VALUE TRUST",
        "value": "MFV"
    },
    {
        "label": "GREE - Greenidge Generation Holdings Inc.",
        "value": "GREE"
    },
    {
        "label": "PTN - PALATIN TECHNOLOGIES INC",
        "value": "PTN"
    },
    {
        "label": "JUPW - Jupiter Wellness, Inc.",
        "value": "JUPW"
    },
    {
        "label": "SATX - SatixFy Communications Ltd.",
        "value": "SATX"
    },
    {
        "label": "AGE - AgeX Therapeutics, Inc.",
        "value": "AGE"
    },
    {
        "label": "MMNFF - MedMen Enterprises, Inc.",
        "value": "MMNFF"
    },
    {
        "label": "HARP - Harpoon Therapeutics, Inc.",
        "value": "HARP"
    },
    {
        "label": "CACO - Caravelle International Group",
        "value": "CACO"
    },
    {
        "label": "ALIM - ALIMERA SCIENCES INC",
        "value": "ALIM"
    },
    {
        "label": "AINC - Ashford Inc.",
        "value": "AINC"
    },
    {
        "label": "UPXI - UPEXI, INC.",
        "value": "UPXI"
    },
    {
        "label": "ANGH - Anghami Inc",
        "value": "ANGH"
    },
    {
        "label": "MNTS - Momentus Inc.",
        "value": "MNTS"
    },
    {
        "label": "ABIO - ARCA biopharma, Inc.",
        "value": "ABIO"
    },
    {
        "label": "SOBR - SOBR Safe, Inc.",
        "value": "SOBR"
    },
    {
        "label": "WVVI - WILLAMETTE VALLEY VINEYARDS INC",
        "value": "WVVI"
    },
    {
        "label": "LISMF - LITHIUM SOUTH DEVELOPMENT Corp",
        "value": "LISMF"
    },
    {
        "label": "CRBP - Corbus Pharmaceuticals Holdings, Inc.",
        "value": "CRBP"
    },
    {
        "label": "MRM - Medirom Healthcare Technologies Inc.",
        "value": "MRM"
    },
    {
        "label": "AZREF - Azure Power Global Ltd",
        "value": "AZREF"
    },
    {
        "label": "BEDU - Bright Scholar Education Holdings Ltd",
        "value": "BEDU"
    },
    {
        "label": "VBIV - VBI Vaccines Inc/BC",
        "value": "VBIV"
    },
    {
        "label": "AGIL - AgileThought, Inc.",
        "value": "AGIL"
    },
    {
        "label": "CNTG - Centogene N.V.",
        "value": "CNTG"
    },
    {
        "label": "OCX - Oncocyte Corp",
        "value": "OCX"
    },
    {
        "label": "OILY - Sino American Oil Co",
        "value": "OILY"
    },
    {
        "label": "MCAG - Mountain Crest Acquisition Corp. V",
        "value": "MCAG"
    },
    {
        "label": "ZCMD - Zhongchao Inc.",
        "value": "ZCMD"
    },
    {
        "label": "CRMZ - CREDITRISKMONITOR COM INC",
        "value": "CRMZ"
    },
    {
        "label": "BRQS - Borqs Technologies, Inc.",
        "value": "BRQS"
    },
    {
        "label": "COCP - Cocrystal Pharma, Inc.",
        "value": "COCP"
    },
    {
        "label": "SNT - SENSTAR TECHNOLOGIES LTD.",
        "value": "SNT"
    },
    {
        "label": "EVAX - Evaxion Biotech A/S",
        "value": "EVAX"
    },
    {
        "label": "BHLL - Bunker Hill Mining Corp.",
        "value": "BHLL"
    },
    {
        "label": "SNSE - Sensei Biotherapeutics, Inc.",
        "value": "SNSE"
    },
    {
        "label": "ANLDF - ANFIELD ENERGY INC.",
        "value": "ANLDF"
    },
    {
        "label": "DOGZ - Dogness (International) Corp",
        "value": "DOGZ"
    },
    {
        "label": "ELDN - Eledon Pharmaceuticals, Inc.",
        "value": "ELDN"
    },
    {
        "label": "SCND - SCIENTIFIC INDUSTRIES INC",
        "value": "SCND"
    },
    {
        "label": "PGTK - Pacific Green Technologies Inc.",
        "value": "PGTK"
    },
    {
        "label": "EQ - Equillium, Inc.",
        "value": "EQ"
    },
    {
        "label": "VIAO - VIA optronics AG",
        "value": "VIAO"
    },
    {
        "label": "XTGRF - XTRA-GOLD RESOURCES CORP",
        "value": "XTGRF"
    },
    {
        "label": "ILUS - Ilustrato Pictures International Inc.",
        "value": "ILUS"
    },
    {
        "label": "PAVS - Paranovus Entertainment Technology Ltd.",
        "value": "PAVS"
    },
    {
        "label": "XELA - Exela Technologies, Inc.",
        "value": "XELA"
    },
    {
        "label": "HWTR - HFactor, Inc.",
        "value": "HWTR"
    },
    {
        "label": "ZDGE - Zedge, Inc.",
        "value": "ZDGE"
    },
    {
        "label": "QMCI - QUOTEMEDIA INC",
        "value": "QMCI"
    },
    {
        "label": "MDNA - Medicenna Therapeutics Corp.",
        "value": "MDNA"
    },
    {
        "label": "PUBC - PureBase Corp",
        "value": "PUBC"
    },
    {
        "label": "KAVL - Kaival Brands Innovations Group, Inc.",
        "value": "KAVL"
    },
    {
        "label": "OPTT - Ocean Power Technologies, Inc.",
        "value": "OPTT"
    },
    {
        "label": "LIFD - LFTD PARTNERS INC.",
        "value": "LIFD"
    },
    {
        "label": "LEJU - Leju Holdings Ltd",
        "value": "LEJU"
    },
    {
        "label": "HLRTF - Hillcrest Energy Technologies Ltd.",
        "value": "HLRTF"
    },
    {
        "label": "VHC - VirnetX Holding Corp",
        "value": "VHC"
    },
    {
        "label": "CISO - CISO Global, Inc.",
        "value": "CISO"
    },
    {
        "label": "IVFH - INNOVATIVE FOOD HOLDINGS INC",
        "value": "IVFH"
    },
    {
        "label": "MSVB - Mid-Southern Bancorp, Inc.",
        "value": "MSVB"
    },
    {
        "label": "ALZN - Alzamend Neuro, Inc.",
        "value": "ALZN"
    },
    {
        "label": "QYOUF - QYOU Media Inc.",
        "value": "QYOUF"
    },
    {
        "label": "BRN - BARNWELL INDUSTRIES INC",
        "value": "BRN"
    },
    {
        "label": "ELBM - Electra Battery Materials Corp",
        "value": "ELBM"
    },
    {
        "label": "OCC - OPTICAL CABLE CORP",
        "value": "OCC"
    },
    {
        "label": "MXC - MEXCO ENERGY CORP",
        "value": "MXC"
    },
    {
        "label": "BRFH - BARFRESH FOOD GROUP INC.",
        "value": "BRFH"
    },
    {
        "label": "MBIO - MUSTANG BIO, INC.",
        "value": "MBIO"
    },
    {
        "label": "LMNL - Liminal BioSciences Inc.",
        "value": "LMNL"
    },
    {
        "label": "IMMX - Immix Biopharma, Inc.",
        "value": "IMMX"
    },
    {
        "label": "PMD - PSYCHEMEDICS CORP",
        "value": "PMD"
    },
    {
        "label": "OZSC - OZOP ENERGY SOLUTIONS, INC.",
        "value": "OZSC"
    },
    {
        "label": "UNCY - Unicycive Therapeutics, Inc.",
        "value": "UNCY"
    },
    {
        "label": "NLSP - NLS Pharmaceutics Ltd.",
        "value": "NLSP"
    },
    {
        "label": "OSBK - OCONEE FINANCIAL CORP",
        "value": "OSBK"
    },
    {
        "label": "CCLD - CareCloud, Inc.",
        "value": "CCLD"
    },
    {
        "label": "NTWK - NETSOL TECHNOLOGIES INC",
        "value": "NTWK"
    },
    {
        "label": "CWD - CaliberCos Inc.",
        "value": "CWD"
    },
    {
        "label": "CDBMF - Cordoba Minerals Corp.",
        "value": "CDBMF"
    },
    {
        "label": "NTRB - NutriBand Inc.",
        "value": "NTRB"
    },
    {
        "label": "MOBQ - Mobiquity Technologies, Inc.",
        "value": "MOBQ"
    },
    {
        "label": "MEGL - Magic Empire Global Ltd",
        "value": "MEGL"
    },
    {
        "label": "MHUBF - MineHub Technologies Inc.",
        "value": "MHUBF"
    },
    {
        "label": "COEP - Coeptis Therapeutics Holdings, Inc.",
        "value": "COEP"
    },
    {
        "label": "HEPA - Hepion Pharmaceuticals, Inc.",
        "value": "HEPA"
    },
    {
        "label": "GWSO - Global Warming Solutions, Inc.",
        "value": "GWSO"
    },
    {
        "label": "CTHR - CHARLES & COLVARD LTD",
        "value": "CTHR"
    },
    {
        "label": "TARA - Protara Therapeutics, Inc.",
        "value": "TARA"
    },
    {
        "label": "PPBT - PURPLE BIOTECH LTD.",
        "value": "PPBT"
    },
    {
        "label": "WRNT - WARRANTEE INC.",
        "value": "WRNT"
    },
    {
        "label": "IGXT - IntelGenx Technologies Corp.",
        "value": "IGXT"
    },
    {
        "label": "TRIB - TRINITY BIOTECH PLC",
        "value": "TRIB"
    },
    {
        "label": "RETO - ReTo Eco-Solutions, Inc.",
        "value": "RETO"
    },
    {
        "label": "NSYS - NORTECH SYSTEMS INC",
        "value": "NSYS"
    },
    {
        "label": "LPTX - LEAP THERAPEUTICS, INC.",
        "value": "LPTX"
    },
    {
        "label": "SHMP - NaturalShrimp Inc",
        "value": "SHMP"
    },
    {
        "label": "OAKV - Oak View Bankshares, Inc.",
        "value": "OAKV"
    },
    {
        "label": "SGRP - SPAR Group, Inc.",
        "value": "SGRP"
    },
    {
        "label": "LGL - LGL GROUP INC",
        "value": "LGL"
    },
    {
        "label": "EMKR - EMCORE CORP",
        "value": "EMKR"
    },
    {
        "label": "CRVW - CareView Communications Inc",
        "value": "CRVW"
    },
    {
        "label": "TGEN - TECOGEN INC.",
        "value": "TGEN"
    },
    {
        "label": "BRST - Broad Street Realty, Inc.",
        "value": "BRST"
    },
    {
        "label": "TRT - TRIO-TECH INTERNATIONAL",
        "value": "TRT"
    },
    {
        "label": "CUBA - HERZFELD CARIBBEAN BASIN FUND INC",
        "value": "CUBA"
    },
    {
        "label": "RSRV - RESERVE PETROLEUM CO",
        "value": "RSRV"
    },
    {
        "label": "BBOP - BeBop Channel Corp",
        "value": "BBOP"
    },
    {
        "label": "ELWS - Earlyworks Co., Ltd.",
        "value": "ELWS"
    },
    {
        "label": "RXMD - Progressive Care Inc.",
        "value": "RXMD"
    },
    {
        "label": "MAIA - MAIA Biotechnology, Inc.",
        "value": "MAIA"
    },
    {
        "label": "TEDU - Tarena International, Inc.",
        "value": "TEDU"
    },
    {
        "label": "SDPI - Superior Drilling Products, Inc.",
        "value": "SDPI"
    },
    {
        "label": "MODD - Modular Medical, Inc.",
        "value": "MODD"
    },
    {
        "label": "RGLS - Regulus Therapeutics Inc.",
        "value": "RGLS"
    },
    {
        "label": "CAMP - CalAmp Corp.",
        "value": "CAMP"
    },
    {
        "label": "PAVM - PAVmed Inc.",
        "value": "PAVM"
    },
    {
        "label": "BMR - Beamr Imaging Ltd.",
        "value": "BMR"
    },
    {
        "label": "VIVC - VIVIC CORP.",
        "value": "VIVC"
    },
    {
        "label": "OPXS - Optex Systems Holdings Inc",
        "value": "OPXS"
    },
    {
        "label": "SRNW - Stratos Renewables Corp",
        "value": "SRNW"
    },
    {
        "label": "AKOM - Aerkomm Inc.",
        "value": "AKOM"
    },
    {
        "label": "PNXLF - Argentina Lithium & Energy Corp.",
        "value": "PNXLF"
    },
    {
        "label": "OCEL - Organicell Regenerative Medicine, Inc.",
        "value": "OCEL"
    },
    {
        "label": "GLGI - GREYSTONE LOGISTICS, INC.",
        "value": "GLGI"
    },
    {
        "label": "LOCL - Local Bounti Corporation/DE",
        "value": "LOCL"
    },
    {
        "label": "HTCR - HeartCore Enterprises, Inc.",
        "value": "HTCR"
    },
    {
        "label": "ICLK - iClick Interactive Asia Group Ltd",
        "value": "ICLK"
    },
    {
        "label": "CIPI - Correlate Energy Corp.",
        "value": "CIPI"
    },
    {
        "label": "MBOT - Microbot Medical Inc.",
        "value": "MBOT"
    },
    {
        "label": "MTEX - MANNATECH INC",
        "value": "MTEX"
    },
    {
        "label": "LGIQ - LOGIQ, INC.",
        "value": "LGIQ"
    },
    {
        "label": "UNXP - United Express Inc.",
        "value": "UNXP"
    },
    {
        "label": "PKKFF - Tenet Fintech Group Inc.",
        "value": "PKKFF"
    },
    {
        "label": "CPIX - CUMBERLAND PHARMACEUTICALS INC",
        "value": "CPIX"
    },
    {
        "label": "VWFB - VWF Bancorp, Inc.",
        "value": "VWFB"
    },
    {
        "label": "NCTY - The9 LTD",
        "value": "NCTY"
    },
    {
        "label": "HMMR - HAMMER FIBER OPTICS HOLDINGS CORP",
        "value": "HMMR"
    },
    {
        "label": "HWNI - HIGH WIRE NETWORKS, INC.",
        "value": "HWNI"
    },
    {
        "label": "ACXP - Acurx Pharmaceuticals, Inc.",
        "value": "ACXP"
    },
    {
        "label": "CYN - Cyngn Inc.",
        "value": "CYN"
    },
    {
        "label": "SOWG - Sow Good Inc.",
        "value": "SOWG"
    },
    {
        "label": "IMUN - Immune Therapeutics, Inc.",
        "value": "IMUN"
    },
    {
        "label": "PRKA - PARKS AMERICA, INC",
        "value": "PRKA"
    },
    {
        "label": "BSEM - BIOSTEM TECHNOLOGIES",
        "value": "BSEM"
    },
    {
        "label": "SNRG - SusGlobal Energy Corp.",
        "value": "SNRG"
    },
    {
        "label": "JT - Jianpu Technology Inc.",
        "value": "JT"
    },
    {
        "label": "AGXPF - SILVER X MINING CORP.",
        "value": "AGXPF"
    },
    {
        "label": "QIND - Quality Industrial Corp.",
        "value": "QIND"
    },
    {
        "label": "JEWL - Adamas One Corp.",
        "value": "JEWL"
    },
    {
        "label": "AYRO - AYRO, Inc.",
        "value": "AYRO"
    },
    {
        "label": "DLPN - Dolphin Entertainment, Inc.",
        "value": "DLPN"
    },
    {
        "label": "BSQR - BSQUARE CORP /WA",
        "value": "BSQR"
    },
    {
        "label": "NIR - Near Intelligence, Inc.",
        "value": "NIR"
    },
    {
        "label": "ASRE - Astra Energy, Inc.",
        "value": "ASRE"
    },
    {
        "label": "CLPS - CLPS Inc",
        "value": "CLPS"
    },
    {
        "label": "JG - Aurora Mobile Ltd",
        "value": "JG"
    },
    {
        "label": "LPCN - Lipocine Inc.",
        "value": "LPCN"
    },
    {
        "label": "BTTC - Bitech Technologies Corp",
        "value": "BTTC"
    },
    {
        "label": "NVOS - Novo Integrated Sciences, Inc.",
        "value": "NVOS"
    },
    {
        "label": "USDP - USD Partners LP",
        "value": "USDP"
    },
    {
        "label": "MEEC - Midwest Energy Emissions Corp.",
        "value": "MEEC"
    },
    {
        "label": "FREQ - Frequency Therapeutics, Inc.",
        "value": "FREQ"
    },
    {
        "label": "NMRD - Nemaura Medical Inc.",
        "value": "NMRD"
    },
    {
        "label": "PRSI - PORTSMOUTH SQUARE INC",
        "value": "PRSI"
    },
    {
        "label": "AWH - Aspira Women's Health Inc.",
        "value": "AWH"
    },
    {
        "label": "SWAG - Stran & Company, Inc.",
        "value": "SWAG"
    },
    {
        "label": "NOM - NUVEEN MISSOURI QUALITY MUNICIPAL INCOME FUND",
        "value": "NOM"
    },
    {
        "label": "UPH - UpHealth, Inc.",
        "value": "UPH"
    },
    {
        "label": "PETV - PetVivo Holdings, Inc.",
        "value": "PETV"
    },
    {
        "label": "NRXP - NRX Pharmaceuticals, Inc.",
        "value": "NRXP"
    },
    {
        "label": "AZYO - AZIYO BIOLOGICS, INC.",
        "value": "AZYO"
    },
    {
        "label": "CRCUF - Canagold Resources Ltd.",
        "value": "CRCUF"
    },
    {
        "label": "QSEP - QS Energy, Inc.",
        "value": "QSEP"
    },
    {
        "label": "EXDW - Exceed World, Inc.",
        "value": "EXDW"
    },
    {
        "label": "SODI - SOLITRON DEVICES INC",
        "value": "SODI"
    },
    {
        "label": "AMRS - AMYRIS, INC.",
        "value": "AMRS"
    },
    {
        "label": "TAIT - TAITRON COMPONENTS INC",
        "value": "TAIT"
    },
    {
        "label": "JGLDF - Japan Gold Corp.",
        "value": "JGLDF"
    },
    {
        "label": "ORGS - Orgenesis Inc.",
        "value": "ORGS"
    },
    {
        "label": "INVO - INVO Bioscience, Inc.",
        "value": "INVO"
    },
    {
        "label": "NPLS - NP Life Sciences Health Industry Group Inc.",
        "value": "NPLS"
    },
    {
        "label": "FAZE - FaZe Holdings Inc.",
        "value": "FAZE"
    },
    {
        "label": "IRIX - IRIDEX CORP",
        "value": "IRIX"
    },
    {
        "label": "PBSV - Pharma-Bio Serv, Inc.",
        "value": "PBSV"
    },
    {
        "label": "IDAI - T Stamp Inc",
        "value": "IDAI"
    },
    {
        "label": "IPW - iPower Inc.",
        "value": "IPW"
    },
    {
        "label": "GBNY - Generations Bancorp NY, Inc.",
        "value": "GBNY"
    },
    {
        "label": "LUMO - LUMOS PHARMA, INC.",
        "value": "LUMO"
    },
    {
        "label": "BIXT - BIOXYTRAN, INC",
        "value": "BIXT"
    },
    {
        "label": "DRFS - Dr. Foods, Inc.",
        "value": "DRFS"
    },
    {
        "label": "MTBL - Moatable, Inc.",
        "value": "MTBL"
    },
    {
        "label": "WLDS - Wearable Devices Ltd.",
        "value": "WLDS"
    },
    {
        "label": "ALRTF - ALR Technologies SG Ltd.",
        "value": "ALRTF"
    },
    {
        "label": "GLBZ - GLEN BURNIE BANCORP",
        "value": "GLBZ"
    },
    {
        "label": "EWGFF - Eat Well Investment Group Inc.",
        "value": "EWGFF"
    },
    {
        "label": "TETOF - Tectonic Metals Inc.",
        "value": "TETOF"
    },
    {
        "label": "VIRI - Virios Therapeutics, Inc.",
        "value": "VIRI"
    },
    {
        "label": "LNBY - Landbay Inc",
        "value": "LNBY"
    },
    {
        "label": "CRYM - Cryomass Technologies, Inc.",
        "value": "CRYM"
    },
    {
        "label": "SONM - SONIM TECHNOLOGIES INC",
        "value": "SONM"
    },
    {
        "label": "PHIL - PHI GROUP INC",
        "value": "PHIL"
    },
    {
        "label": "TRKA - Troika Media Group, Inc.",
        "value": "TRKA"
    },
    {
        "label": "ECOR - electroCore, Inc.",
        "value": "ECOR"
    },
    {
        "label": "SFT - SHIFT TECHNOLOGIES, INC.",
        "value": "SFT"
    },
    {
        "label": "LSTA - LISATA THERAPEUTICS, INC.",
        "value": "LSTA"
    },
    {
        "label": "FPAY - FlexShopper, Inc.",
        "value": "FPAY"
    },
    {
        "label": "KZIA - KAZIA THERAPEUTICS LTD",
        "value": "KZIA"
    },
    {
        "label": "PIRS - PIERIS PHARMACEUTICALS, INC.",
        "value": "PIRS"
    },
    {
        "label": "PFIN - P&F INDUSTRIES INC",
        "value": "PFIN"
    },
    {
        "label": "HANNF - Hannan Metals Ltd.",
        "value": "HANNF"
    },
    {
        "label": "OPHC - OptimumBank Holdings, Inc.",
        "value": "OPHC"
    },
    {
        "label": "EGSE - Evergreen Sustainable Enterprises, Inc.",
        "value": "EGSE"
    },
    {
        "label": "DALN - DallasNews Corp",
        "value": "DALN"
    },
    {
        "label": "SRRE - SUNRISE REAL ESTATE GROUP INC",
        "value": "SRRE"
    },
    {
        "label": "CYTH - Cyclo Therapeutics, Inc.",
        "value": "CYTH"
    },
    {
        "label": "CHSN - Chanson International Holding",
        "value": "CHSN"
    },
    {
        "label": "FEDU - Four Seasons Education (Cayman) Inc.",
        "value": "FEDU"
    },
    {
        "label": "SCGY - SCIENTIFIC ENERGY, INC",
        "value": "SCGY"
    },
    {
        "label": "SCIA - SCI Engineered Materials, Inc.",
        "value": "SCIA"
    },
    {
        "label": "SALM - SALEM MEDIA GROUP, INC. /DE/",
        "value": "SALM"
    },
    {
        "label": "KDOZF - KIDOZ INC.",
        "value": "KDOZF"
    },
    {
        "label": "USEA - United Maritime Corp",
        "value": "USEA"
    },
    {
        "label": "DQWS - DSwiss Inc",
        "value": "DQWS"
    },
    {
        "label": "SUNL - Sunlight Financial Holdings Inc.",
        "value": "SUNL"
    },
    {
        "label": "SHFS - SHF Holdings, Inc.",
        "value": "SHFS"
    },
    {
        "label": "GTEC - Greenland Technologies Holding Corp.",
        "value": "GTEC"
    },
    {
        "label": "ENDI - ENDI Corp.",
        "value": "ENDI"
    },
    {
        "label": "SPGX - Sustainable Projects Group Inc.",
        "value": "SPGX"
    },
    {
        "label": "ACOR - Acorda Therapeutics, Inc.",
        "value": "ACOR"
    },
    {
        "label": "GURE - GULF RESOURCES, INC.",
        "value": "GURE"
    },
    {
        "label": "ELEV - Elevation Oncology, Inc.",
        "value": "ELEV"
    },
    {
        "label": "VYEY - VICTORY OILFIELD TECH, INC.",
        "value": "VYEY"
    },
    {
        "label": "LIQT - LIQTECH INTERNATIONAL INC",
        "value": "LIQT"
    },
    {
        "label": "LICN - Lichen China Ltd",
        "value": "LICN"
    },
    {
        "label": "BRLI - Brilliant Acquisition Corp",
        "value": "BRLI"
    },
    {
        "label": "LCHD - Leader Capital Holdings Corp.",
        "value": "LCHD"
    },
    {
        "label": "TOMZ - TOMI Environmental Solutions, Inc.",
        "value": "TOMZ"
    },
    {
        "label": "ILAG - Intelligent Living Application Group Inc.",
        "value": "ILAG"
    },
    {
        "label": "PMCB - PharmaCyte Biotech, Inc.",
        "value": "PMCB"
    },
    {
        "label": "EQS - EQUUS TOTAL RETURN, INC.",
        "value": "EQS"
    },
    {
        "label": "HUSA - HOUSTON AMERICAN ENERGY CORP",
        "value": "HUSA"
    },
    {
        "label": "SSNT - SilverSun Technologies, Inc.",
        "value": "SSNT"
    },
    {
        "label": "BQ - Boqii Holding Ltd",
        "value": "BQ"
    },
    {
        "label": "ETST - Earth Science Tech, Inc.",
        "value": "ETST"
    },
    {
        "label": "TBTC - TABLE TRAC INC",
        "value": "TBTC"
    },
    {
        "label": "FSRL - FIRST RELIANCE BANCSHARES INC",
        "value": "FSRL"
    },
    {
        "label": "EDXC - Endexx Corp",
        "value": "EDXC"
    },
    {
        "label": "RMSL - RemSleep Holdings Inc.",
        "value": "RMSL"
    },
    {
        "label": "SSKN - STRATA Skin Sciences, Inc.",
        "value": "SSKN"
    },
    {
        "label": "BTTX - Better Therapeutics, Inc.",
        "value": "BTTX"
    },
    {
        "label": "RWBYF - Red White & Bloom Brands Inc.",
        "value": "RWBYF"
    },
    {
        "label": "AUCUF - Inflection Resources Ltd.",
        "value": "AUCUF"
    },
    {
        "label": "STLY - HG Holdings, Inc.",
        "value": "STLY"
    },
    {
        "label": "DBD - DIEBOLD NIXDORF, Inc",
        "value": "DBD"
    },
    {
        "label": "ALID - Allied Corp.",
        "value": "ALID"
    },
    {
        "label": "PT - Pintec Technology Holdings Ltd",
        "value": "PT"
    },
    {
        "label": "MXROF - MAX RESOURCE CORP.",
        "value": "MXROF"
    },
    {
        "label": "TCJH - Top KingWin Ltd",
        "value": "TCJH"
    },
    {
        "label": "RDGL - VIVOS INC",
        "value": "RDGL"
    },
    {
        "label": "CKX - CKX LANDS, INC.",
        "value": "CKX"
    },
    {
        "label": "SFE - SAFEGUARD SCIENTIFICS INC",
        "value": "SFE"
    },
    {
        "label": "WOWI - METRO ONE TELECOMMUNICATIONS INC",
        "value": "WOWI"
    },
    {
        "label": "BFRG - BullFrog AI Holdings, Inc.",
        "value": "BFRG"
    },
    {
        "label": "STKH - Steakholder Foods Ltd.",
        "value": "STKH"
    },
    {
        "label": "UPTD - TradeUP Acquisition Corp.",
        "value": "UPTD"
    },
    {
        "label": "BOSC - BOS BETTER ONLINE SOLUTIONS LTD",
        "value": "BOSC"
    },
    {
        "label": "IMPP - Imperial Petroleum Inc./Marshall Islands",
        "value": "IMPP"
    },
    {
        "label": "VIVK - Vivakor, Inc.",
        "value": "VIVK"
    },
    {
        "label": "SMGI - SMG Industries Inc.",
        "value": "SMGI"
    },
    {
        "label": "FTFT - Future FinTech Group Inc.",
        "value": "FTFT"
    },
    {
        "label": "MGIH - Millennium Group International Holdings Ltd",
        "value": "MGIH"
    },
    {
        "label": "SUND - Sundance Strategies, Inc.",
        "value": "SUND"
    },
    {
        "label": "BTCY - BIOTRICITY INC.",
        "value": "BTCY"
    },
    {
        "label": "GESI - General European Strategic Investments, Inc.",
        "value": "GESI"
    },
    {
        "label": "FRLN - Freeline Therapeutics Holdings plc",
        "value": "FRLN"
    },
    {
        "label": "CHUC - Charlie's Holdings, Inc.",
        "value": "CHUC"
    },
    {
        "label": "POAI - Predictive Oncology Inc.",
        "value": "POAI"
    },
    {
        "label": "BREA - Brera Holdings PLC",
        "value": "BREA"
    },
    {
        "label": "MDJH - MDJM LTD",
        "value": "MDJH"
    },
    {
        "label": "UAVS - AgEagle Aerial Systems Inc.",
        "value": "UAVS"
    },
    {
        "label": "BGLC - BioNexus Gene Lab Corp",
        "value": "BGLC"
    },
    {
        "label": "COSM - Cosmos Health Inc.",
        "value": "COSM"
    },
    {
        "label": "ARGC - ARION GROUP CORP.",
        "value": "ARGC"
    },
    {
        "label": "BRVO - Bravo Multinational Inc.",
        "value": "BRVO"
    },
    {
        "label": "BURU - Nuburu, Inc.",
        "value": "BURU"
    },
    {
        "label": "BMRA - BIOMERICA INC",
        "value": "BMRA"
    },
    {
        "label": "APDN - APPLIED DNA SCIENCES INC",
        "value": "APDN"
    },
    {
        "label": "KA - KINETA, INC./DE",
        "value": "KA"
    },
    {
        "label": "PALT - PALTALK, INC.",
        "value": "PALT"
    },
    {
        "label": "SPFX - STANDARD PREMIUM FINANCE HOLDINGS, INC.",
        "value": "SPFX"
    },
    {
        "label": "ACRHF - Acreage Holdings, Inc.",
        "value": "ACRHF"
    },
    {
        "label": "ANY - Sphere 3D Corp.",
        "value": "ANY"
    },
    {
        "label": "ELYS - Elys Game Technology, Corp.",
        "value": "ELYS"
    },
    {
        "label": "CLEV - Concrete Leveling Systems Inc",
        "value": "CLEV"
    },
    {
        "label": "CLRO - CLEARONE INC",
        "value": "CLRO"
    },
    {
        "label": "VAPO - VAPOTHERM INC",
        "value": "VAPO"
    },
    {
        "label": "GEBRF - Greenbriar Capital Corp.",
        "value": "GEBRF"
    },
    {
        "label": "RVLP - RVL Pharmaceuticals plc",
        "value": "RVLP"
    },
    {
        "label": "OMQS - OMNIQ Corp.",
        "value": "OMQS"
    },
    {
        "label": "DPLS - DarkPulse, Inc.",
        "value": "DPLS"
    },
    {
        "label": "ACER - Acer Therapeutics Inc.",
        "value": "ACER"
    },
    {
        "label": "TGCB - Tego Cyber, Inc.",
        "value": "TGCB"
    },
    {
        "label": "LCGMF - LION COPPER & GOLD CORP.",
        "value": "LCGMF"
    },
    {
        "label": "IGC - IGC Pharma, Inc.",
        "value": "IGC"
    },
    {
        "label": "RLBD - Real Brands, Inc.",
        "value": "RLBD"
    },
    {
        "label": "BOXL - Boxlight Corp",
        "value": "BOXL"
    },
    {
        "label": "RNXT - RenovoRx, Inc.",
        "value": "RNXT"
    },
    {
        "label": "AMPG - AmpliTech Group, Inc.",
        "value": "AMPG"
    },
    {
        "label": "INDP - Indaptus Therapeutics, Inc.",
        "value": "INDP"
    },
    {
        "label": "MGRX - MANGOCEUTICALS, INC.",
        "value": "MGRX"
    },
    {
        "label": "GEHI - Gravitas Education Holdings, Inc.",
        "value": "GEHI"
    },
    {
        "label": "OMHI - Portage Resources Inc.",
        "value": "OMHI"
    },
    {
        "label": "SPRS - SURGE COMPONENTS INC",
        "value": "SPRS"
    },
    {
        "label": "FBPI - FIRST BANCORP OF INDIANA INC",
        "value": "FBPI"
    },
    {
        "label": "MHHC - MHHC Enterprises Inc.",
        "value": "MHHC"
    },
    {
        "label": "XWEL - XWELL, Inc.",
        "value": "XWEL"
    },
    {
        "label": "GLBS - GLOBUS MARITIME LTD",
        "value": "GLBS"
    },
    {
        "label": "HCTI - Healthcare Triangle, Inc.",
        "value": "HCTI"
    },
    {
        "label": "ZIVO - Zivo Bioscience, Inc.",
        "value": "ZIVO"
    },
    {
        "label": "CBIO - CATALYST BIOSCIENCES, INC.",
        "value": "CBIO"
    },
    {
        "label": "LKCO - Luokung Technology Corp.",
        "value": "LKCO"
    },
    {
        "label": "ONCT - Oncternal Therapeutics, Inc.",
        "value": "ONCT"
    },
    {
        "label": "DTST - Data Storage Corp",
        "value": "DTST"
    },
    {
        "label": "YJ - Yunji Inc.",
        "value": "YJ"
    },
    {
        "label": "SEED - Origin Agritech LTD",
        "value": "SEED"
    },
    {
        "label": "CALC - CalciMedica, Inc.",
        "value": "CALC"
    },
    {
        "label": "WHLM - Wilhelmina International, Inc.",
        "value": "WHLM"
    },
    {
        "label": "ADD - Color Star Technology Co., Ltd.",
        "value": "ADD"
    },
    {
        "label": "CLRD - Clearday, Inc.",
        "value": "CLRD"
    },
    {
        "label": "CNTX - Context Therapeutics Inc.",
        "value": "CNTX"
    },
    {
        "label": "CNFR - Conifer Holdings, Inc.",
        "value": "CNFR"
    },
    {
        "label": "AAMC - Altisource Asset Management Corp",
        "value": "AAMC"
    },
    {
        "label": "SGBX - SAFE & GREEN HOLDINGS CORP.",
        "value": "SGBX"
    },
    {
        "label": "SRZN - Surrozen, Inc./DE",
        "value": "SRZN"
    },
    {
        "label": "CFMS - Conformis Inc",
        "value": "CFMS"
    },
    {
        "label": "FBRX - Forte Biosciences, Inc.",
        "value": "FBRX"
    },
    {
        "label": "EDSA - Edesa Biotech, Inc.",
        "value": "EDSA"
    },
    {
        "label": "JSDA - JONES SODA CO",
        "value": "JSDA"
    },
    {
        "label": "VINC - Vincerx Pharma, Inc.",
        "value": "VINC"
    },
    {
        "label": "YJGJ - YIJIA GROUP CORP.",
        "value": "YJGJ"
    },
    {
        "label": "BCDA - BioCardia, Inc.",
        "value": "BCDA"
    },
    {
        "label": "AQB - AquaBounty Technologies, Inc.",
        "value": "AQB"
    },
    {
        "label": "UPYY - UPAY",
        "value": "UPYY"
    },
    {
        "label": "TRNR - Interactive Strength, Inc.",
        "value": "TRNR"
    },
    {
        "label": "NMTC - NEUROONE MEDICAL TECHNOLOGIES Corp",
        "value": "NMTC"
    },
    {
        "label": "SHPW - Shapeways Holdings, Inc.",
        "value": "SHPW"
    },
    {
        "label": "EDTK - Skillful Craftsman Education Technology Ltd",
        "value": "EDTK"
    },
    {
        "label": "BRCNF - Burcon NutraScience Corp",
        "value": "BRCNF"
    },
    {
        "label": "AMS - AMERICAN SHARED HOSPITAL SERVICES",
        "value": "AMS"
    },
    {
        "label": "NEPH - NEPHROS INC",
        "value": "NEPH"
    },
    {
        "label": "RASP - Rasna Therapeutics Inc.",
        "value": "RASP"
    },
    {
        "label": "EESH - EESTech, Inc.",
        "value": "EESH"
    },
    {
        "label": "FAMI - Farmmi, Inc.",
        "value": "FAMI"
    },
    {
        "label": "ASTC - ASTROTECH Corp",
        "value": "ASTC"
    },
    {
        "label": "VEEE - Twin Vee PowerCats, Co.",
        "value": "VEEE"
    },
    {
        "label": "AAU - ALMADEN MINERALS LTD",
        "value": "AAU"
    },
    {
        "label": "FENG - Phoenix New Media Ltd",
        "value": "FENG"
    },
    {
        "label": "GLTO - Galecto, Inc.",
        "value": "GLTO"
    },
    {
        "label": "INRD - Inrad Optics, Inc.",
        "value": "INRD"
    },
    {
        "label": "MBRX - Moleculin Biotech, Inc.",
        "value": "MBRX"
    },
    {
        "label": "GFGSF - GFG Resources Inc.",
        "value": "GFGSF"
    },
    {
        "label": "MIGI - Mawson Infrastructure Group Inc.",
        "value": "MIGI"
    },
    {
        "label": "PSHG - Performance Shipping Inc.",
        "value": "PSHG"
    },
    {
        "label": "IHT - INNSUITES HOSPITALITY TRUST",
        "value": "IHT"
    },
    {
        "label": "EUDA - EUDA Health Holdings Ltd",
        "value": "EUDA"
    },
    {
        "label": "GOVB - Gouverneur Bancorp, Inc./MD/",
        "value": "GOVB"
    },
    {
        "label": "DTSS - DATASEA INC.",
        "value": "DTSS"
    },
    {
        "label": "SPTY - SPECIFICITY, INC.",
        "value": "SPTY"
    },
    {
        "label": "ONTX - Onconova Therapeutics, Inc.",
        "value": "ONTX"
    },
    {
        "label": "CVR - CHICAGO RIVET & MACHINE CO",
        "value": "CVR"
    },
    {
        "label": "OSA - ProSomnus, Inc.",
        "value": "OSA"
    },
    {
        "label": "CHNR - CHINA NATURAL RESOURCES INC",
        "value": "CHNR"
    },
    {
        "label": "CWPE - CW Petroleum Corp",
        "value": "CWPE"
    },
    {
        "label": "SPND - SPINDLETOP OIL & GAS CO",
        "value": "SPND"
    },
    {
        "label": "AFIB - Acutus Medical, Inc.",
        "value": "AFIB"
    },
    {
        "label": "HLYK - HealthLynked Corp",
        "value": "HLYK"
    },
    {
        "label": "BHV - BLACKROCK VIRGINIA MUNICIPAL BOND TRUST",
        "value": "BHV"
    },
    {
        "label": "CTM - Castellum, Inc.",
        "value": "CTM"
    },
    {
        "label": "NVGT - NOVAGANT CORP",
        "value": "NVGT"
    },
    {
        "label": "JCTCF - JEWETT CAMERON TRADING CO LTD",
        "value": "JCTCF"
    },
    {
        "label": "FRES - Fresh2 Group Ltd",
        "value": "FRES"
    },
    {
        "label": "NCRA - NOCERA, INC.",
        "value": "NCRA"
    },
    {
        "label": "INTZ - INTRUSION INC",
        "value": "INTZ"
    },
    {
        "label": "PHGE - BiomX Inc.",
        "value": "PHGE"
    },
    {
        "label": "HYEX - HEALTHY EXTRACTS INC.",
        "value": "HYEX"
    },
    {
        "label": "GYRO - Gyrodyne, LLC",
        "value": "GYRO"
    },
    {
        "label": "INLX - INTELLINETICS, INC.",
        "value": "INLX"
    },
    {
        "label": "FZMD - Fuse Medical, Inc.",
        "value": "FZMD"
    },
    {
        "label": "KUKE - Kuke Music Holding Ltd",
        "value": "KUKE"
    },
    {
        "label": "GSHRF - GOLDSHORE RESOURCES INC.",
        "value": "GSHRF"
    },
    {
        "label": "SEAV - SEATech Ventures Corp.",
        "value": "SEAV"
    },
    {
        "label": "GRCYF - Greencity Acquisition Corp",
        "value": "GRCYF"
    },
    {
        "label": "CLRB - Cellectar Biosciences, Inc.",
        "value": "CLRB"
    },
    {
        "label": "WNW - Meiwu Technology Co Ltd",
        "value": "WNW"
    },
    {
        "label": "ENTX - Entera Bio Ltd.",
        "value": "ENTX"
    },
    {
        "label": "GTAGF - GOLDEN TAG RESOURCES LTD.",
        "value": "GTAGF"
    },
    {
        "label": "CORR - CorEnergy Infrastructure Trust, Inc.",
        "value": "CORR"
    },
    {
        "label": "TPET - Trio Petroleum Corp.",
        "value": "TPET"
    },
    {
        "label": "BMTM - Bright Mountain Media, Inc.",
        "value": "BMTM"
    },
    {
        "label": "BICX - BioCorRx Inc.",
        "value": "BICX"
    },
    {
        "label": "TLCC - TWINLAB CONSOLIDATED HOLDINGS, INC.",
        "value": "TLCC"
    },
    {
        "label": "FGI - FGI Industries Ltd.",
        "value": "FGI"
    },
    {
        "label": "XIN - Xinyuan Real Estate Co., Ltd.",
        "value": "XIN"
    },
    {
        "label": "ACST - Acasti Pharma Inc.",
        "value": "ACST"
    },
    {
        "label": "DXYN - DIXIE GROUP INC",
        "value": "DXYN"
    },
    {
        "label": "GIGM - GIGAMEDIA Ltd",
        "value": "GIGM"
    },
    {
        "label": "AVCRF - Avricore Health Inc.",
        "value": "AVCRF"
    },
    {
        "label": "TSRI - TSR INC",
        "value": "TSRI"
    },
    {
        "label": "MOGU - MOGU Inc.",
        "value": "MOGU"
    },
    {
        "label": "IINN - Inspira Technologies OXY B.H.N. Ltd",
        "value": "IINN"
    },
    {
        "label": "GSUN - Golden Sun Education Group Ltd",
        "value": "GSUN"
    },
    {
        "label": "PMN - ProMIS Neurosciences Inc.",
        "value": "PMN"
    },
    {
        "label": "NYC - American Strategic Investment Co.",
        "value": "NYC"
    },
    {
        "label": "RCON - Recon Technology, Ltd",
        "value": "RCON"
    },
    {
        "label": "POLA - Polar Power, Inc.",
        "value": "POLA"
    },
    {
        "label": "CGRN - Capstone Green Energy Corp",
        "value": "CGRN"
    },
    {
        "label": "VBLT - Vascular Biogenics Ltd.",
        "value": "VBLT"
    },
    {
        "label": "BCEL - Atreca, Inc.",
        "value": "BCEL"
    },
    {
        "label": "YTFD - Yale Transaction Finders, Inc.",
        "value": "YTFD"
    },
    {
        "label": "WYY - WIDEPOINT CORP",
        "value": "WYY"
    },
    {
        "label": "PGID - PEREGRINE INDUSTRIES INC",
        "value": "PGID"
    },
    {
        "label": "GENE - GENETIC TECHNOLOGIES LTD",
        "value": "GENE"
    },
    {
        "label": "KCRD - Kindcard, Inc.",
        "value": "KCRD"
    },
    {
        "label": "CSKL - CATSKILL HUDSON BANCORP INC",
        "value": "CSKL"
    },
    {
        "label": "CYCA - CYTTA CORP.",
        "value": "CYCA"
    },
    {
        "label": "SKYE - Skye Bioscience, Inc.",
        "value": "SKYE"
    },
    {
        "label": "MDIA - Mediaco Holding Inc.",
        "value": "MDIA"
    },
    {
        "label": "TOPS - TOP SHIPS INC.",
        "value": "TOPS"
    },
    {
        "label": "FRZA - Forza X1, Inc.",
        "value": "FRZA"
    },
    {
        "label": "KTTA - Pasithea Therapeutics Corp.",
        "value": "KTTA"
    },
    {
        "label": "VYNE - VYNE Therapeutics Inc.",
        "value": "VYNE"
    },
    {
        "label": "MGOL - MGO Global Inc.",
        "value": "MGOL"
    },
    {
        "label": "DFCO - DALRADA FINANCIAL CORP",
        "value": "DFCO"
    },
    {
        "label": "ZKIN - ZK International Group Co., Ltd.",
        "value": "ZKIN"
    },
    {
        "label": "EEIQ - EpicQuest Education Group International Ltd",
        "value": "EEIQ"
    },
    {
        "label": "BIAF - bioAffinity Technologies, Inc.",
        "value": "BIAF"
    },
    {
        "label": "OHCS - Optimus Healthcare Services, Inc.",
        "value": "OHCS"
    },
    {
        "label": "ITMSF - INTERMAP TECHNOLOGIES CORP",
        "value": "ITMSF"
    },
    {
        "label": "BTBD - BT Brands, Inc.",
        "value": "BTBD"
    },
    {
        "label": "RSMXF - Regency Silver Corp.",
        "value": "RSMXF"
    },
    {
        "label": "BNRG - Brenmiller Energy Ltd.",
        "value": "BNRG"
    },
    {
        "label": "CLAD - China Liaoning Dingxu Ecological Agriculture Development, Inc.",
        "value": "CLAD"
    },
    {
        "label": "ECTM - ECA Marcellus Trust I",
        "value": "ECTM"
    },
    {
        "label": "ELVG - Elvictor Group, Inc.",
        "value": "ELVG"
    },
    {
        "label": "NSGP - NewStream Energy Technologies Group Inc",
        "value": "NSGP"
    },
    {
        "label": "UGHB - UNIVERSAL GLOBAL HUB INC.",
        "value": "UGHB"
    },
    {
        "label": "BBLR - Bubblr Inc.",
        "value": "BBLR"
    },
    {
        "label": "SIF - SIFCO INDUSTRIES INC",
        "value": "SIF"
    },
    {
        "label": "NNVC - NANOVIRICIDES, INC.",
        "value": "NNVC"
    },
    {
        "label": "VBIX - Viewbix Inc.",
        "value": "VBIX"
    },
    {
        "label": "NVVE - Nuvve Holding Corp.",
        "value": "NVVE"
    },
    {
        "label": "GFOO - Genufood Energy Enzymes Corp.",
        "value": "GFOO"
    },
    {
        "label": "QSAM - QSAM Biosciences, Inc.",
        "value": "QSAM"
    },
    {
        "label": "ELRE - Yinfu Gold Corp.",
        "value": "ELRE"
    },
    {
        "label": "STRR - STAR EQUITY HOLDINGS, INC.",
        "value": "STRR"
    },
    {
        "label": "COHN - Cohen & Co Inc.",
        "value": "COHN"
    },
    {
        "label": "PZG - Paramount Gold Nevada Corp.",
        "value": "PZG"
    },
    {
        "label": "NHWK - NightHawk Biosciences, Inc.",
        "value": "NHWK"
    },
    {
        "label": "IVDA - Iveda Solutions, Inc.",
        "value": "IVDA"
    },
    {
        "label": "GDNSF - Goodness Growth Holdings, Inc.",
        "value": "GDNSF"
    },
    {
        "label": "TC - TuanChe Ltd",
        "value": "TC"
    },
    {
        "label": "IMPL - IMPEL PHARMACEUTICALS INC",
        "value": "IMPL"
    },
    {
        "label": "BRDS - Bird Global, Inc.",
        "value": "BRDS"
    },
    {
        "label": "VSOLF - THREE SIXTY SOLAR LTD.",
        "value": "VSOLF"
    },
    {
        "label": "ATIF - ATIF Holdings Ltd",
        "value": "ATIF"
    },
    {
        "label": "SGE - Strong Global Entertainment, Inc.",
        "value": "SGE"
    },
    {
        "label": "FGF - FG Financial Group, Inc.",
        "value": "FGF"
    },
    {
        "label": "NSFDF - NXT Energy Solutions Inc.",
        "value": "NSFDF"
    },
    {
        "label": "CMRA - Comera Life Sciences Holdings, Inc.",
        "value": "CMRA"
    },
    {
        "label": "PYPD - PolyPid Ltd.",
        "value": "PYPD"
    },
    {
        "label": "NRBO - NeuroBo Pharmaceuticals, Inc.",
        "value": "NRBO"
    },
    {
        "label": "BTCS - BTCS Inc.",
        "value": "BTCS"
    },
    {
        "label": "YGMZ - MingZhu Logistics Holdings Ltd",
        "value": "YGMZ"
    },
    {
        "label": "ZEV - Lightning eMotors, Inc.",
        "value": "ZEV"
    },
    {
        "label": "POL - Polished.com Inc.",
        "value": "POL"
    },
    {
        "label": "KINS - KINGSTONE COMPANIES, INC.",
        "value": "KINS"
    },
    {
        "label": "ELSE - ELECTRO SENSORS INC",
        "value": "ELSE"
    },
    {
        "label": "CREX - CREATIVE REALITIES, INC.",
        "value": "CREX"
    },
    {
        "label": "SFR - Appreciate Holdings, Inc.",
        "value": "SFR"
    },
    {
        "label": "FLGC - Flora Growth Corp.",
        "value": "FLGC"
    },
    {
        "label": "TFFP - TFF Pharmaceuticals, Inc.",
        "value": "TFFP"
    },
    {
        "label": "AXREF - AMARC RESOURCES LTD",
        "value": "AXREF"
    },
    {
        "label": "FDIT - FINDIT, INC.",
        "value": "FDIT"
    },
    {
        "label": "BWV - Blue Water Biotech, Inc.",
        "value": "BWV"
    },
    {
        "label": "VLCN - Volcon, Inc.",
        "value": "VLCN"
    },
    {
        "label": "GBNH - Greenbrook TMS Inc.",
        "value": "GBNH"
    },
    {
        "label": "LYT - Lytus Technologies Holdings PTV. Ltd.",
        "value": "LYT"
    },
    {
        "label": "PURE - PURE BIOSCIENCE, INC.",
        "value": "PURE"
    },
    {
        "label": "GOVX - GeoVax Labs, Inc.",
        "value": "GOVX"
    },
    {
        "label": "EKSO - EKSO BIONICS HOLDINGS, INC.",
        "value": "EKSO"
    },
    {
        "label": "PKBO - Peak Bio, Inc.",
        "value": "PKBO"
    },
    {
        "label": "VJET - voxeljet AG",
        "value": "VJET"
    },
    {
        "label": "XERI - XERIANT, INC.",
        "value": "XERI"
    },
    {
        "label": "VCNX - VACCINEX, INC.",
        "value": "VCNX"
    },
    {
        "label": "TPHS - Trinity Place Holdings Inc.",
        "value": "TPHS"
    },
    {
        "label": "AEZS - Aeterna Zentaris Inc.",
        "value": "AEZS"
    },
    {
        "label": "KIQ - KELSO TECHNOLOGIES INC",
        "value": "KIQ"
    },
    {
        "label": "LFLY - Leafly Holdings, Inc. /DE",
        "value": "LFLY"
    },
    {
        "label": "RAYA - Erayak Power Solution Group Inc.",
        "value": "RAYA"
    },
    {
        "label": "QOEG - Quality Online Education Group Inc.",
        "value": "QOEG"
    },
    {
        "label": "MWG - Multi Ways Holdings Ltd",
        "value": "MWG"
    },
    {
        "label": "CHEK - Check-Cap Ltd",
        "value": "CHEK"
    },
    {
        "label": "ACRG - American Clean Resources Group, Inc.",
        "value": "ACRG"
    },
    {
        "label": "AUSI - AURA SYSTEMS INC",
        "value": "AUSI"
    },
    {
        "label": "LSDI - Lucy Scientific Discovery, Inc.",
        "value": "LSDI"
    },
    {
        "label": "ACRX - ACELRX PHARMACEUTICALS INC",
        "value": "ACRX"
    },
    {
        "label": "AIMD - Ainos, Inc.",
        "value": "AIMD"
    },
    {
        "label": "JNVR - Janover Inc.",
        "value": "JNVR"
    },
    {
        "label": "ARTW - ARTS WAY MANUFACTURING CO INC",
        "value": "ARTW"
    },
    {
        "label": "MHPC - MANUFACTURED HOUSING PROPERTIES INC.",
        "value": "MHPC"
    },
    {
        "label": "LASE - Laser Photonics Corp",
        "value": "LASE"
    },
    {
        "label": "AKU - AKUMIN INC.",
        "value": "AKU"
    },
    {
        "label": "CSSE - Chicken Soup for the Soul Entertainment, Inc.",
        "value": "CSSE"
    },
    {
        "label": "RCG - RENN Fund, Inc.",
        "value": "RCG"
    },
    {
        "label": "ERNA - Eterna Therapeutics Inc.",
        "value": "ERNA"
    },
    {
        "label": "BIMI - BIMI International Medical Inc.",
        "value": "BIMI"
    },
    {
        "label": "MCVT - Mill City Ventures III, Ltd",
        "value": "MCVT"
    },
    {
        "label": "CYADY - Celyad Oncology SA",
        "value": "CYADY"
    },
    {
        "label": "DOMH - Dominari Holdings Inc.",
        "value": "DOMH"
    },
    {
        "label": "FXLV - F45 Training Holdings Inc.",
        "value": "FXLV"
    },
    {
        "label": "VVPR - VivoPower International PLC",
        "value": "VVPR"
    },
    {
        "label": "CNVS - Cineverse Corp.",
        "value": "CNVS"
    },
    {
        "label": "APRE - Aprea Therapeutics, Inc.",
        "value": "APRE"
    },
    {
        "label": "MLCT - MALACHITE INNOVATIONS, INC.",
        "value": "MLCT"
    },
    {
        "label": "RLBY - RELIABILITY INC",
        "value": "RLBY"
    },
    {
        "label": "WLGS - WANG & LEE GROUP, Inc.",
        "value": "WLGS"
    },
    {
        "label": "EDUC - EDUCATIONAL DEVELOPMENT CORP",
        "value": "EDUC"
    },
    {
        "label": "CEI - CAMBER ENERGY, INC.",
        "value": "CEI"
    },
    {
        "label": "WAVE - Eco Wave Power Global AB (publ)",
        "value": "WAVE"
    },
    {
        "label": "ITRM - Iterum Therapeutics plc",
        "value": "ITRM"
    },
    {
        "label": "MSGM - Motorsport Games Inc.",
        "value": "MSGM"
    },
    {
        "label": "PEV - PHOENIX MOTOR INC.",
        "value": "PEV"
    },
    {
        "label": "ACFN - ACORN ENERGY, INC.",
        "value": "ACFN"
    },
    {
        "label": "BFRI - Biofrontera Inc.",
        "value": "BFRI"
    },
    {
        "label": "SOPA - SOCIETY PASS INCORPORATED.",
        "value": "SOPA"
    },
    {
        "label": "ONVO - ORGANOVO HOLDINGS, INC.",
        "value": "ONVO"
    },
    {
        "label": "SEAC - SEACHANGE INTERNATIONAL INC",
        "value": "SEAC"
    },
    {
        "label": "EBZT - Everything Blockchain, Inc.",
        "value": "EBZT"
    },
    {
        "label": "ENG - ENGLOBAL CORP",
        "value": "ENG"
    },
    {
        "label": "AEHL - Antelope Enterprise Holdings Ltd",
        "value": "AEHL"
    },
    {
        "label": "NRSN - NeuroSense Therapeutics Ltd.",
        "value": "NRSN"
    },
    {
        "label": "NXGL - NEXGEL, INC.",
        "value": "NXGL"
    },
    {
        "label": "CLOQ - CYBERLOQ TECHNOLOGIES, INC.",
        "value": "CLOQ"
    },
    {
        "label": "BHAT - Blue Hat Interactive Entertainment Technology",
        "value": "BHAT"
    },
    {
        "label": "GXSFF - Goldsource Mines Inc.",
        "value": "GXSFF"
    },
    {
        "label": "TTOO - T2 Biosystems, Inc.",
        "value": "TTOO"
    },
    {
        "label": "AWIN - AERWINS Technologies Inc.",
        "value": "AWIN"
    },
    {
        "label": "ARAV - Aravive, Inc.",
        "value": "ARAV"
    },
    {
        "label": "TLIS - Talis Biomedical Corp",
        "value": "TLIS"
    },
    {
        "label": "AIRO - AIRO Group, Inc.",
        "value": "AIRO"
    },
    {
        "label": "NISN - Nisun International Enterprise Development Group Co., Ltd",
        "value": "NISN"
    },
    {
        "label": "PAYD - PAID INC",
        "value": "PAYD"
    },
    {
        "label": "LABP - Landos Biopharma, Inc.",
        "value": "LABP"
    },
    {
        "label": "VPRB - VPR Brands, LP.",
        "value": "VPRB"
    },
    {
        "label": "CLWT - EURO TECH HOLDINGS CO LTD",
        "value": "CLWT"
    },
    {
        "label": "AEI - Alset Inc.",
        "value": "AEI"
    },
    {
        "label": "WRPT - WARPSPEED TAXI INC.",
        "value": "WRPT"
    },
    {
        "label": "IMCC - IM Cannabis Corp.",
        "value": "IMCC"
    },
    {
        "label": "UNRV - Unrivaled Brands, Inc.",
        "value": "UNRV"
    },
    {
        "label": "CARV - CARVER BANCORP INC",
        "value": "CARV"
    },
    {
        "label": "MSN - EMERSON RADIO CORP",
        "value": "MSN"
    },
    {
        "label": "UGRO - urban-gro, Inc.",
        "value": "UGRO"
    },
    {
        "label": "LVRLF - CordovaCann Corp.",
        "value": "LVRLF"
    },
    {
        "label": "MDRR - Medalist Diversified REIT, Inc.",
        "value": "MDRR"
    },
    {
        "label": "CMMB - Chemomab Therapeutics Ltd.",
        "value": "CMMB"
    },
    {
        "label": "OCG - Oriental Culture Holding LTD",
        "value": "OCG"
    },
    {
        "label": "ENZN - ENZON PHARMACEUTICALS, INC.",
        "value": "ENZN"
    },
    {
        "label": "LVVV - LiveWire Ergogenics, Inc.",
        "value": "LVVV"
    },
    {
        "label": "MCLD - mCloud Technologies Corp.",
        "value": "MCLD"
    },
    {
        "label": "BNOX - BIONOMICS LIMITED/FI",
        "value": "BNOX"
    },
    {
        "label": "WTER - ALKALINE WATER Co INC",
        "value": "WTER"
    },
    {
        "label": "SBFM - Sunshine Biopharma, Inc",
        "value": "SBFM"
    },
    {
        "label": "SLGG - Super League Gaming, Inc.",
        "value": "SLGG"
    },
    {
        "label": "EZFL - EzFill Holdings Inc",
        "value": "EZFL"
    },
    {
        "label": "NSTM - NovelStem International Corp.",
        "value": "NSTM"
    },
    {
        "label": "FMFG - Farmers & Merchants Bancshares, Inc.",
        "value": "FMFG"
    },
    {
        "label": "MF - Missfresh Ltd",
        "value": "MF"
    },
    {
        "label": "GWAV - Greenwave Technology Solutions, Inc.",
        "value": "GWAV"
    },
    {
        "label": "AXLA - Axcella Health Inc.",
        "value": "AXLA"
    },
    {
        "label": "LIPO - LIPELLA PHARMACEUTICALS INC",
        "value": "LIPO"
    },
    {
        "label": "MYO - MYOMO, INC.",
        "value": "MYO"
    },
    {
        "label": "IKT - Inhibikase Therapeutics, Inc.",
        "value": "IKT"
    },
    {
        "label": "MARPS - MARINE PETROLEUM TRUST",
        "value": "MARPS"
    },
    {
        "label": "CLVR - Clever Leaves Holdings Inc.",
        "value": "CLVR"
    },
    {
        "label": "KBNT - Kubient, Inc.",
        "value": "KBNT"
    },
    {
        "label": "JXJT - JX Luxventure Ltd",
        "value": "JXJT"
    },
    {
        "label": "WTRV - White River Energy Corp.",
        "value": "WTRV"
    },
    {
        "label": "VISL - Vislink Technologies, Inc.",
        "value": "VISL"
    },
    {
        "label": "ASST - Asset Entities Inc.",
        "value": "ASST"
    },
    {
        "label": "LXXGQ - LexaGene Holdings Inc.",
        "value": "LXXGQ"
    },
    {
        "label": "ISPC - iSpecimen Inc.",
        "value": "ISPC"
    },
    {
        "label": "ELMSQ - Electric Last Mile Solutions, Inc.",
        "value": "ELMSQ"
    },
    {
        "label": "TIVC - Tivic Health Systems, Inc.",
        "value": "TIVC"
    },
    {
        "label": "ATHE - ALTERITY THERAPEUTICS LTD",
        "value": "ATHE"
    },
    {
        "label": "LEDS - SemiLEDs Corp",
        "value": "LEDS"
    },
    {
        "label": "DHCC - DIAMONDHEAD CASINO CORP",
        "value": "DHCC"
    },
    {
        "label": "BKUCF - BLUE SKY URANIUM CORP.",
        "value": "BKUCF"
    },
    {
        "label": "VSMR - VERIFY SMART CORP.",
        "value": "VSMR"
    },
    {
        "label": "CYXTQ - Cyxtera Technologies, Inc.",
        "value": "CYXTQ"
    },
    {
        "label": "IVDN - INNOVATIVE DESIGNS INC",
        "value": "IVDN"
    },
    {
        "label": "CLEU - China Liberal Education Holdings Ltd",
        "value": "CLEU"
    },
    {
        "label": "MIMO - Airspan Networks Holdings Inc.",
        "value": "MIMO"
    },
    {
        "label": "SONN - Sonnet BioTherapeutics Holdings, Inc.",
        "value": "SONN"
    },
    {
        "label": "VERO - Venus Concept Inc.",
        "value": "VERO"
    },
    {
        "label": "MARK - REMARK HOLDINGS, INC.",
        "value": "MARK"
    },
    {
        "label": "ACTX - ADVANCED CONTAINER TECHNOLOGIES, INC.",
        "value": "ACTX"
    },
    {
        "label": "EQMEF - Equity Metals Corp",
        "value": "EQMEF"
    },
    {
        "label": "IMRN - Immuron Ltd",
        "value": "IMRN"
    },
    {
        "label": "AMTI - Applied Molecular Transport Inc.",
        "value": "AMTI"
    },
    {
        "label": "RVSN - Rail Vision Ltd.",
        "value": "RVSN"
    },
    {
        "label": "SHPH - Shuttle Pharmaceuticals Holdings, Inc.",
        "value": "SHPH"
    },
    {
        "label": "PFHO - PACIFIC HEALTH CARE ORGANIZATION INC",
        "value": "PFHO"
    },
    {
        "label": "PETZ - TDH Holdings, Inc.",
        "value": "PETZ"
    },
    {
        "label": "OIG - Orbital Infrastructure Group, Inc.",
        "value": "OIG"
    },
    {
        "label": "VRME - VerifyMe, Inc.",
        "value": "VRME"
    },
    {
        "label": "VRPX - Virpax Pharmaceuticals, Inc.",
        "value": "VRPX"
    },
    {
        "label": "PEGY - Pineapple Energy Inc.",
        "value": "PEGY"
    },
    {
        "label": "YVR - Liquid Media Group Ltd.",
        "value": "YVR"
    },
    {
        "label": "XRTX - XORTX Therapeutics Inc.",
        "value": "XRTX"
    },
    {
        "label": "FNCH - Finch Therapeutics Group, Inc.",
        "value": "FNCH"
    },
    {
        "label": "ANTE - AIRNET TECHNOLOGY INC.",
        "value": "ANTE"
    },
    {
        "label": "ALBT - Avalon GloboCare Corp.",
        "value": "ALBT"
    },
    {
        "label": "OGBLY - Onion Global Ltd",
        "value": "OGBLY"
    },
    {
        "label": "VAUCF - Viva Gold Corp.",
        "value": "VAUCF"
    },
    {
        "label": "CRTG - CORETEC GROUP INC.",
        "value": "CRTG"
    },
    {
        "label": "LTUM - Lithium Corp",
        "value": "LTUM"
    },
    {
        "label": "CANF - Can-Fite BioPharma Ltd.",
        "value": "CANF"
    },
    {
        "label": "SNWV - SANUWAVE Health, Inc.",
        "value": "SNWV"
    },
    {
        "label": "IMNN - Imunon, Inc.",
        "value": "IMNN"
    },
    {
        "label": "ELOX - Eloxx Pharmaceuticals, Inc.",
        "value": "ELOX"
    },
    {
        "label": "CVKD - Cadrenal Therapeutics, Inc.",
        "value": "CVKD"
    },
    {
        "label": "SCKT - SOCKET MOBILE, INC.",
        "value": "SCKT"
    },
    {
        "label": "BAOS - Baosheng Media Group Holdings Ltd",
        "value": "BAOS"
    },
    {
        "label": "GTBP - GT Biopharma, Inc.",
        "value": "GTBP"
    },
    {
        "label": "AUST - Austin Gold Corp.",
        "value": "AUST"
    },
    {
        "label": "OLB - OLB GROUP, INC.",
        "value": "OLB"
    },
    {
        "label": "CLNV - Clean Vision Corp",
        "value": "CLNV"
    },
    {
        "label": "BLBX - BLACKBOXSTOCKS INC.",
        "value": "BLBX"
    },
    {
        "label": "LEAI - Legacy Education Alliance, Inc.",
        "value": "LEAI"
    },
    {
        "label": "LSF - Laird Superfood, Inc.",
        "value": "LSF"
    },
    {
        "label": "PRPO - Precipio, Inc.",
        "value": "PRPO"
    },
    {
        "label": "DGLY - DIGITAL ALLY, INC.",
        "value": "DGLY"
    },
    {
        "label": "CNEY - CN ENERGY GROUP. INC.",
        "value": "CNEY"
    },
    {
        "label": "SCYYF - SCANDIUM INTERNATIONAL MINING CORP.",
        "value": "SCYYF"
    },
    {
        "label": "OLKR - OpenLocker Holdings, Inc.",
        "value": "OLKR"
    },
    {
        "label": "BASA - BASANITE, INC.",
        "value": "BASA"
    },
    {
        "label": "MRIN - MARIN SOFTWARE INC",
        "value": "MRIN"
    },
    {
        "label": "MMTRS - MILLS MUSIC TRUST",
        "value": "MMTRS"
    },
    {
        "label": "MITQ - MOVING iMAGE TECHNOLOGIES INC.",
        "value": "MITQ"
    },
    {
        "label": "INFI - INFINITY PHARMACEUTICALS, INC.",
        "value": "INFI"
    },
    {
        "label": "OOGI - C2E ENERGY, INC.",
        "value": "OOGI"
    },
    {
        "label": "AIRI - AIR INDUSTRIES GROUP",
        "value": "AIRI"
    },
    {
        "label": "GRTX - Galera Therapeutics, Inc.",
        "value": "GRTX"
    },
    {
        "label": "BRTX - BioRestorative Therapies, Inc.",
        "value": "BRTX"
    },
    {
        "label": "OCLN - ORIGINCLEAR, INC.",
        "value": "OCLN"
    },
    {
        "label": "TLLTF - TILT Holdings Inc.",
        "value": "TLLTF"
    },
    {
        "label": "QH - QUHUO Ltd",
        "value": "QH"
    },
    {
        "label": "DATS - DatChat, Inc.",
        "value": "DATS"
    },
    {
        "label": "MOJO - EQUATOR Beverage Co",
        "value": "MOJO"
    },
    {
        "label": "KWE - KWESST Micro Systems Inc.",
        "value": "KWE"
    },
    {
        "label": "FHLD - Freedom Holdings, Inc.",
        "value": "FHLD"
    },
    {
        "label": "THER - THERALINK TECHNOLOGIES, INC.",
        "value": "THER"
    },
    {
        "label": "ADXN - Addex Therapeutics Ltd.",
        "value": "ADXN"
    },
    {
        "label": "AYTU - AYTU BIOPHARMA, INC",
        "value": "AYTU"
    },
    {
        "label": "SQFT - Presidio Property Trust, Inc.",
        "value": "SQFT"
    },
    {
        "label": "STSS - Sharps Technology Inc.",
        "value": "STSS"
    },
    {
        "label": "WAFU - Wah Fu Education Group Ltd",
        "value": "WAFU"
    },
    {
        "label": "SDCH - SideChannel, Inc.",
        "value": "SDCH"
    },
    {
        "label": "TNXP - Tonix Pharmaceuticals Holding Corp.",
        "value": "TNXP"
    },
    {
        "label": "SOFO - SONIC FOUNDRY INC",
        "value": "SOFO"
    },
    {
        "label": "GIPR - GENERATION INCOME PROPERTIES, INC.",
        "value": "GIPR"
    },
    {
        "label": "APGN - Apexigen, Inc.",
        "value": "APGN"
    },
    {
        "label": "EGYF - ENERGY FINDERS INC",
        "value": "EGYF"
    },
    {
        "label": "DRUG - BRIGHT MINDS BIOSCIENCES INC.",
        "value": "DRUG"
    },
    {
        "label": "APYP - APPYEA, INC",
        "value": "APYP"
    },
    {
        "label": "BUDZ - WEED, INC.",
        "value": "BUDZ"
    },
    {
        "label": "BLIN - Bridgeline Digital, Inc.",
        "value": "BLIN"
    },
    {
        "label": "DMS - Digital Media Solutions, Inc.",
        "value": "DMS"
    },
    {
        "label": "MGAM - Mobile Global Esports, Inc.",
        "value": "MGAM"
    },
    {
        "label": "GHSI - Guardion Health Sciences, Inc.",
        "value": "GHSI"
    },
    {
        "label": "HPCO - Hempacco Co., Inc.",
        "value": "HPCO"
    },
    {
        "label": "GRNQ - Greenpro Capital Corp.",
        "value": "GRNQ"
    },
    {
        "label": "AWX - AVALON HOLDINGS CORP",
        "value": "AWX"
    },
    {
        "label": "TRVN - TREVENA INC",
        "value": "TRVN"
    },
    {
        "label": "JAGX - Jaguar Health, Inc.",
        "value": "JAGX"
    },
    {
        "label": "VQS - VIQ Solutions Inc.",
        "value": "VQS"
    },
    {
        "label": "TOWTF - TOWER ONE WIRELESS CORP.",
        "value": "TOWTF"
    },
    {
        "label": "SPEV - SHOREPOWER TECHNOLOGIES INC.",
        "value": "SPEV"
    },
    {
        "label": "VVOS - Vivos Therapeutics, Inc.",
        "value": "VVOS"
    },
    {
        "label": "WETG - WeTrade Group Inc.",
        "value": "WETG"
    },
    {
        "label": "HIHO - HIGHWAY HOLDINGS LTD",
        "value": "HIHO"
    },
    {
        "label": "NMGX - Nano Magic Inc.",
        "value": "NMGX"
    },
    {
        "label": "SRAX - SRAX, Inc.",
        "value": "SRAX"
    },
    {
        "label": "BOF - BranchOut Food Inc.",
        "value": "BOF"
    },
    {
        "label": "PCSA - Processa Pharmaceuticals, Inc.",
        "value": "PCSA"
    },
    {
        "label": "BMNM - BIMINI CAPITAL MANAGEMENT, INC.",
        "value": "BMNM"
    },
    {
        "label": "SGLY - Singularity Future Technology Ltd.",
        "value": "SGLY"
    },
    {
        "label": "SKKY - Skkynet Cloud Systems, Inc.",
        "value": "SKKY"
    },
    {
        "label": "CJAX - CoJax Oil & Gas Corp",
        "value": "CJAX"
    },
    {
        "label": "CPOP - Pop Culture Group Co., Ltd",
        "value": "CPOP"
    },
    {
        "label": "PULM - Pulmatrix, Inc.",
        "value": "PULM"
    },
    {
        "label": "CDIO - Cardio Diagnostics Holdings, Inc.",
        "value": "CDIO"
    },
    {
        "label": "GVP - GSE SYSTEMS INC",
        "value": "GVP"
    },
    {
        "label": "INBP - INTEGRATED BIOPHARMA INC",
        "value": "INBP"
    },
    {
        "label": "LUCY - Innovative Eyewear Inc",
        "value": "LUCY"
    },
    {
        "label": "CWBR - CohBar, Inc.",
        "value": "CWBR"
    },
    {
        "label": "CUBT - Curative Biotechnology Inc",
        "value": "CUBT"
    },
    {
        "label": "OST - Ostin Technology Group Co., Ltd.",
        "value": "OST"
    },
    {
        "label": "NDRA - ENDRA Life Sciences Inc.",
        "value": "NDRA"
    },
    {
        "label": "CYCN - Cyclerion Therapeutics, Inc.",
        "value": "CYCN"
    },
    {
        "label": "SXTP - 60 DEGREES PHARMACEUTICALS, INC.",
        "value": "SXTP"
    },
    {
        "label": "PKTX - ProtoKinetix, Inc.",
        "value": "PKTX"
    },
    {
        "label": "BPTS - Biophytis SA",
        "value": "BPTS"
    },
    {
        "label": "NAVB - NAVIDEA BIOPHARMACEUTICALS, INC.",
        "value": "NAVB"
    },
    {
        "label": "NBIO - Nascent Biotech Inc.",
        "value": "NBIO"
    },
    {
        "label": "LCFY - Locafy Ltd",
        "value": "LCFY"
    },
    {
        "label": "AIAD - AiAdvertising, Inc.",
        "value": "AIAD"
    },
    {
        "label": "FORD - Forward Industries, Inc.",
        "value": "FORD"
    },
    {
        "label": "ODYY - Odyssey Health, Inc.",
        "value": "ODYY"
    },
    {
        "label": "LMFA - LM FUNDING AMERICA, INC.",
        "value": "LMFA"
    },
    {
        "label": "FEMFF - FE Battery Metals Corp.",
        "value": "FEMFF"
    },
    {
        "label": "SSCC - Spirits Capital Corp",
        "value": "SSCC"
    },
    {
        "label": "ZMENY - Zhangmen Education Inc.",
        "value": "ZMENY"
    },
    {
        "label": "DSNY - DESTINY MEDIA TECHNOLOGIES INC",
        "value": "DSNY"
    },
    {
        "label": "TMPO - Tempo Automation Holdings, Inc.",
        "value": "TMPO"
    },
    {
        "label": "ATXG - ADDENTAX GROUP CORP.",
        "value": "ATXG"
    },
    {
        "label": "CXXMF - CMX GOLD & SILVER CORP.",
        "value": "CXXMF"
    },
    {
        "label": "PXMD - PaxMedica, Inc.",
        "value": "PXMD"
    },
    {
        "label": "SMFL - SMART FOR LIFE, INC.",
        "value": "SMFL"
    },
    {
        "label": "OTLC - Oncotelic Therapeutics, Inc.",
        "value": "OTLC"
    },
    {
        "label": "MNPR - Monopar Therapeutics",
        "value": "MNPR"
    },
    {
        "label": "MIND - MIND TECHNOLOGY, INC",
        "value": "MIND"
    },
    {
        "label": "REBN - Reborn Coffee, Inc.",
        "value": "REBN"
    },
    {
        "label": "MRAI - Marpai, Inc.",
        "value": "MRAI"
    },
    {
        "label": "ATHX - ATHERSYS, INC / NEW",
        "value": "ATHX"
    },
    {
        "label": "SBIG - SpringBig Holdings, Inc.",
        "value": "SBIG"
    },
    {
        "label": "ID - PARTS iD, Inc.",
        "value": "ID"
    },
    {
        "label": "NORD - Nordicus Partners Corp",
        "value": "NORD"
    },
    {
        "label": "BMMJ - BODY & MIND INC.",
        "value": "BMMJ"
    },
    {
        "label": "EVGDF - ELEVATION GOLD MINING Corp",
        "value": "EVGDF"
    },
    {
        "label": "IPIX - Innovation Pharmaceuticals Inc.",
        "value": "IPIX"
    },
    {
        "label": "SIVBQ - SVB FINANCIAL GROUP",
        "value": "SIVBQ"
    },
    {
        "label": "FLCX - flooidCX Corp.",
        "value": "FLCX"
    },
    {
        "label": "ALAR - Alarum Technologies Ltd.",
        "value": "ALAR"
    },
    {
        "label": "CREG - Smart Powerr Corp.",
        "value": "CREG"
    },
    {
        "label": "LEXX - Lexaria Bioscience Corp.",
        "value": "LEXX"
    },
    {
        "label": "BEOB - BEO BANCORP",
        "value": "BEOB"
    },
    {
        "label": "BKTPF - CRUZ BATTERY METALS CORP.",
        "value": "BKTPF"
    },
    {
        "label": "CHHE - China Health Industries Holdings, Inc.",
        "value": "CHHE"
    },
    {
        "label": "SENR - Strategic Environmental & Energy Resources, Inc.",
        "value": "SENR"
    },
    {
        "label": "EMMA - Emmaus Life Sciences, Inc.",
        "value": "EMMA"
    },
    {
        "label": "IMVIQ - IMV Inc.",
        "value": "IMVIQ"
    },
    {
        "label": "ZDPY - Zoned Properties, Inc.",
        "value": "ZDPY"
    },
    {
        "label": "TOVX - Theriva Biologics, Inc.",
        "value": "TOVX"
    },
    {
        "label": "SIGY - Sigyn Therapeutics, Inc.",
        "value": "SIGY"
    },
    {
        "label": "PTIX - Protagenic Therapeutics, Inc.new",
        "value": "PTIX"
    },
    {
        "label": "ADMT - ADM TRONICS UNLIMITED, INC.",
        "value": "ADMT"
    },
    {
        "label": "AMST - Amesite Inc.",
        "value": "AMST"
    },
    {
        "label": "PRKR - PARKERVISION INC",
        "value": "PRKR"
    },
    {
        "label": "WAVD - WAVEDANCER, INC.",
        "value": "WAVD"
    },
    {
        "label": "NLSC - Namliong SkyCosmos, Inc.",
        "value": "NLSC"
    },
    {
        "label": "INPX - INPIXON",
        "value": "INPX"
    },
    {
        "label": "GDSI - GLOBAL DIGITAL SOLUTIONS INC",
        "value": "GDSI"
    },
    {
        "label": "DBRM - DAYBREAK OIL & GAS, INC.",
        "value": "DBRM"
    },
    {
        "label": "HOTH - Hoth Therapeutics, Inc.",
        "value": "HOTH"
    },
    {
        "label": "SEVCQ - Sono Group N.V.",
        "value": "SEVCQ"
    },
    {
        "label": "MOB - Mobilicom Ltd",
        "value": "MOB"
    },
    {
        "label": "TTNP - TITAN PHARMACEUTICALS INC",
        "value": "TTNP"
    },
    {
        "label": "HPTO - hopTo Inc.",
        "value": "HPTO"
    },
    {
        "label": "GARWF - Golden Arrow Resources Corp",
        "value": "GARWF"
    },
    {
        "label": "BJDX - Bluejay Diagnostics, Inc.",
        "value": "BJDX"
    },
    {
        "label": "MNK - Mallinckrodt plc",
        "value": "MNK"
    },
    {
        "label": "REED - REED'S, INC.",
        "value": "REED"
    },
    {
        "label": "SLNH - Soluna Holdings, Inc",
        "value": "SLNH"
    },
    {
        "label": "NXU - Nxu, Inc.",
        "value": "NXU"
    },
    {
        "label": "CLRI - Cleartronic, Inc.",
        "value": "CLRI"
    },
    {
        "label": "REPCF - REPLICEL LIFE SCIENCES INC.",
        "value": "REPCF"
    },
    {
        "label": "HSCS - Heart Test Laboratories, Inc.",
        "value": "HSCS"
    },
    {
        "label": "NOGN - Nogin, Inc.",
        "value": "NOGN"
    },
    {
        "label": "CNSP - CNS Pharmaceuticals, Inc.",
        "value": "CNSP"
    },
    {
        "label": "WLYW - Wally World Media, Inc",
        "value": "WLYW"
    },
    {
        "label": "JVA - COFFEE HOLDING CO INC",
        "value": "JVA"
    },
    {
        "label": "FUV - Arcimoto Inc",
        "value": "FUV"
    },
    {
        "label": "APM - Aptorum Group Ltd",
        "value": "APM"
    },
    {
        "label": "TENX - TENAX THERAPEUTICS, INC.",
        "value": "TENX"
    },
    {
        "label": "VEDU - Visionary Education Technology Holdings Group Inc.",
        "value": "VEDU"
    },
    {
        "label": "AHNR - ATHENA GOLD CORP",
        "value": "AHNR"
    },
    {
        "label": "OXBR - OXBRIDGE RE HOLDINGS Ltd",
        "value": "OXBR"
    },
    {
        "label": "CUEN - Cuentas Inc.",
        "value": "CUEN"
    },
    {
        "label": "MTEK - Maris Tech Ltd.",
        "value": "MTEK"
    },
    {
        "label": "TGL - TREASURE GLOBAL INC",
        "value": "TGL"
    },
    {
        "label": "NVSGF - Nevada Sunrise Gold Corp",
        "value": "NVSGF"
    },
    {
        "label": "JUPGF - Jupiter Gold Corp",
        "value": "JUPGF"
    },
    {
        "label": "ARAO - AuraSource, Inc.",
        "value": "ARAO"
    },
    {
        "label": "TIKK - TEL INSTRUMENT ELECTRONICS CORP",
        "value": "TIKK"
    },
    {
        "label": "VMNT - Vemanti Group, Inc.",
        "value": "VMNT"
    },
    {
        "label": "PRFX - PAINREFORM LTD.",
        "value": "PRFX"
    },
    {
        "label": "CNET - ZW Data Action Technologies Inc.",
        "value": "CNET"
    },
    {
        "label": "NFTN - NFiniTi inc.",
        "value": "NFTN"
    },
    {
        "label": "MSNVF - MISSION READY SOLUTIONS INC",
        "value": "MSNVF"
    },
    {
        "label": "WINT - WINDTREE THERAPEUTICS INC /DE/",
        "value": "WINT"
    },
    {
        "label": "TGLTY - GCDI S.A./ADR",
        "value": "TGLTY"
    },
    {
        "label": "PBMLF - Pacific Booker Minerals Inc.",
        "value": "PBMLF"
    },
    {
        "label": "SRLZF - Salazar Resources Ltd",
        "value": "SRLZF"
    },
    {
        "label": "GRI - GRI BIO, Inc.",
        "value": "GRI"
    },
    {
        "label": "CING - Cingulate Inc.",
        "value": "CING"
    },
    {
        "label": "PGOL - PATRIOT GOLD CORP",
        "value": "PGOL"
    },
    {
        "label": "SNTW - Summit Networks Inc.",
        "value": "SNTW"
    },
    {
        "label": "ATXI - AVENUE THERAPEUTICS, INC.",
        "value": "ATXI"
    },
    {
        "label": "TSSI - TSS, Inc.",
        "value": "TSSI"
    },
    {
        "label": "IDXG - INTERPACE BIOSCIENCES, INC.",
        "value": "IDXG"
    },
    {
        "label": "MRZM - MARIZYME, INC.",
        "value": "MRZM"
    },
    {
        "label": "AUUD - AUDDIA INC.",
        "value": "AUUD"
    },
    {
        "label": "BUUZ - CalEthos, Inc.",
        "value": "BUUZ"
    },
    {
        "label": "ALRN - AILERON THERAPEUTICS INC",
        "value": "ALRN"
    },
    {
        "label": "PIXY - ShiftPixy, Inc.",
        "value": "PIXY"
    },
    {
        "label": "TPST - Tempest Therapeutics, Inc.",
        "value": "TPST"
    },
    {
        "label": "HLTT - Healthtech Solutions, Inc./UT",
        "value": "HLTT"
    },
    {
        "label": "FWFW - FLYWHEEL ADVANCED TECHNOLOGY, INC.",
        "value": "FWFW"
    },
    {
        "label": "JWEL - Jowell Global Ltd.",
        "value": "JWEL"
    },
    {
        "label": "SILEF - Silver Elephant Mining Corp.",
        "value": "SILEF"
    },
    {
        "label": "GTMAY - GRUPO TMM SAB",
        "value": "GTMAY"
    },
    {
        "label": "QZMRF - QUARTZ MOUNTAIN RESOURCES LTD",
        "value": "QZMRF"
    },
    {
        "label": "GTHP - GUIDED THERAPEUTICS INC",
        "value": "GTHP"
    },
    {
        "label": "EFOI - ENERGY FOCUS, INC/DE",
        "value": "EFOI"
    },
    {
        "label": "XCUR - EXICURE, INC.",
        "value": "XCUR"
    },
    {
        "label": "TDCB - THIRD CENTURY BANCORP",
        "value": "TDCB"
    },
    {
        "label": "IBIO - iBio, Inc.",
        "value": "IBIO"
    },
    {
        "label": "AEMD - AETHLON MEDICAL INC",
        "value": "AEMD"
    },
    {
        "label": "MDGS - Medigus Ltd.",
        "value": "MDGS"
    },
    {
        "label": "CJJD - China Jo-Jo Drugstores Holdings, Inc.",
        "value": "CJJD"
    },
    {
        "label": "SSY - SUNLINK HEALTH SYSTEMS INC",
        "value": "SSY"
    },
    {
        "label": "JPOTF - Jackpot Digital Inc.",
        "value": "JPOTF"
    },
    {
        "label": "RELV - RELIV INTERNATIONAL INC",
        "value": "RELV"
    },
    {
        "label": "LUVU - Luvu Brands, Inc.",
        "value": "LUVU"
    },
    {
        "label": "ENSV - Enservco Corp",
        "value": "ENSV"
    },
    {
        "label": "SBET - SharpLink Gaming Ltd.",
        "value": "SBET"
    },
    {
        "label": "UUU - UNIVERSAL SECURITY INSTRUMENTS INC",
        "value": "UUU"
    },
    {
        "label": "FOXO - FOXO TECHNOLOGIES INC.",
        "value": "FOXO"
    },
    {
        "label": "RSCF - REFLECT SCIENTIFIC, INC.",
        "value": "RSCF"
    },
    {
        "label": "TANH - TANTECH HOLDINGS LTD",
        "value": "TANH"
    },
    {
        "label": "TLRS - Timberline Resources Corp",
        "value": "TLRS"
    },
    {
        "label": "MNRLF - MINERAL MOUNTAIN RESOURCES LTD.",
        "value": "MNRLF"
    },
    {
        "label": "BMXI - BROOKMOUNT EXPLORATIONS INC",
        "value": "BMXI"
    },
    {
        "label": "NEWH - NewHydrogen, Inc.",
        "value": "NEWH"
    },
    {
        "label": "VIDE - VIDEO DISPLAY CORP",
        "value": "VIDE"
    },
    {
        "label": "NFTG - NFT Gaming Co Inc.",
        "value": "NFTG"
    },
    {
        "label": "TNLX - TRANS LUX Corp",
        "value": "TNLX"
    },
    {
        "label": "COSG - Cosmos Group Holdings Inc.",
        "value": "COSG"
    },
    {
        "label": "NXTP - NextPlay Technologies Inc.",
        "value": "NXTP"
    },
    {
        "label": "IRNT - IronNet, Inc.",
        "value": "IRNT"
    },
    {
        "label": "SUIC - SUIC Worldwide Holdings Ltd.",
        "value": "SUIC"
    },
    {
        "label": "AUMN - Golden Minerals Co",
        "value": "AUMN"
    },
    {
        "label": "TKAT - Takung Art Co., Ltd",
        "value": "TKAT"
    },
    {
        "label": "AHG - Akso Health Group",
        "value": "AHG"
    },
    {
        "label": "FXBY - FOXBY CORP.",
        "value": "FXBY"
    },
    {
        "label": "SRSG - SPIRITS TIME INTERNATIONAL, INC.",
        "value": "SRSG"
    },
    {
        "label": "UNQL - Unique Logistics International, Inc.",
        "value": "UNQL"
    },
    {
        "label": "LBUY - LEAFBUYER TECHNOLOGIES, INC.",
        "value": "LBUY"
    },
    {
        "label": "BZYR - BURZYNSKI RESEARCH INSTITUTE INC",
        "value": "BZYR"
    },
    {
        "label": "SIDU - Sidus Space Inc.",
        "value": "SIDU"
    },
    {
        "label": "PVNNF - PV Nano Cell, Ltd.",
        "value": "PVNNF"
    },
    {
        "label": "BON - Bon Natural Life Ltd",
        "value": "BON"
    },
    {
        "label": "MACE - MACE SECURITY INTERNATIONAL INC",
        "value": "MACE"
    },
    {
        "label": "MCOM - micromobility.com Inc.",
        "value": "MCOM"
    },
    {
        "label": "VRAX - Virax Biolabs Group Ltd",
        "value": "VRAX"
    },
    {
        "label": "HHSE - Hannover House, Inc.",
        "value": "HHSE"
    },
    {
        "label": "CELZ - CREATIVE MEDICAL TECHNOLOGY HOLDINGS, INC.",
        "value": "CELZ"
    },
    {
        "label": "SVRE - SaverOne 2014 Ltd.",
        "value": "SVRE"
    },
    {
        "label": "GPOX - GPO Plus, Inc.",
        "value": "GPOX"
    },
    {
        "label": "AUVI - Applied UV, Inc.",
        "value": "AUVI"
    },
    {
        "label": "IAALF - IBC Advanced Alloys Corp.",
        "value": "IAALF"
    },
    {
        "label": "NEXI - NexImmune, Inc.",
        "value": "NEXI"
    },
    {
        "label": "LWLW - Longwen Group Corp.",
        "value": "LWLW"
    },
    {
        "label": "CMOT - Curtiss Motorcycle Company, Inc.",
        "value": "CMOT"
    },
    {
        "label": "PRSO - Peraso Inc.",
        "value": "PRSO"
    },
    {
        "label": "NURO - NeuroMetrix, Inc.",
        "value": "NURO"
    },
    {
        "label": "VEII - Value Exchange International, Inc.",
        "value": "VEII"
    },
    {
        "label": "VYND - Vynleads, Inc.",
        "value": "VYND"
    },
    {
        "label": "TCON - Tracon Pharmaceuticals, Inc.",
        "value": "TCON"
    },
    {
        "label": "NUZE - NuZee, Inc.",
        "value": "NUZE"
    },
    {
        "label": "IPSI - Innovative Payment Solutions, Inc.",
        "value": "IPSI"
    },
    {
        "label": "PTPI - Petros Pharmaceuticals, Inc.",
        "value": "PTPI"
    },
    {
        "label": "FFLO - Free Flow, Inc.",
        "value": "FFLO"
    },
    {
        "label": "CEAD - CEA Industries Inc.",
        "value": "CEAD"
    },
    {
        "label": "ACAN - AmeriCann, Inc.",
        "value": "ACAN"
    },
    {
        "label": "INQD - Indoor Harvest Corp",
        "value": "INQD"
    },
    {
        "label": "RGBP - Regen BioPharma Inc",
        "value": "RGBP"
    },
    {
        "label": "NROM - NOBLE ROMANS INC",
        "value": "NROM"
    },
    {
        "label": "ARDS - Aridis Pharmaceuticals, Inc.",
        "value": "ARDS"
    },
    {
        "label": "FEMY - FEMASYS INC",
        "value": "FEMY"
    },
    {
        "label": "OGEN - ORAGENICS INC",
        "value": "OGEN"
    },
    {
        "label": "ISUN - ISUN, INC.",
        "value": "ISUN"
    },
    {
        "label": "LCTC - Lifeloc Technologies, Inc",
        "value": "LCTC"
    },
    {
        "label": "SILO - Silo Pharma, Inc.",
        "value": "SILO"
    },
    {
        "label": "RYES - Rise Gold Corp.",
        "value": "RYES"
    },
    {
        "label": "JUVAF - JUVA LIFE INC./Canada",
        "value": "JUVAF"
    },
    {
        "label": "NVNT - NOVINT TECHNOLOGIES INC",
        "value": "NVNT"
    },
    {
        "label": "GCTK - GlucoTrack, Inc.",
        "value": "GCTK"
    },
    {
        "label": "XBIO - Xenetic Biosciences, Inc.",
        "value": "XBIO"
    },
    {
        "label": "NAHD - New Asia Holdings, Inc.",
        "value": "NAHD"
    },
    {
        "label": "LXEH - Lixiang Education Holding Co. Ltd.",
        "value": "LXEH"
    },
    {
        "label": "LOV - Spark Networks SE",
        "value": "LOV"
    },
    {
        "label": "AVGR - Avinger Inc",
        "value": "AVGR"
    },
    {
        "label": "BKYI - BIO KEY INTERNATIONAL INC",
        "value": "BKYI"
    },
    {
        "label": "BTB - Bit Brother Ltd",
        "value": "BTB"
    },
    {
        "label": "MICS - SINGING MACHINE CO INC",
        "value": "MICS"
    },
    {
        "label": "KLNG - Koil Energy Solutions, Inc.",
        "value": "KLNG"
    },
    {
        "label": "MWRK - METAWORKS PLATFORMS, INC.",
        "value": "MWRK"
    },
    {
        "label": "OP - OceanPal Inc.",
        "value": "OP"
    },
    {
        "label": "BONZ - Marvion Inc.",
        "value": "BONZ"
    },
    {
        "label": "XTLB - XTL BIOPHARMACEUTICALS LTD",
        "value": "XTLB"
    },
    {
        "label": "BETRF - BetterLife Pharma Inc.",
        "value": "BETRF"
    },
    {
        "label": "IMTE - Integrated Media Technology Ltd",
        "value": "IMTE"
    },
    {
        "label": "ABVC - ABVC BIOPHARMA, INC.",
        "value": "ABVC"
    },
    {
        "label": "CNFN - CFN Enterprises Inc.",
        "value": "CNFN"
    },
    {
        "label": "FULO - FULLNET COMMUNICATIONS INC",
        "value": "FULO"
    },
    {
        "label": "TMBXF - TOMBSTONE EXPLORATION CORP",
        "value": "TMBXF"
    },
    {
        "label": "HALL - HALLMARK FINANCIAL SERVICES INC",
        "value": "HALL"
    },
    {
        "label": "PSTV - PLUS THERAPEUTICS, INC.",
        "value": "PSTV"
    },
    {
        "label": "KOAN - Resonate Blends, Inc.",
        "value": "KOAN"
    },
    {
        "label": "CVSI - CV Sciences, Inc.",
        "value": "CVSI"
    },
    {
        "label": "BTTR - Better Choice Co Inc.",
        "value": "BTTR"
    },
    {
        "label": "CDSG - China Dongsheng International, Inc.",
        "value": "CDSG"
    },
    {
        "label": "AEY - ADDVANTAGE TECHNOLOGIES GROUP INC",
        "value": "AEY"
    },
    {
        "label": "LDSN - Luduson G Inc.",
        "value": "LDSN"
    },
    {
        "label": "ODII - Odyssey Semiconductor Technologies, Inc.",
        "value": "ODII"
    },
    {
        "label": "RGMP - Regnum Corp.",
        "value": "RGMP"
    },
    {
        "label": "PBTS - Powerbridge Technologies Co., Ltd.",
        "value": "PBTS"
    },
    {
        "label": "NRIS - Norris Industries, Inc.",
        "value": "NRIS"
    },
    {
        "label": "TIGCF - Triumph Gold Corp.",
        "value": "TIGCF"
    },
    {
        "label": "IMTH - INNOVATIVE MEDTECH, INC.",
        "value": "IMTH"
    },
    {
        "label": "METX - BTC Digital Ltd.",
        "value": "METX"
    },
    {
        "label": "USLG - U.S. Lighting Group, Inc.",
        "value": "USLG"
    },
    {
        "label": "KTRA - Kintara Therapeutics, Inc.",
        "value": "KTRA"
    },
    {
        "label": "QRON - Qrons Inc.",
        "value": "QRON"
    },
    {
        "label": "ABCFF - ABACUS MINING & EXPLORATION CORP",
        "value": "ABCFF"
    },
    {
        "label": "GDC - GD Culture Group Ltd",
        "value": "GDC"
    },
    {
        "label": "TBLT - Toughbuilt Industries, Inc",
        "value": "TBLT"
    },
    {
        "label": "JCSE - JE Cleantech Holdings Ltd",
        "value": "JCSE"
    },
    {
        "label": "WISA - WISA TECHNOLOGIES, INC.",
        "value": "WISA"
    },
    {
        "label": "AMBO - Ambow Education Holding Ltd.",
        "value": "AMBO"
    },
    {
        "label": "RHE - REGIONAL HEALTH PROPERTIES, INC",
        "value": "RHE"
    },
    {
        "label": "MMND - MASTERMIND, INC.",
        "value": "MMND"
    },
    {
        "label": "SLGD - Scott's Liquid Gold - Inc.",
        "value": "SLGD"
    },
    {
        "label": "CYCC - Cyclacel Pharmaceuticals, Inc.",
        "value": "CYCC"
    },
    {
        "label": "OVTZ - OCULUS VISIONTECH INC.",
        "value": "OVTZ"
    },
    {
        "label": "CANN - TREES Corp (Colorado)",
        "value": "CANN"
    },
    {
        "label": "ARTL - ARTELO BIOSCIENCES, INC.",
        "value": "ARTL"
    },
    {
        "label": "YOSH - Yoshiharu Global Co.",
        "value": "YOSH"
    },
    {
        "label": "CLSH - CLS Holdings USA, Inc.",
        "value": "CLSH"
    },
    {
        "label": "SNTG - Sentage Holdings Inc.",
        "value": "SNTG"
    },
    {
        "label": "SKAS - Saker Aviation Services, Inc.",
        "value": "SKAS"
    },
    {
        "label": "SIPN - SIPP International Industries, Inc.",
        "value": "SIPN"
    },
    {
        "label": "WNFT - Worldwide NFT Inc.",
        "value": "WNFT"
    },
    {
        "label": "BTOG - BIT ORIGIN Ltd",
        "value": "BTOG"
    },
    {
        "label": "DBGI - Digital Brands Group, Inc.",
        "value": "DBGI"
    },
    {
        "label": "BOPO - Biopower Operations Corp",
        "value": "BOPO"
    },
    {
        "label": "ENSC - Ensysce Biosciences, Inc.",
        "value": "ENSC"
    },
    {
        "label": "AVOI - Advanced Voice Recognition Systems, Inc",
        "value": "AVOI"
    },
    {
        "label": "FHSEY - First High-School Education Group Co., Ltd.",
        "value": "FHSEY"
    },
    {
        "label": "EAWD - Energy & Water Development Corp",
        "value": "EAWD"
    },
    {
        "label": "KITL - Kisses From Italy Inc.",
        "value": "KITL"
    },
    {
        "label": "GBR - New Concept Energy, Inc.",
        "value": "GBR"
    },
    {
        "label": "CYAN - CYANOTECH CORP",
        "value": "CYAN"
    },
    {
        "label": "SZLSF - STAGEZERO LIFE SCIENCES LTD.",
        "value": "SZLSF"
    },
    {
        "label": "ICU - SeaStar Medical Holding Corp",
        "value": "ICU"
    },
    {
        "label": "SMX - SMX (Security Matters) Public Ltd Co",
        "value": "SMX"
    },
    {
        "label": "INTV - INTEGRATED VENTURES, INC.",
        "value": "INTV"
    },
    {
        "label": "MLMN - Millennium Prime, Inc.",
        "value": "MLMN"
    },
    {
        "label": "VINE - Fresh Vine Wine, Inc.",
        "value": "VINE"
    },
    {
        "label": "KGKG - KONA GOLD BEVERAGE, INC.",
        "value": "KGKG"
    },
    {
        "label": "LRDC - Laredo Oil, Inc.",
        "value": "LRDC"
    },
    {
        "label": "QLGN - Qualigen Therapeutics, Inc.",
        "value": "QLGN"
    },
    {
        "label": "SSOK - Sunstock, Inc.",
        "value": "SSOK"
    },
    {
        "label": "ZPHYF - ZEPHYR MINERALS LTD.",
        "value": "ZPHYF"
    },
    {
        "label": "QNRX - Quoin Pharmaceuticals, Ltd.",
        "value": "QNRX"
    },
    {
        "label": "WDLF - Decentral Life, Inc.",
        "value": "WDLF"
    },
    {
        "label": "EEGI - Eline Entertainment Group, Inc.",
        "value": "EEGI"
    },
    {
        "label": "BLNC - Balance Labs, Inc.",
        "value": "BLNC"
    },
    {
        "label": "BABB - BAB, INC.",
        "value": "BABB"
    },
    {
        "label": "PMCOF - Prospector Metals Corp.",
        "value": "PMCOF"
    },
    {
        "label": "RKFL - ROCKETFUEL BLOCKCHAIN, INC.",
        "value": "RKFL"
    },
    {
        "label": "MINM - MINIM, INC.",
        "value": "MINM"
    },
    {
        "label": "PBIO - PRESSURE BIOSCIENCES INC",
        "value": "PBIO"
    },
    {
        "label": "SMTK - SmartKem, Inc.",
        "value": "SMTK"
    },
    {
        "label": "MEDS - TRxADE HEALTH, INC",
        "value": "MEDS"
    },
    {
        "label": "DBMM - Digital Brand Media & Marketing Group, Inc.",
        "value": "DBMM"
    },
    {
        "label": "ADXS - Ayala Pharmaceuticals, Inc.",
        "value": "ADXS"
    },
    {
        "label": "RIBT - RiceBran Technologies",
        "value": "RIBT"
    },
    {
        "label": "AXIM - AXIM BIOTECHNOLOGIES, INC.",
        "value": "AXIM"
    },
    {
        "label": "IPTK - AS-IP TECH INC",
        "value": "IPTK"
    },
    {
        "label": "NCPL - Netcapital Inc.",
        "value": "NCPL"
    },
    {
        "label": "SING - SinglePoint Inc.",
        "value": "SING"
    },
    {
        "label": "DTGI - Digerati Technologies, Inc.",
        "value": "DTGI"
    },
    {
        "label": "KNGRF - Kingsmen Resources Ltd.",
        "value": "KNGRF"
    },
    {
        "label": "BWMG - Brownie's Marine Group, Inc",
        "value": "BWMG"
    },
    {
        "label": "TARSF - Alianza Minerals Ltd.",
        "value": "TARSF"
    },
    {
        "label": "APTX - Aptinyx Inc.",
        "value": "APTX"
    },
    {
        "label": "BVXV - BiondVax Pharmaceuticals Ltd.",
        "value": "BVXV"
    },
    {
        "label": "GSAC - GELSTAT CORP",
        "value": "GSAC"
    },
    {
        "label": "CETX - CEMTREX INC",
        "value": "CETX"
    },
    {
        "label": "QURT - Quarta-Rad, Inc.",
        "value": "QURT"
    },
    {
        "label": "PIK - KIDPIK CORP.",
        "value": "PIK"
    },
    {
        "label": "TNON - Tenon Medical, Inc.",
        "value": "TNON"
    },
    {
        "label": "ABCP - AmBase Corp",
        "value": "ABCP"
    },
    {
        "label": "AWON - A1 Group, Inc.",
        "value": "AWON"
    },
    {
        "label": "TMBR - Timber Pharmaceuticals, Inc.",
        "value": "TMBR"
    },
    {
        "label": "JZXN - Jiuzi Holdings, Inc.",
        "value": "JZXN"
    },
    {
        "label": "GSTX - Graphene & Solar Technologies Ltd",
        "value": "GSTX"
    },
    {
        "label": "HENC - Hero Technologies Inc.",
        "value": "HENC"
    },
    {
        "label": "DSGT - DSG Global Inc.",
        "value": "DSGT"
    },
    {
        "label": "TLSS - Transportation & Logistics Systems, Inc.",
        "value": "TLSS"
    },
    {
        "label": "RCRT - Recruiter.com Group, Inc.",
        "value": "RCRT"
    },
    {
        "label": "JFBR - Jeffs' Brands Ltd",
        "value": "JFBR"
    },
    {
        "label": "ENVB - Enveric Biosciences, Inc.",
        "value": "ENVB"
    },
    {
        "label": "ECIA - ENCISION INC",
        "value": "ECIA"
    },
    {
        "label": "OWPC - One World Products, Inc.",
        "value": "OWPC"
    },
    {
        "label": "LIXT - LIXTE BIOTECHNOLOGY HOLDINGS, INC.",
        "value": "LIXT"
    },
    {
        "label": "GPLL - GPL Holdings, Inc.",
        "value": "GPLL"
    },
    {
        "label": "CGOLF - Contact Gold Corp.",
        "value": "CGOLF"
    },
    {
        "label": "BGAVF - Bravada Gold Corp",
        "value": "BGAVF"
    },
    {
        "label": "SINT - Sintx Technologies, Inc.",
        "value": "SINT"
    },
    {
        "label": "HITC - Healthcare Integrated Technologies Inc.",
        "value": "HITC"
    },
    {
        "label": "ABTI - ALTEROLA BIOTECH INC.",
        "value": "ABTI"
    },
    {
        "label": "SNOA - Sonoma Pharmaceuticals, Inc.",
        "value": "SNOA"
    },
    {
        "label": "ITP - IT TECH PACKAGING, INC.",
        "value": "ITP"
    },
    {
        "label": "CBDY - Target Group Inc.",
        "value": "CBDY"
    },
    {
        "label": "OILCF - Permex Petroleum Corp",
        "value": "OILCF"
    },
    {
        "label": "PW - Power REIT",
        "value": "PW"
    },
    {
        "label": "HILS - Hillstream BioPharma Inc.",
        "value": "HILS"
    },
    {
        "label": "RMHB - Rocky Mountain High Brands, Inc.",
        "value": "RMHB"
    },
    {
        "label": "FRGT - Freight Technologies, Inc.",
        "value": "FRGT"
    },
    {
        "label": "CMDRF - COMMANDER RESOURCES LTD",
        "value": "CMDRF"
    },
    {
        "label": "CPMV - Mosaic ImmunoEngineering Inc.",
        "value": "CPMV"
    },
    {
        "label": "NXL - Nexalin Technology, Inc.",
        "value": "NXL"
    },
    {
        "label": "PBLA - Panbela Therapeutics, Inc.",
        "value": "PBLA"
    },
    {
        "label": "ILAL - International Land Alliance Inc.",
        "value": "ILAL"
    },
    {
        "label": "SNPX - Synaptogenix, Inc.",
        "value": "SNPX"
    },
    {
        "label": "EVOK - Evoke Pharma Inc",
        "value": "EVOK"
    },
    {
        "label": "BLCM - BELLICUM PHARMACEUTICALS, INC",
        "value": "BLCM"
    },
    {
        "label": "TRCK - Track Group, Inc.",
        "value": "TRCK"
    },
    {
        "label": "ONFO - Onfolio Holdings, Inc",
        "value": "ONFO"
    },
    {
        "label": "TTCFQ - Tattooed Chef, Inc.",
        "value": "TTCFQ"
    },
    {
        "label": "SMREF - SUN SUMMIT MINERALS CORP.",
        "value": "SMREF"
    },
    {
        "label": "REVB - REVELATION BIOSCIENCES, INC.",
        "value": "REVB"
    },
    {
        "label": "TGGI - TRANS GLOBAL GROUP, INC.",
        "value": "TGGI"
    },
    {
        "label": "ALXEF - ALX RESOURCES CORP.",
        "value": "ALXEF"
    },
    {
        "label": "COMS - COMSovereign Holding Corp.",
        "value": "COMS"
    },
    {
        "label": "CNNC - Cannonau Corp.",
        "value": "CNNC"
    },
    {
        "label": "YQAI - YOUNEEQAI TECHNICAL SERVICES, INC.",
        "value": "YQAI"
    },
    {
        "label": "NMEX - NORTHERN MINERALS & EXPLORATION LTD.",
        "value": "NMEX"
    },
    {
        "label": "SECO - Secoo Holding Ltd",
        "value": "SECO"
    },
    {
        "label": "SITS - SOUTHERN ITS INTERNATIONAL, INC.",
        "value": "SITS"
    },
    {
        "label": "WGMCF - WINSTON GOLD CORP.",
        "value": "WGMCF"
    },
    {
        "label": "EVVL - Evil Empire Designs, Inc.",
        "value": "EVVL"
    },
    {
        "label": "TPPM - Yotta Global, Inc.",
        "value": "TPPM"
    },
    {
        "label": "FCIC - FCCC INC",
        "value": "FCIC"
    },
    {
        "label": "UTME - UTime Ltd",
        "value": "UTME"
    },
    {
        "label": "SWVL - Swvl Holdings Corp",
        "value": "SWVL"
    },
    {
        "label": "RELI - Reliance Global Group, Inc.",
        "value": "RELI"
    },
    {
        "label": "PALI - PALISADE BIO, INC.",
        "value": "PALI"
    },
    {
        "label": "VERB - Verb Technology Company, Inc.",
        "value": "VERB"
    },
    {
        "label": "BLPH - Bellerophon Therapeutics, Inc.",
        "value": "BLPH"
    },
    {
        "label": "PHIO - Phio Pharmaceuticals Corp.",
        "value": "PHIO"
    },
    {
        "label": "SVFD - Save Foods, Inc.",
        "value": "SVFD"
    },
    {
        "label": "ACON - Aclarion, Inc.",
        "value": "ACON"
    },
    {
        "label": "VYBE - Limitless X Holdings Inc.",
        "value": "VYBE"
    },
    {
        "label": "LGHL - Lion Group Holding Ltd",
        "value": "LGHL"
    },
    {
        "label": "ADMG - ADAMANT DRI PROCESSING & MINERALS GROUP",
        "value": "ADMG"
    },
    {
        "label": "SQL - SeqLL, Inc.",
        "value": "SQL"
    },
    {
        "label": "GHST - GHST World Inc.",
        "value": "GHST"
    },
    {
        "label": "DRMA - Dermata Therapeutics, Inc.",
        "value": "DRMA"
    },
    {
        "label": "SISI - SHINECO, INC.",
        "value": "SISI"
    },
    {
        "label": "DWAY - Driveitaway Holdings, Inc.",
        "value": "DWAY"
    },
    {
        "label": "CIIT - Tianci International, Inc.",
        "value": "CIIT"
    },
    {
        "label": "SVVC - Firsthand Technology Value Fund, Inc.",
        "value": "SVVC"
    },
    {
        "label": "CVAT - Cavitation Technologies, Inc.",
        "value": "CVAT"
    },
    {
        "label": "HMBL - HUMBL, INC.",
        "value": "HMBL"
    },
    {
        "label": "STAF - Staffing 360 Solutions, Inc.",
        "value": "STAF"
    },
    {
        "label": "KPRX - KIORA PHARMACEUTICALS INC",
        "value": "KPRX"
    },
    {
        "label": "PLSH - PANACEA LIFE SCIENCES HOLDINGS, INC.",
        "value": "PLSH"
    },
    {
        "label": "RSHN - RushNet, Inc.",
        "value": "RSHN"
    },
    {
        "label": "WHLR - Wheeler Real Estate Investment Trust, Inc.",
        "value": "WHLR"
    },
    {
        "label": "XALL - Xalles Holdings Inc.",
        "value": "XALL"
    },
    {
        "label": "INBS - INTELLIGENT BIO SOLUTIONS INC.",
        "value": "INBS"
    },
    {
        "label": "USDR - UAS Drone Corp.",
        "value": "USDR"
    },
    {
        "label": "BXRX - Baudax Bio, Inc.",
        "value": "BXRX"
    },
    {
        "label": "EFSH - 1847 Holdings LLC",
        "value": "EFSH"
    },
    {
        "label": "INTI - Inhibitor Therapeutics, Inc.",
        "value": "INTI"
    },
    {
        "label": "SNPW - Sun Pacific Holding Corp.",
        "value": "SNPW"
    },
    {
        "label": "NVFY - Nova Lifestyle, Inc.",
        "value": "NVFY"
    },
    {
        "label": "TSOI - THERAPEUTIC SOLUTIONS INTERNATIONAL, INC.",
        "value": "TSOI"
    },
    {
        "label": "FTHWF - Field Trip Health & Wellness Ltd.",
        "value": "FTHWF"
    },
    {
        "label": "TIRX - TIAN RUIXIANG HOLDINGS LTD",
        "value": "TIRX"
    },
    {
        "label": "EDBL - Edible Garden AG Inc",
        "value": "EDBL"
    },
    {
        "label": "BACK - IMAC Holdings, Inc.",
        "value": "BACK"
    },
    {
        "label": "ZVSA - ZyVersa Therapeutics, Inc.",
        "value": "ZVSA"
    },
    {
        "label": "RKDA - Arcadia Biosciences, Inc.",
        "value": "RKDA"
    },
    {
        "label": "ICCO - INTERCARE DX INC",
        "value": "ICCO"
    },
    {
        "label": "MYSZ - My Size, Inc.",
        "value": "MYSZ"
    },
    {
        "label": "QLIS - Qualis Innovations, Inc.",
        "value": "QLIS"
    },
    {
        "label": "SNGX - SOLIGENIX, INC.",
        "value": "SNGX"
    },
    {
        "label": "FRTX - Fresh Tracks Therapeutics, Inc.",
        "value": "FRTX"
    },
    {
        "label": "VS - Versus Systems Inc.",
        "value": "VS"
    },
    {
        "label": "AGFY - Agrify Corp",
        "value": "AGFY"
    },
    {
        "label": "RMED - Ra Medical Systems, Inc.",
        "value": "RMED"
    },
    {
        "label": "SHMY - Synergy Empire Ltd",
        "value": "SHMY"
    },
    {
        "label": "VCOR - VISIBER57 CORP.",
        "value": "VCOR"
    },
    {
        "label": "NVIV - INVIVO THERAPEUTICS HOLDINGS CORP.",
        "value": "NVIV"
    },
    {
        "label": "PTCO - PetroGas Co",
        "value": "PTCO"
    },
    {
        "label": "RSLS - ReShape Lifesciences Inc.",
        "value": "RSLS"
    },
    {
        "label": "AIHS - Senmiao Technology Ltd",
        "value": "AIHS"
    },
    {
        "label": "ENRT - Enertopia Corp.",
        "value": "ENRT"
    },
    {
        "label": "AUNFF - Aurcana Silver Corp",
        "value": "AUNFF"
    },
    {
        "label": "NAOV - NanoVibronix, Inc.",
        "value": "NAOV"
    },
    {
        "label": "VRVR - VIRTUAL INTERACTIVE TECHNOLOGIES CORP.",
        "value": "VRVR"
    },
    {
        "label": "RDHL - RedHill Biopharma Ltd.",
        "value": "RDHL"
    },
    {
        "label": "PRET - PENNSYLVANIA REAL ESTATE INVESTMENT TRUST",
        "value": "PRET"
    },
    {
        "label": "ADAD - Huaizhong Health Group, Inc.",
        "value": "ADAD"
    },
    {
        "label": "GRHI - GOLD ROCK HOLDINGS, INC.",
        "value": "GRHI"
    },
    {
        "label": "YCBD - cbdMD, Inc.",
        "value": "YCBD"
    },
    {
        "label": "WORX - SCWorx Corp.",
        "value": "WORX"
    },
    {
        "label": "AGTX - Agentix Corp.",
        "value": "AGTX"
    },
    {
        "label": "GNTOF - GENTOR RESOURCES INC.",
        "value": "GNTOF"
    },
    {
        "label": "BITTF - BITTERROOT RESOURCES LTD",
        "value": "BITTF"
    },
    {
        "label": "FDCT - FDCTECH, INC.",
        "value": "FDCT"
    },
    {
        "label": "ATNF - 180 Life Sciences Corp.",
        "value": "ATNF"
    },
    {
        "label": "CCCP - Crona Corp.",
        "value": "CCCP"
    },
    {
        "label": "SRCO - SPARTA COMMERCIAL SERVICES, INC.",
        "value": "SRCO"
    },
    {
        "label": "CFRX - CONTRAFECT Corp",
        "value": "CFRX"
    },
    {
        "label": "GSBX - Golden State Bancorp",
        "value": "GSBX"
    },
    {
        "label": "BYOC - Beyond Commerce, Inc.",
        "value": "BYOC"
    },
    {
        "label": "TNRG - Thunder Energies Corp",
        "value": "TNRG"
    },
    {
        "label": "DCSX - Direct Communication Solutions, Inc.",
        "value": "DCSX"
    },
    {
        "label": "DYNT - DYNATRONICS CORP",
        "value": "DYNT"
    },
    {
        "label": "SPCB - SuperCom Ltd",
        "value": "SPCB"
    },
    {
        "label": "MYMX - MYMETICS CORP",
        "value": "MYMX"
    },
    {
        "label": "VOCL - Creatd, Inc.",
        "value": "VOCL"
    },
    {
        "label": "CDJM - Carnegie Development, Inc",
        "value": "CDJM"
    },
    {
        "label": "ASTI - Ascent Solar Technologies, Inc.",
        "value": "ASTI"
    },
    {
        "label": "ROYL - Royale Energy, Inc.",
        "value": "ROYL"
    },
    {
        "label": "CISS - C3is Inc.",
        "value": "CISS"
    },
    {
        "label": "HSTO - Histogen Inc.",
        "value": "HSTO"
    },
    {
        "label": "CMND - Clearmind Medicine Inc.",
        "value": "CMND"
    },
    {
        "label": "VINO - Gaucho Group Holdings, Inc.",
        "value": "VINO"
    },
    {
        "label": "MGTI - MGT CAPITAL INVESTMENTS, INC.",
        "value": "MGTI"
    },
    {
        "label": "VYCO - VYCOR MEDICAL INC",
        "value": "VYCO"
    },
    {
        "label": "GKIN - Guskin Gold Corp.",
        "value": "GKIN"
    },
    {
        "label": "BPTH - BIO-PATH HOLDINGS INC",
        "value": "BPTH"
    },
    {
        "label": "NIMU - NON INVASIVE MONITORING SYSTEMS INC /FL/",
        "value": "NIMU"
    },
    {
        "label": "APVO - Aptevo Therapeutics Inc.",
        "value": "APVO"
    },
    {
        "label": "IWSH - Wright Investors Service Holdings, Inc.",
        "value": "IWSH"
    },
    {
        "label": "TOFB - TOFUTTI BRANDS INC",
        "value": "TOFB"
    },
    {
        "label": "KERN - Akerna Corp.",
        "value": "KERN"
    },
    {
        "label": "NUGN - Livento Group, Inc.",
        "value": "NUGN"
    },
    {
        "label": "DUSYF - DUESENBERG TECHNOLOGIES INC.",
        "value": "DUSYF"
    },
    {
        "label": "ENDV - ENDONOVO THERAPEUTICS, INC.",
        "value": "ENDV"
    },
    {
        "label": "AMPE - Ampio Pharmaceuticals, Inc.",
        "value": "AMPE"
    },
    {
        "label": "CRDV - Community Redevelopment Inc.",
        "value": "CRDV"
    },
    {
        "label": "NEPT - Neptune Wellness Solutions Inc.",
        "value": "NEPT"
    },
    {
        "label": "LOWLF - Lowell Farms Inc.",
        "value": "LOWLF"
    },
    {
        "label": "NHIQ - NantHealth, Inc.",
        "value": "NHIQ"
    },
    {
        "label": "GULTU - Gulf Coast Ultra Deep Royalty Trust",
        "value": "GULTU"
    },
    {
        "label": "EJH - E-Home Household Service Holdings Ltd",
        "value": "EJH"
    },
    {
        "label": "SLRX - Salarius Pharmaceuticals, Inc.",
        "value": "SLRX"
    },
    {
        "label": "MAGE - MAGELLAN GOLD Corp",
        "value": "MAGE"
    },
    {
        "label": "IONI - I-ON Digital Corp.",
        "value": "IONI"
    },
    {
        "label": "RNAZ - Transcode Therapeutics, Inc.",
        "value": "RNAZ"
    },
    {
        "label": "INM - InMed Pharmaceuticals Inc.",
        "value": "INM"
    },
    {
        "label": "INKW - Greene Concepts, Inc",
        "value": "INKW"
    },
    {
        "label": "THMG - THUNDER MOUNTAIN GOLD INC",
        "value": "THMG"
    },
    {
        "label": "ADMP - Adamis Pharmaceuticals Corp",
        "value": "ADMP"
    },
    {
        "label": "UFAB - Unique Fabricating, Inc.",
        "value": "UFAB"
    },
    {
        "label": "TLLYF - Trilogy International Partners Inc.",
        "value": "TLLYF"
    },
    {
        "label": "BBLG - Bone Biologics Corp",
        "value": "BBLG"
    },
    {
        "label": "GROM - Grom Social Enterprises, Inc.",
        "value": "GROM"
    },
    {
        "label": "CYTO - Altamira Therapeutics Ltd.",
        "value": "CYTO"
    },
    {
        "label": "EBET - EBET, Inc.",
        "value": "EBET"
    },
    {
        "label": "JAN - JanOne Inc.",
        "value": "JAN"
    },
    {
        "label": "GEGP - GOLD ENTERTAINMENT GROUP INC",
        "value": "GEGP"
    },
    {
        "label": "MOTS - Motus GI Holdings, Inc.",
        "value": "MOTS"
    },
    {
        "label": "PTOS - P2 Solar, Inc.",
        "value": "PTOS"
    },
    {
        "label": "UCLE - US NUCLEAR CORP.",
        "value": "UCLE"
    },
    {
        "label": "KRBP - Kiromic Biopharma, Inc.",
        "value": "KRBP"
    },
    {
        "label": "GWTI - GREENWAY TECHNOLOGIES INC",
        "value": "GWTI"
    },
    {
        "label": "STMH - Stem Holdings, Inc.",
        "value": "STMH"
    },
    {
        "label": "ALDS - APPlife Digital Solutions Inc",
        "value": "ALDS"
    },
    {
        "label": "ALDA - ATLANTICA INC",
        "value": "ALDA"
    },
    {
        "label": "FIFG - First Foods Group, Inc.",
        "value": "FIFG"
    },
    {
        "label": "INUMF - Infinitum Copper Corp.",
        "value": "INUMF"
    },
    {
        "label": "REII - RENEWABLE INNOVATIONS, INC.",
        "value": "REII"
    },
    {
        "label": "YTEN - YIELD10 BIOSCIENCE, INC.",
        "value": "YTEN"
    },
    {
        "label": "ADTX - Aditxt, Inc.",
        "value": "ADTX"
    },
    {
        "label": "GBLX - GB SCIENCES INC",
        "value": "GBLX"
    },
    {
        "label": "GLSH - GELESIS HOLDINGS, INC.",
        "value": "GLSH"
    },
    {
        "label": "DLOC - Digital Locations, Inc.",
        "value": "DLOC"
    },
    {
        "label": "QWTR - Quest Water Global, Inc.",
        "value": "QWTR"
    },
    {
        "label": "IPCIF - Intellipharmaceutics International Inc.",
        "value": "IPCIF"
    },
    {
        "label": "KENS - KENILWORTH SYSTEMS CORP",
        "value": "KENS"
    },
    {
        "label": "SVBL - SILVER BULL RESOURCES, INC.",
        "value": "SVBL"
    },
    {
        "label": "PCOK - Pacific Oak Strategic Opportunity REIT, Inc.",
        "value": "PCOK"
    },
    {
        "label": "BMCS - BMCS SUMCOIN INDEX FUND, INC.",
        "value": "BMCS"
    },
    {
        "label": "PTNYF - ParcelPal Logistics Inc.",
        "value": "PTNYF"
    },
    {
        "label": "ASNS - ACTELIS NETWORKS INC",
        "value": "ASNS"
    },
    {
        "label": "LGMK - LogicMark, Inc.",
        "value": "LGMK"
    },
    {
        "label": "TCRI - TechCom, Inc.",
        "value": "TCRI"
    },
    {
        "label": "NBY - NovaBay Pharmaceuticals, Inc.",
        "value": "NBY"
    },
    {
        "label": "IMII - INCEPTION MINING INC.",
        "value": "IMII"
    },
    {
        "label": "CDELF - CANDELARIA MINING CORP.",
        "value": "CDELF"
    },
    {
        "label": "RWGI - Rodedawg International Industries, Inc.",
        "value": "RWGI"
    },
    {
        "label": "SNBH - SENTIENT BRANDS HOLDINGS INC.",
        "value": "SNBH"
    },
    {
        "label": "SMKG - SmartCard Marketing Systems Inc",
        "value": "SMKG"
    },
    {
        "label": "ASAP - Waitr Holdings Inc.",
        "value": "ASAP"
    },
    {
        "label": "AGRX - AGILE THERAPEUTICS INC",
        "value": "AGRX"
    },
    {
        "label": "BSFC - Blue Star Foods Corp.",
        "value": "BSFC"
    },
    {
        "label": "VNUE - VNUE, Inc.",
        "value": "VNUE"
    },
    {
        "label": "LSMG - Lode-Star Mining Inc.",
        "value": "LSMG"
    },
    {
        "label": "DREM - Dream Homes & Development Corp.",
        "value": "DREM"
    },
    {
        "label": "CNXA - Connexa Sports Technologies Inc.",
        "value": "CNXA"
    },
    {
        "label": "AULT - Ault Alliance, Inc.",
        "value": "AULT"
    },
    {
        "label": "FSTJ - First America Resources Corp",
        "value": "FSTJ"
    },
    {
        "label": "TCBP - TC BioPharm (Holdings) plc",
        "value": "TCBP"
    },
    {
        "label": "STEK - Stemtech Corp",
        "value": "STEK"
    },
    {
        "label": "THMO - ThermoGenesis Holdings, Inc.",
        "value": "THMO"
    },
    {
        "label": "SASI - SIGMA ADDITIVE SOLUTIONS, INC.",
        "value": "SASI"
    },
    {
        "label": "NXMR - NextMart Inc.",
        "value": "NXMR"
    },
    {
        "label": "ESMC - ESCALON MEDICAL CORP",
        "value": "ESMC"
    },
    {
        "label": "NWCN - NETWORK CN INC",
        "value": "NWCN"
    },
    {
        "label": "WSCO - Wall Street Media Co, Inc.",
        "value": "WSCO"
    },
    {
        "label": "LTES - Leet Technology Inc.",
        "value": "LTES"
    },
    {
        "label": "SNES - SenesTech, Inc.",
        "value": "SNES"
    },
    {
        "label": "XSNX - NovAccess Global Inc.",
        "value": "XSNX"
    },
    {
        "label": "WHLT - CHASE PACKAGING CORP",
        "value": "WHLT"
    },
    {
        "label": "CRCE - Circle Energy, Inc./NV",
        "value": "CRCE"
    },
    {
        "label": "GMER - GOOD GAMING, INC.",
        "value": "GMER"
    },
    {
        "label": "VPER - VIPER NETWORKS INC",
        "value": "VPER"
    },
    {
        "label": "FMNJ - Franklin Mining, Inc.",
        "value": "FMNJ"
    },
    {
        "label": "IVBT - Innovation1 Biotech Inc.",
        "value": "IVBT"
    },
    {
        "label": "AGRI - AGRIFORCE GROWING SYSTEMS LTD.",
        "value": "AGRI"
    },
    {
        "label": "BBUZ - Engage Mobility, Inc.",
        "value": "BBUZ"
    },
    {
        "label": "OBLG - Oblong, Inc.",
        "value": "OBLG"
    },
    {
        "label": "GRST - ETHEMA HEALTH Corp",
        "value": "GRST"
    },
    {
        "label": "FCCN - SPECTRAL CAPITAL Corp",
        "value": "FCCN"
    },
    {
        "label": "CRWE - Crown Equity Holdings, Inc.",
        "value": "CRWE"
    },
    {
        "label": "KRFG - KING RESOURCES, INC.",
        "value": "KRFG"
    },
    {
        "label": "IMHC - IMPERALIS HOLDING CORP.",
        "value": "IMHC"
    },
    {
        "label": "GTLL - GLOBAL TECHNOLOGIES LTD",
        "value": "GTLL"
    },
    {
        "label": "TMIN - TRENDMAKER INC. LTD.",
        "value": "TMIN"
    },
    {
        "label": "EGMCF - EMERGENT METALS CORP.",
        "value": "EGMCF"
    },
    {
        "label": "CNNN - ConneXionONE Corp.",
        "value": "CNNN"
    },
    {
        "label": "MDWK - MDWerks, Inc.",
        "value": "MDWK"
    },
    {
        "label": "SHRG - SHARING SERVICES GLOBAL Corp",
        "value": "SHRG"
    },
    {
        "label": "PRTYQ - Party City Holdco Inc.",
        "value": "PRTYQ"
    },
    {
        "label": "MHTX - MANHATTAN SCIENTIFICS INC",
        "value": "MHTX"
    },
    {
        "label": "OCTO - Eightco Holdings Inc.",
        "value": "OCTO"
    },
    {
        "label": "CBNT - C-Bond Systems, Inc",
        "value": "CBNT"
    },
    {
        "label": "ARTH - Arch Therapeutics, Inc.",
        "value": "ARTH"
    },
    {
        "label": "COWI - CarbonMeta Technologies, Inc.",
        "value": "COWI"
    },
    {
        "label": "CPHI - CHINA PHARMA HOLDINGS, INC.",
        "value": "CPHI"
    },
    {
        "label": "STQN - STRATEGIC ACQUISITIONS INC /NV/",
        "value": "STQN"
    },
    {
        "label": "RMESF - RED METAL RESOURCES, LTD.",
        "value": "RMESF"
    },
    {
        "label": "ENMI - DH ENCHANTMENT, INC.",
        "value": "ENMI"
    },
    {
        "label": "AKAN - AKANDA CORP.",
        "value": "AKAN"
    },
    {
        "label": "BSPK - Bespoke Extracts, Inc.",
        "value": "BSPK"
    },
    {
        "label": "SRGZ - Star Gold Corp.",
        "value": "SRGZ"
    },
    {
        "label": "EDGM - Edgemode, Inc.",
        "value": "EDGM"
    },
    {
        "label": "HGYN - HONG YUAN HOLDING GROUP",
        "value": "HGYN"
    },
    {
        "label": "TLIF - TOCCA LIFE HOLDINGS, INC.",
        "value": "TLIF"
    },
    {
        "label": "NUWE - Nuwellis, Inc.",
        "value": "NUWE"
    },
    {
        "label": "PCST - PURE CAPITAL SOLUTIONS, INC.",
        "value": "PCST"
    },
    {
        "label": "VNTRQ - Venator Materials PLC",
        "value": "VNTRQ"
    },
    {
        "label": "HGEN - HUMANIGEN, INC",
        "value": "HGEN"
    },
    {
        "label": "GSTC - GlobeStar Therapeutics Corp",
        "value": "GSTC"
    },
    {
        "label": "FWBI - First Wave BioPharma, Inc.",
        "value": "FWBI"
    },
    {
        "label": "WESC - W&E Source Corp.",
        "value": "WESC"
    },
    {
        "label": "NGTF - NightFood Holdings, Inc.",
        "value": "NGTF"
    },
    {
        "label": "ECGR - Bellatora, Inc.",
        "value": "ECGR"
    },
    {
        "label": "KALO - Kallo Inc.",
        "value": "KALO"
    },
    {
        "label": "ATAO - ALTAIR INTERNATIONAL CORP.",
        "value": "ATAO"
    },
    {
        "label": "TPTW - TPT GLOBAL TECH, INC.",
        "value": "TPTW"
    },
    {
        "label": "ABNAF - Aben Resources Ltd.",
        "value": "ABNAF"
    },
    {
        "label": "NFEI - NEW FRONTIER ENERGY INC",
        "value": "NFEI"
    },
    {
        "label": "ELST - ELECTRONIC SYSTEMS TECHNOLOGY INC",
        "value": "ELST"
    },
    {
        "label": "WHSI - WEARABLE HEALTH SOLUTIONS, INC.",
        "value": "WHSI"
    },
    {
        "label": "IONM - Assure Holdings Corp.",
        "value": "IONM"
    },
    {
        "label": "FOMC - FOMO WORLDWIDE, INC.",
        "value": "FOMC"
    },
    {
        "label": "WEJOQ - Wejo Group Ltd",
        "value": "WEJOQ"
    },
    {
        "label": "MMMM - Quad M Solutions, Inc.",
        "value": "MMMM"
    },
    {
        "label": "SRMX - SADDLE RANCH MEDIA, INC.",
        "value": "SRMX"
    },
    {
        "label": "DXF - Dunxin Financial Holdings Ltd",
        "value": "DXF"
    },
    {
        "label": "MNGG - Mining Global, Inc.",
        "value": "MNGG"
    },
    {
        "label": "MITI - Mitesco, Inc.",
        "value": "MITI"
    },
    {
        "label": "EBYH - Strainsforpains, Inc.",
        "value": "EBYH"
    },
    {
        "label": "PCYN - PROCYON CORP",
        "value": "PCYN"
    },
    {
        "label": "MLRT - Metalert, Inc.",
        "value": "MLRT"
    },
    {
        "label": "BNMV - BitNile Metaverse, Inc.",
        "value": "BNMV"
    },
    {
        "label": "AMMJ - American Cannabis Company, Inc.",
        "value": "AMMJ"
    },
    {
        "label": "AHRO - Authentic Holdings, Inc.",
        "value": "AHRO"
    },
    {
        "label": "GNLN - Greenlane Holdings, Inc.",
        "value": "GNLN"
    },
    {
        "label": "EAST - Eastside Distilling, Inc.",
        "value": "EAST"
    },
    {
        "label": "EPGG - Empire Global Gaming, Inc.",
        "value": "EPGG"
    },
    {
        "label": "AVTX - Avalo Therapeutics, Inc.",
        "value": "AVTX"
    },
    {
        "label": "ASFT - Appsoft Technologies, Inc.",
        "value": "ASFT"
    },
    {
        "label": "SMME - SmartMetric, Inc.",
        "value": "SMME"
    },
    {
        "label": "ALTX - ALTEX INDUSTRIES INC",
        "value": "ALTX"
    },
    {
        "label": "XNDA - Tribal Rides International Corp.",
        "value": "XNDA"
    },
    {
        "label": "ICNB - Iconic Brands, Inc.",
        "value": "ICNB"
    },
    {
        "label": "GLMD - Galmed Pharmaceuticals Ltd.",
        "value": "GLMD"
    },
    {
        "label": "YAYO - EVmo, Inc.",
        "value": "YAYO"
    },
    {
        "label": "VRTC - VERITEC INC",
        "value": "VRTC"
    },
    {
        "label": "HSTC - HST Global, Inc.",
        "value": "HSTC"
    },
    {
        "label": "HBUV - Hubilu Venture Corp",
        "value": "HBUV"
    },
    {
        "label": "FECOF - FEC Resources Inc.",
        "value": "FECOF"
    },
    {
        "label": "WOLV - Wolverine Resources Corp.",
        "value": "WOLV"
    },
    {
        "label": "SXTC - China SXT Pharmaceuticals, Inc.",
        "value": "SXTC"
    },
    {
        "label": "DIGP - Digipath, Inc.",
        "value": "DIGP"
    },
    {
        "label": "MMMW - MASS MEGAWATTS WIND POWER INC",
        "value": "MMMW"
    },
    {
        "label": "PCMC - PUBLIC CO MANAGEMENT CORP",
        "value": "PCMC"
    },
    {
        "label": "ZRFY - Zerify, Inc.",
        "value": "ZRFY"
    },
    {
        "label": "ATYG - Saxon Capital Group, Inc./DE",
        "value": "ATYG"
    },
    {
        "label": "GIGA - GIGA TRONICS INC",
        "value": "GIGA"
    },
    {
        "label": "GYGC - Guyana Gold Corp",
        "value": "GYGC"
    },
    {
        "label": "ALLR - Allarity Therapeutics, Inc.",
        "value": "ALLR"
    },
    {
        "label": "ATVK - Ameritek Ventures, Inc.",
        "value": "ATVK"
    },
    {
        "label": "NUGL - NUGL, INC.",
        "value": "NUGL"
    },
    {
        "label": "TCCO - TECHNICAL COMMUNICATIONS CORP",
        "value": "TCCO"
    },
    {
        "label": "AVPMF - AVRUPA MINERALS LTD.",
        "value": "AVPMF"
    },
    {
        "label": "CAPC - CAPSTONE COMPANIES, INC.",
        "value": "CAPC"
    },
    {
        "label": "UK - Ucommune International Ltd",
        "value": "UK"
    },
    {
        "label": "HCDI - Harbor Custom Development, Inc.",
        "value": "HCDI"
    },
    {
        "label": "IFBD - Infobird Co., Ltd",
        "value": "IFBD"
    },
    {
        "label": "EMAX - Ecomax, Inc",
        "value": "EMAX"
    },
    {
        "label": "BBIG - Vinco Ventures, Inc.",
        "value": "BBIG"
    },
    {
        "label": "NXEN - NEXIEN BIOPHARMA, INC.",
        "value": "NXEN"
    },
    {
        "label": "SSOF - Sixty Six Oilfield Services, Inc.",
        "value": "SSOF"
    },
    {
        "label": "CLOW - Cloudweb, Inc.",
        "value": "CLOW"
    },
    {
        "label": "CSUI - CANNABIS SUISSE CORP.",
        "value": "CSUI"
    },
    {
        "label": "SILS - SILVER SCOTT MINES INC",
        "value": "SILS"
    },
    {
        "label": "BWMY - BorrowMoney.com, Inc.",
        "value": "BWMY"
    },
    {
        "label": "WNDW - SolarWindow Technologies, Inc.",
        "value": "WNDW"
    },
    {
        "label": "INLB - Item 9 Labs Corp.",
        "value": "INLB"
    },
    {
        "label": "BZWR - Business Warrior Corp",
        "value": "BZWR"
    },
    {
        "label": "FMHS - FARMHOUSE, INC. /NV",
        "value": "FMHS"
    },
    {
        "label": "AASP - GLOBAL ACQUISITIONS Corp",
        "value": "AASP"
    },
    {
        "label": "SVSN - STEREO VISION ENTERTAINMENT INC",
        "value": "SVSN"
    },
    {
        "label": "BIOC - BIOCEPT INC",
        "value": "BIOC"
    },
    {
        "label": "OPGN - OPGEN INC",
        "value": "OPGN"
    },
    {
        "label": "TTCM - TAUTACHROME INC.",
        "value": "TTCM"
    },
    {
        "label": "SIXWF - SIXTH WAVE INNOVATIONS INC.",
        "value": "SIXWF"
    },
    {
        "label": "OGAA - Organic Agricultural Co Ltd",
        "value": "OGAA"
    },
    {
        "label": "LBSR - LIBERTY STAR URANIUM & METALS CORP.",
        "value": "LBSR"
    },
    {
        "label": "GMVD - G Medical Innovations Holdings Ltd.",
        "value": "GMVD"
    },
    {
        "label": "DVLP - Golden Developing Solutions, Inc.",
        "value": "DVLP"
    },
    {
        "label": "BOTY - LINGERIE FIGHTING CHAMPIONSHIPS, INC.",
        "value": "BOTY"
    },
    {
        "label": "ELRA - ELRAY RESOURCES, INC.",
        "value": "ELRA"
    },
    {
        "label": "SPRC - SciSparc Ltd.",
        "value": "SPRC"
    },
    {
        "label": "XESP - Electronic Servitor Publication Network Inc.",
        "value": "XESP"
    },
    {
        "label": "NBSE - NeuBase Therapeutics, Inc.",
        "value": "NBSE"
    },
    {
        "label": "STAL - Star Alliance International Corp.",
        "value": "STAL"
    },
    {
        "label": "FLXT - FLEXPOINT SENSOR SYSTEMS INC",
        "value": "FLXT"
    },
    {
        "label": "WOEN - WOLF ENERGY SERVICES INC.",
        "value": "WOEN"
    },
    {
        "label": "MJNE - MJ Holdings, Inc.",
        "value": "MJNE"
    },
    {
        "label": "RAYT - RAYONT INC.",
        "value": "RAYT"
    },
    {
        "label": "SCPS - Scopus BioPharma Inc.",
        "value": "SCPS"
    },
    {
        "label": "GMPW - GIVEMEPOWER CORP",
        "value": "GMPW"
    },
    {
        "label": "INQR - InnovaQor, Inc.",
        "value": "INQR"
    },
    {
        "label": "MXSG - Mexus Gold US",
        "value": "MXSG"
    },
    {
        "label": "BDRX - Biodexa Pharmaceuticals Plc",
        "value": "BDRX"
    },
    {
        "label": "ISGN - iSign Solutions Inc.",
        "value": "ISGN"
    },
    {
        "label": "MEXGF - Mexican Gold Mining Corp.",
        "value": "MEXGF"
    },
    {
        "label": "KATX - KAT EXPLORATION, INC.",
        "value": "KATX"
    },
    {
        "label": "GMZP - GEMZ Corp. NV",
        "value": "GMZP"
    },
    {
        "label": "NUMD - Nu-Med Plus, Inc.",
        "value": "NUMD"
    },
    {
        "label": "LBWR - Labwire Inc",
        "value": "LBWR"
    },
    {
        "label": "NTRR - NEUTRA CORP.",
        "value": "NTRR"
    },
    {
        "label": "COUV - CORPORATE UNIVERSE INC",
        "value": "COUV"
    },
    {
        "label": "GBUX - GIVBUX, INC.",
        "value": "GBUX"
    },
    {
        "label": "PAXH - PREAXIA HEALTH CARE PAYMENT SYSTEMS INC.",
        "value": "PAXH"
    },
    {
        "label": "ISCO - International Stem Cell CORP",
        "value": "ISCO"
    },
    {
        "label": "MTPP - MOUNTAIN TOP PROPERTIES, INC.",
        "value": "MTPP"
    },
    {
        "label": "WINSF - Wins Finance Holdings Inc.",
        "value": "WINSF"
    },
    {
        "label": "JPPYY - Jupai Holdings Ltd",
        "value": "JPPYY"
    },
    {
        "label": "ECGI - ECGI Holdings, Inc.",
        "value": "ECGI"
    },
    {
        "label": "BDPT - BIOADAPTIVES, INC.",
        "value": "BDPT"
    },
    {
        "label": "KAYS - Kaya Holdings, Inc.",
        "value": "KAYS"
    },
    {
        "label": "SATT - SATIVUS TECH CORP.",
        "value": "SATT"
    },
    {
        "label": "QPRC - QUEST PATENT RESEARCH CORP",
        "value": "QPRC"
    },
    {
        "label": "HHHEF - 37 CAPITAL INC",
        "value": "HHHEF"
    },
    {
        "label": "GRLF - GREEN LEAF INNOVATIONS INC",
        "value": "GRLF"
    },
    {
        "label": "GSPE - GULFSLOPE ENERGY, INC.",
        "value": "GSPE"
    },
    {
        "label": "CBDW - 1606 CORP.",
        "value": "CBDW"
    },
    {
        "label": "USNU - U.S. NeuroSurgical Holdings, Inc.",
        "value": "USNU"
    },
    {
        "label": "TRTK - TORtec Group Corp",
        "value": "TRTK"
    },
    {
        "label": "VFRM - Veritas Farms, Inc.",
        "value": "VFRM"
    },
    {
        "label": "STRG - STARGUIDE GROUP, INC.",
        "value": "STRG"
    },
    {
        "label": "WARM - COOL TECHNOLOGIES, INC.",
        "value": "WARM"
    },
    {
        "label": "LADX - LadRx Corp",
        "value": "LADX"
    },
    {
        "label": "MJHI - MJ Harvest, Inc.",
        "value": "MJHI"
    },
    {
        "label": "FRZT - Freeze Tag, Inc.",
        "value": "FRZT"
    },
    {
        "label": "VBHI - VERDE BIO HOLDINGS, INC.",
        "value": "VBHI"
    },
    {
        "label": "AREB - AMERICAN REBEL HOLDINGS INC",
        "value": "AREB"
    },
    {
        "label": "GTCH - GBT Technologies Inc.",
        "value": "GTCH"
    },
    {
        "label": "TRXA - T-REX Acquisition Corp.",
        "value": "TRXA"
    },
    {
        "label": "LAAB - Startech Labs, Inc.",
        "value": "LAAB"
    },
    {
        "label": "NAFS - North America Frac Sand, Inc.",
        "value": "NAFS"
    },
    {
        "label": "MNTR - Mentor Capital, Inc.",
        "value": "MNTR"
    },
    {
        "label": "AMNI - AMERICAN NOBLE GAS, INC.",
        "value": "AMNI"
    },
    {
        "label": "CBDS - Cannabis Sativa, Inc.",
        "value": "CBDS"
    },
    {
        "label": "DUUO - DUO WORLD INC",
        "value": "DUUO"
    },
    {
        "label": "HWKE - Hawkeye Systems, Inc.",
        "value": "HWKE"
    },
    {
        "label": "MGHL - MORGAN GROUP HOLDING CO",
        "value": "MGHL"
    },
    {
        "label": "CMGR - Clubhouse Media Group, Inc.",
        "value": "CMGR"
    },
    {
        "label": "GMBL - ESPORTS ENTERTAINMENT GROUP, INC.",
        "value": "GMBL"
    },
    {
        "label": "NVDEF - NEVADA EXPLORATION INC",
        "value": "NVDEF"
    },
    {
        "label": "MTLK - METALINK LTD",
        "value": "MTLK"
    },
    {
        "label": "HNOI - HNO International, Inc.",
        "value": "HNOI"
    },
    {
        "label": "BTDG - B2Digital, Inc.",
        "value": "BTDG"
    },
    {
        "label": "JKSM - Jacksam Corp",
        "value": "JKSM"
    },
    {
        "label": "IGEN - IGEN NETWORKS CORP",
        "value": "IGEN"
    },
    {
        "label": "NIHK - Video River Networks, Inc.",
        "value": "NIHK"
    },
    {
        "label": "HTGMQ - HTG MOLECULAR DIAGNOSTICS, INC",
        "value": "HTGMQ"
    },
    {
        "label": "WEBB - Web Blockchain Media, Inc.",
        "value": "WEBB"
    },
    {
        "label": "BLPG - Blue Line Protection Group, Inc.",
        "value": "BLPG"
    },
    {
        "label": "TMGI - Marquie Group, Inc.",
        "value": "TMGI"
    },
    {
        "label": "KSPN - Kaspien Holdings Inc.",
        "value": "KSPN"
    },
    {
        "label": "AFHIF - Atlas Financial Holdings, Inc.",
        "value": "AFHIF"
    },
    {
        "label": "DLYT - DAIS Corp",
        "value": "DLYT"
    },
    {
        "label": "SGBI - SANGUI BIOTECH INTERNATIONAL INC",
        "value": "SGBI"
    },
    {
        "label": "REOS - ReoStar Energy CORP",
        "value": "REOS"
    },
    {
        "label": "ETCK - ENERTECK CORP",
        "value": "ETCK"
    },
    {
        "label": "USAQ - QHSLab, Inc.",
        "value": "USAQ"
    },
    {
        "label": "BLIS - Treasure & Shipwreck Recovery, Inc.",
        "value": "BLIS"
    },
    {
        "label": "RNWR - 808 RENEWABLE ENERGY CORP",
        "value": "RNWR"
    },
    {
        "label": "ONEI - OneMeta Inc.",
        "value": "ONEI"
    },
    {
        "label": "MMEX - MMEX Resources Corp",
        "value": "MMEX"
    },
    {
        "label": "HADV - HEALTH ADVANCE INC.",
        "value": "HADV"
    },
    {
        "label": "BFYW - Better For You Wellness, Inc.",
        "value": "BFYW"
    },
    {
        "label": "CMGO - CMG HOLDINGS GROUP, INC.",
        "value": "CMGO"
    },
    {
        "label": "TRLFF - Maven Brands Inc.",
        "value": "TRLFF"
    },
    {
        "label": "GNOLF - GENOIL INC",
        "value": "GNOLF"
    },
    {
        "label": "IMCI - INFINITE GROUP INC",
        "value": "IMCI"
    },
    {
        "label": "TAMG - Transnational Group, Inc.",
        "value": "TAMG"
    },
    {
        "label": "PLYN - Palayan Resources, Inc.",
        "value": "PLYN"
    },
    {
        "label": "SSHT - SSHT S&T Group Ltd.",
        "value": "SSHT"
    },
    {
        "label": "ITOX - IIOT-OXYS, Inc.",
        "value": "ITOX"
    },
    {
        "label": "TEVNF - Tevano Systems Holdings Inc.",
        "value": "TEVNF"
    },
    {
        "label": "RAHGF - Roan Holdings Group Co., Ltd.",
        "value": "RAHGF"
    },
    {
        "label": "MRNJ - METATRON APPS, INC.",
        "value": "MRNJ"
    },
    {
        "label": "ZICX - Zicix Corp",
        "value": "ZICX"
    },
    {
        "label": "AOXY - ADVANCED OXYGEN TECHNOLOGIES INC",
        "value": "AOXY"
    },
    {
        "label": "CANB - Can B Corp",
        "value": "CANB"
    },
    {
        "label": "FRTG - FRONTERA GROUP INC.",
        "value": "FRTG"
    },
    {
        "label": "MYCB - My City Builders, Inc.",
        "value": "MYCB"
    },
    {
        "label": "EQOSQ - EQONEX Ltd",
        "value": "EQOSQ"
    },
    {
        "label": "RDGA - RIDGEFIELD ACQUISITION CORP",
        "value": "RDGA"
    },
    {
        "label": "NSPDF - NATURALLY SPLENDID ENTERPRISES LTD.",
        "value": "NSPDF"
    },
    {
        "label": "YBCN - Yong Bai Chao New Retail Corp",
        "value": "YBCN"
    },
    {
        "label": "PSWW - Principal Solar, Inc.",
        "value": "PSWW"
    },
    {
        "label": "TQLB - Torque Lifestyle Brands, Inc.",
        "value": "TQLB"
    },
    {
        "label": "DCLT - Data Call Technologies",
        "value": "DCLT"
    },
    {
        "label": "ARRT - Artisan Consumer Goods, Inc.",
        "value": "ARRT"
    },
    {
        "label": "NNAX - New Momentum Corp.",
        "value": "NNAX"
    },
    {
        "label": "WWSG - WORLDWIDE STRATEGIES INC",
        "value": "WWSG"
    },
    {
        "label": "ROAG - Rogue One, Inc.",
        "value": "ROAG"
    },
    {
        "label": "OMTK - Omnitek Engineering Corp",
        "value": "OMTK"
    },
    {
        "label": "AMIH - AMERICAN INTERNATIONAL HOLDINGS CORP.",
        "value": "AMIH"
    },
    {
        "label": "NICH - NITCHES INC",
        "value": "NICH"
    },
    {
        "label": "GCAN - Greater Cannabis Company, Inc.",
        "value": "GCAN"
    },
    {
        "label": "SSET - STARSTREAM ENTERTAINMENT, INC.",
        "value": "SSET"
    },
    {
        "label": "SHGI - Sparx Holdings Group, Inc.",
        "value": "SHGI"
    },
    {
        "label": "GSFI - Green Stream Holdings Inc.",
        "value": "GSFI"
    },
    {
        "label": "EARI - ENTERTAINMENT ARTS RESEARCH, INC.",
        "value": "EARI"
    },
    {
        "label": "RINO - JOIN Entertainment Holdings, Inc.",
        "value": "RINO"
    },
    {
        "label": "SANP - SANTO MINING CORP.",
        "value": "SANP"
    },
    {
        "label": "CYDX - CYduct Diagnostics, Inc.",
        "value": "CYDX"
    },
    {
        "label": "HCMC - Healthier Choices Management Corp.",
        "value": "HCMC"
    },
    {
        "label": "ABMC - AMERICAN BIO MEDICA CORP",
        "value": "ABMC"
    },
    {
        "label": "QBIO - Q BioMed Inc.",
        "value": "QBIO"
    },
    {
        "label": "CTKYY - CooTek(Cayman)Inc.",
        "value": "CTKYY"
    },
    {
        "label": "XCRT - Xcelerate, Inc.",
        "value": "XCRT"
    },
    {
        "label": "PHBI - Pharmagreen Biotech Inc.",
        "value": "PHBI"
    },
    {
        "label": "GFMH - Goliath Film & Media Holdings",
        "value": "GFMH"
    },
    {
        "label": "PACV - Pacific Ventures Group, Inc.",
        "value": "PACV"
    },
    {
        "label": "TRMNF - NEW WAVE HOLDINGS CORP.",
        "value": "TRMNF"
    },
    {
        "label": "QNGYQ - Quanergy Systems, Inc.",
        "value": "QNGYQ"
    },
    {
        "label": "JFIL - Jubilant Flame International, Ltd",
        "value": "JFIL"
    },
    {
        "label": "EHVVF - Ehave, Inc.",
        "value": "EHVVF"
    },
    {
        "label": "SKVI - SKINVISIBLE, INC.",
        "value": "SKVI"
    },
    {
        "label": "IVRN - Innoveren Scientific, Inc.",
        "value": "IVRN"
    },
    {
        "label": "VMHG - VICTORY MARINE HOLDINGS CORP",
        "value": "VMHG"
    },
    {
        "label": "EVFM - Evofem Biosciences, Inc.",
        "value": "EVFM"
    },
    {
        "label": "OWVI - One World Ventures Inc",
        "value": "OWVI"
    },
    {
        "label": "BRBL - BrewBilt Brewing Co",
        "value": "BRBL"
    },
    {
        "label": "AURI - AURI INC",
        "value": "AURI"
    },
    {
        "label": "TPIA - Mycotopia Therapies, Inc.",
        "value": "TPIA"
    },
    {
        "label": "GNRS - Greenrose Holding Co Inc.",
        "value": "GNRS"
    },
    {
        "label": "BCNN - Balincan USA, Inc",
        "value": "BCNN"
    },
    {
        "label": "ASII - Accredited Solutions, Inc.",
        "value": "ASII"
    },
    {
        "label": "GEGI - Genesis Electronics Group, Inc.",
        "value": "GEGI"
    },
    {
        "label": "AMLH - AMERICAN LEISURE HOLDINGS, INC.",
        "value": "AMLH"
    },
    {
        "label": "NHMD - NATE'S FOOD CO.",
        "value": "NHMD"
    },
    {
        "label": "RBSH - Rebus Holdings, Inc.",
        "value": "RBSH"
    },
    {
        "label": "KDCE - KID CASTLE EDUCATIONAL CORP",
        "value": "KDCE"
    },
    {
        "label": "RTSL - Rapid Therapeutic Science Laboratories, Inc.",
        "value": "RTSL"
    },
    {
        "label": "RGIN - Regenicin, Inc.",
        "value": "RGIN"
    },
    {
        "label": "MDEX - Madison Technologies Inc.",
        "value": "MDEX"
    },
    {
        "label": "MLFB - MAJOR LEAGUE FOOTBALL INC",
        "value": "MLFB"
    },
    {
        "label": "IMBIQ - iMedia Brands, Inc.",
        "value": "IMBIQ"
    },
    {
        "label": "WDDD - WORLDS INC",
        "value": "WDDD"
    },
    {
        "label": "IINX - IONIX TECHNOLOGY, INC.",
        "value": "IINX"
    },
    {
        "label": "EMED - Electromedical Technologies, Inc",
        "value": "EMED"
    },
    {
        "label": "UVSS - UNIVERSAL SYSTEMS INC",
        "value": "UVSS"
    },
    {
        "label": "FORZ - Forza Innovations Inc",
        "value": "FORZ"
    },
    {
        "label": "CNBX - CNBX Pharmaceuticals Inc.",
        "value": "CNBX"
    },
    {
        "label": "SQZB - SQZ Biotechnologies Co",
        "value": "SQZB"
    },
    {
        "label": "CDXQ - China De Xiao Quan Care Group Co., Ltd",
        "value": "CDXQ"
    },
    {
        "label": "GYST - GRAYSTONE COMPANY, INC.",
        "value": "GYST"
    },
    {
        "label": "WLMSQ - Williams Industrial Services Group Inc.",
        "value": "WLMSQ"
    },
    {
        "label": "VEST - Vestiage, Inc.",
        "value": "VEST"
    },
    {
        "label": "RAKR - Rainmaker Worldwide Inc.",
        "value": "RAKR"
    },
    {
        "label": "BOTH - BIOETHICS LTD",
        "value": "BOTH"
    },
    {
        "label": "ABQQ - AB INTERNATIONAL GROUP CORP.",
        "value": "ABQQ"
    },
    {
        "label": "PRRY - Planet Resource Recovery, Inc.",
        "value": "PRRY"
    },
    {
        "label": "NUVI - Emo Capital Corp.",
        "value": "NUVI"
    },
    {
        "label": "GLAE - GlassBridge Enterprises, Inc.",
        "value": "GLAE"
    },
    {
        "label": "MCCX - MCX Technologies Corp",
        "value": "MCCX"
    },
    {
        "label": "TPII - Triad Pro Innovators, Inc.",
        "value": "TPII"
    },
    {
        "label": "PTAM - POTASH AMERICA, INC.",
        "value": "PTAM"
    },
    {
        "label": "HLLK - HALLMARK VENTURE GROUP, INC.",
        "value": "HLLK"
    },
    {
        "label": "SGLA - Sino Green Land Corp.",
        "value": "SGLA"
    },
    {
        "label": "BISA - BALTIC INTERNATIONAL USA INC",
        "value": "BISA"
    },
    {
        "label": "AFIIQ - Armstrong Flooring, Inc.",
        "value": "AFIIQ"
    },
    {
        "label": "MSYN - MS YOUNG ADVENTURE ENTERPRISE, INC.",
        "value": "MSYN"
    },
    {
        "label": "GTHR - GENETHERA INC",
        "value": "GTHR"
    },
    {
        "label": "CSSI - COSTAS INC",
        "value": "CSSI"
    },
    {
        "label": "RSPI - RespireRx Pharmaceuticals Inc.",
        "value": "RSPI"
    },
    {
        "label": "CONC - CONECTISYS CORP",
        "value": "CONC"
    },
    {
        "label": "PLTYF - Plastec Technologies, Ltd.",
        "value": "PLTYF"
    },
    {
        "label": "SNMN - SNM Global Holdings",
        "value": "SNMN"
    },
    {
        "label": "MAPT - MAPTELLIGENT, INC.",
        "value": "MAPT"
    },
    {
        "label": "PARG - Power Americas Resource Group Ltd.",
        "value": "PARG"
    },
    {
        "label": "VIZC - VIZCONNECT, INC.",
        "value": "VIZC"
    },
    {
        "label": "CDIX - Cardiff Lexington Corp",
        "value": "CDIX"
    },
    {
        "label": "VHLD - VECTOR 21 HOLDINGS, INC.",
        "value": "VHLD"
    },
    {
        "label": "MBGH - MBG Holdings, Inc.",
        "value": "MBGH"
    },
    {
        "label": "ATDS - Data443 Risk Mitigation, Inc.",
        "value": "ATDS"
    },
    {
        "label": "UPDC - UPD HOLDING CORP.",
        "value": "UPDC"
    },
    {
        "label": "QREE - QUANTUM ENERGY INC.",
        "value": "QREE"
    },
    {
        "label": "BLMS - BLOOMIOS, INC.",
        "value": "BLMS"
    },
    {
        "label": "MASN - Maison Luxe, Inc.",
        "value": "MASN"
    },
    {
        "label": "CYAP - Cyber Apps World",
        "value": "CYAP"
    },
    {
        "label": "SCRH - SCORES HOLDING CO INC",
        "value": "SCRH"
    },
    {
        "label": "PPCB - Propanc Biopharma, Inc.",
        "value": "PPCB"
    },
    {
        "label": "MSSV - MESO NUMISMATICS, INC.",
        "value": "MSSV"
    },
    {
        "label": "GAHC - Global Arena Holding, Inc.",
        "value": "GAHC"
    },
    {
        "label": "GXXM - GEX MANAGEMENT, INC.",
        "value": "GXXM"
    },
    {
        "label": "HMLA - HIMALAYA TECHNOLOGIES, INC",
        "value": "HMLA"
    },
    {
        "label": "PMPG - Premier Product Group, Inc.",
        "value": "PMPG"
    },
    {
        "label": "LIVC - Live Current Media Inc.",
        "value": "LIVC"
    },
    {
        "label": "PHOT - GROWLIFE, INC.",
        "value": "PHOT"
    },
    {
        "label": "JPEX - JPX Global Inc.",
        "value": "JPEX"
    },
    {
        "label": "SRGAQ - SURGALIGN HOLDINGS, INC.",
        "value": "SRGAQ"
    },
    {
        "label": "BBLNF - Babylon Holdings Ltd",
        "value": "BBLNF"
    },
    {
        "label": "BEGI - BLACKSTAR ENTERPRISE GROUP, INC.",
        "value": "BEGI"
    },
    {
        "label": "ONCSQ - ONCOSEC MEDICAL Inc",
        "value": "ONCSQ"
    },
    {
        "label": "FLES - Auto Parts 4Less Group, Inc.",
        "value": "FLES"
    },
    {
        "label": "USRM - U.S. Stem Cell, Inc.",
        "value": "USRM"
    },
    {
        "label": "QTXB - QUANTRX BIOMEDICAL CORP",
        "value": "QTXB"
    },
    {
        "label": "EMDF - E Med Future, Inc.",
        "value": "EMDF"
    },
    {
        "label": "LFAP - LGBTQ Loyalty Holdings, Inc.",
        "value": "LFAP"
    },
    {
        "label": "SAML - Samsara Luggage, Inc.",
        "value": "SAML"
    },
    {
        "label": "XFCI - XTREME FIGHTING CHAMPIONSHIPS, INC.",
        "value": "XFCI"
    },
    {
        "label": "DZGH - Da Zhong Trading Group Holding Co",
        "value": "DZGH"
    },
    {
        "label": "THCT - THC Therapeutics, Inc.",
        "value": "THCT"
    },
    {
        "label": "SPOWF - Strata Power Corp",
        "value": "SPOWF"
    },
    {
        "label": "WINR - SIMPLICITY ESPORTS & GAMING Co",
        "value": "WINR"
    },
    {
        "label": "VISM - VISIUM TECHNOLOGIES, INC.",
        "value": "VISM"
    },
    {
        "label": "LFEV - Life Electric Vehicles Holdings, Inc.",
        "value": "LFEV"
    },
    {
        "label": "AHIX - ALUF HOLDINGS, INC.",
        "value": "AHIX"
    },
    {
        "label": "GRMC - GOLDRICH MINING CO",
        "value": "GRMC"
    },
    {
        "label": "CLCS - Cell Source, Inc.",
        "value": "CLCS"
    },
    {
        "label": "PCNT - Point of Care Nano-Technology, Inc.",
        "value": "PCNT"
    },
    {
        "label": "SNWR - Sanwire Corp",
        "value": "SNWR"
    },
    {
        "label": "YCRM - Yuenglings Ice Cream Corp",
        "value": "YCRM"
    },
    {
        "label": "CIRX - CIRTRAN CORP",
        "value": "CIRX"
    },
    {
        "label": "VGFCQ - Very Good Food Co Inc.",
        "value": "VGFCQ"
    },
    {
        "label": "SEII - SHARING ECONOMY INTERNATIONAL INC.",
        "value": "SEII"
    },
    {
        "label": "SCGX - SAXON CAPITAL GROUP INC",
        "value": "SCGX"
    },
    {
        "label": "CWNOF - CHINESEWORLDNET COM INC",
        "value": "CWNOF"
    },
    {
        "label": "AMNL - Applied Minerals, Inc.",
        "value": "AMNL"
    },
    {
        "label": "SUWN - SUNWIN STEVIA INTERNATIONAL, INC.",
        "value": "SUWN"
    },
    {
        "label": "HSTA - Hestia Insight Inc.",
        "value": "HSTA"
    },
    {
        "label": "DLCR - Kibush Capital Corp",
        "value": "DLCR"
    },
    {
        "label": "ATRX - Adhera Therapeutics, Inc.",
        "value": "ATRX"
    },
    {
        "label": "YGYI - Youngevity International, Inc.",
        "value": "YGYI"
    },
    {
        "label": "GTRL - Get Real USA, Inc.",
        "value": "GTRL"
    },
    {
        "label": "WTKN - CLStv Corp.",
        "value": "WTKN"
    },
    {
        "label": "EWLU - Merion, Inc.",
        "value": "EWLU"
    },
    {
        "label": "ASCK - AUSCRETE Corp",
        "value": "ASCK"
    },
    {
        "label": "BBBT - Black Bird Biotech, Inc.",
        "value": "BBBT"
    },
    {
        "label": "STCC - STERLING CONSOLIDATED Corp",
        "value": "STCC"
    },
    {
        "label": "GZIC - GZ6G Technologies Corp.",
        "value": "GZIC"
    },
    {
        "label": "BZRD - Blubuzzard, Inc.",
        "value": "BZRD"
    },
    {
        "label": "CDAKQ - Codiak BioSciences, Inc.",
        "value": "CDAKQ"
    },
    {
        "label": "RDAR - RAADR, INC.",
        "value": "RDAR"
    },
    {
        "label": "HSMD - Healthcare Solutions Management Group, Inc.",
        "value": "HSMD"
    },
    {
        "label": "CPWR - Ocean Thermal Energy Corp",
        "value": "CPWR"
    },
    {
        "label": "VTXB - Vortex Brands Co.",
        "value": "VTXB"
    },
    {
        "label": "ATNXQ - Athenex, Inc.",
        "value": "ATNXQ"
    },
    {
        "label": "SOLS - SOLLENSYS CORP.",
        "value": "SOLS"
    },
    {
        "label": "UNAM - UNICO AMERICAN CORP",
        "value": "UNAM"
    },
    {
        "label": "NLBS - NUTRALIFE BIOSCIENCES, INC",
        "value": "NLBS"
    },
    {
        "label": "STRYQ - Starry Group Holdings, Inc.",
        "value": "STRYQ"
    },
    {
        "label": "CRYO - American CryoStem Corp",
        "value": "CRYO"
    },
    {
        "label": "XCPL - XCPCNL Business Services Corp",
        "value": "XCPL"
    },
    {
        "label": "IFMK - iFresh Inc",
        "value": "IFMK"
    },
    {
        "label": "HDVY - HEALTH DISCOVERY CORP",
        "value": "HDVY"
    },
    {
        "label": "PGAS - PETROGRESS, INC",
        "value": "PGAS"
    },
    {
        "label": "CSTF - CuraScientific Corp.",
        "value": "CSTF"
    },
    {
        "label": "LBTI - Lithium & Boron Technology, Inc.",
        "value": "LBTI"
    },
    {
        "label": "CBDL - CBD Life Sciences Inc.",
        "value": "CBDL"
    },
    {
        "label": "CRCW - Crypto Co",
        "value": "CRCW"
    },
    {
        "label": "RNVA - Rennova Health, Inc.",
        "value": "RNVA"
    },
    {
        "label": "DNAX - DNA BRANDS INC",
        "value": "DNAX"
    },
    {
        "label": "HJGP - Hanjiao Group, Inc.",
        "value": "HJGP"
    },
    {
        "label": "NNRX - NUTRANOMICS, INC.",
        "value": "NNRX"
    },
    {
        "label": "PEARQ - Pear Therapeutics, Inc.",
        "value": "PEARQ"
    },
    {
        "label": "ACUR - ACURA PHARMACEUTICALS, INC",
        "value": "ACUR"
    },
    {
        "label": "DPWW - DIEGO PELLICER WORLDWIDE, INC",
        "value": "DPWW"
    },
    {
        "label": "TWOH - Two Hands Corp",
        "value": "TWOH"
    },
    {
        "label": "BBRW - BrewBilt Manufacturing Inc.",
        "value": "BBRW"
    },
    {
        "label": "LFER - Life On Earth, Inc.",
        "value": "LFER"
    },
    {
        "label": "STAB - Statera Biopharma, Inc.",
        "value": "STAB"
    },
    {
        "label": "SPUP - Sipup Corp",
        "value": "SPUP"
    },
    {
        "label": "AQUI - Aquarius I Acquisition Corp.",
        "value": "AQUI"
    },
    {
        "label": "DTII - DEFENSE TECHNOLOGIES INTERNATIONAL CORP.",
        "value": "DTII"
    },
    {
        "label": "TRFE - Trustfeed Corp.",
        "value": "TRFE"
    },
    {
        "label": "FNHCQ - FedNat Holding Co",
        "value": "FNHCQ"
    },
    {
        "label": "GAXY - GALAXY NEXT GENERATION, INC.",
        "value": "GAXY"
    },
    {
        "label": "SLDC - Telco Cuba, Inc.",
        "value": "SLDC"
    },
    {
        "label": "PHASQ - PhaseBio Pharmaceuticals Inc",
        "value": "PHASQ"
    },
    {
        "label": "CGSI - CGS INTERNATIONAL, INC.",
        "value": "CGSI"
    },
    {
        "label": "CBIA - Blue Heaven Coffee, Inc.",
        "value": "CBIA"
    },
    {
        "label": "GWHP - Global Wholehealth Partners Corp",
        "value": "GWHP"
    },
    {
        "label": "CHGI - China Carbon Graphite Group, Inc.",
        "value": "CHGI"
    },
    {
        "label": "PDNLA - PRESIDENTIAL REALTY CORP/DE/",
        "value": "PDNLA"
    },
    {
        "label": "ALNAQ - Allena Pharmaceuticals, Inc.",
        "value": "ALNAQ"
    },
    {
        "label": "BTIM - BOATIM INC.",
        "value": "BTIM"
    },
    {
        "label": "TDNT - Trident Brands Inc",
        "value": "TDNT"
    },
    {
        "label": "CCOB - Century Cobalt Corp.",
        "value": "CCOB"
    },
    {
        "label": "FRFR - Fritzy Tech Inc.",
        "value": "FRFR"
    },
    {
        "label": "NNMX - NANOMIX Corp",
        "value": "NNMX"
    },
    {
        "label": "BMXC - Bemax, Inc.",
        "value": "BMXC"
    },
    {
        "label": "VYST - Vystar Corp",
        "value": "VYST"
    },
    {
        "label": "FBCD - FBC Holding, Inc.",
        "value": "FBCD"
    },
    {
        "label": "BRGO - Bergio International, Inc.",
        "value": "BRGO"
    },
    {
        "label": "SFIO - Starfleet Innotech, Inc.",
        "value": "SFIO"
    },
    {
        "label": "LHDXQ - Lucira Health, Inc.",
        "value": "LHDXQ"
    },
    {
        "label": "MAXD - Max Sound Corp",
        "value": "MAXD"
    },
    {
        "label": "ONCI - ON4 COMMUNICATIONS INC.",
        "value": "ONCI"
    },
    {
        "label": "NPHC - NUTRA PHARMA CORP",
        "value": "NPHC"
    },
    {
        "label": "BOXDQ - Boxed, Inc.",
        "value": "BOXDQ"
    },
    {
        "label": "GWSN - Gulf West Security Network, Inc.",
        "value": "GWSN"
    },
    {
        "label": "AMHG - Amergent Hospitality Group Inc.",
        "value": "AMHG"
    },
    {
        "label": "BLFE - BioLIfe Sciences Inc",
        "value": "BLFE"
    },
    {
        "label": "PLXPQ - PLx Pharma Winddown Corp.",
        "value": "PLXPQ"
    },
    {
        "label": "NECA - NEW AMERICA ENERGY CORP.",
        "value": "NECA"
    },
    {
        "label": "ANSU - AMANASU TECHNO HOLDINGS CORP",
        "value": "ANSU"
    },
    {
        "label": "SDON - SANDSTON CORP",
        "value": "SDON"
    },
    {
        "label": "FERN - Fernhill Corp",
        "value": "FERN"
    },
    {
        "label": "HYREQ - HC LIQUIDATING, INC.",
        "value": "HYREQ"
    },
    {
        "label": "GMEV - GME INNOTAINMENT, INC.",
        "value": "GMEV"
    },
    {
        "label": "ATTOF - Atento S.A.",
        "value": "ATTOF"
    },
    {
        "label": "WEIDY - Weidai Ltd.",
        "value": "WEIDY"
    },
    {
        "label": "KEGS - 1812 Brewing Company, Inc.",
        "value": "KEGS"
    },
    {
        "label": "RTON - Right On Brands, Inc.",
        "value": "RTON"
    },
    {
        "label": "CGAC - CODE GREEN APPAREL CORP",
        "value": "CGAC"
    },
    {
        "label": "BNCM - BOUNCE MOBILE SYSTEMS, INC.",
        "value": "BNCM"
    },
    {
        "label": "TUEMQ - TUESDAY MORNING CORP/DE",
        "value": "TUEMQ"
    },
    {
        "label": "ALFIQ - Alfi, Inc.",
        "value": "ALFIQ"
    },
    {
        "label": "MKDTY - Molecular Data Inc.",
        "value": "MKDTY"
    },
    {
        "label": "PGAI - PGI INC",
        "value": "PGAI"
    },
    {
        "label": "PRCX - Phoenix Rising Companies",
        "value": "PRCX"
    },
    {
        "label": "GTOR - GGTOOR, INC.",
        "value": "GTOR"
    },
    {
        "label": "MUSS - MULTI SOLUTIONS II, INC",
        "value": "MUSS"
    },
    {
        "label": "GPLDF - GREAT PANTHER MINING Ltd",
        "value": "GPLDF"
    },
    {
        "label": "VTNA - VetaNova Inc.",
        "value": "VTNA"
    },
    {
        "label": "KALRQ - Kalera Public Ltd Co",
        "value": "KALRQ"
    },
    {
        "label": "CLIS - ClickStream Corp",
        "value": "CLIS"
    },
    {
        "label": "CPMD - CANNAPHARMARX, INC.",
        "value": "CPMD"
    },
    {
        "label": "HBIS - Home Bistro, Inc. /NV/",
        "value": "HBIS"
    },
    {
        "label": "YUKA - Yuka Group Inc",
        "value": "YUKA"
    },
    {
        "label": "AFOM - ALL FOR ONE MEDIA CORP.",
        "value": "AFOM"
    },
    {
        "label": "CMCZ - Curtis Mathes Corp",
        "value": "CMCZ"
    },
    {
        "label": "ICGL - Image Chain Group Limited, Inc.",
        "value": "ICGL"
    },
    {
        "label": "SAG - SAG Holdings Ltd",
        "value": "SAG"
    },
    {
        "label": "IPB - MERRILL LYNCH DEPOSITOR INC INDEXPLUS TRUST SERIES 2003-1",
        "value": "IPB"
    },
    {
        "label": "GJO - STRATS SM TRUST FOR WAL-MART STORES, INC. SECURITIES, SERIES 2005-4",
        "value": "GJO"
    },
    {
        "label": "PSGI - Perfect Solutions Group, Inc.",
        "value": "PSGI"
    },
    {
        "label": "CEAC - CE Energy Acquisition Corp.",
        "value": "CEAC"
    },
    {
        "label": "FLCT - Felicitex Therapeutics Inc.",
        "value": "FLCT"
    },
    {
        "label": "KPN - Kepuni Holdings Inc.",
        "value": "KPN"
    },
    {
        "label": "BCGF - BCGF Acquisition Corp.",
        "value": "BCGF"
    },
    {
        "label": "CJOY - Cine Top Culture Holdings Ltd.",
        "value": "CJOY"
    },
    {
        "label": "GLIV - Grayscale Livepeer Trust (LPT)",
        "value": "GLIV"
    },
    {
        "label": "GBAT - Grayscale Basic Attention Token Trust (BAT)",
        "value": "GBAT"
    },
    {
        "label": "YBZN - Yi Po International Holdings Ltd",
        "value": "YBZN"
    },
    {
        "label": "AFJK - Aimei Health Technology Co., Ltd.",
        "value": "AFJK"
    },
    {
        "label": "FMST - Foremost Lithium Resource & Technology Ltd.",
        "value": "FMST"
    },
    {
        "label": "SUBL - BioLingus (Cayman) Ltd",
        "value": "SUBL"
    },
    {
        "label": "CEDAX - BlueBay Destra International Event-Driven Credit Fund",
        "value": "CEDAX"
    },
    {
        "label": "SPKL - Spark I Acquisition Corp",
        "value": "SPKL"
    },
    {
        "label": "VCMIX - Versus Capital Multi-Manager Real Estate Income Fund LLC",
        "value": "VCMIX"
    },
    {
        "label": "GFR - Greenfire Resources Ltd.",
        "value": "GFR"
    },
    {
        "label": "GLDM - World Gold Trust",
        "value": "GLDM"
    },
    {
        "label": "KARX - Karbon-X Corp.",
        "value": "KARX"
    },
    {
        "label": "BITW - Bitwise 10 Crypto Index Fund",
        "value": "BITW"
    },
    {
        "label": "LGCP - Legion Capital Corp",
        "value": "LGCP"
    },
    {
        "label": "TIPLX - Bluerock Total Income (plus) Real Estate Fund",
        "value": "TIPLX"
    },
    {
        "label": "GEMZ - Gemxx Corp.",
        "value": "GEMZ"
    },
    {
        "label": "ICR-PA - InPoint Commercial Real Estate Income, Inc.",
        "value": "ICR-PA"
    },
    {
        "label": "ACIC - AMERICAN COASTAL INSURANCE Corp",
        "value": "ACIC"
    },
    {
        "label": "ACRU - AmeriCrew Inc.",
        "value": "ACRU"
    },
    {
        "label": "WEFCX - Wildermuth Fund",
        "value": "WEFCX"
    },
    {
        "label": "NBBK - NB Bancorp, Inc.",
        "value": "NBBK"
    },
    {
        "label": "CNROX - City National Rochdale Strategic Credit Fund",
        "value": "CNROX"
    },
    {
        "label": "GTTJ - GTJ REIT, INC.",
        "value": "GTTJ"
    },
    {
        "label": "ADOB - Soul Biotechnology Corp",
        "value": "ADOB"
    },
    {
        "label": "ULY - Urgent.ly Inc.",
        "value": "ULY"
    },
    {
        "label": "ETCG - Grayscale Ethereum Classic Trust (ETC)",
        "value": "ETCG"
    },
    {
        "label": "HGIA - HENGGUANG HOLDING CO, Ltd",
        "value": "HGIA"
    },
    {
        "label": "PLTM - GraniteShares Platinum Trust",
        "value": "PLTM"
    },
    {
        "label": "JDRR - F3 Platform Biologics INC",
        "value": "JDRR"
    },
    {
        "label": "GLE - Global Engine Group Holding Ltd",
        "value": "GLE"
    },
    {
        "label": "TRCC - TRACCOM INC.",
        "value": "TRCC"
    },
    {
        "label": "APHP - American Picture House Corp",
        "value": "APHP"
    },
    {
        "label": "NCNC - Prime Number Holding Ltd",
        "value": "NCNC"
    },
    {
        "label": "LARAX - Lord Abbett Credit Opportunities Fund",
        "value": "LARAX"
    },
    {
        "label": "INTM - INTERMEDIA CLOUD COMMUNICATIONS, INC.",
        "value": "INTM"
    },
    {
        "label": "MNGA - MNG Havayollari ve Tasimacilik A.S.",
        "value": "MNGA"
    },
    {
        "label": "ASPP - Abri SPAC 2, Inc.",
        "value": "ASPP"
    },
    {
        "label": "RLND - RoyaLand Co Ltd.",
        "value": "RLND"
    },
    {
        "label": "ABLV - Able View Global Inc.",
        "value": "ABLV"
    },
    {
        "label": "VLTO - Veralto Corp",
        "value": "VLTO"
    },
    {
        "label": "XALCX - BNY Mellon Alcentra Global Multi-Strategy Credit Fund, Inc.",
        "value": "XALCX"
    },
    {
        "label": "RVGO - RVELOCITY, INC.",
        "value": "RVGO"
    },
    {
        "label": "CCTG - CCSC Technology International Holdings Ltd",
        "value": "CCTG"
    },
    {
        "label": "ZKH - ZKH Group Ltd",
        "value": "ZKH"
    },
    {
        "label": "OPHV - Opti-Harvest, Inc.",
        "value": "OPHV"
    },
    {
        "label": "SKFG - Stark Focus Group, Inc.",
        "value": "SKFG"
    },
    {
        "label": "FSHP - Flag Ship Acquisition Corp",
        "value": "FSHP"
    },
    {
        "label": "FBGL - FBS Global Ltd",
        "value": "FBGL"
    },
    {
        "label": "ESGL - ESGL Holdings Ltd",
        "value": "ESGL"
    },
    {
        "label": "BELR - Bell Rose Capital, Inc.",
        "value": "BELR"
    },
    {
        "label": "RENB - RENOVARO BIOSCIENCES INC.",
        "value": "RENB"
    },
    {
        "label": "FXF - Invesco CurrencyShares Swiss Franc Trust",
        "value": "FXF"
    },
    {
        "label": "CBIH - Cannabis Bioscience International Holdings, Inc.",
        "value": "CBIH"
    },
    {
        "label": "SAAYY - SAIPEM S P A /FI",
        "value": "SAAYY"
    },
    {
        "label": "CNCL - CANCER CAPITAL CORP",
        "value": "CNCL"
    },
    {
        "label": "PMEC - Primech Holdings Ltd",
        "value": "PMEC"
    },
    {
        "label": "RR - RICHTECH ROBOTICS INC.",
        "value": "RR"
    },
    {
        "label": "FOGO - Fogo Hospitality, Inc.",
        "value": "FOGO"
    },
    {
        "label": "GSGG - GSG GROUP INC.",
        "value": "GSGG"
    },
    {
        "label": "INHD - INNO HOLDINGS INC.",
        "value": "INHD"
    },
    {
        "label": "QTM - CH AUTO Inc.",
        "value": "QTM"
    },
    {
        "label": "PFAB - PreTam Holdings Inc.",
        "value": "PFAB"
    },
    {
        "label": "YMAT - J-Star Holding Co., Ltd.",
        "value": "YMAT"
    },
    {
        "label": "BCG - Binah Capital Group, Inc.",
        "value": "BCG"
    },
    {
        "label": "SVMH - SRIVARU Holding Ltd",
        "value": "SVMH"
    },
    {
        "label": "SDOT - Sadot Group Inc.",
        "value": "SDOT"
    },
    {
        "label": "RPGL - Republic Power Group Ltd",
        "value": "RPGL"
    },
    {
        "label": "HIGR - Hi-Great Group Holding Co",
        "value": "HIGR"
    },
    {
        "label": "SWGC - Stillwater Growth Corp. I",
        "value": "SWGC"
    },
    {
        "label": "PSOIX - Palmer Square Opportunistic Income Fund",
        "value": "PSOIX"
    },
    {
        "label": "ROMA - Roma Green Finance Ltd",
        "value": "ROMA"
    },
    {
        "label": "SIVR - abrdn Silver ETF Trust",
        "value": "SIVR"
    },
    {
        "label": "NREG - Springs Rejuvenation, Inc.",
        "value": "NREG"
    },
    {
        "label": "HHH - Howard Hughes Holdings Inc.",
        "value": "HHH"
    },
    {
        "label": "ALEH - ALE Group Holding Ltd",
        "value": "ALEH"
    },
    {
        "label": "CNRLX - City National Rochdale Select Strategies Fund",
        "value": "CNRLX"
    },
    {
        "label": "FLXG - Flexi Group Holdings Ltd",
        "value": "FLXG"
    },
    {
        "label": "QSJC - TANCHENG GROUP CO., LTD.",
        "value": "QSJC"
    },
    {
        "label": "GPAK - Gamer Pakistan Inc",
        "value": "GPAK"
    },
    {
        "label": "SRBK - SR Bancorp, Inc.",
        "value": "SRBK"
    },
    {
        "label": "EIC - Eagle Point Income Co Inc.",
        "value": "EIC"
    },
    {
        "label": "RCIAX - Alternative Credit Income Fund",
        "value": "RCIAX"
    },
    {
        "label": "VRAYQ - ViewRay, Inc.",
        "value": "VRAYQ"
    },
    {
        "label": "GCGJ - GUOCHUN INTERNATIONAL INC.",
        "value": "GCGJ"
    },
    {
        "label": "NATL - NCR Atleos, LLC",
        "value": "NATL"
    },
    {
        "label": "JETR - Star Jets International Inc.",
        "value": "JETR"
    },
    {
        "label": "USEE - USEE ELECTRONIC COMMERCE LTD",
        "value": "USEE"
    },
    {
        "label": "ELEP - Elephant Oil Corp.",
        "value": "ELEP"
    },
    {
        "label": "XPTFX - Federated Hermes Project & Trade Finance Tender Fund",
        "value": "XPTFX"
    },
    {
        "label": "VACX - Vistas Acquisition Co II Inc.",
        "value": "VACX"
    },
    {
        "label": "BGFDX - Blackstone Floating Rate Enhanced Income Fund",
        "value": "BGFDX"
    },
    {
        "label": "BNTY - Bounty Minerals, Inc.",
        "value": "BNTY"
    },
    {
        "label": "CADCX - CION Ares Diversified Credit Fund",
        "value": "CADCX"
    },
    {
        "label": "TURO - Turo Inc.",
        "value": "TURO"
    },
    {
        "label": "CTWO - COTWO ADVISORS PHYSICAL EUROPEAN CARBON ALLOWANCE TRUST",
        "value": "CTWO"
    },
    {
        "label": "NNAG - 99 Acquisition Group Inc.",
        "value": "NNAG"
    },
    {
        "label": "UNFL - Unifoil Holdings, Inc.",
        "value": "UNFL"
    },
    {
        "label": "CKHL - Chi Ko Holdings Ltd",
        "value": "CKHL"
    },
    {
        "label": "HUHU - HUHUTECH International Group Inc.",
        "value": "HUHU"
    },
    {
        "label": "FDAN - FD TECHNOLOGY INC.",
        "value": "FDAN"
    },
    {
        "label": "KLG - WK Kellogg Co",
        "value": "KLG"
    },
    {
        "label": "YZH - YUEZHONGHUI INTERNATIONAL HOLDINGS GROUP LTD",
        "value": "YZH"
    },
    {
        "label": "HMR - Heidmar Marine Inc.",
        "value": "HMR"
    },
    {
        "label": "NVNI - Nvni Group Ltd",
        "value": "NVNI"
    },
    {
        "label": "SCRP - Scripps Safe, Inc.",
        "value": "SCRP"
    },
    {
        "label": "DESR - DESRI Inc.",
        "value": "DESR"
    },
    {
        "label": "PTRAQ - Proterra Inc",
        "value": "PTRAQ"
    },
    {
        "label": "PRZO - ParaZero Technologies Ltd.",
        "value": "PRZO"
    },
    {
        "label": "LOBO - LOBO EV TECHNOLOGIES LTD",
        "value": "LOBO"
    },
    {
        "label": "USL - United States 12 Month Oil Fund, LP",
        "value": "USL"
    },
    {
        "label": "VITT - Vittoria Ltd",
        "value": "VITT"
    },
    {
        "label": "UFND - Unifund Financial Technologies, Inc.",
        "value": "UFND"
    },
    {
        "label": "JTAI - Jet.AI Inc.",
        "value": "JTAI"
    },
    {
        "label": "BKV - BKV Corp",
        "value": "BKV"
    },
    {
        "label": "PSIG - PSI Group Holdings Ltd.",
        "value": "PSIG"
    },
    {
        "label": "IMSV - IMMRSIV Inc.",
        "value": "IMSV"
    },
    {
        "label": "MIRA - MIRA PHARMACEUTICALS, INC.",
        "value": "MIRA"
    },
    {
        "label": "RYET - Ruanyun Edai Technology Inc.",
        "value": "RYET"
    },
    {
        "label": "SCIT - Sancai Holding Group Ltd.",
        "value": "SCIT"
    },
    {
        "label": "ACMSY - Accustem Sciences Inc.",
        "value": "ACMSY"
    },
    {
        "label": "WEIX - Dynamic Shares Trust",
        "value": "WEIX"
    },
    {
        "label": "ELIQ - Electriq Power Holdings, Inc.",
        "value": "ELIQ"
    },
    {
        "label": "FTEL - Fitell Corp",
        "value": "FTEL"
    },
    {
        "label": "CTBB - QWEST CORP",
        "value": "CTBB"
    },
    {
        "label": "LEWY - LEEWAY SERVICES, INC.",
        "value": "LEWY"
    },
    {
        "label": "STA - HWEL Holdings Corp.",
        "value": "STA"
    },
    {
        "label": "AGLY - Atlantis Glory Inc.",
        "value": "AGLY"
    },
    {
        "label": "ADVB - Advanced Biomed Inc.",
        "value": "ADVB"
    },
    {
        "label": "QVCD - QVC INC",
        "value": "QVCD"
    },
    {
        "label": "PMFAX - PIMCO Flexible Municipal Income Fund",
        "value": "PMFAX"
    },
    {
        "label": "UDN - INVESCO DB US DOLLAR INDEX BEARISH FUND",
        "value": "UDN"
    },
    {
        "label": "HXHX - Haoxin Holdings Ltd",
        "value": "HXHX"
    },
    {
        "label": "ZLME - Zhanling International Ltd",
        "value": "ZLME"
    },
    {
        "label": "PGFF - Pioneer Green Farms, Inc.",
        "value": "PGFF"
    },
    {
        "label": "CHRO - Chromocell Therapeutics Corp",
        "value": "CHRO"
    },
    {
        "label": "ELGP - Elate Group, Inc.",
        "value": "ELGP"
    },
    {
        "label": "NYX - NYIAX, INC.",
        "value": "NYX"
    },
    {
        "label": "DAZS - DA AI ZHI SHUI INTERNATIONAL HOLDING GROUP LTD",
        "value": "DAZS"
    },
    {
        "label": "XCAPX - ACAP Strategic Fund",
        "value": "XCAPX"
    },
    {
        "label": "GOSC - Giant Oak Acquisition Corp",
        "value": "GOSC"
    },
    {
        "label": "FLLZ - Fellazo Corp",
        "value": "FLLZ"
    },
    {
        "label": "ALUR - ALLURION TECHNOLOGIES, INC.",
        "value": "ALUR"
    },
    {
        "label": "MYSN - Myson, Inc.",
        "value": "MYSN"
    },
    {
        "label": "NEAT - Noble Education Acquisition Corp.",
        "value": "NEAT"
    },
    {
        "label": "WDHI - Worldwide Diversified Holdings, Inc",
        "value": "WDHI"
    },
    {
        "label": "TSIO - Thornburg Strategic Income Opportunities Trust",
        "value": "TSIO"
    },
    {
        "label": "PMTM - Prometheum, Inc.",
        "value": "PMTM"
    },
    {
        "label": "CAPT - Captivision Inc.",
        "value": "CAPT"
    },
    {
        "label": "USQIX - USQ Core Real Estate Fund",
        "value": "USQIX"
    },
    {
        "label": "BZFL - BOZHI FANGLUE INTERNATIONAL INVESTMENT GROUP CO LTD",
        "value": "BZFL"
    },
    {
        "label": "XYLB - XY Labs, Inc.",
        "value": "XYLB"
    },
    {
        "label": "BATXF - Straightup Resources Inc",
        "value": "BATXF"
    },
    {
        "label": "ATGL - Alpha Technology Group Ltd",
        "value": "ATGL"
    },
    {
        "label": "XPASX - Peachtree Alternative Strategies Fund",
        "value": "XPASX"
    },
    {
        "label": "COPR - Idaho Copper Corp",
        "value": "COPR"
    },
    {
        "label": "MADL - MAN AHL DIVERSIFIED I LP",
        "value": "MADL"
    },
    {
        "label": "LGCL - Lucas GC Ltd",
        "value": "LGCL"
    },
    {
        "label": "GJS - STRATS(SM) Trust for Goldman Sachs Group Securities, Series 2006-2",
        "value": "GJS"
    },
    {
        "label": "FXC - Invesco CurrencyShares Canadian Dollar Trust",
        "value": "FXC"
    },
    {
        "label": "DVGR - Digital Virgo Group S.A.",
        "value": "DVGR"
    },
    {
        "label": "PODC - Courtside Group, Inc.",
        "value": "PODC"
    },
    {
        "label": "DSWR - Deseo Swimwear Inc.",
        "value": "DSWR"
    },
    {
        "label": "LEC - LAFAYETTE ENERGY CORP.",
        "value": "LEC"
    },
    {
        "label": "LBGJ - Li Bang International Corp Inc.",
        "value": "LBGJ"
    },
    {
        "label": "ALKD - Alkaid Acquisition Corp.",
        "value": "ALKD"
    },
    {
        "label": "NICHX - Variant Alternative Income Fund",
        "value": "NICHX"
    },
    {
        "label": "ODRS - Outdoor Specialty Products, Inc.",
        "value": "ODRS"
    },
    {
        "label": "CDTG - CDT Environmental Technology Investment Holdings Ltd",
        "value": "CDTG"
    },
    {
        "label": "GLNS - Golden Star Resource Corp.",
        "value": "GLNS"
    },
    {
        "label": "LNZNY - Lenzing AG/ADR",
        "value": "LNZNY"
    },
    {
        "label": "ZLS - Zalatoris II Acquisition Corp",
        "value": "ZLS"
    },
    {
        "label": "EPWK - EPWK Holdings Ltd.",
        "value": "EPWK"
    },
    {
        "label": "PFFLX - PIMCO Flexible Credit Income Fund",
        "value": "PFFLX"
    },
    {
        "label": "MXRX - MED-X, INC.",
        "value": "MXRX"
    },
    {
        "label": "SYRA - Syra Health Corp",
        "value": "SYRA"
    },
    {
        "label": "XMTI - X Metaverse Inc.",
        "value": "XMTI"
    },
    {
        "label": "SEZL - Sezzle Inc.",
        "value": "SEZL"
    },
    {
        "label": "MTWO - M2i Global, Inc.",
        "value": "MTWO"
    },
    {
        "label": "UMAV - UAV Corp",
        "value": "UMAV"
    },
    {
        "label": "ECLP - Eclipse Bancorp, Inc.",
        "value": "ECLP"
    },
    {
        "label": "NTCL - NetClass Technology Inc",
        "value": "NTCL"
    },
    {
        "label": "MPH - MEDICURE INC",
        "value": "MPH"
    },
    {
        "label": "VTRO - Vitro Biopharma, Inc.",
        "value": "VTRO"
    },
    {
        "label": "PDPG - Performance Drink Group, Inc.",
        "value": "PDPG"
    },
    {
        "label": "BTRY - Clarios International Inc.",
        "value": "BTRY"
    },
    {
        "label": "SKUR - Sekur Private Data Ltd.",
        "value": "SKUR"
    },
    {
        "label": "SOSH - SOS Hydration Inc.",
        "value": "SOSH"
    },
    {
        "label": "LVER - Lever Global Corp",
        "value": "LVER"
    },
    {
        "label": "NMTRQ - 9 METERS BIOPHARMA, INC.",
        "value": "NMTRQ"
    },
    {
        "label": "INTJ - Intelligent Group Ltd",
        "value": "INTJ"
    },
    {
        "label": "ZDYM - ZHIDING YUEMEI TECHNOLOGY CO., LTD",
        "value": "ZDYM"
    },
    {
        "label": "RISE - Rise Oil & Gas, Inc.",
        "value": "RISE"
    },
    {
        "label": "BGAC - Biotech Group Acquisition Corp",
        "value": "BGAC"
    },
    {
        "label": "VSTE - Vast Solar Pty Ltd",
        "value": "VSTE"
    },
    {
        "label": "ASCIX - Angel Oak Strategic Credit Fund",
        "value": "ASCIX"
    },
    {
        "label": "MJID - Majestic Ideal Holdings Ltd",
        "value": "MJID"
    },
    {
        "label": "VSME - VS MEDIA Holdings Ltd",
        "value": "VSME"
    },
    {
        "label": "PXDT - PIXIE DUST TECHNOLOGIES, INC.",
        "value": "PXDT"
    },
    {
        "label": "CPRDX - Clarion Partners Real Estate Income Fund Inc.",
        "value": "CPRDX"
    },
    {
        "label": "HLST - Holisto Ltd.",
        "value": "HLST"
    },
    {
        "label": "PAPL - Pineapple Financial Inc.",
        "value": "PAPL"
    },
    {
        "label": "CGTH - Creative Global Technology Holdings Ltd",
        "value": "CGTH"
    },
    {
        "label": "JBS - JBS B.V.",
        "value": "JBS"
    },
    {
        "label": "EMTX - EMULATE THERAPEUTICS, INC.",
        "value": "EMTX"
    },
    {
        "label": "KTN - STRUCTURED PRODUCTS CORP CRED ENHANCE CORTS TR FOR AON CAP A",
        "value": "KTN"
    },
    {
        "label": "BNO - United States Brent Oil Fund, LP",
        "value": "BNO"
    },
    {
        "label": "AIMA - Aimfinity Investment Corp. I",
        "value": "AIMA"
    },
    {
        "label": "DCCA - Decca Investment Ltd",
        "value": "DCCA"
    },
    {
        "label": "MANA - Grayscale Decentraland Trust (MANA)",
        "value": "MANA"
    },
    {
        "label": "YELLQ - Yellow Corp",
        "value": "YELLQ"
    },
    {
        "label": "LENDX - Stone Ridge Trust V",
        "value": "LENDX"
    },
    {
        "label": "AEIB - AEI CapForce II Investment Corp",
        "value": "AEIB"
    },
    {
        "label": "OUNZ - VanEck Merk Gold Trust",
        "value": "OUNZ"
    },
    {
        "label": "NBND - NetBrands Corp.",
        "value": "NBND"
    },
    {
        "label": "NRHI - Natural Resource Holdings, Inc.",
        "value": "NRHI"
    },
    {
        "label": "EVOH - EvoAir Holdings Inc.",
        "value": "EVOH"
    },
    {
        "label": "CRGH - Carriage House Event Center, Inc.",
        "value": "CRGH"
    },
    {
        "label": "EXTO - Almacenes Exito S.A.",
        "value": "EXTO"
    },
    {
        "label": "BOWN - Bowen Acquisition Corp",
        "value": "BOWN"
    },
    {
        "label": "VFS - VinFast Auto Ltd.",
        "value": "VFS"
    },
    {
        "label": "HGRN - Healthy Green Group Holding Ltd",
        "value": "HGRN"
    },
    {
        "label": "RADX - Radiopharm Theranostics Ltd",
        "value": "RADX"
    },
    {
        "label": "GLXG - Galaxy Payroll Group Ltd",
        "value": "GLXG"
    },
    {
        "label": "GLXY - Galaxy Digital Inc.",
        "value": "GLXY"
    },
    {
        "label": "GJH - STRATS SM TRUST FOR U S CELL CORP SEC SERIES 2004 6",
        "value": "GJH"
    },
    {
        "label": "GXLM - Grayscale Stellar Lumens Trust (XLM)",
        "value": "GXLM"
    },
    {
        "label": "PPLT - abrdn Platinum ETF Trust",
        "value": "PPLT"
    },
    {
        "label": "GLNK - Grayscale Chainlink Trust (LINK)",
        "value": "GLNK"
    },
    {
        "label": "NOVNQ - Novan, Inc.",
        "value": "NOVNQ"
    },
    {
        "label": "MALG - MICROALLIANCE GROUP INC.",
        "value": "MALG"
    },
    {
        "label": "SESG - Sprott ESG Gold ETF",
        "value": "SESG"
    },
    {
        "label": "SPGC - Sacks Parente Golf, Inc.",
        "value": "SPGC"
    },
    {
        "label": "SN - SharkNinja, Inc.",
        "value": "SN"
    },
    {
        "label": "SUNH - Xuhang Holdings Ltd",
        "value": "SUNH"
    },
    {
        "label": "MTEN - Mingteng International Corp Inc.",
        "value": "MTEN"
    },
    {
        "label": "EGOX - Next.e.GO B.V.",
        "value": "EGOX"
    },
    {
        "label": "ZXTY - ZHONGXING HOLDING GROUP LTD",
        "value": "ZXTY"
    },
    {
        "label": "BUJA - Bukit Jalil Global Acquisition 1 Ltd.",
        "value": "BUJA"
    },
    {
        "label": "SONG - Music Licensing Inc.",
        "value": "SONG"
    },
    {
        "label": "LVDW - LiquidValue Development Inc.",
        "value": "LVDW"
    },
    {
        "label": "ZTOP - Zi Toprun Acquisition Corp.",
        "value": "ZTOP"
    },
    {
        "label": "TDRK - TIDEROCK COMPANIES, INC.",
        "value": "TDRK"
    },
    {
        "label": "KTH - STRUCTURED PRODUCTS CORP CORTS TR FOR PECO ENERGY CAP TR III",
        "value": "KTH"
    },
    {
        "label": "GELS - Gelteq Ltd",
        "value": "GELS"
    },
    {
        "label": "GFCX - GoodFaith Technology Inc.",
        "value": "GFCX"
    },
    {
        "label": "OAK-PA - Oaktree Capital Group, LLC",
        "value": "OAK-PA"
    },
    {
        "label": "SGOL - abrdn Gold ETF Trust",
        "value": "SGOL"
    },
    {
        "label": "OBTC - Osprey Bitcoin Trust",
        "value": "OBTC"
    },
    {
        "label": "MDLS - MDNA Life Sciences, Inc.",
        "value": "MDLS"
    },
    {
        "label": "BMNR - BITMINE IMMERSION TECHNOLOGIES, INC.",
        "value": "BMNR"
    },
    {
        "label": "QTTOY - Qutoutiao Inc.",
        "value": "QTTOY"
    },
    {
        "label": "ZKGCF - ZKGC New Energy Ltd",
        "value": "ZKGCF"
    },
    {
        "label": "AAAU - Goldman Sachs Physical Gold ETF",
        "value": "AAAU"
    },
    {
        "label": "ETHE - Grayscale Ethereum Trust (ETH)",
        "value": "ETHE"
    },
    {
        "label": "GPLB - Green Planet Bio Engineering Co. Ltd.",
        "value": "GPLB"
    },
    {
        "label": "AETHF - Aether Global Innovations Corp.",
        "value": "AETHF"
    },
    {
        "label": "UGA - United States Gasoline Fund, LP",
        "value": "UGA"
    },
    {
        "label": "RUSA - IWAC HOLDINGS INC.",
        "value": "RUSA"
    },
    {
        "label": "AVRW - Avenir Wellness Solutions, Inc.",
        "value": "AVRW"
    },
    {
        "label": "WIN - Garden Stage Ltd",
        "value": "WIN"
    },
    {
        "label": "HAND - Hand in Hand Metaverse Marriage Technology Co., Ltd",
        "value": "HAND"
    },
    {
        "label": "PMC - PIMCO Municipal Credit Income Fund",
        "value": "PMC"
    },
    {
        "label": "JJOC - JJ Opportunity Corp.",
        "value": "JJOC"
    },
    {
        "label": "UBXG - U-BX Technology Ltd.",
        "value": "UBXG"
    },
    {
        "label": "BDS - Building DreamStar Technology Inc.",
        "value": "BDS"
    },
    {
        "label": "FROPX - Flat Rock Opportunity Fund",
        "value": "FROPX"
    },
    {
        "label": "FGDL - Franklin Templeton Holdings Trust",
        "value": "FGDL"
    },
    {
        "label": "AQUB - Aquarius II Acquisition Corp.",
        "value": "AQUB"
    },
    {
        "label": "ZBJ - ZBJ FINANCE GROUP LTD",
        "value": "ZBJ"
    },
    {
        "label": "MSBB - Mercer Bancorp, Inc.",
        "value": "MSBB"
    },
    {
        "label": "JZL - JIZHILONG HOLDING GROUP LTD",
        "value": "JZL"
    },
    {
        "label": "GJP - STRATS(SM) TRUST FOR DOMINION RESOURCES, INC. SECURITIES, SERIES 2005-6",
        "value": "GJP"
    },
    {
        "label": "GLTR - abrdn Precious Metals Basket ETF Trust",
        "value": "GLTR"
    },
    {
        "label": "YAMHY - Yamaha Motor Co., Ltd.",
        "value": "YAMHY"
    },
    {
        "label": "EAWC - Ecco Auto World Corp",
        "value": "EAWC"
    },
    {
        "label": "KBSG - KBS Growth & Income REIT, Inc.",
        "value": "KBSG"
    },
    {
        "label": "SMST - SmartStop Self Storage REIT, Inc.",
        "value": "SMST"
    },
    {
        "label": "DXYZ - Destiny Tech100 Inc.",
        "value": "DXYZ"
    },
    {
        "label": "IAUM - iShares Gold Trust Micro",
        "value": "IAUM"
    },
    {
        "label": "TLSI - TriSalus Life Sciences, Inc.",
        "value": "TLSI"
    },
    {
        "label": "PGRM - Panagram Capital, LLC",
        "value": "PGRM"
    },
    {
        "label": "WOK - WORK Medical Technology Group LTD",
        "value": "WOK"
    },
    {
        "label": "TRSG - Tungray Technologies Inc",
        "value": "TRSG"
    },
    {
        "label": "BRNI - Beroni Group Ltd",
        "value": "BRNI"
    },
    {
        "label": "EEW - ClimateRock Holdings Ltd",
        "value": "EEW"
    },
    {
        "label": "LIAI - LEMENG HOLDINGS LTD",
        "value": "LIAI"
    },
    {
        "label": "LRE - LEAD REAL ESTATE CO., LTD",
        "value": "LRE"
    },
    {
        "label": "WETH - Wetouch Technology Inc.",
        "value": "WETH"
    },
    {
        "label": "TDAI - Thornburg Durable Allocation & Income Trust",
        "value": "TDAI"
    },
    {
        "label": "NRSAX - NEXPOINT REAL ESTATE STRATEGIES FUND",
        "value": "NRSAX"
    },
    {
        "label": "MDRN - Modern Mining Technology Corp.",
        "value": "MDRN"
    },
    {
        "label": "THNK - T1V, Inc.",
        "value": "THNK"
    },
    {
        "label": "LQLY - QLY Biotech Group Corp.",
        "value": "LQLY"
    },
    {
        "label": "XGEIX - Guggenheim Energy & Income Fund",
        "value": "XGEIX"
    },
    {
        "label": "LQR - LQR House Inc.",
        "value": "LQR"
    },
    {
        "label": "MSTH - Mystic Holdings Inc./NV",
        "value": "MSTH"
    },
    {
        "label": "GOAI - Eva Live Inc",
        "value": "GOAI"
    },
    {
        "label": "RPET - New Ruipeng Pet Group Inc.",
        "value": "RPET"
    },
    {
        "label": "GDLT - Greifenberg Digital Ltd",
        "value": "GDLT"
    },
    {
        "label": "FLYF - Flewber Global Inc.",
        "value": "FLYF"
    },
    {
        "label": "DRFY - Droneify Holdings Ltd",
        "value": "DRFY"
    },
    {
        "label": "SRM - SRM Entertainment, Inc.",
        "value": "SRM"
    },
    {
        "label": "JPO - JP Outfitters, Inc.",
        "value": "JPO"
    },
    {
        "label": "ENGN - enGene Holdings Inc.",
        "value": "ENGN"
    },
    {
        "label": "NTV - Neotv Group Ltd",
        "value": "NTV"
    },
    {
        "label": "ILLR - Triller Corp.",
        "value": "ILLR"
    },
    {
        "label": "GSOL - Grayscale Solana Trust (SOL)",
        "value": "GSOL"
    },
    {
        "label": "ETI-P - ENTERGY TEXAS, INC.",
        "value": "ETI-P"
    },
    {
        "label": "UUP - Invesco DB US Dollar Index Bullish Fund",
        "value": "UUP"
    },
    {
        "label": "MNKA - Manuka, Inc.",
        "value": "MNKA"
    },
    {
        "label": "GJR - STRATS(SM) Trust for Procter & Gamble Securities, Series 2006-1",
        "value": "GJR"
    },
    {
        "label": "XSIAX - VOYA CREDIT INCOME FUND",
        "value": "XSIAX"
    },
    {
        "label": "ZAAG - ZA Group, Inc.",
        "value": "ZAAG"
    },
    {
        "label": "TPTA - Terra Property Trust, Inc.",
        "value": "TPTA"
    },
    {
        "label": "LTAFX - Alternative Strategies Fund",
        "value": "LTAFX"
    },
    {
        "label": "BSME - MED EIBY Holding Co., Ltd",
        "value": "BSME"
    },
    {
        "label": "WEIB - Weiss Strategic Interval Fund",
        "value": "WEIB"
    },
    {
        "label": "HTIA - Healthcare Trust, Inc.",
        "value": "HTIA"
    },
    {
        "label": "SAGN - Sagoon Inc.",
        "value": "SAGN"
    },
    {
        "label": "EHGO - EShallGo Inc.",
        "value": "EHGO"
    },
    {
        "label": "AVAI - AVANT TECHNOLOGIES INC.",
        "value": "AVAI"
    },
    {
        "label": "VOCO - Vocodia Holdings Corp",
        "value": "VOCO"
    },
    {
        "label": "FP - First Person Ltd.",
        "value": "FP"
    },
    {
        "label": "UMAC - Unusual Machines, Inc.",
        "value": "UMAC"
    },
    {
        "label": "JR - JINRONG HOLDINGS LTD.",
        "value": "JR"
    },
    {
        "label": "MI - NFT Ltd",
        "value": "MI"
    },
    {
        "label": "FBYD - Falcon's Beyond Global, Inc.",
        "value": "FBYD"
    },
    {
        "label": "DTCK - DAVIS COMMODITIES Ltd",
        "value": "DTCK"
    },
    {
        "label": "XJET - XJet Ltd.",
        "value": "XJET"
    },
    {
        "label": "MNX - MN8 Energy, Inc.",
        "value": "MNX"
    },
    {
        "label": "SFCO - Southern Financial Corp",
        "value": "SFCO"
    },
    {
        "label": "LRHC - La Rosa Holdings Corp.",
        "value": "LRHC"
    },
    {
        "label": "BOUW - Boustead Wavefront Inc.",
        "value": "BOUW"
    },
    {
        "label": "TSIFX - Ecofin Tax-Exempt Private Credit Fund, Inc.",
        "value": "TSIFX"
    },
    {
        "label": "EXOD - Exodus Movement, Inc.",
        "value": "EXOD"
    },
    {
        "label": "FXB - Invesco CurrencyShares British Pound Sterling Trust",
        "value": "FXB"
    },
    {
        "label": "BOMO - bowmo, Inc.",
        "value": "BOMO"
    },
    {
        "label": "CRVO - CervoMed Inc.",
        "value": "CRVO"
    },
    {
        "label": "FCCI - FAST CASUAL CONCEPTS, INC.",
        "value": "FCCI"
    },
    {
        "label": "DPUI - DISCOUNT PRINT USA, INC.",
        "value": "DPUI"
    },
    {
        "label": "PYT - PPLUS Trust Series GSC-2",
        "value": "PYT"
    },
    {
        "label": "KVAC - Keen Vision Acquisition Corp.",
        "value": "KVAC"
    },
    {
        "label": "AITR - AI Transportation Acquisition Corp",
        "value": "AITR"
    },
    {
        "label": "LGM - SIXGOMEOW LTD",
        "value": "LGM"
    },
    {
        "label": "HZEN - Grayscale Horizen Trust (ZEN)",
        "value": "HZEN"
    },
    {
        "label": "DEFG - Grayscale Decentralized Finance (DeFi) Fund LLC",
        "value": "DEFG"
    },
    {
        "label": "RVR - REV Renewables, Inc.",
        "value": "RVR"
    },
    {
        "label": "FPAQ - FPA ENERGY ACQUISITION CORP.",
        "value": "FPAQ"
    },
    {
        "label": "QHI - QINHONG INTERNATIONAL GROUP",
        "value": "QHI"
    },
    {
        "label": "CVTO - Covalto Ltd.",
        "value": "CVTO"
    },
    {
        "label": "SAVU - BioLife4D Corp",
        "value": "SAVU"
    },
    {
        "label": "WBUY - WEBUY GLOBAL LTD",
        "value": "WBUY"
    },
    {
        "label": "CTNT - CHEETAH NET SUPPLY CHAIN SERVICE INC.",
        "value": "CTNT"
    },
    {
        "label": "WXT - Wuxin Technology Holdings, Inc.",
        "value": "WXT"
    },
    {
        "label": "CRML - Critical Metals Corp.",
        "value": "CRML"
    },
    {
        "label": "UNL - United States 12 Month Natural Gas Fund, LP",
        "value": "UNL"
    },
    {
        "label": "SEQP - Santana Equestrian Private Financial, LLC",
        "value": "SEQP"
    },
    {
        "label": "SFWJ - Software Effective Solutions, Corp.",
        "value": "SFWJ"
    },
    {
        "label": "IAMR - Medical Industries of the Americas",
        "value": "IAMR"
    },
    {
        "label": "CCG - Cheche Group Inc.",
        "value": "CCG"
    },
    {
        "label": "MDRI - Midori Group Inc.",
        "value": "MDRI"
    },
    {
        "label": "SLDX - Stella Diagnostics, Inc.",
        "value": "SLDX"
    },
    {
        "label": "UNSS - UNIVERSAL SOLAR TECHNOLOGY, INC.",
        "value": "UNSS"
    },
    {
        "label": "GXXY - Galexxy Holdings, Inc.",
        "value": "GXXY"
    },
    {
        "label": "VCRRX - Versus Capital Real Assets Fund LLC",
        "value": "VCRRX"
    },
    {
        "label": "EIOAX - ELLINGTON INCOME OPPORTUNITIES FUND",
        "value": "EIOAX"
    },
    {
        "label": "SGD - Safe & Green Development Corp",
        "value": "SGD"
    },
    {
        "label": "PRDEX - PREDEX",
        "value": "PRDEX"
    },
    {
        "label": "ATCH - Calculator New Pubco, Inc.",
        "value": "ATCH"
    },
    {
        "label": "OMG - Broad Capital Acquisition Pty Ltd",
        "value": "OMG"
    },
    {
        "label": "AGII - AgiiPlus Inc.",
        "value": "AGII"
    },
    {
        "label": "NISM - Newsight Imaging Ltd.",
        "value": "NISM"
    },
    {
        "label": "SPPL - SIMPPLE LTD.",
        "value": "SPPL"
    },
    {
        "label": "TURB - Turbo Energy, S.A.",
        "value": "TURB"
    },
    {
        "label": "ONS - ONS Acquisition Corp.",
        "value": "ONS"
    },
    {
        "label": "XYGJ - Fortune Joy International Acquisition Corp",
        "value": "XYGJ"
    },
    {
        "label": "RMSG - Real Messenger Corp",
        "value": "RMSG"
    },
    {
        "label": "CHSCP - CHS INC",
        "value": "CHSCP"
    },
    {
        "label": "NRUC - NATIONAL RURAL UTILITIES COOPERATIVE FINANCE CORP /DC/",
        "value": "NRUC"
    },
    {
        "label": "SFLM - SFLMaven Corp.",
        "value": "SFLM"
    },
    {
        "label": "RYNL - Reynaldo's Mexican Food Company, Inc.",
        "value": "RYNL"
    },
    {
        "label": "PALL - abrdn Palladium ETF Trust",
        "value": "PALL"
    },
    {
        "label": "ANVI - ANVI GLOBAL HOLDINGS, INC.",
        "value": "ANVI"
    },
    {
        "label": "NRXS - Neuraxis, INC",
        "value": "NRXS"
    },
    {
        "label": "AIRE - reAlpha Tech Corp.",
        "value": "AIRE"
    },
    {
        "label": "TFSA - Terra Income Fund 6, LLC",
        "value": "TFSA"
    },
    {
        "label": "PSE - Prime Skyline Ltd",
        "value": "PSE"
    },
    {
        "label": "AIDG - AIS Holdings Group, Inc.",
        "value": "AIDG"
    },
    {
        "label": "ZCSH - Grayscale Zcash Trust (ZEC)",
        "value": "ZCSH"
    },
    {
        "label": "APPHQ - AppHarvest, Inc.",
        "value": "APPHQ"
    },
    {
        "label": "FCREX - FS Credit Income Fund",
        "value": "FCREX"
    },
    {
        "label": "IVP - INSPIRE VETERINARY PARTNERS, INC.",
        "value": "IVP"
    },
    {
        "label": "AAIDX - Axonic Alternative Income Fund",
        "value": "AAIDX"
    },
    {
        "label": "WDSP - World Scan Project, Inc.",
        "value": "WDSP"
    },
    {
        "label": "XILSX - Pioneer ILS Interval Fund",
        "value": "XILSX"
    },
    {
        "label": "RBNK - RiverBank Holding Co",
        "value": "RBNK"
    },
    {
        "label": "CCLFX - Cliffwater Corporate Lending Fund",
        "value": "CCLFX"
    },
    {
        "label": "CCIF - Carlyle Credit Income Fund",
        "value": "CCIF"
    },
    {
        "label": "NWGL - Nature Wood Group Ltd",
        "value": "NWGL"
    },
    {
        "label": "ANL - Adlai Nortye Ltd.",
        "value": "ANL"
    },
    {
        "label": "AUMB - AUM Biosciences Ltd",
        "value": "AUMB"
    },
    {
        "label": "SRRIX - Stone Ridge Trust II",
        "value": "SRRIX"
    },
    {
        "label": "ETZ - Earntz Healthcare Products, Inc.",
        "value": "ETZ"
    },
    {
        "label": "GMM - Global Mofy Metaverse Ltd",
        "value": "GMM"
    },
    {
        "label": "CPBI - Central Plains Bancshares, Inc.",
        "value": "CPBI"
    },
    {
        "label": "GC - Gladstone Companies, Inc.",
        "value": "GC"
    },
    {
        "label": "MDBH - MDB Capital Holdings, LLC",
        "value": "MDBH"
    },
    {
        "label": "CDEX - CardieX Ltd",
        "value": "CDEX"
    },
    {
        "label": "HBER - HUBEIER GROUP LTD.",
        "value": "HBER"
    },
    {
        "label": "OWSCX - 1WS Credit Income Fund",
        "value": "OWSCX"
    },
    {
        "label": "PDSKX - Principal Diversified Select Real Asset Fund",
        "value": "PDSKX"
    },
    {
        "label": "BAR - GraniteShares Gold Trust",
        "value": "BAR"
    },
    {
        "label": "CBLO - C2 Blockchain,Inc.",
        "value": "CBLO"
    },
    {
        "label": "OSTX - OS Therapies Inc",
        "value": "OSTX"
    },
    {
        "label": "ODDB - Odd Burger Corp",
        "value": "ODDB"
    },
    {
        "label": "LDWY - LENDWAY, INC.",
        "value": "LDWY"
    },
    {
        "label": "WETO - Webus International Ltd.",
        "value": "WETO"
    },
    {
        "label": "NPCT - Nuveen Core Plus Impact Fund",
        "value": "NPCT"
    },
    {
        "label": "AZI - Autozi Internet Technology (Global) Ltd.",
        "value": "AZI"
    },
    {
        "label": "JBK - LEHMAN ABS CORP GOLDMAN SACHS CAP 1 SEC BACKED SER 2004-6",
        "value": "JBK"
    },
    {
        "label": "SMXT - SolarMax Technology, Inc.",
        "value": "SMXT"
    },
    {
        "label": "NBRVF - Nabriva Therapeutics plc",
        "value": "NBRVF"
    },
    {
        "label": "FXA - Invesco CurrencyShares Australian Dollar Trust",
        "value": "FXA"
    },
    {
        "label": "GJT - STRATS(SM) Trust for Allstate Corp Securities, Series 2006-3",
        "value": "GJT"
    },
    {
        "label": "ELC - ENTERGY LOUISIANA, LLC",
        "value": "ELC"
    },
    {
        "label": "OLSI - Origin Life Sciences, Inc.",
        "value": "OLSI"
    },
    {
        "label": "MAMA - MamaMancini's Holdings, Inc.",
        "value": "MAMA"
    },
    {
        "label": "DDCIU - Diffuse Digital 30, LP",
        "value": "DDCIU"
    },
    {
        "label": "CECL - CECIL BANCORP INC",
        "value": "CECL"
    },
    {
        "label": "MDLB - Medlab Clinical Ltd.",
        "value": "MDLB"
    },
    {
        "label": "DRPO - Draganfly Inc.",
        "value": "DRPO"
    },
    {
        "label": "CGRD - GRD Biotechnology Acquisition Ltd",
        "value": "CGRD"
    },
    {
        "label": "VFLEX - FIRST TRUST ALTERNATIVE OPPORTUNITIES FUND",
        "value": "VFLEX"
    },
    {
        "label": "MSS - Maison Solutions Inc.",
        "value": "MSS"
    },
    {
        "label": "YIBO - Planet Image International Ltd",
        "value": "YIBO"
    },
    {
        "label": "HRYU - Hanryu Holdings, Inc.",
        "value": "HRYU"
    },
    {
        "label": "TWEN - T20 HOLDINGS LTD.",
        "value": "TWEN"
    },
    {
        "label": "LBIO - Denali SPAC Holdco, Inc.",
        "value": "LBIO"
    },
    {
        "label": "HARD - Harden Technologies Inc.",
        "value": "HARD"
    },
    {
        "label": "CETI - Cyber Enviro-Tech, Inc.",
        "value": "CETI"
    },
    {
        "label": "NCL - Northann Corp.",
        "value": "NCL"
    },
    {
        "label": "TKO - New Whale Inc.",
        "value": "TKO"
    },
    {
        "label": "NETD - Nabors Energy Transition Corp. II",
        "value": "NETD"
    },
    {
        "label": "IBG - Innovation Beverage Group Ltd",
        "value": "IBG"
    },
    {
        "label": "AMGS - Prospect Energy Holdings Corp.",
        "value": "AMGS"
    },
    {
        "label": "CRGT - Cortigent, Inc.",
        "value": "CRGT"
    },
    {
        "label": "SWIN - Solowin Holdings, Ltd.",
        "value": "SWIN"
    },
    {
        "label": "GOOG - Alphabet Inc.",
        "value": "GOOG"
    },
    {
        "label": "USB-PA - US BANCORP \\DE\\",
        "value": "USB-PA"
    },
    {
        "label": "BRK-A - BERKSHIRE HATHAWAY INC",
        "value": "BRK-A"
    },
    {
        "label": "LVMHF - LVMH MOET HENNESSY LOUIS VUITTON",
        "value": "LVMHF"
    },
    {
        "label": "NONOF - NOVO NORDISK A S",
        "value": "NONOF"
    },
    {
        "label": "JPM-PD - JPMORGAN CHASE & CO",
        "value": "JPM-PD"
    },
    {
        "label": "BML-PG - BANK OF AMERICA CORP /DE/",
        "value": "BML-PG"
    },
    {
        "label": "BML-PH - BANK OF AMERICA CORP /DE/",
        "value": "BML-PH"
    },
    {
        "label": "ASMLF - ASML HOLDING NV",
        "value": "ASMLF"
    },
    {
        "label": "BAC-PE - BANK OF AMERICA CORP /DE/",
        "value": "BAC-PE"
    },
    {
        "label": "BML-PJ - BANK OF AMERICA CORP /DE/",
        "value": "BML-PJ"
    },
    {
        "label": "BABAF - Alibaba Group Holding Ltd",
        "value": "BABAF"
    },
    {
        "label": "NVSEF - NOVARTIS AG",
        "value": "NVSEF"
    },
    {
        "label": "AZNCF - ASTRAZENECA PLC",
        "value": "AZNCF"
    },
    {
        "label": "RYDAF - Shell plc",
        "value": "RYDAF"
    },
    {
        "label": "WFC-PQ - WELLS FARGO & COMPANY/MN",
        "value": "WFC-PQ"
    },
    {
        "label": "PCCYF - PETROCHINA CO LTD",
        "value": "PCCYF"
    },
    {
        "label": "WFC-PR - WELLS FARGO & COMPANY/MN",
        "value": "WFC-PR"
    },
    {
        "label": "WFC-PY - WELLS FARGO & COMPANY/MN",
        "value": "WFC-PY"
    },
    {
        "label": "SAPGF - SAP SE",
        "value": "SAPGF"
    },
    {
        "label": "TTFNF - TotalEnergies SE",
        "value": "TTFNF"
    },
    {
        "label": "HBCYF - HSBC HOLDINGS PLC",
        "value": "HBCYF"
    },
    {
        "label": "BHPLF - BHP Group Ltd",
        "value": "BHPLF"
    },
    {
        "label": "SNYNF - Sanofi",
        "value": "SNYNF"
    },
    {
        "label": "WFC-PC - WELLS FARGO & COMPANY/MN",
        "value": "WFC-PC"
    },
    {
        "label": "EADSF - Airbus SE/ADR",
        "value": "EADSF"
    },
    {
        "label": "SNEJF - Sony Group Corp",
        "value": "SNEJF"
    },
    {
        "label": "CILJF - CHINA LIFE INSURANCE CO LTD",
        "value": "CILJF"
    },
    {
        "label": "DGEAF - DIAGEO PLC",
        "value": "DGEAF"
    },
    {
        "label": "RY-PT - ROYAL BANK OF CANADA",
        "value": "RY-PT"
    },
    {
        "label": "CMXHF - CSL LTD",
        "value": "CMXHF"
    },
    {
        "label": "MBGAF - DAIMLER AG",
        "value": "MBGAF"
    },
    {
        "label": "GS-PA - GOLDMAN SACHS GROUP INC",
        "value": "GS-PA"
    },
    {
        "label": "MS-PA - MORGAN STANLEY",
        "value": "MS-PA"
    },
    {
        "label": "GS-PD - GOLDMAN SACHS GROUP INC",
        "value": "GS-PD"
    },
    {
        "label": "BTAFF - British American Tobacco p.l.c.",
        "value": "BTAFF"
    },
    {
        "label": "GLAXF - GSK plc",
        "value": "GLAXF"
    },
    {
        "label": "ABLZF - ABB LTD",
        "value": "ABLZF"
    },
    {
        "label": "GS-PJ - GOLDMAN SACHS GROUP INC",
        "value": "GS-PJ"
    },
    {
        "label": "ESOCF - ENEL SOCIETA PER AZIONI",
        "value": "ESOCF"
    },
    {
        "label": "MS-PI - MORGAN STANLEY",
        "value": "MS-PI"
    },
    {
        "label": "MS-PF - MORGAN STANLEY",
        "value": "MS-PF"
    },
    {
        "label": "BCDRF - Banco Santander, S.A.",
        "value": "BCDRF"
    },
    {
        "label": "GS-PK - GOLDMAN SACHS GROUP INC",
        "value": "GS-PK"
    },
    {
        "label": "TOELF - Tokyo Electron LTD",
        "value": "TOELF"
    },
    {
        "label": "ATLCY - ATLAS COPCO AB",
        "value": "ATLCY"
    },
    {
        "label": "DUK-PA - Duke Energy CORP",
        "value": "DUK-PA"
    },
    {
        "label": "SCHW-PD - SCHWAB CHARLES CORP",
        "value": "SCHW-PD"
    },
    {
        "label": "AIG-PA - AMERICAN INTERNATIONAL GROUP, INC.",
        "value": "AIG-PA"
    },
    {
        "label": "BAESF - BAE SYSTEMS PLC /FI/",
        "value": "BAESF"
    },
    {
        "label": "PSA-PK - Public Storage",
        "value": "PSA-PK"
    },
    {
        "label": "ALL-PH - ALLSTATE CORP",
        "value": "ALL-PH"
    },
    {
        "label": "ALL-PB - ALLSTATE CORP",
        "value": "ALL-PB"
    },
    {
        "label": "FNCTF - ORANGE",
        "value": "FNCTF"
    },
    {
        "label": "VODPF - VODAFONE GROUP PUBLIC LTD CO",
        "value": "VODPF"
    },
    {
        "label": "SOJC - SOUTHERN CO",
        "value": "SOJC"
    },
    {
        "label": "TEFOF - TELEFONICA S A",
        "value": "TEFOF"
    },
    {
        "label": "DLR-PJ - DIGITAL REALTY TRUST, INC.",
        "value": "DLR-PJ"
    },
    {
        "label": "NOKBF - NOKIA CORP",
        "value": "NOKBF"
    },
    {
        "label": "FCNCB - FIRST CITIZENS BANCSHARES INC /DE/",
        "value": "FCNCB"
    },
    {
        "label": "HEI-A - HEICO CORP",
        "value": "HEI-A"
    },
    {
        "label": "HIG-PG - HARTFORD FINANCIAL SERVICES GROUP, INC.",
        "value": "HIG-PG"
    },
    {
        "label": "FITBI - FIFTH THIRD BANCORP",
        "value": "FITBI"
    },
    {
        "label": "EBR-B - BRAZILIAN ELECTRIC POWER CO",
        "value": "EBR-B"
    },
    {
        "label": "FOX - Fox Corp",
        "value": "FOX"
    },
    {
        "label": "FWONK - Liberty Media Corp",
        "value": "FWONK"
    },
    {
        "label": "FWONA - Liberty Media Corp",
        "value": "FWONA"
    },
    {
        "label": "HUNGF - HUANENG POWER INTERNATIONAL INC",
        "value": "HUNGF"
    },
    {
        "label": "NLY-PG - ANNALY CAPITAL MANAGEMENT INC",
        "value": "NLY-PG"
    },
    {
        "label": "CFG-PD - CITIZENS FINANCIAL GROUP INC/RI",
        "value": "CFG-PD"
    },
    {
        "label": "NLY-PF - ANNALY CAPITAL MANAGEMENT INC",
        "value": "NLY-PF"
    },
    {
        "label": "LNVGF - LENOVO GROUP LTD",
        "value": "LNVGF"
    },
    {
        "label": "ACGLO - ARCH CAPITAL GROUP LTD.",
        "value": "ACGLO"
    },
    {
        "label": "KEY-PJ - KEYCORP /NEW/",
        "value": "KEY-PJ"
    },
    {
        "label": "NWS - NEWS CORP",
        "value": "NWS"
    },
    {
        "label": "CXMSF - CEMEX SAB DE CV",
        "value": "CXMSF"
    },
    {
        "label": "Z - ZILLOW GROUP, INC.",
        "value": "Z"
    },
    {
        "label": "FMCCT - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCCT"
    },
    {
        "label": "NI-PB - NISOURCE INC.",
        "value": "NI-PB"
    },
    {
        "label": "GIKLY - Grifols SA",
        "value": "GIKLY"
    },
    {
        "label": "VIVEF - VIVENDI",
        "value": "VIVEF"
    },
    {
        "label": "CIG-C - ENERGY CO OF MINAS GERAIS",
        "value": "CIG-C"
    },
    {
        "label": "AGNCN - AGNC Investment Corp.",
        "value": "AGNCN"
    },
    {
        "label": "FRT-PC - FEDERAL REALTY INVESTMENT TRUST",
        "value": "FRT-PC"
    },
    {
        "label": "RZB - REINSURANCE GROUP OF AMERICA INC",
        "value": "RZB"
    },
    {
        "label": "LBTYK - Liberty Global plc",
        "value": "LBTYK"
    },
    {
        "label": "GS-PC - GOLDMAN SACHS GROUP INC",
        "value": "GS-PC"
    },
    {
        "label": "AGNCM - AGNC Investment Corp.",
        "value": "AGNCM"
    },
    {
        "label": "AMH-PH - American Homes 4 Rent",
        "value": "AMH-PH"
    },
    {
        "label": "LSXMK - Liberty Media Corp",
        "value": "LSXMK"
    },
    {
        "label": "AMH-PG - American Homes 4 Rent",
        "value": "AMH-PG"
    },
    {
        "label": "KIM-PM - KIMCO REALTY CORP",
        "value": "KIM-PM"
    },
    {
        "label": "RNR-PF - RENAISSANCERE HOLDINGS LTD",
        "value": "RNR-PF"
    },
    {
        "label": "KIM-PL - KIMCO REALTY CORP",
        "value": "KIM-PL"
    },
    {
        "label": "VOYA-PB - Voya Financial, Inc.",
        "value": "VOYA-PB"
    },
    {
        "label": "ATH-PA - Athene Holding Ltd",
        "value": "ATH-PA"
    },
    {
        "label": "UNVR - Univar Solutions Inc.",
        "value": "UNVR"
    },
    {
        "label": "PCG-PA - PG&E Corp",
        "value": "PCG-PA"
    },
    {
        "label": "OAK-PB - Oaktree Capital Group, LLC",
        "value": "OAK-PB"
    },
    {
        "label": "SLG-PI - SL GREEN REALTY CORP",
        "value": "SLG-PI"
    },
    {
        "label": "UNMA - Unum Group",
        "value": "UNMA"
    },
    {
        "label": "JSM - NAVIENT CORP",
        "value": "JSM"
    },
    {
        "label": "CWEN-A - Clearway Energy, Inc.",
        "value": "CWEN-A"
    },
    {
        "label": "SNV-PD - SYNOVUS FINANCIAL CORP",
        "value": "SNV-PD"
    },
    {
        "label": "GAB-PG - GABELLI EQUITY TRUST INC",
        "value": "GAB-PG"
    },
    {
        "label": "PCG-PB - PG&E Corp",
        "value": "PCG-PB"
    },
    {
        "label": "AUOTY - AU OPTRONICS CORP",
        "value": "AUOTY"
    },
    {
        "label": "EPR-PG - EPR PROPERTIES",
        "value": "EPR-PG"
    },
    {
        "label": "PCG-PE - PG&E Corp",
        "value": "PCG-PE"
    },
    {
        "label": "THNPY - Technip Energies N.V.",
        "value": "THNPY"
    },
    {
        "label": "HHC - Howard Hughes Corp",
        "value": "HHC"
    },
    {
        "label": "SR-PA - SPIRE INC",
        "value": "SR-PA"
    },
    {
        "label": "REXR-PB - Rexford Industrial Realty, Inc.",
        "value": "REXR-PB"
    },
    {
        "label": "EQC-PD - Equity Commonwealth",
        "value": "EQC-PD"
    },
    {
        "label": "SAPMY - SAIPEM S P A /FI",
        "value": "SAPMY"
    },
    {
        "label": "BHFAP - Brighthouse Financial, Inc.",
        "value": "BHFAP"
    },
    {
        "label": "SRC-PA - SPIRIT REALTY CAPITAL, INC.",
        "value": "SRC-PA"
    },
    {
        "label": "ESGRO - Enstar Group LTD",
        "value": "ESGRO"
    },
    {
        "label": "SF-PB - STIFEL FINANCIAL CORP",
        "value": "SF-PB"
    },
    {
        "label": "GEF-B - GREIF, INC",
        "value": "GEF-B"
    },
    {
        "label": "RUSHB - RUSH ENTERPRISES INC TX",
        "value": "RUSHB"
    },
    {
        "label": "PBI-PB - PITNEY BOWES INC /DE/",
        "value": "PBI-PB"
    },
    {
        "label": "UA - Under Armour, Inc.",
        "value": "UA"
    },
    {
        "label": "NS-PB - NuStar Energy L.P.",
        "value": "NS-PB"
    },
    {
        "label": "NS-PA - NuStar Energy L.P.",
        "value": "NS-PA"
    },
    {
        "label": "TWO-PC - TWO HARBORS INVESTMENT CORP.",
        "value": "TWO-PC"
    },
    {
        "label": "WTFCM - WINTRUST FINANCIAL CORP",
        "value": "WTFCM"
    },
    {
        "label": "FNMAS - FEDERAL NATIONAL MORTGAGE ASSOCIATION FANNIE MAE",
        "value": "FNMAS"
    },
    {
        "label": "FNMAH - FEDERAL NATIONAL MORTGAGE ASSOCIATION FANNIE MAE",
        "value": "FNMAH"
    },
    {
        "label": "CIM-PB - CHIMERA INVESTMENT CORP",
        "value": "CIM-PB"
    },
    {
        "label": "CIM-PD - CHIMERA INVESTMENT CORP",
        "value": "CIM-PD"
    },
    {
        "label": "FNMFN - FEDERAL NATIONAL MORTGAGE ASSOCIATION FANNIE MAE",
        "value": "FNMFN"
    },
    {
        "label": "FNMAJ - FEDERAL NATIONAL MORTGAGE ASSOCIATION FANNIE MAE",
        "value": "FNMAJ"
    },
    {
        "label": "CIM-PC - CHIMERA INVESTMENT CORP",
        "value": "CIM-PC"
    },
    {
        "label": "TWO-PA - TWO HARBORS INVESTMENT CORP.",
        "value": "TWO-PA"
    },
    {
        "label": "HOVNP - HOVNANIAN ENTERPRISES INC",
        "value": "HOVNP"
    },
    {
        "label": "TWO-PB - TWO HARBORS INVESTMENT CORP.",
        "value": "TWO-PB"
    },
    {
        "label": "KDNY - CHINOOK THERAPEUTICS, INC.",
        "value": "KDNY"
    },
    {
        "label": "TY-P - TRI-CONTINENTAL CORP",
        "value": "TY-P"
    },
    {
        "label": "GNGYF - GUANGSHEN RAILWAY CO LTD",
        "value": "GNGYF"
    },
    {
        "label": "MFA-PB - MFA FINANCIAL, INC.",
        "value": "MFA-PB"
    },
    {
        "label": "VLYPP - VALLEY NATIONAL BANCORP",
        "value": "VLYPP"
    },
    {
        "label": "AKO-B - ANDINA BOTTLING CO INC",
        "value": "AKO-B"
    },
    {
        "label": "NS-PC - NuStar Energy L.P.",
        "value": "NS-PC"
    },
    {
        "label": "BATRK - Atlanta Braves Holdings, Inc.",
        "value": "BATRK"
    },
    {
        "label": "CIXXF - CI Financial Corp.",
        "value": "CIXXF"
    },
    {
        "label": "SRG-PA - Seritage Growth Properties",
        "value": "SRG-PA"
    },
    {
        "label": "DICE - DICE Therapeutics, Inc.",
        "value": "DICE"
    },
    {
        "label": "TRTN-PA - Triton International Ltd",
        "value": "TRTN-PA"
    },
    {
        "label": "CENTA - CENTRAL GARDEN & PET CO",
        "value": "CENTA"
    },
    {
        "label": "ASB-PE - ASSOCIATED BANC-CORP",
        "value": "ASB-PE"
    },
    {
        "label": "LDDD - Longduoduo Co Ltd",
        "value": "LDDD"
    },
    {
        "label": "LILAK - Liberty Latin America Ltd.",
        "value": "LILAK"
    },
    {
        "label": "AGM-A - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM-A"
    },
    {
        "label": "NGL-PB - NGL Energy Partners LP",
        "value": "NGL-PB"
    },
    {
        "label": "IVR-PC - Invesco Mortgage Capital Inc.",
        "value": "IVR-PC"
    },
    {
        "label": "LGF-B - LIONS GATE ENTERTAINMENT CORP /CN/",
        "value": "LGF-B"
    },
    {
        "label": "FMCKI - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCKI"
    },
    {
        "label": "FMCKL - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCKL"
    },
    {
        "label": "FMCCJ - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCCJ"
    },
    {
        "label": "FMCCL - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCCL"
    },
    {
        "label": "FMCCG - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCCG"
    },
    {
        "label": "GAB-PH - GABELLI EQUITY TRUST INC",
        "value": "GAB-PH"
    },
    {
        "label": "MHLA - Maiden Holdings, Ltd.",
        "value": "MHLA"
    },
    {
        "label": "FMCCI - FEDERAL HOME LOAN MORTGAGE CORP",
        "value": "FMCCI"
    },
    {
        "label": "GLOG-PA - GasLog Ltd.",
        "value": "GLOG-PA"
    },
    {
        "label": "GNL-PA - Global Net Lease, Inc.",
        "value": "GNL-PA"
    },
    {
        "label": "HCXY - Hercules Capital, Inc.",
        "value": "HCXY"
    },
    {
        "label": "CODI-PA - Compass Diversified Holdings",
        "value": "CODI-PA"
    },
    {
        "label": "NYMTN - NEW YORK MORTGAGE TRUST INC",
        "value": "NYMTN"
    },
    {
        "label": "AHL-PD - ASPEN INSURANCE HOLDINGS LTD",
        "value": "AHL-PD"
    },
    {
        "label": "GLOP-PB - GasLog Partners LP",
        "value": "GLOP-PB"
    },
    {
        "label": "AHH-PA - Armada Hoffler Properties, Inc.",
        "value": "AHH-PA"
    },
    {
        "label": "GLOP-PC - GasLog Partners LP",
        "value": "GLOP-PC"
    },
    {
        "label": "IIPR-PA - INNOVATIVE INDUSTRIAL PROPERTIES INC",
        "value": "IIPR-PA"
    },
    {
        "label": "INN-PE - Summit Hotel Properties, Inc.",
        "value": "INN-PE"
    },
    {
        "label": "GAM-PB - GENERAL AMERICAN INVESTORS CO INC",
        "value": "GAM-PB"
    },
    {
        "label": "UBA - URSTADT BIDDLE PROPERTIES INC",
        "value": "UBA"
    },
    {
        "label": "FSUN - FIRSTSUN CAPITAL BANCORP",
        "value": "FSUN"
    },
    {
        "label": "AGM-PC - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM-PC"
    },
    {
        "label": "AGM-PD - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM-PD"
    },
    {
        "label": "UBP-PH - URSTADT BIDDLE PROPERTIES INC",
        "value": "UBP-PH"
    },
    {
        "label": "AIC - Arlington Asset Investment Corp.",
        "value": "AIC"
    },
    {
        "label": "CMRE-PB - Costamare Inc.",
        "value": "CMRE-PB"
    },
    {
        "label": "BELFB - BEL FUSE INC /NJ",
        "value": "BELFB"
    },
    {
        "label": "CMRE-PC - Costamare Inc.",
        "value": "CMRE-PC"
    },
    {
        "label": "HT-PD - HERSHA HOSPITALITY TRUST",
        "value": "HT-PD"
    },
    {
        "label": "CMRE-PD - Costamare Inc.",
        "value": "CMRE-PD"
    },
    {
        "label": "CMRE-PE - Costamare Inc.",
        "value": "CMRE-PE"
    },
    {
        "label": "GGN-PB - GAMCO Global Gold, Natural Resources & Income Trust",
        "value": "GGN-PB"
    },
    {
        "label": "GGT-PE - GABELLI MULTIMEDIA TRUST INC.",
        "value": "GGT-PE"
    },
    {
        "label": "DGICB - DONEGAL GROUP INC",
        "value": "DGICB"
    },
    {
        "label": "BH - Biglari Holdings Inc.",
        "value": "BH"
    },
    {
        "label": "GAMI - GAMCO INVESTORS, INC. ET AL",
        "value": "GAMI"
    },
    {
        "label": "AIRTP - AIR T INC",
        "value": "AIRTP"
    },
    {
        "label": "KDSKF - Royal DSM N.V.",
        "value": "KDSKF"
    },
    {
        "label": "CPAA - Conyers Park III Acquisition Corp.",
        "value": "CPAA"
    },
    {
        "label": "UMH-PD - UMH PROPERTIES, INC.",
        "value": "UMH-PD"
    },
    {
        "label": "GOODO - GLADSTONE COMMERCIAL CORP",
        "value": "GOODO"
    },
    {
        "label": "ECCX - Eagle Point Credit Co Inc.",
        "value": "ECCX"
    },
    {
        "label": "NCV-PA - Virtus Convertible & Income Fund",
        "value": "NCV-PA"
    },
    {
        "label": "MBINP - Merchants Bancorp",
        "value": "MBINP"
    },
    {
        "label": "OXLCO - Oxford Lane Capital Corp.",
        "value": "OXLCO"
    },
    {
        "label": "BRCHF - Brainchip Holdings Limited/ADR",
        "value": "BRCHF"
    },
    {
        "label": "GMRE-PA - Global Medical REIT Inc.",
        "value": "GMRE-PA"
    },
    {
        "label": "MITT-PA - AG Mortgage Investment Trust, Inc.",
        "value": "MITT-PA"
    },
    {
        "label": "SPLP-PA - STEEL PARTNERS HOLDINGS L.P.",
        "value": "SPLP-PA"
    },
    {
        "label": "QRTEB - Qurate Retail, Inc.",
        "value": "QRTEB"
    },
    {
        "label": "GUT-PC - GABELLI UTILITY TRUST",
        "value": "GUT-PC"
    },
    {
        "label": "GSCCF - ioneer Ltd",
        "value": "GSCCF"
    },
    {
        "label": "MITT-PB - AG Mortgage Investment Trust, Inc.",
        "value": "MITT-PB"
    },
    {
        "label": "UIHC - AMERICAN COASTAL INSURANCE Corp",
        "value": "UIHC"
    },
    {
        "label": "AFTR - AfterNext HealthTech Acquisition Corp.",
        "value": "AFTR"
    },
    {
        "label": "TNP-PE - TSAKOS ENERGY NAVIGATION LTD",
        "value": "TNP-PE"
    },
    {
        "label": "CIO-PA - City Office REIT, Inc.",
        "value": "CIO-PA"
    },
    {
        "label": "NVNXF - NOVONIX Ltd",
        "value": "NVNXF"
    },
    {
        "label": "BHR-PD - Braemar Hotels & Resorts Inc.",
        "value": "BHR-PD"
    },
    {
        "label": "APLMW - Apollomics Inc.",
        "value": "APLMW"
    },
    {
        "label": "BHR-PB - Braemar Hotels & Resorts Inc.",
        "value": "BHR-PB"
    },
    {
        "label": "CHMI-PB - Cherry Hill Mortgage Investment Corp",
        "value": "CHMI-PB"
    },
    {
        "label": "UONEK - URBAN ONE, INC.",
        "value": "UONEK"
    },
    {
        "label": "FGPRB - FERRELLGAS PARTNERS L P",
        "value": "FGPRB"
    },
    {
        "label": "AHT-PG - ASHFORD HOSPITALITY TRUST INC",
        "value": "AHT-PG"
    },
    {
        "label": "AHT-PH - ASHFORD HOSPITALITY TRUST INC",
        "value": "AHT-PH"
    },
    {
        "label": "BSAQ - Black Spade Acquisition Co",
        "value": "BSAQ"
    },
    {
        "label": "AHT-PD - ASHFORD HOSPITALITY TRUST INC",
        "value": "AHT-PD"
    },
    {
        "label": "AHT-PI - ASHFORD HOSPITALITY TRUST INC",
        "value": "AHT-PI"
    },
    {
        "label": "AHT-PF - ASHFORD HOSPITALITY TRUST INC",
        "value": "AHT-PF"
    },
    {
        "label": "SB-PD - SAFE BULKERS, INC.",
        "value": "SB-PD"
    },
    {
        "label": "SAL - SALISBURY BANCORP, INC.",
        "value": "SAL"
    },
    {
        "label": "CDR-PB - CEDAR REALTY TRUST, INC.",
        "value": "CDR-PB"
    },
    {
        "label": "DLNG-PA - Dynagas LNG Partners LP",
        "value": "DLNG-PA"
    },
    {
        "label": "NSRCF - NextSource Materials Inc.",
        "value": "NSRCF"
    },
    {
        "label": "SGTM - Sustainable Green Team, Ltd.",
        "value": "SGTM"
    },
    {
        "label": "CDR-PC - CEDAR REALTY TRUST, INC.",
        "value": "CDR-PC"
    },
    {
        "label": "ECF-PA - ELLSWORTH GROWTH & INCOME FUND LTD",
        "value": "ECF-PA"
    },
    {
        "label": "SOHOB - Sotherly Hotels Inc.",
        "value": "SOHOB"
    },
    {
        "label": "SOHON - Sotherly Hotels Inc.",
        "value": "SOHON"
    },
    {
        "label": "BCV-PA - BANCROFT FUND LTD",
        "value": "BCV-PA"
    },
    {
        "label": "IDBA - IDEX Biometrics ASA",
        "value": "IDBA"
    },
    {
        "label": "MTAC - TriSalus Life Sciences, Inc.",
        "value": "MTAC"
    },
    {
        "label": "TLGA - Electriq Power Holdings, Inc.",
        "value": "TLGA"
    },
    {
        "label": "WONDF - WonderFi Technologies Inc.",
        "value": "WONDF"
    },
    {
        "label": "ARBKF - Argo Blockchain Plc",
        "value": "ARBKF"
    },
    {
        "label": "OFED - Oconee Federal Financial Corp.",
        "value": "OFED"
    },
    {
        "label": "ZFOXW - ZeroFox Holdings, Inc.",
        "value": "ZFOXW"
    },
    {
        "label": "FFBW - FFBW, Inc. /MD/",
        "value": "FFBW"
    },
    {
        "label": "UBOH - UNITED BANCSHARES INC/OH",
        "value": "UBOH"
    },
    {
        "label": "YELL - Yellow Corp",
        "value": "YELL"
    },
    {
        "label": "SGTX - Sigilon Therapeutics, Inc.",
        "value": "SGTX"
    },
    {
        "label": "DEFTF - Defi Technologies, Inc.",
        "value": "DEFTF"
    },
    {
        "label": "CNNB - Cincinnati Bancorp, Inc.",
        "value": "CNNB"
    },
    {
        "label": "FBIOP - Fortress Biotech, Inc.",
        "value": "FBIOP"
    },
    {
        "label": "OXAC - Jet.AI Inc.",
        "value": "OXAC"
    },
    {
        "label": "DPRO - Draganfly Inc.",
        "value": "DPRO"
    },
    {
        "label": "CYCCP - Cyclacel Pharmaceuticals, Inc.",
        "value": "CYCCP"
    },
    {
        "label": "WVVIP - WILLAMETTE VALLEY VINEYARDS INC",
        "value": "WVVIP"
    },
    {
        "label": "NEXCF - NexTech AR Solutions Corp.",
        "value": "NEXCF"
    },
    {
        "label": "AATC - AUTOSCOPE TECHNOLOGIES CORP",
        "value": "AATC"
    },
    {
        "label": "SIOX - Sio Gene Therapies Inc.",
        "value": "SIOX"
    },
    {
        "label": "OCGSF - Outcrop Silver & Gold Corp",
        "value": "OCGSF"
    },
    {
        "label": "ACRDF - Acreage Holdings, Inc.",
        "value": "ACRDF"
    },
    {
        "label": "AFGC - AMERICAN FINANCIAL GROUP INC",
        "value": "AFGC"
    },
    {
        "label": "WSTL - WESTELL TECHNOLOGIES INC",
        "value": "WSTL"
    },
    {
        "label": "ODDAF - Odd Burger Corp",
        "value": "ODDAF"
    },
    {
        "label": "GTVI - Idaho Copper Corp",
        "value": "GTVI"
    },
    {
        "label": "PTRA - Proterra Inc",
        "value": "PTRA"
    },
    {
        "label": "TMDIF - TITAN MEDICAL INC",
        "value": "TMDIF"
    },
    {
        "label": "MINDP - MIND TECHNOLOGY, INC",
        "value": "MINDP"
    },
    {
        "label": "ALPSQ - ALPINE SUMMIT ENERGY PARTNERS, INC.",
        "value": "ALPSQ"
    },
    {
        "label": "WBSR - Webstar Technology Group Inc.",
        "value": "WBSR"
    },
    {
        "label": "BNSOF - BONSO ELECTRONICS INTERNATIONAL INC",
        "value": "BNSOF"
    },
    {
        "label": "TVE - Tennessee Valley Authority",
        "value": "TVE"
    },
    {
        "label": "WHLRD - Wheeler Real Estate Investment Trust, Inc.",
        "value": "WHLRD"
    },
    {
        "label": "SWISF - Sekur Private Data Ltd.",
        "value": "SWISF"
    },
    {
        "label": "PMEDF - Predictmedix Inc.",
        "value": "PMEDF"
    },
    {
        "label": "DFFN - CervoMed Inc.",
        "value": "DFFN"
    },
    {
        "label": "CURR - Avenir Wellness Solutions, Inc.",
        "value": "CURR"
    },
    {
        "label": "GSPT - Golden Star Enterprises Ltd.",
        "value": "GSPT"
    },
    {
        "label": "FALC - FALCONSTOR SOFTWARE INC",
        "value": "FALC"
    },
    {
        "label": "SGLDF - KIDOZ INC.",
        "value": "SGLDF"
    },
    {
        "label": "GRDAF - Guardforce AI Co., Ltd.",
        "value": "GRDAF"
    },
    {
        "label": "ADMQ - ADM ENDEAVORS, INC.",
        "value": "ADMQ"
    },
    {
        "label": "SIRC - SOLAR INTEGRATED ROOFING CORP.",
        "value": "SIRC"
    },
    {
        "label": "SICP - Silvergate Capital Corp",
        "value": "SICP"
    },
    {
        "label": "GRVE - GROOVE BOTANICALS INC.",
        "value": "GRVE"
    },
    {
        "label": "TKOI - TELKONET INC",
        "value": "TKOI"
    },
    {
        "label": "ACGN - Aceragen, Inc.",
        "value": "ACGN"
    },
    {
        "label": "ASPU - ASPEN GROUP, INC.",
        "value": "ASPU"
    },
    {
        "label": "BNKL - Bionik Laboratories Corp.",
        "value": "BNKL"
    },
    {
        "label": "RGRX - REGENERX BIOPHARMACEUTICALS INC",
        "value": "RGRX"
    },
    {
        "label": "BRLL - Barrel Energy Inc.",
        "value": "BRLL"
    },
    {
        "label": "CETXP - CEMTREX INC",
        "value": "CETXP"
    },
    {
        "label": "BTZI - BOTS, Inc./PR",
        "value": "BTZI"
    },
    {
        "label": "ENDPQ - Endo International plc",
        "value": "ENDPQ"
    },
    {
        "label": "MLLOF - Medallion Resources Ltd",
        "value": "MLLOF"
    },
    {
        "label": "EXNRF - EXCELLON RESOURCES INC",
        "value": "EXNRF"
    },
    {
        "label": "IMPM - IMPAC MORTGAGE HOLDINGS INC",
        "value": "IMPM"
    },
    {
        "label": "WSRC - WESTERN SIERRA RESOURCE Corp",
        "value": "WSRC"
    },
    {
        "label": "BDRL - BLONDER TONGUE LABORATORIES INC",
        "value": "BDRL"
    },
    {
        "label": "DBDQQ - DIEBOLD NIXDORF, Inc",
        "value": "DBDQQ"
    },
    {
        "label": "AGNPF - Algernon Pharmaceuticals Inc.",
        "value": "AGNPF"
    },
    {
        "label": "MKGP - Maverick Energy Group, Ltd.",
        "value": "MKGP"
    },
    {
        "label": "PCTL - PCT LTD",
        "value": "PCTL"
    },
    {
        "label": "APSI - AQUA POWER SYSTEMS INC.",
        "value": "APSI"
    },
    {
        "label": "GVSI - Good Vibrations Shoes, Inc.",
        "value": "GVSI"
    },
    {
        "label": "VYNT - Vyant Bio, Inc.",
        "value": "VYNT"
    },
    {
        "label": "ONCR - Oncorus, Inc.",
        "value": "ONCR"
    },
    {
        "label": "IMUC - EOM Pharmaceutical Holdings, Inc.",
        "value": "IMUC"
    },
    {
        "label": "BLEG - Branded Legacy, Inc.",
        "value": "BLEG"
    },
    {
        "label": "DROP - Fuse Science, Inc.",
        "value": "DROP"
    },
    {
        "label": "SNNC - Sibannac, Inc.",
        "value": "SNNC"
    },
    {
        "label": "CENBF - CEN BIOTECH INC",
        "value": "CENBF"
    },
    {
        "label": "RHE-PA - REGIONAL HEALTH PROPERTIES, INC",
        "value": "RHE-PA"
    },
    {
        "label": "PTEIQ - POLARITYTE, INC.",
        "value": "PTEIQ"
    },
    {
        "label": "GLUC - Glucose Health, Inc.",
        "value": "GLUC"
    },
    {
        "label": "CBTC - XTRA Bitcoin Inc.",
        "value": "CBTC"
    },
    {
        "label": "KNOS - KRONOS ADVANCED TECHNOLOGIES INC",
        "value": "KNOS"
    },
    {
        "label": "SLHGF - Skylight Health Group Inc.",
        "value": "SLHGF"
    },
    {
        "label": "PHCG - Pure Harvest Corporate Group, Inc.",
        "value": "PHCG"
    },
    {
        "label": "VERBW - Verb Technology Company, Inc.",
        "value": "VERBW"
    },
    {
        "label": "FDBL - Friendable, Inc.",
        "value": "FDBL"
    },
    {
        "label": "BAC-PL - BANK OF AMERICA CORP /DE/",
        "value": "BAC-PL"
    },
    {
        "label": "RPT-PD - RPT Realty",
        "value": "RPT-PD"
    },
    {
        "label": "MFA-PC - MFA FINANCIAL, INC.",
        "value": "MFA-PC"
    },
    {
        "label": "RBCP - RBC Bearings INC",
        "value": "RBCP"
    },
    {
        "label": "HTFC - Horizon Technology Finance Corp",
        "value": "HTFC"
    },
    {
        "label": "BPYPO - Brookfield Property Partners L.P.",
        "value": "BPYPO"
    },
    {
        "label": "BPYPM - Brookfield Property Partners L.P.",
        "value": "BPYPM"
    },
    {
        "label": "BPYPN - Brookfield Property Partners L.P.",
        "value": "BPYPN"
    },
    {
        "label": "EQCDX - Equalize Community Development Fund",
        "value": "EQCDX"
    },
    {
        "label": "SLACW - Social Leverage Acquisition Corp I",
        "value": "SLACW"
    },
    {
        "label": "SLACU - Social Leverage Acquisition Corp I",
        "value": "SLACU"
    },
    {
        "label": "NBLWF - Noble Corp plc",
        "value": "NBLWF"
    },
    {
        "label": "GECCM - Great Elm Capital Corp.",
        "value": "GECCM"
    },
    {
        "label": "GECCO - Great Elm Capital Corp.",
        "value": "GECCO"
    },
    {
        "label": "GECCN - Great Elm Capital Corp.",
        "value": "GECCN"
    },
    {
        "label": "GWH-WT - ESS Tech, Inc.",
        "value": "GWH-WT"
    },
    {
        "label": "LSEAW - Landsea Homes Corp",
        "value": "LSEAW"
    },
    {
        "label": "SCLXW - Scilex Holding Co",
        "value": "SCLXW"
    },
    {
        "label": "EACPW - Edify Acquisition Corp.",
        "value": "EACPW"
    },
    {
        "label": "BITE-WT - Bite Acquisition Corp.",
        "value": "BITE-WT"
    },
    {
        "label": "DISAW - Disruptive Acquisition Corp I",
        "value": "DISAW"
    },
    {
        "label": "XFLT-PA - XAI Octagon Floating Rate & Alternative Income Term Trust",
        "value": "XFLT-PA"
    },
    {
        "label": "ALLG-WT - Allego N.V.",
        "value": "ALLG-WT"
    },
    {
        "label": "DRTSW - Alpha Tau Medical Ltd.",
        "value": "DRTSW"
    },
    {
        "label": "LVROW - Lavoro Ltd",
        "value": "LVROW"
    },
    {
        "label": "DBRG-PJ - DigitalBridge Group, Inc.",
        "value": "DBRG-PJ"
    },
    {
        "label": "DBRG-PI - DigitalBridge Group, Inc.",
        "value": "DBRG-PI"
    },
    {
        "label": "DRMAW - Dermata Therapeutics, Inc.",
        "value": "DRMAW"
    },
    {
        "label": "CLNNW - Clene Inc.",
        "value": "CLNNW"
    },
    {
        "label": "LTCFX - Alternative Strategies Fund",
        "value": "LTCFX"
    },
    {
        "label": "PHUNW - Phunware, Inc.",
        "value": "PHUNW"
    },
    {
        "label": "HTIBP - Healthcare Trust, Inc.",
        "value": "HTIBP"
    },
    {
        "label": "BFS-PE - SAUL CENTERS, INC.",
        "value": "BFS-PE"
    },
    {
        "label": "T-PC - AT&T INC.",
        "value": "T-PC"
    },
    {
        "label": "TBC - AT&T INC.",
        "value": "TBC"
    },
    {
        "label": "T-PA - AT&T INC.",
        "value": "T-PA"
    },
    {
        "label": "SIGIP - SELECTIVE INSURANCE GROUP INC",
        "value": "SIGIP"
    },
    {
        "label": "MTB-PH - M&T BANK CORP",
        "value": "MTB-PH"
    },
    {
        "label": "ACHR-WT - Archer Aviation Inc.",
        "value": "ACHR-WT"
    },
    {
        "label": "SBBA - Scorpio Tankers Inc.",
        "value": "SBBA"
    },
    {
        "label": "OCCIO - OFS Credit Company, Inc.",
        "value": "OCCIO"
    },
    {
        "label": "OCCIN - OFS Credit Company, Inc.",
        "value": "OCCIN"
    },
    {
        "label": "RWAYZ - Runway Growth Finance Corp.",
        "value": "RWAYZ"
    },
    {
        "label": "RWAYL - Runway Growth Finance Corp.",
        "value": "RWAYL"
    },
    {
        "label": "WLLAW - Whiting Holdings LLC",
        "value": "WLLAW"
    },
    {
        "label": "HROWM - HARROW HEALTH, INC.",
        "value": "HROWM"
    },
    {
        "label": "CPER - United States Commodity Index Funds Trust",
        "value": "CPER"
    },
    {
        "label": "USCI - United States Commodity Index Funds Trust",
        "value": "USCI"
    },
    {
        "label": "COF-PK - CAPITAL ONE FINANCIAL CORP",
        "value": "COF-PK"
    },
    {
        "label": "OPP-PA - RiverNorth/DoubleLine Strategic Opportunity Fund, Inc.",
        "value": "OPP-PA"
    },
    {
        "label": "ADOCR - Edoc Acquisition Corp.",
        "value": "ADOCR"
    },
    {
        "label": "ADOCW - Edoc Acquisition Corp.",
        "value": "ADOCW"
    },
    {
        "label": "ADEX-WT - Adit EdTech Acquisition Corp.",
        "value": "ADEX-WT"
    },
    {
        "label": "DMAQR - Deep Medicine Acquisition Corp.",
        "value": "DMAQR"
    },
    {
        "label": "ARKOW - ARKO Corp.",
        "value": "ARKOW"
    },
    {
        "label": "COF-PN - CAPITAL ONE FINANCIAL CORP",
        "value": "COF-PN"
    },
    {
        "label": "COF-PJ - CAPITAL ONE FINANCIAL CORP",
        "value": "COF-PJ"
    },
    {
        "label": "FHN-PE - FIRST HORIZON CORP",
        "value": "FHN-PE"
    },
    {
        "label": "FHN-PF - FIRST HORIZON CORP",
        "value": "FHN-PF"
    },
    {
        "label": "FHN-PB - FIRST HORIZON CORP",
        "value": "FHN-PB"
    },
    {
        "label": "SNV-PE - SYNOVUS FINANCIAL CORP",
        "value": "SNV-PE"
    },
    {
        "label": "ALVOW - Alvotech",
        "value": "ALVOW"
    },
    {
        "label": "FOSLL - Fossil Group, Inc.",
        "value": "FOSLL"
    },
    {
        "label": "ADSEW - Ads-Tec Energy Public Ltd Co",
        "value": "ADSEW"
    },
    {
        "label": "BYTSW - BYTE Acquisition Corp.",
        "value": "BYTSW"
    },
    {
        "label": "BBAI-WT - BigBear.ai Holdings, Inc.",
        "value": "BBAI-WT"
    },
    {
        "label": "GROY-WT - Gold Royalty Corp.",
        "value": "GROY-WT"
    },
    {
        "label": "ETWO-WT - E2open Parent Holdings, Inc.",
        "value": "ETWO-WT"
    },
    {
        "label": "EOSEW - Eos Energy Enterprises, Inc.",
        "value": "EOSEW"
    },
    {
        "label": "KPLTW - Katapult Holdings, Inc.",
        "value": "KPLTW"
    },
    {
        "label": "JXN-PA - Jackson Financial Inc.",
        "value": "JXN-PA"
    },
    {
        "label": "AMPX-WT - Amprius Technologies, Inc.",
        "value": "AMPX-WT"
    },
    {
        "label": "GFX-WT - Golden Falcon Acquisition Corp.",
        "value": "GFX-WT"
    },
    {
        "label": "RUMBW - Rumble Inc.",
        "value": "RUMBW"
    },
    {
        "label": "PL-WT - Planet Labs PBC",
        "value": "PL-WT"
    },
    {
        "label": "ASTSW - AST SpaceMobile, Inc.",
        "value": "ASTSW"
    },
    {
        "label": "ATLCL - Atlanticus Holdings Corp",
        "value": "ATLCL"
    },
    {
        "label": "ATLCP - Atlanticus Holdings Corp",
        "value": "ATLCP"
    },
    {
        "label": "FILG - Grayscale Filecoin Trust (FIL)",
        "value": "FILG"
    },
    {
        "label": "RF-PE - REGIONS FINANCIAL CORP",
        "value": "RF-PE"
    },
    {
        "label": "EQH-PC - Equitable Holdings, Inc.",
        "value": "EQH-PC"
    },
    {
        "label": "ACR-PC - ACRES Commercial Realty Corp.",
        "value": "ACR-PC"
    },
    {
        "label": "ACR-PD - ACRES Commercial Realty Corp.",
        "value": "ACR-PD"
    },
    {
        "label": "DGP - DEUTSCHE BANK AKTIENGESELLSCHAFT",
        "value": "DGP"
    },
    {
        "label": "ALSAW - Alpha Star Acquisition Corp",
        "value": "ALSAW"
    },
    {
        "label": "GNT-PA - GAMCO Natural Resources, Gold & Income Trust",
        "value": "GNT-PA"
    },
    {
        "label": "SNAXW - STRYVE FOODS, INC.",
        "value": "SNAXW"
    },
    {
        "label": "PRIF-PK - Priority Income Fund, Inc.",
        "value": "PRIF-PK"
    },
    {
        "label": "PRIF-PJ - Priority Income Fund, Inc.",
        "value": "PRIF-PJ"
    },
    {
        "label": "PRIF-PI - Priority Income Fund, Inc.",
        "value": "PRIF-PI"
    },
    {
        "label": "RTLPP - Necessity Retail REIT, Inc.",
        "value": "RTLPP"
    },
    {
        "label": "RTLPO - Necessity Retail REIT, Inc.",
        "value": "RTLPO"
    },
    {
        "label": "POL-WT - Polished.com Inc.",
        "value": "POL-WT"
    },
    {
        "label": "SVIX - VS Trust",
        "value": "SVIX"
    },
    {
        "label": "UVIX - VS Trust",
        "value": "UVIX"
    },
    {
        "label": "SHFSW - SHF Holdings, Inc.",
        "value": "SHFSW"
    },
    {
        "label": "TMCWW - TMC the metals Co Inc.",
        "value": "TMCWW"
    },
    {
        "label": "JPM-PK - JPMORGAN CHASE & CO",
        "value": "JPM-PK"
    },
    {
        "label": "AMJ - JPMORGAN CHASE & CO",
        "value": "AMJ"
    },
    {
        "label": "JPM-PL - JPMORGAN CHASE & CO",
        "value": "JPM-PL"
    },
    {
        "label": "OPFI-WT - OppFi Inc.",
        "value": "OPFI-WT"
    },
    {
        "label": "ADVWW - Advantage Solutions Inc.",
        "value": "ADVWW"
    },
    {
        "label": "AEVA-WT - Aeva Technologies, Inc.",
        "value": "AEVA-WT"
    },
    {
        "label": "AAC-WT - Ares Acquisition Corp",
        "value": "AAC-WT"
    },
    {
        "label": "AAC-UN - Ares Acquisition Corp",
        "value": "AAC-UN"
    },
    {
        "label": "BLDEW - Blade Air Mobility, Inc.",
        "value": "BLDEW"
    },
    {
        "label": "SLND-WT - Southland Holdings, Inc.",
        "value": "SLND-WT"
    },
    {
        "label": "PMT-PC - PennyMac Mortgage Investment Trust",
        "value": "PMT-PC"
    },
    {
        "label": "RCC - Ready Capital Corp",
        "value": "RCC"
    },
    {
        "label": "RCB - Ready Capital Corp",
        "value": "RCB"
    },
    {
        "label": "STRRP - STAR EQUITY HOLDINGS, INC.",
        "value": "STRRP"
    },
    {
        "label": "BEP-PA - Brookfield Renewable Partners L.P.",
        "value": "BEP-PA"
    },
    {
        "label": "PXSAW - Pyxis Tankers Inc.",
        "value": "PXSAW"
    },
    {
        "label": "PXSAP - Pyxis Tankers Inc.",
        "value": "PXSAP"
    },
    {
        "label": "DWACW - Digital World Acquisition Corp.",
        "value": "DWACW"
    },
    {
        "label": "DWACU - Digital World Acquisition Corp.",
        "value": "DWACU"
    },
    {
        "label": "UVXY - ProShares Trust II",
        "value": "UVXY"
    },
    {
        "label": "EUO - ProShares Trust II",
        "value": "EUO"
    },
    {
        "label": "YCL - ProShares Trust II",
        "value": "YCL"
    },
    {
        "label": "SCO - ProShares Trust II",
        "value": "SCO"
    },
    {
        "label": "ULE - ProShares Trust II",
        "value": "ULE"
    },
    {
        "label": "AGQ - ProShares Trust II",
        "value": "AGQ"
    },
    {
        "label": "GLL - ProShares Trust II",
        "value": "GLL"
    },
    {
        "label": "VIXY - ProShares Trust II",
        "value": "VIXY"
    },
    {
        "label": "ZSL - ProShares Trust II",
        "value": "ZSL"
    },
    {
        "label": "BOIL - ProShares Trust II",
        "value": "BOIL"
    },
    {
        "label": "UCO - ProShares Trust II",
        "value": "UCO"
    },
    {
        "label": "VIXM - ProShares Trust II",
        "value": "VIXM"
    },
    {
        "label": "KOLD - ProShares Trust II",
        "value": "KOLD"
    },
    {
        "label": "SVXY - ProShares Trust II",
        "value": "SVXY"
    },
    {
        "label": "GAINN - GLADSTONE INVESTMENT CORPORATIONDE",
        "value": "GAINN"
    },
    {
        "label": "VAL-WT - Valaris Ltd",
        "value": "VAL-WT"
    },
    {
        "label": "MS-PP - MORGAN STANLEY",
        "value": "MS-PP"
    },
    {
        "label": "MS-PO - MORGAN STANLEY",
        "value": "MS-PO"
    },
    {
        "label": "MS-PL - MORGAN STANLEY",
        "value": "MS-PL"
    },
    {
        "label": "WTFCP - WINTRUST FINANCIAL CORP",
        "value": "WTFCP"
    },
    {
        "label": "ADC-PA - AGREE REALTY CORP",
        "value": "ADC-PA"
    },
    {
        "label": "SCCC - Sachem Capital Corp.",
        "value": "SCCC"
    },
    {
        "label": "SCCD - Sachem Capital Corp.",
        "value": "SCCD"
    },
    {
        "label": "SCCF - Sachem Capital Corp.",
        "value": "SCCF"
    },
    {
        "label": "SCCB - Sachem Capital Corp.",
        "value": "SCCB"
    },
    {
        "label": "SCCG - Sachem Capital Corp.",
        "value": "SCCG"
    },
    {
        "label": "SAJ - SARATOGA INVESTMENT CORP.",
        "value": "SAJ"
    },
    {
        "label": "SAY - SARATOGA INVESTMENT CORP.",
        "value": "SAY"
    },
    {
        "label": "SAT - SARATOGA INVESTMENT CORP.",
        "value": "SAT"
    },
    {
        "label": "NEWTZ - NewtekOne, Inc.",
        "value": "NEWTZ"
    },
    {
        "label": "NEWTL - NewtekOne, Inc.",
        "value": "NEWTL"
    },
    {
        "label": "ACAHW - Atlantic Coastal Acquisition Corp.",
        "value": "ACAHW"
    },
    {
        "label": "ENERR - ACCRETION ACQUISITION CORP.",
        "value": "ENERR"
    },
    {
        "label": "PACI-WT - PROOF Acquisition Corp I",
        "value": "PACI-WT"
    },
    {
        "label": "TFINP - Triumph Financial, Inc.",
        "value": "TFINP"
    },
    {
        "label": "NYMTZ - NEW YORK MORTGAGE TRUST INC",
        "value": "NYMTZ"
    },
    {
        "label": "NYMTM - NEW YORK MORTGAGE TRUST INC",
        "value": "NYMTM"
    },
    {
        "label": "NYMTL - NEW YORK MORTGAGE TRUST INC",
        "value": "NYMTL"
    },
    {
        "label": "PSA-PL - Public Storage",
        "value": "PSA-PL"
    },
    {
        "label": "PSA-PQ - Public Storage",
        "value": "PSA-PQ"
    },
    {
        "label": "PSA-PF - Public Storage",
        "value": "PSA-PF"
    },
    {
        "label": "PSA-PN - Public Storage",
        "value": "PSA-PN"
    },
    {
        "label": "PSA-PJ - Public Storage",
        "value": "PSA-PJ"
    },
    {
        "label": "PSA-PO - Public Storage",
        "value": "PSA-PO"
    },
    {
        "label": "PSA-PG - Public Storage",
        "value": "PSA-PG"
    },
    {
        "label": "PSA-PR - Public Storage",
        "value": "PSA-PR"
    },
    {
        "label": "PSA-PM - Public Storage",
        "value": "PSA-PM"
    },
    {
        "label": "PSA-PP - Public Storage",
        "value": "PSA-PP"
    },
    {
        "label": "MIR-WT - Mirion Technologies, Inc.",
        "value": "MIR-WT"
    },
    {
        "label": "SHNY - BANK OF MONTREAL /CAN/",
        "value": "SHNY"
    },
    {
        "label": "BNKD - BANK OF MONTREAL /CAN/",
        "value": "BNKD"
    },
    {
        "label": "FNGU - BANK OF MONTREAL /CAN/",
        "value": "FNGU"
    },
    {
        "label": "OILD - BANK OF MONTREAL /CAN/",
        "value": "OILD"
    },
    {
        "label": "WTID - BANK OF MONTREAL /CAN/",
        "value": "WTID"
    },
    {
        "label": "NRGD - BANK OF MONTREAL /CAN/",
        "value": "NRGD"
    },
    {
        "label": "FNGO - BANK OF MONTREAL /CAN/",
        "value": "FNGO"
    },
    {
        "label": "BNKU - BANK OF MONTREAL /CAN/",
        "value": "BNKU"
    },
    {
        "label": "WTIU - BANK OF MONTREAL /CAN/",
        "value": "WTIU"
    },
    {
        "label": "BULZ - BANK OF MONTREAL /CAN/",
        "value": "BULZ"
    },
    {
        "label": "FLYU - BANK OF MONTREAL /CAN/",
        "value": "FLYU"
    },
    {
        "label": "NRGU - BANK OF MONTREAL /CAN/",
        "value": "NRGU"
    },
    {
        "label": "GDXD - BANK OF MONTREAL /CAN/",
        "value": "GDXD"
    },
    {
        "label": "GDXU - BANK OF MONTREAL /CAN/",
        "value": "GDXU"
    },
    {
        "label": "FLYD - BANK OF MONTREAL /CAN/",
        "value": "FLYD"
    },
    {
        "label": "FNGS - BANK OF MONTREAL /CAN/",
        "value": "FNGS"
    },
    {
        "label": "AGM-PG - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM-PG"
    },
    {
        "label": "AGM-PE - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM-PE"
    },
    {
        "label": "AGM-PF - FEDERAL AGRICULTURAL MORTGAGE CORP",
        "value": "AGM-PF"
    },
    {
        "label": "BHIL-WT - Benson Hill, Inc.",
        "value": "BHIL-WT"
    },
    {
        "label": "BREZW - Breeze Holdings Acquisition Corp.",
        "value": "BREZW"
    },
    {
        "label": "HCMAW - HCM Acquisition Corp",
        "value": "HCMAW"
    },
    {
        "label": "CRGOW - Freightos Ltd",
        "value": "CRGOW"
    },
    {
        "label": "EGGF-WT - EG Acquisition Corp.",
        "value": "EGGF-WT"
    },
    {
        "label": "CXAIW - CXApp Inc.",
        "value": "CXAIW"
    },
    {
        "label": "ALTUW - Altitude Acquisition Corp.",
        "value": "ALTUW"
    },
    {
        "label": "VIEWW - View, Inc.",
        "value": "VIEWW"
    },
    {
        "label": "AEAEW - AltEnergy Acquisition Corp",
        "value": "AEAEW"
    },
    {
        "label": "BNGOW - Bionano Genomics, Inc.",
        "value": "BNGOW"
    },
    {
        "label": "BEEMW - Beam Global",
        "value": "BEEMW"
    },
    {
        "label": "CHSCM - CHS INC",
        "value": "CHSCM"
    },
    {
        "label": "CHSCO - CHS INC",
        "value": "CHSCO"
    },
    {
        "label": "CHSCN - CHS INC",
        "value": "CHSCN"
    },
    {
        "label": "SLNHP - Soluna Holdings, Inc",
        "value": "SLNHP"
    },
    {
        "label": "LNC-PD - LINCOLN NATIONAL CORP",
        "value": "LNC-PD"
    },
    {
        "label": "AP-WT - AMPCO PITTSBURGH CORP",
        "value": "AP-WT"
    },
    {
        "label": "HBANP - HUNTINGTON BANCSHARES INC /MD/",
        "value": "HBANP"
    },
    {
        "label": "HBANM - HUNTINGTON BANCSHARES INC /MD/",
        "value": "HBANM"
    },
    {
        "label": "RGTIW - Rigetti Computing, Inc.",
        "value": "RGTIW"
    },
    {
        "label": "PAYOW - Payoneer Global Inc.",
        "value": "PAYOW"
    },
    {
        "label": "ECXWW - ECARX Holdings Inc.",
        "value": "ECXWW"
    },
    {
        "label": "KREF-PA - KKR Real Estate Finance Trust Inc.",
        "value": "KREF-PA"
    },
    {
        "label": "TALKW - Talkspace, Inc.",
        "value": "TALKW"
    },
    {
        "label": "LVOXW - LiveVox Holdings, Inc.",
        "value": "LVOXW"
    },
    {
        "label": "ASTLW - Algoma Steel Group Inc.",
        "value": "ASTLW"
    },
    {
        "label": "GGROW - Gogoro Inc.",
        "value": "GGROW"
    },
    {
        "label": "PGYWW - Pagaya Technologies Ltd.",
        "value": "PGYWW"
    },
    {
        "label": "CELUW - Celularity Inc",
        "value": "CELUW"
    },
    {
        "label": "TVIXF - CREDIT SUISSE AG",
        "value": "TVIXF"
    },
    {
        "label": "USLVF - CREDIT SUISSE AG",
        "value": "USLVF"
    },
    {
        "label": "USOI - CREDIT SUISSE AG",
        "value": "USOI"
    },
    {
        "label": "UGLDF - CREDIT SUISSE AG",
        "value": "UGLDF"
    },
    {
        "label": "GLDI - CREDIT SUISSE AG",
        "value": "GLDI"
    },
    {
        "label": "AHL-PE - ASPEN INSURANCE HOLDINGS LTD",
        "value": "AHL-PE"
    },
    {
        "label": "CHKEL - CHESAPEAKE ENERGY CORP",
        "value": "CHKEL"
    },
    {
        "label": "LFT-PA - Lument Finance Trust, Inc.",
        "value": "LFT-PA"
    },
    {
        "label": "JOBY-WT - Joby Aviation, Inc.",
        "value": "JOBY-WT"
    },
    {
        "label": "OUST-WT - Ouster, Inc.",
        "value": "OUST-WT"
    },
    {
        "label": "OUST-WTA - Ouster, Inc.",
        "value": "OUST-WTA"
    },
    {
        "label": "GB-WT - Global Blue Group Holding AG",
        "value": "GB-WT"
    },
    {
        "label": "BTWNW - Bridgetown Holdings Ltd",
        "value": "BTWNW"
    },
    {
        "label": "EVE-WT - EVe Mobility Acquisition Corp",
        "value": "EVE-WT"
    },
    {
        "label": "HFRO-PA - HIGHLAND OPPORTUNITIES & INCOME FUND",
        "value": "HFRO-PA"
    },
    {
        "label": "XOMAP - XOMA Corp",
        "value": "XOMAP"
    },
    {
        "label": "XOMAO - XOMA Corp",
        "value": "XOMAO"
    },
    {
        "label": "AFGD - AMERICAN FINANCIAL GROUP INC",
        "value": "AFGD"
    },
    {
        "label": "AFGE - AMERICAN FINANCIAL GROUP INC",
        "value": "AFGE"
    },
    {
        "label": "AFGB - AMERICAN FINANCIAL GROUP INC",
        "value": "AFGB"
    },
    {
        "label": "GDL-PC - GDL FUND",
        "value": "GDL-PC"
    },
    {
        "label": "XELAP - Exela Technologies, Inc.",
        "value": "XELAP"
    },
    {
        "label": "EFC-PB - Ellington Financial Inc.",
        "value": "EFC-PB"
    },
    {
        "label": "EFC-PA - Ellington Financial Inc.",
        "value": "EFC-PA"
    },
    {
        "label": "EFC-PC - Ellington Financial Inc.",
        "value": "EFC-PC"
    },
    {
        "label": "SEAL-PA - Seapeak LLC",
        "value": "SEAL-PA"
    },
    {
        "label": "WFC-PA - WELLS FARGO & COMPANY/MN",
        "value": "WFC-PA"
    },
    {
        "label": "DDT - DILLARD'S, INC.",
        "value": "DDT"
    },
    {
        "label": "BPOPM - POPULAR, INC.",
        "value": "BPOPM"
    },
    {
        "label": "WFC-PD - WELLS FARGO & COMPANY/MN",
        "value": "WFC-PD"
    },
    {
        "label": "SUNL-WT - Sunlight Financial Holdings Inc.",
        "value": "SUNL-WT"
    },
    {
        "label": "LGHLW - Lion Group Holding Ltd",
        "value": "LGHLW"
    },
    {
        "label": "DATSW - DatChat, Inc.",
        "value": "DATSW"
    },
    {
        "label": "RNR-PG - RENAISSANCERE HOLDINGS LTD",
        "value": "RNR-PG"
    },
    {
        "label": "WBS-PG - WEBSTER FINANCIAL CORP",
        "value": "WBS-PG"
    },
    {
        "label": "LFMDP - LifeMD, Inc.",
        "value": "LFMDP"
    },
    {
        "label": "NWTNW - NWTN, Inc.",
        "value": "NWTNW"
    },
    {
        "label": "CORN - Teucrium Commodity Trust",
        "value": "CORN"
    },
    {
        "label": "TAGS - Teucrium Commodity Trust",
        "value": "TAGS"
    },
    {
        "label": "DEFI - Teucrium Commodity Trust",
        "value": "DEFI"
    },
    {
        "label": "CANE - Teucrium Commodity Trust",
        "value": "CANE"
    },
    {
        "label": "WEAT - Teucrium Commodity Trust",
        "value": "WEAT"
    },
    {
        "label": "SOYB - Teucrium Commodity Trust",
        "value": "SOYB"
    },
    {
        "label": "BFIIW - BurgerFi International, Inc.",
        "value": "BFIIW"
    },
    {
        "label": "AMRLF - American Lithium Corp.",
        "value": "AMRLF"
    },
    {
        "label": "HMLPF - Hoegh LNG Partners LP",
        "value": "HMLPF"
    },
    {
        "label": "FTAIO - FTAI Aviation Ltd.",
        "value": "FTAIO"
    },
    {
        "label": "FTAIP - FTAI Aviation Ltd.",
        "value": "FTAIP"
    },
    {
        "label": "FTAIM - FTAI Aviation Ltd.",
        "value": "FTAIM"
    },
    {
        "label": "FTAIN - FTAI Aviation Ltd.",
        "value": "FTAIN"
    },
    {
        "label": "CNFRL - Conifer Holdings, Inc.",
        "value": "CNFRL"
    },
    {
        "label": "SABSW - SAB Biotherapeutics, Inc.",
        "value": "SABSW"
    },
    {
        "label": "METXW - BTC Digital Ltd.",
        "value": "METXW"
    },
    {
        "label": "XOSWW - Xos, Inc.",
        "value": "XOSWW"
    },
    {
        "label": "HPLTW - Home Plate Acquisition Corp",
        "value": "HPLTW"
    },
    {
        "label": "LUCYW - Innovative Eyewear Inc",
        "value": "LUCYW"
    },
    {
        "label": "HUBCW - Hub Cyber Security Ltd.",
        "value": "HUBCW"
    },
    {
        "label": "SMXWW - SMX (Security Matters) Public Ltd Co",
        "value": "SMXWW"
    },
    {
        "label": "BOH-PA - BANK OF HAWAII CORP",
        "value": "BOH-PA"
    },
    {
        "label": "SVIIU - Spring Valley Acquisition Corp. II",
        "value": "SVIIU"
    },
    {
        "label": "XPDBW - Power & Digital Infrastructure Acquisition II Corp.",
        "value": "XPDBW"
    },
    {
        "label": "CFG-PE - CITIZENS FINANCIAL GROUP INC/RI",
        "value": "CFG-PE"
    },
    {
        "label": "PRETL - PENNSYLVANIA REAL ESTATE INVESTMENT TRUST",
        "value": "PRETL"
    },
    {
        "label": "PRETM - PENNSYLVANIA REAL ESTATE INVESTMENT TRUST",
        "value": "PRETM"
    },
    {
        "label": "TFC-PO - TRUIST FINANCIAL CORP",
        "value": "TFC-PO"
    },
    {
        "label": "TFC-PI - TRUIST FINANCIAL CORP",
        "value": "TFC-PI"
    },
    {
        "label": "TFC-PR - TRUIST FINANCIAL CORP",
        "value": "TFC-PR"
    },
    {
        "label": "SCE-PL - SOUTHERN CALIFORNIA EDISON Co",
        "value": "SCE-PL"
    },
    {
        "label": "SCE-PK - SOUTHERN CALIFORNIA EDISON Co",
        "value": "SCE-PK"
    },
    {
        "label": "PETWW - Wag! Group Co.",
        "value": "PETWW"
    },
    {
        "label": "OPAD-WT - Offerpad Solutions Inc.",
        "value": "OPAD-WT"
    },
    {
        "label": "RDW-WT - Redwire Corp",
        "value": "RDW-WT"
    },
    {
        "label": "AGBAW - AGBA Group Holding Ltd.",
        "value": "AGBAW"
    },
    {
        "label": "RBOT-WT - Vicarious Surgical Inc.",
        "value": "RBOT-WT"
    },
    {
        "label": "GMVDW - G Medical Innovations Holdings Ltd.",
        "value": "GMVDW"
    },
    {
        "label": "IMPPP - Imperial Petroleum Inc./Marshall Islands",
        "value": "IMPPP"
    },
    {
        "label": "RCFA-WT - RCF Acquisition Corp.",
        "value": "RCFA-WT"
    },
    {
        "label": "BWSN - Babcock & Wilcox Enterprises, Inc.",
        "value": "BWSN"
    },
    {
        "label": "TGH-PB - TEXTAINER GROUP HOLDINGS LTD",
        "value": "TGH-PB"
    },
    {
        "label": "AAM-PB - Apollo Asset Management, Inc.",
        "value": "AAM-PB"
    },
    {
        "label": "AAM-PA - Apollo Asset Management, Inc.",
        "value": "AAM-PA"
    },
    {
        "label": "OTRKP - Ontrak, Inc.",
        "value": "OTRKP"
    },
    {
        "label": "CIVIW - CIVITAS RESOURCES, INC.",
        "value": "CIVIW"
    },
    {
        "label": "SLNAW - SELINA HOSPITALITY PLC",
        "value": "SLNAW"
    },
    {
        "label": "OCSAW - Oculis Holding AG",
        "value": "OCSAW"
    },
    {
        "label": "PCGU - PG&E Corp",
        "value": "PCGU"
    },
    {
        "label": "RILYM - B. Riley Financial, Inc.",
        "value": "RILYM"
    },
    {
        "label": "RILYP - B. Riley Financial, Inc.",
        "value": "RILYP"
    },
    {
        "label": "RILYG - B. Riley Financial, Inc.",
        "value": "RILYG"
    },
    {
        "label": "RILYN - B. Riley Financial, Inc.",
        "value": "RILYN"
    },
    {
        "label": "RILYL - B. Riley Financial, Inc.",
        "value": "RILYL"
    },
    {
        "label": "RILYZ - B. Riley Financial, Inc.",
        "value": "RILYZ"
    },
    {
        "label": "RILYT - B. Riley Financial, Inc.",
        "value": "RILYT"
    },
    {
        "label": "NETC-WT - Nabors Energy Transition Corp.",
        "value": "NETC-WT"
    },
    {
        "label": "BAC-PN - BANK OF AMERICA CORP /DE/",
        "value": "BAC-PN"
    },
    {
        "label": "BAC-PP - BANK OF AMERICA CORP /DE/",
        "value": "BAC-PP"
    },
    {
        "label": "BAC-PO - BANK OF AMERICA CORP /DE/",
        "value": "BAC-PO"
    },
    {
        "label": "BAC-PM - BANK OF AMERICA CORP /DE/",
        "value": "BAC-PM"
    },
    {
        "label": "ZIONP - ZIONS BANCORPORATION, NATIONAL ASSOCIATION /UT/",
        "value": "ZIONP"
    },
    {
        "label": "ZIONL - ZIONS BANCORPORATION, NATIONAL ASSOCIATION /UT/",
        "value": "ZIONL"
    },
    {
        "label": "ZIONO - ZIONS BANCORPORATION, NATIONAL ASSOCIATION /UT/",
        "value": "ZIONO"
    },
    {
        "label": "DCOMP - Dime Community Bancshares, Inc. /NY/",
        "value": "DCOMP"
    },
    {
        "label": "UZE - UNITED STATES CELLULAR CORP",
        "value": "UZE"
    },
    {
        "label": "UZF - UNITED STATES CELLULAR CORP",
        "value": "UZF"
    },
    {
        "label": "PARAP - Paramount Global",
        "value": "PARAP"
    },
    {
        "label": "AIMDW - Ainos, Inc.",
        "value": "AIMDW"
    },
    {
        "label": "OCFCP - OCEANFIRST FINANCIAL CORP",
        "value": "OCFCP"
    },
    {
        "label": "SYF-PA - Synchrony Financial",
        "value": "SYF-PA"
    },
    {
        "label": "DBGIW - Digital Brands Group, Inc.",
        "value": "DBGIW"
    },
    {
        "label": "MCAGR - Mountain Crest Acquisition Corp. V",
        "value": "MCAGR"
    },
    {
        "label": "CVII-WT - Churchill Capital Corp VII",
        "value": "CVII-WT"
    },
    {
        "label": "HTOOW - Fusion Fuel Green PLC",
        "value": "HTOOW"
    },
    {
        "label": "SPKX - ConvexityShares Trust",
        "value": "SPKX"
    },
    {
        "label": "SPKY - ConvexityShares Trust",
        "value": "SPKY"
    },
    {
        "label": "INVZW - Innoviz Technologies Ltd.",
        "value": "INVZW"
    },
    {
        "label": "CLBTW - Cellebrite DI Ltd.",
        "value": "CLBTW"
    },
    {
        "label": "PEB-PH - Pebblebrook Hotel Trust",
        "value": "PEB-PH"
    },
    {
        "label": "SABRP - Sabre Corp",
        "value": "SABRP"
    },
    {
        "label": "AUUDW - AUDDIA INC.",
        "value": "AUUDW"
    },
    {
        "label": "GOVXW - GeoVax Labs, Inc.",
        "value": "GOVXW"
    },
    {
        "label": "C-PN - CITIGROUP INC",
        "value": "C-PN"
    },
    {
        "label": "UGIC - UGI CORP /PA/",
        "value": "UGIC"
    },
    {
        "label": "HTLFP - HEARTLAND FINANCIAL USA INC",
        "value": "HTLFP"
    },
    {
        "label": "NXPLW - NextPlat Corp",
        "value": "NXPLW"
    },
    {
        "label": "ARGO-PA - Argo Group International Holdings, Ltd.",
        "value": "ARGO-PA"
    },
    {
        "label": "BWBBP - Bridgewater Bancshares Inc",
        "value": "BWBBP"
    },
    {
        "label": "VNO-PO - VORNADO REALTY TRUST",
        "value": "VNO-PO"
    },
    {
        "label": "AGNCP - AGNC Investment Corp.",
        "value": "AGNCP"
    },
    {
        "label": "AGNCL - AGNC Investment Corp.",
        "value": "AGNCL"
    },
    {
        "label": "AGNCO - AGNC Investment Corp.",
        "value": "AGNCO"
    },
    {
        "label": "DHCNL - DIVERSIFIED HEALTHCARE TRUST",
        "value": "DHCNL"
    },
    {
        "label": "IWML - UBS AG",
        "value": "IWML"
    },
    {
        "label": "MLPB - UBS AG",
        "value": "MLPB"
    },
    {
        "label": "BDCX - UBS AG",
        "value": "BDCX"
    },
    {
        "label": "CEFD - UBS AG",
        "value": "CEFD"
    },
    {
        "label": "MLPR - UBS AG",
        "value": "MLPR"
    },
    {
        "label": "MVRL - UBS AG",
        "value": "MVRL"
    },
    {
        "label": "HDLB - UBS AG",
        "value": "HDLB"
    },
    {
        "label": "IFED - UBS AG",
        "value": "IFED"
    },
    {
        "label": "QULL - UBS AG",
        "value": "QULL"
    },
    {
        "label": "BDCZ - UBS AG",
        "value": "BDCZ"
    },
    {
        "label": "AMUB - UBS AG",
        "value": "AMUB"
    },
    {
        "label": "UCIB - UBS AG",
        "value": "UCIB"
    },
    {
        "label": "SMHB - UBS AG",
        "value": "SMHB"
    },
    {
        "label": "SCDL - UBS AG",
        "value": "SCDL"
    },
    {
        "label": "FBGX - UBS AG",
        "value": "FBGX"
    },
    {
        "label": "BCDAW - BioCardia, Inc.",
        "value": "BCDAW"
    },
    {
        "label": "HWM-P - Howmet Aerospace Inc.",
        "value": "HWM-P"
    },
    {
        "label": "KEY-PL - KEYCORP /NEW/",
        "value": "KEY-PL"
    },
    {
        "label": "PFXNZ - PhenixFIN Corp",
        "value": "PFXNZ"
    },
    {
        "label": "VIASP - Via Renewables, Inc.",
        "value": "VIASP"
    },
    {
        "label": "BIP-PB - Brookfield Infrastructure Partners L.P.",
        "value": "BIP-PB"
    },
    {
        "label": "WLDSW - Wearable Devices Ltd.",
        "value": "WLDSW"
    },
    {
        "label": "OPINL - OFFICE PROPERTIES INCOME TRUST",
        "value": "OPINL"
    },
    {
        "label": "LUNRW - Intuitive Machines, Inc.",
        "value": "LUNRW"
    },
    {
        "label": "BYNOW - byNordic Acquisition Corp",
        "value": "BYNOW"
    },
    {
        "label": "LLAP-WT - Terran Orbital Corp",
        "value": "LLAP-WT"
    },
    {
        "label": "DNA-WT - Ginkgo Bioworks Holdings, Inc.",
        "value": "DNA-WT"
    },
    {
        "label": "CDROW - Codere Online Luxembourg, S.A.",
        "value": "CDROW"
    },
    {
        "label": "UKOMW - Ucommune International Ltd",
        "value": "UKOMW"
    },
    {
        "label": "ACONW - Aclarion, Inc.",
        "value": "ACONW"
    },
    {
        "label": "ADNWW - ADVENT TECHNOLOGIES HOLDINGS, INC.",
        "value": "ADNWW"
    },
    {
        "label": "AJXA - Great Ajax Corp.",
        "value": "AJXA"
    },
    {
        "label": "MYPSW - PLAYSTUDIOS, Inc.",
        "value": "MYPSW"
    },
    {
        "label": "DLR-PL - DIGITAL REALTY TRUST, INC.",
        "value": "DLR-PL"
    },
    {
        "label": "ET-PC - Energy Transfer LP",
        "value": "ET-PC"
    },
    {
        "label": "ET-PE - Energy Transfer LP",
        "value": "ET-PE"
    },
    {
        "label": "ET-PD - Energy Transfer LP",
        "value": "ET-PD"
    },
    {
        "label": "WAL-PA - WESTERN ALLIANCE BANCORPORATION",
        "value": "WAL-PA"
    },
    {
        "label": "PSEC-PA - PROSPECT CAPITAL CORP",
        "value": "PSEC-PA"
    },
    {
        "label": "GDV-PK - GABELLI DIVIDEND & INCOME TRUST",
        "value": "GDV-PK"
    },
    {
        "label": "FGBIP - First Guaranty Bancshares, Inc.",
        "value": "FGBIP"
    },
    {
        "label": "MCOMW - micromobility.com Inc.",
        "value": "MCOMW"
    },
    {
        "label": "IINNW - Inspira Technologies OXY B.H.N. Ltd",
        "value": "IINNW"
    },
    {
        "label": "NVVEW - Nuvve Holding Corp.",
        "value": "NVVEW"
    },
    {
        "label": "OXLCP - Oxford Lane Capital Corp.",
        "value": "OXLCP"
    },
    {
        "label": "OXLCN - Oxford Lane Capital Corp.",
        "value": "OXLCN"
    },
    {
        "label": "OXLCZ - Oxford Lane Capital Corp.",
        "value": "OXLCZ"
    },
    {
        "label": "MBINO - Merchants Bancorp",
        "value": "MBINO"
    },
    {
        "label": "MBINM - Merchants Bancorp",
        "value": "MBINM"
    },
    {
        "label": "MBINN - Merchants Bancorp",
        "value": "MBINN"
    },
    {
        "label": "SHCRW - Sharecare, Inc.",
        "value": "SHCRW"
    },
    {
        "label": "WALDW - Waldencast plc",
        "value": "WALDW"
    },
    {
        "label": "LVWR-WT - LiveWire Group, Inc.",
        "value": "LVWR-WT"
    },
    {
        "label": "GL-PD - GLOBE LIFE INC.",
        "value": "GL-PD"
    },
    {
        "label": "FNMAP - FEDERAL NATIONAL MORTGAGE ASSOCIATION FANNIE MAE",
        "value": "FNMAP"
    },
    {
        "label": "SCHW-PJ - SCHWAB CHARLES CORP",
        "value": "SCHW-PJ"
    },
    {
        "label": "MET-PF - METLIFE INC",
        "value": "MET-PF"
    },
    {
        "label": "MVLAW - Movella Holdings Inc.",
        "value": "MVLAW"
    },
    {
        "label": "APXIW - APx Acquisition Corp. I",
        "value": "APXIW"
    },
    {
        "label": "FCRX - Crescent Capital BDC, Inc.",
        "value": "FCRX"
    },
    {
        "label": "UWMC-WT - UWM Holdings Corp",
        "value": "UWMC-WT"
    },
    {
        "label": "AEPPZ - AMERICAN ELECTRIC POWER CO INC",
        "value": "AEPPZ"
    },
    {
        "label": "PRSRU - Prospector Capital Corp.",
        "value": "PRSRU"
    },
    {
        "label": "PRSRW - Prospector Capital Corp.",
        "value": "PRSRW"
    },
    {
        "label": "ANGHW - Anghami Inc",
        "value": "ANGHW"
    },
    {
        "label": "BDRY - ETF Managers Group Commodity Trust I",
        "value": "BDRY"
    },
    {
        "label": "BKSY-WT - BlackSky Technology Inc.",
        "value": "BKSY-WT"
    },
    {
        "label": "PCTTW - PureCycle Technologies, Inc.",
        "value": "PCTTW"
    },
    {
        "label": "LIDRW - AEye, Inc.",
        "value": "LIDRW"
    },
    {
        "label": "SST-WT - System1, Inc.",
        "value": "SST-WT"
    },
    {
        "label": "BEATW - HeartBeam, Inc.",
        "value": "BEATW"
    },
    {
        "label": "CCLDO - CareCloud, Inc.",
        "value": "CCLDO"
    },
    {
        "label": "CCLDP - CareCloud, Inc.",
        "value": "CCLDP"
    },
    {
        "label": "CIFRW - Cipher Mining Inc.",
        "value": "CIFRW"
    },
    {
        "label": "ECC-PD - Eagle Point Credit Co Inc.",
        "value": "ECC-PD"
    },
    {
        "label": "ECCW - Eagle Point Credit Co Inc.",
        "value": "ECCW"
    },
    {
        "label": "MNSBP - MainStreet Bancshares, Inc.",
        "value": "MNSBP"
    },
    {
        "label": "FATH-WT - Fathom Digital Manufacturing Corp",
        "value": "FATH-WT"
    },
    {
        "label": "ROSEW - Rose Hill Acquisition Corp",
        "value": "ROSEW"
    },
    {
        "label": "HUDAR - Hudson Acquisition I Corp.",
        "value": "HUDAR"
    },
    {
        "label": "SCRMW - Screaming Eagle Acquisition Corp.",
        "value": "SCRMW"
    },
    {
        "label": "LEV-WT - Lion Electric Co",
        "value": "LEV-WT"
    },
    {
        "label": "GAB-PK - GABELLI EQUITY TRUST INC",
        "value": "GAB-PK"
    },
    {
        "label": "PRE-PJ - PARTNERRE LTD",
        "value": "PRE-PJ"
    },
    {
        "label": "PRH - PRUDENTIAL FINANCIAL INC",
        "value": "PRH"
    },
    {
        "label": "PRS - PRUDENTIAL FINANCIAL INC",
        "value": "PRS"
    },
    {
        "label": "SPE-PC - SPECIAL OPPORTUNITIES FUND, INC.",
        "value": "SPE-PC"
    },
    {
        "label": "KMPB - KEMPER Corp",
        "value": "KMPB"
    },
    {
        "label": "ONBPP - OLD NATIONAL BANCORP /IN/",
        "value": "ONBPP"
    },
    {
        "label": "AQNA - ALGONQUIN POWER & UTILITIES CORP.",
        "value": "AQNA"
    },
    {
        "label": "AQNB - ALGONQUIN POWER & UTILITIES CORP.",
        "value": "AQNB"
    },
    {
        "label": "DUKB - Duke Energy CORP",
        "value": "DUKB"
    },
    {
        "label": "ASBA - ASSOCIATED BANC-CORP",
        "value": "ASBA"
    },
    {
        "label": "TBLAW - Taboola.com Ltd.",
        "value": "TBLAW"
    },
    {
        "label": "F-PB - FORD MOTOR CO",
        "value": "F-PB"
    },
    {
        "label": "F-PD - FORD MOTOR CO",
        "value": "F-PD"
    },
    {
        "label": "F-PC - FORD MOTOR CO",
        "value": "F-PC"
    },
    {
        "label": "DFLIW - Dragonfly Energy Holdings Corp.",
        "value": "DFLIW"
    },
    {
        "label": "ROIVW - Roivant Sciences Ltd.",
        "value": "ROIVW"
    },
    {
        "label": "HWCPZ - HANCOCK WHITNEY CORP",
        "value": "HWCPZ"
    },
    {
        "label": "AEFC - AEGON NV",
        "value": "AEFC"
    },
    {
        "label": "RVPHW - REVIVA PHARMACEUTICALS HOLDINGS, INC.",
        "value": "RVPHW"
    },
    {
        "label": "CMAXW - CareMax, Inc.",
        "value": "CMAXW"
    },
    {
        "label": "GEGGL - Great Elm Group, Inc.",
        "value": "GEGGL"
    },
    {
        "label": "PRSTW - Presto Automation Inc.",
        "value": "PRSTW"
    },
    {
        "label": "CETUW - Cetus Capital Acquisition Corp.",
        "value": "CETUW"
    },
    {
        "label": "GLFC - Gold Flora Corp.",
        "value": "GLFC"
    },
    {
        "label": "HPKEW - HighPeak Energy, Inc.",
        "value": "HPKEW"
    },
    {
        "label": "NRXPW - NRX Pharmaceuticals, Inc.",
        "value": "NRXPW"
    },
    {
        "label": "EFTRW - eFFECTOR Therapeutics, Inc.",
        "value": "EFTRW"
    },
    {
        "label": "MCAFR - Mountain Crest Acquisition Corp. IV",
        "value": "MCAFR"
    },
    {
        "label": "BFRGW - BullFrog AI Holdings, Inc.",
        "value": "BFRGW"
    },
    {
        "label": "WBX-WT - Wallbox N.V.",
        "value": "WBX-WT"
    },
    {
        "label": "ATCOL - Atlas Corp.",
        "value": "ATCOL"
    },
    {
        "label": "ATCO-PH - Atlas Corp.",
        "value": "ATCO-PH"
    },
    {
        "label": "ATCO-PI - Atlas Corp.",
        "value": "ATCO-PI"
    },
    {
        "label": "GSMGW - GLORY STAR NEW MEDIA GROUP HOLDINGS Ltd",
        "value": "GSMGW"
    },
    {
        "label": "SLGCW - SomaLogic, Inc.",
        "value": "SLGCW"
    },
    {
        "label": "TDW-WT - TIDEWATER INC",
        "value": "TDW-WT"
    },
    {
        "label": "CCV-WT - Churchill Capital Corp V",
        "value": "CCV-WT"
    },
    {
        "label": "VGASW - Verde Clean Fuels, Inc.",
        "value": "VGASW"
    },
    {
        "label": "UNOV - Earth Science Tech, Inc.",
        "value": "UNOV"
    },
    {
        "label": "AWINW - AERWINS Technologies Inc.",
        "value": "AWINW"
    },
    {
        "label": "AACIW - Armada Acquisition Corp. I",
        "value": "AACIW"
    },
    {
        "label": "REXR-PC - Rexford Industrial Realty, Inc.",
        "value": "REXR-PC"
    },
    {
        "label": "LTRYW - Lottery.com Inc.",
        "value": "LTRYW"
    },
    {
        "label": "EPR-PC - EPR PROPERTIES",
        "value": "EPR-PC"
    },
    {
        "label": "EPR-PE - EPR PROPERTIES",
        "value": "EPR-PE"
    },
    {
        "label": "NYCB-PU - NEW YORK COMMUNITY BANCORP INC",
        "value": "NYCB-PU"
    },
    {
        "label": "FLRAP - FLUOR CORP",
        "value": "FLRAP"
    },
    {
        "label": "SRCU - SPIRE INC",
        "value": "SRCU"
    },
    {
        "label": "CNO-PA - CNO Financial Group, Inc.",
        "value": "CNO-PA"
    },
    {
        "label": "PLTNW - Plutonian Acquisition Corp.",
        "value": "PLTNW"
    },
    {
        "label": "QVCC - QVC INC",
        "value": "QVCC"
    },
    {
        "label": "NLY-PI - ANNALY CAPITAL MANAGEMENT INC",
        "value": "NLY-PI"
    },
    {
        "label": "RWT-PA - REDWOOD TRUST INC",
        "value": "RWT-PA"
    },
    {
        "label": "ACGLN - ARCH CAPITAL GROUP LTD.",
        "value": "ACGLN"
    },
    {
        "label": "APCXW - AppTech Payments Corp.",
        "value": "APCXW"
    },
    {
        "label": "MOBQW - Mobiquity Technologies, Inc.",
        "value": "MOBQW"
    },
    {
        "label": "PEGRW - Project Energy Reimagined Acquisition Corp.",
        "value": "PEGRW"
    },
    {
        "label": "BLEUR - bleuacacia ltd",
        "value": "BLEUR"
    },
    {
        "label": "ALTG-PA - ALTA EQUIPMENT GROUP INC.",
        "value": "ALTG-PA"
    },
    {
        "label": "ARIZW - Arisz Acquisition Corp.",
        "value": "ARIZW"
    },
    {
        "label": "ACUT - Accustem Sciences Inc.",
        "value": "ACUT"
    },
    {
        "label": "VLN-WT - Valens Semiconductor Ltd.",
        "value": "VLN-WT"
    },
    {
        "label": "NVACR - NorthView Acquisition Corp",
        "value": "NVACR"
    },
    {
        "label": "FREY-WT - FREYR Battery",
        "value": "FREY-WT"
    },
    {
        "label": "INDIW - indie Semiconductor, Inc.",
        "value": "INDIW"
    },
    {
        "label": "GPACW - Global Partner Acquisition Corp II",
        "value": "GPACW"
    },
    {
        "label": "AUVIP - Applied UV, Inc.",
        "value": "AUVIP"
    },
    {
        "label": "HCDIP - Harbor Custom Development, Inc.",
        "value": "HCDIP"
    },
    {
        "label": "NIOBW - NIOCORP DEVELOPMENTS LTD",
        "value": "NIOBW"
    },
    {
        "label": "HSCSW - Heart Test Laboratories, Inc.",
        "value": "HSCSW"
    },
    {
        "label": "ACP-PA - abrdn Income Credit Strategies Fund",
        "value": "ACP-PA"
    },
    {
        "label": "ZEV-WT - Lightning eMotors, Inc.",
        "value": "ZEV-WT"
    },
    {
        "label": "CVE-WT - CENOVUS ENERGY INC.",
        "value": "CVE-WT"
    },
    {
        "label": "LILMW - Lilium N.V.",
        "value": "LILMW"
    },
    {
        "label": "APGNW - Apexigen, Inc.",
        "value": "APGNW"
    },
    {
        "label": "SBFMW - Sunshine Biopharma, Inc",
        "value": "SBFMW"
    },
    {
        "label": "AGRIW - AGRIFORCE GROWING SYSTEMS LTD.",
        "value": "AGRIW"
    },
    {
        "label": "SMR-WT - NUSCALE POWER Corp",
        "value": "SMR-WT"
    },
    {
        "label": "FLME-WT - Flame Acquisition Corp.",
        "value": "FLME-WT"
    },
    {
        "label": "RITM-PB - Rithm Capital Corp.",
        "value": "RITM-PB"
    },
    {
        "label": "RITM-PD - Rithm Capital Corp.",
        "value": "RITM-PD"
    },
    {
        "label": "RITM-PA - Rithm Capital Corp.",
        "value": "RITM-PA"
    },
    {
        "label": "RITM-PC - Rithm Capital Corp.",
        "value": "RITM-PC"
    },
    {
        "label": "BURU-WT - Nuburu, Inc.",
        "value": "BURU-WT"
    },
    {
        "label": "CHRB - Charah Solutions, Inc.",
        "value": "CHRB"
    },
    {
        "label": "CSSEN - Chicken Soup for the Soul Entertainment, Inc.",
        "value": "CSSEN"
    },
    {
        "label": "AFRIW - Forafric Global PLC",
        "value": "AFRIW"
    },
    {
        "label": "NTRSO - NORTHERN TRUST CORP",
        "value": "NTRSO"
    },
    {
        "label": "BC-PB - BRUNSWICK CORP",
        "value": "BC-PB"
    },
    {
        "label": "BC-PA - BRUNSWICK CORP",
        "value": "BC-PA"
    },
    {
        "label": "BC-PC - BRUNSWICK CORP",
        "value": "BC-PC"
    },
    {
        "label": "DCFCW - Tritium DCFC Ltd",
        "value": "DCFCW"
    },
    {
        "label": "EVTL-WT - Vertical Aerospace Ltd.",
        "value": "EVTL-WT"
    },
    {
        "label": "PSFE-WT - Paysafe Ltd",
        "value": "PSFE-WT"
    },
    {
        "label": "QRTEP - Qurate Retail, Inc.",
        "value": "QRTEP"
    },
    {
        "label": "AIZN - ASSURANT, INC.",
        "value": "AIZN"
    },
    {
        "label": "GNL-PB - Global Net Lease, Inc.",
        "value": "GNL-PB"
    },
    {
        "label": "BRDS-WT - Bird Global, Inc.",
        "value": "BRDS-WT"
    },
    {
        "label": "MTEKW - Maris Tech Ltd.",
        "value": "MTEKW"
    },
    {
        "label": "BANFP - BANCFIRST CORP /OK/",
        "value": "BANFP"
    },
    {
        "label": "CMSD - CMS ENERGY CORP",
        "value": "CMSD"
    },
    {
        "label": "CMS-PC - CMS ENERGY CORP",
        "value": "CMS-PC"
    },
    {
        "label": "SIVPQ - SVB FINANCIAL GROUP",
        "value": "SIVPQ"
    },
    {
        "label": "FITBP - FIFTH THIRD BANCORP",
        "value": "FITBP"
    },
    {
        "label": "FITBO - FIFTH THIRD BANCORP",
        "value": "FITBO"
    },
    {
        "label": "RJF-PB - RAYMOND JAMES FINANCIAL INC",
        "value": "RJF-PB"
    },
    {
        "label": "AAIN - Arlington Asset Investment Corp.",
        "value": "AAIN"
    },
    {
        "label": "AAIC-PB - Arlington Asset Investment Corp.",
        "value": "AAIC-PB"
    },
    {
        "label": "AAIC-PC - Arlington Asset Investment Corp.",
        "value": "AAIC-PC"
    },
    {
        "label": "AESC - AES CORP",
        "value": "AESC"
    },
    {
        "label": "REVBW - REVELATION BIOSCIENCES, INC.",
        "value": "REVBW"
    },
    {
        "label": "TELZ - TELLURIAN INC. /DE/",
        "value": "TELZ"
    },
    {
        "label": "DJP - BARCLAYS BANK PLC",
        "value": "DJP"
    },
    {
        "label": "VXZ - BARCLAYS BANK PLC",
        "value": "VXZ"
    },
    {
        "label": "GRN - BARCLAYS BANK PLC",
        "value": "GRN"
    },
    {
        "label": "VXX - BARCLAYS BANK PLC",
        "value": "VXX"
    },
    {
        "label": "FRMEP - FIRST MERCHANTS CORP",
        "value": "FRMEP"
    },
    {
        "label": "SF-PC - STIFEL FINANCIAL CORP",
        "value": "SF-PC"
    },
    {
        "label": "SF-PD - STIFEL FINANCIAL CORP",
        "value": "SF-PD"
    },
    {
        "label": "SFB - STIFEL FINANCIAL CORP",
        "value": "SFB"
    },
    {
        "label": "MITT-PC - AG Mortgage Investment Trust, Inc.",
        "value": "MITT-PC"
    },
    {
        "label": "ANZUW - Anzu Special Acquisition Corp I",
        "value": "ANZUW"
    },
    {
        "label": "NSTD-WT - Northern Star Investment Corp. IV",
        "value": "NSTD-WT"
    },
    {
        "label": "ADERW - 26 Capital Acquisition Corp.",
        "value": "ADERW"
    },
    {
        "label": "ACABW - Atlantic Coastal Acquisition Corp. II",
        "value": "ACABW"
    },
    {
        "label": "TBLTW - Toughbuilt Industries, Inc",
        "value": "TBLTW"
    },
    {
        "label": "AUROW - Aurora Innovation, Inc.",
        "value": "AUROW"
    },
    {
        "label": "SPNT-PB - SiriusPoint Ltd",
        "value": "SPNT-PB"
    },
    {
        "label": "WESTW - Westrock Coffee Co",
        "value": "WESTW"
    },
    {
        "label": "ADTHW - AdTheorent Holding Company, Inc.",
        "value": "ADTHW"
    },
    {
        "label": "INBKZ - First Internet Bancorp",
        "value": "INBKZ"
    },
    {
        "label": "MHNC - Maiden Holdings, Ltd.",
        "value": "MHNC"
    },
    {
        "label": "ABR-PF - ARBOR REALTY TRUST INC",
        "value": "ABR-PF"
    },
    {
        "label": "ABR-PE - ARBOR REALTY TRUST INC",
        "value": "ABR-PE"
    },
    {
        "label": "ABR-PD - ARBOR REALTY TRUST INC",
        "value": "ABR-PD"
    },
    {
        "label": "TDS-PV - TELEPHONE & DATA SYSTEMS INC /DE/",
        "value": "TDS-PV"
    },
    {
        "label": "ATH-PB - Athene Holding Ltd",
        "value": "ATH-PB"
    },
    {
        "label": "ATH-PC - Athene Holding Ltd",
        "value": "ATH-PC"
    },
    {
        "label": "ATH-PD - Athene Holding Ltd",
        "value": "ATH-PD"
    },
    {
        "label": "ATH-PE - Athene Holding Ltd",
        "value": "ATH-PE"
    },
    {
        "label": "RBT-WT - Rubicon Technologies, Inc.",
        "value": "RBT-WT"
    },
    {
        "label": "TRTN-PB - Triton International Ltd",
        "value": "TRTN-PB"
    },
    {
        "label": "TRTN-PE - Triton International Ltd",
        "value": "TRTN-PE"
    },
    {
        "label": "GREEL - Greenidge Generation Holdings Inc.",
        "value": "GREEL"
    },
    {
        "label": "FREEW - Whole Earth Brands, Inc.",
        "value": "FREEW"
    },
    {
        "label": "NEOVW - NeoVolta Inc.",
        "value": "NEOVW"
    },
    {
        "label": "MSBIP - Midland States Bancorp, Inc.",
        "value": "MSBIP"
    },
    {
        "label": "LMND-WT - Lemonade, Inc.",
        "value": "LMND-WT"
    },
    {
        "label": "TCBIO - TEXAS CAPITAL BANCSHARES INC/TX",
        "value": "TCBIO"
    },
    {
        "label": "SHO-PH - Sunstone Hotel Investors, Inc.",
        "value": "SHO-PH"
    },
    {
        "label": "ALL-PI - ALLSTATE CORP",
        "value": "ALL-PI"
    },
    {
        "label": "AUB-PA - Atlantic Union Bankshares Corp",
        "value": "AUB-PA"
    },
    {
        "label": "AEL-PA - AMERICAN EQUITY INVESTMENT LIFE HOLDING CO",
        "value": "AEL-PA"
    },
    {
        "label": "AEL-PB - AMERICAN EQUITY INVESTMENT LIFE HOLDING CO",
        "value": "AEL-PB"
    },
    {
        "label": "CNOBP - ConnectOne Bancorp, Inc.",
        "value": "CNOBP"
    },
    {
        "label": "YSBPW - YS Biopharma Co., Ltd.",
        "value": "YSBPW"
    },
    {
        "label": "FEXDR - Fintech Ecosystem Development Corp.",
        "value": "FEXDR"
    },
    {
        "label": "WRB-PH - BERKLEY W R CORP",
        "value": "WRB-PH"
    },
    {
        "label": "WRB-PF - BERKLEY W R CORP",
        "value": "WRB-PF"
    },
    {
        "label": "UBP-PK - URSTADT BIDDLE PROPERTIES INC",
        "value": "UBP-PK"
    },
    {
        "label": "CRESW - CRESUD INC",
        "value": "CRESW"
    },
    {
        "label": "MBNKP - MEDALLION FINANCIAL CORP",
        "value": "MBNKP"
    },
    {
        "label": "FCNCP - FIRST CITIZENS BANCSHARES INC /DE/",
        "value": "FCNCP"
    },
    {
        "label": "PSBYP - PS BUSINESS PARKS, INC./MD",
        "value": "PSBYP"
    },
    {
        "label": "PSBXP - PS BUSINESS PARKS, INC./MD",
        "value": "PSBXP"
    },
    {
        "label": "PSBZP - PS BUSINESS PARKS, INC./MD",
        "value": "PSBZP"
    }
];

export default Tickers;