import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import Tickers from '../data/Tickers';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '100vw',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

    },
    TextField: {
        width: 200,

    }
});

const DataComponent = () => {
    const [DataTable, setData] = useState([]);
    const classes = useStyles();
    const [ticker, setTicker] = useState('');
    const [isDateVisible, setIsDateVisible] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year'));
    const [endDate, setEndDate] = useState(dayjs());
    const columns = [
        { field: 'timestamp', headerName: 'Date', width: 200 },
        { field: 'open', headerName: 'Open', width: 150 },
        { field: 'high', headerName: 'High', width: 150 },
        { field: 'low', headerName: 'Low', width: 150 },
        { field: 'close', headerName: 'Close', width: 150 },
        { field: 'volume', headerName: 'Volume', width: 100 },
    ];
    let data = Tickers;

    if (window.localStorage.getItem('data') !== null) {
        if (DataTable.length === 0) {
            setData(JSON.parse(window.localStorage.getItem('data')));
            setIsDateVisible(true);
        }
    }


    const fetchData = async (e) => {
        if (ticker === null || ticker === undefined || ticker === '') {
            return;
        }

        fetch(`${process.env.REACT_APP_API_HOST}/data/get_historical?ticker=${ticker}&start=${startDate}&end=${endDate}`)
            .then(response => response.json())
            .then(result => {
                const temp = [];
                for (const key in result) {
                    const person = {
                        id: key,
                        timestamp: result[key]['Datetime'],
                        high: result[key]['high'],
                        low: result[key]['low'],
                        open: result[key]['open'],
                        close: result[key]['close'],
                        volume: result[key]['volume'],

                    }
                    temp.push(person);
                }
                setData(temp);
                window.localStorage.setItem('data', JSON.stringify(temp));
            }
            ).catch(error => console.log('error', error));
        setIsDateVisible(true);
    }


    return (
        <Box>
            <Grid container spacing={2} className={classes.container}>
                <Grid item>
                    <Autocomplete
                        id="Ticker"
                        value={ticker}
                        freeSolo
                        autoSelect
                        disableClearable
                        options={data}
                        className={classes.TextField}
                        onChange={(e, value) => {
                            console.log("passed from onChange")
                            console.log(typeof value);
                            if (value !== undefined && value !== null) {
                                if (typeof value === 'object') {
                                    console.log("if statement ran");
                                    setTicker(value.value.toUpperCase());
                                } else {
                                    console.log("else statement ran");
                                    setTicker(value.toUpperCase());
                                }
                            }
                        }}
                        renderInput={(params) => <TextField  {...params} label="Ticker" id="ticker" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className={classes.TextField} defaultValue={dayjs().subtract(1, 'year')} value={startDate} label="Start Date" onChange={(newValue) => setStartDate(newValue)} />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className={classes.TextField} defaultValue={dayjs()} label="End Date" value={endDate} onChange={(newValue) => setEndDate(newValue)} />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <Button variant='outlined' onClick={fetchData}>Fetch Data</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <center>
                        {isDateVisible && <Box sx={{ height: '100%', width: '70%' }} className={classes.box}>
                            <DataGrid
                                rows={DataTable}
                                columns={columns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                disableSelectionOnClick
                            />
                        </Box>}
                    </center>
                </Grid>
            </Grid>

        </Box>


    );
};

export default DataComponent;
