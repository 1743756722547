import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import MyTable from '../ui/fundementals_table';
import Grid from '@mui/material/Unstable_Grid2';
import Tickers from '../data/Tickers';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '100vw',
    },
    TextField: {
        width: 200,

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: 20,
    },


});


const Fundamentals = () => {
    const classes = useStyles();
    const [ticker, setTicker] = useState("");
    const [statement, setStatement] = useState('');
    const [period, setPeriod] = useState('');
    const [DataTable, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let data = Tickers;

    if (window.localStorage.getItem('fundamentals') !== null) {
        if (DataTable === null) {
            setData(JSON.parse(window.localStorage.getItem('fundamentals')));
            setIsLoading(true);
        }
    }


    const fetchData = async (e) => {
        if (ticker === null || ticker === '') {
            return;
        }

        if (statement === null || statement === '') {
            // alert('Please select a statement');
            return;
        }

        if (period === null || period === '') {
            // alert('Please select a period');
            return;
        }

        fetch(`${process.env.REACT_APP_API_HOST}/fundamentals/${statement}?ticker=${ticker}&period=${period}`)
            .then(response => response.json())
            .then(result => {
                setData(result);
                window.localStorage.setItem('fundamentals', JSON.stringify(result));
                setIsLoading(true);
            })
            .catch(error => console.log('error', error));
    }



    return (
        <Box>
            <Grid container spacing={2} className={classes.container}>
                <Grid item>
                    <Autocomplete
                        id="Ticker"
                        value={ticker}
                        freeSolo
                        autoSelect
                        disableClearable
                        options={data}
                        className={classes.TextField}
                        onChange={(e, value) => {
                            console.log("passed from onChange")
                            console.log(typeof value);
                            if (value !== undefined && value !== null) {
                                if (typeof value === 'object') {
                                    console.log("if statement ran");
                                    setTicker(value.value.toUpperCase());
                                } else {
                                    console.log("else statement ran");
                                    setTicker(value.toUpperCase());
                                }
                            }
                        }}
                        renderInput={(params) => <TextField  {...params} label="Ticker" id="ticker" variant="outlined" />}
                    />
                </Grid>
                <Grid item>
                    <FormControl className={classes.TextField}>
                        <InputLabel id="label">Statement</InputLabel>
                        <Select
                            labelId="label"
                            id="statement"
                            value={statement}
                            label="Statement"
                            onChange={(e) => setStatement(e.target.value)}
                        >
                            <MenuItem value={'incomestatement'}>Income Statement</MenuItem>
                            <MenuItem value={'balancesheet'}>Balance Sheet Statement</MenuItem>
                            <MenuItem value={'cashflow'}>Cash Flow Statement</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl className={classes.TextField}>
                        <InputLabel id="label">Period</InputLabel>
                        <Select
                            labelId="label"
                            id="period"
                            value={period}
                            label="Period"
                            onChange={(e) => setPeriod(e.target.value)}
                        >
                            <MenuItem value={'q'}>Quarterly</MenuItem>
                            <MenuItem value={'a'}>Annual</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <Button variant="outlined" onClick={fetchData}>Fetch Data</Button>
                </Grid>

            </Grid>
            <Box sx={{ height: '100%', width: '100%' }} className={classes.box}>
                {isLoading && <MyTable data={DataTable} />}
            </Box>
        </Box>
    );
    // }
}

export default Fundamentals