// import React from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";


const Signout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
            signOut(auth).then(() => {
                // Sign-out successful.
                navigate("/");
                console.log("Signed out successfully")
            }).catch((error) => {
                // An error happened.
            });
        }

    return (
        handleLogout()

    )
}

export default Signout;
