import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from "@mui/material";
import NewsList from "../ui/NewsList";
import FinancialChart from "../Charting/PriceChart";
import { useWindowSize } from "@uidotdev/usehooks";
import styles from "./StockDashboard.module.css";
import { TextField } from "@mui/material";
import StockRating from "../ui/StockRating";
import NewQuoteCard from "../ui/NewQuoteCard";
import Tickers from '../data/Tickers';
import Autocomplete from '@mui/material/Autocomplete';

const StockDataGrid = () => {
    const size = useWindowSize();
    const [articles, setArticles] = useState([]);
    const [tempTicker, setTempTicker] = useState('');
    const [ticker, setTicker] = useState('');
    const [recommendation, setRecommendation] = useState('');
    const [sentiment, setSentiment] = useState('');
    const [sri, setSri] = useState('');
    const [isArticleLoaded, setIsArticleLoaded] = useState(false);
    const [isStockDataLoaded, setIsStockDataLoaded] = useState(false);
    const [isRecommendationLoaded, setIsRecommendationLoaded] = useState(false);
    let data = Tickers;

    const fetchData = (event) => {
        if (event.key === 'Enter') {
            setTicker(tempTicker);
        }
    };

    const [stockData, setStockData] = useState({});

    useEffect(() => {
        if (!ticker) return;
        if (ticker === null || ticker === '') return;
        console.log(ticker);

        fetch(`${process.env.REACT_APP_API_HOST}/analysis/news?ticker=${ticker}`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setArticles(data);
                setIsArticleLoaded(true);
            });

        fetch(`${process.env.REACT_APP_API_HOST}/data/quote?ticker=${ticker}&isCrypto=false`)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setStockData(result)
                setIsStockDataLoaded(true);
            })
            .catch(error => console.log('error', error));

        fetch(`${process.env.REACT_APP_API_HOST}/analysis/recomendations?ticker=${ticker}`)
            .then(response => response.text())
            .then(result => {
                const json = JSON.parse(result);
                setRecommendation(json['recommendation']);
                setSentiment(json['sentiment']);
                setSri(json['sri']);
                setIsRecommendationLoaded(true);
            })
            .catch(error => console.log('error', error));


    }, [ticker]);

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={2}>
                    <Box className={styles.GridItem}>
                    <Autocomplete
                        id="Ticker"
                        value={ticker}
                        freeSolo
                        autoSelect
                        options={data}
                        clearIcon
                        // className={classes.TextField}
                        sx={{margin: 1.5}}
                        onChange={(e, value) => {
                            console.log("passed from onChange")
                            console.log(typeof value);
                            if (value !== undefined && value !== null) {
                                if (typeof value === 'object') {
                                    console.log("if statement ran");
                                    setTicker(value.value.toUpperCase());
                                    setTempTicker(value.value.toUpperCase());
                                } else {
                                    console.log("else statement ran");
                                    setTicker(value.toUpperCase());
                                    setTempTicker(value.toUpperCase());
                                }
                            }
                        }}
                        onKeyDown={fetchData}
                        renderInput={(params) => <TextField  {...params} label="Ticker" id="ticker" variant="outlined" />}
                    />
                        {/* <TextField id="outlined-basic" label="Ticker" variant="outlined" sx={{margin: 1.5}} onChange={(e) => setTempTicker(e.target.value)} onKeyDown={fetchData} /> */}
                        {isArticleLoaded && <NewsList articles={articles} />}
                        {isStockDataLoaded && <NewQuoteCard {...stockData} />}
                        {isRecommendationLoaded && <StockRating symbol={ticker} recommendation={recommendation} sentiment={sentiment} sri={sri} />}
                    </Box>

                </Grid>

                <Grid item xs={12} md={10} className={styles.GridItem}>
                    <div className={styles.chart}>
                        <FinancialChart
                            ticker={ticker}
                            width={size.width * .82 || 1000}
                            height={size.height * .87 || 600}
                            ratio={2}
                        />
                    </div>
                </Grid>

            </Grid>
        </Box>
    );
}

export default StockDataGrid;