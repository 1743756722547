import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom'
import { TextField, Button } from '@mui/material';
import Layout from '../layout/Layout';
import styles from './Login.module.css';


const Login = ({handleThemeChange}) => {
    // const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const onLogin = async (e) => {
        e.preventDefault();
        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);

                var myHeaders = new Headers();
                myHeaders.append("Authorization", user.accessToken);

                var raw = "";

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_HOST}/auth/login`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
            })

        navigate('/dashboard');
    }

    return (
        <div>
            <Layout handleThemeChange={handleThemeChange} />
            <h1>Sign in with email</h1>
            <div className={styles.container} component='form'>
                <div className={styles.card}>
                    <TextField className={styles.field} required id='email' label='Email' variant='outlined' onChange={(e) => setEmail(e.target.value)} />
                    <TextField className={styles.field} required id='password' type='password' label='Password' variant='outlined' onChange={(e) => setPassword(e.target.value)} />
                    <Button className={styles.field} variant='outlined' onClick={onLogin}>Login</Button>
                    <div>
                        <p>Need an account? <a href='/signup'>Sign Up</a></p>
                        <p><a href='/forgot'>Forgot Password</a></p>
                    </div>
                </div>
            </div>
        </div>


    )
}


export default Login