import CardLeadership from "./CardLeadership";
import { Grid } from "@mui/material";


function CardList(props) {
    console.log(props.leader);
    return (
        <div>
            <Grid container justifyContent="center" spacing={1} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} lg={12} xl={12} md={12}> 
                    <Grid container justifyContent="center" spacing={1}>
                        {props.leader.map((lead) => (
                            <CardLeadership
                                name={lead.name}
                                title={lead.title}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default CardList;