import React, { useState } from "react";
import Layout from "../layout/Layout";
import ArticleList from "../ui/ArticleList";
import ArticleForm from "../ui/ArticleForm";
import styles from "./Research.module.css";
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import dayjs from 'dayjs';


const Research = ({ handleThemeChange }) => {
    const [value, setValue] = useState(window.localStorage.getItem('tab') || '1');


    const handleChange = (event, newValue) => {
        const oldDate = dayjs(localStorage.getItem('startDate'));
        const current = dayjs();
        if (current.diff(oldDate, 'day') > 1) {
            console.log('clearing local storage');
            window.localStorage.clear();
        }
        setValue(newValue);
        window.localStorage.clear();
        window.localStorage.setItem('tab', newValue);
        window.localStorage.setItem('startDate', dayjs());
    };

    const articlesStart = [
        {
            title: "Municipal-Bond Investors Pay a Hefty Price for Not Being Taxed",
            link: 'https://www.wsj.com/finance/investing/municipal-bonds-tax-free-price-74d4bbcf?mod=investing_more_article_pos6'
        },
        {
            title: "Municipal-Bond Investors Pay a Hefty Price for Not Being Taxed",
            link: 'https://www.wsj.com/finance/investing/municipal-bonds-tax-free-price-74d4bbcf?mod=investing_more_article_pos6'
        },
        {
            title: "Municipal-Bond Investors Pay a Hefty Price for Not Being Taxed",
            link: 'https://www.wsj.com/finance/investing/municipal-bonds-tax-free-price-74d4bbcf?mod=investing_more_article_pos6'
        },
        {
            title: "Municipal-Bond Investors Pay a Hefty Price for Not Being Taxed",
            link: 'https://www.wsj.com/finance/investing/municipal-bonds-tax-free-price-74d4bbcf?mod=investing_more_article_pos6'
        },
        {
            title: "Municipal-Bond Investors Pay a Hefty Price for Not Being Taxed",
            link: 'https://www.wsj.com/finance/investing/municipal-bonds-tax-free-price-74d4bbcf?mod=investing_more_article_pos6'
        },
    ];

    const [articles, setArticles] = useState(articlesStart);

    const handleAddArticle = (newArticle) => {
        setArticles([...articles, newArticle]);
    };

    return (
        <div>
            <Layout handleThemeChange={handleThemeChange} />
            <div className={styles.container}>
                <TabContext value={value}>
                    <div className={styles.tabContainer}>
                        <TabList onChange={handleChange}
                            aria-label="analysis tabs"
                            centered
                            // variant="scrollable"
                            allowScrollButtonsMobile
                        >
                            <Tab label="Articles" value="1" />
                            <Tab label="Submit Article" value="2" />
                        </TabList>
                    </div>
                    <TabPanel value="1">
                        <ArticleList articles={articles} />
                    </TabPanel>
                    <TabPanel value="2">
                        <ArticleForm handleAddArticle={handleAddArticle} />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
}

export default Research;