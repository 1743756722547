import React, { useState } from "react";
import RatingCard from "../ui/RatingCard";
import { Box, Button } from '@mui/material';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Tickers from '../data/Tickers';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '100vw',
    },
    TextField: {
        width: 200,

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },


});


function Rating() {
    const classes = useStyles();
    const [ticker, setTicker] = useState('');
    const [recommendation, setRecommendation] = useState('');
    const [sentiment, setSentiment] = useState('');
    const [sri, setSri] = useState('');
    const [isData, setIsData] = useState(false);
    let data = Tickers;

    if (window.localStorage.getItem('rating') !== null) {
        if (recommendation === '') {
            const json = JSON.parse(window.localStorage.getItem('rating'));
            setRecommendation(json['recommendation']);
            setSentiment(json['sentiment']);
            setSri(json['sri']);
            setIsData(true);
        }
    }

    const onSearch = async (e) => {
        if (ticker === null || ticker === '') {
            // alert('Please select a ticker');
            return;
        }

        fetch(`${process.env.REACT_APP_API_HOST}/analysis/recomendations?ticker=${ticker}`)
            .then(response => response.text())
            .then(result => {
                const json = JSON.parse(result);
                console.log(json);
                setRecommendation(json['recommendation']);
                setSentiment(json['sentiment']);
                setSri(json['sri']);
                setIsData(true);
                window.localStorage.setItem('rating', JSON.stringify(json));
            })
            .catch(error => console.log('error', error));
    }

    return (
        <Box>
            <Grid container spacing={2} className={classes.container}>
                <Grid item>
                    <Autocomplete
                        id="Ticker"
                        value={ticker}
                        freeSolo
                        autoSelect
                        disableClearable
                        options={data}
                        className={classes.TextField}
                        onChange={(e, value) => {
                            console.log("passed from onChange")
                            console.log(typeof value);
                            if (value !== undefined && value !== null) {
                                if (typeof value === 'object') {
                                    console.log("if statement ran");
                                    setTicker(value.value.toUpperCase());
                                } else {
                                    console.log("else statement ran");
                                    setTicker(value.toUpperCase());
                                }
                            }
                        }}
                        renderInput={(params) => <TextField  {...params} label="Ticker" id="ticker" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined" onClick={onSearch}>Fetch Data</Button>
                </Grid>
                <Grid item xs={12}>
                    <center>
                        {isData ? <RatingCard ticker={ticker} recommendation={recommendation} sentiment={sentiment} sri={sri} /> : null}
                    </center>
                </Grid>
            </Grid>
        </Box>





    );
}

export default Rating;