import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, TextField, Alert } from '@mui/material';
import styles from './Signup.module.css';
import Layout from '../layout/Layout';


const Signup = ({handleThemeChange}) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setShowAlert(true);
            return;
        }

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);

                var myHeaders = new Headers();
                myHeaders.append("Authorization", user['accessToken']);

                var raw = "";

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_API_HOST}/auth/signup`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });


    }

    return (
        <div>
            <Layout handleThemeChange={handleThemeChange} />
            <h1>Sign up with email</h1>
            <div className={styles.container} component='form'>
                <div className={styles.card}>
                    <TextField className={styles.field} required id='email' label='Email' variant='outlined' onChange={(e) => setEmail(e.target.value)} />
                    <TextField className={styles.field} required id='password' label='Password' variant='outlined' onChange={(e) => setPassword(e.target.value)} />
                    <TextField className={styles.field} required id='confirmPassword' label='Confirm Password' variant='outlined' onChange={(e) => setConfirmPassword(e.target.value)} />
                    <Button className={styles.field} variant='outlined' onClick={() => onSubmit && navigate('/signup')}>Sign Up</Button>
                    {showAlert &&
                        <Alert varient='outlined' color='red' severity="error">Passwords do not match</Alert>
                    }
                    {/* <Alert className={styles.field} severity="info">Already have an account? <NavLink to='/login'>Login</NavLink></Alert> */}
                    <div>
                        <p>Already have an account? <a href='/login'>Login</a></p>
                        <p><a href='/forgot'>Forgot Password</a></p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Signup