import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const MyTable = ({ data }) => {
  const renderTableHeader = () => {
    const { schema } = data;
    const headers = schema.fields.map((field) => field.name);
    // Replace "index" with "Line item" in the headers array
    const updatedHeaders = headers.map((header) => (header === 'index' ? 'Thousands' : header));

    return (
      <TableHead>
        <TableRow>
          {updatedHeaders.map((header, index) => (
            <TableCell key={index}>{header.substring(0,10)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const formatData = (data, index) => {
    if (index === 0) {
      return data;
    }else if (data < 1000 && data > 0) {
      return data;
    }
    else {
      return (data/1000).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  };

  const renderTableBody = () => {
    const { data: tableData } = data;
    return (
      <TableBody>
        {tableData.map((row, index) => (
          <TableRow key={index}>
            {Object.values(row).map((cell, index) => (
              <TableCell key={index}>{formatData(cell, index)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <TableContainer>
      <Table>
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
    </TableContainer>
  );
};

export default MyTable;
