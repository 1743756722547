import React from 'react';
import styles from './StockCard.module.css';

const StockCard = ({ ticker, price, change, logo }) => {
    return (
        <div className={styles.stockPill}>
            <img src={logo} className={styles.logo} alt="logo" />
            <div className={styles.col}>
                <div className={styles.symbol}>{ticker}</div>
                <div className={`${styles.row} ${change >= 0 ? styles.positive : styles.negative}`}>
                    <div className={styles.price}>${Math.round(price*100)/100}</div>
                    <div className={styles.change}>
                        {Math.round(change*100)/100}%
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockCard;
