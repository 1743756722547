import React from 'react';
import styles from './ArticleList.module.css';

function ArticleList({ articles }) {
    console.log(articles);
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>News Articles</h2>
            <ul className={styles.articleList}>
                {Object.entries(articles).map(([email, articleData]) => (
                    <li key={email} className={styles.articleItem}>
                        <a href={articleData.link} className={styles.articleLink} target="_blank" rel="noopener noreferrer">
                            {articleData.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ArticleList;



// import React from 'react';
// import styles from './ArticleList.module.css';

// const ArticleList = ({ articles }) => {

//     return (
//         <div className={styles.newsList}>
//             <div className={styles.newsList__header}>
//                 <span>Articles</span>
//             </div>
//             <div className={styles.newsList__articles}>
//                 {articles.map((article, index) => (
//                     <a key={index} className={styles.newsList__article} target="_blank" href={article.link} rel='noreferrer'>
//                         <span className={styles.newsList__articleTitle}>
//                             {article.title}
//                         </span>
//                     </a>
//                 ))}
//                 {articles.length === 0 && (
//                     <div className={styles.newsList__article}>
//                         <span className={styles.newsList__articleTitle}>
//                             No articles found
//                         </span>
//                     </div>)
//                 }
//             </div>
//         </div>
//     );
// };

// export default ArticleList;



// // import React from 'react';
// // import styles from './ArticleList.module.css'; // Import css modules stylesheet as styles
// // import './ArticleList.module.css'

// // const ArticleList = ({ articles }) => {
// //   return (
// //     <div className={styles.articleList}> {/* Updated class name */}
// //       <h2>Research Articles</h2>
// //       <ul>
// //         {articles.map((article, index) => (
// //           <li key={index}>
// //             <a href={article.url} target="_blank" rel="noopener noreferrer">
// //               {article.title}
// //             </a>
// //           </li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // };

// // export default ArticleList;
