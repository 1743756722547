import React, { useState } from 'react';
import styles from './ArticleForm.module.css';

function ArticleForm() {
  const [formData, setFormData] = useState({
    email: '',
    title: '',
    link: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Collected Data:', formData);
    sendData();


  }

  const sendData = async(e) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "email": formData.email,
      "title": formData.title,
      "link": formData.link
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_HOST}/articles/submit`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }



  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Collect Articles</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className={styles.label} htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className={styles.inputField}
          />
        </div>
        <div className="form-group">
          <label className={styles.label} htmlFor="title">Article Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            className={styles.inputField}
          />
        </div>
        <div className="form-group">
          <label className={styles.label} htmlFor="link">Article Link</label>
          <input
            type="url"
            id="link"
            name="link"
            value={formData.link}
            onChange={handleInputChange}
            required
            className={styles.inputField}
          />
        </div>
        <button type="submit" className={styles.submitButton}>Submit</button>
      </form>
    </div>
  );
}

export default ArticleForm;
