import React from "react";
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton, Tooltip } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Divider } from "@mui/material";
import styles from "./NavBar.module.css";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Switch } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';

const NavBar = ({handleThemeChange}) => {
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const navigate = useNavigate();
    const theme = useTheme();
    const secondaryColor = (theme.palette.primary.main);

    const accountHandler = (event) => {
        setAnchor(event.currentTarget);
    };
    const accountClose = () => {
        setAnchor(null);
    };

    return (
        <div className={styles.bar}>

            {/* <IconButton>
                    <img src="" alt="" width="50" height="" />
                </IconButton> */}

            <nav className={styles.links}>
                <Link to="/" className={styles.link} style={{color: secondaryColor}}>
                    Home
                </Link>
                <Link to="/analysis" className={styles.link} style={{color: secondaryColor}}>
                    Analysis
                </Link>
                {/* <Link to="/portfolio" className={styles.link}>
                    Portfolio
                </Link> */}
                {/* <Link to="/research" className={styles.link} style={{color: secondaryColor}}>
                    Research
                </Link> */}
                <Link to="/dashboard" className={styles.link} style={{color: secondaryColor}}>
                    Dashboard
                </Link>
            </nav>

            <Tooltip title="Account">
                <IconButton
                    onClick={accountHandler}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}>
                    <AccountCircleIcon sx={{color: secondaryColor}}/>
                </IconButton>
            </Tooltip>

            <Menu
                id="account-menu"
                anchorEl={anchor}
                open={open}
                onClose={accountClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => { accountClose(); navigate('/login'); }}>
                    Login
                </MenuItem>
                <MenuItem onClick={() => { accountClose(); navigate('/signup'); }}>
                    Profile
                </MenuItem>
                <MenuItem onClick={() => { accountClose(); navigate('/signup'); }}>
                    Sign Up
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { accountClose(); navigate('/whatsnew'); }}>
                    Whats new
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { accountClose(); navigate('/settings'); }}>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => { accountClose(); navigate('/signout'); }}>
                    Sign Out
                </MenuItem>
                <MenuItem>
                    <FormControlLabel control={
                    <Switch onChange={() => { handleThemeChange() }} />
                    } label="Light" />

                </MenuItem>
            </Menu>
        </div>
    );
}

export default NavBar;