import { useState, useEffect } from 'react';
import StockCard from '../ui/StockCard';
import Layout from '../layout/Layout';
import styles from './Home.module.css';
import aapl from '../../assets/images/appleLogo.png';
import rstn from '../../assets/images/rstnLogo.png';
import amzn from '../../assets/images/amznLogo.jpg';

const Home = ({ handleThemeChange }) => {
    const tickers = ['AAPL', 'RSTN', 'AMZN'];
    const [stockOne, setStockOne] = useState({});
    const [stockTwo, setStockTwo] = useState({});
    const [stockThree, setStockThree] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const timer = setTimeout(() => {
            fetch(`${process.env.REACT_APP_API_HOST}/data/quote?ticker=${tickers[0]}`)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    setStockOne(result);
                })
                .catch(error => console.log('error', error));

            fetch(`${process.env.REACT_APP_API_HOST}/data/quote?ticker=${tickers[1]}`)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    setStockTwo(result);
                })
                .catch(error => console.log('error', error));

            fetch(`${process.env.REACT_APP_API_HOST}/data/quote?ticker=${tickers[2]}`)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    setStockThree(result);
                })
                .catch(error => console.log('error', error));

            setIsDataLoaded(true);

        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const stockOne = {
    //     price: 146.92,
    //     ticker: 'AAPL',
    //     daily_price_change: 0.01,
    // };

    // const stockTwo = {
    //     price: 759.49,
    //     ticker: 'TSLA',
    //     daily_price_change: 4,
    // };

    // const stockThree = {
    //     price: 334.63,
    //     ticker: 'AMZN',
    //     daily_price_change: -0.10,
    // };

    return (
        <div className={styles.background}>
            <Layout handleThemeChange={handleThemeChange} />
            <div className={styles.container}>
                <h2 className={styles.item}>
                    Welcome to Red Bank
                </h2>
                {isDataLoaded && <div className={styles.pills}>
                    <StockCard ticker={stockOne.ticker} price={stockOne.price} change={stockOne.daily_percentage_change} logo={aapl} />
                    <StockCard ticker={stockTwo.ticker} price={stockTwo.price} change={stockTwo.daily_percentage_change} logo={rstn} />
                    <StockCard ticker={stockThree.ticker} price={stockThree.price} change={stockThree.daily_percentage_change} logo={amzn} />
                </div>}
            </div>
        </div>
    )
}

export default Home;
