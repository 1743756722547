import { BrowserRouter as Router, Route } from "react-router-dom";
import React from "react";
import { Routes } from "react-router-dom";
import Signup from "./components/pages/Signup";
import Signout from "./components/pages/Signout";
import Login from "./components/pages/Login";
import Research from "./components/pages/Research";
import Analysis from "./components/pages/Analysis";
import Home from "./components/pages/Home"
import Dashboard from "./components/pages/Dashboard";
import Portfolio from "./components/pages/Portfolio";
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";


const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#ffffff",
        },
        secondary: {
            main: "#000000",
        },
    },
});

const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#696969",
        },
        secondary: {
            main: "#ffffff",
        },
    },
});


export default function App() {
    const [darkMode, setDarkMode] = React.useState(true);

    const handleThemeChange = () => {
        setDarkMode(!darkMode);
        console.log(darkMode);
    };

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <div className="App">
                <Router>
                    <Routes>
                        <Route path="/" element={<Home handleThemeChange={handleThemeChange} />} />
                        <Route path="/signup" element={<Signup  handleThemeChange={handleThemeChange} />} />
                        <Route path="/login" element={<Login handleThemeChange={handleThemeChange} />} />
                        <Route path="/signout" element={<Signout handleThemeChange={handleThemeChange} />} />
                        <Route path="/analysis" element={<Analysis handleThemeChange={handleThemeChange} />} />
                        <Route path="/research" element={<Research handleThemeChange={handleThemeChange} />} />
                        <Route path="/dashboard" element={<Dashboard handleThemeChange={handleThemeChange} />} />
                        <Route path="/portfolio" element={<Portfolio handleThemeChange={handleThemeChange} />} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>

    );
}
