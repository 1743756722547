import React from 'react';
import Layout from '../layout/Layout';
import StockDataGrid from '../tabPages/StockDataGrid';
import TickerTape from '../layout/TickerTape';

const Dashboard = ({handleThemeChange}) => {
    return (
        <div>
            <Layout handleThemeChange={handleThemeChange} />
            <TickerTape />
            <StockDataGrid />
        </div>
    );
}

export default Dashboard;