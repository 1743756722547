import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function RatingCard(props) {

  return (
    <Card sx={{ width: 200, backgroundColor: 'white', margin: 5 }} variant='outlined'>
      <CardContent>
        <Typography sx={{ mb: 1.5 }} color='black'>
          Recommendation: {props.recommendation}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color='black'>
          Sentiment: {props.sentiment}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color='black'>
          SRI: {props.sri}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default RatingCard;