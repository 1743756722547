import React, { useState } from "react";
import CardList from "../ui/CardList";
import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Tickers from '../data/Tickers';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        maxWidth: '100vw',
    },
    TextField: {
        width: 200,

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
})

const Leadership = () => {
    const [ticker, setTicker] = useState('');
    const [leaders, setLeaders] = useState([]);
    const classes = useStyles();
    let data = Tickers;

    if (window.localStorage.getItem('leadership') !== null) {
        if (leaders.length === 0) {
            setLeaders(JSON.parse(window.localStorage.getItem('leadership')));
        }
    }

    const onSearch = async (e) => {
        if (ticker === null || ticker === '') {
            // alert('Please select a ticker');
            return;
        }

        fetch(`${process.env.REACT_APP_API_HOST}/analysis/leadership?ticker=${ticker}`)
            .then(response => response.json())
            .then(result => {
                const temp = [];
                for (const key in result) {
                    const person = {
                        id: key,
                        name: result[key]['Name'],
                        title: result[key]['Title'],
                    }
                    temp.push(person);
                }
                setLeaders(temp);
                window.localStorage.setItem('leadership', JSON.stringify(temp));

            })
            .catch(error => console.log('error', error));
    }

    return (
        <Box>
            <Grid container spacing={2} className={classes.container}>
                <Grid item>
                    <Autocomplete
                        id="Ticker"
                        value={ticker}
                        freeSolo
                        autoSelect
                        disableClearable
                        options={data}
                        className={classes.TextField}
                        onChange={(e, value) => {
                            console.log("passed from onChange")
                            console.log(typeof value);
                            if (value !== undefined && value !== null) {
                                if (typeof value === 'object') {
                                    console.log("if statement ran");
                                    setTicker(value.value.toUpperCase());
                                } else {
                                    console.log("else statement ran");
                                    setTicker(value.toUpperCase());
                                }
                            }
                        }}
                        renderInput={(params) => <TextField  {...params} label="Ticker" id="ticker" variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined" onClick={onSearch}>Fetch Data</Button>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <CardList leader={leaders} />
                </Grid>
            </Grid>
        </Box>


    );
}

export default Leadership;