import React from 'react';
import styles from './NewsList.module.css';
import { useTheme } from '@mui/material/styles';

const NewsList = ({ articles }) => {
    const theme = useTheme();
    const secondaryColor = (theme.palette.primary.main);

    return (
        <div className={styles.newsList}>
            <div className={styles.newsList__header}>
                <span>Live News</span>
            </div>
            <div className={styles.newsList__articles}>
                {articles.map((article, index) => (
                    <a key={index} className={styles.newsList__article} target="_blank" href={article.link} rel='noreferrer'>
                        <span className={styles.newsList__articleTitle} style={{color: secondaryColor}}>
                            {article.title}
                        </span>
                    </a>
                ))}
                {articles.length === 0 && (
                    <div className={styles.newsList__article}>
                        <span className={styles.newsList__articleTitle} style={{color: secondaryColor}}>
                            No articles found
                        </span>
                    </div>)
                }
            </div>
        </div>
    );
};

export default NewsList;
