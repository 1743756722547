import React, { useState } from 'react';
import Layout from '../layout/Layout';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Fundamentals from '../tabPages/Fundamentals';
import DataComponent from '../tabPages/HistoricalData';
import Leadership from '../tabPages/Lead';
import Rating from '../tabPages/Rating';
import Backtest from '../tabPages/Backtest';
import dayjs from 'dayjs';

const Analysis = ({handleThemeChange}) => {
    const [value, setValue] = useState(window.localStorage.getItem('tab') || '1');


    const handleChange = (event, newValue) => {
        const oldDate = dayjs(localStorage.getItem('startDate'));
        const current = dayjs();
        if (current.diff(oldDate, 'day') > 1) {
            console.log('clearing local storage');
            window.localStorage.clear();
        }
        setValue(newValue);
        window.localStorage.clear();
        window.localStorage.setItem('tab', newValue);
        window.localStorage.setItem('startDate', dayjs());
    };

    return (
        <Box>
            <Layout handleThemeChange={handleThemeChange} />
            <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}
                            aria-label="analysis tabs"
                            centered
                            // variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                        >
                            <Tab label="Fundamentals" value="1" />
                            <Tab label="Historical Data" value="2" />
                            <Tab label="Leadership" value="3" />
                            <Tab label="Rating" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Fundamentals />
                    </TabPanel>
                    <TabPanel value="2">
                        <DataComponent />
                    </TabPanel>
                    <TabPanel value="3">
                        <Leadership />
                    </TabPanel>
                    <TabPanel value="4">
                        <Rating />
                    </TabPanel>
                    <TabPanel value="5">
                        <Backtest />
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    );
};

export default Analysis;

