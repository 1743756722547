import React from 'react';
import styles from './StockRating.module.css';

const StockRating = ({ symbol, recommendation, sentiment, sri }) => {
  return (
    <div className={styles.stockRating}>
      {/* <h2>{symbol}</h2> */}
      <div className={styles.ratingInfo}>
        <div className={styles.ratingItem}>
          <strong>Recommendation: </strong> {recommendation}
        </div>
        <div className={styles.ratingItem}>
          <strong>Sentiment: </strong> {sentiment}
        </div>
        {/* <div className={styles.ratingItem}>
          <strong>Social Responsibility Index: </strong> {sri}
        </div> */}
      </div>
    </div>
  );
};

export default StockRating;
