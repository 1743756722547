import React, { useEffect } from 'react';
import Marquee from "react-fast-marquee";
import { Box } from '@mui/material';
import styles from "./tape.module.css"
// import { useInterval } from "@uidotdev/usehooks";


const TickerTape = () => {
  const [data, setData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  // useInterval(() => {
  //   console.log("tick");
  // }, 1000);

  const finishedLoop = () => {
    console.log("finished");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetch(`${process.env.REACT_APP_API_HOST}/data/index`)
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setData(data);
          setIsDataLoaded(true);
        });
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <Marquee gradient={true} gradientColor={[0, 0, 0]} speed={100} pauseOnHover={true} autoFill={true} className={styles.tickerTape} onCycleComplete={finishedLoop}>
        {isDataLoaded && 
          data.map((item, index) => (
            <div key={index} className={styles.indexTicker}>
              {Object.keys(item)[0]}: {item[Object.keys(item)[0]]}
            </div>
          ))
        }
      </Marquee>
    </Box>
  )
}

export default TickerTape