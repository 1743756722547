import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function CardLeadership(props) {
    return (
        <div style={{ margin: '2%' }}>
            <Card sx={{ maxWidth: 345 }} variant='outlined'>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {props.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.title}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default CardLeadership;